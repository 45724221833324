import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseTableListService, ITdDataTableColumnCustom } from '@saep-ict/angular-core';

@Injectable()
export class ClientsColumnService extends BaseTableListService {
	constructor(@Inject(TranslateService) public translate: TranslateService) {
		super();
	}
	_columns: ITdDataTableColumnCustom[] = [
		{
			name: 'business_name',
			labelPath: 'theme.organization.field.name',
			label: null
		},
		{
			name: 'address',
			labelPath: 'saep_ict_angular_spin8_core.widget.destination.address.name',
			label: null
		},
		{
			name: 'last_order_date',
			labelPath: 'order.last',
			label: null,
			width: 100
		},
		{
			name: 'total',
			labelPath: 'general.total_spending',
			label: null,
			numeric: true,
			width: 100
		}
	];
}
