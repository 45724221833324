import { Component, Input, OnInit } from '@angular/core';
import { ArticlePouchModel, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';

@Component({
	selector: 'article-input-multiple-validation',
	templateUrl: './article-input-multiple-validation.component.html',
	styleUrls: ['./article-input-multiple-validation.component.scss']
})
export class ArticleInputMultipleValidationComponent implements OnInit {

	@Input() article: ArticlePouchModel;
	@Input() organization: OrganizationPouchModel;

	configurationCustomer = ConfigurationCustomer;

	constructor() { }

	ngOnInit(): void {
	}

}
