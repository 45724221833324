<mat-toolbar *ngIf="localListHandlerTextFilterShow" class="pt-20">
	<mat-toolbar-row>
		{{ localListHandlerTitle }}
		<span class="badge" [class.ml-3]="localListHandlerTitle">
			{{ localListHandlerData?.data?.length || 0 }}
		</span>
	</mat-toolbar-row>

	<mat-toolbar-row *ngIf="lastCustomerCreditDate">
		<mat-card-subtitle
			>{{ 'general.last_update' | translate | sentencecase }} {{ lastCustomerCreditDate }}</mat-card-subtitle
		>
	</mat-toolbar-row>

	<mat-toolbar-row
		*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
		class="mt-30"
	>
		<td-search-box
			*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="true"
			placeholder="{{ 'general.search' | translate | sentencecase}}"
			(searchDebounce)="localListHandlerApplyFilter()"
		>
		</td-search-box>
	</mat-toolbar-row>
</mat-toolbar>

<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<td-data-table
		#dataTable
		[data]="localListHandlerData.dataSubset"
		[columns]="_localListHandlerWidgetWrapperColumnList"
		[sortable]="true"
		(sortChange)="localListHandlerApplyFilter({ sort: $event })"
		[sortBy]="localListHandlerData.sort && localListHandlerData.sort.name ? localListHandlerData.sort.name : null"
		[sortOrder]="
			localListHandlerData.sort && localListHandlerData.sort.order ? localListHandlerData.sort.order : null
		"
	>
		<ng-template tdDataTableTemplate="number_document" let-row="row" let-value="value">
			<p>
				{{ value }} <br />
				<small class="opacity-50">
					{{ row.number_batch }}
				</small>
			</p>
		</ng-template>
		<!--  se esiste currency allora amount_document non è in euro -->
		<!-- TODO: valutare che l'aproccio sia sufficiente per la gestione dinamica delle currency -->
		<ng-template tdDataTableTemplate="flag_give_take_dare" let-row="row">
			<p *ngIf="row.flag_give_take === giveTakeFlagEnum.take" class="success">
				{{ row.amount_document | utilPriceReturnItemValueFormattedPipe }}
				{{ row.currency ? row.currency : configurationCustomer.Article.currencyDefault }}
			</p>
		</ng-template>
		<ng-template tdDataTableTemplate="flag_give_take_avere" let-row="row">
			<p *ngIf="row.flag_give_take === giveTakeFlagEnum.give" class="warning">
				{{ row.amount_document | utilPriceReturnItemValueFormattedPipe }}
				{{ row.currency ? row.currency : configurationCustomer.Article.currencyDefault }}
			</p>
		</ng-template>
		<ng-template tdDataTableTemplate="date_expiration" let-row="row" let-value="value">
			<mat-icon *ngIf="row.flag_expired_bool" matTooltip="{{ 'general.expired' | translate | sentencecase }}"
				>error_outline</mat-icon
			>&nbsp;
			{{ value | date: 'dd/MM/yyyy' }}
		</ng-template>
	</td-data-table>
	<mat-toolbar>
		<mat-toolbar-row>
			<span class="flex-span"></span>
			<mat-paginator
				*ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow"
				#matPaginator
				(page)="localListHandlerApplyFilter({ pagination: $event })"
				[length]="localListHandlerData.pagination.totalFilteredItemCount"
				[pageSize]="localListHandlerData.pagination.pageSize"
				[pageSizeOptions]="[5, 10, 25, 100]"
			>
			</mat-paginator>
		</mat-toolbar-row>
	</mat-toolbar>
</ng-container>
<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
