<page-with-sidebar [sideBarPosition]="sideBarPositionValues.BOTTOM">
	<ng-container pageMain>
		<mat-card class="angelo-theme">
			<mat-toolbar>
				<mat-toolbar-row>
					<!-- Tabs | WIP tab-wrapper -->
					<mat-tab-group (selectedTabChange)="filterOnTabClick($event)">
						<mat-tab label="all_products">
							<ng-template mat-tab-label>{{
								'product.stock.all_products' | translate | sentencecase
							}}</ng-template>
						</mat-tab>
						<mat-tab label="regular">
							<ng-template mat-tab-label>{{
								'product.stock.regular' | translate | sentencecase
							}}</ng-template>
						</mat-tab>
						<mat-tab label="posm_gwp">
							<ng-template mat-tab-label>{{
								'product.stock.posm_gwp' | translate | sentencecase
							}}</ng-template>
						</mat-tab>
					</mat-tab-group>
					<span class="flex-span"></span>
				</mat-toolbar-row>
			</mat-toolbar>
			<article-list-structure-wrapper-plain-list
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="listPageBaseData.columnList"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				[onItemSelectShow]="false"
			>
			</article-list-structure-wrapper-plain-list>
		</mat-card>
	</ng-container>
	<ng-container pageSide>
		<sidebar-content-wrapper [title]="'product.stock.summary' | translate | sentencecase">
			<ng-container main-content>
				<div class="sidebar-content" *ngIf="filteredArticleMap">
					<div class="info__row">
						<p class="info__row__header">{{ 'product.stock.all_products' | translate | sentencecase }}</p>
						<p class="info__row__content">{{ filteredArticleMap.all.length }}</p>
					</div>
					<div class="info__row">
						<p class="info__row__header">{{ 'product.stock.regular' | translate | sentencecase }}</p>
						<p class="info__row__content">{{ filteredArticleMap.regular.length }}</p>
					</div>
					<div class="info__row">
						<p class="info__row__header">{{ 'product.stock.posm_gwp' | translate | sentencecase }}</p>
						<p class="info__row__content">{{ filteredArticleMap.freeSample.length }}</p>
					</div>
				</div>
			</ng-container>
		</sidebar-content-wrapper>
	</ng-container>
</page-with-sidebar>
