import { Injector } from '@angular/core';
import { DateMomentService, ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import { AppUtilService } from '../../../service/util/app-util.service';

const injector = Injector.create({
	providers: [
		{ provide: DateMomentService, deps: [] },
		{ provide: AppUtilService, deps: [] }
	]
});

const dateMomentService = injector.get(DateMomentService);
const appUtilService = injector.get(AppUtilService);

export namespace ColumnListOfferMap {
	export const Base: ITdDataTableColumnCustom[] = [
		{
			name: '_id',
			labelPath: 'offer.name',
			label: null
		},
		{
			name: 'header.custom_field.title',
			labelPath: 'offer.field.title',
			label: null
		},
		{
			name: 'header.date',
			labelPath: 'offer.field.date_creation',
			label: null,
			format: (v: string) => dateMomentService.convertStringDateFromFormatToFormat(v, 'x', 'DD/MM/YYYY')
		},
		{
			name: 'header.organization.business_name',
			labelPath: 'offer.field.client',
			label: null
		},
		{
			name: 'WIP',
			labelPath: 'offer.field.contact',
			label: null
		},
		{
			name: 'header.price.total',
			labelPath: 'offer.field.amount',
			label: null
		},
		{
			name: 'header.first_evasion_date',
			labelPath: 'offer.field.estimated_close_date',
			label: null,
			format: (v: string) => dateMomentService.convertStringDateFromFormatToFormat(v, 'x', 'DD/MM/YYYY')
		}
		// {
		// 	name: 'header.status',
		// 	label: '',
		// 	width: 80,
		// 	sortable: true
		// }
	];

	export const CRM = appUtilService
		.arrayRemoveElement(
			appUtilService.arrayMoveElement(
				Base,
				Base.findIndex(item => item.name === 'header.custom_field.title'),
				Base.findIndex(item => item.name === 'header.organization.business_name') + 1
			),
			Base.findIndex(item => item.name === 'WIP')
		)
		.map(item => {
			if (item.name == 'header.status') {
				item.labelPath = 'general.progress';
			}
			return item;
		})
		.concat(<ITdDataTableColumnCustom>{
			name: 'attachment',
			labelPath: '',
			label: null,
			sortable: false,
			width: 80,
			numeric: true
		});
}
