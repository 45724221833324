import { OrderStateModel, PermissionKeyEnum } from '@saep-ict/angular-spin8-core';
import { OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import moment from 'moment';
import { StatisticModel } from '../model/statistics.model';
import { ItemBoxTrendInfoComponentConfig, TrendDirectionValues } from '../widget/box-trend-info/box-trend-info.component';

export namespace Dashboard {
	export const cardVisibilityPermissions = {
		BACKOFFICE: {
			trendBoxContent: [PermissionKeyEnum.BACKOFFICE_DATA_CONFIGURATION],
			cardDashboardConfigCurrentOrdersToAuthorize: [PermissionKeyEnum.BACKOFFICE_DATA_CONFIGURATION],
			cardDashboardConfigCurrentOrdersProcessing: [PermissionKeyEnum.BACKOFFICE_DATA_CONFIGURATION],
			cardDashboardConfigCurrentOrdersConsolidated: [PermissionKeyEnum.BACKOFFICE_DATA_CONFIGURATION],
			cardDashboardConfigOrdersRecent: [PermissionKeyEnum.BACKOFFICE_DASHBOARD],
			cardDashboardConfigOrdersAverage: [PermissionKeyEnum.BACKOFFICE_DATA_CONFIGURATION],
			cardDashboardConfigOrdersQuantity: [PermissionKeyEnum.BACKOFFICE_DATA_CONFIGURATION],
			cardDashboardConfigFulfilledTrend: [PermissionKeyEnum.BACKOFFICE_DATA_CONFIGURATION],
			cardDashboardConfigExpired: [PermissionKeyEnum.BACKOFFICE_DATA_CONFIGURATION],
			cardDashboardConfigClientsTop5: [PermissionKeyEnum.BACKOFFICE_DATA_CONFIGURATION]
		}
	};
}

// order trend box
export const returnBoxTrendInfoOrderContent = (
	statusList: OrderStatusEnum[],
	orderList: OrderStateModel[],
	timeRange: StatisticModel.TimeRangeSelector
): ItemBoxTrendInfoComponentConfig[] => {
	const boxTrendInfoOrderContent: ItemBoxTrendInfoComponentConfig[] = [];
	for (const status of statusList) {
		orderList = orderList.filter(i => i.header.status === status);
		const orderNumberLastTimeRange = returnOrderNumberLastTimeRange(orderList, timeRange); 
		boxTrendInfoOrderContent.push(
			{
				value: orderNumberLastTimeRange,
				title: `order.summary.status_title_override.${status}`,
				subtitle: `order.summary.status_subtitle_override.${status}`,
				trendDirection: returnOrderTrendDirection(orderList, orderNumberLastTimeRange, timeRange)
			}
		);
	}
	return boxTrendInfoOrderContent;
}

export const returnOrderNumberLastTimeRange = (
	orderList: OrderStateModel[],
	timeRange: StatisticModel.TimeRangeSelector): number => {
	const today: Date = new Date();
	const dateLast = moment(today).subtract(timeRange.value, timeRange.key).valueOf();
	orderList = _.cloneDeep(orderList.filter(i => i.header.date >= dateLast));
	return orderList ? orderList.length : 0;
}

export const returnOrderTrendDirection = (
	orderList: OrderStateModel[],
	orderNumberLastTimeRange: number,
	timeRange: StatisticModel.TimeRangeSelector
): TrendDirectionValues => {
	const timeRangePrevius = _.cloneDeep(timeRange);
	timeRangePrevius.value = timeRangePrevius.value * 2;
	let orderNumberPreviousLastTimeRange = returnOrderNumberLastTimeRange(orderList, timeRangePrevius);
	orderNumberPreviousLastTimeRange = orderNumberPreviousLastTimeRange - orderNumberLastTimeRange;
	if (orderNumberPreviousLastTimeRange < orderNumberLastTimeRange) {
		return TrendDirectionValues.UP;
	}
	if (orderNumberPreviousLastTimeRange > orderNumberLastTimeRange) {
		return TrendDirectionValues.DOWN;
	}
	if (orderNumberPreviousLastTimeRange === orderNumberLastTimeRange) {
		return TrendDirectionValues.STEADY;
	}
}

export const compareValueOrderTrendTimeRange = (a, b): boolean => {
	return a && b && a.key === b.key && a.value === b.value;
}