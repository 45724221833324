import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { AngularCoreUtilService } from '@saep-ict/angular-core';

@Component({
	selector: 'receipt-list-wrapper',
	templateUrl: './receipt-list-wrapper.component.html',
	styleUrls: ['./receipt-list-wrapper.component.scss']
})
export class ReceiptListWrapperComponent extends LocalListHandlerWidgetWrapper {
	constructor(
		public utilService: AngularCoreUtilService,
		public translateService: TranslateService,
		public store: Store,
	) {
		super(utilService, translateService, store);
	}

	printReceipt(downloadPath: string) {
		window.open(downloadPath);
	}
}
