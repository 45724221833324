import { Component, Input } from '@angular/core';

// model
import { ViewTypeEnum } from '../../../../enum/view-type.enum';
import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import { ItemType } from './card-slider.models';

// misc
import { UtilOrderService } from '../../../../service/util/util-order.service';
import { AngularSpin8CoreUtilTranslateService } from '@saep-ict/angular-spin8-core';
import { UtilPriceService } from '../../../../service/util/util-price.service';
import { ROUTE_URL } from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'b2c-article-item',
	templateUrl: './b2c-article-item.component.html',
	styleUrls: ['./b2c-article-item.component.scss']
})
export class B2cArticleItemComponent {
	@Input() viewType: ViewTypeEnum;
	@Input() itemType: ItemType;
	@Input() showDetails: boolean = true;
	@Input() article: ArticlePouchModel;

	public ViewTypeEnum = ViewTypeEnum;

	ROUTE_URL = ROUTE_URL;
	itemTypeEnum = ItemType;

	constructor(
		public utilOrderService: UtilOrderService,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		public utilPriceService: UtilPriceService
	) {}

	linkToItem() {
		if (this.article && this.article.type == 'category') {
			return this.article._id;
		}
		return this.article.code_item;
	}
}
