<div class="container-fluid">
	<div class="row">
		<div class="col-12">
			<mat-card>
				<form class="angelo-theme" [formGroup]="form" (ngSubmit)="onFormSubmit()">
					<div class="row">
						<!-- origin_name: NASCOSTO -->
						<!-- <div class="col-md-6">
							<mat-form-field>
								<mat-label>{{ 'opportunity.field.origin_name' | translate | sentencecase }}</mat-label>
								<input matInput type="text" formControlName="origin_name" />
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="form.get('origin_name')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div> -->

						<button
							mat-icon-button
							aria-label="add action"
							[matTooltip]="'action.show' | translate | sentencecase"
							matTooltipPosition="left"
							(click)="openDialogActionList()"
							type="button"
							class="actionList"
							*ngIf="idOpportunity !== ROUTE_URL.newOpportunity"
						>
							<mat-icon>fact_check</mat-icon>
						</button>

						<!-- Titolo opportunità -->
						<div class="col-md-12">
							<mat-form-field>
								<mat-label>{{
									'saep_ict_angular_spin8_core.general.title' | translate | sentencecase
								}}</mat-label>
								<input matInput type="text" formControlName="title" />
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="getFormControl('title')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>

						<!-- Messaggio -->
						<div class="col-md-12">
							<mat-form-field>
								<mat-label>{{ 'opportunity.field.message' | translate | sentencecase }} *</mat-label>
								<textarea matInput formControlName="message" rows="5"></textarea>
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="getFormControl('message')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>

						<!-- Separatore -->
						<div class="col-12"></div>

						<!-- organizzazione -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>
									{{
										'saep_ict_angular_spin8_core.page.organization.name' | translate | sentencecase
									}}
									*</mat-label
								>
								<input matInput formControlName="organization" readonly />
								<button
									type="button"
									mat-button
									matSuffix
									mat-icon-button
									aria-label="Edit"
									(click)="openDialogSelectOrganization()"
									*ngIf="!opportunity || (opportunity && !opportunity.close_date)"
								>
									<mat-icon>edit</mat-icon>
								</button>
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="getFormControl('organization')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>

						<!-- Mittente -->
						<div class="col-md-5">
							<mat-form-field>
								<mat-label>{{ 'opportunity.field.referent' | translate | sentencecase }} *</mat-label>
								<input matInput [matAutocomplete]="auto" formControlName="contact" />
								<button
									*ngIf="getFormControl('contact').value"
									matSuffix
									mat-icon-button
									aria-label="Clear"
									(click)="getFormControl('contact').setValue('')"
								>
									<mat-icon>close</mat-icon>
								</button>
								<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
									<mat-option
										*ngFor="let referent of filteredReferentList | async"
										[value]="referent"
									>
										{{ getReferentDescription(referent) }}
									</mat-option>
								</mat-autocomplete>
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="getFormControl('contact')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>

						<!-- Pulsante per aggiungere referente (solo se l'organizzazione è stata selezionata) -->
						<div class="col-sm-1">
							<button
								mat-icon-button
								type="button"
								color="primary"
								(click)="openDialogOrganizationContact()"
								aria-label="Add referent"
								[matTooltip]="'opportunity.field.add_referent' | translate | sentencecase"
								[disabled]="!form.get('organization').value"
							>
								<mat-icon>person_add</mat-icon>
							</button>
						</div>

						<!-- assegnee -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label> {{ 'opportunity.field.assegnee' | translate | sentencecase }}</mat-label>
								<input matInput formControlName="assegnee" readonly />
								<button
									type="button"
									mat-button
									matSuffix
									mat-icon-button
									aria-label="Edit"
									(click)="openDialogSelectAssegnee()"
									*ngIf="!opportunity || (opportunity && !opportunity.close_date)"
								>
									<mat-icon>edit</mat-icon>
								</button>
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="getFormControl('assegnee')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>

						<!-- Tipo opportunità -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>{{ 'opportunity.field.type' | translate | sentencecase }} *</mat-label>
								<mat-select formControlName="opportunity_type">
									<mat-option
										*ngFor="let oppType of OpportunityEnum.Type | enumIterator"
										[value]="oppType.id"
									>
										{{ oppType.description | sentencecase }}
									</mat-option>
								</mat-select>
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="getFormControl('opportunity_type')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>

						<!-- Area di interesse -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>{{ 'opportunity.field.topic' | translate | sentencecase }}</mat-label>
								<mat-select formControlName="topic">
									<mat-option
										*ngFor="let topicType of OpportunityEnum.TopicType | enumIterator"
										[value]="topicType.id"
									>
										{{ topicType.description | sentencecase }}
									</mat-option>
								</mat-select>
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="getFormControl('topic')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>

						<!-- Campi per le opportunity prospect e client -->
						<ng-container
							*ngIf="
								route.parent.snapshot.paramMap.get('opportunityLevel') !== OpportunityEnum.Level.LEAD
							"
						>
							<!-- Separatore -->
							<div class="col-12"></div>

							<!-- Riferimento -->
							<div class="col-md-4">
								<mat-form-field>
									<mat-label>{{
										'opportunity.field.reference' | translate | sentencecase
									}}</mat-label>
									<input matInput type="text" formControlName="reference" maxlength="15" />
									<mat-error>
										<form-error-message
											ngDefaultControl
											[formControl]="getFormControl('reference')"
										></form-error-message>
									</mat-error>
								</mat-form-field>
							</div>

							<!-- Interesse progetto -->
							<div class="col-md-4">
								<mat-form-field>
									<mat-label>
										{{ 'opportunity.field.project_interest' | translate | sentencecase }}
									</mat-label>
									<input
										matInput
										type="number"
										min="0"
										max="100"
										formControlName="project_interest"
									/>
									<span matSuffix>%</span>
									<mat-error>
										<form-error-message
											ngDefaultControl
											[formControl]="getFormControl('project_interest')"
										></form-error-message>
									</mat-error>
								</mat-form-field>
							</div>

							<!-- Probabilità di chiusura -->
							<div class="col-md-4">
								<mat-form-field>
									<mat-label>{{
										'opportunity.field.close_probability' | translate | sentencecase
									}}</mat-label>
									<input
										matInput
										type="number"
										min="0"
										max="100"
										formControlName="close_probability"
									/>
									<span matSuffix>%</span>
									<mat-error>
										<form-error-message
											ngDefaultControl
											[formControl]="getFormControl('close_probability')"
										></form-error-message>
									</mat-error>
								</mat-form-field>
							</div>

							<!-- Valore opportunità -->
							<div class="col-md-6">
								<mat-form-field>
									<mat-label>{{
										'opportunity.field.actual_value' | translate | sentencecase
									}}</mat-label>
									<input matInput formControlName="actual_value" />
									<span matSuffix>€</span>
									<mat-error>
										<form-error-message
											ngDefaultControl
											[formControl]="getFormControl('actual_value')"
										></form-error-message>
									</mat-error>
								</mat-form-field>
							</div>

							<!-- Budget Disponibile -->
							<div class="col-md-6">
								<mat-form-field>
									<mat-label>
										{{ 'opportunity.field.budget_amount' | translate | sentencecase }}
									</mat-label>
									<input matInput formControlName="budget_amount" />
									<span matSuffix>€</span>
									<mat-error>
										<form-error-message
											ngDefaultControl
											[formControl]="getFormControl('budget_amount')"
										></form-error-message>
									</mat-error>
								</mat-form-field>
							</div>

							<!-- Separatore -->
							<div class="col-12"></div>

							<!-- Data chiusura -->
							<div class="col-md-6">
								<mat-form-field>
									<mat-label>{{
										'opportunity.field.estimated_close_date' | translate | sentencecase
									}}</mat-label>
									<input
										matInput
										readonly
										formControlName="estimated_close_date"
										[matDatepicker]="estimatedCloseDatePicker"
										(click)="estimatedCloseDatePicker.open()"
									/>
									<mat-datepicker-toggle
										matSuffix
										[for]="estimatedCloseDatePicker"
									></mat-datepicker-toggle>
									<mat-datepicker #estimatedCloseDatePicker></mat-datepicker>
									<mat-error>
										<form-error-message
											ngDefaultControl
											[formControl]="getFormControl('estimated_close_date')"
										></form-error-message>
									</mat-error>
									<button
										type="button"
										mat-button
										matSuffix
										mat-icon-button
										aria-label="Clear"
										*ngIf="
											(!opportunity || (opportunity && !opportunity.close_date)) &&
											form.get('estimated_close_date').value
										"
										(click)="resetSelectField($event, 'estimated_close_date')"
									>
										<mat-icon>clear</mat-icon>
									</button>
								</mat-form-field>
							</div>
							<!-- Data followup -->
							<div class="col-md-6">
								<mat-form-field>
									<mat-label>{{
										'opportunity.field.schedule_followup_prospect' | translate | sentencecase
									}}</mat-label>
									<input
										matInput
										readonly
										formControlName="schedule_followup_prospect"
										[matDatepicker]="scheduleFollowUpProspectPicker"
										(click)="scheduleFollowUpProspectPicker.open()"
									/>
									<mat-datepicker-toggle
										matSuffix
										[for]="scheduleFollowUpProspectPicker"
									></mat-datepicker-toggle>
									<mat-datepicker #scheduleFollowUpProspectPicker></mat-datepicker>
									<mat-error>
										<form-error-message
											ngDefaultControl
											[formControl]="getFormControl('schedule_followup_prospect')"
										></form-error-message>
									</mat-error>
									<button
										type="button"
										mat-button
										matSuffix
										mat-icon-button
										aria-label="Clear"
										*ngIf="
											(!opportunity || (opportunity && !opportunity.close_date)) &&
											form.get('schedule_followup_prospect').value
										"
										(click)="resetSelectField($event, 'schedule_followup_prospect')"
									>
										<mat-icon>clear</mat-icon>
									</button>
								</mat-form-field>
							</div>

							<!-- step -->
							<div class="col-md-6">
								<mat-form-field>
									<mat-label>{{ 'opportunity.field.step' | translate | sentencecase }}</mat-label>
									<mat-select formControlName="step">
										<mat-option *ngFor="let step of stepList" [value]="step.id">
											{{ step.name | translate | sentencecase }}
										</mat-option>
									</mat-select>
									<button
										type="button"
										mat-button
										matSuffix
										mat-icon-button
										aria-label="Clear"
										*ngIf="
											(!opportunity || (opportunity && !opportunity.close_date)) &&
											form.get('step').value
										"
										(click)="resetSelectField($event, 'step')"
									>
										<mat-icon>clear</mat-icon>
									</button>
								</mat-form-field>
							</div>
						</ng-container>

						<!-- Separatore -->
						<div class="col-12"></div>

						<div class="col-md-12">
							<mat-accordion>
								<mat-expansion-panel [expanded]="false">
									<mat-expansion-panel-header [collapsedHeight]="'64px'">
										<mat-panel-title> Informazioni aggiuntive </mat-panel-title>
									</mat-expansion-panel-header>

									<div class="row">
										<!-- id -->
										<div class="col-md-6">
											<mat-form-field>
												<mat-label>{{ 'id' | translate | sentencecase }}</mat-label>
												<input matInput type="text" formControlName="id" />
											</mat-form-field>
										</div>

										<!-- Client ID Google -->
										<div class="col-md-6">
											<mat-form-field>
												<mat-label>{{
													'opportunity.field.client_id' | translate | sentencecase
												}}</mat-label>
												<input matInput type="text" formControlName="client_id" />
												<mat-error>
													<form-error-message
														ngDefaultControl
														[formControl]="getFormControl('client_id')"
													></form-error-message>
												</mat-error>
											</mat-form-field>
										</div>

										<!-- Separatore -->
										<div class="col-12"></div>

										<!-- Origine -->
										<div class="col-md-6">
											<mat-form-field>
												<mat-label>{{
													'opportunity.field.origin_name' | translate | sentencecase
												}}</mat-label>
												<input matInput type="text" formControlName="origin_name" />
												<mat-error>
													<form-error-message
														ngDefaultControl
														[formControl]="getFormControl('origin_name')"
													></form-error-message>
												</mat-error>
											</mat-form-field>
										</div>

										<!-- ID utente -->
										<div class="col-md-6" *ngIf="getFormControl('user_id').value">
											<mat-form-field>
												<mat-label>{{
													'opportunity.field.user_id' | translate | sentencecase
												}}</mat-label>
												<input matInput type="text" formControlName="user_id" />
												<mat-error>
													<form-error-message
														ngDefaultControl
														[formControl]="getFormControl('user_id')"
													></form-error-message>
												</mat-error>
											</mat-form-field>
										</div>

										<!-- Utente di inserimento -->
										<div class="col-md-6">
											<mat-form-field>
												<mat-label>{{
													'Utente di inserimento' | translate | sentencecase
												}}</mat-label>
												<input matInput type="text" formControlName="added_by" />
												<mat-error>
													<form-error-message
														ngDefaultControl
														[formControl]="getFormControl('added_by')"
													></form-error-message>
												</mat-error>
											</mat-form-field>
										</div>
									</div>
								</mat-expansion-panel>
							</mat-accordion>
						</div>
					</div>

					<!--  Footer -->
					<div class="row">
						<!-- Separatore -->
						<div class="col-12"></div>

						<div class="col-12">
							<p class="opacity-50" *ngIf="opportunity && opportunity.date_creation">
								Aperta il {{ opportunity.date_creation | date: 'dd/MM/yyyy HH:mm' }}
							</p>

							<p class="opacity-50" *ngIf="opportunity && opportunity.close_date">
								Chiusa il {{ opportunity.close_date | date: 'dd/MM/yyyy HH:mm' }}
							</p>
						</div>

						<mat-toolbar *ngIf="!opportunity || (opportunity && !opportunity.close_date)">
							<!-- Salva -->
							<button
								mat-raised-button
								color="primary"
								type="submit"
								class="mr-3"
								*ngIf="!opportunity || (opportunity && !opportunity.close_date)"
							>
								{{ 'general.save' | translate | sentencecase }}
							</button>
							<!-- Promuovi -->
							<button
								mat-stroked-button
								type="button"
								class="mr-3"
								(click)="changeStatusOpportunityTo(OpportunityEnum.Level.PROSPECT)"
								*ngIf="
									opportunity &&
									opportunity._id &&
									route.parent.snapshot.paramMap.get('opportunityLevel') ===
										OpportunityEnum.Level.LEAD &&
									!opportunity.close_date
								"
							>
								{{ 'opportunity.promote' | translate | sentencecase }}
							</button>
							<!-- Chiudi opportunity -->
							<button
								mat-stroked-button
								type="button"
								(click)="openDialogCloseOpportunity()"
								*ngIf="opportunity && opportunity._id && !opportunity.close_date"
							>
								{{
									(route.parent.snapshot.paramMap.get('opportunityLevel') ===
									OpportunityEnum.Level.LEAD
										? 'request.close'
										: 'opportunity.close'
									)
										| translate
										| sentencecase
								}}
							</button>

							<!-- <form-button-bar
								[align]="'left'"
								[primaryButtonLabel]="
								canSaveForm
										? ('general.save' | translate | sentencecase)
										: null
								"
								(primaryButtonClickEmitter)="onFormSubmit()"
								[secondaryButtonLabel]="
									canPromoteForm
										? ('opportunity.promote' | translate | sentencecase)
										: null
								"
								(secondaryButtonClickEmitter)="changeStatusOpportunityTo(OpportunityEnum.Level.PROSPECT)"
								[otherButtonLabel]="
									opportunity && opportunity._id && !opportunity.close_date
										? ((route.parent.snapshot.paramMap.get('opportunityLevel') ===
										  OpportunityEnum.Level.LEAD
												? 'request.close'
												: 'opportunity.close'
										  )
										  | translate
										  | sentencecase)
										: null
								"
								(otherButtonClickEmitter)="openDialogCloseOpportunity()"
							></form-button-bar> -->
						</mat-toolbar>
					</div>
				</form>
			</mat-card>
		</div>
	</div>
</div>
