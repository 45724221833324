<div [ngClass]="styleClassList">
	<ng-scrollbar [track]="'horizontal'">
		<div class="tab-wrapper">
			<div
				*ngFor="let category of _list"
				[ngClass]="{ active: category.active }"
				(click)="clickHandler(category)"
			>
				{{ utilTranslateService.getTranslationFromLanguage(category.language_list).description }}
			</div>
		</div>
	</ng-scrollbar>
</div>

<category-tab-list
	*ngIf="listChildren"
	[list]="listChildren"
	[configuration]="listChildrenConfiguration"
	(categorySelectedChange)="emitHandler($event)"
></category-tab-list>
