import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
	BaseState,
	DateMomentService,
	DialogConfirmComponent,
	GuidFormatterPipe,
	SubscribeManagerService,
	UtilDownload
} from '@saep-ict/angular-core';
import {
	BodyTablePouchModel,
	DivisionPouchModel,
	OrganizationPouchModel,
	DestinationPouchModel,
	OrderPouchModel,
	OrderStatusEnum,
	PaymentPouchModel,
	OrganizationLevelEnum,
	ArticlePouchModel,
	CausalHeaderSoPouchModel
} from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { Observable } from 'rxjs';
import { debounceTime, filter, map, mergeMap } from 'rxjs/operators';
import { ConfigurationOrder, OrderBackoffice } from '../../../../constants/order/order.constants';
import { StatusBarOrderService } from '../../../../service/status-bar-config/implementation/status-bar-order.service';
import { UtilOrderService } from '../../../../service/util/util-order.service';
import { StateFeature } from '../../../../state';
import { OrganizationActionEnum, OrganizationStateAction } from '../../../../state/organization/organization.actions';
import { OrderActionEnum, OrderStateAction } from '../../../../state/order/order.actions';
import { MatSnackBarWrapperComponent } from '../../../../widget/mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import { ConfigurationCustomer } from '../../../../constants/configuration-customer';
import { ArticleStateAction } from '../../../../state/article/article.actions';
import { BaseStateModel, SentencecasePipe } from '@saep-ict/angular-core';
import { AppUtilService } from '../../../../service/util/app-util.service';
import { AlertType } from '../../../../widget/alert/alert.component';
import { StoreUtilService } from '../../../../service/util/store-util.service';
import { PermissionUtilService } from '../../../../service/util/permission-util.service';
import {
	ArticleEnum,
	AuxiliaryTableStateModel,
	CausalHeaderOrderEnum,
	ContextApplicationItemCodeEnum,
	ExtraFieldOrderHeaderPouchModel,
	OrderStateModel,
	PATH_URL,
	UserDetailModel,
	AngularSpin8CoreUtilCompanyService,
	OrganizationStateModel,
	ROUTE_URL
} from '@saep-ict/angular-spin8-core';
import { StateRelatedLink } from '../../../../constants/configuration-customer/order/status-aggregation-map.constant';
import { ResumeService } from '../../../../service/rest/resume.service';
import { DialogActionsComponent } from '../../../../widget/dialog/dialog-actions/dialog-actions.component';
import { OrderCloneTypeEnum } from '../../../../enum/order.enum';
import { BreadcrumbTranslateItemModel, UtilBreadcrumbService } from '../../../../service/util/util-breadcrumb.service';
import { StatusBarConfigNew } from '../../../../model/status-bar-new.model';
import { ConfigurationOrderExtend } from '../../../../constants/configuration-customer/order/order.constants';
import { ExtendedDestinationPouchModel } from '../../../../model/destination.model';

@Component({
	selector: 'app-order-detail',
	templateUrl: './order-detail.component.html',
	styleUrls: ['./order-detail.component.scss'],
	providers: [SubscribeManagerService, StatusBarOrderService]
})
export class OrderDetailComponent implements OnInit, OnDestroy {
	// ngrx state start
	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel;

	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;
	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);
	auxiliaryTable: AuxiliaryTableStateModel;
	organizationList$: Observable<BaseStateModel<OrganizationPouchModel[]>> = this.store.select(
		StateFeature.getOrganizationListState
	);
	destinationList: ExtendedDestinationPouchModel[];

	// ngrx state end
	orderId: string = this.route.snapshot.paramMap.get('orderId');
	orderStatus: string = this.route.snapshot.paramMap.get('orderStatus');
	organizationPermission: OrganizationPouchModel;
	// order util data
	paymentList: { name: string; payment: PaymentPouchModel[] }[];
	paymentDivision: PaymentPouchModel[];
	paymentDefault: PaymentPouchModel[];
	methodDeliveryList: BodyTablePouchModel[];
	// enum
	orderStatusEnum = OrderStatusEnum;
	causalHeaderOrderEnum = CausalHeaderOrderEnum;
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
	// form
	form: FormGroup;
	// check date
	setTodayDate = false;
	setTodayMinusFiveDaysDate = false;
	setProductPartiallyAvailable = false;
	setProductNotAvailable = false;
	orderProductListNotFound = [];

	breadcrumbPathList: string[];
	// tab wrapper
	tabsToExclude = [];
	// backoffice
	organizationToBeValidated: boolean = false;

	configurationCustomer = ConfigurationCustomer;
	configurationOrder = ConfigurationOrder;
	configurationOrderExtend = ConfigurationOrderExtend;

	alertTypeEnum = AlertType;

	formattedOrderUuid: string;

	statusBarConfig: StatusBarConfigNew;

	constructor(
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private route: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private datePipe: DatePipe,
		public utilOrderService: UtilOrderService,
		public utilCompanyService: AngularSpin8CoreUtilCompanyService,
		public utilService: AppUtilService,
		public statusBardOrderService: StatusBarOrderService,
		private dialog: MatDialog,
		private dateMomentService: DateMomentService,
		private snackBar: MatSnackBar,
		private guidFormatterPipe: GuidFormatterPipe,
		private translate: TranslateService,
		private utilStoreService: StoreUtilService,
		private permissionUtilService: PermissionUtilService,
		private sentenceCasePipe: SentencecasePipe,
		private resumeService: ResumeService,
		private utilBreadcrumbService: UtilBreadcrumbService
	) {
		this.setTabsToExclude();
		const organizationId = this.route.snapshot.paramMap.get('organizationId');
		this.loadStaticData(organizationId);
		this.createForm();
		this.subscribeManagerInit();
		this.setRouteMetaInformation();
	}

	// angular lifecycle
	ngOnInit() {
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		if (!this.permissionUtilService.isOrganizationCodeItem(this.user)) {
			this.store.dispatch(OrganizationStateAction.reset());
			this.store.dispatch(ArticleStateAction.reset());
		}
		this.store.dispatch(OrderStateAction.reset());
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	// load static data
	loadStaticData(organizationId: string) {
		this.utilStoreService.retrieveSyncState<UserDetailModel>(this.user$).subscribe(res => {
			this.user = res.data;
		});
		this.utilStoreService.retrieveSyncState<AuxiliaryTableStateModel>(this.auxiliaryTable$).subscribe(res => {
			this.auxiliaryTable = res.data;
		});
		if (!this.permissionUtilService.isOrganizationCodeItem(this.user)) {
			this.utilStoreService.retrieveSyncState<OrganizationPouchModel[]>(this.organizationList$).subscribe(res => {
				const organization: OrganizationStateModel = res.data.find(x => x.code_item === organizationId);
				if (organization) {
					this.store.dispatch(OrganizationStateAction.update(new BaseState(organization)));
				} else {
					this.router.navigate(['/not-found']);
				}
			});
		}
	}

	// subscribe

	initMandatoryData() {
		return this.organization$.pipe(
			filter(
				(store: BaseStateModel<OrganizationPouchModel>) =>
					store && store.data && store.type !== OrganizationActionEnum.LOAD
			),
			mergeMap((organization: BaseStateModel<OrganizationPouchModel>) => {
				switch (organization.type) {
					case OrganizationActionEnum.ERROR:
						throw new Error(OrganizationActionEnum.ERROR);
					case OrganizationActionEnum.UPDATE:
						this.setOrganizationRelatedData(organization.data);
						break;
				}
				return this.articleList$;
			}),
			filter((articleList: BaseStateModel<ArticlePouchModel[]>) => !!(articleList && articleList.data)),
			mergeMap((articleList: BaseStateModel<ArticlePouchModel[]>) => {
				if (articleList.type === OrganizationActionEnum.ERROR) {
					throw new Error(OrganizationActionEnum.ERROR);
				}
				if (this.orderId === 'new') {
					this.store.dispatch(OrderStateAction.update(new BaseState(new OrderStateModel())));
				} else {
					this.store.dispatch(OrderStateAction.load(new BaseState(this.orderId)));
				}
				return this.order$;
			}),
			filter(
				(order: BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>>) =>
					this.organization &&
					order &&
					order.data &&
					!(order.type === OrderActionEnum.LOAD || order.type === OrderActionEnum.REMOVE)
			),
			map((order: BaseStateModel<OrderStateModel>) => {
				const urlStringArrayCheckout = [
					PATH_URL.PRIVATE,
					ROUTE_URL.orders,
					this.orderStatus,
					this.organization.code_item,
					order.data._id,
					ROUTE_URL.checkout
				];
				switch (order.type) {
					case OrderActionEnum.ERROR:
						throw new Error(OrderActionEnum.ERROR);
					case OrderActionEnum.REMOVED:
						this.goBack();
						break;
					case OrderActionEnum.UPDATE:
						if (order.data._id) {
							if (this.utilOrderService.orderChangeTemp && order.data._rev) {
								this.utilOrderService.orderChangeTemp._rev = order.data._rev;
							}
							let urlStringArrayDynamic: string[];
							let urlCurrentId: string = this.router.url.replace('/new/', `/${order.data._id}/`);
							const urlCurrentIdArray = urlCurrentId.split('/');
							urlCurrentIdArray.splice(0, 1);
							if (this.order && this.order._id && this.order._id !== order.data._id) {
								urlStringArrayDynamic = urlStringArrayCheckout;
							} else {
								urlStringArrayDynamic = urlCurrentIdArray;
							}
							this.setPage(order.data, urlStringArrayDynamic);
						}
						this.setOrder(order.data);
						this.setModelFormValueFromOrder();
						this.showAvailabilityAlerts(this.order);
						break;
					case OrderActionEnum.COMPLETED:
						this.setPage(order.data, urlStringArrayCheckout);
						this.setOrder(order.data);
						this.setModelFormValueFromOrder();
						this.showAvailabilityAlerts(this.order);
						break;
				}
				return this.order;
			})
		);
	}

	subscribeManagerInit() {
		this.subscribeManagerService.populate(
			this.initMandatoryData().subscribe(
				res => {},
				error => {
					console.log('something went wrong ', error);
				}
			),
			'order-data'
		);
		this.subscribeManagerService.populate(
			this.utilOrderService.propagationSubmitForm.subscribe(res => {
				if (res) {
					this.form.markAllAsTouched();
				}
			}),
			'submit-form'
		);
	}

	setTabsToExclude() {
		if (this.orderStatus) {
			switch (OrderStatusEnum[this.orderStatus.toUpperCase()]) {
				// tabs: catalogo, checkout
				case OrderStatusEnum.DRAFT:
				case OrderStatusEnum.NOT_AUTHORIZED:
					this.tabsToExclude = ['variation'];
					break;
				// tabs: checkout, variazioni
				case OrderStatusEnum.CONSOLIDATED:
				case OrderStatusEnum.PARTIALLY_FULFILLED:
				case OrderStatusEnum.FULFILLED:
					this.tabsToExclude = ['catalog', 'variation'];
					break;
				// tab: nessuna
				default:
					this.tabsToExclude = ['catalog', 'checkout', 'variation'];
			}
		}
	}

	setOrganizationRelatedData(organization: OrganizationStateModel) {
		this.organization = organization;
		this.destinationList = this.organization.destination_list;
		if (this.destinationList) {
			this.destinationList = this.destinationList
				.sort((a, b) => (a.code_item > b.code_item ? 1 : -1))
				.filter(destination => destination.valid);
			this.destinationList.map(
				destination => (destination.complete = this.utilOrderService.getFormattedAddress(destination.address))
			);
		}
		if (!this.permissionUtilService.isOrganizationCodeItem(this.user)) {
			// TODO: sostituire con nuovo meccanismo di dispatch
			// this.utilStoreService.dispatchArticleRecap(
			// 	this.organization.code_item,
			// 	this.organization
			// );
		}
		const divisionList = this.organization.division_list ? this.organization.division_list : [];
		this.paymentDivision = this.utilOrderService.filterPaymentDivisionList(
			divisionList,
			this.auxiliaryTable.paymentList
		);
		this.paymentDefault = this.utilOrderService.filterPaymentDivisionList(
			this.utilOrderService.agreedPaymentDefault,
			this.auxiliaryTable.paymentList
		);
		this.paymentList = [
			{ name: 'Pagamenti', payment: this.paymentDivision },
			{ name: 'Pagamenti Concordati', payment: this.paymentDefault }
		];
	}

	setPage(order: OrderStateModel, url: string[]) {
		this.orderStatus = order.header.status;
		this.setTabsToExclude();
		this.router.navigate(url);
	}

	setOrder(order: OrderStateModel) {
		this.order = order;
		if (!this.getCanEditOrder()) {
			this.form.disable({ emitEvent: false });
		} else if (!this.order._id) {
			this.utilOrderService.setOrderNewDraft(this.order, this.organization);
			if (this.user.current_permission.context_application === ContextApplicationItemCodeEnum.AGENT) {
				this.order.header.code_agent = this.user.current_permission.context_code.code;
			}
		} else if (this.order.header.status === OrderStatusEnum.DRAFT && this.order._id) {
			this.form.enable({ emitEvent: false });
			if (this.eventuallyUpdateDraftOrder()) {
				this.store.dispatch(OrderStateAction.save(new BaseState(_.cloneDeep(this.order))));
			}

		}

		this.breadcrumbPathList = [
			'Home',
			this.translate.instant('theme.order.name_plural'),
			this.order._id ? this.guidFormatterPipe.transform(this.order._id) : 'Nuovo',
			this.order.header.date ? this.datePipe.transform(this.order.header.date) : ''
		];

		this.setRouteMetaInformation();
		this.statusBarConfig = this.statusBardOrderService.returnStatusBarList(
			ConfigurationCustomer.Order.statusBarMap[this.user.current_permission.context_application],
			this.order.header.status
		);
	}

	getCanEditOrder() {
		if (
			this.order.header.status === OrderStatusEnum.DRAFT ||
			this.order.header.status === OrderStatusEnum.NOT_AUTHORIZED
		) {
			return true;
		}
		if (
			this.user.current_permission.context_application === ContextApplicationItemCodeEnum.BACKOFFICE &&
			OrderBackoffice.filter(el => el.status === this.order.header.status).map(el => el.can_edit)[0]
		) {
			return true;
		}
		return false;
	}

	createForm() {
		this.form = this.fb.group({
			customer_order_ref_code: [{ value: '' }],
			customer_order_ref_date: [{ value: '' }],
			first_evasion_date: [{ value: '' }, Validators.required],
			note_order: [{ value: '' }],
			order_causal: [{ value: '' }, Validators.required],
			payment_code: [{ value: '' }, Validators.required],
			goods_destination_code: [{ value: '' }]
		});
	}

	setModelFormValueFromOrder() {
		this.form.patchValue(
			{
				customer_order_ref_code: this.order.header.customer_order_ref_code,
				customer_order_ref_date: this.order.header.customer_order_ref_date
					? moment(this.order.header.customer_order_ref_date)
					: null,
				first_evasion_date: moment(this.order.header.first_evasion_date),
				note_order: this.order.header.note_order,
				order_causal: this.order.header.order_causal,
				payment_code: this.order.header.payment_code,
				goods_destination_code: this.order.header.goods_destination_code
			},
			{ emitEvent: false }
		);

		// definisce l'eventuale validità del form di testata, in modo da abilitare il button di invio
		this.utilOrderService.formHeaderValiditySource.next(this.form.valid);
		if (!this.subscribeManagerService.hasSubscription('change-form')) {
			this.onChangeForm();
		}
	}

	onChangeForm() {
		this.subscribeManagerService.populate(
			this.form.valueChanges.pipe(debounceTime(1500)).subscribe(async formChange => {
				this.utilOrderService.orderChangeTemp = this.utilOrderService.orderChangeTemp
					? this.utilOrderService.orderChangeTemp
					: this.order;
				const customer_order_ref_date = formChange['customer_order_ref_date'] as Moment;
				const first_evasion_date = formChange['first_evasion_date'] as Moment;
				if (formChange['goods_destination_code']) {
					this.utilOrderService.orderChangeTemp.header.goods_destination_code =
						formChange['goods_destination_code'];
					this.utilOrderService.setOrderHeaderDestinationObject(
						this.utilOrderService.orderChangeTemp,
						this.organization.destination_list
					);
				}
				this.utilOrderService.orderChangeTemp.header.payment_code = formChange['payment_code'];
				this.utilOrderService.setOrderHeaderObject<PaymentPouchModel>(
					'payment_code',
					'payment_object',
					this.utilOrderService.orderChangeTemp,
					this.auxiliaryTable.paymentList
				);
				this.utilOrderService.orderChangeTemp.header.customer_order_ref_code =
					formChange['customer_order_ref_code'];
				this.utilOrderService.orderChangeTemp.header.customer_order_ref_date = customer_order_ref_date
					? customer_order_ref_date.valueOf()
					: null;
				this.utilOrderService.orderChangeTemp.header.first_evasion_date = first_evasion_date
					? first_evasion_date.valueOf()
					: null;
				this.utilOrderService.orderChangeTemp.header.order_causal = formChange['order_causal'];
				this.utilOrderService.setOrderHeaderObject<CausalHeaderSoPouchModel>(
					'order_causal',
					'order_causal_object',
					this.utilOrderService.orderChangeTemp,
					this.auxiliaryTable.causalHeaderSoList
				);
				this.utilOrderService.orderChangeTemp.header.note_order = formChange['note_order'];
				this.utilOrderService.orderChangeTemp.header.division = this.utilService.returnIsMainOfList<DivisionPouchModel>(
					this.organization.division_list
				).division;
				const cloneOrder = _.cloneDeep(this.utilOrderService.orderChangeTemp);
				this.utilOrderService.updateOrderSource.next({
					order: cloneOrder,
					useLoader: false
				});
				this.utilOrderService.formHeaderValiditySource.next(this.form.valid);
			}),
			'change-form'
		);
	}

	dialogConfirmOrderClone() {
		const dialogRef = this.dialog.open(DialogActionsComponent, {
			data: {
				title: `${this.sentenceCasePipe.transform(this.translate.instant('general.duplicate'))}
				${this.sentenceCasePipe.transform(this.translate.instant('order.name'))}`,
				text: this.sentenceCasePipe.transform(this.translate.instant('order.dialog.clone.text')),
				actions: [
					{
						key: OrderCloneTypeEnum.HEADER,
						text: 'order.dialog.clone.header_option'
					},
					{
						key: OrderCloneTypeEnum.HEADER_AND_ROWS,
						text: 'order.dialog.clone.header_and_rows_option'
					}
				]
			},
			disableClose: true,
			panelClass: 'dialog-normal'
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.utilOrderService.cloneOrder(this.order, res);
			}
		});
	}

	dialogConfirmOrderDelete() {
		const dialog = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: this.sentenceCasePipe.transform(this.translate.instant('order.action.delete')),
				text: this.sentenceCasePipe.transform(this.translate.instant('order.question.delete'))
			}
		});
		dialog.afterClosed().subscribe(res => {
			if (res) {
				if (this.order._id) {
					this.store.dispatch(OrderStateAction.remove(new BaseState(this.order)));
				}
			}
		});
	}

	generatePDF() {
		this.resumeService.getPdf(this.order, { document_type: 'order' }).then(res => {
			let base64Data = res.data;
			const fileType: string = 'pdf';
			UtilDownload.downloadBase64(base64Data, fileType, `order.${fileType}`);
		});
	}

	goBack() {
		const goBakPath = this.getGoBackPath();
		if (goBakPath) {
			this.router.navigate([goBakPath]);
		}

		// const statusRelatedMap: StateRelatedLink[] =
		// 	ConfigurationCustomer.Order.statusAggregationMap[
		// 		ContextApplicationItemCodeEnum[this.user.current_permission.context_application]
		// 	];
		// const stateRelatedLink = statusRelatedMap.find(i => i.related_list.includes(this.order.header.status));
		// if (stateRelatedLink) {
		// 	this.router.navigate([PATH_URL.PRIVATE, ROUTE_URL.orders, stateRelatedLink.state.toLowerCase()]);
		// }
	}

	getGoBackPath() {
		const statusRelatedMap: StateRelatedLink[] =
			ConfigurationCustomer.Order.statusAggregationMap[
				ContextApplicationItemCodeEnum[this.user.current_permission.context_application]
			];
		const stateRelatedLink = statusRelatedMap.find(i => i.related_list.includes(this.order.header.status));
		return stateRelatedLink
			? PATH_URL.PRIVATE + '/' + ROUTE_URL.orders + '/' + stateRelatedLink.state.toLowerCase()
			: null;
	}

	showAvailabilityAlerts(order: OrderStateModel) {
		const orderProductList: ArticlePouchModel[] = [];
		order.product_list.forEach(product =>
			product ? orderProductList.push(product) : this.orderProductListNotFound.push(product)
		);
		this.setTodayMinusFiveDaysDate =
			!order.header.first_evasion_date || moment(order.header.first_evasion_date).diff(moment(), 'd') < 5;
		this.setProductPartiallyAvailable =
			orderProductList.findIndex(
				prod => prod.articleDescription.cf_available === ArticleEnum.Status.Available.LOW
			) !== -1;
		this.setProductNotAvailable =
			orderProductList.findIndex(
				prod => prod.articleDescription.cf_available === ArticleEnum.Status.Available.NOT_AVAILABLE
			) !== -1;
		if (order.header.first_evasion_date) {
			this.showAlert(moment(order.header.first_evasion_date));
		}
	}

	showAlert(date: Moment) {
		const orderCompositionDate = moment(this.order.header.date, 'DD/MM/YYYY');
		const isSameDay = orderCompositionDate.diff(date, 'd', true) === 0;
		const isBeforeMidday = ConfigurationOrder.UtilDate.today.getHours() - 12 < 0;

		if (isSameDay && isBeforeMidday) {
			this.setTodayDate = true;
		} else {
			this.setTodayDate = false;
		}
	}

	getOrderCausal(code_item: string) {
		return this.auxiliaryTable.causalHeaderSoList.find(causalHeaderSo => causalHeaderSo.code_item === code_item);
	}

	convertMilllisStringToStringDate(stringMillis: string, format: string = 'DD/MM/YYYY'): string {
		return this.dateMomentService.convertStringDateFromFormatToFormat(stringMillis, 'x', format);
	}

	eventuallyUpdateDraftOrder(): boolean {
		let diffFound = false;
		if (this.order.header.goods_destination_code) {
			if (!this.destinationList.find(i => i.code_item === this.order.header.goods_destination_code)) {
				this.utilOrderService.setOrderHeaderDestinationDefault(this.order, this.organization.destination_list);
				diffFound = true;
			}
		}
		if (this.order.header.payment_code) {
			if (!this.auxiliaryTable.paymentList.find(i => i.code_item === this.order.header.payment_code)) {
				const paymentCode = this.utilOrderService.getOrganizationPreference(
					'payment_condition',
					this.auxiliaryTable.paymentList,
					this.organization
				);
				// TODO: verificare se il metodo di pagamento preferito è sempre presente
				if (paymentCode) {
					this.order.header.payment_code = paymentCode;
					this.utilOrderService.setOrderHeaderObject<PaymentPouchModel>(
						'payment_code',
						'payment_object',
						this.order,
						this.auxiliaryTable.paymentList
					);
				} else {
					delete this.order.header.payment_code;
					delete this.order.header.payment_object;
				}
				diffFound = true;
			}
		}
		if (this.order.header.order_causal) {
			if (!this.auxiliaryTable.causalHeaderSoList.find(i => i.code_item === this.order.header.order_causal)) {
				this.order.header.order_causal =
					ConfigurationCustomer.Order.causalCode[this.user.current_permission.context_application];
				this.utilOrderService.setOrderHeaderObject<CausalHeaderSoPouchModel>(
					'order_causal',
					'order_causal_object',
					this.order,
					this.auxiliaryTable.causalHeaderSoList
				);
				diffFound = true;
			}
		}
		const isBeforeToday: boolean =
			moment(this.order.header.first_evasion_date)
			.diff(
				ConfigurationCustomer.Order.Date.minDateSelectable[this.user.current_permission.context_application],
				'd',
				false
			) < 0;
		if (isBeforeToday) {
			this.order.header.first_evasion_date =
				moment(ConfigurationCustomer.Order.Date.minDateSelectable[this.user.current_permission.context_application])
				.valueOf();
			diffFound = true;
		}
		if (diffFound) {
			this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
				duration: 5000,
				data: { message: this.translate.instant('order.validation.updated_data') }
			});
		}
		return diffFound;
	}

	/**
	 * BACKOFFICE CONTEXT
	 */

	checkOrganizationForOrderValidation() {
		if (this.organization) {
			switch (this.organization.level) {
				case OrganizationLevelEnum.LEAD:
					this.organizationToBeValidated = true;
					break;
				case OrganizationLevelEnum.PROSPECT:
				case OrganizationLevelEnum.CUSTOMER:
					this.dialogConfirmOrderValidation();
					break;
			}
		}
	}

	// Valida ordine
	dialogConfirmOrderValidation() {
		const title = 'Valida ordine';
		const text = "Sei sicuro di voler validare l'ordine?";
		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: title,
				text: text,
				disableClose: true,
				panelClass: 'dialog-medium'
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.utilOrderService.orderChangeTemp = this.utilOrderService.orderChangeTemp
					? this.utilOrderService.orderChangeTemp
					: this.order;
				console.log(res);
				// Modifica livello di organizzazione e salva
				if (this.organization.level === OrganizationLevelEnum.PROSPECT) {
					const updatedOrganization = _.cloneDeep(this.organization);
					updatedOrganization.level = OrganizationLevelEnum.CUSTOMER;
					this.store.dispatch(OrganizationStateAction.update(new BaseState(updatedOrganization)));
				}
				// Modifica ordine e salva
				this.utilOrderService.orderChangeTemp.header.status = OrderStatusEnum.READY_TO_SEND;
				this.utilOrderService.orderChangeTemp.header.submission_date = new Date().getTime();
				this.store.dispatch(
					OrderStateAction.save(new BaseState(_.cloneDeep(this.utilOrderService.orderChangeTemp)))
				);
			}
		});
	}
	/**
	 * END BACKOFFICE CONTEXT
	 */

	setRouteMetaInformation() {
		let title: BreadcrumbTranslateItemModel;
		if (this.order && this.order._id) {
			title = {
				value: 'order.number',
				param: {
					codeItem: this.guidFormatterPipe.transform(this.order._id)
				}
			};
		} else {
			title = {
				value: 'order.new',
				param: null
			};
		}
		this.utilBreadcrumbService.title = title;
		this.utilBreadcrumbService.subtitle.value =
		this.organization.code_erp == undefined ? this.organization.business_name : `(${this.organization.business_name} - ${this.organization.code_erp})`;
	}

}
