import { AdditionalServiceModule } from './state/additional-service/additional-service.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Inject, Injector, NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ResourceActionReturnType, ResourceGlobalConfig } from '@ngx-resource/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { MediaCenterModule } from '@saep-ict/media-center';
import { TicketCenterModule } from '@saep-ict/ticket-center';
import { PouchdbModule } from '@saep-ict/pouch-db';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { LocalStorageService, NgxWebstorageModule } from 'ngx-webstorage';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { CustomerAppConfig } from './customer-app.config';
import { FrameModule } from './frame/frame.module';
import { PageModule } from './page/page.module';
import { RoutingModule } from './router/app-routing.module';
import { AuthLoginGuard } from './service/guard/auth-login.guard';
import { AuthTokenGuard } from './service/guard/auth-token.guard';
import { AuthUserStateGuard } from './service/guard/auth-user-state.guard';
import { UtilGuardService } from './service/guard/util-guard/util-guard.service';
import { VerifyTokenEmailGuard } from './service/guard/verify-token-email.guard';
import { PouchDbSpin8AgentAdapter } from './service/pouch-db/spin8/pouchdb-spin8-agent.adapter';
import { AngularSpin8CoreProbeTestService } from '@saep-ict/angular-spin8-core';
import { LoaderService, SaepICTAngularCoreModule, TranslateMultiHttpLoader } from '@saep-ict/angular-core';
import { SaepICTAngularSpin8CoreModule } from '@saep-ict/angular-spin8-core';
import { ContactStateModule } from './state/contact/contact-state.module';
import { ContactEffects } from './state/contact/contact.effects';
import { ArticleEffects } from './state/article/article.effects';
import { ArticleStateModule } from './state/article/article-state.module';
import { AuxiliaryTableStateModule } from './state/auxiliary-table/auxiliary-table-state.module';
import { AuxiliaryTableEffects } from './state/auxiliary-table/auxiliary-table.effects';
import { CategoryListStateModule } from './state/category-list/category-list-state.module';
import { CategoryListEffects } from './state/category-list/category-list.effects';
import { CompanyAccountBalanceStateModule } from './state/company-account-balance/company-account-balance-state.module';
import { CompanyAccountBalanceEffects } from './state/company-account-balance/company-account-balance.effects';
import { OrganizationStateModule } from './state/organization/organization-state.module';
import { OrganizationEffects } from './state/organization/organization.effects';
import { OrderListStateModule } from './state/order-list/order-list-state.module';
import { OrderListEffects } from './state/order-list/order-list.effects';
import { OrderStateModule } from './state/order/order-state.module';
import { OrderEffects } from './state/order/order.effects';
import { UserActivateStateModule } from './state/user-activate/user-activate-state.module';
import { UserActivateEffects } from './state/user-activate/user-activate.effects';
import { UserStateModule } from './state/user/user-state.module';
import { UserEffects } from './state/user/user.effects';
import { UserManagementStateModule } from './state/backoffice/user-management/user-management-state.module';
import { UserManagementEffects } from './state/backoffice/user-management/user-management.effects';
import { OrganizationListStateModule } from './state/common/organization-list/organization-list-state.module';
import { OrganizationListEffects } from './state/common/organization-list/organization-list.effects';
import { ContextCodeAssociationEffects } from './state/backoffice/context-code/context-code-association/context-code-association.effects';
import { ContextCodeAssociationStateModule } from './state/backoffice/context-code/context-code-association/context-code-association-state.module';
import { PermissionAuxiliaryTableStateModule } from './state/permission-auxiliary-table/permission-auxiliary-table-state.module';
import { PermissionAuxiliaryTableEffects } from './state/permission-auxiliary-table/permission-auxiliary-table.effects';
import { OpportunityStateModule } from './state/opportunity/opportunity-state.module';
import { ActionStateModule } from './state/action/action-state.module';
import { OpportunityEffects } from './state/opportunity/opportunity.effects';
import { CookieService } from 'ngx-cookie-service';
import { UserResolver } from './service/resolvers/user.resolver';
import { ConfigurationEffects } from './state/configuration/configuration.effects';
import { ConfigurationStateModule } from './state/configuration/configuration-state.module';
import { LoginContextCodeEffects } from './state/common/login/context-code/login-context-code.effects';
import { LoginContextCodeStateModule } from './state/common/login/context-code/login-context-code.module';
import { SearchResultsStateModule } from './state/search-results/search-results-state.module';
import { SearchResultsEffects } from './state/search-results/search-results.effects';
import { CheckoutGuard } from './service/guard/checkout.guard';
import { StatisticsAgentStateModule } from './state/statistics-agent/statistics-agent.state.module';
import { StatisticsAgentEffects } from './state/statistics-agent/statistics-agent.effect';
import { StatisticsOrdersStateModule } from './state/statistics-orders/statistics-orders.state.module';
import { StatisticsOrdersEffects } from './state/statistics-orders/statistics-orders.effect';
import { LOCATION_INITIALIZED } from '@angular/common';
import { ConnectionActionEnum, ConnectionStateAction } from './state/connection/connection.actions';
import { StateFeature } from './state';
import { filter, take } from 'rxjs/operators';
import { ConnectionEffects } from './state/connection/connection.effects';
import { PouchAdapterSelectorService } from './service/pouch-db/pouch-adapter-selector.service';
import { PouchDbAgentAdapter } from './service/pouch-db/spin8/pouchdb-agent.adapter';
import { PouchDbOrganizationB2CAdapter } from './service/pouch-db/spin8/pouchdb-organization-b2c.adapter';
import { PouchDbCommonsAdapter } from './service/pouch-db/spin8/pouchdb-commons.adapter';
import { ConnectionStateModule } from './state/connection/connection-state.module';
import { ReceiptListEffects } from './state/receipt-list/receipt-list.effects';
import { ReceiptListStateModule } from './state/receipt-list/receipt-list-state.module';
import { InformativePageStateModule } from './state/informative-page/informative-page-state.module';
import { InformativePageEffects } from './state/informative-page/informative-page.effects';
import { KanbanEffects } from './state/kanban/kanban.effects';
import { KanbanStateModule } from './state/kanban/kanban-state.module';

import { Plugins } from '@capacitor/core';
import { EnvConfiguratorModule } from '@saep-ict/env-configurator';
import { PouchDbBackofficeAdapter } from './service/pouch-db/spin8/pouchdb-backoffice.adapter';
import { PouchDbCommonsOrganizationAdapter } from './service/pouch-db/spin8/pouchdb-commons-organization.adapter';
import { PouchDbCommonsArticleAdapter } from './service/pouch-db/spin8/pouchdb-commons-article.adapter';
import { PouchDbCommonsOrderAdapter } from './service/pouch-db/spin8/pouchdb-commons-order.adapter';
import { PouchDbCommonsUserAdapter } from './service/pouch-db/spin8/pouchdb-commons-user.adapter';
import { PouchDbCommonsAgentAdapter } from './service/pouch-db/spin8/pouchdb-commons-agent.adapter';
import { PouchDbCommonsBackofficeAdapter } from './service/pouch-db/spin8/pouchdb-commons-backoffice.adapter';
import { PouchDbCrmAdapter } from './service/pouch-db/spin8/pouchdb-crm.adapter';
import { PouchDbCommonsRequestAdapter } from './service/pouch-db/spin8/pouchdb-commons-request';
import { StatisticsOrganizationEffects } from './state/statistics-organization/statistics-organization.effect';
import { StatisticsOrganizationStateModule } from './state/statistics-organization/statistics-organization.state.module';
import { StatisticsBackofficeStateModule } from './state/statistics-backoffice/statistics-backoffice.state.module';
import { StatisticsBackofficeEffects } from './state/statistics-backoffice/statistics-backoffice.effect';
import { StatisticsDetailOrdersEffects } from './state/statistics-detail-orders/statistics-detail-orders.effect';
import { StatisticsDetailOrdersStateModule } from './state/statistics-detail-orders/statistics-detail-orders.state.module';
import { StatisticsCrmEffects } from './state/statistics-crm/statistics-crm.effect';
import { StatisticsCrmStateModule } from './state/statistics-crm/statistics-crm.state.module';
import { StatisticsDetailSoldStateModule } from './state/statistics-detail-sold/statistics-detail-sold.state.module';
import { StatisticsDetailSoldEffects } from './state/statistics-detail-sold/statistics-detail-sold.effect';
import { StatisticsDetailClientsStateModule } from './state/statistics-detail-clients/statistics-detail-clients.state.module';
import { StatisticsDetailClientsEffects } from './state/statistics-detail-clients/statistics-detail-clients.effect';
import { StatisticsDetailExpiredStateModule } from './state/statistics-detail-expired/statistics-detail-expired.state.module';
import { StatisticsDetailExpiredEffects } from './state/statistics-detail-expired/statistics-detail-expired.effect';
import { PouchDbCommonsContactAdapter } from './service/pouch-db/spin8/pouchdb-commons-contact.adapter';
import { PouchDbOrganizationB2BAdapter } from './service/pouch-db/spin8/pouchdb-organization-b2b.adapter';
import { LanguageListStateModule } from './state/language-list/language-list-state.module';
import { LanguageListEffects } from './state/language-list/language-list.effects';
import { ArticleDescriptionEffects } from './state/article-description/article-description.effects';
import { ArticleDescriptionStateModule } from './state/article-description/article-description-state.module';
import { Language } from './enum/language.enum';
import { PouchDbOrganizationPortalAdapter } from './service/pouch-db/spin8/pouchdb-organization-portal.adapter';
import { ContextCodeManagementStateModule } from './state/backoffice/context-code/context-code-management/context-code-management-state.module';
import { ContextCodeManagementEffects } from './state/backoffice/context-code/context-code-management/context-code-management.effects';
import { PouchDbCommonsCrmAdapter } from './service/pouch-db/spin8/pouchdb-commons-crm.adapter';
import { ActionEffects } from './state/action/action.effects';
import { AuxiliaryTableGeographicTreeStateModule } from './state/auxiliary-table/auxiliary-table-geographic-tree/auxiliary-table-geographic-tree-state.module';
import { AuxiliaryTableGeographicTreeEffects } from './state/auxiliary-table/auxiliary-table-geographic-tree/auxiliary-table-geographic-tree.effects';
import { AdditionalServiceEffects } from './state/additional-service/additional-service.effects';
import { NewsListStateModule } from './state/news-list/news-list-state.module';
import { NewsListEffects } from './state/news-list/news-list.effects';
import { StatisticStateModule } from './state/statistic/statistic-state.module';
import { StatisticEffects } from './state/statistic/statistic.effect';
import { StatisticGroupStateModule } from './state/statistic/statistic-group/statistic-group-state.module';
import { StatisticGroupEffects } from './state/statistic/statistic-group/statistic-group.effect';
import { ArticleStockStateModule } from './state/article-stock/article-stock-state.module';
import { ArticleStockEffects } from './state/article-stock/article-stock.effects';
import { NewsDetailStateModule } from './state/news-detail/news-detail-state.module';
import { NewsDetailEffects } from './state/news-detail/news-detail.effects';
import { ForecastStateModule } from './state/forecast/forecast-state.module';
import { ForecastEffects } from './state/forecast/forecast.effect';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { ErrorHandler} from "@angular/core";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { CategoryDetailStateModule } from './state/category-detail/category-detail-state.module';
import { CategoryDetailEffects } from './state/category-detail/category-detail.effects';
import { SharedModule } from './shared/shared.module';


const { Network } = Plugins;

// AoT requires an exported function for factories
export function httpLoaderFactory(http: HttpClient, customerAppConfig: CustomerAppConfig) {
	return new TranslateMultiHttpLoader(http, customerAppConfig, [
		// project bundle
		{ prefix: './assets/common/i18n/', suffix: '.json' },
		// themes bundle (frontend/src/themes/*/i18n
		{ prefix: './assets/theme-current/i18n/', suffix: '.json' },
		// lib bundle
		{ configKey: 'saepIctAngularCore_config' },
		{ configKey: 'saepIctAngularSpin8Core_config' },
		{ configKey: 'mc_config' },
		{ configKey: 'tc_config' }
	]);
}

export function getConnection(store: Store<any>) {
	return () =>
		new Promise(resolve => {
			// store.dispatch(new StartAppInitializer());
			store.dispatch(ConnectionStateAction.load());
			store
				.select(StateFeature.getConnectionState)
				.pipe(
					filter(
						connection => connection && connection.data && connection.type === ConnectionActionEnum.UPDATE
					),
					take(1)
				)
				.subscribe(connection => {
					// store.dispatch(new FinishAppInitializer());
					resolve(true);
				});
		});
}

export function appInitializerFactory(
	translate: TranslateService,
	injector: Injector,
	customerAppConfig: CustomerAppConfig,
	localStorageService: LocalStorageService
) {
	return () =>
		new Promise<any>((resolve: any) => {
			customerAppConfig.libInitializationDone$.subscribe((e: boolean) => {
				if (e) {
					const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
					locationInitialized.then(() => {
						const langToSet = localStorageService.retrieve('language') || Language.DEFAULT;
						translate.setDefaultLang(langToSet);
						translate.use(langToSet).subscribe(
							() => {
								// console.info(`Successfully initialized '${langToSet}' language.'`);
							},
							err => {
								console.error(`Problem with '${langToSet}' language initialization.'`);
							},
							() => {
								resolve(null);
							}
						);
					});
				}
			});
		});
}

export function initializeKeycloak(keycloak: KeycloakService, customerAppConfig: CustomerAppConfig) {
	return () => {
		// console.info('=== KEYCLOAK ====', 'APP_INITIALIZER -> initializeKeycloak');
		return keycloak.init({
			...{
				initOptions: {
					onLoad: 'check-sso',
					silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
				}
				// shouldAddToken: request => {
				// 	const { method, url } = request;

				// 	//const isGetRequest = 'GET' === method.toUpperCase();
				// 	// const acceptablePaths = ['/demo-test'];
				// 	// const isAcceptablePathMatch = acceptablePaths.some(path => url.includes(path));
				// 	// return !(isGetRequest && isAcceptablePathMatch);

				// 	const acceptablePaths = ['/public/catalog/'];
				// 	const isAcceptablePathMatch = acceptablePaths.some(path => url.includes(path));
				// 	if (isAcceptablePathMatch) {
				// 		//	debugger;
				// 		console.log('Keycloak URL -> ' + url);
				// 	}
				// 	return isAcceptablePathMatch;
				// }
				// bearerExcludedUrls: ['/assets', '/api']
				// shouldUpdateToken: request => {
				// 	return !(request.headers.get('token-update') === 'false');
				// }
			},
			config: customerAppConfig.config.sso
		});
	};
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		// SharedModule,
		BrowserModule.withServerTransition({ appId: 'spin8' }),
		BrowserAnimationsModule,
		RoutingModule,
		NgScrollbarModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient, CustomerAppConfig]
			}
		}),

		NgxWebstorageModule.forRoot(),
		// EnvironmentFunction
		EnvConfiguratorModule.forRoot({ env: environment, appConfig: CustomerAppConfig }),
		// CouchFunction
		PouchdbModule.forRoot({
			config: [
				PouchDbSpin8AgentAdapter,
				PouchDbAgentAdapter,
				PouchDbOrganizationB2CAdapter,
				PouchDbOrganizationB2BAdapter,
				PouchDbOrganizationPortalAdapter,
				PouchDbCommonsAdapter,
				PouchDbBackofficeAdapter,
				PouchDbCrmAdapter,
				PouchDbCommonsRequestAdapter,
				PouchDbCommonsOrganizationAdapter,
				PouchDbCommonsArticleAdapter,
				PouchDbCommonsOrderAdapter,
				PouchDbCommonsUserAdapter,
				PouchDbCommonsAgentAdapter,
				PouchDbCommonsBackofficeAdapter,
				PouchDbCommonsContactAdapter,
				PouchDbCommonsCrmAdapter
			],
			appConfig: CustomerAppConfig
		}),
		// NgRx - Store
		StoreModule.forRoot(
			{},
			{
				runtimeChecks: {
					strictStateImmutability: false,
					strictActionImmutability: false
				}
			}
		),
		ConnectionStateModule,
		UserStateModule,
		OrganizationStateModule,
		ContactStateModule,
		AdditionalServiceModule,
		UserActivateStateModule,
		OrderStateModule,
		OrderListStateModule,
		AuxiliaryTableStateModule,
		AuxiliaryTableGeographicTreeStateModule,
		ForecastStateModule,
		StatisticStateModule,
		StatisticGroupStateModule,
		StatisticsAgentStateModule,
		StatisticsOrganizationStateModule,
		StatisticsBackofficeStateModule,
		StatisticsCrmStateModule,
		StatisticsDetailOrdersStateModule,
		StatisticsDetailSoldStateModule,
		StatisticsDetailClientsStateModule,
		StatisticsDetailExpiredStateModule,
		StatisticsOrdersStateModule,
		ArticleStateModule,
		ArticleDescriptionStateModule,
		CategoryListStateModule,
		CategoryDetailStateModule,
		OrganizationListStateModule,
		ReceiptListStateModule,
		InformativePageStateModule,
		LanguageListStateModule,
		NewsListStateModule,
		NewsDetailStateModule,

		// B2C
		ConfigurationStateModule,
		SearchResultsStateModule,

		// Backoffice
		UserManagementStateModule,
		CompanyAccountBalanceStateModule,
		ContextCodeAssociationStateModule,
		PermissionAuxiliaryTableStateModule,
		ContextCodeManagementStateModule,
		ArticleStockStateModule,

		// CRM
		OpportunityStateModule,
		ActionStateModule,
		// OfferStateModule,

		// Kanban
		KanbanStateModule,

		// login
		LoginContextCodeStateModule,
		EffectsModule.forRoot([
			ConnectionEffects,
			OrganizationEffects,
			UserEffects,
			UserActivateEffects,
			OrderEffects,
			OrderListEffects,
			AuxiliaryTableEffects,
			AuxiliaryTableGeographicTreeEffects,
			ContactEffects,
			AdditionalServiceEffects,
			StatisticEffects,
			StatisticGroupEffects,
			ForecastEffects,
			StatisticsAgentEffects,
			StatisticsOrganizationEffects,
			StatisticsBackofficeEffects,
			StatisticsOrdersEffects,
			ArticleEffects,
			ArticleDescriptionEffects,
			CategoryListEffects,
			CategoryDetailEffects,
			OrganizationListEffects,
			InformativePageEffects,
			LanguageListEffects,
			StatisticsDetailOrdersEffects,
			StatisticsDetailSoldEffects,
			StatisticsDetailClientsEffects,
			StatisticsDetailExpiredEffects,
			StatisticsCrmEffects,
			ReceiptListEffects,
			NewsListEffects,
			NewsDetailEffects,

			// Backoffice
			UserManagementEffects,
			CompanyAccountBalanceEffects,
			ContextCodeAssociationEffects,
			PermissionAuxiliaryTableEffects,
			ContextCodeManagementEffects,
			ArticleStockEffects,

			// B2C
			ConfigurationEffects,
			SearchResultsEffects,

			// CRM
			OpportunityEffects,
			ActionEffects,
			// OfferEffects,

			// Kanban
			KanbanEffects,

			// login
			LoginContextCodeEffects
		]),
		StoreDevtoolsModule.instrument({
			maxAge: 25, // Retains last 25 states
			logOnly: environment.production // Restrict extension to log-only mode
		}),
		// structure modules
		FrameModule,
		PageModule,
		SharedModule,
		// saep-ict
		SaepICTAngularCoreModule.forRoot({
			appConfig: CustomerAppConfig,
			env: environment,
			loaderSerivce: LoaderService
		}),
		SaepICTAngularSpin8CoreModule.forRoot({
			appConfig: CustomerAppConfig,
			env: environment,
			loaderSerivce: LoaderService
		}),
		MediaCenterModule.forRoot({
			appConfig: CustomerAppConfig,
			env: environment
		}),
		TicketCenterModule.forRoot({
			appConfig: CustomerAppConfig,
			env: environment,
			loaderSerivce: LoaderService
		}),
		KeycloakAngularModule
	],
	providers: [
		PouchAdapterSelectorService,
		PouchDbSpin8AgentAdapter,
		PouchDbAgentAdapter,
		PouchDbOrganizationB2CAdapter,
		PouchDbOrganizationB2BAdapter,
		PouchDbOrganizationPortalAdapter,
		PouchDbCommonsAdapter,
		PouchDbBackofficeAdapter,
		PouchDbCrmAdapter,
		PouchDbCommonsRequestAdapter,
		PouchDbCommonsOrganizationAdapter,
		PouchDbCommonsArticleAdapter,
		PouchDbCommonsOrderAdapter,
		PouchDbCommonsUserAdapter,
		PouchDbCommonsAgentAdapter,
		PouchDbCommonsBackofficeAdapter,
		PouchDbCommonsContactAdapter,
		PouchDbCommonsCrmAdapter,
		DeviceDetectorService,
		MatIconRegistry,
		UtilGuardService,
		AuthTokenGuard,
		AuthUserStateGuard,
		AuthLoginGuard,
		VerifyTokenEmailGuard,
		UserResolver,
		AngularSpin8CoreProbeTestService,
		CookieService,
		CheckoutGuard,
		ConnectionEffects,
		{ provide: APP_INITIALIZER, useFactory: getConnection, deps: [[new Inject(Store)]], multi: true },
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFactory,
			deps: [TranslateService, Injector, CustomerAppConfig, LocalStorageService],
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: initializeKeycloak,
			multi: true,
			deps: [KeycloakService, CustomerAppConfig]
		},
		// {
		// 	provide: ErrorHandler,
		// 	useValue: Sentry.createErrorHandler({
		// 	showDialog: false,
		// 	}),
		// },
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},

	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(private store: Store<any>) {
		ResourceGlobalConfig.returnAs = ResourceActionReturnType.Promise;
	}

	private handler = Network.addListener('networkStatusChange', status => {
		console.log('Network status changed', status);
		this.store.dispatch(ConnectionStateAction.load());
	});
}
