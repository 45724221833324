<mat-toolbar *ngIf="localListHandlerTextFilterShow">
	<mat-toolbar-row>
		<span class="flex-span"></span>
		<td-search-box
			*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="true"
			(searchDebounce)="localListHandlerApplyFilter()"
			[placeholder]="
				'general.search' | translate: { x: 'receipt.document_type.invoice' | translate } | sentencecase
			"
		>
		</td-search-box>
	</mat-toolbar-row>
</mat-toolbar>
<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<td-data-table
		#dataTable
		[data]="localListHandlerData.dataSubset"
		[columns]="_localListHandlerWidgetWrapperColumnList"
		[sortable]="true"
		(sortChange)="localListHandlerApplyFilter({ sort: $event })"
		[sortBy]="localListHandlerData.sort && localListHandlerData.sort.name ? localListHandlerData.sort.name : null"
		[sortOrder]="
			localListHandlerData.sort && localListHandlerData.sort.order ? localListHandlerData.sort.order : null
		"
	>
		<ng-template tdDataTableTemplate="type" let-value="value">
			{{ 'receipt.document_type.' + value | translate | sentencecase }}
		</ng-template>
		<ng-template tdDataTableTemplate="organization" let-value="value">
			<p>
				{{ value.name | titlecase }} <br />
				<small class="cell-subtitle">
					{{ 'organization.field.vat_number_short' | translate | uppercase }}
					<!--{{ value.id }}-->
					{{ value.piva }}
				</small>
			</p>
		</ng-template>
		<!--<ng-template tdDataTableTemplate="outlay" let-value="value">-->
		<ng-template tdDataTableTemplate="price" let-value="value">
			<p *ngIf="value && value.total && value.currency">
				<b>{{ value.total | currency: '':'':'':'it' }} {{ value.currency }}</b>
			</p>
		</ng-template>
		<ng-template tdDataTableTemplate="download_" let-value="value">
			<button
				mat-icon-button
				(click)="printReceipt(value)"
				[matTooltip]="'receipt.download' | translate | sentencecase"
				matTooltipPosition="left"
			>
				<mat-icon [svgIcon]="'custom-download'"></mat-icon>
			</button>
		</ng-template>
	</td-data-table>

	<mat-toolbar class="____mt-20">
		<mat-toolbar-row>
			<span class="flex-span"></span>
			<mat-paginator
				*ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow"
				#matPaginator
				(page)="localListHandlerApplyFilter({ pagination: $event })"
				[length]="localListHandlerData.pagination.totalFilteredItemCount"
				[pageSize]="localListHandlerData.pagination.pageSize"
				[pageSizeOptions]="[5, 10, 25, 100]"
				[hidePageSize]="true"
			>
			</mat-paginator>
		</mat-toolbar-row>
	</mat-toolbar>
</ng-container>
<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
