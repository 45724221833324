<!-- TODO: il passaggio di title="' '" serve solo a mostrare il close button di default, da rendere indipendente
in libreria -->
<mat-dialog-wrapper [title]="' '" [showCloseIcon]="true">
	<ng-container content>
		<!-- <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery> -->
		<div [style]="{ height: galleryHeight + 5 + 'px' }">
			<ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>
		</div>
	</ng-container>
</mat-dialog-wrapper>
