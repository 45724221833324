import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// model
import {
	BaseState,
	BaseStateModel,
	SubscribeManagerService
} from '@saep-ict/angular-core';
import { LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';
// store
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../state';
import { UserStateAction } from '../../../state/user/user.actions';

// misc
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';
import { PermissionUtilService } from '../../../service/util/permission-util.service';
import { PermissionContextListColumnService } from '../../../service/td-data-table/implementation/backoffice/permission-context-list.service';
import { ExtendedUserDetailModel } from '../../../model/user.model';
import { Router } from '@angular/router';
import { ITdDataTableColumn, TdDataTableSortingOrder } from '@covalent/core/data-table';
import {
	ContextApplicationItemCodeEnum,
	ContextCodeItem,
	ContextPermission,
	ROUTE_URL,
	LanguageStateModel
} from '@saep-ict/angular-spin8-core';

import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { AppUtilService } from '../../../service/util/app-util.service';
import { SideBarPositionValues } from '../../../enum/sidebar-position-values.enum';

export interface ContextApplicationModel {
	code?: ContextApplicationItemCodeEnum;
	context_code_data?: LocalListHandlerBaseModel<ContextCodeItem>;
}

@Component({
	selector: 'user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss'],
	providers: [SubscribeManagerService, PermissionContextListColumnService]
})
export class UserComponent implements OnInit, OnDestroy {
	@ViewChild('firstElementFocus') firstElementFocus: ElementRef;

	user$: Observable<BaseStateModel<ExtendedUserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: ExtendedUserDetailModel;

	languageList$: Observable<BaseStateModel<LanguageStateModel[]>> = this.store.select(StateFeature.getLanguageList);
	languageList: LanguageStateModel[];

	// Form
	form: FormGroup;
	isEditing = false;

	columns: ITdDataTableColumn[] = this.permissionContextListColumnService.columns;

	// enum
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
	sideBarPositionValues = SideBarPositionValues;

	currentContext: ContextApplicationItemCodeEnum;
	ROUTE_URL = ROUTE_URL;

	userBeforeEdit: ExtendedUserDetailModel;

	contextApplicationList: ContextApplicationModel[] = [];

	constructor(
		private store: Store<any>,
		private fb: FormBuilder,
		private subscribeManagerService: SubscribeManagerService,
		private permissionContextListColumnService: PermissionContextListColumnService,
		public permissionUtilService: PermissionUtilService,
		private router: Router,
		private utilBreadcrumbService: UtilBreadcrumbService,
		public utilService: AppUtilService,
	) {
		this.createForm();
		this.setColumns();
		this.subscribeManagerService.populate(this.subscribeUserState().subscribe(), 'user-state');

		// language list
		this.languageList$.pipe(take(1)).subscribe(res => {
			this.languageList = res ? res.data : null;
		});

		this.utilBreadcrumbService.title.value = 'user.profile.title';
		this.utilBreadcrumbService.subtitle.value = '';
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	// subscribe
	subscribeUserState() {
		return this.user$.pipe(
			skipWhile((state: BaseStateModel<ExtendedUserDetailModel>) => !(state && state.data)),
			map((state: BaseStateModel<ExtendedUserDetailModel>) => {
				if (state) {
					this.user = state ? state.data : null;
					this.updateListContextCodeItemData(this.user.context_application_list);
					this.currentContext = this.user.current_permission.context_application;
					this.updateFormFieldValue();
					this.form.disable();
					this.isEditing = false;
				}
			})
		);
	}

	// Aggiorna l'oggetto passato al list-wrapper
	updateListContextCodeItemData(context_list: ContextPermission[]) {
		context_list.forEach(context => {
			this.contextApplicationList.push({
				code: <ContextApplicationItemCodeEnum>context.code,
				context_code_data: {
					sort: {
						name: 'code',
						order: TdDataTableSortingOrder.Ascending
					},
					data: context.context_code_list
				}
			});
		});
	}

	// form
	createForm() {
		this.form = this.fb.group({
			name: [{ value: null, disabled: !this.isEditing }, [Validators.required]],
			lastName: [{ value: null, disabled: !this.isEditing }, [Validators.required]],
			language: [{ value: null, disabled: !this.isEditing }, [Validators.required]],
			email: [{ value: null, disabled: true }, [Validators.required, Validators.email]]
		});
	}

	updateFormFieldValue() {
		this.form.patchValue({
			name: this.user.first_name ? this.user.first_name : null,
			lastName: this.user.last_name ? this.user.last_name : null,
			language: (this.user as ExtendedUserDetailModel).language || null,
			email: this.user.username ? this.user.username : null
		});
	}

	onFormSubmit() {
		if (this.form.valid) {
			let updatedUser = _.cloneDeep(this.user);
			updatedUser.first_name = this.form.value.name;
			updatedUser.last_name = this.form.value.lastName;
			(updatedUser as ExtendedUserDetailModel).language = this.form.value.language;
			delete updatedUser.avatar;

			this.store.dispatch(UserStateAction.save(new BaseState(updatedUser)));
		}
	}

	editForm() {
		this.isEditing = true;
		['name', 'lastName', 'language'].forEach(field => this.form.get(field).enable());
		this.userBeforeEdit = _.cloneDeep(this.user);
	}

	exitEditMode() {
		this.isEditing = false;
		this.form.disable({ emitEvent: false });
		this.user = _.cloneDeep(this.userBeforeEdit);
		this.updateFormFieldValue();
	}

	setColumns() {
		const base: ITdDataTableColumn[] = this.permissionContextListColumnService.columns;
		// B2C
		this.columns[ContextApplicationItemCodeEnum.B2C] = base.filter(field =>
			['code', 'description', 'typology'].includes(field.name)
		);
		// Other contexts
		this.columns[ContextApplicationItemCodeEnum.AGENT] = this.columns[
			ContextApplicationItemCodeEnum.B2B
		] = this.columns[ContextApplicationItemCodeEnum.BACKOFFICE] = this.columns[
			ContextApplicationItemCodeEnum.PORTAL
		] = this.columns[ContextApplicationItemCodeEnum.CRM] = base.filter(field =>
			['code', 'description'].includes(field.name)
		);
	}

	/**
	 * navigation
	 */

	goToPasswordUpdate() {
		this.router.navigate(['/', ROUTE_URL.authentication, ROUTE_URL.passwordUpdate]);
	}
}
