import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeIt from '@angular/common/locales/it';
import { Component, ViewEncapsulation, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Language } from './enum/language.enum';
import { AppVersionService } from './service/structure/app-version-settings.service';
import { AngularSpin8CoreUtilTranslateService } from '@saep-ict/angular-spin8-core';
import { CustomerAppConfig } from './customer-app.config';

registerLocaleData(localeIt, 'it');
registerLocaleData(localeEn, Language.ENGLISH);
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	encapsulation: ViewEncapsulation.None
})
export class AppComponent implements AfterViewInit {

	// @ViewChild('rocketChatAuth') set rocketChatAuth(e: ElementRef) {
	// 	if (e) {
	// 		this.rocketChatInit();
	// 	}
	// }

	@ViewChild('rocketChatAuth') rocketChatAuth: ElementRef;

	chatUrl: string;

	constructor(
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		private appVersionService: AppVersionService,
		private translateUtilService: AngularSpin8CoreUtilTranslateService,
		private appConfig: CustomerAppConfig
	) {
		this.translateUtilService.setLanguageFirstTime(<any>Language);

		// this.handleDarkTheme();
		this.registerCustomIcon();

		this.appVersionService.setTag();
		this.appVersionService.setRelease();
		this.chatUrl = this.appConfig.config.chat.url;
	}

	ngAfterViewInit() {
		this.rocketChatInit();
	}

	registerCustomIcon() {
		this.matIconRegistry.addSvgIcon(
			'custom-hamburger',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-hamburger.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-pencil',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-pencil.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-gear',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-gear.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-package',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-package.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-trend-up',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-trend-up.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-trend-down',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-trend-down.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-trend-steady',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-trend-steady.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-download',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-download.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-document',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-document.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-folder',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-folder.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-picture',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-picture.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-help',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-help.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-visibility',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-visibility.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-business',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-business.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-dashboard',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-dashboard.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-inbox',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-inbox.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-content_copy',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-content_copy.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-pie_chart',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-pie_chart.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-image',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-image.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-folder-2',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-folder-2.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-insert_drive_file',
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/common/img/custom-icon/icon-insert_drive_file.svg'
			)
		);

		this.matIconRegistry.addSvgIcon(
			'custom-help_outline',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-help_outline.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-bar_chart',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-bar_chart.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-textsms',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-textsms.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-groups',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-groups.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-settings',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-settings.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-shopping_basket',
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/common/img/custom-icon/icon-shopping_basket.svg'
			)
		);

		this.matIconRegistry.addSvgIcon(
			'custom-inventory_2',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-inventory_2.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-tune',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-tune.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-account_tree',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-account_tree.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-confirmation_number',
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/common/img/custom-icon/icon-confirmation_number.svg'
			)
		);

		this.matIconRegistry.addSvgIcon(
			'custom-contact_page',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-contact_page.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-info',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-info.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-campaign',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-campaign.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-lightbulb',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-lightbulb.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-checklist',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-checklist.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-category',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-category.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-logo_dev',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-logo_dev.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'custom-person',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-person.svg')
		);

        this.matIconRegistry.addSvgIcon(
			'custom-envelop',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-envelop.svg')
		);

        this.matIconRegistry.addSvgIcon(
			'custom-trash',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-trash.svg')
		);

        this.matIconRegistry.addSvgIcon(
			'custom-attach',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-attach.svg')
		);

        this.matIconRegistry.addSvgIcon(
			'custom-check_circle',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-check_circle.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'icon-send',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-send.svg')
		);

        this.matIconRegistry.addSvgIcon(
			'custom-push_pin',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-push_pin.svg')
		);

        this.matIconRegistry.addSvgIcon(
			'custom-send',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-send.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'custom-timer',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-timer.svg')
		);
        this.matIconRegistry.addSvgIcon(
			'not-authorized',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/not-authorized.svg')
		);

        this.matIconRegistry.addSvgIcon(
			'custom-visibility_off',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-visibility_off.svg')
		);
	}

	handleDarkTheme() {
		const darkModeOn = window.matchMedia('(prefers-color-scheme: dark)');
		this.updateTheme(darkModeOn.matches);

		darkModeOn.addListener(e => {
			this.updateTheme(e.matches);
		});
	}

	updateTheme(isDarkMode: boolean) {
		if (isDarkMode) {
			// dark mode
			localStorage.setItem('theme', 'dark');
		} else {
			// light mode
			localStorage.setItem('theme', 'light');
		}
	}

	// rocket chat iframe
	rocketChatInit() {
		const start = performance.now();
		console.log("called1: " + start);
		const iframe = document.getElementById('rocketChatAuth') as HTMLIFrameElement;
		iframe.onload = (e) => {
			if (e.type === 'load') {
				setTimeout(() => {
					console.log("called2: ", (performance.now() - start));
					iframe.contentWindow.postMessage({
						externalCommand: 'call-custom-oauth-login',
						service: 'keycloak'
					}, '*');
					iframe.onload = null;
				}, 1000);
			}
		}
	}


	// goToGeneral(){
	// 	document.querySelector('iframe').contentWindow.postMessage({
	// 		externalCommand: 'go',
	// 		path: '/channel/general'
	// 	}, '*');
	// };

	// loginWithKeycloak(){
	// 	document.querySelector('iframe').contentWindow.postMessage({
	// 		externalCommand: 'call-custom-oauth-login',
	// 		service: 'keycloak'
	// 	}, '*');
	// };

	// logout(){
	// 	document.querySelector('iframe').contentWindow.postMessage({
	// 		externalCommand: 'logout'
	// 	}, '*');
	// };
}
