<div class="user-button-container" [class]="contextClass" [class.open]="isOpen" #userButton (clickOutside)="onClickOutside()" *ngIf="user">
	<button
		mat-button
		class="user-button-container__button"
		(click)="toggleDropdown()"
		[class.open]="isOpen"
		[matTooltip]="user.username"
	>
		<div class="button-content__wrapper">
			<mat-icon>account_circle</mat-icon>
			<div class="button-content__label">
				<span class="button-content__label__user-info">
					{{ userInfo }}
				</span>
				<span class="button-content__label__user-role opacity-50" *ngIf="userRole">
					{{ userRole | translate | uppercase }}
				</span>
			</div>
			<mat-icon class="arrow-down" [class.rotate]="isOpen">keyboard_arrow_down</mat-icon>
		</div>
	</button>

	<div class="user-button-container__dropdown mat-elevation-z1" [class.open]="isOpen" [class.open-up]="openUp">
		<!-- context !B2C: Profile -->
		<ng-template [ngIf]="user.current_permission.context_application !== ContextApplicationItemCodeEnum.B2C">
			<button
				mat-button
				[routerLink]="'/' + ROUTE_URL.private + '/' + ROUTE_URL.user"
				class="dropdown__item"
				(click)="toggleDropdown()"
			>
				<mat-icon class="dropdown__item__icon">perm_identity</mat-icon>
				<span class="dropdown__item__title">
					{{ 'general.profile' | translate | sentencecase }}
				</span>
			</button>
		</ng-template>

		<!-- context B2C: Area riservata -->
		<ng-template
			[ngIf]="
				!router.url.includes(ROUTE_URL.private) &&
				user.current_permission.context_application === ContextApplicationItemCodeEnum.B2C
			"
		>
			<button mat-button [routerLink]="[PATH_URL.MY_ACCOUNT]" class="dropdown__item" (click)="toggleDropdown()">
				<mat-icon class="dropdown__item__icon">perm_identity</mat-icon>
				<span class="dropdown__item__title">
					{{ 'general.reserved_area' | translate | sentencecase }}
				</span>
			</button>
		</ng-template>

		<!-- context *: Dashboard -->
		<ng-template
			[ngIf]="
				!router.url.includes(ROUTE_URL.private) &&
				user.current_permission.context_application !== ContextApplicationItemCodeEnum.B2C
			"
		>
			<mat-divider></mat-divider>
			<button mat-button class="dropdown__item" [routerLink]="'/' + ROUTE_URL.private">
				<mat-icon class="dropdown__item__icon">dashboard</mat-icon>
				<span class="dropdown__item__title">
					{{ 'general.dashboard' | translate | sentencecase }}
				</span>
			</button>
		</ng-template>

		<!-- context *: Storefront -->
		<ng-template
			[ngIf]="
				router.url.includes(ROUTE_URL.private) &&
				appConfig.config.permissionContext.includes(ContextApplicationItemCodeEnum.B2C)
			"
		>
			<mat-divider></mat-divider>

			<button mat-button class="dropdown__item" [routerLink]="'/' + ROUTE_URL.public">
				<mat-icon class="dropdown__item__icon">store</mat-icon>
				<span class="dropdown__item__title">
					{{ 'general.storefront' | translate | sentencecase }}
				</span>
			</button>
		</ng-template>

		<!-- context *: Change Context -->
		<ng-template [ngIf]="user.context_application_list.length > 1 || hasMultipleContextCode">
			<mat-divider></mat-divider>
			<button mat-button class="dropdown__item" (click)="authService.changeContext()">
				<mat-icon class="dropdown__item__icon">swap_horiz</mat-icon>
				<span class="dropdown__item__title">
					{{ 'general.change_context' | translate | sentencecase }}
				</span>
			</button>
		</ng-template>

		<!-- context *: Language -->
		<ng-container
			*ngIf="
				utilTranslateService.languages &&
				utilTranslateService.languages.length &&
				utilTranslateService.languages.length > 1
			"
		>
			<mat-divider></mat-divider>
			<div
				mat-button
				class="dropdown__item dropdown__item--label"
			>
				<div class="label" (click)="selectLanguage.open()">
					{{ 'language.name' | translate | sentencecase }}
				</div>
				<mat-select
					#selectLanguage
					(selectionChange)="utilTranslateService.setLanguage($event.value, true)"
					[value]="utilTranslateService.translate.currentLang"
				>
					<mat-option *ngFor="let lang of utilTranslateService.languages" [value]="lang">
						{{ lang | uppercase }}
					</mat-option>
				</mat-select>
			</div>
		</ng-container>

		<!-- context *: Fontsize -->
		<mat-divider></mat-divider>
		<div mat-button class="dropdown__item dropdown__item--label">
			<div class="label" (click)="selectFontsize.open()">
				{{ 'general.fontsize.name' | translate | sentencecase }}
			</div>
			<mat-select
				#selectFontsize
				(selectionChange)="utilFontsizeService.fontsizeChange($event)"
				[(ngModel)]="utilFontsizeService.fontSizeSelected"
			>
				<mat-option *ngFor="let fontsize of utilFontsizeService.fontsizeList" [value]="fontsize">
					{{ 'general.fontsize.' + fontsize | translate | sentencecase }}
				</mat-option>
			</mat-select>
		</div>

		<!-- context *: Logout -->
		<mat-divider></mat-divider>
		<button mat-button (click)="authService.logout()" class="dropdown__item" (click)="toggleDropdown()">
			<mat-icon class="dropdown__item__icon">exit_to_app</mat-icon>
			<span class="dropdown__item__title"> Logout </span>
		</button>
	</div>
</div>
