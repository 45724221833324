import { Injectable } from '@angular/core';
import { BaseStateModel } from '@saep-ict/angular-core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { StateFeature } from '../../state';
import { filter } from 'rxjs/operators';
import { Category, CategoryMap, CategoryMetaInformation, ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import { OrderStateModel } from '@saep-ict/angular-spin8-core';
import { UtilOrderService } from './util-order.service';
import _ from 'lodash';
import { UtilPriceService } from './util-price.service';

// model
// pouch instance

// widget & utility
@Injectable({
	providedIn: 'root'
})
export class UtilCategoryListService {
	selectedCategoryList: Set<string> = new Set<string>();
	selectedCategoryList$: BehaviorSubject<Set<string>> = new BehaviorSubject<Set<string>>(null);

	categoryList: Category<never>[];
	categoryList$: Observable<BaseStateModel<CategoryMap>> = this.store.select(StateFeature.getCategoryListState);

	filteredCategoryList: Category<never>[];

	constructor(
		private store: Store,
		private utilOrderService: UtilOrderService,
		private utilPriceService: UtilPriceService,
	) {
		this.categoryList$
			.pipe(filter(res => !!(res && res.data)))
			.subscribe(categoryList => (this.filteredCategoryList = this.categoryList = categoryList.data.tree));
	}

	// TODO: deprecate once all references are made via code_item
	returnCategoryById(id: string, category_list: Category[]): Promise<Category> {
		return new Promise(async resolve => {
			try {
				let category: Category;
				for (let i = 0; i < category_list.length; i++) {
					if (category_list[i]._id === id) {
						category = category_list[i];
					} else {
						if (category_list[i].category_list && category_list[i].category_list.length > 0) {
							category = await this.returnCategoryById(id, category_list[i].category_list);
						}
					}
					if (category) { break; }
				}
				resolve(category ? category : null);
			} catch (err) { throw new Error(err); }
		});
	}

	returnCategoryParentById(id: string, category_list: Category[], categoryParent?: Category): Promise<Category> {
		return new Promise(async resolve => {
			try {
				let category: Category;
				for (let i = 0; i < category_list.length; i++) {
					if (category_list[i]._id === id) {
						category = categoryParent;
					} else {
						if (category_list[i].category_list && category_list[i].category_list.length > 0) {
							category =
								await this.returnCategoryParentById(
									id,
									category_list[i].category_list,
									category_list[i]
								);
						}
					}
					if (category) { break; }
				}
				resolve(category ? category : null);
			} catch (err) { throw new Error(err); }
		});
	}

	changeSelectedCategoryList(selectedCategoryRef: string) {
		let childCategoryList = [];
		for (let i = 0; i < this.filteredCategoryList.length; i++) {
			const selectedCategory = this.recursivelyFindCategoryByCodeItem(
				selectedCategoryRef,
				this.filteredCategoryList[i]
			);
			if (selectedCategory) {
				this.getChildCodeItemList(selectedCategory, childCategoryList);
				break;
			}
		}
		childCategoryList = [...new Set(childCategoryList)];
		if (this.selectedCategoryList.has(selectedCategoryRef)) {
			this.selectedCategoryList.delete(selectedCategoryRef);
			childCategoryList.forEach(category => {
				this.selectedCategoryList.delete(category);
			});
		} else {
			this.selectedCategoryList.add(selectedCategoryRef);
			for (let child of childCategoryList) {
				this.selectedCategoryList.add(child);
			}
		}
		this.selectedCategoryList$.next(this.selectedCategoryList);
	}

	recursivelyFindCategoryByCodeItem(categoryCodeItem: string, category: Category): Category {
		if (category.code_item === categoryCodeItem) {
			return category;
		} else {
			if (category.category_list) {
				for (let ctg of category.category_list) {
					const selectedCategory = this.recursivelyFindCategoryByCodeItem(categoryCodeItem, ctg);
					if (selectedCategory !== null) {
						return selectedCategory;
					}
				}
			}
		}
		return null;
	}

	getChildCodeItemList(category: Category, childCategoryList) {
		category.children.forEach(childCategoryCodeItem => {
			const childCategory = this.recursivelyFindCategoryByCodeItem(childCategoryCodeItem, category);
			if (childCategory && childCategory.code_item) {
				childCategoryList.push(childCategory.code_item);
				this.getChildCodeItemList(childCategory, childCategoryList);
			}
		});
	}

	splitCategoryList(activeCategoryList) {
		return activeCategoryList.split(',');
	}

	joinCategoryList(activeCategoryList) {
		return [...activeCategoryList].join(',');
	}

	returnArticleListFilteredByCategoryList(
		articleList: ArticlePouchModel[],
		categoryListSelected: string[]
	): ArticlePouchModel[] {
		let articleListToReturn: ArticlePouchModel[];
		if (categoryListSelected && categoryListSelected.length > 0) {
			articleListToReturn = articleList.filter(article =>
				article.articleDescription &&
				article.articleDescription.category_list &&
				article.articleDescription.category_list.length > 0
					? article.articleDescription.category_list.filter(category =>
							categoryListSelected.includes(category)
					  ).length > 0
					: false
			);
		}
		return articleListToReturn ? articleListToReturn : articleList;
	}

	returnCategoryArticleMetaInformation(
		categoryCodeItem: string,
		order: OrderStateModel,
		articleList: ArticlePouchModel[],
		division: string
	): CategoryMetaInformation {
		const metaInformation = {
				ordered_quantity: 0,
				free_sample: 0,
				qty_free: 0,
				total: 0,
			};
		for (const orderArticle of order.product_list) {
			let article = articleList.find(i => i.code_item === orderArticle.code_item);
			this.utilPriceService.mapArticlePrice(article, division)
			if (
				article &&
				article.articleDescription.category_list &&
				article.articleDescription.category_list.includes(categoryCodeItem)
			) {
				article = _.cloneDeep(this.utilOrderService.mergeProductDetailInArticle([article], order)[0]);
				if (article.articleDescription.free_sample === 'S') {
					if (article.input_quantity) {
						metaInformation.free_sample = metaInformation.free_sample + article.input_quantity;
					}
				} else {
					if (article.input_quantity) {
						metaInformation.ordered_quantity = metaInformation.ordered_quantity + article.input_quantity;
					}
				}
				if (article.qty_free) {
					metaInformation.qty_free = metaInformation.qty_free + article.qty_free;
				}
				if (article.input_quantity) {
					metaInformation.total = metaInformation.total + article.calculate_price;
				}
			}
		}
		return metaInformation;
	}
}
