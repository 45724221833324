import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
	BaseStateModel,
	FormValidatorCustom,
	ITdDataTableColumnCustom,
	SubscribeManagerService
} from '@saep-ict/angular-core';
import { LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ReceiptStateModel } from '../../../model/state/receipt-state.model';
import { ReceiptColumnService } from '../../../service/td-data-table/implementation/receipt-list.service';
import { StateFeature } from '../../../state';
import { ReceiptListActionEnum, ReceiptListStateAction } from '../../../state/receipt-list/receipt-list.actions';
import { ReceiptListWrapperComponent } from '../../../widget/receipt/receipt-list-wrapper/receipt-list-wrapper.component';
import { ActivatedRoute } from '@angular/router';
import { ContextApplicationItemCodeEnum, UserDetailModel } from '@saep-ict/angular-spin8-core';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { FramePageComponent } from '../../../frame/admin/admin.component';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';
import { DateFilterModel } from '../../../service/pouch-db/filter/date-filter.model';
import { Options } from '@angular-slider/ngx-slider';
import { SideBarPositionValues } from '../../../enum/sidebar-position-values.enum';

@Component({
	selector: 'organization',
	templateUrl: './receipt.component.html',
	styleUrls: ['./receipt.component.scss'],
	providers: [SubscribeManagerService]
})
export class ReceiptComponent implements OnInit, OnDestroy {
	@ViewChild(ReceiptListWrapperComponent) public receiptListWrapperComponent: ReceiptListWrapperComponent;

	receiptList$: Observable<BaseStateModel<ReceiptStateModel[]>> = this.store.select(StateFeature.getReceiptListState);
	receiptList: ReceiptStateModel[];

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	// enum
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	public listPageBaseData = <LocalListHandlerBaseModel<ReceiptStateModel>>{
		pagination: {
			pageSize: this.contextApplicationItemCodeEnum.CRM ? 25 : 10
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: ['number', 'price.total']
			}
		},
		sort: {
			name: 'number',
			order: 'DESC'
		},
		data: []
	};

	// Price slider
	minValue: number = 0;
	maxValue: number = 0;
	options: Options = {
		floor: 0,
		ceil: 0
	};

	filterOpened = false;
	columns: ITdDataTableColumnCustom[];
	formFilters: FormGroup;
	documentTypes = ['invoice', 'credit_note'];

	sideBarPositionValues = SideBarPositionValues;

	constructor(
		private store: Store<any>,
		public receiptColumnService: ReceiptColumnService,
		private subscribeManagerService: SubscribeManagerService,
		private fb: FormBuilder,
		public activatedRoute: ActivatedRoute,
		private utilBreadcrumbService: UtilBreadcrumbService
	) {
		this.createFormFilters();
		this.store.dispatch(ReceiptListStateAction.loadAll());

		this.subscribeManagerService.populate(this.subscribeReceiptList().subscribe(), 'subscribeReceiptList');

		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
			this.columns = ConfigurationCustomer.Receipt.columnList(this.user.current_permission.context_application);
		});
		this.setRouteMetaInformation();
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.store.dispatch(ReceiptListStateAction.reset());
		this.subscribeManagerService.destroy();
		this.unsetRouteMetaInformation();
	}

	/**
	 * Subscription
	 */

	subscribeReceiptList() {
		return this.receiptList$.pipe(
			filter((state: BaseStateModel<ReceiptStateModel[]>) => !!(state && state.data)),
			map((state: BaseStateModel<ReceiptStateModel[]>) => {
				switch (state.type) {
					case ReceiptListActionEnum.UPDATE:
						this.applyPermanentFilters(state.data);
						break;

					case ReceiptListActionEnum.ERROR:
						throw new Error(ReceiptListActionEnum.ERROR);
						break;

					default:
						break;
				}

				return state;
			})
		);
	}

	applyPermanentFilters(receiptList: ReceiptStateModel[]) {
		this.receiptList = receiptList;
		this.setPriceOptionOnSlider();
		this.receiptListWrapperComponent.updateListPageBaseData(this.receiptList);
	}

	/**
	 * Filters sidebar
	 */

	createFormFilters() {
		this.formFilters = this.fb.group({
			// receipt_date: undefined,
			// receipt_document_type: '',
			// receipt_organization_name: '',
			creationDate: this.fb.group(
				{ start: [''], end: [''] },
				{ validators: [FormValidatorCustom.allEmptyOrAllFilledByObject] }
			),
			expirationDate: this.fb.group(
				{ start: [''], end: [''] },
				{ validators: [FormValidatorCustom.allEmptyOrAllFilledByObject] }
			),
			price: ''
		});
	}

	resetFilters() {
		this.formFilters.patchValue({
			// receipt_date: null,
			// receipt_document_type: '',
			// receipt_organization_name: '',
			creationDate: { start: '', end: '' },
			expirationDate: { start: '', end: '' }
		});
		this.setPriceOptionOnSlider();
		this.onFormFiltersSubmit();
	}

	onFormFiltersSubmit() {
		let filteredList = _.cloneDeep(this.receiptList);

		// // filtro sul tipo di documento
		// if (this.formFilters && this.formFilters.value.receipt_document_type) {
		// 	const type: string = this.formFilters.value.receipt_document_type;
		// 	filteredList = filteredList.filter(item => {
		// 		return item.document_type === type;
		// 	});
		// }

		// // filtro sul nome dell'organizzazione
		// if (this.formFilters && this.formFilters.value.receipt_organization_name) {
		// 	const organizationName: string = this.formFilters.value.receipt_organization_name;
		// 	filteredList = filteredList.filter(item => {
		// 		return item.organization.name.indexOf(organizationName) + 1;
		// 	});
		// }

		// // filtro sulla data del documento
		// if (this.formFilters && this.formFilters.value.receipt_date) {
		// 	const date: number = this.formFilters.value.receipt_date;
		// 	filteredList = filteredList.filter(item => {
		// 		return item.date === date;
		// 	});
		// }

		// Creation date
		if (this.formFilters.value.creationDate) {
			if (this.formFilters.value.creationDate.start) {
				const filterDate: DateFilterModel = {
					begin: this.formFilters.value.creationDate.start.valueOf()
				};
				filteredList = filteredList.filter(i => i.date >= +filterDate.begin);
			}
			if (this.formFilters.value.creationDate.end) {
				const filterDate: DateFilterModel = {
					end: this.formFilters.value.creationDate.end.add(1, 'days').valueOf()
				};
				filteredList = filteredList.filter(i => i.date <= +filterDate.end);
			}
		}

		// Expiration date
		if (this.formFilters.value.expirationDate) {
			if (this.formFilters.value.expirationDate.start) {
				const filterDate: DateFilterModel = {
					begin: this.formFilters.value.expirationDate.start.valueOf()
				};
				filteredList = filteredList.filter(i => i.expiration_date >= +filterDate.begin);
			}
			if (this.formFilters.value.expirationDate.end) {
				const filterDate: DateFilterModel = {
					end: this.formFilters.value.expirationDate.end.add(1, 'days').valueOf()
				};
				filteredList = filteredList.filter(i => i.expiration_date <= +filterDate.end);
			}
		}

		// Price
		filteredList = this.filteredListByPrice(filteredList);

		this.receiptListWrapperComponent.updateListPageBaseData(filteredList);
	}

	setPriceOptionOnSlider() {
		if (this.receiptList.length) {
			const priceList: number[] = this.receiptList.map(receipt => {
				return receipt.price.total;
			});
			this.options.floor = this.minValue = Math.floor(Math.min(...priceList));
			this.options.ceil = this.maxValue = Math.ceil(Math.max(...priceList));
		} else {
			this.options.floor = this.minValue = Math.floor(0);
			this.options.ceil = this.maxValue = Math.ceil(0);
		}
		this.options = Object.assign({}, this.options);
	}

	// Price filter
	filteredListByPrice(listToFilter: ReceiptStateModel[]) {
		listToFilter = listToFilter.filter(receipt => {
			if (receipt.price.total) {
				return receipt.price.total >= this.minValue && receipt.price.total <= this.maxValue;
			}
			return false;
		});
		return listToFilter;
	}

	setRouteMetaInformation() {
		if (!this.activatedRoute.parent.component || this.activatedRoute.parent.component === FramePageComponent) {
			switch (this.user.current_permission.context_application) {
				case ContextApplicationItemCodeEnum.AGENT:
					this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('archive');
					this.utilBreadcrumbService.subtitle.value = this.utilBreadcrumbService.getBreadcrumbTitle(
						'archive_receipts'
					);
					break;
				case ContextApplicationItemCodeEnum.B2B:
				case ContextApplicationItemCodeEnum.CRM:
					this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('receipts');
					this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['receipts']);
					break;
			}
		}
	}

	unsetRouteMetaInformation() {
		if (!this.activatedRoute.parent.component || this.activatedRoute.parent.component === FramePageComponent) {
			// Componente radice
			this.utilBreadcrumbService.unsetRouteMetaInformation();
		}
	}
}
