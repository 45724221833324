<form class="angelo-theme" *ngIf="form" [formGroup]="form">
	<div class="row">
		<form-control-multipurpose
			*ngFor="
				let field of formFieldList
					| filterByAllTheBooleans
						: {
								propertyListName: ['on_form_template'],
								value: true
						  }
			"
			[formControlName]="field.name"
			[field]="field"
			class="col-md-{{ field.column_width ? +field.column_width : 12 }}"
		></form-control-multipurpose>
	</div>
</form>
<form-button-bar
	*ngIf="organization && order && order.header.status === orderStatusEnum.DRAFT && order._id"
	[align]="'space-between'"
	class="actions-buttons"
	[primaryButtonIsDisabled]="!form.valid || disableExit"
	[primaryButtonLabel]="'general.forward_with_the_order' | translate | sentencecase"
	(primaryButtonClickEmitter)="
		router.navigate([
			PATH_URL.PRIVATE,
			ROUTE_URL.orders,
			orderStatusEnum.DRAFT,
			organization.code_item,
			order._id,
			ROUTE_URL.catalog
		])
	"
	[secondaryButtonLabel]="'order.action.delete' | translate | sentencecase"
	(secondaryButtonClickEmitter)="utilOrderService.dialogConfirmOrderDelete(order)"
></form-button-bar>
