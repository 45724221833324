import { Injectable } from '@angular/core';
import { BaseStateModel } from '@saep-ict/angular-core';
import { PouchDbModel } from '@saep-ict/angular-spin8-core';
import { ListViewDataPouchModel } from '@saep-ict/pouch_agent_models';
import { ConfigurationCustomer } from '../../constants/configuration-customer';
import { PouchAdapterSelectorService, AdapterOptions } from '../pouch-db/pouch-adapter-selector.service';

@Injectable({
	providedIn: 'root'
})
export class UtilPouchService {
	constructor(private pouchAdapterSelectorService: PouchAdapterSelectorService) {}
	async saveAnyDocument(action: any, couchDocumentType?: PouchDbModel.PouchDbDocumentType): Promise<any> {
		try {
			const actionReturn = await (
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter(couchDocumentType)
			).basePouch.saveBodyTable(action);
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	async deleteAnyDocument(action: any): Promise<any> {
		try {
			const actionReturn = await (
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter()
			).basePouch.deleteBodyTable(action);
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	async getView<D>(path: string): Promise<BaseStateModel<D[]>> {
		try {
			const view: ListViewDataPouchModel<D> = await (
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter()
			).basePouch.getView<D>(path);
			const actionReturn = <BaseStateModel<D[]>>{
				data: this.mapViewResponse(view)
			};
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	async allDocs<D>(opts, documentType?: PouchDbModel.PouchDbDocumentType, options?: AdapterOptions): Promise<BaseStateModel<D[]>> {
		try {
			const view: ListViewDataPouchModel<D> = await (
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter(documentType, options)
			).basePouch.allDocs<D>(opts);
			const actionReturn = <BaseStateModel<D[]>>{
				data: this.mapAllDocsResponse(view)
			};
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	// widget & utility

	mapViewResponse<D>(view: ListViewDataPouchModel<D>): D[] {
		return view.rows.map(i => i.value);
	}

	// TODO: modificare tipizzazione any una volta generalizzato ListViewDataPouchModel
	// in modo possa restituire anche doc
	mapAllDocsResponse<D>(view: any): any[] {
		return view.rows.map(i => i.doc);
	}

	/**
	 * Permette di utilizzare un'unica chiave come id (es: geographic-tree per table_geographic_tree)
	 * per il recupero di una aux table tramite REST API o chiamata diretta verso couch
	 *
	 * @param {string} key
	 * @returns {string}
	 * @memberof UtilPouchService
	 */
	auxiliaryTableIdFormatter(key: string): string {
		const id = `table${ConfigurationCustomer.AppStructure.noSqlDocSeparator}${key.replace("-", "_")}`;
		return id;
	}
}
