<div
	(mouseenter)="maskMouseenterHandler()"
	(mouseleave)="maskMouseleaveHandler()"
>
	<input
		[hidden]="!configuration.visible"
		appOnlyNumbers
		stopPropagation
		placeholder="0"
		[value]="configuration.value"
		[disabled]="configuration.disabled"
		appMaxNumberInput
		[attr.maxValue]="configuration.maxValue ? configuration.maxValue : null"
		[attr.decimals]="configuration.decimals ? configuration.decimals : null"
		#inputTag
		(focus)="
			utilInputNavigationService.selectItem(configuration);
			maskShow = false;
			maskShowFocusOnInput = true;
		"
		(focusout)="
			maskShow = true;
			maskShowFocusOnInput = false;
		"
		(input)="
			updatedArticleRowSelection({
				event: $event,
				row: configuration.row,
				key: configuration.key,
				dataSubset: configuration.dataSubset,
				dataTable: configuration.dataTable,
				organization: configuration.organization,
				onArticleChange: configuration.onArticleChange
			})
		"
		(keydown)="onKeydown($event)"
	/>
	<div
		*ngIf="configuration.maskEnabled && configuration.visible"
		class="mask"
		[ngClass]="{maskShow: maskShow}"
	>
		{{ (configuration.value | utilPriceReturnItemValueFormattedPipe: 0) || 0 }}
	</div>
	<ng-container *ngIf="!configuration.visible"><span>-</span></ng-container>
</div>