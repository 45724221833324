import { ContextApplicationItemCodeEnum, ROUTE_URL } from '@saep-ict/angular-spin8-core';
import { OrganizationPouchModel, OrganizationLevelEnum } from '@saep-ict/pouch_agent_models';

// TOFIX: capire come gestire questa costante
export const OrganizationBackoffice = [
	{
		tab: ROUTE_URL.details,
		can_edit: true
	},
	{
		tab: ROUTE_URL.addresses,
		can_edit: true
	},
	{
		tab: ROUTE_URL.orders,
		can_edit: true
	}
];

export namespace ConfigurationOrganization {
	export const listFilter = (
		list: OrganizationPouchModel[],
		contextApplication: ContextApplicationItemCodeEnum,
		organizationLevel: OrganizationLevelEnum
	): OrganizationPouchModel[] => {
		list = list.filter(item => item.valid);
		switch (contextApplication) {
			case ContextApplicationItemCodeEnum.BACKOFFICE:
				return list.filter(organization => organization.level === organizationLevel);
			case ContextApplicationItemCodeEnum.CRM:
				return list.filter(organization => organization.level === organizationLevel);
			default:
				return list;
		}
	};
}
