<mat-toolbar *ngIf="localListHandlerTextFilterShow">
	<mat-toolbar-row>
		{{ 'general.items_number' | translate | sentencecase }}
		<span class="badge ml-3" [class.ml-3]="localListHandlerTitle">
			{{ localListHandlerData?.data?.length || 0 }}
		</span>
	</mat-toolbar-row>
	<mat-toolbar-row
		*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
		class="mt-30"
	>
		<span class="flex-span"></span>
		<td-search-box
			*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="true"
			(searchDebounce)="localListHandlerApplyFilter()"
			[placeholder]="'general.search'| translate | sentencecase"
		>
		</td-search-box>
	</mat-toolbar-row>
</mat-toolbar>
<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<td-data-table
		#dataTable
		[data]="localListHandlerData.dataSubset"
		[columns]="_localListHandlerWidgetWrapperColumnList"
		[sortable]="true"
		(sortChange)="localListHandlerApplyFilter({ sort: $event })"
		[sortBy]="localListHandlerData.sort && localListHandlerData.sort.name ? localListHandlerData.sort.name : null"
		[sortOrder]="
			localListHandlerData.sort && localListHandlerData.sort.order ? localListHandlerData.sort.order : null
		"
	>
		<ng-template tdDataTableTemplate="status" let-value="value">
			<div class="chips-mini">
				<span
					[ngClass]="value"
					[ngStyle]="{ position: 'relative' }"
					matTooltip="{{ 'organization.status.' + value | translate | sentencecase }}"
				></span>
			</div>
		</ng-template>
		<ng-template tdDataTableTemplate="level" let-value="value">
			<div class="chips-mini">
				<span
					class="material-icons"
					[ngClass]="value"
					[ngStyle]="{ position: 'relative' }"
					matTooltip="{{ 'organization.level.' + value | translate | sentencecase }}"
					>person</span
				>
			</div>
		</ng-template>
		<ng-template tdDataTableTemplate="business_name" let-value="value" let-row="row">
			<p>
				<a
					href="javascript:void(0)"
					(click)="onItemSelect.emit({ level: row.level, code: row.code_erp ? row.code_erp : row.code_item })"
				>
					{{ utilCompanyService.getBusinessNameLabel(row) | sentencecase }}
				</a>
				<br />
				<small class="cell-subtitle">
					{{ row.code_erp ? row.code_erp : (row.code_item | guidFormatter) }}
				</small>
			</p>
		</ng-template>
		<ng-template tdDataTableTemplate="address" let-row="row">
			<ng-container
				*ngTemplateOutlet="
					addressTemplate;
					context: {
						address: utilService.returnIsRegisteredOffice(row.destination_list)
					}
				"
			>
			</ng-container>
		</ng-template>
		<ng-template tdDataTableTemplate="average" let-row="row">
			{{
				row.average |
				utilPriceReturnItemValueFormattedPipe |
				utilReturnIsMainOfListPropertyPipe: { list: row.currency, propertyName: 'description_short' }
			}}
		</ng-template>
		<ng-template tdDataTableTemplate="total" let-row="row">
			{{
				row.total |
				utilPriceReturnItemValueFormattedPipe |
				utilReturnIsMainOfListPropertyPipe: { list: row.currency, propertyName: 'description_short' }
			}}
		</ng-template>
		<ng-template tdDataTableTemplate="add_new_order" let-row="row">
			<button
				mat-icon-button
				(click)="openOrderNew.emit(row)"
				[matTooltip]="'order.add_new' | translate | sentencecase"
				matTooltipPosition="left"
			>
				<mat-icon>note_add</mat-icon>
			</button>
		</ng-template>
		<ng-template tdDataTableTemplate="organization_type" let-value="value">
			<p>
				{{
					value
						? ('saep_ict_angular_spin8_core.page.organization.organization_type_label.' + value
						  | translate
						  | sentencecase)
						: ''
				}}
			</p>
		</ng-template>
		<ng-template tdDataTableTemplate="vat_number_or_tax_code" let-row="row">
			<p>
				<!-- <ng-container *ngFor="let destination of row.destination_list">
					<ng-container *ngIf="destination.is_registered_office && destination.address">
						{{ destination.address.country ? destination.address.country : '' }}
					</ng-container>
				</ng-container> -->
				{{ row?.tax_data?.vat_number || row?.tax_data?.tax_code || '-' }}
			</p>
		</ng-template>
		<ng-template tdDataTableTemplate="contact_list.email" let-row="row">
			<span *ngIf="utilService.returnIsMainOfList(row.contact_list)">
				{{ utilService.returnIsMainOfList(row.contact_list)['email'] }}
			</span>
		</ng-template>
	</td-data-table>
	<mat-paginator
		*ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow"
		#matPaginator
		(page)="localListHandlerApplyFilter({ pagination: $event })"
		[length]="localListHandlerData.pagination.totalFilteredItemCount"
		[pageSize]="localListHandlerData.pagination.pageSize"
		[pageSizeOptions]="[5, 10, 25, 100]"
	>
	</mat-paginator>
</ng-container>
<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<!-- ng-template -->
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
<ng-template #nothingToShow>
	<p>-</p>
</ng-template>
<ng-template #addressTemplate let-address="address">
	<p *ngIf="address && address.address; else nothingToShow">
		{{ address.address.locality }} <br />
		<small class="cell-subtitle">
			{{ address.address.zip_code ? address.address.zip_code + ' - ' : '' }}
			{{ address.address.address | sentencecase }}</small
		>
	</p>
</ng-template>
