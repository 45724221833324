<a
	*ngIf="_item.route && _item.route !== '' else itemTemplate"
	[routerLink]="[_item.route]"
>
	<ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
</a>
<!-- ng-template -->
<ng-template #itemTemplate>
	<div
		class="nav__item"
		[class.open]="sidebarIsOpen"
		[ngClass]="{ 'router-link-active': isActive }"
	>
		<ng-container *ngIf="!isSubitem">
			<mat-icon *ngIf="!_item.iconCustom; else iconCustom">{{ _item.icon }}</mat-icon>
			<ng-template #iconCustom>
				<mat-icon [svgIcon]="_item.icon"></mat-icon>
			</ng-template>
		</ng-container>
		<div class="{{ isSubitem ? 'nav__secondlevel__content-wrapper' : 'nav__firstlevel__content-wrapper' }}">
			<span class="nav__item__name">{{ _item.name | translate | sentencecase }}</span>
			<span class="flex-span"></span>
			<span class="nav__item__badge" *ngIf="_item.badge" [style.background-color]="_item.badgeColor">
				{{ _item.badge }}
			</span>
			<mat-icon
				*ngIf="_item.hasSubItems()"
				class="nav__item__dropdown"
				[class.rotate]="navigationService.isOpen(_item)"
			>
				expand_more
			</mat-icon>
		</div>
	</div>
</ng-template>