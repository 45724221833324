import moment from 'moment';
import { StatusBar } from '../../configuration-customer/order/status-aggregation-map-status-bar.constant';

export namespace OrderExportExcelBackoffice {
	export const heading = [
		['N°', 'Membro', 'Email', 'Provincia', 'Stato', 'Anno', 'Data Ordine', 'Importo', 'Data pagamento effettivo']
	];

	export const exportExcel = (list: any[]) => {
		let json = [];
		list.forEach(order => {
			let h = order.header;
			json.push({
				n: order._id.substring(order._id.length - 12).toUpperCase(),
				member: h.organization ? h.organization.business_name : '',
				email: h.organization ? h.organization.email : '',
				provincia: h.organization ? h.organization.province : '',
				status: StatusBar.statusList.find(i => i.id === h.status).label,
				year: h.order_causal_object ? h.order_causal_object.description : '',
				payment_date: h.date ? moment(h.date).toDate() : '',
				amount: h.price && h.currency ? h.price.total + ' ' + h.currency.code_item : '',
				actual_payment_date: h['actual_payment_date'] ? moment(h['actual_payment_date']).toDate() : ''
			});
		});
		return json;
	};
}
