import { Validators } from '@angular/forms';
import { FormControlMultipurposeEnum, FormControlMultipurposeModel, FormValidatorCustom } from '@saep-ict/angular-core';

export namespace TicketCenterBodyCustomField {
	export enum RequestTypeEnum {
		COMMERCIAL_REQUEST = 'COMMERCIAL_REQUEST',
		TECHNICAL_REQUEST = 'TECHNICAL_REQUEST',
		GENERAL_INFORMATIONS_REQUEST = 'GENERAL_INFORMATIONS_REQUEST',
		NOT_CONFORMITY_REPORT = 'NOT_CONFORMITY_REPORT'
	}

	export const creationFieldMap: FormControlMultipurposeModel.Item[] = [
		{
			name: 'request_type',
			on_form_template: true,
			type: FormControlMultipurposeEnum.ItemType.SELECT,
			column_width: 6,
			validator_list: [Validators.required],
			option_list: [
				{
					value: RequestTypeEnum.COMMERCIAL_REQUEST
				},
				{
					value: RequestTypeEnum.TECHNICAL_REQUEST
				},
				{
					value: RequestTypeEnum.GENERAL_INFORMATIONS_REQUEST
				},
				{
					value: RequestTypeEnum.NOT_CONFORMITY_REPORT
				}
			]
		},
		{
			name: 'organization_code_item',
            label:'Organization Code',
			on_form_template: true,
			type: FormControlMultipurposeEnum.ItemType.STRING,
			column_width: 6,
			validator_list: [Validators.required]
		},
		{
			name: 'organization_referent',
			on_form_template: true,
			type: FormControlMultipurposeEnum.ItemType.STRING,
			column_width: 6,
			validator_list: [Validators.required]
		},
		{
			name: 'organization_referent_contact_address',
			on_form_template: true,
			type: FormControlMultipurposeEnum.ItemType.STRING,
			column_width: 6,
			validator_list: [FormValidatorCustom.email, Validators.required]
		},
		{
			name: 'order_code_item',
            label: 'Order Code',
			on_form_template: true,
			type: FormControlMultipurposeEnum.ItemType.STRING,
			column_width: 6
		},
		{
			name: 'article_code_item',
			on_form_template: true,
			type: FormControlMultipurposeEnum.ItemType.STRING,
			column_width: 6
		},
		{
			name: 'ddt_code_item',
			on_form_template: true,
			type: FormControlMultipurposeEnum.ItemType.STRING,
			column_width: 6
		}
	];
}
