import { CalcSheetFile } from '../../../model/calc-sheet.model';
import { read, ParsingOptions, WorkBook, WorkSheet, utils } from 'xlsx';
import * as ForecastModel from '../../../model/forecast.model';
import * as ForecastUtil from '../../forecast-util.constant';
import { ConfigurationCustomer } from '..';
import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
export namespace ForecastCalcSheetConstants {
	export interface ForecastQuantity {
		key: number;
		value?: number;
	}

	export interface ForecastData {
		code: string;
		quantity: ForecastQuantity[];
	}
	/**
	 * Restituisce i dati di forecast a partire da un excel in formato base64
	 * @param file: CalcSheetFile
	 * @returns
	 */
	export const returnCalcSheetForecastList = (file: CalcSheetFile): ForecastData[] => {
		const data = file.data;
		const base64a = data.split(';')[1];
		const base64 = base64a.split(',')[1];
		const read_opts: ParsingOptions = { type: 'base64', dense: true };

		try {
			const wb: WorkBook = read(base64, read_opts);
			const wsn: string[] = wb.SheetNames;
			const ws: WorkSheet = wb.Sheets[wsn[0]];
			const json = utils.sheet_to_json(ws);

			return ForecastCalcSheetConstants.returnForecastData(json);
		} catch (e) {
			console.log('ERR', e);
		}
	};

	/**
	 * Restituisce i dati di forecast a partire da un json rappresentante l'excel caricato dal cliente
	 * @param json
	 * @returns
	 */
	export const returnForecastData = (json: any[]): ForecastData[] => {
		let data: ForecastData[] = [];
		const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
		json.forEach(item => {
			try {
				let quantity: ForecastQuantity[] = [];
				if (item['Ref. '] != undefined) {
					const code = item['Ref. '];
					let values = [];
					months.forEach(m => values.push(item[m]));
					let hasValues: number = 0;
					values.forEach(v => (hasValues += v ? 1 : 0));
					if (hasValues > 0) {
						values.forEach((value, index) => {
							if (value != undefined) quantity.push({ key: index + 1, value: value as number });
						});
						data.push({ code: code, quantity: quantity });
					}
				}
			} catch (err) {
				console.log('ERR', err);
			}
		});
		return data;
	};

	/**
	 * TODO: testare una volta modificata response del excel parse e dopo la creazione degli endpoint per recupero
	 * e salvataggio dei dati
	 *
	 * Restituisce una lista di oggetti da utilizzare come payload di `ForecastStateAction.save()` partendo dalla response
	 * di `ForecastCalcSheetConstants.returnCalcSheetForecastList()`
	 * @param calcSheetList
	 * @param year
	 * @param articleList
	 * @param forecast
	 * @returns
	 */
	export const returnCalcSheetForecastResponseParsedPayload = (
		calcSheetList: ForecastData[],
		year: number,
		articleList: ArticlePouchModel[]
	): Promise<ForecastModel.CalcSheetPayload> => {
		return new Promise(resolve => {
			try {
				const calcSheetPayload: ForecastModel.CalcSheetPayload = {
					payload: [],
					codeNotFoundList: [],
					codeWithInalidTimeRangeList: []
				};
				// loop sulla response di `ForecastCalcSheetConstants.returnCalcSheetForecastList()`
				for (let i = 0; i < calcSheetList.length; i++) {
					// l'elemento è considerato soltanto se associato ad un articolo esistente (su file excel la referenza avviene
					// tramite code_erp)
					const article = articleList.find(a => a.code_erp === calcSheetList[i].code);
					if (article) {
						// loop sui valori dei time range (mesi) associati all'articolo
						for (const timeRange of calcSheetList[i].quantity) {
							// returnInputDisable ritorna false, ovvero il time range e correntemente editabile
							if (!ConfigurationCustomer.Forecast.returnInputDisable(year, timeRange.key)) {
								// verifica circa la pregressa esistenza di un elemento del payload associato all'articolo
								// nel quale inserire nuovo time range in modifica
								let payloadItem = calcSheetPayload.payload.find(pi => pi.code_item === article.code_item);
								if (!payloadItem) {
									// l'elemento del payload non esiste ancora, viene creato ed inserito nel payload
									payloadItem = {
										code_item: article.code_item,
										data: []
									};
									calcSheetPayload.payload.push(payloadItem);
								}
								// viene aggiunto all'elemento del payload il valore di time range
								ForecastUtil.updateCalcSheetForecastPayloadTimeRange(payloadItem, timeRange);
							} else {
								// compilazione lista dei `code` (`code_erp`) contenenti time range non validi
								// (mese corrente o precedente)
								if (!calcSheetPayload.codeWithInalidTimeRangeList.includes(calcSheetList[i].code)) {
									calcSheetPayload.codeWithInalidTimeRangeList.push(calcSheetList[i].code);
								}
							}
						}
					} else {
						// alimentazione lista dei `code` (`code_erp`) non trovati
						calcSheetPayload.codeNotFoundList.push(calcSheetList[i].code);
					}
				}
				resolve(calcSheetPayload);
			} catch (err) {
				throw new Error(err);
			}
		});
	};
}
