<page-with-sidebar [isRoot]="true" [sideBarPosition]="sideBarPositionValues.TOP">
	<ng-container pageMain>
		<mat-card class="angelo-theme">
			<receipt-list-wrapper
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="columns"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
			>
			</receipt-list-wrapper>
		</mat-card>
	</ng-container>
	<ng-container pageSide>
		<sidebar-content-wrapper [title]="'general.filters'">
			<ng-container main-content>
				<form [formGroup]="formFilters" (ngSubmit)="onFormFiltersSubmit()" class="angelo-theme">
					<!-- <mat-form-field>
				<mat-label>{{ 'receipt.field.date' | translate | sentencecase }}</mat-label>
				<input matInput [matDatepicker]="picker" formControlName="receipt_date" />
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-datepicker #picker></mat-datepicker>
			</mat-form-field> -->

					<ng-container formGroupName="creationDate">
						<mat-form-field>
							<mat-label>{{ 'receipt.field.date' | translate | sentencecase }}</mat-label>
							<mat-date-range-input [rangePicker]="pickerCreationDate">
								<input
									matStartDate
									formControlName="start"
									placeholder="{{ 'general.time.start_date' | translate | sentencecase }}"
								/>
								<input
									matEndDate
									formControlName="end"
									placeholder="{{ 'general.time.end_date' | translate | sentencecase }}"
								/>
							</mat-date-range-input>
							<mat-datepicker-toggle matSuffix [for]="pickerCreationDate"></mat-datepicker-toggle>
							<mat-date-range-picker #pickerCreationDate></mat-date-range-picker>
						</mat-form-field>
					</ng-container>

					<ng-container formGroupName="expirationDate">
						<mat-form-field>
							<mat-label>{{ 'receipt.field.expiration_date' | translate | sentencecase }}</mat-label>
							<mat-date-range-input [rangePicker]="pickerExpirationDate">
								<input
									matStartDate
									formControlName="start"
									placeholder="{{ 'general.time.start_date' | translate | sentencecase }}"
								/>
								<input
									matEndDate
									formControlName="end"
									placeholder="{{ 'general.time.end_date' | translate | sentencecase }}"
								/>
							</mat-date-range-input>
							<mat-datepicker-toggle matSuffix [for]="pickerExpirationDate"></mat-datepicker-toggle>
							<mat-date-range-picker #pickerExpirationDate></mat-date-range-picker>
						</mat-form-field>
					</ng-container>

					<ngx-slider
						*ngIf="receiptList"
						[(value)]="minValue"
						[(highValue)]="maxValue"
						[options]="options"
					></ngx-slider>

					<!-- filtro sul tipo di documento -->
					<!-- <mat-form-field>
				<mat-label>{{ 'receipt.field.document_type' | translate | sentencecase }}</mat-label>
				<mat-select formControlName="receipt_document_type">
					<mat-option *ngFor="let item of documentTypes" [value]="item">
						{{ 'receipt.document_type.' + item | translate | sentencecase }}
					</mat-option>
				</mat-select>
			</mat-form-field> -->

					<!-- filtro sul nome dell'organizzazione -->
					<!-- <mat-form-field>
				<mat-label>{{
					'saep_ict_angular_spin8_core.page.organization.name' | translate | sentencecase
				}}</mat-label>
				<input matInput type="text" formControlName="receipt_organization_name" />
			</mat-form-field> -->
					<form-button-bar
						[align]="'right'"
						[primaryButtonLabel]="'general.apply_filters' | translate | sentencecase"
						(primaryButtonClickEmitter)="onFormFiltersSubmit()"
						[secondaryButtonLabel]="'general.reset' | translate | sentencecase"
						(secondaryButtonClickEmitter)="resetFilters()"
					></form-button-bar>
				</form>
			</ng-container>
		</sidebar-content-wrapper>
	</ng-container>
</page-with-sidebar>
