import { ArticleTableConfigurationAction } from "../../../model/article.model";

export namespace articleConfigurationAction {
    export const Configuration_B2B: ArticleTableConfigurationAction = {
    delete: true,
    validationMultiple: true,
    note: true,
    ticket: false
    }
}
