<!--
<div class="container-fluid">
	<mat-card>
		<new-order-detail></new-order-detail>
	</mat-card>
	<~~! questo blocco, con le relative logiche di visualizzaizone è stato spostato in order-detail ~~>
	<mat-card *ngIf="this.order?.header?.status === this.orderToAuthorize">
		<~~! <order-detail-main-header-edit [configuration]="this.configuration"></order-detail-main-header-edit> ~~>
		<~~! Nuovo componente di conferma testata ordine ~~>
		<order-detail-confirm *ngIf="this.user.current_permission.context_application === this.contextApplicationItemCodeEnum.BACKOFFICE"></order-detail-confirm>
	</mat-card>
	<mat-divider></mat-divider>

</div> -->
<new-order-detail></new-order-detail>
