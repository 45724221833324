import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BaseStateModel, BaseStateSerializerModel } from '@saep-ict/angular-core';
import {
	ArticlePouchModel,
	ContactPouchModel,
	ListDataPouchModel,
	OrderPouchModel,
	BodyTablePouchModel,
	OrderStatisticsList,
	CategoryMap,
	Category
} from '@saep-ict/pouch_agent_models';
import { ConnectionModel } from '../model/connection.model';
import { ConfigurationViewModel } from '../model/configuration.model';
import { ArticleRecap, ArticleRecapArticleList } from '../model/state/article-list-state.model';
import { StatisticsAgent } from '../model/statistics-agent.model';
import { StatisticsOrders } from '../model/statistics-orders.model';
import { CustomerCreditPouchModelCustom } from './company-account-balance/company-account-balance.reducer';
import { ReceiptStateModel } from '../model/state/receipt-state.model';
import { StatisticsOrganization } from '../model/statistics-organization.model';
import { StatisticsBackoffice } from '../model/statistics-backoffice.model';
import { StatisticsCrm } from '../model/statistics-crm.model';
import { StatisticsDetailOrders } from '../model/statistics-detail-orders.model';
import { StatisticsDetailSold } from '../model/statistics-detail-sold.model';
import { StatisticsDetailClients } from '../model/statistics-detail-clients.model';
import { StatisticsDetailBalance } from '../model/statistics-detail-balance.model';
import { ActionPouchModel } from '../model/state/action-state.model';
import {
	AdditionalServiceStateModel,
	AuxiliaryTabeleGeographicTree,
	AuxiliaryTableStateModel,
	ExtraFieldOrderHeaderPouchModel,
	OrderStateModel,
	ContextCodeAssociation,
	KanbanModel,
	PermissionAuxiliaryTableStateModel,
	UserActivationStateModel,
	UserDetailModel,
	OrganizationStateModel,
	OpportunityPouchModel,
	OfferStateModel,
	InformativePageModel,
	LanguageStateModel,
	ArticleRecapStockStateModel,
} from '@saep-ict/angular-spin8-core';
import { NewsModel } from '../model/news.model';
import { StatisticModel } from '../model/statistics.model';
import { StatisticGroupModel } from '../model/statistics-group.model';
import * as ForecastModel from '../model/forecast.model'

export namespace StateFeature {
	export const getConnectionState = createFeatureSelector<BaseStateModel<ConnectionModel>>('connection');

	// Login
	export const getLoginContextCodeState = createFeatureSelector<BaseStateModel<BodyTablePouchModel>>(
		'login-context-code'
	);

	// Languages
	export const getLanguageList = createFeatureSelector<BaseStateModel<LanguageStateModel[]>>('language-list');

	export const getUserState = createFeatureSelector<BaseStateModel<UserDetailModel>>('user');
	export const getAgentState = createFeatureSelector<BaseStateModel<BodyTablePouchModel>>('agent');

	// organization
	export const getOrganizationListState = createFeatureSelector<BaseStateModel<OrganizationStateModel[]>>(
		'organization-list'
	);
	export const getOrganizationState = createFeatureSelector<BaseStateModel<OrganizationStateModel>>('organization');

	export const getUserActivateState = createFeatureSelector<BaseStateModel<UserActivationStateModel>>(
		'user-activate'
	);
	export const getOrderState = createFeatureSelector<BaseStateModel<OrderStateModel>>('order');
	export const getOrderListState = createFeatureSelector<
		BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>[]>
	>('order-list');
	export const getAuxiliaryTableState = createFeatureSelector<BaseStateModel<AuxiliaryTableStateModel>>(
		'auxiliary-table'
	);
	export const getAuxiliaryTableGeographicTreeState = createFeatureSelector<
		BaseStateModel<AuxiliaryTabeleGeographicTree.StateModel>
	>('auxiliary-table-geographic-tree');
	export const getAuxiliaryTableItemState = createSelector(
		getAuxiliaryTableState,
		(state, param: BaseStateSerializerModel<AuxiliaryTableStateModel>) => state.data[param.stateSlice.type]
	);

	export const getOrderStatistics = createFeatureSelector<BaseStateModel<OrderStatisticsList>>('order-statistics');
	export const getContact = createFeatureSelector<BaseStateModel<ListDataPouchModel<ContactPouchModel>>>('contact');

	// Statistics
	export const getStatistic = createFeatureSelector<BaseStateModel<StatisticModel.Document>>('statistic');
	export const getStatisticGroup = createFeatureSelector<BaseStateModel<StatisticGroupModel.Document[]>>(
		'statistic-group'
	);
	export const getStatisticsAgent = createFeatureSelector<BaseStateModel<StatisticsAgent>>('statistics-agent');
	export const getStatisticsOrganization = createFeatureSelector<BaseStateModel<StatisticsOrganization>>(
		'statistics-organization'
	);
	export const getStatisticsCrm = createFeatureSelector<BaseStateModel<StatisticsCrm>>('statistics-crm');
	export const getStatisticsBackoffice = createFeatureSelector<BaseStateModel<StatisticsBackoffice>>(
		'statistics-backoffice'
	);
	export const getStatisticsOrders = createFeatureSelector<BaseStateModel<StatisticsOrders>>('statistics-orders');
	export const getStatisticsDetailOrders = createFeatureSelector<BaseStateModel<StatisticsDetailOrders>>(
		'statistics-detail-orders'
	);
	export const getStatisticsDetailSold = createFeatureSelector<BaseStateModel<StatisticsDetailSold>>(
		'statistics-detail-sold'
	);
	export const getStatisticsDetailClients = createFeatureSelector<BaseStateModel<StatisticsDetailClients>>(
		'statistics-detail-clients'
	);
	export const getStatisticsDetailExpired = createFeatureSelector<BaseStateModel<StatisticsDetailBalance>>(
		'statistics-detail-expired'
	);

	export const getArticleList = createFeatureSelector<BaseStateModel<ArticleRecapArticleList[]>>('article');
	export const getArticleDescription = createFeatureSelector<BaseStateModel<ArticleRecap>>('article-description');

	// CompanyAccountBalance
	export const getCompanyAccountBalance = createFeatureSelector<BaseStateModel<CustomerCreditPouchModelCustom>>(
		'company-account-balance'
	);
	// category
	export const getCategoryListState = createFeatureSelector<BaseStateModel<CategoryMap>>('category-list');
	export const getCategoryDetailState = createFeatureSelector<BaseStateModel<Category>>('category-detail');

	export const getPermissionAuxiliaryTableState = createFeatureSelector<
		BaseStateModel<PermissionAuxiliaryTableStateModel>
	>('permission-auxiliary-table');
	// context-code-association
	export const getContextCodeAssociationState = createFeatureSelector<BaseStateModel<ContextCodeAssociation>>(
		'context-code-association'
	);

	// receipts
	export const getReceiptListState = createFeatureSelector<BaseStateModel<ReceiptStateModel[]>>('receipt-list');

	// informative page
	export const getInformativePageState = createFeatureSelector<BaseStateModel<InformativePageModel[]>>(
		'informative-page'
	);

	/**
	 * Backoffice
	 */
	// user-management
	export const getUserManagementState = createFeatureSelector<BaseStateModel<UserDetailModel[]>>('user-management');
	export const getContextCodeManagementState = createFeatureSelector<BaseStateModel<BodyTablePouchModel[]>>(
		'context-code-management'
	);
	export const getArticleStockState = createFeatureSelector<BaseStateModel<ArticleRecapStockStateModel>>(
		'article-stock'
	);

	// B2C
	export const getConfigurationState = createFeatureSelector<BaseStateModel<ConfigurationViewModel>>('configuration');
	export const getSearchResultsState = createFeatureSelector<BaseStateModel<ArticlePouchModel[]>>('search-results');
	export const getB2cUserListState = createFeatureSelector<BaseStateModel<BodyTablePouchModel[]>>('b2c-user-list');
	export const getArticleDetail = createFeatureSelector<BaseStateModel<ArticlePouchModel[]>>('article-detail');
	// CRM
	export const getCrmUserListState = createFeatureSelector<BaseStateModel<BodyTablePouchModel[]>>('crm-user-list');

	/**
	 * CRM
	 */
	// Opportunity
	export const getOpportunityState = createFeatureSelector<BaseStateModel<OpportunityPouchModel[]>>('opportunity');
	export const getActionState = createFeatureSelector<BaseStateModel<ActionPouchModel[]>>('action');
	export const getOfferState = createFeatureSelector<BaseStateModel<OfferStateModel[]>>('offer');

	// Kanban
	export const getKanbanState = createFeatureSelector<BaseStateModel<KanbanModel.Base[]>>('kanban');

	//Additional Service
	export const getAdditionalService = createFeatureSelector<BaseStateModel<AdditionalServiceStateModel>>(
		'additional-service'
	);
	//Additional Service
	export const getRequestList = createFeatureSelector<BaseStateModel<OpportunityPouchModel[]>>('request-list');

	export const getNewsListState = createFeatureSelector<BaseStateModel<NewsModel[]>>('news-list');

	export const getNewsDetailState = createFeatureSelector<BaseStateModel<NewsModel>>('news-detail');

	// forecast
	export const getForecast = createFeatureSelector<BaseStateModel<ForecastModel.Document>>('forecast');
}
