import { ITdDataTableColumn } from "@covalent/core/data-table";
import {ITdDataTableColumnCustom}  from '@saep-ict/angular-core';
import { ConfigurationContactContextApplicationColumnMap } from "../../configuration-customer/contact/contact-column-map.constant";
import _ from "lodash";

export const valueToInclude = ['full_name', 'contact_details'];

export const projectOverride: ITdDataTableColumn[] = 
_.cloneDeep(ConfigurationContactContextApplicationColumnMap.baseColumns)
.filter(col => valueToInclude.includes(col.name))
