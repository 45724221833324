import { Injector } from "@angular/core";
import { AngularCoreUtilService } from "@saep-ict/angular-core";
import { ArticleCheckoutTree, ArticleCheckoutTreeParseUtil, Category } from "@saep-ict/pouch_agent_models";
import { ArticlePouchModel } from "@saep-ict/pouch_agent_models";
import _ from "lodash";
import { CategoryEnum } from "../enum/category.enum";
import { CategoryModel } from "../model/category-list.model";
import { ConfigurationCustomer } from "./configuration-customer";

const injector = Injector.create({
	providers: [
		{ provide: AngularCoreUtilService, deps: [] }
	]
});

const utilService = injector.get(AngularCoreUtilService);

export namespace ConfigurationCategory {
    // TODO: gli articoli presenti in più di una categoria vengono visualizzati soltanto nella prima
	// individuata dal flussoin ogni branch di appartenenza
    /**
     * Restituisce gli articoli annidati nel più precisono nodo dell'alberatura di categoria passata
	 * passata come parametro
     * @param articleList 
     * @param category_list 
     * @param utilTranslateService 
     * @returns 
     */
	export const returnArticleCheckoutTree = (
		articleList: ArticlePouchModel[],
		category_list: Category[],
        languageList: string[]
	): Promise<ArticleCheckoutTree[]> => {
		let articleCheckoutTreeList: ArticleCheckoutTree[];
		return ConfigurationCategory.articleCheckoutTreeRecursivelyParse(articleList, category_list)
		.then(async (res: ArticleCheckoutTreeParseUtil) => {
			articleCheckoutTreeList = res.article_checkout_tree;
			// in caso siano ancora presenti articoli non appartenenti a nessuna categoria
			// vengono organizzati in una catagoria di primo livello
			if (res.articleList && res.articleList.length > 0) {
				const articleListWithoutCategory = <ArticleCheckoutTree>{
					code_item: CategoryEnum.CodeItem.ARTICLE_LIST_WITHOUT_CATEGORY,
					article_list: res.articleList,
					language_list: []
				};
				for (let i = 0; i < languageList.length; i++) {
					articleListWithoutCategory.language_list.push({
						language: languageList[i],
						name: null,
						description: "category.articles_without_category"
					});
				}
				articleCheckoutTreeList.push(articleListWithoutCategory);
			}
			return articleCheckoutTreeList;
		});
	}
    /**
	 * Metodo ricorsivo che parsa il category-list inserendo in ogni branch gli eventuali articoli
	 * associati alla categoria
	 *
	 * @param articleList: ArticlePouchModel[]
	 * @param category_list: Category[]
	 */
	export const articleCheckoutTreeRecursivelyParse = (
		articleList: ArticlePouchModel[],
		category_list: Category[]
	): Promise<ArticleCheckoutTreeParseUtil>  => {
		return new Promise(async resolve => {
			try {
				const articleCheckoutTreeList: ArticleCheckoutTree[] = [];
				let articleListReturn: ArticlePouchModel[] = _.cloneDeep(articleList);
				for (let i = 0; i < category_list.length; i++) {
					const articleCheckoutTreeItem = <ArticleCheckoutTree>{
						code_item: category_list[i].code_item,
						language_list: category_list[i].language_list,
						level: category_list[i].level,
						category_list: [],
						article_list: []
					};
					for (let n = 0; n < articleList.length; n++) {
						if (
							articleList[n].articleDescription &&
							articleList[n].articleDescription.category_list &&
							articleList[n].articleDescription.category_list.length &&
							articleList[n].articleDescription.category_list.includes(category_list[i].code_item) &&
							(!(category_list[i].category_list && category_list[i].category_list.length) ||
								(
									await ConfigurationCategory.articleCheckoutTreeRecursivelyParse(
										[articleList[n]],
										category_list[i].category_list
									)
								).articleList.length)
						) {
							
							articleCheckoutTreeItem.article_list.push(articleList[n]);
							articleListReturn.splice(
								utilService.getElementIndex(
									articleListReturn,
									'code_item',
									articleList[n].code_item
								),
								1
							);
						}
					}
					articleList = _.cloneDeep(articleListReturn);
					if (
						articleListReturn.length &&
						category_list[i].category_list &&
						category_list[i].category_list.length
					) {
						try {
							const categoryChildren: ArticleCheckoutTreeParseUtil = await ConfigurationCategory.articleCheckoutTreeRecursivelyParse(
								articleListReturn,
								category_list[i].category_list
							);
							if (
								categoryChildren.article_checkout_tree &&
								categoryChildren.article_checkout_tree.length
							) {
								articleCheckoutTreeItem.category_list = categoryChildren.article_checkout_tree;
								articleListReturn = _.cloneDeep(categoryChildren.articleList);
							}
						} catch (err) {
							throw new Error(err);
						}
					}
					if (
						(articleCheckoutTreeItem.article_list && articleCheckoutTreeItem.article_list.length) ||
						(articleCheckoutTreeItem.category_list && articleCheckoutTreeItem.category_list.length)
					) {
						// TODO: tendenzialmente la visualizzazione degli articoli abbinata a questa struttura non permette
						// l'ordinamento esplicito. Valutare nel tempo se questa casistica possa essere indicata come default
						if (articleCheckoutTreeItem.article_list && articleCheckoutTreeItem.article_list.length) {
							articleCheckoutTreeItem.article_list.sort((a, b) => {
								if (a.articleDescription.sequence === b.articleDescription.sequence) {
									return a.articleDescription.is_tester ? 1 : -1;
								} else {
									return a.articleDescription.sequence - b.articleDescription.sequence;
								}
							});
						}
						articleCheckoutTreeList.push(articleCheckoutTreeItem);
					}
					if (!articleListReturn.length) {
						break;
					}
				}
				resolve({ article_checkout_tree: articleCheckoutTreeList, articleList: articleListReturn });
			} catch (err) {
				throw new Error(err);
			}
		});
	}

	/**
	 * Restituisce la categoria più precisa possible seguendo le opzioni di selezione offerte da `selectLevelBy`
	 * @param selectLevelBy 
	 * @param categoryList 
	 * @returns 
	 */
	export const returnCategorySelectBy =
		(selectLevelBy: CategoryModel.SelectLevelBy, categoryList: Category[]): Promise<Category> => {
			return new Promise(async resolve => {
				try {
					if (categoryList && categoryList.length && selectLevelBy) {
						if (selectLevelBy.index && selectLevelBy.index.length > 0) {
							resolve(await ConfigurationCategory.returnCategorySelectByIndex(selectLevelBy.index, categoryList));
							return;
						}
						if (selectLevelBy.propertyKey && selectLevelBy.propertyKey.length > 0) {
							// TODO: select by code_item
							return;
						}
					}
					resolve(null);
				} catch (err) {
					throw new Error(err);
				}
			});
		}

	/**
	 * Restituisce il `code_item` più preciso possible seguendo il percorso indicato dagli index di `selectLevelBy.index`
	 * @param indexList 
	 * @param categoryList 
	 * @param category 
	 * @returns 
	 */
	export const returnCategorySelectByIndex =
		(indexList: number[], categoryList: Category[], category?: Category): Promise<Category> => {
			return new Promise(async resolve => {
				try {
					if (categoryList && categoryList.length > 0 && indexList && indexList.length > 0) {
						const categorySelected: Category = _.cloneDeep(categoryList[indexList[0]]);
						if (categorySelected) {
							category = categorySelected;
							if (
								categorySelected.category_list &&
								categorySelected.category_list.length > 0 &&
								indexList.length >= 2
							) {
								indexList.splice(0, 1);
								resolve(
									await ConfigurationCategory.returnCategorySelectByIndex(
										indexList,
										categorySelected.category_list,
										category
									)
								);
								return;
							}
						}
					}
					resolve(category);
				} catch (err) {
					throw new Error(err);
				}
			});
		}
	
	export const returnCategorySelectByPropertyValue =
		(categoryList: Category[], propertyKey: string, propertyValue: number | string): Promise<Category> => {
		return new Promise(async resolve => {
			try {
				if (categoryList && categoryList.length > 0 && propertyKey && propertyValue) {
					for (const category of categoryList) {
						if (category) {
							if (category.hasOwnProperty(propertyKey) && category[propertyKey] === propertyValue) {
								resolve(category);
								return;
							}
							if (category.category_list && category.category_list.length > 0) {
								const categoryReturn: Category =
									await ConfigurationCategory.returnCategorySelectByPropertyValue(
										category.category_list,
										propertyKey,
										propertyValue
									);
								if (categoryReturn) {
									resolve(categoryReturn);
									return;
								}
							}
						}
					}					
				}
				resolve(null);
			} catch (err) {
				throw new Error(err);
			}
		});
	}
	
		/**
	 * Organizza l'alberatura di categorie aggiungendo due livelli normati presso
	 * `ConfigurationCustomer.Order.categorySectionList` (se presenti).
	 * @param categoryTree 
	 * @returns 
	 */
	export const returnCategorySectionListLevel = (
		category: ArticleCheckoutTree,
		categorySectionList: CategoryModel.SectionItem[]
	) : Promise<ArticleCheckoutTree> => {
			return new Promise(resolve => {
				try {
					for (let i = 0; i < categorySectionList.length; i++) {
						if (!category.category_list) {
							category.category_list = [];
						}
						category.category_list[i] = {
							_id: null,
							type: 'category',
							level: 'section',
							code_item: null,
							description: categorySectionList[i].title,
							children: null,
							sequence: null,
							article_list: categorySectionList[i].articleListFilter(category.article_list)
						};
					}
					category.article_list = [];
					resolve(category);
				} catch (err) {
					throw new Error(err);
				}
			})
		}

	/**
	 * Restituisce un nodo di catalogo in base al match con il code_item passato come parametro. Il metodo può agire
	 * ricorsivamente nelle liste category_list e article_list.
	 * Non è in grado di recuperare eventuali successivi nodi aventi uguale code_item.
	 * @param tree 
	 * @param code_item 
	 * @returns 
	 */
	export const returnCategoryTreeNodeByCodeItem = <D extends ArticleCheckoutTree>(tree: D[], code_item: string):
	Promise<Category | ArticlePouchModel> => {
		return new Promise(async resolve => {
			try {
				let node: Category | ArticlePouchModel;
				for (let i = 0; i < tree.length; i++) {
					if (tree[i].code_item === code_item) {
						node = tree[i]
					} else {
						if (tree[i].article_list && tree[i].article_list.length > 0) {
							node = await returnArticleByCodeItem(tree[i].article_list, code_item);
						}
						if (tree[i].category_list && tree[i].category_list.length > 0) {
							node = await returnCategoryTreeNodeByCodeItem(tree[i].category_list, code_item);
						}
					}
					if (node) { break; }
				}
				resolve(node);
			} catch (err) {
				throw new Error(err);
			}
		});
	}

	/**
	 * Restituisce un nodo di catalogo di tipo article in base al match con il code_item passato come parametro
	 * @param articleList 
	 * @param code_item 
	 * @returns 
	 */
	export const returnArticleByCodeItem = (articleList: ArticlePouchModel[], code_item: string):
	Promise<ArticlePouchModel> => {
		return new Promise(resolve => {
			try {
				const article: ArticlePouchModel = articleList.find(i => i.code_item === code_item);
				resolve(article);
			} catch (err) {
				throw new Error(err);
			}
		});
	}

}
