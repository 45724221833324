import moment from 'moment';
import { StatusBar } from '../../configuration-customer/order/status-aggregation-map-status-bar.constant';

export namespace OrderExportExcelAgent {
	export const heading = [
		['Order Number', 'Customer Reference', 'Status', 'Date', 'Business Name', 'Code', 'Shipping Address', 'Amount']
	];

	export const exportExcel = (list: any[]) => {
		let json = [];
		list.forEach(order => {
			let h = order.header;
			let dest = h.goods_destination_object;
			json.push({
				n: order._id.substring(order._id.length - 12).toUpperCase(),
				customer_reference: h.customer_order_ref_code ? h.customer_order_ref_code : '',
				status: StatusBar.statusList.find(i => i.id === h.status).label,
				date: h.date ? moment(h.date).toDate() : '',
				client: h.organization ? h.organization.business_name : '',
				code_erp: h.organization ? h.organization.code_erp : '',
				shipping_address:
					dest.address +
					' ' +
					dest.zip_code +
					' ' +
					dest.locality +
					(dest.province ? '(' + dest.province.label + ' )' : ' '),
				amount: h.price && h.currency ? h.price.total + ' ' + h.currency.code_item : ''
			});
		});
		return json;
	};
}
