import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
	AngularCoreUtilService,
	BaseState,
	BaseStateModel,
	SubscribeManagerService,
	FormControlMultipurposeModel
} from '@saep-ict/angular-core';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { StateFeature } from '../../../../state';
import { OrganizationActionEnum, OrganizationStateAction } from '../../../../state/organization/organization.actions';
import { ContextCodeEditOnChange } from '../../../../widget/context-code/context-code-edit/context-code-edit.component';
import { LocalStorage } from 'ngx-webstorage';
import { RecaptchaComponent } from '../../../../widget/recaptcha/recaptcha.component';
import { UtilAddressService } from '../../../../service/util/util-address.service';
import { ConfigurationViewModel } from '../../../../model/configuration.model';
import {
	ContextApplicationItemCodeEnum,
	FormControlMap,
	OrganizationRequestModel,
	OrganizationStateModel,
	PouchDbModel,
	ROUTE_URL,
	UserDetailModel
} from '@saep-ict/angular-spin8-core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfigurationCustomer } from '../../../../constants/configuration-customer/index';

@Component({
	selector: 'organization-registration',
	templateUrl: './organization-registration.component.html',
	styleUrls: ['./organization-registration.component.scss'],
	providers: [SubscribeManagerService]
})
export class OrganizationRegistrationComponent implements OnInit, OnDestroy {
	@ViewChild(RecaptchaComponent, { static: false }) recaptchaComponent: RecaptchaComponent;
	@LocalStorage('authenticationToken')
	authenticationToken: string;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);

	configuration$: Observable<BaseStateModel<ConfigurationViewModel>> = this.store.select(
		StateFeature.getConfigurationState
	);
	configuration: ConfigurationViewModel;

	formControlConfigurationEdit: FormControlMultipurposeModel.ConfigurationEdit;

	accountCreated: boolean = false;
	formIsValid: boolean = false;
	recaptchaResponse: string = null;
	newOrganization: OrganizationStateModel;

	ROUTE_URL = ROUTE_URL;

	checkboxForm: FormGroup;

	constructor(
		public router: Router,
		private store: Store<any>,
		public utilService: AngularCoreUtilService,
		public subscribeManagerService: SubscribeManagerService,
		private cdr: ChangeDetectorRef,
		public utilAddressService: UtilAddressService,
		private fb: FormBuilder
	) {
		this.createCheckboxForm();
		this.configuration$
			.pipe(
				filter(res => !!(res && res.data)),
				take(1)
			)
			.subscribe(res => {
				this.configuration = res.data;
			});
		this.subscribeManagerService.populate(
			this.subscribeOrganizationState().subscribe(),
			'subscribeOrganizationState'
		);
		if (this.authenticationToken) {
			this.subscribeManagerService.populate(this.subscribeUserState().subscribe(), 'subscribe-user');
		} else {
			this.setFormControlConfigurationEdit(ContextApplicationItemCodeEnum.PUBLIC);
		}
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	createCheckboxForm() {
		this.checkboxForm = this.fb.group({
			civilCodeArticles: false,
			privacyPolicy: false,
			termsAndConditions: false
		});
	}

	subscribeOrganizationState() {
		return this.organization$.pipe(
			filter((state: BaseStateModel<OrganizationStateModel>) => !!state),
			map((state: BaseStateModel<OrganizationStateModel>) => {
				switch (state.type) {
					case OrganizationActionEnum.SAVE_SUCCESS:
						this.accountCreated = true;
						break;

					case OrganizationActionEnum.ERROR:
						console.log('error');
						break;
				}
				return true;
			})
		);
	}

	subscribeUserState() {
		return this.user$.pipe(
			filter(res => !!(res && res.data)),
			take(1),
			map(res => {
				if (res.data && res.data.current_permission && res.data.current_permission.context_application) {
					switch (res.data.current_permission.context_application) {
						case ContextApplicationItemCodeEnum.CRM:
							this.setFormControlConfigurationEdit(ContextApplicationItemCodeEnum.CRM);
							break;
						default:
							this.setFormControlConfigurationEdit(ContextApplicationItemCodeEnum.B2C);
							break;
					}
				}
			})
		);
	}

	onFormSubmit() {
		if (this.configuration?.division_list) {
			this.newOrganization.division_list = this.configuration.division_list;
		}
		const request: OrganizationRequestModel = _.cloneDeep(this.newOrganization);
		request.captcha = this.recaptchaResponse;
		// Reset recaptcha
		this.recaptchaComponent.resetRecaptcha();
		this.store.dispatch(OrganizationStateAction.createItemApi(new BaseState({ organization: request })));
	}

	contextCodeEditOnChange(e: ContextCodeEditOnChange) {
		this.newOrganization = e.value;
		this.formIsValid = e.isValid;
	}

	contextCodeEditOnCancel() {
		this.router.navigate(['/']);
	}

	setFormControlConfigurationEdit(context: ContextApplicationItemCodeEnum | string) {
		const controlMapDefaultValue = _.cloneDeep(
			FormControlMap.CreationDefaultValue.ContextApplication[ContextApplicationItemCodeEnum[context]][
				PouchDbModel.PouchDbDocumentType.ORGANIZATION.toUpperCase()
			]
		);
		const defaultDivision = controlMapDefaultValue.division_list.find(div => div.is_main_of_list);
		defaultDivision.division = this.configuration.division_list.find(div => div.is_main_of_list).division;
		const formControlConfigurationEdit: FormControlMultipurposeModel.ConfigurationEdit = {
			formControlList:
				ConfigurationCustomer.Form.formControlMultipurpose[context][
					PouchDbModel.PouchDbDocumentType.ORGANIZATION.toUpperCase()
				],
			value: controlMapDefaultValue
		};
		const destination_listIndex = this.utilService.getElementIndex(
			formControlConfigurationEdit.formControlList,
			'name',
			'destination_list'
		);
		if (destination_listIndex) {
			formControlConfigurationEdit.formControlList[
				destination_listIndex
			].form_control_list = this.utilAddressService.setAddressProvinceOptionList(
				ConfigurationCustomer.Form.formControlMultipurpose[context].DESTINATION_BASE
			);
			formControlConfigurationEdit.value.destination_list = [
				FormControlMap.CreationDefaultValue.destination.create
			];
		}
		this.formControlConfigurationEdit = formControlConfigurationEdit;
	}

	returnRecaptchaResponse(recaptchaResponse: string) {
		this.formControlConfigurationEdit.value.captcha = recaptchaResponse;
		this.recaptchaResponse = recaptchaResponse;
		this.cdr.detectChanges();
	}

	returnFormIsValid() {
		const checkboxFormIsValid: boolean = Object.values(this.checkboxForm.value).every(
			checkbox => checkbox === true
		);
		if (this.formIsValid && this.recaptchaResponse && checkboxFormIsValid) {
			return true;
		}
		return false;
	}
}
