import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SubscribeManagerService, BaseStateModel, BaseState, GuidFormatterPipe } from '@saep-ict/angular-core';
import { filter, map, mergeMap, startWith, take } from 'rxjs/operators';
import {
	ActionPouchModel,
	ActionStatusEnum,
	ActionTypeEnum,
	ActionTypes
} from '../../../../model/state/action-state.model';
import { ActionStateAction, ActionActionEnum } from '../../../../state/action/action.actions';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../../state';
import { ContactPouchModel, ListDataPouchModel } from '@saep-ict/pouch_agent_models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactStateAction } from '../../../../state/contact/contact.actions';
import moment from 'moment';
import _ from 'lodash';
import { MatSelectChange } from '@angular/material/select';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogSelectAssegneeComponent } from '../../../../widget/dialog/dialog-select-assegnee/dialog-select-assegnee.component';
import { TranslateService } from '@ngx-translate/core';
import { StatusBarOrderService } from '../../../../service/status-bar-config/implementation/status-bar-order.service';
import { DialogSelectContactComponent } from '../../../../widget/dialog/dialog-select-contact/dialog-select-contact.component';
import { DialogSelectOpportunityComponent } from '../../../../widget/dialog/dialog-select-opportunity/dialog-select-opportunity.component';
import { Location } from '@angular/common';
import { ContactModel } from '../../../../model/contact.model';
import { AdaptiveLoadingState } from '../../../../model/state/conditional-loading-state.model';
import { OpportunityStateAction } from '../../../../state/opportunity/opportunity.actions';
import { LocalStorage } from 'ngx-webstorage';
import {
	OpportunityEnum,
	ROUTE_URL,
	UserDetailModel,
	OpportunityPouchModel,
	AngularSpin8CoreActionService
} from '@saep-ict/angular-spin8-core';
import { AppUtilService } from '../../../../service/util/app-util.service';
import { UtilBreadcrumbService } from '../../../../service/util/util-breadcrumb.service';
import { TopBarComponent } from '../../../../widget/top-bar/top-bar.component';

@Component({
	selector: 'action-detail',
	templateUrl: './action-detail.component.html',
	styleUrls: ['./action-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class ActionDetailComponent implements OnInit, OnDestroy {
	@LocalStorage('user') private user: UserDetailModel;

	action: ActionPouchModel;
	action$: Observable<BaseStateModel<ActionPouchModel[]>> = this.store.select(StateFeature.getActionState);

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);

	contact$: Observable<BaseStateModel<ListDataPouchModel<ContactPouchModel>>> = this.store.select(
		StateFeature.getContact
	);
	referentList: ContactPouchModel[] = [];

	idAction: string;
	ActionTypes = ActionTypes;
	OpportunityEnum = OpportunityEnum;
	ActionStatusEnum = ActionStatusEnum;
	selectedAssegnee: UserDetailModel;
	selectedContact: ContactPouchModel;
	selectedOpportunityId: string;

	form: FormGroup;

	public backButtonRoute;

	now = this.formatDatetime(new Date());
	actionTypeEnum = ActionTypeEnum;
	isLogs: boolean = false;
	backPath: string;

	constructor(
		private store: Store<any>,
		public route: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private dialog: MatDialog,
		public translate: TranslateService,
		private subscribeManagerService: SubscribeManagerService,
		private utilService: AppUtilService,
		public statusBarOrderService: StatusBarOrderService,
		private guidFormatterPipe: GuidFormatterPipe,
		private location: Location,
		private actionService: AngularSpin8CoreActionService,
		private utilBreadcrumbService: UtilBreadcrumbService
	) {
		this.isLogs = this.router.url.includes(ROUTE_URL.logs);
		this.backPath =  this.router.createUrlTree(['../'], { relativeTo: this.route }).toString();
		//this.utilBreadcrumbService.title = this.translate.instant((this.idAction == 'new' ? 'action.new' : 'action.type.' + this.action?.action_type));
	}

	ngOnInit() {
		this.getBackButtonRoute();

		this.user$.pipe(take(1)).subscribe(res => {
			this.selectedAssegnee = res ? res.data : null;
		});

		this.store.dispatch(ContactStateAction.loadAll());
		// this.store.dispatch(OpportunityStateAction.loadAll());
		// this.subscribeManagerService.populate(this.subscribeContactList().subscribe(), 'subscribeContactList');

		// subscribe
		this.subscribeManagerService.populate(this.subscribeAction().subscribe(), 'subscribeAction');
	}

	ngOnDestroy() {
		this.store.dispatch(ActionStateAction.reset());
		this.subscribeManagerService.destroy();
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	/**
	 * Form
	 */

	getFormControl(key: string): FormControl {
		return <FormControl>this.form.get(key);
	}

	displayFn(contact: ContactModel): string {
		return contact
			? contact.full_name + (contact.organization ? ' | ' + contact.organization.business_name : '')
			: '';
	}

	getBackButtonRoute() {
		this.backButtonRoute = true;
		// if (this.route.snapshot.paramMap.get('actionLevel') === ActionLevelEnum.LEAD) {
		// 	this.backButtonRoute =
		// 		ROUTE_URL.private +
		// 		'/' +
		// 		ROUTE_URL.action +
		// 		'/' +
		// 		this.route.snapshot.paramMap.get('actionLevel');
		// } else {
		// 	this.backButtonRoute = ROUTE_URL.private + '/' + ROUTE_URL.actionKanban;
		// }
	}

	filteredContacts: Observable<ContactModel[]>;
	createForm() {
		this.form = this.fb.group({
			id: ['', { validators: [] }],
			contact: ['', { validators: [Validators.required] }],
			assegnee: ['', { validators: [Validators.required] }],
			start_date: ['', { validators: [Validators.required] }],
			close_date: ['', { validators: [] }],
			action_type: ['', { validators: [Validators.required] }],
			estimated_close_date: ['', { validators: [] }],
			text: ['', { validators: [] }],
			answer: ['', { validators: [] }],
			status: ['', { validators: [] }],
			opportunity: ['', { validators: [] }],
			subject: [''],
			added_by: ['', { validators: [] }]
		});

		this.disableFieldList(['id', 'added_by']);

		this.filteredContacts = this.getFormControl('contact').valueChanges.pipe(
			startWith(''),
			map(state =>
				state && typeof state == 'string'
					? this._filterContacts(state)
					: (this.referentList.slice() as ContactModel[])
			)
		);

		this.form.get('action_type').valueChanges.subscribe(value => {
			if (value && this.actionTypeEnum.ML === value.code) {
				this.getFormControl('subject').setValidators(Validators.required);
			} else {
				this.getFormControl('subject').clearValidators();
				this.getFormControl('subject').updateValueAndValidity();
			}
		});

		if (this.idAction === ROUTE_URL.newAction) {
			this.populateDefaultData();
		}
	}
	private _filterContacts(value: string): ContactModel[] {
		const filterValue = value.toLowerCase();

		return this.referentList.filter(contact =>
			contact.full_name.toLowerCase().includes(filterValue)
		) as ContactModel[];
	}

	populateDefaultData() {
		this.form.patchValue(
			{
				start_date: this.now,
				close_date: this.now,
				action_type: this.ActionTypes.find(at => at.code == ActionTypeEnum.TL),
				estimated_close_date: this.formatDatetime(
					this.addDays(new Date(), this.ActionTypes.find(at => at.code == ActionTypeEnum.TL).estimated_days)
				),
				assegnee: `${this.utilService.getUserNameFormatted(this.selectedAssegnee)}`,
				status: ActionStatusEnum.E
			},
			{ emitEvent: false }
		);
	}

	disableFieldList(fieldList: string[]) {
		fieldList.forEach(nameField => {
			this.form.get(nameField)?.disable({ emitEvent: false });
		});
	}

	setFormFromRemoteData() {
		let contact: ContactPouchModel = null;

		if (this.referentList && this.action.contact_id) {
			contact = this.referentList.find(contact => contact.code_item === this.action.contact_id);
		}

		// Compongo assegnee name
		let assegneeName = '';
		if (this.action.assegnee_full_name) {
			assegneeName = `${this.action.assegnee_full_name}`;
		}

		this.form.patchValue(
			{
				id: this.guidFormatterPipe.transform(this.action._id || ''),
				contact: this.action.contact_id ? contact : null,
				assegnee: assegneeName,
				start_date: this.action.start_date ? this.formatDatetime(new Date(this.action.start_date)) : null,
				close_date: this.action.close_date ? this.formatDatetime(new Date(this.action.close_date)) : null,
				action_type: this.ActionTypes.find(at => at.code == this.action.action_type),
				estimated_close_date: this.action.estimated_close_date
					? this.formatDatetime(new Date(this.action.estimated_close_date))
					: null,
				text: this.action.text,
				answer: this.action.answer,
				status: this.action.status,
				opportunity: this.guidFormatterPipe.transform(this.action.opportunity_id || ''),
				subject: this.action.subject,
				added_by: this.action.added_by ? this.action.added_by : null
			},
			{ emitEvent: false }
		);

		// Disattivo il form se la action è ESAURITA
		// if (this.action.status == ActionStatusEnum.E) {
		// 	this.form.disable({ emitEvent: false });
		// } else {
		// 	this.form.enable({ emitEvent: false });
		// }

		// explicitely update value of action_type, to trigger valueChanges
		this.getFormControl('action_type').setValue(this.ActionTypes.find(at => at.code == this.action.action_type));
	}

	saveAction() {
		if (this.form.valid) {
			const loadActionList = this.form.value.action_type.code !== ActionTypeEnum.ML;
			const actionToSave = this.prepareSaveForm();
			this.store.dispatch(ActionStateAction.save(new AdaptiveLoadingState([actionToSave], null, loadActionList)));
			if (actionToSave && actionToSave.action_type !== this.actionTypeEnum.ML) {
				this.location.back();
			}
		} else {
			this.utilService.showDialog('general.save_missing_data');
		}
	}

	prepareSaveForm() {
		const formValue = this.form.getRawValue();

		// initialization
		let newAction: ActionPouchModel = this.action ? _.cloneDeep(this.action) : <ActionPouchModel>{};

		newAction.type = 'action';
		newAction.date_creation = newAction.date_creation || moment().valueOf();
		newAction.start_date = formValue.start_date ? new Date(formValue.start_date).valueOf() : null;
		newAction.close_date = formValue.estimated_close_date
			? null
			: formValue.close_date
			? new Date(formValue.close_date).valueOf()
			: null;
		newAction.action_type = formValue.action_type ? formValue.action_type.code : null;
		newAction.estimated_close_date = formValue.estimated_close_date
			? new Date(formValue.estimated_close_date).valueOf()
			: null;
		newAction.text = formValue.text ? formValue.text : null;
		newAction.answer = formValue.answer ? formValue.answer : null;
		newAction.status = formValue.status;
		// newAction.opportunity_id = formValue.opportunity ? this.action.opportunity_id : null;
		newAction.subject = formValue.subject ? formValue.subject : null;

		if (newAction.status == ActionStatusEnum.E) {
			newAction.close_date = new Date().valueOf();
			newAction.estimated_close_date = new Date().valueOf();
		}

		// opportunity
		if (this.selectedOpportunityId) {
			newAction.opportunity_id = this.selectedOpportunityId || null;
		}

		// contact
		// if (this.selectedContact) {
		newAction.contact_id = formValue.contact?.code_item || null;
		newAction.contact_name = formValue.contact?.full_name || null;
		// }

		// assegnee
		if (this.selectedAssegnee) {
			newAction.assegnee_user_id = this.selectedAssegnee.id || null;
			newAction.assegnee_full_name = `${this.utilService.getUserNameFormatted(this.selectedAssegnee)}`;
		}
		if (this.user && !newAction.added_by) {
			newAction.added_by = this.user.first_name
				? this.user.first_name + ' ' + this.user.last_name
				: this.user.username;
		}

		this.utilService.deleteEmptyProperties(newAction);

		// console.log(newAction);
		return newAction;
	}

	updateFormFieldValue(key: string, value: any) {
		const formField = {};
		formField[key] = _.cloneDeep(value);
		this.form.patchValue(formField);
	}

	getFullname(contact) {
		if (!contact.full_name) {
			contact.full_name = contact.first_name + ' ' + contact.last_name;
		}
		return contact.full_name;
	}

	canSaveForm() {
		if (!this.action || (this.action && !this.action.close_date)) {
			return true;
		}
		return false;
	}

	canPromoteForm() {
		// if (
		// 	this.action &&
		// 	this.action._id &&
		// 	this.route.parent.snapshot.paramMap.get('actionLevel') === this.actionLevelEnum.LEAD &&
		// 	!this.action.close_date
		// ) {
		// 	return true;
		// }
		return false;
	}

	canCloseForm() {
		if (this.action && this.action._id && !this.action.close_date) {
			return true;
		}
		return false;
	}

	referentChange(event: MatSelectChange) {
		if (event) {
			if (!this.action) {
				this.action = <ActionPouchModel>{};
			}
			this.action.contact_id = event.value._id;

			this.setRouteMetaInformation();
		}
	}

	actionTypeChange(event: MatSelectChange) {
		if (event) {
			if (!this.action) {
				this.action = <ActionPouchModel>{};
			}
			this.action.action_type = event.value.code;
			if (this.action.action_type == ActionTypeEnum.OT) {
				this.form.patchValue({
					estimated_close_date: this.formatDatetime(
						new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59)
					),
					status: ActionStatusEnum.S
				});
			} else {
				this.form.patchValue({
					estimated_close_date: this.formatDatetime(this.addDays(new Date(), event.value.estimated_days))
				});
			}

			this.setRouteMetaInformation();
		}
	}

	/**
	 * Subscription
	 */

	// subscribeContactList() {
	// 	return this.contact$.pipe(
	// 		filter((state: BaseStateModel<ListDataPouchModel<ContactPouchModel>>) => !!state),
	// 		map((state: BaseStateModel<ListDataPouchModel<ContactPouchModel>>) => {
	// 			this.referentList = state.data.docs;
	// 			return state;
	// 		})
	// 	);
	// }

	subscribeAction() {
		return this.contact$.pipe(
			filter((state: BaseStateModel<ListDataPouchModel<ContactPouchModel>>) => !!state),
			mergeMap((state: BaseStateModel<ListDataPouchModel<ContactPouchModel>>) => {
				this.referentList = state.data.docs;
				this.referentList.map(contact => {
					if (!contact.full_name) {
						contact.full_name = contact.first_name + ' ' + contact.last_name;
					}
				});
				// console.log('referentList', this.referentList);
				return this.route.paramMap;
			}),
			mergeMap(params => {
				this.idAction = params.get('idAction');
				this.setRouteMetaInformation();
				// populate form if not new
				this.createForm();
				if (!this.idAction.includes(ROUTE_URL.newAction)) {
					this.store.dispatch(ActionStateAction.load({ data: { _id: this.idAction } }));
				}
				if (this.route.snapshot.queryParams.contact_id) {
					this.selectedContact = this.referentList.find(
						contact => contact.code_item === this.route.snapshot.queryParams.contact_id
					);
					if (this.selectedContact) {
						this.form.patchValue({
							contact: this.selectedContact
						});
					}
				}
				if (this.route.snapshot.queryParams.opportunity_id) {
					this.selectedOpportunityId = this.route.snapshot.queryParams.opportunity_id;
					if (this.selectedOpportunityId) {
						this.form.patchValue({
							opportunity: this.guidFormatterPipe.transform(this.selectedOpportunityId)
						});
					}
				}
				return this.action$;
			}),
			filter((actionState: BaseStateModel<ActionPouchModel[]>) => !!(actionState && actionState.data)),
			map(actionState => {
				switch (actionState.type) {
					case ActionActionEnum.UPDATE:
						// Se salvo la prima volta faccio il redirect per aggiornare l'url con il nuovo id
						if (
							(!this.action || !this.action._id) &&
							actionState.data.length == 1 &&
							actionState.data[0]._id
						) {
							this.router.navigate(['../' + actionState.data[0]._id], { relativeTo: this.route });
						}

						// Se cambia la tipologia di action cambio anche il param
						// if (this.action && this.action.level !== actionState.data[0].level) {
						// 	const currentType = this.action.level;
						// 	const newType = actionState.data[0].level;
						// 	this.actionLevel = newType;
						// 	this.router.navigate([this.router.url.replace(currentType, newType)]);
						// }
						if (this.idAction !== ROUTE_URL.newAction) {
							this.action = actionState.data[0];
							this.setFormFromRemoteData();
							this.setRouteMetaInformation();
						}
						break;
					case ActionActionEnum.ERROR:
						this.utilService.showDialog('action.save_error');
						break;
				}
			})
		);
	}

	/**
	 * Dialog
	 */

	openDialogSelectAssegnee() {
		const dialogRef: MatDialogRef<DialogSelectAssegneeComponent> = this.dialog.open(DialogSelectAssegneeComponent, {
			disableClose: true,
			panelClass: 'dialog-medium'
		});
		dialogRef.afterClosed().subscribe((assegnee: UserDetailModel) => {
			if (assegnee) {
				this.selectedAssegnee = assegnee;
				this.updateFormFieldValue(
					'assegnee',
					// `${assegnee.username} - ${this.utilService.getUserNameFormatted(assegnee)}`
					`${this.utilService.getUserNameFormatted(assegnee)}`
				);
			}
		});
	}

	// openDialogSelectContact() {
	// 	const dialogRef: MatDialogRef<DialogSelectContactComponent> = this.dialog.open(DialogSelectContactComponent, {
	// 		disableClose: true,
	// 		panelClass: 'dialog-medium'
	// 	});
	// 	dialogRef.afterClosed().subscribe((contact: ContactPouchModel) => {
	// 		if (contact) {
	// 			this.selectedContact = contact;
	// 			this.updateFormFieldValue('contact', this.getReferentDescription(contact));
	// 		}
	// 	});
	// }

	openDialogSelectOpportunity() {
		const dialogRef: MatDialogRef<DialogSelectOpportunityComponent> = this.dialog.open(
			DialogSelectOpportunityComponent,
			{
				disableClose: true,
				panelClass: 'dialog-medium'
			}
		);
		dialogRef.afterClosed().subscribe((opportunity: OpportunityPouchModel) => {
			if (opportunity) {
				this.selectedOpportunityId = opportunity._id;
				// this.updateFormFieldValue('opportunity', opportunity);
				this.updateFormFieldValue('opportunity', this.guidFormatterPipe.transform(opportunity._id));
			}
		});
	}

	openDialogCloseAction() {
		// const dialogRef: MatDialogRef<DialogCloseActionComponent> = this.dialog.open(DialogCloseActionComponent, {
		// 	disableClose: true,
		// 	data: { actionLevel: this.route.parent.snapshot.paramMap.get('actionLevel') }
		// });
		// dialogRef.afterClosed().subscribe((res: ActionStatusEnum) => {
		// 	if (res) {
		// 		const newAction = _.cloneDeep(this.action);
		// 		// newAction.status = res;
		// 		newAction.close_date = moment().valueOf();
		// 		this.store.dispatch(ActionStateAction.save(new BaseState([newAction])));
		// 	}
		// });
	}

	/**
	 * Utilities
	 */
	selectOption(option: any, value: any): boolean {
		if (value) {
			return option.code_item === value.code_item;
		}
		return false;
	}

	resetSelectField(event, controlName: string) {
		this.form.get(controlName).setValue('');
		event.stopPropagation();
	}

	// changeStatusActionTo(level: ActionLevelEnum) {
	// 	let action: ActionPouchModel = _.cloneDeep(this.action);
	// 	// action.level = level;
	// 	this.store.dispatch(ActionStateAction.save(new BaseState([action])));
	// }

	getReferentDescription(referent) {
		let text = '';
		if (referent.full_name) {
			text += referent.full_name;
		}
		if (referent.email) {
			text += (text ? ' - ' : '') + referent.email;
		}
		if (referent.role) {
			text += (text ? ' - ' : '') + referent.role;
		}
		return text;
	}

	localizeDate(date: Date) {
		date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
	}

	formatDatetime(date: Date) {
		this.localizeDate(date);
		return date.toISOString().slice(0, 16);
	}

	addDays(date: Date, days: number) {
		var result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	}

	async sendAction() {
		this.actionService.sendAction(null, { code_item: this.action.code_item });
		this.router.navigate(['../'], { relativeTo: this.route });
	}

	setRouteMetaInformation() {
		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle(
			this.isLogs ? 'log' : 'action'
		);
		if (this.idAction == 'new') {
			this.utilBreadcrumbService.subtitle.value = 'action.new';
		} else if (this.action?.action_type) {
			this.utilBreadcrumbService.subtitle.value = 'action.type.' + this.action.action_type;
		}
	}
}
