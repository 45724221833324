import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import { Store } from '@ngrx/store';
import { BaseState, BaseStateModel, FileTypeEnum,  SentencecasePipe, SubscribeManagerService } from '@saep-ict/angular-core';
import { OrderRowModel, OrganizationStateModel, UserDetailModel } from '@saep-ict/angular-spin8-core';
import { LocalListHandlerBaseModel, Category, CategoryMap, ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import { debounceTime, filter, map, mergeMap, Observable, Subject } from 'rxjs';
import { DialogUploadFileComponent } from '../../../widget/dialog/dialog-upload-file/dialog-upload-file.component';
import { ConfigurationCategory } from '../../../constants/category.constant';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';
import { ConfigurationSubscribeManager } from '../../../constants/subscribe-manager.constant';
import { CategoryModel } from '../../../model/category-list.model';
import { SubscribeManagerItem } from '../../../model/subscribe-manager.model';
import { StoreUtilService } from '../../../service/util/store-util.service';
import { UtilCategoryListService } from '../../../service/util/util-category-list.service';
import { StateFeature } from '../../../state';
import { ArticleActionEnum, ArticleStateAction } from '../../../state/article/article.actions';
import { CategoryListActionEnum } from '../../../state/category-list/category-list.actions';
import * as ForecastModel from '../../../model/forecast.model';
import { ForecastCalcSheetConstants } from '../../../constants/configuration-customer/forecast/forecast-calc-sheet.constants';
import { BucketManagerService } from '../../../service/util/util-bucket-manager.service';
import { ForecastActionEnum, ForecastStateAction } from '../../../state/forecast/forecast.action';
import { TranslateService } from '@ngx-translate/core';
import * as Util from '../../../constants/util.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSnackBarWrapperComponent } from '../../../widget/mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import * as ForecastUtil from '../../../constants/forecast-util.constant';
import { UtilBreadcrumbService } from './../../../service/util/util-breadcrumb.service';
import moment from 'moment';

@Component({
	selector: 'forecast',
	templateUrl: './forecast.component.html',
	styleUrls: ['./forecast.component.scss'],
	providers: [SubscribeManagerService]
})
export class ForecastComponent implements OnInit, OnDestroy {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(StateFeature.getOrganizationState);
	organization: OrganizationStateModel;

	categoryList$: Observable<BaseStateModel<CategoryMap>> = this.store.select(StateFeature.getCategoryListState);
	categoryList: Category[];
	categoryListParseVisualization: Category[];

	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);
	articleList: ArticlePouchModel[];
	articleListWithForecast: ArticlePouchModel[];

	listPageBaseData: LocalListHandlerBaseModel<ArticlePouchModel, ForecastModel.LocalListHandlerFilter>;

	forecast$: Observable<BaseStateModel<ForecastModel.Document>> = this.store.select(StateFeature.getForecast);
	forecast: ForecastModel.Document;

	configurationCustomer = ConfigurationCustomer;

	form: FormGroup;
	formDateSelectorList: number[] = ConfigurationCustomer.Forecast.returnDateSelectorList();

	excelUpdate = false;

	forecastChangeTemp: ForecastModel.Document;
	forecastUpdateSource = new Subject<ForecastModel.Document>();
	forecastUpdate$: Observable<ForecastModel.Document> = this.forecastUpdateSource.asObservable();

	constructor(
		private store: Store,
		private subscribeManagerService: SubscribeManagerService,
		private utilStoreService: StoreUtilService,
		private utilCategoryListService: UtilCategoryListService,
		private fb: FormBuilder,
		private dialog: MatDialog,
		public bucketManager: BucketManagerService,
		private sentencecasePipe: SentencecasePipe,
		private translate: TranslateService,
		private snackBar: MatSnackBar,
		private utilBreadcrumbService: UtilBreadcrumbService
	) {
		this.formCreate();
		this.loadStaticData();
		this.storeDispatch();
		const subscribeList: SubscribeManagerItem[] = [
			{ key: 'data', observable: this.subscribeData() },
			{ key: 'form-change', observable: this.subscribeFormChange() },
			{ key: 'forecast-change', observable: this.subscribeForecastChange() }
		];
		ConfigurationSubscribeManager.init(subscribeList, this.subscribeManagerService);
		this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['survey_center', 'survey_center_forecast']);
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
		this.store.dispatch(ArticleStateAction.reset());
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	storeDispatch() {
		this.store.dispatch(
			ArticleStateAction.loadFromRecap(
				{
					dataSetting: {
						appliedFilter: {
							organization: this.organization
						}
					},
					data: null
				}
			)
		);
		this.storeForecastDispatchLoad(this.formDateSelectorList[0]);
	}

	loadStaticData() {
		this.utilStoreService.retrieveSyncState<UserDetailModel>(this.user$).subscribe(res => {
			this.user = res.data;
		});
		this.utilStoreService.retrieveSyncState<OrganizationStateModel>(this.organization$).subscribe(e => {
			this.organization = e.data;
		});
	}

	subscribeData(): Observable<void> {
		return this.categoryList$.pipe(
			filter((e: BaseStateModel<CategoryMap>) => e && e.type !== CategoryListActionEnum.LOAD_ALL),
			mergeMap((e: BaseStateModel<CategoryMap>) => {
				switch (e.type) {
					case CategoryListActionEnum.ERROR:
						throw new Error(CategoryListActionEnum.ERROR);
					default:
						this.categoryList = e.data.tree;
				}
				return this.articleList$;
			}),
			filter((e: BaseStateModel<ArticlePouchModel[]>) => e && e.type !== ArticleActionEnum.LOAD_FROM_RECAP),
			mergeMap((e: BaseStateModel<ArticlePouchModel[]>) => {
				switch (e.type) {
					case ArticleActionEnum.ERROR:
						throw new Error(CategoryListActionEnum.ERROR);
					case ArticleActionEnum.UPDATE:
						this.articleList = e.data;
						return this.forecast$;
				}
			}),
			filter((e: BaseStateModel<ForecastModel.Document>) => !!(e && e.data)),
			mergeMap(async (e: BaseStateModel<ForecastModel.Document>) => {
				switch (e.type) {
					case ForecastActionEnum.ERROR:
						throw new Error(ForecastActionEnum.ERROR);
					case ForecastActionEnum.UPDATE:
						this.forecast = _.cloneDeep(e.data);
						this.setForecastList(e.data);
						break;
					case ForecastActionEnum.SAVE_COMPLETE:
						this.forecast = _.cloneDeep(e.data);
						if (this.excelUpdate) {
							this.excelUpdate = false;
							this.setForecastList(e.data);
						}
						break;
				}
				this.utilBreadcrumbService.title = {
					value: 'forecast.page_title',
					param: {
						year: e.data.year
					}
				}
				this.utilBreadcrumbService.subtitle =
					e.data.date_update ?
					{
						value: 'general.last_update_date',
						param: {
							date: moment(e.data.date_update).format('DD/MM/YYYY')
						}
					} :
					{
						value: 'general.not_yet_sent'
					};
			})
		);
	}

	subscribeFormChange(): Observable<void> {
		return this.form.valueChanges.pipe(
			debounceTime(500),
			map(e => {
				this.storeForecastDispatchLoad(e.dateSelector);
			})
		);
	}

	subscribeForecastChange(): Observable<void> {
		return this.forecastUpdate$.pipe(
			debounceTime(2000),
			map((e: ForecastModel.Document) => {
				this.forecastChangeTemp = null;
				this.store.dispatch(ForecastStateAction.save(new BaseState(_.cloneDeep(e))));
			})
		);
	}

	storeForecastDispatchLoad(year: number) {
		this.store.dispatch(
			ForecastStateAction.load(
				{
					year: year,
					user_code_item: this.user.current_permission.context_code.code
				}
			)
		);
	}

	async setForecastList(forecast: ForecastModel.Document) {
		this.articleListWithForecast = _.cloneDeep(this.articleList);

		for (const f of forecast.values) {
			const article: ArticlePouchModel = this.articleListWithForecast.find(i => i.code_item === f.code_item);
			if (article) {
				article.forecast = f.data;
			}
		}

		this.categoryListParseVisualization =
			await ConfigurationCustomer.Forecast.returnNestedCategoryParse(this.user, this.categoryList);
		const selectLevelBy: CategoryModel.SelectLevelBy =
			_.cloneDeep(ConfigurationCustomer.Forecast.categoryTabOnTopConfiguration.initialization.selectLevelBy);
		const categorySelectBy: Category =
			await ConfigurationCategory.returnCategorySelectBy(selectLevelBy, this.categoryList);

		this.listPageBaseData = {
			pageName: 'order-detail',
			filters: {
				localSearchText: {
					value: null,
					key_list: ConfigurationCustomer.AppStructure.Erp.has_erp
						? [
								'articleDescription.language_list.description',
								'code_erp',
								'articleDescription.relatedArticleTester.code_erp'
						]
						: [
								'articleDescription.language_list.description',
								'code_item',
								'articleDescription.relatedArticleTester.code_item'
						]
				},
				customFilters: {
					categoryList: [categorySelectBy],
					forecastYear: this.returnFormDateSelectorValue()
				}
			},
			sort: {
				name: 'articleDescription.sequence',
				order: TdDataTableSortingOrder.Ascending
			},
			columnList: ConfigurationCustomer.Forecast.returnArticleColumnList(
				this.user.current_permission.context_application
			),
			data:
				categorySelectBy ?
				this.utilCategoryListService.returnArticleListFilteredByCategoryList(
					this.articleListWithForecast,
					[categorySelectBy.code_item]
				) :
				this.articleListWithForecast
		};

		this.listPageBaseData = _.cloneDeep(this.listPageBaseData);

	}

	async categorySelectedChange(e: Category) {
		this.listPageBaseData.data =
			e.code_item ?
			this.utilCategoryListService.returnArticleListFilteredByCategoryList(this.articleListWithForecast, [e.code_item]) :
			this.articleListWithForecast;
		const categorySelected: Category = await ConfigurationCategory.returnCategorySelectByPropertyValue(
			this.categoryList,
			'code_item',
			e.code_item
		);
		this.listPageBaseData.filters.customFilters.categoryList = categorySelected ? [categorySelected] : [];
		this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
	}

	async onForecastChangeHandler(e: ForecastModel.CodeItemUpdate[]) {
		this.forecastChangeTemp =
			this.forecastChangeTemp ?
			this.forecastChangeTemp :
			_.cloneDeep(this.forecast);
		this.forecastChangeTemp.values = await ForecastUtil.updateForecastList(e, this.forecastChangeTemp.values);
		this.forecastChangeTemp.date_update = Date.now();
		this.forecastUpdateSource.next(this.forecastChangeTemp);
	}

	formCreate() {
		this.form = this.fb.group({
			dateSelector: [this.formDateSelectorList[0]]
		});
	}

	returnFormDateSelectorValue() {
		return this.form.get('dateSelector').value;
	}

	openDialogUploadForecast() {
		const dialogRef = this.dialog.open(DialogUploadFileComponent, {
			data: {
				acceptedType: FileTypeEnum.EXCEL
			},
			disableClose: true,
			panelClass: ['dialog-normal', 'angelo-theme-dialog']
		});
		dialogRef.afterClosed().subscribe(async e => {
			if (e) {
				const data = ForecastCalcSheetConstants.returnCalcSheetForecastList(e);
				const calcSheetPayload =
					await ForecastCalcSheetConstants.returnCalcSheetForecastResponseParsedPayload(
						data,
						this.returnFormDateSelectorValue(),
						this.articleList
					);
				let message: string;
				message = this.sentencecasePipe.transform(
					`${this.translate.instant('forecast.message.added_to_forecast_or_modified')}:`
				);
				if (calcSheetPayload.payload.length > 0) {
					this.excelUpdate = true;
					this.onForecastChangeHandler(calcSheetPayload.payload);
					message = message + ` ${calcSheetPayload.payload.length}`;
				} else {
					message = message + ' 0';
				}
				if (calcSheetPayload.codeNotFoundList.length > 0) {
					message = message + '<br><br>';
					message = message + this.sentencecasePipe.transform(`${this.translate.instant('forecast.message.not_found_plural')}:`);
					message = Util.returnMessageCodeUl(message, calcSheetPayload.codeNotFoundList);
				}
				if (calcSheetPayload.codeWithInalidTimeRangeList.length > 0) {
					message = message + this.sentencecasePipe.transform(`${this.translate.instant('forecast.message.with_invalid_time_range')}:`);
					message = Util.returnMessageCodeUl(message, calcSheetPayload.codeWithInalidTimeRangeList);
				}
				this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
					duration: null,
					data: {
						message: message,
						action:'OK'
					}
				});
			}
		});
	}

}
