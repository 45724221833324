import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AuthService } from '../service/rest/auth.service';
import { OrganizationColumnService } from '../service/td-data-table/implementation/organization-list.service';
import { OpportunityListColumnService } from '../service/td-data-table/implementation/opportunity-list.service';
import { ProductKitColumnService } from '../service/td-data-table/implementation/product-catalog.service';
import { SharedModule } from '../shared/shared.module';
import { WidgetModule } from '../widget/widget.module';
import { PageAgentModule } from './agent/page-agent.module';
import { PageB2BModule } from './b2b/page-b2b.module';
import { PageBackofficeModule } from './backoffice/page-backoffice.module';
import { PageCommonsModule } from './commons/page-commons.module';
import { CompanyDetailFullDetailPaymentsColumnService } from '../service/td-data-table/implementation/company-detail.service';
import { CompanyAccountBalanceColumnService } from '../service/td-data-table/implementation/company-account-balance.service';
import { ContactColumnService } from '../service/td-data-table/implementation/contact.service';
import { PageStorefrontModule } from './b2c/storefront/page-storefront.module';
import { AddressListColumnService } from '../service/td-data-table/implementation/reserved-area/address-list.service';
import { UserManagementListColumnService } from '../service/td-data-table/implementation/backoffice/user-management-list.service';
import { PermissionContextListColumnService } from '../service/td-data-table/implementation/backoffice/permission-context-list.service';
import { ReceiptColumnService } from '../service/td-data-table/implementation/receipt-list.service';
import { RequestListColumnService } from '../service/td-data-table/implementation/request-list.service';
import { PageCrmModule } from './crm/page-crm.module';
import {
	OfferListColumnService,
	OfferFreelineListColumnService
} from '../service/td-data-table/implementation/offer-list.service';
import { InformativePageComponent } from './commons/informative-page/informative-page.component';
import { PageB2cModule } from './b2c/page-b2c.module';
import { ResumeService } from '../service/rest/resume.service';
import {
	AngularSpin8CoreActionService,
	AngularSpin8CoreAttachedFileService,
	AngularSpin8CoreAuxiliaryTableRestService,
	AngularSpin8CoreCatalogService,
	AngularSpin8CoreCompanyService,
	AngularSpin8CoreInformativePageService,
	AngularSpin8CoreLanguageService,
	AngularSpin8CoreOrderService,
	AngularSpin8CoreStorefrontConfigurationService,
	AngularSpin8CoreUserService
} from '@saep-ict/angular-spin8-core';
import { ConfigurationService } from '../service/rest/backoffice/configuration.service';
import { AggregatorService } from '../service/rest/aggregator.service';

@NgModule({
	imports: [
		WidgetModule,
		SharedModule,
		PageAgentModule,
		PageB2BModule,
		PageB2cModule,
		PageBackofficeModule,
		PageCommonsModule,
		PageStorefrontModule,
		PageCrmModule
	],
	declarations: [InformativePageComponent],
	providers: [
		AuthService,
		AngularSpin8CoreUserService,
		AngularSpin8CoreCompanyService,
		AngularSpin8CoreCatalogService,
		AngularSpin8CoreAuxiliaryTableRestService,
		AngularSpin8CoreStorefrontConfigurationService,
		AngularSpin8CoreOrderService,
		AngularSpin8CoreInformativePageService,
		AngularSpin8CoreLanguageService,
		AngularSpin8CoreAttachedFileService,
		AngularSpin8CoreActionService,
		ResumeService,

		// backoffice
		AngularSpin8CoreStorefrontConfigurationService,
		ConfigurationService,
		// td-data-table columns
		OrganizationColumnService,
		OpportunityListColumnService,
		OfferListColumnService,
		OfferFreelineListColumnService,
		ReceiptColumnService,
		RequestListColumnService,
		ProductKitColumnService,
		UserManagementListColumnService,
		CompanyDetailFullDetailPaymentsColumnService,
		CompanyAccountBalanceColumnService,
		ContactColumnService,
		AddressListColumnService,
		PermissionContextListColumnService,
		AggregatorService
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageModule {}
