import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AngularCoreUtilService } from '@saep-ict/angular-core';
import { ArticlePouchModel, ArticleDescriptionItem, LocalListHandlerBaseApplyFilterParam } from '@saep-ict/pouch_agent_models';
import { Store } from '@ngrx/store';
import { ArticleListStructureWrapperBase } from '@saep-ict/angular-spin8-core';
import { UtilInputNavigationService } from '../../../../service/util/input-navigation-util.service';
import { ArticleTableConfigurationAction } from '../../../../model/article.model';


@Component({
	selector: 'article-list-structure-wrapper-plain-list',
	templateUrl: './article-list-structure-wrapper-plain-list.component.html',
	styleUrls: ['./article-list-structure-wrapper-plain-list.component.scss']
})
export class ArticleListStructureWrapperPlainListComponent extends ArticleListStructureWrapperBase {
	@Input() onItemSelectShow: boolean;
	@Input() paginatorOnTopShow = false;
	@Input('configurationAction') configurationAction: ArticleTableConfigurationAction;
	@Output() onDeleteArticle: EventEmitter<ArticlePouchModel | ArticleDescriptionItem> = new EventEmitter();

	constructor(
		public store: Store,
		public utilService: AngularCoreUtilService,
		public translateService: TranslateService,
		private utilInputNavigationService: UtilInputNavigationService
	) {
		super(utilService, translateService, store);
	}

	localListHandlerApplyFilterCustom(e?: LocalListHandlerBaseApplyFilterParam) {
		this.utilInputNavigationService.deleteAll();
		this.localListHandlerApplyFilter(e);
	}
}
