import { ContextApplicationItemCodeEnum } from '@saep-ict/angular-spin8-core';
import moment from 'moment';
import { NewsModel } from '../model/news.model';

export namespace ConfigurationNews {
	export const listFilter = (list: NewsModel[], contextApplication: ContextApplicationItemCodeEnum): NewsModel[] => {
		switch (contextApplication) {
			case ContextApplicationItemCodeEnum.BACKOFFICE:
			case ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN:
				return list;
			default:
				const timestampNow = moment().valueOf();
				return list.filter(item => item.header.published && item.header.date_publication < timestampNow);
		}
	};
}
