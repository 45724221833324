import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, filter, map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { BaseState, BaseStateModel, SubscribeManagerService, SentencecasePipe } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../state';
import { AlertType } from '../../../widget/alert/alert.component';
import { DialogPickAddressComponent } from '../../../widget/dialog/dialog-pick-address/dialog-pick-address.component';
import {
	AddressPouchModel,
	DestinationPouchModel,
	OrderPouchModel,
	OrganizationTypeEnum,
	PaymentPouchModel,
	ContactPouchModel,
	ArticlePouchModel,
	CommercialAreaPouchModel,
	PriceEnum,
	TableListEnum
} from '@saep-ict/pouch_agent_models';
import { CurrencyPipe } from '@angular/common';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { OrderActionEnum, OrderStateAction } from '../../../state/order/order.actions';
import _ from 'lodash';
import { ConfigurationViewModel } from '../../../model/configuration.model';
import { CustomerAppConfig } from '../../../customer-app.config';
import { TranslateService } from '@ngx-translate/core';
import { AppUtilService } from '../../../service/util/app-util.service';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';
import { UtilPriceService } from '../../../service/util/util-price.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrganizationStateAction } from '../../../state/organization/organization.actions';
import { UtilAddressService } from '../../../service/util/util-address.service';
import {
	PATH_URL,
	ROUTE_URL,
	UserDetailModel,
	FormEnum,
	OrganizationStateModel,
	AdditionalServiceStateModel,
	OrderStateModel,
	ExtraFieldOrderHeaderPouchModel,
	ShippingMethodModel,
	OrderEnum,
	OrderPriceMap,
	AngularSpin8CoreOrderService
} from '@saep-ict/angular-spin8-core';

import { OrderListStateAction } from '../../../state/order-list/order-list.actions';

enum PaymentState {
	NOT_AUTH,
	AUTH,
	COMPLETED
}

@Component({
	selector: 'b2c-checkout',
	templateUrl: './b2c-checkout.component.html',
	styleUrls: ['./b2c-checkout.component.scss'],
	providers: [SubscribeManagerService, AngularSpin8CoreOrderService]
})
export class B2cCheckoutComponent implements OnInit, OnDestroy {
	@Output() viewDetails = new EventEmitter();
	@ViewChild('formDirective') formDirective: FormGroupDirective;
	checkoutForm: FormGroup;
	key: Array<string> = ['receipt_country', 'receipt_address', 'receipt_cap', 'receipt_city', 'receipt_province'];
	AlertType = AlertType;
	PaymentState = PaymentState;
	OrganizationTypeEnum = OrganizationTypeEnum;
	OrderEnum = OrderEnum;
	ROUTE_URL = ROUTE_URL;
	PATH_URL = PATH_URL;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	configuration$: Observable<BaseStateModel<ConfigurationViewModel>> = this.store.select(
		StateFeature.getConfigurationState
	);
	configuration: ConfigurationViewModel;

	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel;

	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);
	articleList: ArticlePouchModel[];

	paymentMethods: PaymentPouchModel[];

	additionalService$: Observable<BaseStateModel<AdditionalServiceStateModel>> = this.store.select(
		StateFeature.getAdditionalService
	);
	additionalService: AdditionalServiceStateModel;

	destinationRegisteredOffice: AddressPouchModel;
	commercialArea: CommercialAreaPouchModel;

	contactMainOfList: ContactPouchModel;

	operationCompleted = false;

	isSmartphone = false;

	configurationCustomer = ConfigurationCustomer;

	orderPriceMap: OrderPriceMap = new OrderPriceMap();

	formValueEnum = FormEnum.Value;

	tableListEnum = TableListEnum;

	componentInit = true;

	constructor(
		private snackBar: MatSnackBar,
		private fb: FormBuilder,
		private store: Store<any>,
		private dialog: MatDialog,
		private subscribeManagerService: SubscribeManagerService,
		private currencyPipe: CurrencyPipe,
		public utilOrderService: UtilOrderService,
		private appConfig: CustomerAppConfig,
		private orderService: AngularSpin8CoreOrderService,
		private translate: TranslateService,
		private utilService: AppUtilService,
		public utilPriceService: UtilPriceService,
		private sentenceCasePipe: SentencecasePipe,
		private utilAddressService: UtilAddressService,
		private snackbar: MatSnackBar,
		private translateService: TranslateService
	) {
		this.utilOrderService.orderThresholdSatisfied = {
			status: false,
			conditionList: []
		};
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});

		this.organization$
			.pipe(
				filter(res => !!(res && res.data)),
				take(1)
			)
			.subscribe(res => {
				this.organization = res.data;
				if (
					this.organization &&
					this.organization.destination_list &&
					this.organization.destination_list.length
				) {
					const mainDestination: DestinationPouchModel =
						this.organization.destination_list.find(destination => destination.is_registered_office) ||
						null;
					this.destinationRegisteredOffice = mainDestination ? mainDestination.address : null;
					this.contactMainOfList = this.utilService.returnIsMainOfList(this.organization.contact_list);
				}
			});

		this.createForm();

		this.articleList$.pipe(take(1)).subscribe((res: BaseStateModel<ArticlePouchModel[]>) => {
			this.articleList = res.data;
		});

		this.additionalService$.pipe().subscribe(res => {
			this.additionalService = res ? res.data : null;
		});

		this.configuration$.pipe(take(1)).subscribe(res => {
			this.configuration = res && res.data ? res.data : null;
			// metodi di pagamento
			// TODO: la lista dei pagamenti dipende dalla tipologia dell'organization.
			// per ora questi vengono recuperati dalle liste statiche dello store configuration
			this.paymentMethods =
				this.organization && this.organization.organization_type === OrganizationTypeEnum.COMPANY
					? this.configuration.company_payment_methods
					: this.configuration.private_payment_methods;
		});

		this.subscribeManagerService.populate(
			this.initMandatoryData().subscribe(
				res => {},
				error => {
					console.log('something went wrong ', error);
				}
			),
			'order-data'
		);
	}

	ngOnInit() {
		this.isSmartphone = window.innerWidth < 768;
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	// TODO: la sola condizione sul token non fa in tempo a prevenire gli errori tra il login
	// ed il redirect verso context-selection
	operationState() {
		if (!this.appConfig.authenticationToken || !this.organization) {
			return PaymentState.NOT_AUTH;
		}

		if (!this.operationCompleted) {
			return PaymentState.AUTH;
		}
	}

	initMandatoryData() {
		return this.order$.pipe(
			filter(
				(order: BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>>) =>
					order && order.type !== OrderActionEnum.LOAD
			),
			map(async (order: BaseStateModel<OrderStateModel>) => {
				switch (order.type) {
					case OrderActionEnum.ERROR:
						this.operationCompleted = true;
						break;
					// throw new Error(OrderActionEnum.ERROR);
					case OrderActionEnum.UPDATE:
						if (this.organization) {
							this.order = _.cloneDeep(order.data);
							if (
								this.componentInit &&
								// TODO: questa condizione permette di distingue se si sta ritornando da una schermata di pagamento
								// andrebbe sostituita con uno stato ordine apposito. Le istruzioni contenute nel seguente if devono
								// essere eseguite solo alla prima visualizzazione del checkout
								!this.order.header.payment_code
							) {
								this.utilOrderService.setOrderNewDraft(
									this.order,
									this.organization,
									this.configuration
								);
								this.order = this.utilOrderService.setStartingWarehouse(this.order);
								this.componentInit = false;
							}
							this.articleList = this.utilOrderService.returnFilteredAndMergedArticleListByOrderDraft(
								this.order,
								this.articleList,
								this.organization
							);
							this.order = this.utilOrderService.returnOrderDraftWithoutMissingArticle(
								this.order,
								this.articleList
							);
							this.setModelFormValueFromOrder(this.order);
							this.order = await this.setOrderAndCommercialAreaByFormValue(
								this.order,
								this.checkoutForm.value
							);					
						}
						break;
					case OrderActionEnum.COMPLETED:
						this.operationCompleted = true;
						// resetta ordine attuale
						this.store.dispatch(OrderStateAction.reset());
						this.store.dispatch(OrderListStateAction.reset());
						break;
				}
				return order;
			})
		);
	}

	createForm() {
		this.checkoutForm = this.fb.group({
			shipping_address: ['', Validators.required],
			additionalNotes: [''],
			invoiceRequested: false,
			paymentMethod: ['', Validators.required]
		});
		if (this.organization) {
			const additionalServices = this.fb.group({});
			if (ConfigurationCustomer.Order.AdditionalService[this.organization.organization_type].deliveryDate) {
				additionalServices.addControl('delivery_date', new FormControl(null, [Validators.required]));
			}
			if (ConfigurationCustomer.Order.AdditionalService[this.organization.organization_type].stockType) {
				additionalServices.addControl('stock_type', new FormControl(null, [Validators.required]));
			}
			this.checkoutForm.addControl('additionalServices', additionalServices);
		}
		this.onChangeFormSubscription();
	}

	setModelFormValueFromOrder(order: OrderStateModel) {
		const formPatchValue = {
			shipping_address: order.header.goods_destination_code,
			paymentMethod: order.header.payment_code,
			additionalNotes: order.header.note_order,
			invoiceRequested: order.header.invoice_requested,
			additionalServices: {
				delivery_date: null,
				stock_type: null
			}
		};
		if (ConfigurationCustomer.Order.AdditionalService[this.organization.organization_type].deliveryDate) {
			formPatchValue.additionalServices.delivery_date =
				order.header.additional_services && order.header.additional_services.delivery_date
					? order.header.additional_services.delivery_date.code_item
					: this.additionalService?.delivery_date[0].code_item;
		}
		if (ConfigurationCustomer.Order.AdditionalService[this.organization.organization_type].stockType) {
			formPatchValue.additionalServices.stock_type =
				order.header.additional_services && order.header.additional_services.stock_type
					? order.header.additional_services.stock_type.code_item
					: this.additionalService?.stock_type[0].code_item;
		}
		this.checkoutForm.patchValue(formPatchValue, { emitEvent: false });
	}

	onChangeFormSubscription() {
		this.subscribeManagerService.populate(
			this.checkoutForm.valueChanges.pipe(debounceTime(500)).subscribe(async formChange => {
				this.order = await this.setOrderAndCommercialAreaByFormValue(this.order, formChange);
			}),
			'change-form'
		);
	}

	async setOrderAndCommercialAreaByFormValue(order: OrderStateModel, formValue): Promise<OrderStateModel> {
		order = _.cloneDeep(order);
		if (formValue['shipping_address']) {
			order.header.goods_destination_code = formValue['shipping_address'] || null;
		}
		this.utilOrderService.setOrderHeaderDestinationObject(order, this.organization.destination_list);
		order.header.note_order = formValue['additionalNotes'] || null;
		order.header.invoice_requested = formValue['invoiceRequested'] || null;
		this.commercialArea = await this.utilAddressService.selectCommercialArea(order, this.organization);
		// data di consegna
		order.header.first_evasion_date = this.getFirstEvasionDate();
		// metodo di pagamento
		order.header.payment_code = formValue['paymentMethod'];
		this.utilOrderService.setOrderHeaderObject<PaymentPouchModel>(
			'payment_code',
			'payment_object',
			order,
			this.paymentMethods
		);
		// additional services
		if (this.checkoutForm.controls.additionalServices) {
			this.setOrderHeaderAdditionalServices(order, formValue['additionalServices']);
		}
		// set shipping costs
		try {
			order = await this.utilPriceService.updatePriceOrder(order, this.organization, this.commercialArea);
		} catch (error) {
			const snackBarRef = this.snackBar.open(
				error,
				this.sentenceCasePipe.transform(this.translate.instant('general.close')),
				{
					duration: 3000
				}
			);
		}
		this.utilOrderService.returnOrderThresholdSatisfiedStatus(order, this.commercialArea);
		this.orderPriceMap = _.cloneDeep(this.utilPriceService.updateOrderPriceMap(order));
		return order;
	}

	onSubmit() {
		if (this.checkoutForm.valid) {
			this.orderService
				.postOrderPayment(this.order)
				.then(res => {
					if (res.data) {
						window.location.href = res.data;
					}
				})
				.catch(err => {
					this.snackbar.open(
						this.sentenceCasePipe.transform(this.translateService.instant('checkout.error.generic')),
						this.translateService.instant('general.close').toUpperCase(),
						{
							duration: 10000
						}
					);
					throw new Error(err);
				});
		}
	}

	// Data di consegna stimata in base ai giorni di consegna del metodo di spedizione
	getFirstEvasionDate() {
		if (!this.commercialArea) {
			return null;
		}
		const today: Date = new Date();
		const noOfDaysToAdd = this.commercialArea.delivery_days;
		let endDate: Date = today,
			count = 0;
		while (count < noOfDaysToAdd) {
			endDate = new Date(today.setDate(today.getDate() + 1));
			// skip weekends (0 = sunday, 6 = saturday)
			if (endDate.getDay() !== 0 && endDate.getDay() !== 6) {
				count++;
			}
		}
		return endDate.getTime();
	}

	// Label: Tipo di metodo (giorni di consegna) - prezzo
	getShippingLabel(method: ShippingMethodModel) {
		const deliveryDaysLabel =
			`(${method.delivery_days} ` +
			(method.delivery_days === 1
				? this.translate.instant('day.day')
				: this.translate.instant('day.day_plural')) +
			')';

		const chargeLabel = '<b>' + this.currencyPipe.transform(method.charge, 'EUR', 'symbol', '', 'it') + '</b>';

		return method.description + ' ' + deliveryDaysLabel + ' - ' + chargeLabel;
	}

	showWarning() {
		let lacks = '';
		if (!(this.organization.tax_data && this.organization.tax_data.sdi_code)) {
			lacks += (lacks ? ', ' : '') + 'Codice SDI';
		}
		// TODO: verificare questo controllo, la PEC potrebbe non essere obbligatoria per le org. di tipo PRIVATE
		if (!this.organization.tax_data.pec) {
			lacks += (lacks ? ', ' : '') + 'PEC';
		}
		return lacks;
	}

	pickAddress() {
		const dialogRef = this.dialog.open(DialogPickAddressComponent, {
			data: {
				modalTitle: this.sentenceCasePipe.transform(this.translateService.instant('checkout.field.destination'))
			},
			disableClose: true,
			autoFocus: true,
			width: '75%'
		});
		dialogRef.afterClosed().subscribe((destination: DestinationPouchModel) => {
			if (destination) {
				this.order.header.goods_destination_code = destination.code_item;
				this.utilOrderService.setOrderHeaderDestinationObject(this.order, this.organization.destination_list);
				this.checkoutForm.patchValue({
					shipping_address: this.order.header.goods_destination_code
				});
				this.store.dispatch(OrderStateAction.update(new BaseState(this.order)));
			}
		});
	}

	openDialogNewDestination() {
		const dialogNewDestination = this.utilOrderService
			.openDialogNewDestination(this.order, this.checkoutForm)
			.subscribe(res => {
				if (res) {
					this.organization.destination_list.push(res);
					this.store.dispatch(OrganizationStateAction.save(new BaseState(this.organization)));
					dialogNewDestination.unsubscribe();
				}
			});
	}

	setOrderHeaderAdditionalServices(order: OrderStateModel, additionalServices: AdditionalServiceStateModel) {
		for (let serviceKey in additionalServices) {
			if (additionalServices[serviceKey]) {
				if (
					additionalServices[serviceKey] === this.formValueEnum.NO_PREFERENCE &&
					order.header.additional_services &&
					order.header.additional_services[serviceKey]
				) {
					delete order.header.additional_services[serviceKey];
					if (_.isEmpty(order.header.additional_services)) {
						delete order.header.additional_services;
					}
				} else if (additionalServices[serviceKey] !== this.formValueEnum.NO_PREFERENCE) {
					if (!order.header.additional_services) {
						order.header.additional_services = {};
					}
					order.header.additional_services[serviceKey] = this.additionalService[serviceKey].find(
						serv => serv.code_item === additionalServices[serviceKey]
					);
					if (['stock_type'].includes(serviceKey)) {
						order.header.additional_services[serviceKey].charge_type =
							additionalServices[serviceKey].charge_type || PriceEnum.ValueType.ABSOLUTE;
						order.header.additional_services[serviceKey].ordered_quantity = order.header.weight
							? Math.ceil(+order.header.weight / +order.header.additional_services[serviceKey].value)
							: 1;
					}
				}
			}
		}
	}
}
