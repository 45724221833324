<form class="angelo-theme" (ngSubmit)="onFormSubmit()" [formGroup]="form">
	<mat-dialog-wrapper
		title="{{ data.contextTypeAndApplicationLink.context_code_item.description | translate | titlecase }}: {{
			data.contextCodeItem.code
		}}"
		[showCloseIcon]="false"
	>
		<ng-container content>
			<h2>{{ data.contextCodeItem.description }}</h2>
			<p>
				{{ 'context_code.intended_for' | translate | sentencecase }}
				<strong>
					{{ utilService.formatPlaceholder(data.contextTypeAndApplicationLink.application_name) }}
				</strong>
			</p>
			<form-permission-group-select
				formControlName="permission"
				[groupList]="contextPermissionGroup"
			></form-permission-group-select>
		</ng-container>

		<ng-container actions>
			<form-button-bar
				[align]="'right'"
				[primaryButtonLabel]="'general.save' | translate | sentencecase"
				[primaryButtonIsDisabled]="!form.valid"
				(primaryButtonClickEmitter)="onFormSubmit()"
				[secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
				(secondaryButtonClickEmitter)="dialogRef.close()"
			></form-button-bar>
		</ng-container>
	</mat-dialog-wrapper>
</form>
