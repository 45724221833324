import { Injector } from "@angular/core";
import { AngularCoreUtilService } from "@saep-ict/angular-core";
import { commonMainSidebarB2bMap, ConfigurationProjectModel, UserDetailModel } from '@saep-ict/angular-spin8-core';
import _ from "lodash";
const injector = Injector.create({
	providers: [{ provide: AngularCoreUtilService, deps: [] }]
});

const utilService = injector.get(AngularCoreUtilService);

export const projectOverride = (user: UserDetailModel): ConfigurationProjectModel.Navigation.MainSidebarItemModel[] => {
    const map = _.cloneDeep(commonMainSidebarB2bMap(user));
    const mediaCenterIndex = utilService.getElementIndex(map, 'key', 'media_center',);
    if (mediaCenterIndex || mediaCenterIndex === 0) {
        map[mediaCenterIndex].route = 'media-center/mediacenter';
        map[mediaCenterIndex].key = 'mediacenter';

        const mediaCenterInternational:  ConfigurationProjectModel.Navigation.MainSidebarItemModel = {
            key: 'mediacenter',
            name: 'theme.navigation.mediacenter_international_name',
            icon: map[mediaCenterIndex].icon,
            route: 'media-center/mediacenter',
            position: map[mediaCenterIndex].position,
            permission: 'b2b-mediacenter',
            iconCustom: map[mediaCenterIndex].iconCustom,
            disableWhenOffline: map[mediaCenterIndex].disableWhenOffline
        }

        const mediaCenterItalia: ConfigurationProjectModel.Navigation.MainSidebarItemModel = {
            key: 'mediacenter-italy',
            name: 'theme.navigation.mediacenter_italy_name',
            icon: map[mediaCenterIndex].icon,
            route: 'media-center/mediacenter-italy',
            position: map[mediaCenterIndex].position,
            permission: 'b2b-mediacenter-italy',
            iconCustom: map[mediaCenterIndex].iconCustom,
            disableWhenOffline: map[mediaCenterIndex].disableWhenOffline
        };
        map.splice(mediaCenterIndex, 1, mediaCenterInternational, mediaCenterItalia);
    }
    return map;
}