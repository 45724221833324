import { Injector } from '@angular/core';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { TranslateService } from '@ngx-translate/core';
import { PermissionContextListColumnService } from '../../../service/td-data-table/implementation/backoffice/permission-context-list.service';

const injector = Injector.create({
	providers: [
		{ provide: PermissionContextListColumnService, deps: [] },
		{ provide: TranslateService, deps: [] }
	]
});

const permissionContextListColumnService = injector.get(PermissionContextListColumnService);

export namespace ConfigurationContextCodeColumnMap {
	export const base = (canEdit: boolean): ITdDataTableColumn[] =>
		canEdit
			? permissionContextListColumnService.columns
			: permissionContextListColumnService.columns.filter(column => column.name != 'action');

	export const excludeForAgent: string[] = ['typology'];
	export const excludeForB2b: string[] = ['typology'];
	export const excludeForB2c: string[] = [];
	export const excludeForBackoffice: string[] = ['typology'];
	export const excludeForPortal: string[] = ['typology'];
	export const excludeForCrm: string[] = ['typology'];
}
