import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// widget & utility
import _ from 'lodash';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { BaseStateModel, DateMomentService, LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';
import { Store } from '@ngrx/store';
import {
	OrderStateModel,
	OrganizationStateModel,
	PATH_URL,
	ROUTE_URL,
	UserDetailModel
} from '@saep-ict/angular-spin8-core';
import { OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import { StatusBar } from '../../../constants/configuration-customer/order/status-aggregation-map-status-bar.constant';
import { AppUtilService } from '../../../service/util/app-util.service';
import { StateFeature } from '../../../state';
import { Observable, take } from 'rxjs';
import { ExportExcelService } from '../../../service/export/export-excel.service';

@Component({
	selector: 'order-list-wrapper',
	templateUrl: './order-list-wrapper.component.html',
	styleUrls: ['./order-list-wrapper.component.scss']
})
export class OrderListWrapperComponent extends LocalListHandlerWidgetWrapper implements OnInit {
	@Input() localListHandlerWidgetWrapperViewAllLink: boolean;
	@Input() title: string;

	@Output() selectCompany: EventEmitter<{ level: string; code: string }> = new EventEmitter();

	ConfigurationCustomer = ConfigurationCustomer;

	isTablet: any = false;

	ROUTE_URL = ROUTE_URL;
	PATH_URL = PATH_URL;

	orderStatusEnum = OrderStatusEnum;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	constructor(
		public utilOrderService: UtilOrderService,
		public dateMomentService: DateMomentService,
		public utilService: AppUtilService,
		public translateService: TranslateService,
		public store: Store,
		private exportService: ExportExcelService
	) {
		super(utilService, translateService, store);
		this.organization$.pipe(take(1)).subscribe(res => {
			this.organization = res ? res.data : null;
		});
		this.user$.pipe(take(1)).subscribe(res => {
			if (res) {
				this.user = res.data;
			}
		});
	}

	ngOnInit() {
		this.checkTableColumnVisibility();
	}

	// widget & utility
	showGenericVariationIcon(row: OrderStateModel) {
		if (
			(row && row.header && row.header.order_progress_detail && !_.isEmpty(row.header.order_progress_detail)) ||
			(row.order_variation_list && row.order_variation_list.length)
		) {
			return true;
		}
		return false;
	}

	convertMilllisStringToStringDate(stringMillis: string, format: string = 'DD/MM/YYYY'): string {
		return this.dateMomentService.convertStringDateFromFormatToFormat(stringMillis, 'x', format);
	}

	convertMillisStringToTime(stringMillis: string) {
		return this.dateMomentService.convertMillisStringToTime(stringMillis);
	}

	/**
	 * Check column visibility based on window width
	 * @param width This is the width of the window (px)
	 */
	checkTableColumnVisibility() {
		['header.date', 'header.first_evasion_date', 'header.shipped_on'].forEach(columnName => {
			this.changeVisibilityColumn(columnName, this.utilService.isMobile());
			//if(this.organization) this.changeVisibilityColumn('header.organization.business_name', true);
		});
		if (this.dataTable) {
			this.dataTable.refresh();
		}
	}

	/**
	 * Hide or show a column
	 * @param columnName the name of the column declared in the ITdDataTableColumn
	 * @param hide boolean to hide or not the column
	 */
	changeVisibilityColumn(columnName: string, hide: boolean) {
		const indexColumn = this.utilService.getElementIndex(
			this._localListHandlerWidgetWrapperColumnList,
			'name',
			columnName
		);
		if (indexColumn !== undefined) {
			this._localListHandlerWidgetWrapperColumnList[indexColumn].hidden = hide;
		}
	}

	getStatusConfig(status: OrderStatusEnum) {
		return StatusBar.statusList.find(i => i.id === status);
	}
}
