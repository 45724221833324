import { BaseTableListService, ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import { Injectable } from '@angular/core';
@Injectable()
export class CompanyDetailFullDetailPaymentsColumnService extends BaseTableListService {
	constructor() {
		super();
	}
	_columns = [
		{
			name: 'code_item',
			label: 'Codice',
			width: 80
		},
		{
			name: 'description',
			label: 'Descrizione'
		}
	];
}
