export namespace ConfigurationOrderVisibilityFilter{
	// Context
	export const filterVisibleForAgent = {
		orderStatus:'orderStatus',
		dateCreation:'dateCreation',
		dateDelivery:'dateDelivery',
		dateSubmission:'dateSubmission',
		causalList:'causalList'			
	}; 	
	export const filterVisibleForBackoffice = {
		orderStatus:'orderStatus',
		dateSubmission:'dateSubmission',
		causalList:'causalList'
	}; 	 		
	export const filterVisibleForCrm = {
		orderStatus:'orderStatus',
		dateCreation:'dateCreation',
		dateDelivery:'dateDelivery',
		dateSubmission:'dateSubmission',
		causalList:'causalList'			
	}; 	
    export const filterVisibleForB2b = {
		orderStatus:'orderStatus',
		dateCreation:'dateCreation',
		dateDelivery:'dateDelivery',
		dateSubmission:'dateSubmission',
		causalList:'causalList'			
	}; 	
    export const filterVisibleForB2c = {
		orderStatus:'orderStatus',
		dateCreation:'dateCreation',
		dateDelivery:'dateDelivery',
		dateSubmission:'dateSubmission',
		causalList:'causalList'			
	}; 	
}

export class ConfigurationVisibleOrderFilter {
	orderStatus?: boolean;
	dateCreation?: boolean;
	dateDelivery?: boolean;
	dateSubmission?: boolean;
	causalList?: boolean
}




