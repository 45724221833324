import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ActionPouchModel } from '../../model/state/action-state.model';
import { PouchDeleteResponse, PouchErrorResponse } from '../../service/pouch-db/model/pouch-base-response.model';
import { ActionActionEnum, ActionStateAction } from './action.actions';
import { UtilPouchService } from '../../service/util/util-pouch.service';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';
import { AngularCoreUtilService } from '@saep-ict/angular-core';
import { StoreUtilService } from '../../service/util/store-util.service';
import { BasePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';
import { AdaptiveLoadingState, AdaptiveLoadingStateModel } from '../../model/state/conditional-loading-state.model';
import { PouchDbConstant, PouchDbModel } from '@saep-ict/angular-spin8-core';
import { ConfigurationCustomer } from '../../constants/configuration-customer';

@Injectable()
export class ActionEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActionActionEnum.LOAD),
			mergeMap((action: BaseStateModel<BasePouchModel>) =>
				from(
					this.storeUtilService.getCouchDetailAndReturnInListState<ActionPouchModel>(
						action,
						PouchDbModel.PouchDbDocumentType.ORGANIZATION
					)
				)
			),

			map((action: BaseStateModel<ActionPouchModel[]>) => ActionStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(ActionStateAction.error(null));
				return caught;
			})
		)
	);

	loadAll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActionActionEnum.LOAD_ALL),
			mergeMap((action: BaseStateModel<ActionPouchModel[]>) => from(this.getAllAction(action))),
			map((action: BaseStateModel<ActionPouchModel[]>) => ActionStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(ActionStateAction.error(null));
				return caught;
			})
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActionActionEnum.SAVE),
			mergeMap((action: AdaptiveLoadingStateModel<ActionPouchModel[]>) => from(this.putAction(action))),
			map((action: AdaptiveLoadingStateModel<ActionPouchModel[]>) => {
				if (!action.loadAll && action.loadAll !== undefined) {
					return ActionStateAction.load({ data: { _id: action.data[0]._id } });
				} else {
					return ActionStateAction.loadAll();
				}
			}),
			catchError((error, caught) => {
				this.store.dispatch(ActionStateAction.error(error));
				return caught;
			})
		)
	);

	remove$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActionActionEnum.REMOVE),
			mergeMap((action: BaseStateModel<ActionPouchModel>) => from(this.deleteAction(action.data))),
			map((user: BaseStateModel<PouchDeleteResponse>) => ActionStateAction.removed()),
			catchError((error, caught) => {
				this.store.dispatch(ActionStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchAdapterSelectorService: PouchAdapterSelectorService,
		private utilPouchService: UtilPouchService,
		private utilService: AngularCoreUtilService,
		private storeUtilService: StoreUtilService
	) {}

	async putAction(
		action: AdaptiveLoadingStateModel<ActionPouchModel[]>
	): Promise<AdaptiveLoadingStateModel<ActionPouchModel[]>> {
		return (
			(await this.pouchAdapterSelectorService.retrieveCurrentAdapter(PouchDbModel.PouchDbDocumentType.ORGANIZATION)).basePouch
				.saveBodyTable(action.data[0])
				// .actionPouch
				// 	.putAction(action.data[0], action.data[0]._id ? false : true)
				.then(async (updatedAction: ActionPouchModel) => {
					this.utilService.showDialog('action.save');
					return new AdaptiveLoadingState([updatedAction], null, action.loadAll);
				})
				.catch((err: PouchErrorResponse) => {
					throw new Error(err.error + err.reason);
				})
		);
	}
	async deleteAction(data: ActionPouchModel): Promise<BaseStateModel<PouchDeleteResponse>> {
		return (
			await this.pouchAdapterSelectorService.retrieveCurrentAdapter(PouchDbModel.PouchDbDocumentType.ORGANIZATION)
		).actionPouch
			.deleteAction(data)
			.then(async action => {
				return new BaseState(action);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}

	async getAllAction(action: BaseStateModel<ActionPouchModel[]>): Promise<BaseStateModel<ActionPouchModel[]>> {
		const documentName = 'action';
		const allDocsParam: any = {
			include_docs: true,
			startkey:
				documentName +
				ConfigurationCustomer.AppStructure.noSqlDocSeparator,
			endkey:
				documentName +
				ConfigurationCustomer.AppStructure.noSqlDocSeparator +
				PouchDbConstant.allDocsLastCharacter
		};

		await this.utilPouchService
			.allDocs<ActionPouchModel>(allDocsParam, PouchDbModel.PouchDbDocumentType.ORGANIZATION)
			.then(res => {
				action.data = res.data;
			})
			.catch(err => console.log(err));

		return action;
	}
}
