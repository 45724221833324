import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';
import { BodyTablePouchModel, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { OrganizationStateAction } from '../../../../state/organization/organization.actions';
import { StateFeature } from '../../../../state';
import { OrganizationStateModel } from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'app-b2c-organization-detail',
	templateUrl: './b2c-organization-detail.component.html',
	styleUrls: ['./b2c-organization-detail.component.scss']
})
export class B2cOrganizationDetailComponent implements OnInit {
	loginContextCode$: Observable<BaseStateModel<BodyTablePouchModel>> = this.store.select(
		StateFeature.getLoginContextCodeState
	);
	organization: OrganizationStateModel;

	constructor(public router: Router, private store: Store<any>) {
		// Organization
		this.loginContextCode$.pipe(take(1)).subscribe(res => {
			this.organization = res ? res.data : null;
			this.store.dispatch(OrganizationStateAction.update(new BaseState(this.organization)));
		});
	}

	ngOnInit() {}
}
