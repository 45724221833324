import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { BaseStateModel, NavItem } from '@saep-ict/angular-core';
import { StateFeature } from '../../state';
import { UtilGuardService } from '../guard/util-guard/util-guard.service';
import { StatisticsOrders } from '../../model/statistics-orders.model';
import { ConfigurationCustomer } from '../../constants/configuration-customer';
import { ContextApplicationItemCodeEnum, PATH_URL, UserDetailModel } from '@saep-ict/angular-spin8-core';
import { CustomerAppConfig } from '../../customer-app.config';
import { ConnectionModel } from '../../model/connection.model';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ConnectionActionEnum } from '../../state/connection/connection.actions';
import { UtilBreadcrumbService } from '../util/util-breadcrumb.service';
@Injectable()
export class NavigationService {
	private _itemsSubject: BehaviorSubject<NavItem[]> = new BehaviorSubject<NavItem[]>([]);
	private _items: NavItem[] = [];
	items$: Observable<NavItem[]> = this._itemsSubject.asObservable();

	private _currentlyOpenSubject: BehaviorSubject<NavItem[]> = new BehaviorSubject<NavItem[]>([]);
	private _currentlyOpen: NavItem[] = [];

	currentlyOpen$: Observable<NavItem[]> = this._currentlyOpenSubject.asObservable();

	isIconSidenav: boolean;
	isHorizontal = false;

	connection$: Observable<BaseStateModel<ConnectionModel>> = this.store.select(StateFeature.getConnectionState);
	offline: boolean = false;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;
	_user: Subscription;

	statisticsOrders$: Observable<BaseStateModel<StatisticsOrders>> = this.store.select(
		StateFeature.getStatisticsOrders
	);
	statisticsOrders: StatisticsOrders;

	constructor(
		private store: Store<any>,
		snackbar: MatSnackBar,
		public translate: TranslateService,
		private utilGuardService: UtilGuardService,
		private appConfig: CustomerAppConfig,
		private utilBreadcrumbService: UtilBreadcrumbService
	) {
		// setta di default tutte le voci di menu esplose (aperte)
		this._currentlyOpen = this._items;
		this.subscribeData().subscribe();
		//  questa funzione permette di generare menu dinamici ma in questo momento volatili
		// const dynamicMenuFunctionDemo = () => {
		// 	const dynamicFunction = () => {
		// 		const dynamicMenu = this.addItem('Dynamic Menu Item', 'extension', dynamicFunction, 12);
		// 		const snackbarRef = snackbar.open('This menu item got added dynamically!', 'Remove item', <
		// 			MatSnackBarConfig
		// 		>{
		// 			duration: 5000
		// 		});
		// 		snackbarRef.onAction().subscribe(() => {
		// 			this.removeItem(dynamicMenu);
		// 		});
		// 	};
		// };
	}

	subscribeData(): Observable<void> {
		return this.connection$.pipe(
			filter((e: BaseStateModel<ConnectionModel>) => !!(e && e.data)),
			mergeMap((e: BaseStateModel<ConnectionModel>) => {
				switch (e.type) {
					case ConnectionActionEnum.ERROR:
						throw new Error(ConnectionActionEnum.ERROR);
					case ConnectionActionEnum.UPDATE:
						if (this.appConfig.envConfig.enableOffline) {
							this.offline = e.data.offline;
						}
						break;
				}
				return this.user$;
			}),
			filter((e: BaseStateModel<UserDetailModel>) => !!(e && e.data)),
			map((e: BaseStateModel<UserDetailModel>) => {
				this.user = e.data;
				this._items = [];
				this._itemsSubject.next(this._items);
				if (
					this.user &&
					this.user.current_permission &&
					this.user.current_permission.context_application &&
					this.user.current_permission.context_code &&
					this.user.current_permission.context_code.code
				) {
					const navItemReference = {};
					const mainSidenavItemLis = _.cloneDeep(
						ConfigurationCustomer.Navigation.mainSidebarContextApplicationMap(this.user)
					);
					this.utilBreadcrumbService.mainSidenavItemList = mainSidenavItemLis;
					if (mainSidenavItemLis) {
						mainSidenavItemLis.forEach(i => {
							if (i && !(this.offline && i.disableWhenOffline)) {
								navItemReference[i.key] = !i.parentKey
									? this.addItem(
											i.key,
											i.name,
											i.icon,
											i.route,
											i.position,
											i.permission,
											i.badge,
											i.badgeColor,
											i.customClass,
											i.iconCustom
									  )
									: this.addSubItem(
											i.key,
											navItemReference[i.parentKey],
											i.name,
											i.icon,
											i.route,
											i.position,
											i.permission,
											i.badge,
											i.badgeColor,
											i.customClass
									  );
							}
						});

						// Agent
						if (this.user.current_permission.context_application === ContextApplicationItemCodeEnum.AGENT) {
							// TODO: verificare la seguente che pare non funzionante al momento di questa implementazione
							// Popolo i badge
							this.statisticsOrders$.subscribe((stats: BaseStateModel<StatisticsOrders>) => {
								if (stats && stats.data) {
									navItemReference['orders_draft'].badge = (
										stats.data.draft.count + stats.data.error_preparing.count
									).toString();
									// toAuthorize.badge = stats.data.to_authorize.count.toString();
									// notAuthorized.badge = stats.data.not_authorized.count.toString();
									navItemReference['orders_sending'].badge = (
										stats.data.sending.count +
										stats.data.ready_to_send.count +
										stats.data.error_sending.count
									).toString();
									navItemReference[
										'orders_processing'
									].badge = stats.data.processing.count.toString();
									navItemReference['orders_consolidated'].badge = (
										stats.data.consolidated.count + stats.data.partially_fulfilled.count
									).toString();
									navItemReference['orders_archived'].badge = (
										stats.data.fulfilled.count + stats.data.deleted.count
									).toString();
								}
							});
						}
					}
				}
			})
		);
	}

	addItem(
		key: string,
		name: string,
		icon: string,
		route: any,
		position: number,
		permission?: string,
		badge?: string,
		badgeColor?: string,
		customClass?: string,
		iconCustom?: boolean
	) {
		const item = new NavItem({
			name: name,
			icon: icon,
			route: this.mapItemRoute(route),
			subItems: [],
			position: position || 99,
			permission: permission || null,
			badge: badge || null,
			badgeColor: badgeColor || null,
			customClass: customClass || null,
			iconCustom: iconCustom || false
		});
		item['key'] = key;
		if (this.utilGuardService.checkUserPermission(this.user, permission)) {
			this._items.push(item);
			this._itemsSubject.next(this._items);
		}
		return item;
	}

	mapItemRoute(route: string): string {
		return route && route !== PATH_URL.PRIVATE ? PATH_URL.PRIVATE + '/' + route : route;
	}

	addSubItem(
		key: string,
		parent: NavItem,
		name: string,
		icon: string,
		route: any,
		position: number,
		permission?: string,
		badge?: string,
		badgeColor?: string,
		customClass?: string
	) {
		const item = new NavItem({
			name: name,
			icon: icon,
			route: this.mapSubItemRoute(route),
			parent: parent,
			subItems: [],
			position: position || 99,
			badge: badge || null,
			badgeColor: badgeColor || null,
			customClass: customClass || null
		});
		item['key'] = key;
		if (this.utilGuardService.checkUserPermission(this.user, permission)) {
			parent.subItems.push(item);
			this._itemsSubject.next(this._items);
		}
		return item;
	}

	mapSubItemRoute(route: string): string {
		return route && route !== PATH_URL.PRIVATE ? PATH_URL.PRIVATE + route : route;
	}

	// rimuove un elemento dall'itemSubject
	removeItem(item: NavItem) {
		const index = this._items.indexOf(item);
		if (index > -1) {
			this._items.splice(index, 1);
		}

		this._itemsSubject.next(this._items);
	}

	isOpen(item: NavItem) {
		return this._currentlyOpen.indexOf(item) !== -1;
	}

	// ritorna i subItem dell'elemento padre interrogato attivando un observable
	toggleCurrentlyOpen(item: NavItem) {
		let currentlyOpen = this._currentlyOpen;

		if (this.isOpen(item)) {
			if (currentlyOpen.length > 1) {
				currentlyOpen.length = this._currentlyOpen.indexOf(item);
			} else {
				currentlyOpen = [];
			}
		} else {
			currentlyOpen = this.getAllParents(item);
		}

		this._currentlyOpen = currentlyOpen;
		this._currentlyOpenSubject.next(currentlyOpen);
	}

	closeAll() {
		if (this._currentlyOpen.length) {
			const currentlyOpen = [];
			this._currentlyOpen = currentlyOpen;
			this._currentlyOpenSubject.next(currentlyOpen);
		}
	}

	getAllParents(item: NavItem, currentlyOpen: NavItem[] = []) {
		currentlyOpen.unshift(item);
		if (item?.hasParent()) {
			return this.getAllParents(item.parent, currentlyOpen);
		} else {
			return currentlyOpen;
		}
	}

	nextCurrentlyOpen(currentlyOpen: NavItem[]) {
		this._currentlyOpen = currentlyOpen;
		this._currentlyOpenSubject.next(currentlyOpen);
	}

	nextCurrentlyOpenByRoute(route: string) {
		let currentlyOpen = [];
		const item: NavItem = this.findByRouteRecursive(route, this._items);
		if (item && item.hasParent()) {
			currentlyOpen = this.getAllParents(item);
		} else if (item) {
			currentlyOpen = [item];
		}

		this.nextCurrentlyOpen(currentlyOpen);
	}

	// metodo ricorsivo che recupera l'elemento innestato selezionato
	findByRouteRecursive(route: string, collection: NavItem[]): any {
		let result = _.find(collection, { route: route });
		let typeItem: string;
		if (!result) {
			_.each(collection, item => {
				typeItem = 'subItems';
				const found = this.findByRouteRecursive(route, item[typeItem]);
				if (found) {
					result = found;
					return false;
				}
			});
		}
		return result;
	}

	get currentlyOpen() {
		return this._currentlyOpen;
	}

	set currentlyOpen(currentlyOpen) {
		this._currentlyOpen = currentlyOpen;
	}

	getSidenavItemByRoute(route) {
		return this.findByRouteRecursive(route, this._items);
	}

	getActiveItemByRoute(route) {
		return this.findByRouteRecursive(route, this._items);
	}
}
