export namespace StatisticEnum {
	export namespace Item {
		export enum Type {
			TOTAL = 'total',
			TREND = 'trend'
		}
		export enum Key {
			PRESENT = 'present',
			PAST = 'past',
			PAST_YEAYR = 'past_year'
		}
	}
	export enum Type {
		SALES_REPORTS = 'sales-reports'
	}
	export enum Unit {
		QUANTITY = 'quantity',
		VALUE = 'value'
	}
	export enum FormField {
		GROUP_ORGANIZATION = 'groupOrganization',
		COMPARISON_YEAR = 'comparisonYear'
	}
}