// model
import { BasePouchModel, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { ContextCodeFilterModel } from '../../../service/pouch-db/filter/context-code-filter.model';

// store
import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';

export enum OrganizationListActionEnum {
	UPDATE = '[organization-list] Update',
	UPDATE_ITEM = '[organization-list] Update Item',
	LOAD = '[organization-list] Load',
	LOAD_ALL = '[organization-list] Load All',
	LOAD_ITEM = '[organization-list] Load Item',
	CREATE_ITEM = '[organization-list] Create Item',
	CREATE_SUCCESS = '[organization-list] Create Success',
	DELETE_ITEM = '[organization-list] Delete Item',
	DELETE_ITEM_SUCCESS = '[organization-list] Delete Item Success',
	RESET = '[organization-list] Reset',
	ERROR = '[organization-list] Error'
}

export namespace OrganizationListStateAction {
	export const load = createAction(OrganizationListActionEnum.LOAD);
	export const loadAll = createAction(OrganizationListActionEnum.LOAD_ALL);
	export const loadItem = createAction(OrganizationListActionEnum.LOAD_ITEM, props<BaseStateModel<BasePouchModel>>());
	export const update = createAction(
		OrganizationListActionEnum.UPDATE,
		props<BaseStateModel<OrganizationPouchModel[], ContextCodeFilterModel>>()
	);
	export const updateItem = createAction(
		OrganizationListActionEnum.UPDATE_ITEM,
		props<BaseStateModel<OrganizationPouchModel[]>>()
	);
	export const createItem = createAction(
		OrganizationListActionEnum.CREATE_ITEM,
		props<BaseStateModel<OrganizationPouchModel[]>>()
	);
	export const createSuccess = createAction(
		OrganizationListActionEnum.CREATE_SUCCESS,
		props<BaseStateModel<OrganizationPouchModel[]>>()
	);
	export const deleteItem = createAction(
		OrganizationListActionEnum.DELETE_ITEM,
		props<BaseStateModel<OrganizationPouchModel>>()
	);
	export const deleteItemSuccess = createAction(
		OrganizationListActionEnum.DELETE_ITEM_SUCCESS,
		props<BaseStateModel<OrganizationPouchModel[]>>()
	);
	export const reset = createAction(OrganizationListActionEnum.RESET);
	export const error = createAction(OrganizationListActionEnum.ERROR, props<BaseStateModel<any>>());
}
