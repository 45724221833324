import { Route } from '@angular/router';
import { ContactComponent } from '../page/commons/contact/contact.component';
import { OrganizationDetailDestinationComponent } from '../page/commons/organization/organization-detail/organization-detail-destination/organization-detail-destination.component';
import { OrganizationDetailFullDetailComponent } from '../page/commons/organization/organization-detail/organization-detail-full-detail/organization-detail-full-detail.component';
import { OrganizationDetailComponent } from '../page/commons/organization/organization-detail/organization-detail.component';
import { OrganizationComponent } from '../page/commons/organization/organization.component';
import { MetaDataResolver } from '../service/resolvers/meta-data.resolver';
import { OpportunityComponent } from '../page/crm/opportunity/opportunity.component';
import { OpportunityKanbanComponent } from '../page/crm/opportunity/opportunity-kanban/opportunity-kanban.component';
import { OpportunityDetailComponent } from '../page/crm/opportunity/opportunity-detail/opportunity-detail.component';
import { OpportunityDetailMainComponent } from '../page/crm/opportunity/opportunity-detail/opportunity-detail-main/opportunity-detail-main.component';
import { OpportunityDetailCommentComponent } from '../page/crm/opportunity/opportunity-detail/opportunity-detail-comment/opportunity-detail-comment.component';
import { OpportunityDetailActivityComponent } from '../page/crm/opportunity/opportunity-detail/opportunity-detail-activity/opportunity-detail-activity.component';
import { OrganizationDetailCreditComponent } from '../page/commons/organization/organization-detail/organization-detail-credit/organization-detail-credit.component';
import { OrganizationDetailCommentComponent } from '../page/commons/organization/organization-detail/organization-detail-comment/organization-detail-comment.component';
import { OfferComponent } from '../page/crm/offer/offer.component';
import { OfferDetailComponent } from '../page/crm/offer/offer-detail/offer-detail.component';
import { OfferDetailHeadComponent } from '../page/crm/offer/offer-detail/offer-detail-head/offer-detail-head.component';
import { DashboardCrmComponent } from '../page/crm/dashboard-crm/dashboard-crm.component';
import { FramePageComponent } from '../frame/admin/admin.component';
import { OrganizationDetailOverviewComponent } from '../page/commons/organization/organization-detail/organization-detail-overview/organization-detail-overview.component';
import { OrderDetailCatalogComponent } from '../page/commons/order/order-detail/order-detail-catalog/order-detail-catalog.component';
import { OfferDetailCheckoutComponent } from '../page/crm/offer/offer-detail/offer-detail-checkout/offer-detail-checkout.component';
import { ActionComponent } from '../page/crm/action/action.component';
import { ActionDetailComponent } from '../page/crm/action/action-detail/action-detail.component';
import { ReceiptComponent } from '../page/commons/receipt/receipt.component';
import { OfferDetailAttachmentListComponent } from '../page/crm/offer/offer-detail/offer-detail-attachment-list/offer-detail-attachment-list.component';
import { PermissionKeyEnum, ROUTE_URL } from '@saep-ict/angular-spin8-core';

export namespace CrmRouteList {
	export const routes: Route[] = [
		{
			path: '',
			component: FramePageComponent,
			children: [
				// Root
				{
					path: '',
					component: DashboardCrmComponent,
					data: {
						permissionKey: [PermissionKeyEnum.CRM_DASHBOARD],
						meta: {
							title: 'CRM Dashboard',
							description: 'CRM Dashboard'
						}
					},
					pathMatch: 'full',
					resolve: {
						meta: MetaDataResolver
					}
				},
				// Organizations
				{
					path: `${ROUTE_URL.organizations}/:organizationLevel`,
					data: { permissionKey: [PermissionKeyEnum.CRM_ORGANIZATION_LIST] },
					children: [
						{
							path: '',
							component: OrganizationComponent,
							data: {
								permissionKey: [PermissionKeyEnum.CRM_ORGANIZATION_LIST],
								meta: {
									title: 'Lista organizzazioni',
									description: 'Lista delle organizzazioni filtrate per tipo'
								}
							},
							resolve: {
								meta: MetaDataResolver
							}
						},
						{
							path: ':idOrganization',
							component: OrganizationDetailComponent,
							data: {
								permissionKey: [PermissionKeyEnum.CRM_ORGANIZATION_DETAIL],
								meta: {
									title: 'Dettaglio organizzazione',
									description: "Sezione di dettaglio dell'organizzazione"
								}
							},
							resolve: {
								meta: MetaDataResolver
							},
							children: [
								{
									path: '',
									redirectTo: ROUTE_URL.details,
									data: { permissionKey: [PermissionKeyEnum.CRM_ORGANIZATION_DETAIL] },
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.overview,
									component: OrganizationDetailOverviewComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_ORGANIZATION_DETAIL] },
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.details,
									component: OrganizationDetailFullDetailComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_ORGANIZATION_DETAIL] },
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.contacts,
									component: ContactComponent,
									data: {
										refreshChild: true,
										permissionKey: [PermissionKeyEnum.CRM_CONTACT_LIST]
									},
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.addresses,
									component: OrganizationDetailDestinationComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_ORGANIZATION_DETAIL] },
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.comments,
									component: OrganizationDetailCommentComponent,
									data: {
										refreshChild: true,
										permissionKey: [PermissionKeyEnum.CRM_ORGANIZATION_DETAIL]
									},
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.opportunity,
									component: OpportunityComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_OPPORTUNITY_LIST] },
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.opportunityKanban,
									component: OpportunityKanbanComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_OPPORTUNITY_LIST] },
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.offer,
									component: OfferComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_OFFER_LIST] },
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.credits,
									component: OrganizationDetailCreditComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_ORGANIZATION_DETAIL] },
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.receipts,
									component: ReceiptComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_INVOICE_LIST] },
									pathMatch: 'full'
								}
							]
						}
					]
				},
				// Contacts
				{
					path: ROUTE_URL.contacts,
					data: { permissionKey: [PermissionKeyEnum.CRM_CONTACT_LIST] },
					children: [
						{
							path: '',
							data: {
								permissionKey: [PermissionKeyEnum.CRM_CONTACT_LIST],
								meta: {
									title: 'Contatti agente',
									description: "Lista dei contatti associati all'agente"
								}
							},
							resolve: {
								meta: MetaDataResolver
							},
							component: ContactComponent
						}
					]
				},
				// Opportunity
				{
					path: `${ROUTE_URL.opportunity}/:opportunityLevel`,
					data: { permissionKey: [PermissionKeyEnum.CRM_OPPORTUNITY_LIST] },
					children: [
						{
							path: '',
							component: OpportunityComponent,
							data: {
								permissionKey: [PermissionKeyEnum.CRM_OPPORTUNITY_LIST],
								meta: {
									title: 'Lista opportunity',
									description: 'Lista delle opportunity'
								}
							},
							resolve: {
								meta: MetaDataResolver
							}
						},
						{
							path: ':idOpportunity',
							component: OpportunityDetailComponent,
							data: {
								permissionKey: [PermissionKeyEnum.CRM_OPPORTUNITY_DETAIL],
								meta: {
									title: 'Dettaglio opportunity',
									description: "Sezione di dettaglio dell'opportunity"
								}
							},
							resolve: {
								meta: MetaDataResolver
							},
							children: [
								{
									path: '',
									redirectTo: ROUTE_URL.opportunityDetail,
									data: { permissionKey: [PermissionKeyEnum.CRM_OPPORTUNITY_DETAIL] },
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.opportunityDetail,
									component: OpportunityDetailMainComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_OPPORTUNITY_DETAIL] }
								},
								{
									path: ROUTE_URL.opportunityNote,
									component: OpportunityDetailCommentComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_OPPORTUNITY_DETAIL] }
								},
								{
									path: ROUTE_URL.opportunityLog,
									component: OpportunityDetailActivityComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_OPPORTUNITY_DETAIL] }
								},
								{
									path: ROUTE_URL.opportunityOffer,
									component: OfferComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_OFFER_LIST] }
								}
							]
						}
					]
				},
				// opportunity - kanban
				{
					path: `${ROUTE_URL.opportunityKanban}`,
					data: { permissionKey: [PermissionKeyEnum.CRM_OPPORTUNITY_LIST] },
					component: OpportunityKanbanComponent
				},
				// Actions
				{
					path: `${ROUTE_URL.actions}`,
					data: { permissionKey: [PermissionKeyEnum.CRM_OPPORTUNITY_ACTIVITY] },
					children: [
						{
							path: '',
							component: ActionComponent,
							data: {
								permissionKey: [PermissionKeyEnum.CRM_OPPORTUNITY_ACTIVITY],
								meta: {
									title: 'Lista action',
									description: 'Lista delle action'
								}
							},
							resolve: {
								meta: MetaDataResolver
							}
						},
						{
							path: ':idAction',
							component: ActionDetailComponent,
							data: {
								permissionKey: [PermissionKeyEnum.CRM_OPPORTUNITY_ACTIVITY],
								meta: {
									title: 'Dettaglio action',
									description: "Sezione di dettaglio dell'action"
								}
							},
							resolve: {
								meta: MetaDataResolver
							}
						}
					]
				},
				{
					path: `${ROUTE_URL.logs}`,
					data: { permissionKey: [PermissionKeyEnum.CRM_OPPORTUNITY_LIST] },
					children: [
						{
							path: '',
							component: ActionComponent,
							data: {
								permissionKey: [PermissionKeyEnum.CRM_OPPORTUNITY_LIST],
								meta: {
									title: 'Lista action',
									description: 'Lista delle action'
								}
							},
							resolve: {
								meta: MetaDataResolver
							}
						},
						{
							path: ':idAction',
							component: ActionDetailComponent,
							data: {
								permissionKey: [PermissionKeyEnum.CRM_OPPORTUNITY_DETAIL],
								meta: {
									title: 'Dettaglio action',
									description: "Sezione di dettaglio dell'action"
								}
							},
							resolve: {
								meta: MetaDataResolver
							}
						}
					]
				},
				// offer
				{
					path: ROUTE_URL.offer,
					// path: `${ROUTE_URL.offer}/:orderStatus`,
					data: { permissionKey: [PermissionKeyEnum.CRM_OFFER_LIST] },
					children: [
						{
							path: '',
							component: OfferComponent,
							data: {
								permissionKey: [PermissionKeyEnum.CRM_OFFER_LIST],
								meta: {
									title: 'Lista offerte',
									description: 'Lista delle offerte'
								}
							},
							resolve: {
								meta: MetaDataResolver
							}
						},
						// new
						{
							path: ':idOrganization/:idOpportunity/:idOffer',
							component: OfferDetailComponent,
							data: {
								permissionKey: [PermissionKeyEnum.CRM_OFFER_DETAIL],
								meta: {
									title: 'Dettaglio offerta',
									description: "Sezione di dettaglio dell'offerta"
								}
							},
							resolve: {
								meta: MetaDataResolver
							},
							children: [
								{
									path: '',
									redirectTo: ROUTE_URL.catalog,
									data: { permissionKey: [PermissionKeyEnum.CRM_OFFER_DETAIL] },
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.catalog,
									component: OrderDetailCatalogComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_OFFER_DETAIL] }
								},
								{
									path: ROUTE_URL.checkout,
									component: OfferDetailCheckoutComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_OFFER_DETAIL] }
								},
								{
									path: ROUTE_URL.attachment,
									component: OfferDetailAttachmentListComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_OFFER_DETAIL] }
								}
							]
						}
					]
				},
				// Receipts
				{
					path: ROUTE_URL.receipts,
					component: ReceiptComponent,
					data: {
						permissionKey: [PermissionKeyEnum.CRM_INVOICE_LIST],
						meta: {
							title: 'Fatture e note di credito',
							description: 'Lista delle fatture e note di credito'
						}
					},
					resolve: {
						meta: MetaDataResolver
					}
				}
			]
		}
	];
}
