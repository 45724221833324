import { Injector } from "@angular/core";
import { AngularCoreUtilService } from "@saep-ict/angular-core";
import { commonMainSidebarBackofficeMap, ConfigurationProjectModel } from "@saep-ict/angular-spin8-core";
import _ from "lodash";
const injector = Injector.create({
	providers: [{ provide: AngularCoreUtilService, deps: [] }]
});

const utilService = injector.get(AngularCoreUtilService);

export const projectOverride = (): ConfigurationProjectModel.Navigation.MainSidebarItemModel[] => {
    const map = _.cloneDeep(commonMainSidebarBackofficeMap);
    const mediaCenterIndex = utilService.getElementIndex(map, 'key', 'media_center',);
    if (mediaCenterIndex || mediaCenterIndex === 0) {
        map[mediaCenterIndex].route = 'media-center/mediacenter';
        map[mediaCenterIndex].key = 'mediacenter';

        const mediaCenterInternational: ConfigurationProjectModel.Navigation.MainSidebarItemModel = {
            key: 'mediacenter',
            name: 'theme.navigation.mediacenter_international_name',
            icon: map[mediaCenterIndex].icon,
            route: 'media-center/mediacenter',
            position: map[mediaCenterIndex].position,
            permission: 'backoffice-mediacenter',
            iconCustom: map[mediaCenterIndex].iconCustom,
            disableWhenOffline: map[mediaCenterIndex].disableWhenOffline

        }

        const mediaCenterItalia: ConfigurationProjectModel.Navigation.MainSidebarItemModel = {
            key: 'mediacenter-italy',
            name: 'theme.navigation.mediacenter_italy_name',
            icon: map[mediaCenterIndex].icon,
            route: 'media-center/mediacenter-italy',
            position: map[mediaCenterIndex].position,
            permission: 'backoffice-mediacenter-italy',
            iconCustom: map[mediaCenterIndex].iconCustom,
            disableWhenOffline: map[mediaCenterIndex].disableWhenOffline
        };
        map.splice(mediaCenterIndex, 1 , mediaCenterInternational, mediaCenterItalia);
        const organizationsIndex = utilService.getElementIndex(map, 'key', 'organizations');
        const customerOrganizations: ConfigurationProjectModel.Navigation.MainSidebarItemModel = {
            key: 'organizations_customer',
            name: 'theme.navigation.organizations',
            icon: 'custom-business',
            route: '/organizations/customer',
            position: map[organizationsIndex].position,
            permission: 'backoffice-organization-list',
            iconCustom: map[organizationsIndex].iconCustom
        };
        map.splice(organizationsIndex, 4, customerOrganizations);
    }
    return map;
}