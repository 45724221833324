import { FormControlMultipurposeEnum, FormControlMultipurposeModel } from "@saep-ict/angular-core";
import _ from "lodash";
import { Validators } from '@angular/forms';
import { ConfigurationOrder } from "../../../order/order.constants";
import { ConfigurationOrderExtend } from "../order.constants";
import { FormValidatorCustom } from "../../../form-validator-custom";

export const configurationCustomerOrderListFilterCreationFielMapAgent: FormControlMultipurposeModel.Item[] = [
	{
		name: 'dateCreation',
		on_form_template: true,
		label: 'order.field.creation_date',
		type: FormControlMultipurposeEnum.ItemType.DATE_RANGE
	},
	{
		name: 'dateDelivery',
		on_form_template: true,
		label: 'theme.order.field.delivery_date',
		type: FormControlMultipurposeEnum.ItemType.DATE_RANGE
	},
	{
		name: 'dateSubmission',
		on_form_template: true,
		label: 'theme.order.field.submission_date',
		type: FormControlMultipurposeEnum.ItemType.DATE_RANGE
	}
];