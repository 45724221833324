import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import { SortSelectorConfigurationItem } from '../../../widget/sort-selector/sort-selector.component';

export namespace ConfigurationCustomerArticleContextApplicationCatalogSortList {
	export const base: SortSelectorConfigurationItem[] = [
		{
			name: null,
			order: null,
			label: 'general.sort.suggested',
			selected: true
		},
		{
			name: 'division_list.price',
			order: TdDataTableSortingOrder.Descending,
			label: 'general.sort.price_descending'
		},
		{
			name: 'division_list.price',
			order: TdDataTableSortingOrder.Ascending,
			label: 'general.sort.price_ascending'
		},
		{
			name: 'articleDescription.language_list.description',
			order: TdDataTableSortingOrder.Descending,
			label: 'general.sort.name_descending'
		},
		{
			name: 'articleDescription.language_list.description',
			order: TdDataTableSortingOrder.Ascending,
			label: 'general.sort.name_ascending'
		}
	];
}
