import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { ConfigurationCustomer } from '../../constants/configuration-customer';

@Component({
	selector: 'recaptcha',
	templateUrl: './recaptcha.component.html',
	styleUrls: ['./recaptcha.component.scss']
})
export class RecaptchaComponent implements OnInit {
	@ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;
	@Output() recaptchaResponseEmitter = new EventEmitter();
	recaptchaSiteKey = ConfigurationCustomer.Form.recaptchaSiteKey;

	constructor() {}

	ngOnInit() {
		this.addRecaptchaScript();
	}

	renderRecaptcha() {
		window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
			sitekey: this.recaptchaSiteKey,
			callback: captcha => {
				this.recaptchaResponseEmitter.emit(captcha);
			}
		});
	}

	recaptchaExpired() {
		this.recaptchaResponseEmitter.emit(null);
	}

	resetRecaptcha() {
		window['grecaptcha'].reset();
	}

	addRecaptchaScript() {
		window['grecaptchaCallback'] = () => {
			this.renderRecaptcha();
		};
		window['recaptchaExpired'] = () => {
			this.recaptchaExpired();
		};
		(function (id, obj) {
			let js,
				fjs = document.getElementsByTagName('script')[0];
			if (document.getElementById(id)) {
				obj.renderRecaptcha();
				return;
			}
			js = document.createElement('script');
			js.id = id;
			js.src = 'https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit';
			fjs.parentNode.insertBefore(js, fjs);
		})('recaptcha-jssdk', this);
	}
}
