import { RestBasePk } from '@saep-ict/angular-core';
import { ExtraFieldOrderHeaderPouchModel, OrderStateModel, PouchDbConstant } from '@saep-ict/angular-spin8-core';
import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { AllDocsDataPouchModel, ListDataPouchModel, OrderProgressPouchModel } from '@saep-ict/pouch_agent_models';
import { ConfigurationCustomer } from '../../../../constants/configuration-customer';
import { OrderListFilterModel } from '../../filter/order-filter.model';
import { PouchDeleteResponse, PouchErrorResponse } from '../../model/pouch-base-response.model';

export class OrderPouch extends AbstractPouchdbMethod {
	selector: PouchDB.Find.Selector;
	getOrderProgress(data: RestBasePk): Promise<OrderProgressPouchModel<ExtraFieldOrderHeaderPouchModel>> {
		return new Promise((resolve, reject) => {
			this.selector = {
				$and: [
					{
						type: 'progress'
					},
					{
						doc_id: data.id
					}
				]
			};
			this.getWithIndexFilter('order-progress', this.selector)
				.then((res: ListDataPouchModel<OrderProgressPouchModel<ExtraFieldOrderHeaderPouchModel>>) => {
					res.docs ? resolve(res.docs[0]) : resolve(undefined);
					// resolve(res.docs);
				})
				.catch((err: PouchErrorResponse) => {
					reject(err);
				});
		});
	}

	getOrderProgessList(): Promise<AllDocsDataPouchModel<OrderProgressPouchModel<ExtraFieldOrderHeaderPouchModel>>> {
		return new Promise((resolve, reject) => {
			const opts = {
				startkey: 'progress_order',
				endkey:
					'progress_order' +
					ConfigurationCustomer.AppStructure.noSqlDocSeparator +
					PouchDbConstant.allDocsLastCharacter,
				include_docs: true
			};
			this.allDocs(opts)
				.then((res: AllDocsDataPouchModel<OrderProgressPouchModel<ExtraFieldOrderHeaderPouchModel>>) => {
					resolve(res);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	deleteOrder(order: OrderStateModel): Promise<PouchDeleteResponse> {
		return new Promise((resolve, reject) => {
			this.delete(order)
				.then((doc: PouchDeleteResponse) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	// setField(key: string, condition: any) {
	// 	this.selector.$and.push({ [key]: condition });
	// }
}
