<div class="quantity-button" [class.disabled]="disabled">
	<button
		*ngIf="showButton"
		matRipple
		type="button"
		class="quantity-button__less"
		(click)="onArticleChangeDebounce.next('sub')"
		[disabled]="disabled || value < 1"
	>
		<mat-icon class="mat-icon">remove</mat-icon>
	</button>
	<div class="quantity-button__number">
		<input
			appOnlyNumbers
			placeholder="0"
			[value]="value"
			[disabled]="disabled"
			(input)="onArticleChangeDebounce.next($event)"
		/>
	</div>
	<button
		*ngIf="showButton"
		matRipple
		type="button"
		class="quantity-button__more"
		(click)="onArticleChangeDebounce.next('add')"
		[disabled]="disabled"
	>
		<mat-icon class="mat-icon">add</mat-icon>
	</button>
</div>
<!-- <small class="cell-subtitle">
	<span>{{ utilOrderService.returnOrderedQuantityLabel(article, value) }}</span>
</small> -->
<article-input-multiple-validation [article]="article" [organization]="organization">
</article-input-multiple-validation>
<small
	*ngIf="article && article.articleDescription && ![undefined, null].includes(article.articleDescription.weight)"
	class="cell-subtitle d-block normal-line"
>
	{{ 'article.weight_unit' | translate: { amount: article.articleDescription.weight } | sentencecase }}
</small>
