import { Injectable } from '@angular/core';
import { BaseStateModel } from '@saep-ict/angular-core';
import { BasePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';

// model
import { StateForeConcextResultInterface } from '../../model/state/store-common.model';

// pouch instance
import { OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { AdapterOptions, PouchAdapterSelectorService } from '../pouch-db/pouch-adapter-selector.service';
import { Observable } from 'rxjs';
import { skipWhile, take, tap } from 'rxjs/operators';
import _ from 'lodash';
import { Store } from '@ngrx/store';
import { ContextCodeAssociation, PouchDbModel, BodyTablePouchCustomModel } from '@saep-ict/angular-spin8-core';

@Injectable({
	providedIn: 'root'
})
export class StoreUtilService {
	stateForeConcextResult: StateForeConcextResultInterface;

	constructor(private pouchAdapterSelectorService: PouchAdapterSelectorService, private store: Store<any>) {}

	retrieveSyncState<T>(state$: Observable<BaseStateModel<T>>): Observable<BaseStateModel<T>> {
		return state$.pipe(
			skipWhile((state: BaseStateModel<T>) => !(state && state.data)),
			take(1),
			tap((state: BaseStateModel<T>) => {
				return state;
			})
		);
	}

	async getCouchDetailAndReturnInDetailState<D>(
		action: BaseStateModel<BasePouchModel>,
		couchDocumentType?: PouchDbModel.PouchDbDocumentType
	): Promise<BaseStateModel<D>> {
		try {
			const actionReturn = <BaseStateModel<D>>action;
			actionReturn.data = await (
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter(couchDocumentType)
			).basePouch.getDetail<D>(action.data._id);

			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}
	async getCouchDetailAndReturnInListState<D>(
		action: BaseStateModel<BasePouchModel>,
		couchDocumentType?: PouchDbModel.PouchDbDocumentType,
		options?: AdapterOptions
	): Promise<BaseStateModel<D[]>> {
		try {
			const actionReturn = <BaseStateModel<D[]>>action;
			actionReturn.data = [
				await (
					await this.pouchAdapterSelectorService.retrieveCurrentAdapter(couchDocumentType, options)
				).basePouch.getDetail<D>(action.data._id)
			];
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	async saveCouchDetailAndReturnInDetailState<
		D extends BodyTablePouchCustomModel | OrganizationPouchModel | ContextCodeAssociation
	>(action: BaseStateModel<D>, couchDocumentType?: PouchDbModel.PouchDbDocumentType): Promise<BaseStateModel<D>> {
		try {
			const actionReturn = <BaseStateModel<D>>{
				type: action.type
			};
			actionReturn.data = await (
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter(couchDocumentType)
			).basePouch.saveBodyTable<D>(action.data);
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	async saveCouchDetailAndReturnInListState<D extends BodyTablePouchCustomModel | OrganizationPouchModel>(
		action: BaseStateModel<D>
	): Promise<BaseStateModel<D[]>> {
		try {
			const actionReturn = <BaseStateModel<D[]>>{
				type: action.type,
				data: []
			};
			actionReturn.data = [
				await (await this.pouchAdapterSelectorService.retrieveCurrentAdapter()).basePouch.saveBodyTable<D>(
					action.data
				)
			];
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	/**
	 * - Salva i dati dello store passato in `state`, rendendoli accessibili presso
	 * 	 stateForeConcextResult[storeName]
	 * - Effettua il return dell'observable successivo passato in `observable`
	 *
	 * @template S
	 * @template O
	 * @param {BaseStateModel<S>} state
	 * @param {Observable<BaseStateModel<O>>} [observable]
	 * @returns {Observable<BaseStateModel<O>>}
	 * @memberof StoreUtilService
	 */
	contextManagerStoreSequenceHandler<S, O>(
		state: BaseStateModel<S>,
		observable?: Observable<BaseStateModel<O>>
	): Observable<BaseStateModel<O>> {
		try {
			if (state) {
				let storeName = state.type.split('[')[1];
				storeName = storeName.split(']')[0];
				storeName = _.camelCase(storeName);
				this.stateForeConcextResult[storeName] = state.data;
			}
			return observable ? observable : null;
		} catch (err) {
			throw new Error(err);
		}
	}

}
