import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { CustomerAppConfig } from '../../customer-app.config';
import { LinkListClass } from '../../widget/unordered-list/unordered-list.component';
import { ConfigurationCustomer } from '../../constants/configuration-customer';
import { CallToActionConfig } from '../../widget/call-to-action/call-to-action.component';
import { TranslateService } from '@ngx-translate/core';
import { SentencecasePipe, BaseStateModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { Store } from '@ngrx/store';
import { StateFeature } from '../../state';
import { filter, take, tap } from 'rxjs/operators';
import {  ROUTE_URL } from '@saep-ict/angular-spin8-core';
import { Category, CategoryMap } from '@saep-ict/pouch_agent_models';
import { Router } from '@angular/router';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
	providers: [SubscribeManagerService]
})
export class FooterComponent implements OnInit {
	linkCategoryList: LinkListClass[] = [];
	linkInfoList: LinkListClass[] = [
		{ item: 'Politica di spedizione', url: `${ROUTE_URL.deliveryPolicy}` },
		{ item: 'Politica di restituzione', url: `${ROUTE_URL.returnPolicy}` },
		{ item: 'informative_page.type.cookie_policy', url: ROUTE_URL.cookiePolicy },
		{
			item: 'informative_page.type.terms_and_conditions',
			url: `${ROUTE_URL.termsAndConditions}`
		},
		{ item: 'Contatti', url: `${ROUTE_URL.contact}` },
		{ item: 'Login', url: `${ROUTE_URL.login}` }
	];

	callToActionConfig: CallToActionConfig = {
		title: 'theme.cta.title',
		titleClass: 'txt-white',
		text: 'theme.cta.text',
		textClass: 'txt-white',
		btnLabel: 'theme.contact.contact_us',
		theme: 'accent',
		backgroundImage: 'theme.cta.backgroundImage',
		hasBackdrop: true
	};

	customerReferenceDev = ConfigurationCustomer.Reference.Development;
	customerReference = ConfigurationCustomer.Reference.Customer;
	configSubscription: Subscription;
	appTag: string;
	currentYear: number = new Date().getFullYear();

	categoryList$: Observable<BaseStateModel<CategoryMap>> = this.store.select(StateFeature.getCategoryListState);
	categoryList: Category[];

	constructor(
		private store: Store,
		public appConfig: CustomerAppConfig,
		private translateService: TranslateService,
		private sentenceCasePipe: SentencecasePipe,
		private subscribeManagerService: SubscribeManagerService,
		private router: Router
	) {}

	ngOnInit() {
		this.subscribeManagerService.populate(this.subscribeCategoryList().subscribe(), 'category-list-subscription');
		this.subscribeManagerService.populate(this.subscribeConfig().subscribe(), 'config-subscription');
	}

	subscribeConfig() {
		return this.appConfig.config$.pipe(
			filter(res => !!res),
			take(1),
			tap(config => {
				this.appTag = `${this.sentenceCasePipe.transform(
					this.translateService.instant(this.customerReferenceDev.SOFTWARE)
				)} v.${config.tag}`;
			})
		);
	}

	subscribeCategoryList() {
		return this.categoryList$.pipe(
			filter(res => !!(res && res.data && res.data.is_highlighted)),
			tap(res => {
				this.categoryList = res.data.is_highlighted;
				this.linkCategoryList = [];
				this.categoryList.forEach(ctg => {
					this.linkCategoryList.push({
						item: ctg,
						url: `${ROUTE_URL.catalog}`,
						queryParam: {
							categories: ctg.code_item
						}
					});
				});
			})
		);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	clickCallToAction() {
		this.appConfig.authenticationToken
			? this.router.navigate([ROUTE_URL.private, ROUTE_URL.myAccount])
			: this.router.navigate([ROUTE_URL.public, ROUTE_URL.login]);
	}
}
