import { ConfigurationUtil } from '@saep-ict/angular-spin8-core';

export namespace TicketCenterLocalSearchTextKeyList {
	export const base: string[] = ['id'];
	export const backoffice: string[] = ['id', 'body.custom_field.organization_code_item'];

	export const contextApplicationMap = <ConfigurationUtil.Context.ApplicationSelector<string[]>>(<unknown>{
		B2B: TicketCenterLocalSearchTextKeyList.base,
		BACKOFFICE_ADMIN: TicketCenterLocalSearchTextKeyList.backoffice,
		BACKOFFICE: TicketCenterLocalSearchTextKeyList.backoffice,
		PORTAL: TicketCenterLocalSearchTextKeyList.base
	});
}
