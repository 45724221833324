import { ITdDataTableColumn } from '@covalent/core/data-table';
import _ from 'lodash';
import { ColumnListOrder } from '@saep-ict/angular-spin8-core';

// Copio l'array originale perché il cloneDeep non salva le manipolazioni effettuate sul nuovo array
let newColumnList = [...ColumnListOrder.base];
newColumnList.splice(2, 0, {
	name: 'header.date',
	labelPath: 'theme.order.field.date',
	label: '',
	width: 100
});

export const projectOverride: ITdDataTableColumn[] =
	// Aggiugnere date ed eliminare business name
	_.cloneDeep(newColumnList);
