import { read, ParsingOptions, WorkBook, WorkSheet, utils } from 'xlsx';
import { CalcSheetFile, CalcSheetItem } from "../model/calc-sheet.model";

/**
 * Restituisce un array di { CODE: string, QUANTITY: number } a valle del processo di un foglio di calcolo formattato
 * come segue:
 *
 * Formato: .xlxs
 *
 * | |A     |B       |
 * |1|CODE  |QUANTITY|
 * |2|702160|1       |
 * ...
 *
 * @param file
 * @returns
 */
export const returnCalcSheetArticleList = (file: CalcSheetFile): CalcSheetItem[] => {
	const data = file.data;
	const base64a = data.split(';')[1];
	const base64 = base64a.split(',')[1];
	const read_opts: ParsingOptions = { type: 'base64', dense: true };
	let calcSheetList: CalcSheetItem[] = [];
	try {
		const wb: WorkBook = read(base64, read_opts);
		const wsn: string[] = wb.SheetNames;
		const ws: WorkSheet = wb.Sheets[wsn[0]];
		const json = utils.sheet_to_json(ws);
		json.forEach(item => {
			try {
				let calcSheetItem: CalcSheetItem = { code: item['CODE'], quantity: +item['QUANTITY'] };
				if (typeof calcSheetItem.code === 'number') calcSheetItem.code = String(calcSheetItem.code);
				if (calcSheetItem.quantity && typeof calcSheetItem.quantity === 'number')
					calcSheetList.push(calcSheetItem);
			} catch (err) {
				console.log('ERR', err);
			}
		});
	} catch (e) {
		console.log('ERR', e);
	}
	return calcSheetList;
}
