<page-with-sidebar [isRoot]="true">
	<ng-container pageMain>
		<mat-card class="angelo-theme autoheight">
			<category-tab-list
				[list]="categoryListParseVisualization"
				[configuration]="configurationCustomer.Forecast.categoryTabOnTopConfiguration"
				(categorySelectedChange)="categorySelectedChange($event)"
			></category-tab-list>
			<article-list-structure-wrapper-accordion
				*ngIf="listPageBaseData"
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="listPageBaseData.columnList"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerTitle]="'article.list' | translate | sentencecase"
				[configuration]="{
					multipleOpen: configurationCustomer.Forecast.articleStructureAccordionMultipleOpen,
					forecastInstance: true
				}"
				(onForecastChange)="onForecastChangeHandler([$event])"
				class="catalog-article-table-wrapper"
			>
			</article-list-structure-wrapper-accordion>
		</mat-card>
	</ng-container>
	<ng-container pageSide>
		<sidebar-content-wrapper [title]="'Filters'">
			<ng-container main-content>
				<form [formGroup]="form" class="angelo-theme">
					<mat-radio-group formControlName="dateSelector">
						<mat-radio-button *ngFor="let item of formDateSelectorList" [value]="item">
							{{ item }}
						</mat-radio-button>
					</mat-radio-group>
				</form>
			</ng-container>
		</sidebar-content-wrapper>
		<sidebar-content-wrapper [title]="'Forecast utility'" class="forecast-utility">
			<ng-container main-content>
				<button mat-menu-item (click)="bucketManager.bucketManagerDownloadTemplate('budget_template.xlsx')">
					<span>{{ 'general.download_xls_template' | translate | sentencecase }}</span>
				</button>
				<mat-divider></mat-divider>
				<button mat-menu-item (click)="openDialogUploadForecast()">
					<span>{{ 'general.upload_xls_template' | translate | sentencecase }}</span>
				</button>
			</ng-container>
		</sidebar-content-wrapper>
	</ng-container>
</page-with-sidebar>
