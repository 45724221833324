<sidebar-content-wrapper *ngIf="order" [title]="'theme.order.order_aside_help.order_summary'">
	<ng-container main-content>
		<div class="information" *ngIf="order.header.status === orderStatusEnum.DRAFT">
			<h3>{{ 'order.order_aside_help.information' | translate | sentencecase }}</h3>
			<div *ngFor="let field of orderHeaderFieldList" class="item">
				{{ field.label | translate | sentencecase }}
				<span>{{ field.value }}</span>
			</div>
			<a
				*ngIf="order.header.status === orderStatusEnum.DRAFT"
				(click)="router.navigate(['header-edit'], { relativeTo: route })"
				href="javascript:void(0)"
			>
				{{ 'order.order_aside_help.edit_informations' | translate | sentencecase }}
			</a>
		</div>
		<ng-container *ngFor="let csl of categorySectionList">
			<ng-container *ngIf="csl.articleCategoryTree && csl.articleCategoryTree.length > 0">
				<div class="articles">
					<h3>{{ csl.title | translate | sentencecase }}</h3>
					<article-list-category-tree-recursion-summary
						[configuration]="{
							articleCheckoutTree: csl.articleCategoryTree,
							currency: order.header.currency.description_short
						}"
					>
					</article-list-category-tree-recursion-summary>
				</div>
			</ng-container>
		</ng-container>

		<div class="total">
			{{ 'order.order_aside_help.total' | translate | sentencecase }}
			<span>
				{{ orderPriceMap.article.value | utilPriceReturnItemValueFormattedPipe }}
				{{ order.header.currency.description_short }}
			</span>
		</div>
		<form-button-bar
			class="actions-buttons"
			*ngIf="order.header.status === orderStatusEnum.DRAFT"
			[align]="'space-between'"
			[primaryButtonLabel]="
				(routedMainContentKey === ROUTE_URL.checkout ? 'order.send' : 'general.forward_with_the_order')
					| translate
					| sentencecase
			"
			[primaryButtonIsDisabled]="
				!(
					(order.product_list && order.product_list.length > 0) ||
					(order['free_line_list'] && order['free_line_list'].length > 0)
				) ||
				(routedMainContentKey === ROUTE_URL.checkout && offline)
			"
			(primaryButtonClickEmitter)="handlePrimaryButton()"
		></form-button-bar>
	</ng-container>
</sidebar-content-wrapper>
