import { SubscribeManagerService } from "@saep-ict/angular-core";
import { SubscribeManagerItem } from "../model/subscribe-manager.model";

export namespace ConfigurationSubscribeManager {
    export const init =
        (subscribeList: SubscribeManagerItem[], subscribeManagerService: SubscribeManagerService): void => {
            for (const subscribe of subscribeList) {
                subscribeManagerService.populate(
                    subscribe.observable.subscribe(
                        { error: (e) => { console.log(`${subscribe.key}: something went wrong`, e); }}
                    ),
                    subscribe.key
                );
            }
        }
}
