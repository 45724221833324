import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ICardSliderConfiguration, ItemType } from '../../../widget/b2c/article/b2c-article-item/card-slider.models';
import { ViewTypeEnum } from '../../../enum/view-type.enum';
import { ArticlePouchModel, DivisionPouchModel } from '@saep-ict/pouch_agent_models';
import { BaseStateModel, SentencecasePipe, SubscribeManagerService } from '@saep-ict/angular-core';
import { OrderActionEnum } from '../../../state/order/order.actions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../state';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { CallToActionConfig } from '../../../widget/call-to-action/call-to-action.component';
import { UtilPriceService } from '../../../service/util/util-price.service';
import { AppUtilService } from '../../../service/util/app-util.service';
import { ConfigurationViewModel } from '../../../model/configuration.model';
import { ArticleRecap } from '../../../model/state/article-list-state.model';
import { NgxGalleryAnimation, NgxGalleryImage, NgxGalleryImageSize, NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';
import {
	AngularSpin8CoreUtilTranslateService,
	ArticleEnum,
	AuxiliaryTableStateModel,
	OrderStateModel,
	OrganizationStateModel,
	Placeholder,
	ROUTE_URL
} from '@saep-ict/angular-spin8-core';
import { AlertType } from '../../../widget/alert/alert.component';

@Component({
	selector: 'b2c-article-detail',
	templateUrl: './b2c-article-detail.component.html',
	styleUrls: ['./b2c-article-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class ProductDetailComponent implements OnInit {
	public viewTypeEnum = ViewTypeEnum;
	public ItemType = ItemType;

	config: ICardSliderConfiguration;

	article: ArticlePouchModel;
	relatedProducts: ArticlePouchModel[] = [];

	configuration$: Observable<BaseStateModel<ConfigurationViewModel>> = this.store.select(
		StateFeature.getConfigurationState
	);
	configuration: ConfigurationViewModel;

	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel = new OrderStateModel();

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);
	auxiliaryTable: AuxiliaryTableStateModel;

	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);
	articleList: ArticlePouchModel[];

	articleDetail$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleDetail);

	articleDescription$: Observable<BaseStateModel<ArticleRecap>> = this.store.select(
		StateFeature.getArticleDescription
	);

	callToActionConfig: CallToActionConfig = {
		text: 'Lorem ipsum',
		btnLabel: 'contact.contact_us',
		theme: 'accent'
	};

	placeholder = Placeholder;

	inputQuantity: number;

	galleryOptions: NgxGalleryOptions[];
	galleryImages: NgxGalleryImage[] = [];

	visualizedText: {
		visualizedDescription: string;
		visualizedTechnicalInfo: string;
	};

	inputQuantityUpdate = true;
	isAvailable: boolean = true;

	// Enum
	alertTypeEnum = AlertType;
	articleEnum = ArticleEnum;

	ROUTE_URL = ROUTE_URL;

	constructor(
		private snackBar: MatSnackBar,
		public router: Router,
		private route: ActivatedRoute,
		private store: Store<any>,
		public utilOrderService: UtilOrderService,
		private subscribeManagerService: SubscribeManagerService,
		public utilPriceService: UtilPriceService,
		private utilService: AppUtilService,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		private sentencecasePipe: SentencecasePipe
	) {
		this.loadStaticData();
		this.subscribeManagerService.populate(this.subscribeMainPipeData().subscribe(), 'main-pipe-data');
	}

	// angular lifecycle
	ngOnInit() {}
	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	// observable data
	subscribeMainPipeData(): Observable<ArticlePouchModel> {
		return this.route.paramMap.pipe(
			mergeMap((routeParam: ParamMap) => {
				this.article = <ArticlePouchModel>{
					code_item: routeParam.get('product_id')
				};
				this.returnArticle(this.articleList);
				this.visualizedText = {
					visualizedDescription: this.utilTranslateService.getTranslationFromLanguage(
						this.article.articleDescription.language_list
					).description_add
						? this.getTextPreview(
								this.utilTranslateService.getTranslationFromLanguage(
									this.article.articleDescription.language_list
								).description_add
						  )
						: null,
					visualizedTechnicalInfo: this.utilTranslateService.getTranslationFromLanguage(
						this.article.articleDescription.language_list
					).technical_info
						? this.getTextPreview(
								this.utilTranslateService.getTranslationFromLanguage(
									this.article.articleDescription.language_list
								).technical_info
						  )
						: null
				};
				this.initializeGallery();
				return this.order$;
			}),
			filter(
				(store: BaseStateModel<OrderStateModel>) =>
					!!(store && (store.data || store.type === OrderActionEnum.SKIP))
			),
			map((store: BaseStateModel<OrderStateModel>) => {
				if (store.data) {
					switch (store.type) {
						case OrderActionEnum.ERROR:
							throw new Error(OrderActionEnum.ERROR);
						case OrderActionEnum.UPDATE:
							this.order = store.data;
							break;
					}
				} else {
					this.utilOrderService.setOrderNewDraft<ConfigurationViewModel>(
						this.order,
						this.organization,
						this.configuration
					);
				}
				this.article = this.utilOrderService.mergeProductDetailInArticle([this.article], this.order)[0];
				this.isAvailable = this.utilOrderService.articleQuantityIsAvailable(this.article, this.organization);
				return this.article;
			})
		);
	}

	// static data
	loadStaticData() {
		this.configuration$.pipe(take(1)).subscribe(res => {
			this.configuration = res ? res.data : null;
		});
		this.organization$.pipe(take(1)).subscribe(res => {
			this.organization = res ? res.data : null;
		});
		this.auxiliaryTable$.pipe(take(1)).subscribe(res => {
			this.auxiliaryTable = res ? res.data : null;
		});
		this.articleList$.pipe(take(1)).subscribe(res => {
			this.articleList = res ? res.data : null;
		});
	}

	async initializeGallery() {
		const articleImage = this.utilTranslateService.getImageWithLanguage(this.article);
		this.galleryImages.push({
			small: articleImage && articleImage.bucket_link ? articleImage.bucket_link : Placeholder.Image.article,
			medium: articleImage && articleImage.bucket_link ? articleImage.bucket_link : Placeholder.Image.article
		});
		this.galleryOptions = [
			{
				thumbnailsColumns: 4,
				imageArrows: true,
				imageAnimation: NgxGalleryAnimation.Slide,
				imageAutoPlayPauseOnHover: true,
				imageBullets: false,
				preview: false,
				imagePercent: 100,
				imageSize: NgxGalleryImageSize.Contain,
				thumbnails: this.galleryImages.length > 1 ? true : false
			}
		];
	}

	returnArticle(articleList: ArticlePouchModel[]) {
		this.article = articleList.find(article => article.code_item == this.article.code_item);
		const mainDivision = this.organization
			? this.utilService.returnIsMainOfList<DivisionPouchModel>(this.organization.division_list)
			: {};
		this.utilPriceService.returnArticleListWithCalculatePriceForSingleItem([this.article], mainDivision?.division);
		if (
			this.article.articleDescription.related_article_list &&
			this.article.articleDescription.related_article_list.length > 0
		) {
			this.getRelated();
		}
		if (this.inputQuantityUpdate) {
			this.inputQuantity = this.utilOrderService.returnInputMultipleOf(1, this.article, this.organization);
		}
	}

	getRelated() {
		this.relatedProducts = [];
		for (const productCode of this.article.articleDescription.related_article_list) {
			const articleDetail: ArticlePouchModel = this.articleList.find(article => article.code_item == productCode);
			if (articleDetail) {
				this.relatedProducts.push(articleDetail);
			}
		}
		this.utilPriceService.returnArticleListWithCalculatePriceForSingleItem(
			this.relatedProducts,
			this.organization
				? this.utilService.returnIsMainOfList<DivisionPouchModel>(this.organization.division_list).division
				: null
		);
		this.config = {
			data: this.relatedProducts,
			medias: {
				md: 2,
				lg: 3
			},
			animation: {
				animationDuration: '.8s',
				easing: 'ease-in-out',
				loop: true
			}
		};
	}

	// form and actions
	changeValue(quantity: number) {
		const quantityCanBeOrdered = this.utilOrderService.returnArticleQuantityCanBeOrdered(
			quantity,
			this.article,
			this.organization
		);
		if (quantity > quantityCanBeOrdered) {
			const message = this.sentencecasePipe.transform(
				this.utilTranslateService.translate.instant('article.quantity.not_available')
			);
			this.snackBar.open(message, 'OK', { duration: 5000 });
			// TODO: non aggiorna child component dopo la prima volta
			quantity = quantityCanBeOrdered;
		}
		// Set input quantity
		this.inputQuantity = quantity;
	}

	async addToCart() {
		await this.utilOrderService.changeOrderArticle(
			{
				event: {
					target: {
						value: (this.article.ordered_quantity
							? this.article.ordered_quantity + this.inputQuantity
							: this.inputQuantity
						).toString()
					}
				},
				row: this.article,
				key: 'input_quantity'
			},
			this.order,
			this.organization
		);

		// notification
		// TODO: il trigger dello snakbar va spostato all'interno di un type tipo ORDER_SAVE_SUCCESS
		const snackBarRef = this.snackBar.open('Prodotto aggiunto al carrello', 'Apri il carrello', {
			duration: 3000
		});
		snackBarRef.afterDismissed().subscribe((info: MatSnackBarDismiss) => {
			if (info.dismissedByAction) {
				this.router.navigate([`/${ROUTE_URL.public}/${ROUTE_URL.cart}`]);
			}
		});
	}

	clickCallToAction() {}

	getTextPreview(text: string) {
		let preview = text;
		if (preview.length > ConfigurationCustomer.Article.previewLength + 3) {
			const firstWhiteSpaceIndex = preview.indexOf(' ', ConfigurationCustomer.Article.previewLength);
			preview = preview.slice(0, firstWhiteSpaceIndex) + '...';
		}
		return preview;
	}

	getExtendedText(textContainer: string, text: string) {
		this.visualizedText[textContainer] = text;
	}
}
