import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
	BaseStateModel,
	GuidFormatterPipe
} from '@saep-ict/angular-core';
import { PermissionTypeList } from '../../../service/guard/util-guard/util-guard.service';
import { AuthService } from '../../../service/rest/auth.service';
import { StateFeature } from '../../../state';
import { Router } from '@angular/router';
import { CustomerAppConfig } from '../../../customer-app.config';
import { AngularSpin8CoreUtilFontsizeService,
	AngularSpin8CoreUtilTranslateService,
	ContextApplicationItemCodeEnum,
	PATH_URL,
	ROUTE_URL,
	UserDetailModel
} from '@saep-ict/angular-spin8-core';
import { AppUtilService } from '../../../service/util/app-util.service';

@Component({
	selector: 'ms-toolbar-user-button',
	templateUrl: './toolbar-user-button.component.html',
	styleUrls: ['./toolbar-user-button.component.scss']
})
export class ToolbarUserButtonComponent implements OnInit {
	@Input() openUp = false;
    @Input() contextClass : string = null;

	panelOpenState = false;
	isOpen: boolean;
	userInfo: string;

	ContextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;
	userRole: string;

	ROUTE_URL = ROUTE_URL;
	PATH_URL = PATH_URL;

	/**
	 * Serve a indicare se in almeno in un contesto ho più codici
	 */
	get hasMultipleContextCode() {
		return this.user.context_application_list.some(
			context_application => context_application.context_code_list.length > 1
		);
	}

	constructor(
		public authService: AuthService,
		private store: Store<any>,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		public router: Router,
		public utilFontsizeService: AngularSpin8CoreUtilFontsizeService,
		public appConfig: CustomerAppConfig,
		private utilService: AppUtilService,
		private guidFormatterPipe: GuidFormatterPipe
	) {
		this.loadStaticData();
		this.setUserInfo();
	}

	ngOnInit() {}

	loadStaticData() {
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});
	}

	setUserInfo() {
		const contextApplication = PermissionTypeList.typeList.find(
			item => item.type === this.user.current_permission.context_application
		);
		this.userRole = `
			${contextApplication.description}
			(
				${
					this.user.current_permission.context_code.code_erp ?
					this.user.current_permission.context_code.code_erp :
					this.guidFormatterPipe.transform(this.user.current_permission.context_code.code)
				}
			)
		`;
		const nameComplete = this.utilService.getUserNameFormatted(this.user);
		this.userInfo =
			nameComplete +
			(nameComplete && nameComplete !== "" ? ' | ' : '') +
			this.user.current_permission.context_code.description;
	}

	toggleDropdown() {
		this.isOpen = !this.isOpen;
	}

	onClickOutside() {
		this.isOpen = false;
	}

}
