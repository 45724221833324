import { createAction, props } from '@ngrx/store';
import { ArticleRecap } from '../../model/state/article-list-state.model';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ArticleDescriptionItemRest } from '@saep-ict/pouch_agent_models';

export enum ArticleDescriptionActionEnum {
	LOAD_DESCRIPTION_FROM_RECAP = '[Article Description] Load description from recap',
	UPDATE = '[Article Description] Update',
	SAVE = '[Article Description] Save',
	RESET = '[Article Description] Reset',
	ERROR = '[Article Description] Error'
}

export namespace ArticleDescriptionStateAction {
	export const loadDescriptionFromRecap = createAction(ArticleDescriptionActionEnum.LOAD_DESCRIPTION_FROM_RECAP);
	export const update = createAction(ArticleDescriptionActionEnum.UPDATE, props<BaseStateModel<ArticleRecap>>());
	export const save = createAction(
		ArticleDescriptionActionEnum.SAVE,
		props<BaseStateModel<ArticleDescriptionItemRest>>()
	);
	export const reset = createAction(ArticleDescriptionActionEnum.RESET);
	export const error = createAction(ArticleDescriptionActionEnum.ERROR, props<BaseStateModel<any>>());
}
