import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
	BaseStateModel,
	SubscribeManagerService,
	ChartjsModel
} from '@saep-ict/angular-core';
import { CurrencyPouchModel, LocalListHandlerBaseModel, OrderPouchModel } from '@saep-ict/pouch_agent_models';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, filter, skipWhile } from 'rxjs/operators';
import { TableOrderModel } from '../../../model/table/table-order.model';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { StateFeature } from '../../../state';
import { OrderListActionEnum, OrderListStateAction } from '../../../state/order-list/order-list.actions';
import { AppUtilService } from '../../../service/util/app-util.service';
import { MatSelectChange } from '@angular/material/select';
import { ConfigurationLastOrders } from '../../../constants/order/order.constants';
import {
	PATH_URL,
	UserDetailModel,
	OrderStateModel,
	ExtraFieldOrderHeaderPouchModel,
	AngularSpin8CoreUtilCompanyService,
	ContextApplicationItemCodeEnum,
	ROUTE_URL,
	ArticleRecap,
	OrganizationStateModel,
	PermissionKeyEnum
} from '@saep-ict/angular-spin8-core';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import {
	ItemBoxTrendInfoComponentConfig,
	TrendDirectionValues
} from '../../../widget/box-trend-info/box-trend-info.component';
import {
	ItemSidebarContentStandardComponentConfig,
	IconType
} from '../../../widget/sidebar-content-standard/sidebar-content-standard.component';
import { SideBarPositionValues } from '../../../enum/sidebar-position-values.enum';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';
import { NewsListStateAction } from '../../../state/news-list/news-list.actions';
import { NewsListActionEnum } from '../../../state/news-list/news-list.actions';
import { NewsModel } from '../../../model/news.model';
import { DateAsAgoPipe } from '../../../shared/pipe/date-as-ago.pipe';
import { NewsStatusEnum } from '../../../enum/news.enum';
import { MediaCenterFilter } from '@saep-ict/media-center';
import * as DashboardConfiguration from '../../../constants/dashboard.constants'
import moment from 'moment';
import { BucketManagerService } from '../../../service/util/util-bucket-manager.service';
import { StoreUtilService } from '../../../service/util/store-util.service';
import { ConfigurationSubscribeManager } from '../../../constants/subscribe-manager.constant';
import { SubscribeManagerItem } from '../../../model/subscribe-manager.model';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import { ConfigurationNews } from '../../../constants/news.constants';
import { CustomerCreditPouchModelCustom } from '../../../state/company-account-balance/company-account-balance.reducer';
import * as UtilPrice from '../../../constants/util-price.constants';
import { UtilStatisticService } from '../../../service/util/util-statistc.service';
import { CompanyAccountBalanceAction } from '../../../state/company-account-balance/company-account-balance.actions';
import { CompanyAccountBalanceFilterModel } from '../../../service/pouch-db/filter/company-account-balance-filter.model';
import { PermissionUtilService } from '../../../service/util/permission-util.service';

@Component({
	selector: 'app-dashboard-b2b',
	templateUrl: './dashboard-b2b.component.html',
	styleUrls: ['./dashboard-b2b.component.scss'],
	providers: [
		SubscribeManagerService
	]
})
export class DashboardB2bComponent implements OnDestroy {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	orderList$: Observable<BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>[]>> = this.store.select(
		StateFeature.getOrderListState
	);
	orderList: OrderStateModel[];

	newsList$: Observable<BaseStateModel<NewsModel[]>> = this.store.select(StateFeature.getNewsListState);

	articleDescription$: Observable<BaseStateModel<ArticleRecap>> = this.store.select(StateFeature.getArticleDescription);

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(StateFeature.getOrganizationState);
	organization: OrganizationStateModel;

	companyAccountBalance$: Observable<BaseStateModel<CustomerCreditPouchModelCustom>> =
		this.store.select(StateFeature.getCompanyAccountBalance);;

	subscribeList: SubscribeManagerItem[] = [
		{ key: 'news-list', observable: this.subscribeNewsList() },
		{ key: 'order-list', observable: this.subscribeOrderList() },
		{ key: 'organization-account-balance', observable: this.subscribeOrganizationAccountBalance() }
	];

	sidebarContentNews: Array<ItemSidebarContentStandardComponentConfig> = [];

	PATH_URL = PATH_URL;
	ROUTE_URL = ROUTE_URL;

	listPageBaseLastOrders = <LocalListHandlerBaseModel<TableOrderModel>>{
		pagination: {
			pageSize: 10
		},
		sort: {
			name: 'header.date',
			order: TdDataTableSortingOrder.Descending
		}
	};

	sideBarPositionValues = SideBarPositionValues;

	displayCreditDebitInfoBox: boolean;

	boxTrendInfoOrder: Array<ItemBoxTrendInfoComponentConfig>;
	overdueTrendBoxContent: Array<ItemBoxTrendInfoComponentConfig> = [];

	mediaCenterCustomHeaders;
	mediaCenterFilter: MediaCenterFilter;
	configurationCustomer = ConfigurationCustomer;
	dashboardConfiguration = DashboardConfiguration;

	updatedAtGeneral = moment().format("DD/MM/YYYY, HH:mm");

	sidebarContentPriceList: Array<ItemSidebarContentStandardComponentConfig> = [];

	dueTrendBoxContent: Array<ItemBoxTrendInfoComponentConfig> = [];

	// permission differente per visibilità singola card dashboard
	permissionKeyEnum = {
		news: PermissionKeyEnum.B2B_NEWS,
		mediaCenter: PermissionKeyEnum.B2B_MEDIACENTER,
		order: PermissionKeyEnum.B2B_ORDER_LIST,
		credit: PermissionKeyEnum.B2B_ORGANIZATION_CREDIT
	}

	constructor(
		private store: Store<any>,
		private router: Router,
		private subscribeManagerService: SubscribeManagerService,
		private utilOrderService: UtilOrderService,
		public utilCompanyService: AngularSpin8CoreUtilCompanyService,
		public utilService: AppUtilService,
		public utilBreadcrumbService: UtilBreadcrumbService,
		private dateAsAgo: DateAsAgoPipe,
		public bucketManager: BucketManagerService,
		private utilStoreService: StoreUtilService,
		private utilStatisticService: UtilStatisticService,
		public permissionUtilService: PermissionUtilService
	) {
		this.store.dispatch(NewsListStateAction.loadAll());
		this.store.dispatch(OrderListStateAction.loadAll());
		this.loadStaticData();
		ConfigurationSubscribeManager.init(this.subscribeList, this.subscribeManagerService);
		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('dashboard');
		this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['dashboard']);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(NewsListStateAction.reset());
		this.store.dispatch(OrderListStateAction.reset());
		this.store.dispatch(CompanyAccountBalanceAction.reset());
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	loadStaticData() {
		this.utilStoreService.retrieveSyncState<UserDetailModel>(this.user$).subscribe(e => {
			this.user = e.data;
			this.mediaCenterCustomHeaders = {
				'context-code': e.data.current_permission.context_code.code,
				'context-application': e.data.current_permission.context_application
			};
			this.displayCreditDebitInfoBox =
				ConfigurationCustomer.Organization.displayCreditDebitInfoBox[
					ContextApplicationItemCodeEnum[this.user.current_permission.context_application]
				];
			this.listPageBaseLastOrders.columnList = ConfigurationCustomer.Order.columnList(
				this.user.current_permission.context_application
			);
			this.mediaCenterFilter = {
				path: '/',
				latest: true,
				limit: 3,
				bucketFolderName: 'mediacenter',
				actionDownloadOnly:
					this.user.current_permission.context_application === ContextApplicationItemCodeEnum.BACKOFFICE ||
					this.user.current_permission.context_application === ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN ?
					false :
					true,
				avoidItemSelection: true,
				loaderExpandBeyondLibrary: true
			};
		});
		this.utilStoreService.retrieveSyncState<ArticleRecap>(this.articleDescription$).subscribe(e => {
			this.sidebarContentPriceList.push({
				title: 'catalogue.download_the_updated_catalog',
				// TODO: aggiornare modello ArticleRecap
				subtitle: moment(e.data['date_update']).format("DD/MM/YYYY, HH:mm"),
				icon: { type: IconType.CUSTOM, name: 'custom-download' }
			});
		});
		this.utilStoreService.retrieveSyncState<OrganizationStateModel>(this.organization$).subscribe(e => {
			this.organization = e.data;
			const accountBalancePayload: BaseStateModel<CustomerCreditPouchModelCustom, CompanyAccountBalanceFilterModel> = {
				data: {
					header: null,
					summary: null
				},
				dataSetting: {
					sort: [],
					appliedFilter: {
						organization_code: this.organization.code_item,
						date_document: null,
						date_expiration: null,
						text: null
					}
				}
			};
			this.store.dispatch(CompanyAccountBalanceAction.load(accountBalancePayload));
		});
	}

	subscribeOrderList(): Observable<void> {
		return this.orderList$.pipe(
			filter(
				(orderList: BaseStateModel<OrderStateModel[]>) =>
					this.user.current_permission.context_code.code &&
					orderList &&
					orderList.type !== OrderListActionEnum.LOAD_ALL
			),
			map((orderList: BaseStateModel<OrderStateModel[]>) => {
				if (orderList.type === OrderListActionEnum.ERROR) {
					throw new Error(OrderListActionEnum.ERROR);
				}
				this.orderList = orderList.data;
				this.boxTrendInfoOrder =
					DashboardConfiguration.returnBoxTrendInfoOrderContent(
						ConfigurationCustomer.Order.Dashboard.orderTrendBoxStatusList[this.user.current_permission.context_application],
						this.orderList,
						ConfigurationCustomer.Statistic.timeRangeSelector[0]
					);
				// last orders permanent filters
				const listPageBaseLastOrders =
					ConfigurationLastOrders.listFilter(
						_.cloneDeep(orderList.data),
						this.user.current_permission.context_application
					);
				this.listPageBaseLastOrders.data = this.utilOrderService.getTableOrderList(listPageBaseLastOrders);
				this.listPageBaseLastOrders = _.cloneDeep(this.listPageBaseLastOrders);
			})
		);
	}

	subscribeNewsList(): Observable<void> {
		return this.newsList$.pipe(
			filter((state: BaseStateModel<NewsModel[]>) => !!(state && state.data)),
			map((state: BaseStateModel<NewsModel[]>) => {
				switch (state.type) {
					case NewsListActionEnum.UPDATE:
						this.sidebarContentNews =
							ConfigurationNews.listFilter(state.data, this.user.current_permission.context_application)
							.filter(item => item.header.status === NewsStatusEnum.NEW)
							.sort((a, b) => b.header.date_publication - a.header.date_publication)
							.slice(0, ConfigurationCustomer.News.showLastN)
							.map(item => {
								return {
									title: item.body.subject,
									subtitle: this.dateAsAgo.transform(item.date_update),
									icon: { type: IconType.CUSTOM, name: 'custom-document' },
									clickEvent: 'sadsds'
								};
							});
				}
			})
		);
	}

	subscribeOrganizationAccountBalance() {
		return this.companyAccountBalance$.pipe(
			filter(
				(e: BaseStateModel<CustomerCreditPouchModelCustom>) =>
					!!(
						e &&
						e.data &&
						e.data.header &&
						e.data.summary &&
						e.data.summary.values &&
						e.data.summary.values.length > 0
					)
			),
			map((e: BaseStateModel<CustomerCreditPouchModelCustom>) => {
				const dueSeriesChart: number[] = [];
				const dueLabelChart: string[] = [];
				const overdueSeriesChart: number[] = [];
				const overdueLabelChart: string[] = [];
				const amountCreditGrantedSeriesChart: { x: string; y: number }[] = [];
				e.data.summary.values.forEach(value => {
					const date = moment(value.date).format('DD/MM/YYYY').toString();
					const dueValue = +value.balance_give_take.toFixed(2);
					const overdueValue = +value.balance_give_take_expired.toFixed(2);
					dueSeriesChart.push(dueValue);
					dueLabelChart.push(date);
					overdueSeriesChart.push(overdueValue);
					overdueLabelChart.push(date);
					if (e.data.header.amount_credit_granted) {
						let amount_credit_granted = e.data.header.amount_credit_granted;
						if (e.data.header.sign_credit_granted === '-') {
							amount_credit_granted = -amount_credit_granted;
						}
						amountCreditGrantedSeriesChart.push({ x: date, y: amount_credit_granted });
					}
				});
				const lastItem = e.data.summary.values.sort((a, b) => (a.date < b.date ? 1 : -1))[0];
				const credit = +lastItem.total_take.toFixed(2);
				const debit = +lastItem.total_give.toFixed(2);
				const suffix: string =
					`currency.${this.utilService.returnIsMainOfList<CurrencyPouchModel>(this.organization.currency).multilangRef}.short`;
				const overdueCredit = +lastItem.total_take_expired.toFixed(2);
				const overdueDebit = +lastItem.total_give_expired.toFixed(2);
				const dueValue = +lastItem.balance_give_take.toFixed(2);
				const overdueValue = +lastItem.balance_give_take_expired.toFixed(2);
				this.dueTrendBoxContent.push({
					value: UtilPrice.returnItemValueFormatted(dueValue),
					suffix: suffix,
					trendDirection: TrendDirectionValues.NONE
				});
				this.overdueTrendBoxContent.push({
					value: UtilPrice.returnItemValueFormatted(overdueValue),
					suffix: suffix,
					trendDirection: TrendDirectionValues.NONE
				});
			})
		);
	}

	goToOrderDetail(selectedOrder: OrderPouchModel<ExtraFieldOrderHeaderPouchModel>) {
		this.router.navigate([
			PATH_URL.PRIVATE,
			'orders',
			selectedOrder.header.status,
			selectedOrder.header.organization.code_item,
			selectedOrder._id,
			'checkout'
		]);
	}

	updateBoxTrendInfoOrderContent(e: MatSelectChange) {
		this.boxTrendInfoOrder =
			DashboardConfiguration.returnBoxTrendInfoOrderContent(
				ConfigurationCustomer.Order.Dashboard.orderTrendBoxStatusList[this.user.current_permission.context_application],
				this.orderList,
				e.value
			);
	}

	chartData: ChartjsModel = {
		data: [],
		datasets: [
			{
				data: [330, 600, 260, 700],
				label: 'Account A'
			},
			{
				data: [120, 455, 100, 340],
				label: 'Account B'
			},
			{
				data: [45, 67, 800, 500],
				label: 'Account C'
			}
		],
		labels: ['January', 'February', 'March', 'April'],
		chartType: 'bar',
		options: {
			responsive: true
		},
		colors: [],
		legend: false
	};

}
