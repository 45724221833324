import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';

@Component({
	selector: 'article-image',
	templateUrl: './article-image.component.html',
	styleUrls: ['./article-image.component.scss']
})
export class ArticleImageComponent implements OnInit {

	private wrapper: ElementRef;

	@ViewChild('wrapper') set wrapperContent(e: ElementRef) {
		if (e) {
			setTimeout(() => {
				this.wrapper = e;
				this.wrapper.nativeElement.style.height = e.nativeElement.offsetWidth + 'px';
			},0)
		}
	}

	@Input() image;
	@Input() alt;
	@Input() name;
	@Input() link;
	@Input('refreshArticleImage') set refreshArticleImage(e: boolean) {
		if (this.wrapper) {
			this.setWrapperHeight();
		}
	};

	configurationCustomer = ConfigurationCustomer;
	constructor() { }

	ngOnInit(): void {
	}

	setWrapperHeight() {
		this.wrapper.nativeElement.style.height = this.wrapper.nativeElement.offsetWidth + 'px';
	}

}
