import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
	FormatMultiplesCurrency,
	SentencecasePipe,
	UtilColorService,
	AngularCoreUtilService
} from '@saep-ict/angular-core';
import _ from 'lodash';
import { filter, take } from 'rxjs/operators';
import { StateFeature } from '../../../state';
import { StatisticsBaseSerieOrder } from '../../../model/statistics.model';
import { BaseChartClass } from '../chart-configurations/base-chart.service';
import { ActivatedRoute } from '@angular/router';
import { AppUtilService } from '../../util/app-util.service';
import { MetricsDetail } from '../../../model/statistics.model';
import { ChartEnum, ContextApplicationItemCodeEnum } from '@saep-ict/angular-spin8-core';

@Injectable()
export class FulfilledTrendChartConfigService extends BaseChartClass {
	metrics: MetricsDetail;

	constructor(
		public utilService: AppUtilService,
		public utilColorService: UtilColorService,
		public store: Store<any>,
		private translateService: TranslateService,
		private sentencecasePipe: SentencecasePipe,
		private formatMultiplesCurrency: FormatMultiplesCurrency,
		public route: ActivatedRoute
	) {
		super(utilService, utilColorService, store, route);
		this.chart.chartType = 'line';
		this.chart.options = this.optionsStyle(ChartEnum.Theme.DEFAULT);
		this.chart.colors = [{ borderColor: this.colorPrimary }];
	}

	// Functions

	createDataSerie() {
		// const fulfilledTrend = this.statistics.fulfilled.fulfilled_trend;

		const serieTemp: number[] = [];
		this.utilService
			.findLeaf(this.statistics.data, `fulfilled.fulfilled_trend`)
			.forEach((element: StatisticsBaseSerieOrder) => {
				serieTemp.push(element.value);
				this.fullLabels.push(element.date);
			});
		this.fullDataSets.push({
			label: 'value',
			data: serieTemp
		});
	}

	populateChart(rangeDays: number = this.cardDashboardConfigFulfilledTrend.rangeTempList.values[0]) {
		this.removeData(this.chart.labels);
		this.removeData(this.chart.datasets);

		let tempSerie = [];

		this.fullDataSets.forEach(item => {
			let serie = _.cloneDeep(item);
			serie.data = item.data.slice(-rangeDays);
			tempSerie.push(serie);
		});

		this.addData(this.chart.datasets, tempSerie);
		this.addData(this.chart.labels, this.fullLabels.slice(-rangeDays));

		this.getMetrics(rangeDays);
		this.chart = _.cloneDeep(this.chart);
	}

	getMetrics(rangeDays: number) {
		this.user$.pipe(take(1)).subscribe(res => {
			switch (res.data.current_permission.context_application) {
				case ContextApplicationItemCodeEnum.AGENT:
					this.statistics$ = this.store.select(StateFeature.getStatisticsAgent);
				case ContextApplicationItemCodeEnum.B2B:
					this.statistics$ = this.statistics$;
			}
			this.statistics$
				.pipe(
					filter(state => !!(state && state.data)),
					take(1)
				)
				.subscribe(res => {
					this.metrics = this.formatMetrics(
						this.utilService.findLeaf(res.data.data, `fulfilled.metrics.sold.days_${rangeDays}`)
					);
				});
		});
	}

	formatMetrics(data) {
		const metrics = {
			average: data.average < 1 ? +data.average.toFixed(2) : +data.average.toFixed(0),
			trend: data.trend.toFixed(0)
		};
		return metrics;
	}

	// Style

	tooltipsCallbacks(translateService, sentencecasePipe, formatMultiplesCurrency) {
		return {
			label: function (tooltipItem, data) {
				let label = translateService.instant('order.' + data.datasets[tooltipItem.datasetIndex].label) || '';

				if (label) {
					label = sentencecasePipe.transform(label);
					label += ': ';
				}
				label += formatMultiplesCurrency.transform(tooltipItem.yLabel);
				return label;
			}
		};
	}

	yAxesStyle(theme: ChartEnum.Theme) {
		return [
			{
				stacked: false,
				gridLines: {
					drawBorder: false,
					display: false,
					borderDash: [4, 4],
					color: theme === ChartEnum.Theme.DEFAULT ? this.colorBlack30 : this.colorWhite50
				},
				ticks: {
					display: false,
					// Valore minimo dell'asse
					// min: 0,
					// Scala da usare
					// stepSize: 1000,
					// Sets the offset of the tick labels from the axis
					padding: 8,
					// Font color for tick labels.
					fontColor: theme === ChartEnum.Theme.DEFAULT ? this.colorBlack : this.colorWhite
					// callback: this.ticksCallback()
				}
			}
		];
	}

	xAxesStyle() {
		return [
			{
				stacked: false,
				gridLines: {
					drawBorder: false,
					display: false
				},
				ticks: {
					display: false
				}
			}
		];
	}

	tooltipsStyle() {
		return {
			// se false il tooltip compare se sull'asse x
			intersect: false,
			callbacks: this.tooltipsCallbacks(
				this.translateService,
				this.sentencecasePipe,
				this.formatMultiplesCurrency
			)
		};
	}

	optionsStyle(theme: ChartEnum.Theme) {
		return {
			responsive: true,
			layout: this.layoutStyle(),
			elements: this.elementsStyle(),
			tooltips: this.tooltipsStyle(),
			hover: this.hoverStyle(),
			scales: {
				xAxes: this.xAxesStyle(),
				yAxes: this.yAxesStyle(theme)
			},
			aspectRatio: 2
		};
	}
}
