import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SentencecasePipe, MediaReplayService } from '@saep-ict/angular-core';
import { AngularSpin8CoreUtilService, PouchDbModel } from '@saep-ict/angular-spin8-core';
import { OrganizationPouchModel, DestinationPouchModel } from '@saep-ict/pouch_agent_models';
import { FileUpload } from '@saep-ict/ticket-center';
import { Observable, Subject } from 'rxjs';

/**
 * Questo service sarà presente in ogni progetto e servirà ad estende l'UtilService della libreria @saep-ict/angular-core
 * con i metodi di utility specifici per l'applicazione di cui fa parte.
 * Va da sé che questo servizio è utilizzabile anche a scopo di test prima di portare, eventualmente,
 * nuove utility nel servizio in libreria.
 */
@Injectable({
	providedIn: 'root'
})
export class AppUtilService extends AngularSpin8CoreUtilService {
	constructor(
		router: Router,
		public snackBar: MatSnackBar,
		public translate: TranslateService,
		public sentencecasePipe: SentencecasePipe,
		public mediaReplayService: MediaReplayService
	) {
		super(router, snackBar, translate, sentencecasePipe);
	}
	/**
	 * Metodo che si aspetto in ingresso un `object` strutturato ricorsivamente come {key: string; value: any | any[]}
	 * e restituisce il value elaborando la catena ricorsiva espressa in ingresso dal `path`
	 * @param object oggetto principale da elaborare
	 * @param path percorso annidato da interrogare
	 * @returns il valore al percorso richiesto
	 */
	findLeaf(object: any, path: string) {
		if (!object) {
			return null;
		}
		path = path.replace(/\[(\w+)\]/g, '.$1');
		path = path.replace(/^\./, '');
		var a = path.split('.');
		for (var i = 0, n = a.length; i < n; ++i) {
			const k = a[i];
			const inner = object.find(stat => stat.key == k);
			if (inner) {
				object = inner.value;
			} else {
				return null;
			}
		}
		return object;
	}

	returnIsRegisteredOffice(list: DestinationPouchModel[] = []): DestinationPouchModel {
		return list.find(i => i.is_registered_office);
	}

	returnPrefixVatNumber(organization: OrganizationPouchModel): string {
		const mainDestination = this.returnIsMainOfList<DestinationPouchModel>(organization.destination_list);
		return mainDestination?.address?.country || '';
	}

	returnCompleteVatNumber(organization: OrganizationPouchModel): string {
		const vatPrefix = this.returnPrefixVatNumber(organization);
		return organization?.tax_data?.vat_number ? vatPrefix + organization?.tax_data?.vat_number : '';
	}

	castToString(input: any): string {
		return input.toString();
	}

	swapArrayElements(arr: any[], indexA: number, indexB: number) {
		const temp = arr[indexA];
		arr[indexA] = arr[indexB];
		arr[indexB] = temp;
		return arr;
	}

	// Composes the document id
	returnDocumentId(document_type: PouchDbModel.PouchDbDocumentType, code_item: string): string {
		if (document_type && code_item) {
			return document_type + '_' + code_item;
		}
	}

	// check if some of the initial form values has changed
	onCreateFormValueChange(form: FormGroup): Observable<boolean> {
		const hasChange = new Subject<boolean>();
		const initialValue = form.value;
		form.valueChanges.subscribe(value => {
			hasChange.next(Object.keys(initialValue).some(key => form.value[key] != initialValue[key]));
		});
		return hasChange.asObservable();
	}

	arrayMoveElement<T>(array: T[], old_index: number, new_index: number): T[] {
		if (new_index >= array.length) {
			var k = new_index - array.length + 1;
			while (k--) {
				array.push(undefined);
			}
		}
		array.splice(new_index, 0, array.splice(old_index, 1)[0]);
		return array;
	}

	arrayRemoveElement<T>(array: T[], index) {
		array.splice(index, 1);
		return array;
	}

	isDesktop() {
		// return this.mediaReplayService.deviceW >= 1200;
		return this.mediaReplayService.deviceW > 1439;
	}

	isTablet() {
		const w = this.mediaReplayService.deviceW;
		//return w < 1200 && w >= 768;
		return w < 1440 && w > 767;
	}

	isMobile() {
		return this.mediaReplayService.deviceW < 768;
	}

	showSnackBar(message: string, action = '', duration = 3000) {
		let finalMessage = this.translate.instant(message);
		finalMessage = this.sentencecasePipe.transform(finalMessage);
		this.snackBar.open(finalMessage, action, { duration: duration });
	}
}
