<div class="container-fluid">
	<div class="row">
		<div class="col-12">
			<form class="angelo-theme" [formGroup]="form">
				<div class="row">
					<!-- Titolo -->
					<div class="col-md-12">
						<mat-form-field>
							<mat-label
								>{{
									'saep_ict_angular_spin8_core.general.title' | translate | sentencecase
								}}
								*</mat-label
							>
							<input matInput type="text" formControlName="title" />
							<mat-error>
								<form-error-message
									ngDefaultControl
									[formControl]="getFormControl('title')"
								></form-error-message>
							</mat-error>
						</mat-form-field>
					</div>

					<!-- first_evasion_date -->
					<div class="col-md-6">
						<mat-form-field>
							<mat-label> {{ 'offer.field.date_end_validity' | translate | sentencecase }} * </mat-label>
							<input
								matInput
								readonly
								formControlName="first_evasion_date"
								[matDatepicker]="DateEndValidityPicker"
								(click)="DateEndValidityPicker.open()"
							/>
							<mat-datepicker-toggle matSuffix [for]="DateEndValidityPicker"></mat-datepicker-toggle>
							<mat-datepicker #DateEndValidityPicker></mat-datepicker>
							<mat-error>
								<form-error-message
									ngDefaultControl
									[formControl]="getFormControl('first_evasion_date')"
								></form-error-message>
							</mat-error>
						</mat-form-field>
					</div>

					<!-- payment_method -->
					<div class="col-md-6">
						<mat-form-field>
							<mat-label>{{ 'offer.field.payment_terms' | translate | sentencecase }} *</mat-label>
							<mat-select formControlName="payment_method">
								<mat-option
									*ngFor="let paymentMethod of auxiliaryTable.paymentList"
									[value]="paymentMethod.code_item"
								>
									{{ paymentMethod.description }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<!-- other_descriptions -->
					<div class="col-md-12">
						<mat-checkbox formControlName="other_descriptions">
							{{ 'offer.field.other_descriptions' | translate | sentencecase }}
						</mat-checkbox>
					</div>

					<ng-container *ngIf="form.get('other_descriptions').value">
						<!-- object -->
						<div class="col-md-12">
							<mat-form-field>
								<mat-label>{{ 'offer.field.object' | translate | sentencecase }}</mat-label>
								<input matInput type="text" formControlName="object" />
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="getFormControl('object')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>

						<!-- summary -->
						<div class="col-md-12">
							<mat-form-field>
								<mat-label>{{ 'offer.field.summary' | translate | sentencecase }}</mat-label>
								<input matInput type="text" formControlName="summary" />
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="getFormControl('summary')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>

						<!-- references -->
						<div class="col-md-12">
							<mat-form-field>
								<mat-label>{{ 'offer.field.references' | translate | sentencecase }}</mat-label>
								<input matInput type="text" formControlName="references" />
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="getFormControl('references')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>

						<!-- premise -->
						<div class="col-md-12">
							<mat-form-field class="example-full-width">
								<mat-label>{{ 'offer.field.premise' | translate | sentencecase }}</mat-label>
								<textarea matInput formControlName="premise"></textarea>
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="getFormControl('premise')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>
					</ng-container>
				</div>
			</form>
		</div>
	</div>
</div>
