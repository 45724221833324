export namespace OrganizationExportExcelAgent {
	export const heading = [['Business Name', 'Code', 'Vat Number', 'Address']];

	export const exportExcel = (list: any) => {
		let json = [];
		list.forEach(org => {
			let business_address = '';
			org.destination_list.forEach(dest => {
				if (dest.is_main_of_list) {
					let a = dest.address;
					business_address =
						a.address +
						' - ' +
						a.zip_code +
						' ' +
						a.locality +
						(a.province ? ' (' + a.province.label + ')' : '');
				}
			});
			json.push({
				name: org.business_name ? org.business_name: '',
				code_erp: org.code_erp ? org.code_erp: '',
				vat_number: org.tax_data && org.tax_data.vat_number ? org.tax_data.vat_number.toUpperCase() : '',
				address: business_address
			});
		});
		return json;
	};
}

