import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AngularCoreUtilService, GuidFormatterPipe, LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { AngularSpin8CoreUtilTranslateService, ROUTE_URL } from '@saep-ict/angular-spin8-core';
import _ from 'lodash';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';
import { DialogImageGalleryComponent } from '../../dialog/dialog-image-gallery/dialog-image-gallery.component';
import { CustomerAppConfig } from '../../../customer-app.config';
import { Category, LanguageItem } from '@saep-ict/pouch_agent_models';

@Component({
	selector: 'category-list-wrapper',
	templateUrl: './category-list-wrapper.component.html',
	styleUrls: ['./category-list-wrapper.component.scss']
})
export class CategoryListWrapperComponent extends LocalListHandlerWidgetWrapper {

	@Output() onItemEdit: EventEmitter<Category> = new EventEmitter();
	@Output() onItemDelete: EventEmitter<Category> = new EventEmitter();
	configurationCustomer = ConfigurationCustomer;

	constructor(
		public utilService: AngularCoreUtilService,
		public translateService: TranslateService,
		public store: Store,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		public guidFormatterPipe: GuidFormatterPipe,
		public router: Router,
		private dialog: MatDialog,
		private appConfig: CustomerAppConfig
	) {
		super(utilService, translateService, store);
	}

	ngOnInit(): void {}

	async dialogGallery(e: Category) {
		this.dialog.open(DialogImageGalleryComponent, {
			data: {
				language_list: await this.setAttachmentBucketLink(e)
			},
			disableClose: true,
			panelClass: ['angelo-theme-dialog', 'image-gallery']
		});
	}

	setAttachmentBucketLink(e: Category): Promise<LanguageItem[]> {
		return new Promise(resolve => {
			try {
				e = _.cloneDeep(e);
				for (const languageItem of e.language_list) {
					for (const attachment of languageItem.image_list) {
						attachment.bucket_link =
						`${this.appConfig.config.bucketManager.be_url}/category/download-multipart?path=/${e.code_item}/${attachment.name}&token=${this.appConfig.token}`;
					}
				}
				resolve(e.language_list);
			} catch(err) {
				throw new Error(err);
			}
		});
	}

}
