import { ITdDataTableColumnCustom, ListStructureEnum } from '@saep-ict/pouch_agent_models';

export const articleColumnMapBackofficeArticle: ITdDataTableColumnCustom[] = [
	// colonne di configurazione
	{
		name: 'articleDescription.sequence',
		label: null,
		sortType: ListStructureEnum.Sort.Type.NUMBER,
		hidden: true
	},
	{
		name: 'column-width-configuration-class',
		label: 'articleColumnMapBackofficeArticle',
		hidden: true
	},
	// colonne renderizzate
	{
		name: 'code_erp',
		labelPath: 'article.field.ref',
		label: null,
		hidden: false
	},
	{
		name: 'description',
		labelPath: 'article.field.description',
		label: null,
		sortType: ListStructureEnum.Sort.Type.STRING,
		hidden: false
	}
];