import { ContextCodeAssociation, BodyTablePouchCustomModel } from '@saep-ict/angular-spin8-core';
import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { ListViewDataPouchModel, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';

export class BasePouch extends AbstractPouchdbMethod {
	getDetail<D>(_id: string): Promise<D> {
		return new Promise((resolve, reject) => {
			this.get(_id)
				.then((doc: D) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	saveBodyTable<D extends BodyTablePouchCustomModel | OrganizationPouchModel | ContextCodeAssociation>(
		data: D,
		useLoader: boolean = true
	): Promise<D> {
		const useGuid = data._id ? false : true;
		const some = useGuid ? data.type : data._id;
		return new Promise(resolve => {
			this.put(data, some, useGuid, useLoader)
				.then(res => {
					resolve(res);
				})
				.catch(error => {
					console.log(error);
				});
		});
	}

	deleteBodyTable<D extends BodyTablePouchCustomModel>(data: D): Promise<D> {
		return new Promise((resolve, reject) => {
			this.delete(data)
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getView<D>(path: string): Promise<ListViewDataPouchModel<D>> {
		return new Promise((resolve, reject) => {
			this.queryIndex(path, {})
				.then((doc: ListViewDataPouchModel<D>) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	allDocs<D>(
		options?:
			| PouchDB.Core.AllDocsWithKeyOptions
			| PouchDB.Core.AllDocsWithKeysOptions
			| PouchDB.Core.AllDocsWithinRangeOptions
			| PouchDB.Core.AllDocsOptions
	): Promise<ListViewDataPouchModel<D>> {
		return new Promise((resolve, reject) => {
			this.db
				.allDocs(options)
				.then((res: any) => {
					resolve(res);
				})
				.catch(err => {
					reject(err);
				});
		});
	}
}
