import { FormControlMultipurposeEnum, FormControlMultipurposeModel } from '@saep-ict/angular-core';
import _ from 'lodash';
import { Validators } from '@angular/forms';
import { ConfigurationOrder } from '../../../order/order.constants';
import { ConfigurationOrderExtend } from '../order.constants';
import { FormValidatorCustom } from '../../../form-validator-custom';

export const configurationCustomerOrderHeaderFieldListBackOffice: FormControlMultipurposeModel.Item[] = [
	{
		name: 'submission_date',
		type: FormControlMultipurposeEnum.ItemType.DATE,
		on_form_template: true,
		label: 'theme.order.field.submission_date',
		column_width: 12
	},
	{
		name: 'note_order',
		type: FormControlMultipurposeEnum.ItemType.STRING,
		on_form_template: true,
		label: 'theme.order.field.note',
		column_width: 12
	},
	{
		name: 'order_causal',
		type: FormControlMultipurposeEnum.ItemType.STRING,
		on_form_template: true,
		label: 'theme.order.field.causal',
		column_width: 12
	},
	{
		name: 'payment_code',
		type: FormControlMultipurposeEnum.ItemType.STRING,
		on_form_template: true,
		label: 'theme.order.field.payment_code',
		column_width: 12
	},
	{
		name: 'actual_payment_date',
		type: FormControlMultipurposeEnum.ItemType.DATE,
		on_form_template: true,
		label: 'theme.order.field.actual_payment_date',
		column_width: 12
	}
];

export const configurationCustomerOrderRevisionHeaderFieldListBackOffice: FormControlMultipurposeModel.Item[] = [
	{
		name: 'date',
		type: FormControlMultipurposeEnum.ItemType.DATE,
		on_form_template: true,
		label: 'theme.order.field.date',
		column_width: 12
	}
];
