import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseStateModel, MediaReplayService } from '@saep-ict/angular-core';
import { ContextApplicationItemCodeEnum, OrganizationStateModel, UserDetailModel } from '@saep-ict/angular-spin8-core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { CustomerAppConfig } from '../../../customer-app.config';
import { StateFeature } from '../../../state';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';

@Component({
  selector: 'survey-center',
  templateUrl: './survey-center.component.html',
  styleUrls: ['./survey-center.component.scss']
})
export class SurveyCenterComponent implements OnInit {

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

  surveyCenterIframeUrl: string;

  constructor(
    public mediaReplayService: MediaReplayService,
    private appConfig: CustomerAppConfig,
    private route: ActivatedRoute,
    private store: Store,
		private utilBreadcrumbService: UtilBreadcrumbService
  ) {
    this.loadStaticData();
    this.formatIframeUrl();
  }

  ngOnInit(): void {
  }

	loadStaticData() {
		this.user$.pipe(take(1)).subscribe((store: BaseStateModel<UserDetailModel>) => {
			this.user = store ? store.data : null;
		});
	}

  formatIframeUrl() {
    let platform: string;
    let clientCode: string;
    const context_code = this.user.current_permission.context_code.code;
    const link_code_context_application = this.user.current_permission.context_application;

    switch (this.user.current_permission.context_application) {
      case ContextApplicationItemCodeEnum.B2B:
        platform = 'frontoffice';
        clientCode = this.user.current_permission.context_code.code;
        this.setNavigationItemSource(this.route.snapshot.data.pageKey);
        break;
      case ContextApplicationItemCodeEnum.BACKOFFICE:
        platform = 'backoffice';
        this.setNavigationItemSource(this.route.snapshot.data.pageKey);
        break;
      case ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN:
        platform = 'backoffice';
        this.setNavigationItemSource(this.route.snapshot.data.pageKey);
        break;
    }

    this.surveyCenterIframeUrl =
      this.appConfig.config.euroitaliaB2bOldAppIframeUrl +
      '/#/' +
      platform +
      '/survey-center/' +
      this.route.snapshot.data.pageKey +
      `${clientCode ? '/' + clientCode : ''}` +
      '?context_application=' +
      link_code_context_application +
      '&context_code=' + context_code +
      '&token=' +
      `${this.appConfig.authenticationToken}` ;
  }

  setNavigationItemSource(pageKey: string)
  {
    pageKey = pageKey.replace(/-/g,"_").toLocaleLowerCase();
    this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['survey_center', `survey_center_${pageKey}`]);
    this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('survey_center');
    this.utilBreadcrumbService.subtitle.value = this.utilBreadcrumbService.getBreadcrumbTitle(`survey_center_${pageKey}`);
  }

  ngOnDestroy(): void {
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}
}
