import { ConfigurationUtil } from '@saep-ict/angular-spin8-core';
import { ColumnListOfferMap } from './column-list-section-map/column-list-offer.constant';

export namespace ColumnListMap {
	export const ContextApplicationSection = <
		ConfigurationUtil.Context.ApplicationSelector<ConfigurationUtil.DocumentSelector<any>>
	>{
		BACKOFFICE: {
			OFFER: ColumnListOfferMap.Base
		},
		AGENT: {
			OFFER: ColumnListOfferMap.Base
		},
		B2B: {
			OFFER: ColumnListOfferMap.Base
		},
		B2C: {
			OFFER: ColumnListOfferMap.Base
		},
		PORTAL: {
			OFFER: ColumnListOfferMap.Base
		},
		CRM: {
			OFFER: ColumnListOfferMap.CRM
		},
		PUBLIC: {
			OFFER: ColumnListOfferMap.Base
		}
	};
}
