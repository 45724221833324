<page-with-sidebar [sideBarPosition]="sideBarPositionValues.NONE">
	<ng-container pageMain>
		<mat-card class="angelo-theme user-detail">
			<div class="section" *ngIf="!isEditing; else editingTemplate">
				<p class="section__title">{{ 'user.profile.information' | translate | sentencecase }}</p>
				<div class="info">
					<div class="info__profile-image">
						<!-- <div class="placeholder-box"></div> -->
						<div class="person-placeholder-box">
							<mat-icon [svgIcon]="'custom-person'" class="person"></mat-icon>
						</div>
					</div>
					<div class="info__content">
						<div class="info__row">
							<p class="info__row__header">{{ 'user.field.first_name' | translate | sentencecase }}</p>
							<p class="info__row__content">{{ user.first_name }}</p>
						</div>
						<div class="info__row">
							<p class="info__row__header">{{ 'user.field.last_name' | translate | sentencecase }}</p>
							<p class="info__row__content">{{ user.last_name }}</p>
						</div>
						<div class="info__row">
							<p class="info__row__header">
								{{ 'theme.contact.field.email_label' | translate | sentencecase }}
							</p>
							<p class="info__row__content">{{ user.username }}</p>
						</div>
						<div class="info__row">
							<p class="info__row__header">{{ 'language.name' | translate | sentencecase }}</p>
							<p class="info__row__content">
								{{ 'language.' + user.language | translate | sentencecase }}
							</p>
						</div>
					</div>

					<div class="edit-button">
						<ng-container actions *ngIf="!isEditing">
							<form-button-bar
								[align]="utilService.isMobile() ? 'vertical' : 'right'"
								[primaryButtonLabel]="'general.edit_profile' | translate | sentencecase"
								(primaryButtonClickEmitter)="editForm()"
							></form-button-bar>
						</ng-container>
					</div>
				</div>
				<!-- Change user data -->
			</div>
			<ng-template #editingTemplate>
				<p class="section__title">{{ 'user.profile.information' | translate | sentencecase }}</p>
				<form [formGroup]="form" class="angelo-theme">
					<div class="section__user-info">
						<button mat-button class="p-0 upload_image">
							<mat-icon>account_circle</mat-icon>
							{{ 'user.profile.upload_image' | translate | sentencecase }}
						</button>
						<mat-form-field>
							<mat-select
								formControlName="language"
								placeholder="{{ 'language.name' | translate | sentencecase }}"
							>
								<mat-option *ngFor="let language of languageList" [value]="language.description_short">
									{{ 'language.' + language.description_short | translate | sentencecase }}
								</mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field>
							<input
								#firstElementFocus
								formControlName="name"
								matInput
								[value]="user.first_name"
								placeholder="{{ 'user.field.first_name' | translate | sentencecase }}"
							/>
						</mat-form-field>
						<mat-form-field>
							<input
								formControlName="lastName"
								matInput
								[value]="user.last_name"
								placeholder="{{ 'user.field.last_name' | translate | sentencecase }}"
							/>
						</mat-form-field>
					</div>
					<ng-container actions *ngIf="isEditing">
						<form-button-bar
							class="actions-buttons"
							[align]="'space-between'"
							[primaryButtonLabel]="'general.save' | translate | sentencecase"
							(primaryButtonClickEmitter)="onFormSubmit()"
							[primaryButtonIsDisabled]="!form.valid"
							[secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
							(secondaryButtonClickEmitter)="exitEditMode()"
						></form-button-bar>
					</ng-container>
				</form>
			</ng-template>
			<mat-divider class="top-spacing mt-20"></mat-divider>
			<div class="section">
				<p class="section__title">{{ 'user.profile.change_password.label' | translate | sentencecase }}</p>
				{{ 'user.profile.change_password.text' | translate | sentencecase }}
				<a [routerLink]="['/', ROUTE_URL.authentication, ROUTE_URL.passwordUpdate]">link</a>
			</div>
			<mat-divider class="show-on-angelo-theme"></mat-divider>
			<ng-container *ngIf="user.current_permission.context_application !== contextApplicationItemCodeEnum.B2C">
				<ng-container *ngFor="let contextApplication of contextApplicationList; let last = last">
					<div class="section">
						<p class="section__title">
							{{
								permissionUtilService.contextTypeAndApplicationLinkFullObject(contextApplication.code)
									.application_name
							}}
						</p>
						<context-code-management-wrapper
							[localListHandlerWidgetWrapperData]="contextApplication.context_code_data"
							[localListHandlerWidgetWrapperColumnList]="columns[contextApplication.code]"
							[showLinkToDetail]="false"
						>
						</context-code-management-wrapper>
					</div>
					<mat-divider *ngIf="!last" class="show-on-angelo-theme"></mat-divider>
				</ng-container>
			</ng-container>
		</mat-card>
	</ng-container>
</page-with-sidebar>
