import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
// store
import { Store } from '@ngrx/store';
import {
	BaseStateModel,
	SubscribeManagerService,
	MediaReplayService,
	AngularCoreUtilService
} from '@saep-ict/angular-core';
import { LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
// model
import { PermissionContextListColumnService } from '../../../service/td-data-table/implementation/backoffice/permission-context-list.service';
// widet & utility
import { PermissionUtilService } from '../../../service/util/permission-util.service';
import { ContextCodeManagementStateAction } from '../../../state/backoffice/context-code/context-code-management/context-code-management.actions';
import { ContextApplicationItemCodeEnum, ContextCodeItem, ContextTypeAndApplicationLink } from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'dialog-add-context-code',
	templateUrl: './dialog-add-context-code.component.html',
	styleUrls: ['./dialog-add-context-code.component.scss'],
	providers: [SubscribeManagerService, PermissionContextListColumnService]
})
export class DialogAddContextCodeComponent implements OnInit, OnDestroy {
	listPageBaseData = <LocalListHandlerBaseModel<ContextCodeItem>>{
		data: [],
		filters: {
			localSearchText: {
				value: null,
				key_list: ['code', 'description', 'code_erp']
			}
		},
		pagination: {
			pageSize: 5
		},
		sort: {
			name: 'code',
			order: 'ASC'
		}
	};

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: ContextTypeAndApplicationLink,
		private store: Store<any>,
		public mediaReplayService: MediaReplayService,
		public dialogRef: MatDialogRef<DialogAddContextCodeComponent>,
		public utilService: AngularCoreUtilService,
		public permissionUtilService: PermissionUtilService,
		public permissionContextListColumnService: PermissionContextListColumnService,
		private subscribeManagerService: SubscribeManagerService
	) {
		this.permissionUtilService.resetContextCodeListAssociatedState();
		this.setVisibleTableColumn();
		this.subscribeManagerService.populate(this.subscribeContextCodeList().subscribe(), 'context-code-list');
		this.store.dispatch(ContextCodeManagementStateAction.loadList({ contextTypeAndApplicationLink: this.data }));
	}

	ngOnInit() {}
	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.permissionUtilService.resetContextCodeListAssociatedState();
	}

	// subscribe
	subscribeContextCodeList(): Observable<BaseStateModel<ContextCodeItem[]>> {
		return this.permissionUtilService.subscribeContextCodeList().pipe(
			map((state: BaseStateModel<ContextCodeItem[]>) => {
				for (let i = 0; i < state.data.length; i++) {
					if (
						!this.data.context_code_item.already_associated_context_code_list.includes(
							state.data[i]['code']
						)
					) {
						this.listPageBaseData.data.push(state.data[i]);
					}
				}
				this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
				return state;
			}),
			catchError((error, caught) => {
				console.log(error);
				this.listPageBaseData.data = [];
				this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
				return caught;
			})
		);
	}

	// widget & utility
	selectCode(permissionContext: ContextCodeItem) {
		this.dialogRef.close(permissionContext);
	}

	// widget & utility
	setVisibleTableColumn() {
		switch (this.data.context_application_item_code) {
			case ContextApplicationItemCodeEnum.B2C:
				this.permissionContextListColumnService.columns.map(col => {
					col.hidden = ['permission', 'action'].includes(col.name);
				});
				break;
			default:
				this.permissionContextListColumnService.columns.map(col => {
					col.hidden = ['permission', 'action', 'typology'].includes(col.name);
				});
				break;
		}
	}
}
