import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

// store
import { Store } from '@ngrx/store';

// constant
import { ConfigurationCustomer } from '../../../constants/configuration-customer';

// misc
import { TdDataTableComponent } from '@covalent/core/data-table';
import { Observable } from 'rxjs';
import { map, filter, catchError } from 'rxjs/operators';
import { PermissionUtilService } from '../../../service/util/permission-util.service';
import { AngularCoreUtilService } from '@saep-ict/angular-core';
import { PermissionContextListColumnService } from '../../../service/td-data-table/implementation/backoffice/permission-context-list.service';
import { DialogContextCodeEditComponent } from '../../../widget/dialog/dialog-context-code-edit/dialog-context-code-edit.component';
import { SubscribeManagerService, BaseStateModel } from '@saep-ict/angular-core';
import { LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import { ContextCodeManagementWrapperComponent } from '../../../widget/context-code/context-code-management-wrapper/context-code-management-wrapper.component';
import {
	ContextCodeManagementActionEnum,
	ContextCodeManagementStateAction
} from '../../../state/backoffice/context-code/context-code-management/context-code-management.actions';
import {
	ContextApplicationItemCodeEnum,
	ContextCodeItem,
	ContextCodeItemLink,
	ContextTypeAndApplicationLink,
	LinkDetailModel
} from '@saep-ict/angular-spin8-core';

import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SideBarPositionValues } from '../../../enum/sidebar-position-values.enum';

@Component({
	selector: 'context-code-management',
	templateUrl: './context-code-management.component.html',
	styleUrls: ['./context-code-management.component.scss'],
	providers: [SubscribeManagerService, PermissionContextListColumnService]
})
export class ContextCodeManagementComponent implements OnInit, OnDestroy {
	@ViewChild('dataTable') dataTable: TdDataTableComponent;
	@ViewChild(ContextCodeManagementWrapperComponent)
	contextCodeManagementWrapperComponent: ContextCodeManagementWrapperComponent;

	public listPageBaseData = <LocalListHandlerBaseModel<ContextCodeItem>>{
		filters: {
			localSearchText: {
				value: null,
				key_list: ['code', 'description']
			}
		},
		pagination: {
			pageSize: 5
		},
		sort: {
			name: 'code',
			order: 'ASC'
		},
		data: []
	};

	contextTypeAndApplicationLink: ContextTypeAndApplicationLink;
	isLoading = true;

	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	configurationCustomer = ConfigurationCustomer;
	sideBarPositionValues = SideBarPositionValues;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		public permissionContextListColumnService: PermissionContextListColumnService,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		public permissionUtilService: PermissionUtilService,
		private snackBar: MatSnackBar,
		public utilService: AngularCoreUtilService,
		private dialog: MatDialog,
		private utilBreadcrumbService: UtilBreadcrumbService
	) {
		this.subscribeManagerService.populate(this.routeParamSubscribe().subscribe(), 'route-param-state');
		this.subscribeManagerService.populate(this.subscribeContextCodeList().subscribe(), 'context-code-list');
		this.setRouteMetaInformation();
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.permissionUtilService.resetContextCodeListAssociatedState();
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	// subscribe
	routeParamSubscribe(): Observable<ContextTypeAndApplicationLink> {
		return this.route.paramMap.pipe(
			map((routeParam: ParamMap) => {
				this.listPageBaseData.dataSubset = [];
				this.isLoading = true;

				this.refreshTable();
				// resetto gli state
				this.permissionUtilService.resetContextCodeListAssociatedState();

				this.contextTypeAndApplicationLink = ConfigurationCustomer.ContextApplication.map.find(
					i =>
						i.context_application_item_code ===
						ContextApplicationItemCodeEnum[routeParam.get('contextApplicationType').toUpperCase()]
				);
				if (this.contextTypeAndApplicationLink) {
					// faccio la dispatch per reperire i dati
					this.loadContextCodeList();
				} else {
					// TODO: gestire l'accidentale inserimento di un errato parametro contextApplicationType in URL
					this.snackBar.open('Context Application Not Found', 'OK', { duration: 5000 });
				}
				// Set columns according to context application
				this.setVisibleTableColumn();
				this.setRouteMetaInformation();
				return this.contextTypeAndApplicationLink;
			})
		);
	}

	subscribeContextCodeList(): Observable<BaseStateModel<LinkDetailModel[]>> {
		return this.permissionUtilService.subscribeContextCodeList().pipe(
			filter(
				(state: BaseStateModel<ContextCodeItem[]>) => state.type === ContextCodeManagementActionEnum.UPDATE_LIST
			),
			map((state: BaseStateModel<ContextCodeItem[]>) => {
				this.listPageBaseData.data = state.data;
				this.contextCodeManagementWrapperComponent.updateListPageBaseData(state.data);
				return state;
			}),
			catchError((error, caught) => {
				console.log(error);
				this.listPageBaseData.data = [];
				return caught;
			})
		);
	}

	// load
	loadContextCodeList() {
		this.store.dispatch(
			ContextCodeManagementStateAction.loadList({
				contextTypeAndApplicationLink: this.contextTypeAndApplicationLink
			})
		);
	}

	// navigation
	goToDetail(contextCodeItem: ContextCodeItem) {
		this.router.navigate([contextCodeItem.code], { relativeTo: this.route });
	}

	// dialog
	dialogCreateContextCodeItem(context_code_item: ContextCodeItemLink) {
		console.log('context_code_item', context_code_item);
		const dialogRef: MatDialogRef<DialogContextCodeEditComponent> = this.dialog.open(
			DialogContextCodeEditComponent,
			{
				data: context_code_item,
				panelClass: ['dialog-medium', 'angelo-theme-dialog']
			}
		);
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				// Messaggio gestito dalla dialog
				this.loadContextCodeList();
			}
		});
	}

	// widget & utility
	setVisibleTableColumn() {
		switch (this.contextTypeAndApplicationLink.context_application_item_code) {
			case ContextApplicationItemCodeEnum.B2C:
				this.permissionContextListColumnService.columns.map(col => {
					col.hidden = ['permission', 'action'].includes(col.name);
				});
				break;
			default:
				this.permissionContextListColumnService.columns.map(col => {
					col.hidden = ['permission', 'action', 'typology'].includes(col.name);
				});
				break;
		}
	}

	refreshTable() {
		if (this.dataTable) {
			this.dataTable.refresh();
		}
	}

	setRouteMetaInformation() {
		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle(
			'context_code_management'
		);
		this.utilBreadcrumbService.subtitle.value = `saep_ict_angular_spin8_core.context_code.description.${this.contextTypeAndApplicationLink.context_application_item_code}`;
		this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['context_code_management',`context_code_management_${this.contextTypeAndApplicationLink.context_application_item_code.toLocaleLowerCase()}`]);
	}

	filterCodesOnTabClick(event: MatTabChangeEvent) {
		switch (event.tab.textLabel) {
			case 'All codes':
				break;
			case 'Associated code':
				break;
			case 'Blank code':
				break;
		}
	}
}
