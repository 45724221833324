<form class="angelo-theme" (ngSubmit)="onFormSubmit()" [formGroup]="form">
	<div class="row">
		<div class="col-1 icon-input mt-10"><mat-icon>account_circle</mat-icon></div>
		<div class="col-10">
			<mat-form-field>
				<input
					matInput
					formControlName="email"
					placeholder="{{ 'login.general.email' | translate | sentencecase }}"
				/>
			</mat-form-field>
		</div>
	</div>
	<div class="row">
		<div class="col-1 icon-input mt-10"><mat-icon>lock</mat-icon></div>
		<div class="col-10">
			<mat-form-field>
				<input
					matInput
					formControlName="password"
					placeholder="{{ 'login.general.password' | translate | sentencecase }}"
					[type]="hide ? 'password' : 'text'"
				/>
				<mat-icon class="mat-icon-hide highlight" matSuffix (click)="hide = !hide">
					{{ hide ? 'visibility_off' : 'visibility' }}
				</mat-icon>
			</mat-form-field>
		</div>
	</div>

	<mat-toolbar>
		<button mat-raised-button [disabled]="!form.valid" [color]="'primary'">
			{{ 'login.general.login' | translate | sentencecase }}
		</button>
	</mat-toolbar>

	<div class="link">
		<a [routerLink]="['/', ROUTE_URL.authentication, ROUTE_URL.passwordRecovery]">
			{{ 'login.general.recover_password' | translate | sentencecase }}</a
		>
	</div>

	<div class="link mt-8" *ngIf="canAccessToRegistration">
		<a [routerLink]="linkAccessToRegistration()">
			{{ 'login.general.request_access' | translate | sentencecase }}</a
		>
	</div>
</form>
