import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	BaseStateModel,
	SubscribeManagerService
} from '@saep-ict/angular-core';
import { LocalListHandlerBaseModel, ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import { filter, map, mergeMap } from 'rxjs/operators';
import { StateFeature } from '../../../state';
import { Observable } from 'rxjs';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ArticleStockActionEnum, ArticleStockStateAction } from '../../../state/article-stock/article-stock.actions';
import { ArticleListStructureWrapperPlainListComponent } from '../../../widget/article/article-list-structure-wrapper/article-list-structure-wrapper-plain-list/article-list-structure-wrapper-plain-list.component';
import { ArticleActionEnum } from '../../../state/article/article.actions';
import { AngularSpin8CoreUtilArticleService, ArticleRecapStockStateModel } from '@saep-ict/angular-spin8-core';
import { SideBarPositionValues } from '../../../enum/sidebar-position-values.enum';

@Component({
	selector: 'product-stock',
	templateUrl: './product-stock.component.html',
	styleUrls: ['./product-stock.component.scss'],
	providers: [SubscribeManagerService]
})
export class ProductStockComponent implements OnInit, OnDestroy {
	@ViewChild(ArticleListStructureWrapperPlainListComponent)
	articleListStructureWrapperPlainListComponent: ArticleListStructureWrapperPlainListComponent;

	articleStock$: Observable<BaseStateModel<ArticleRecapStockStateModel>> = this.store.select(
		StateFeature.getArticleStockState
	);
	articleStock: ArticleRecapStockStateModel;

	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);
	articleList: ArticlePouchModel[];

	filteredArticleMap: {
		all: ArticlePouchModel[];
		freeSample: ArticlePouchModel[];
		regular: ArticlePouchModel[];
	};

	public listPageBaseData = <LocalListHandlerBaseModel<any>>{
		data: [],
		pagination: {
			pageSize: 10
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: [
					'articleDescription.language_list.description',
					ConfigurationCustomer.AppStructure.Erp.has_erp ? 'code_erp' : 'code_item',
					'free_sample'
				]
			}
		},
		columnList: ConfigurationCustomer.Article.articleColumnMapBackofficeProductStockColumnList,
		sort: {
			name: ConfigurationCustomer.AppStructure.Erp.has_erp ? 'code_erp' : 'code_item',
			order: 'ASC'
		},
		sortRemapObject: {
			description: 'articleDescription.language_list.description'
		}
	};

    sideBarPositionValues = SideBarPositionValues;

	constructor(
		private subscribeManagerService: SubscribeManagerService,
		private store: Store<any>,
		private utilBreadcrumbService: UtilBreadcrumbService,
		private articleService: AngularSpin8CoreUtilArticleService
	) {
		this.store.dispatch(ArticleStockStateAction.load());
		this.subscribeManagerService.populate(this.subscribeProductStock().subscribe(), 'subscribeProductStock');
		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('product_stock');
		this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['product_stock']);
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
		this.store.dispatch(ArticleStockStateAction.reset());
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	subscribeProductStock() {
		return this.articleList$.pipe(
			filter((state: BaseStateModel<ArticlePouchModel[]>) => !!(state && state.data)),
			mergeMap((state: BaseStateModel<ArticlePouchModel[]>) => {
				switch (state.type) {
					case ArticleActionEnum.UPDATE:
						this.articleList = state.data;
				}
				return this.articleStock$;
			}),
			filter((state: BaseStateModel<ArticleRecapStockStateModel>) => !!(state && state.data)),
			map((state: BaseStateModel<ArticleRecapStockStateModel>) => {
				switch (state.type) {
					case ArticleStockActionEnum.UPDATE:
						this.articleStock = state.data;
						this.articleList = this.articleService.mergeArticleDescriptionStock(
							this.articleList,
							this.articleStock.article_list
						);
						this.articleList = this.applyDefaultFilter(this.articleList);
						this.getFilteredArticleList();
						this.articleListStructureWrapperPlainListComponent.updateListPageBaseData(this.articleList);
						break;
					case ArticleStockActionEnum.ERROR:
						throw new Error(ArticleStockActionEnum.ERROR);
				}
			})
		);
	}

	applyDefaultFilter(articleList: ArticlePouchModel[]): ArticlePouchModel[] {
		return articleList.filter(article => article.valid);
	}

	getFilteredArticleList() {
		this.filteredArticleMap = {
			all: this.articleList,
			freeSample: this.articleList.filter(item => item.articleDescription.free_sample === 'S'),
			regular: this.articleList.filter(item => item.articleDescription.free_sample !== 'S')
		};
	}

	// WIP tab-wrapper
	filterOnTabClick(event: MatTabChangeEvent) {
		let filteredArticleList: ArticlePouchModel[];
		switch (event.tab.textLabel) {
			case 'all_products':
				filteredArticleList = this.filteredArticleMap.all;
				break;
			case 'regular':
				filteredArticleList = this.filteredArticleMap.regular;
				break;
			case 'posm_gwp':
				filteredArticleList = this.filteredArticleMap.freeSample;
				break;
		}
		this.articleListStructureWrapperPlainListComponent.updateListPageBaseData(filteredArticleList);
	}
}
