import { BaseTableListService, DateMomentService, ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { Inject, Injectable, Injector } from '@angular/core';

const injector = Injector.create({
	providers: [{ provide: DateMomentService, deps: [] }]
});

const dateMomentService = injector.get(DateMomentService);
@Injectable()
export class ReceiptColumnService extends BaseTableListService {
	constructor(@Inject(TranslateService) public translate: TranslateService) {
		super();
	}

	_columns: ITdDataTableColumnCustom[] = [
		{
			name: 'number',
			labelPath: 'receipt.field.document_number',
			label: null
		},
		// {
		// 	name: 'document_type',
		// 	labelPath: 'receipt.field.document_type',
		// 	label: null,
		// 	sortable: false
		// },
		// {
		// 	name: 'organization',
		// 	labelPath: 'saep_ict_angular_spin8_core.page.organization.name',
		// 	label: null
		// },
		{
			name: 'date',
			labelPath: 'receipt.field.date',
			label: null,
			width: { min: 100, max: 200 },
			numeric: true,
			format: (v: string) => dateMomentService.convertStringDateFromFormatToFormat(v, 'x', 'DD/MM/YYYY')
		},
		{
			name: 'expiration_date',
			labelPath: 'receipt.field.expiration_date',
			label: null,
			width: { min: 100, max: 200 },
			numeric: true,
			format: (v: string) => dateMomentService.convertStringDateFromFormatToFormat(v, 'x', 'DD/MM/YYYY')
		},
		{
			name: 'price',
			labelPath: 'receipt.field.amount',
			label: null,
			numeric: true
		},
		{
			name: 'download',
			labelPath: 'receipt.field.download',
			label: null,
			sortable: false,
			numeric: true
		}
	];
}
