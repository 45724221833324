import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseStateModel, MediaReplayService } from '@saep-ict/angular-core';
import { ContextApplicationItemCodeEnum, UserDetailModel } from '@saep-ict/angular-spin8-core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { CustomerAppConfig } from '../../../customer-app.config';
import { StateFeature } from '../../../state';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';

@Component({
  selector: 'asset-request-center',
  templateUrl: './asset-request-center.component.html',
  styleUrls: ['./asset-request-center.component.scss']
})
export class AssetRequestCenterComponent implements OnInit {

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

  assetRequestCenterIframeUrl: string;

  constructor(
    public mediaReplayService: MediaReplayService,
    private appConfig: CustomerAppConfig,
    private route: ActivatedRoute,
    private store: Store,
		private utilBreadcrumbService: UtilBreadcrumbService
  ) {
    this.loadStaticData();
    this.formatIframeUrl();
  }

  ngOnInit(): void {
  }

	loadStaticData() {
		this.user$.pipe(take(1)).subscribe((store: BaseStateModel<UserDetailModel>) => {
			this.user = store ? store.data : null;
		});
	}

  formatIframeUrl() {
    let platform: string;
    let clientCode: string;
    const link_code_context_application = this.user.current_permission.context_application;
    const context_code = this.user.current_permission.context_code.code;
    switch (this.user.current_permission.context_application) {
      case ContextApplicationItemCodeEnum.B2B:
        platform = 'frontoffice';
        clientCode = this.user.current_permission.context_code.code;
        this.setNavigationItemSource('image_video', 'euroitalia');
        break;
      case ContextApplicationItemCodeEnum.BACKOFFICE:
        platform = 'backoffice';
        this.setNavigationItemSource(this.route.snapshot.data.assetRequestType, this.route.snapshot.data.userType);
        break;
      case ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN:
        this.setNavigationItemSource(this.route.snapshot.data.assetRequestType, this.route.snapshot.data.userType);
        platform = 'backoffice';
        break;
    }

    const assetRequestType = this.route.snapshot.data.assetRequestType ? "/" + this.route.snapshot.data.assetRequestType : "";
    const userType = this.route.snapshot.data.userType ? "/" + this.route.snapshot.data.userType  : '/company';
    const routingAsset = this.appConfig.config.euroitaliaB2bOldAppIframeUrl + '/#/' + platform + '/request-center' + assetRequestType + userType

    this.assetRequestCenterIframeUrl = routingAsset +
      `${clientCode ? '/' + clientCode : ''}` +
      '?context_application=' + link_code_context_application +
      '&context_code=' + context_code +
      `&token=${this.appConfig.authenticationToken}`;
  }

  setNavigationItemSource(assetRequestType: string, userType: string)
  {
    assetRequestType = assetRequestType.replace(/-/g,"_");
    this.utilBreadcrumbService.updateActiveNavigationItemSource.next([`asset_request_ticket_list_${assetRequestType}_${userType}`]);
    this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle(`asset_request_ticket_list_${assetRequestType}_${userType}`);
  }

  ngOnDestroy(): void {
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

}
