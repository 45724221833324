import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DateMomentService, FormatMultiplesCurrency, SentencecasePipe, UtilColorService } from '@saep-ict/angular-core';
import * as _ from 'lodash';
import {
	StatisticsFulfilledData
} from '../../../model/statistics.model';
import { BaseChartClass } from '../chart-configurations/base-chart.service';
import { CardDashboardConfig } from '../../../widget/card-dashboard/card-dashboard.component';
import { ActivatedRoute } from '@angular/router';
import { AppUtilService } from '../../util/app-util.service';
import { MetricsDetail, StatisticsBaseSerieOrder } from '../../../model/statistics.model';
import { ChartEnum, ROUTE_URL } from '@saep-ict/angular-spin8-core';

@Injectable()
export class FulfilledTrendDetailChartConfigService extends BaseChartClass {
	metrics: MetricsDetail;
	quarterList: StatisticsFulfilledData[];
	fulfilledTrend;

	cardDashboardConfigFulfilledTrend: CardDashboardConfig = {
		title: 'chart.fulfilled_trend_detail.title',
		subtitle: 'chart.fulfilled_trend_detail.subtitle',
		helpText: 'chart.fulfilled_trend_detail.helptext',
		icon: 'shopping_cart'
	};

	constructor(
		public utilService: AppUtilService,
		public utilColorService: UtilColorService,
		public store: Store<any>,
		private translateService: TranslateService,
		private sentencecasePipe: SentencecasePipe,
		private formatMultiplesCurrency: FormatMultiplesCurrency,
		public route: ActivatedRoute,
		public dateMomentService: DateMomentService
	) {
		super(utilService, utilColorService, store, route, dateMomentService);
		this.chart.chartType = 'line';
		this.chart.options = this.optionsStyle(ChartEnum.Theme.DEFAULT);
		this.chart.colors = [
			{
				borderColor: this.colorPrimary
			}
		];
	}

	// Functions

	createDataSerie() {
		if (this.route.snapshot.routeConfig.path === ROUTE_URL.dashboardSold) {
			this.fulfilledTrend = this.utilService.findLeaf(this.statistics.data, 'fulfilled_trend');
		} else {
			this.fulfilledTrend = this.utilService.findLeaf(this.statistics.data, 'fulfilled.fulfilled_trend');
		}
		
		const serieTemp: number[] = [];
		this.fulfilledTrend.forEach((element: StatisticsBaseSerieOrder) => {
			serieTemp.push(element.value);
			this.fullLabels.push(element.date);
		});
		this.fullDataSets.push({
			label: 'value',
			data: serieTemp
		});
	}

	populateQuarterList() {
		if (this.route.snapshot.routeConfig.path === ROUTE_URL.dashboardSold) {
			this.quarterList = this.utilService.findLeaf(this.statistics.data, 'quarters');
		} else {
			this.quarterList = this.utilService.findLeaf(this.statistics.data, 'fulfilled.quarters');
		}
	}

	populateChart(quarter: string = this.quarterList[0].name) {
		this.removeData(this.chart.labels);
		this.removeData(this.chart.datasets);

		let tempSerie = [];
		let tempLabels = [];

		const period = {
			from: this.quarterList.find(item => item.name === quarter).from,
			to: this.quarterList.find(item => item.name === quarter).to
		};

		this.fullDataSets[0].data.forEach((item, index) => {
			if (this.fullLabels[index] && this.fullLabels[index] >= period.from && this.fullLabels[index] < period.to) {
				let serie = _.cloneDeep(item);
				tempSerie.push(serie);
				tempLabels.push(this.convertDateStatisticsDetailSold(this.fullLabels[index]));
			}
		});

		let fullDataSet = [];
		fullDataSet.push({
			label: 'value',
			backgroundColor: this.colorPrimary,
			data: tempSerie
		});

		this.addData(this.chart.datasets, fullDataSet);
		this.addData(this.chart.labels, tempLabels);

		this.getMetrics(quarter);
		this.chart = _.cloneDeep(this.chart);
	}

	getMetrics(quarterName: string) {
		const quarter = this.quarterList.find(item => item.name === quarterName);
		const data = [];
		this.fulfilledTrend.forEach(item => {
			if (item.date < quarter.to && item.date > quarter.from) {
				data.push(item.value);
			}
		});
		
		let metrics;
		if (this.route.snapshot.routeConfig.path === ROUTE_URL.dashboardSold) {
			metrics = this.utilService.findLeaf(this.statistics.data, 'metrics');
		} else {
			metrics = this.utilService.findLeaf(this.statistics.data, 'fulfilled.metrics');
		}
		this.metrics = this.formatMetrics(this.utilService.findLeaf(metrics, `sold.${quarterName}`));
	}

	formatMetrics(data) {
		const metrics = {
			average: +data.average < 1 ? +data.average.toFixed(2) : +data.average.toFixed(0),
			trend: data.trend.toFixed(0)
		};
		return metrics;
	}

	// Style

	tooltipsCallbacks(translateService, sentencecasePipe, formatMultiplesCurrency) {
		return {
			label: function (tooltipItem, data) {
				let label =
					translateService.instant('fulfilled.' + data.datasets[tooltipItem.datasetIndex].label) || '';

				if (label) {
					label = sentencecasePipe.transform(label);
					label += ': ';
				}
				label += formatMultiplesCurrency.transform(tooltipItem.yLabel);
				return label;
			}
		};
	}

	yAxesStyle(theme: ChartEnum.Theme) {
		return [
			{
				stacked: false,
				gridLines: {
					drawBorder: false,
					display: true,
					borderDash: [8, 6],
					color: theme === ChartEnum.Theme.DEFAULT ? this.colorBlack30 : this.colorWhite50
				},
				ticks: {
					display: true,
					// Valore minimo dell'asse
					// min: 0,
					// Scala da usare
					stepSize: 100,
					// Sets the offset of the tick labels from the axis
					padding: 8,
					// Font color for tick labels.
					fontColor: theme === ChartEnum.Theme.DEFAULT ? this.colorBlack : this.colorWhite
					// callback: this.ticksCallback()
				}
			}
		];
	}

	xAxesStyle() {
		return [
			{
				stacked: false,
				gridLines: {
					drawBorder: false,
					display: false
				},
				ticks: {
					display: false
				}
			}
		];
	}

	tooltipsStyle() {
		return {
			// se false il tooltip compare se sull'asse x
			intersect: false,
			callbacks: this.tooltipsCallbacks(
				this.translateService,
				this.sentencecasePipe,
				this.formatMultiplesCurrency
			)
		};
	}

	optionsStyle(theme: ChartEnum.Theme) {
		return {
			responsive: true,
			layout: this.layoutStyle(),
			elements: this.elementsStyle(),
			tooltips: this.tooltipsStyle(),
			hover: this.hoverStyle(),
			scales: {
				xAxes: this.xAxesStyle(),
				yAxes: this.yAxesStyle(theme)
			},
			aspectRatio: 2
		};
	}

	convertDateStatisticsDetailSold(date: number | string): string {
		return this.dateMomentService.convertMilllisStringToStringDate('' + date);
	}
}
