import { Component, Input, OnInit } from '@angular/core';

// model
import { OrderEnum, OrderPriceMap } from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'order-detail-price',
	templateUrl: './order-detail-price.component.html',
	styleUrls: ['./order-detail-price.component.scss']
})
export class OrderDetailPriceComponent implements OnInit {
	@Input() orderPriceMap: OrderPriceMap;

	objectConstructor = Object;

	orderEnum = OrderEnum;

	constructor() {}

	ngOnInit(): void {}
}
