import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { IPageChangeEvent } from '@covalent/core/paging';
import { ITdDataTableSortChangeEvent, TdDataTableComponent, TdDataTableSortingOrder } from '@covalent/core/data-table';
// store
import { Store } from '@ngrx/store';
import { BaseStateModel, MediaReplayService, SubscribeManagerService } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { BackofficeUserFilterModel } from '../../../service/pouch-db/filter/backoffice-user-filter.model';
// model
import { UserManagementListColumnService } from '../../../service/td-data-table/implementation/backoffice/user-management-list.service';
import { PermissionUtilService } from '../../../service/util/permission-util.service';

// constant
import { ConfigurationCustomer } from '../../../constants/configuration-customer';

// widet & utility
import { AngularCoreUtilService } from '@saep-ict/angular-core';
import { StateFeature } from '../../../state';
import { UserDetailModel } from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'dialog-select-organization',
	templateUrl: './dialog-select-assegnee.component.html',
	styleUrls: ['./dialog-select-assegnee.component.scss'],
	providers: [SubscribeManagerService]
})
export class DialogSelectAssegneeComponent implements OnDestroy {
	@ViewChild('dataTable', { static: false }) dataTable: TdDataTableComponent;

	userManagementState$: Observable<BaseStateModel<UserDetailModel[]>> = this.store.select(
		StateFeature.getUserManagementState
	);
	public userManagementState: BaseStateModel<UserDetailModel[], BackofficeUserFilterModel> = {
		data: [],
		dataSetting: {
			pagination: {
				page_current: 1,
				page_size: 5
			},
			appliedFilter: {
				text: null,
				context_code: null
			},
			sort: [{ ['username']: 'asc' }]
		}
	};

	form: FormGroup;
	isLoading: boolean = false;

	constructor(
		private store: Store<any>,
		public mediaReplayService: MediaReplayService,
		public dialogRef: MatDialogRef<DialogSelectAssegneeComponent>,
		public utilService: AngularCoreUtilService,
		public userManagementListColumnService: UserManagementListColumnService,
		private subscribeManagerService: SubscribeManagerService,
		public permissionUtilService: PermissionUtilService
	) {
		this.setVisibleTableColumn();
		this.loadUserList();
		this.subscribeManagerService.populate(this.subscribeUserManagementState().subscribe(), 'assignee-list');
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(ConfigurationCustomer.UserManagement.storeAction.reset());
	}

	// load
	loadUserList() {
		this.store.dispatch(ConfigurationCustomer.UserManagement.storeAction.load_list(this.userManagementState));
		this.isLoading = true;
	}

	// Subscribe
	subscribeUserManagementState() {
		return this.userManagementState$.pipe(
			skipWhile((state: BaseStateModel<UserDetailModel[], BackofficeUserFilterModel>) => !(state && state.data)),
			map((state: BaseStateModel<UserDetailModel[], BackofficeUserFilterModel>) => {
				this.userManagementState = state;
				if (this.dataTable) {
					this.dataTable.refresh();
				}
				this.isLoading = false;
			})
		);
	}

	// widget & utility
	selectAssegnee(assegnee: UserDetailModel) {
		this.dialogRef.close(assegnee);
	}

	setVisibleTableColumn() {
		this.userManagementListColumnService.columns.map(col => {
			col.hidden = ['is_active', 'context_application_list'].includes(col.name);
		});
	}

	changePageSize(size: number) {
		if (size) {
			this.userManagementState.dataSetting.pagination.page_size = size;
			this.loadUserList();
		}
	}

	paginationChange(pagination: IPageChangeEvent) {
		if (pagination) {
			this.userManagementState.dataSetting.pagination.page_current = pagination.page;
			this.loadUserList();
		}
	}

	sortChange(e: ITdDataTableSortChangeEvent) {
		const sort: { [key: string]: 'asc' | 'desc' }[] = [
			{
				[e.name]: e.order === TdDataTableSortingOrder.Ascending ? 'asc' : 'desc'
			}
		];
		this.userManagementState.dataSetting.sort = sort;
		this.loadUserList();
	}
}
