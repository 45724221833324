import { Component, OnDestroy } from '@angular/core';
import {
	BaseStateModel,
	SubscribeManagerService,
	ITdDataTableColumnCustom,
} from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { LocalListHandlerBaseModel, ArticlePouchModel, AttachedFile, ArticleDescriptionItem } from '@saep-ict/pouch_agent_models';
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../../state';
import { ArticleActionEnum } from '../../../../state/article/article.actions';
import { filter, map, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationCustomer } from '../../../../constants/configuration-customer';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Language } from '../../../../enum/language.enum';
import _ from 'lodash';
import { ArticleDescriptionStateAction } from '../../../../state/article-description/article-description.actions';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DialogArticleSelectComponent } from '../../../../widget/dialog/dialog-article-select/dialog-article-select.component';
import { UtilDownload, DialogConfirmComponent, SentencecasePipe } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
	PATH_URL,
	ROUTE_URL,
	UserDetailModel,
	AngularSpin8CoreUtilTranslateService,
	AngularSpin8CoreUtilArticleService
} from '@saep-ict/angular-spin8-core';
import { UtilBreadcrumbService } from '../../../../service/util/util-breadcrumb.service';

@Component({
	selector: 'article-detail',
	templateUrl: './article-detail.component.html',
	styleUrls: ['./article-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class ArticleDetailComponent implements OnDestroy {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);

	article: ArticlePouchModel;

	relatedArticleListData = <LocalListHandlerBaseModel<ArticleDescriptionItem>>{
		filters: {
			localSearchText: {
				value: null,
				key_list: [
					ConfigurationCustomer.AppStructure.Erp.has_erp ? 'code_erp' : 'code_item'
				]
			}
		},
		columnList: ConfigurationCustomer.Article.backofficeArticleDetailColumnList,
		sort: {
			name: ConfigurationCustomer.AppStructure.Erp.has_erp ? 'code_erp' : 'code_item',
			order: 'ASC'
		},
		data: []
	};

	imageListColumnList: ITdDataTableColumnCustom[];
	configurationCustomer = ConfigurationCustomer;

	form: FormGroup;

	language = Language;

	refreshArticleImage: boolean = true;
	isStockHandled: boolean;
	backPath: string;

	constructor(
		private store: Store,
		private subscribeManagerService: SubscribeManagerService,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		private route: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private dialog: MatDialog,
		private articleService: AngularSpin8CoreUtilArticleService,
		private sentencecasePipe: SentencecasePipe,
		private translateService: TranslateService,
		private snackBar: MatSnackBar,
		private utilBreadcrumbService: UtilBreadcrumbService
	) {
		this.relatedArticleListData.languageKey = this.translateService.currentLang;
		this.user$
			.pipe(
				filter(res => !!(res && res.data)),
				take(1)
			)
			.subscribe(res => {
				this.user = res ? res.data : null;
			});
		this.imageListColumnList = ConfigurationCustomer.AttachedFile.columnList(
			this.user.current_permission.context_application
		);

		this.subscribeManagerService.populate(this.subscribeArticleList().subscribe(), 'subscribe-article-list');
		// utilBreadcrumbService | subtititle tofix
		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('catalogue');
		this.utilBreadcrumbService.subtitle.value =
			this.utilTranslateService.getTranslationFromLanguage(this.article.articleDescription.language_list)
				.description || this.translateService.instant('article.new');

		this.backPath =  this.router.createUrlTree(['../'], { relativeTo: this.route }).toString();

		this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['catalogue','catalogue_articles']);
	}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	/**
	 *  Subscription
	 */

	subscribeArticleList() {
		return this.articleList$.pipe(
			filter((state: BaseStateModel<ArticlePouchModel[]>) => !!(state && state.data)),
			map(async (state: BaseStateModel<ArticlePouchModel[]>) => {
				switch (state.type) {
					case ArticleActionEnum.UPDATE:
						this.article = state.data.find(
							article => article.code_item === this.route.snapshot.paramMap.get('articleId')
						);
						if (this.article) {
							this.createForm();
						} else {
							this.snackBar.open(
								this.sentencecasePipe.transform(
									this.translateService.instant('article.error.not_found')
								),
								this.translateService.instant('general.close').toUpperCase(),
								{
									duration: 3000
								}
							);
							this.router.navigate([PATH_URL.PRIVATE, ROUTE_URL.catalog, ROUTE_URL.articles]);
						}
						break;
				}
				return state;
			})
		);
	}

	/**
	 *  Form
	 */

	createForm() {
		this.isStockHandled =
			this.article &&
			this.article.articleDescription &&
			this.article.articleDescription.stock &&
			'is_available' in this.article.articleDescription.stock ?
			true :
			false;
		const languageList = [];
		for (let language of this.utilTranslateService.languages) {
			const inLanguageDescription = this.utilTranslateService.getTranslationFromLanguage(
				this.article.articleDescription.language_list,
				language,
				true
			);
			const languageImageList = [];
			if (inLanguageDescription && inLanguageDescription.image_list && inLanguageDescription.image_list.length) {
				for (let image of inLanguageDescription.image_list) {
					languageImageList.push(
						this.fb.group({
							id: this.fb.control(image.id ? image.id : null),
							name: this.fb.control(image.name ? image.name : ''),
							alt: this.fb.control(image.alt ? image.alt : '', [Validators.required]),
							data: this.fb.control(image.data ? image.data : null),
							bucket_link: this.fb.control(image.bucket_link || ''),
							is_main_of_list: this.fb.control(image.is_main_of_list ? image.is_main_of_list : false)
						})
					);
				}
			}
			languageList.push(
				this.fb.group({
					language: [language, [Validators.required]],
					description: [
						inLanguageDescription ? inLanguageDescription.description : null,
						language == 'it' ? [Validators.required] : null
					],
					description_add: [inLanguageDescription ? inLanguageDescription.description_add : null],
					technical_info: inLanguageDescription ? inLanguageDescription.technical_info : null,
					description_extended: inLanguageDescription ? inLanguageDescription.description_extended : null,
					seo: null,
					image_list: this.fb.array(languageImageList)
				})
			);
		}

		this.form = this.fb.group({
			size: this.article && this.article.articleDescription.size ? this.article.articleDescription.size : null,
			stock: this.fb.group({
				is_available: [{
					value: this.isStockHandled ? this.article.articleDescription.stock.is_available : true,
					disabled: this.isStockHandled ? false : true
				}]
			}),
			visible: {
				value: this.article && this.article.valid ? !!this.article.articleDescription.visible : false,
				disabled: !this.article?.valid
			},
			is_highlighted: this.article ? this.article.articleDescription.is_highlighted : null,
			related_article_list: [this.article.articleDescription.related_article_list] || new Array([]),
			language_list: this.fb.array(languageList)
		});

		if (this.article.articleDescription.related_article_list) {
			this.relatedArticleListData.data = this.articleService.getArticleDescriptionList(
				this.article.articleDescription.related_article_list
			);
			this.relatedArticleListData = _.cloneDeep(this.relatedArticleListData);
		}
	}

	returnFormFieldByName(formFieldName: string) {
		return this.form.get(formFieldName) as FormArray;
	}

	returnFormFieldLanguageImageListByIndex(index) {
		return this.returnFormFieldByName('language_list').controls[index].get('image_list') as FormArray;
	}

	dialogRelatedArticleAdd() {
		const relatedArticleCodeList = this.article.articleDescription.related_article_list || [];
		const dialogRef: MatDialogRef<DialogArticleSelectComponent> = this.dialog.open(DialogArticleSelectComponent, {
			data: { relatedArticleCodeListToFilter: [...relatedArticleCodeList, this.article.code_item] },
			disableClose: true,
			panelClass: ['dialog-medium', 'angelo-theme-dialog']
		});
		dialogRef.afterClosed().subscribe(articleCodeList => {
			if (articleCodeList) {
				if (!this.article.articleDescription.related_article_list) {
					this.article.articleDescription.related_article_list = [];
				}
				this.article.articleDescription.related_article_list.push(...articleCodeList);
				this.form.controls.related_article_list.setValue(this.article.articleDescription.related_article_list);

				this.relatedArticleListData.data = this.articleService.getArticleDescriptionList(
					this.article.articleDescription.related_article_list
				);
				this.relatedArticleListData = _.cloneDeep(this.relatedArticleListData);
			}
		});
	}

	dialogRelatedArticleDelete(articleToDelete: ArticleDescriptionItem) {
		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: this.sentencecasePipe.transform(this.translateService.instant('general.remove')),
				text: this.sentencecasePipe.transform(
					this.translateService.instant('article.confirm_remove', {
						description: articleToDelete.language_list
							? this.utilTranslateService.getTranslationFromLanguage(articleToDelete.language_list)
									.description
							: this.translateService.instant('general.unknown'),
						code: ConfigurationCustomer.AppStructure.Erp.has_erp
							? articleToDelete.code_erp
							: articleToDelete.code_item
					})
				)
			},
			disableClose: true,
			panelClass: 'dialog-medium'
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				const removeIndex = this.article.articleDescription.related_article_list.indexOf(
					articleToDelete.code_item
				);
				this.article.articleDescription.related_article_list.splice(removeIndex, 1);
				this.form.controls.related_article_list.setValue(this.article.articleDescription.related_article_list);

				this.relatedArticleListData.data = this.articleService.getArticleDescriptionList(
					this.article.articleDescription.related_article_list
				);
				this.relatedArticleListData = _.cloneDeep(this.relatedArticleListData);
			}
		});
	}

	downloadImage(image: AttachedFile) {
		if (UtilDownload.isPopupBlockerActive()) {
			this.snackBar.open(
				this.sentencecasePipe.transform(
					this.translateService.instant('saep_ict_angular_core.util.download.popup_blocker_off')
				),
				this.sentencecasePipe.transform(this.translateService.instant('general.close')),
				{
					duration: 3000
				}
			);
		} else {
			if (image.bucket_link) {
				window.open(image.bucket_link, '_blank').focus();
			} else {
				this.snackBar.open(
					this.sentencecasePipe.transform(this.translateService.instant('general.image.invalid_url')),
					this.sentencecasePipe.transform(this.translateService.instant('general.close')),
					{
						duration: 3000
					}
				);
			}
		}
	}

	deleteImage(language: string, imageToDeleteIndex: number) {
		const imageList = <FormArray>(
			this.returnFormFieldByName(
				`language_list.${this.returnFormFieldByName('language_list').value.findIndex(
					lang => lang.language === language
				)}.image_list`
			)
		);
		imageList.removeAt(imageToDeleteIndex);
	}

	addImageRow(language: string) {
		const languageDescription: FormGroup = (<FormGroup[]>this.returnFormFieldByName('language_list').controls).find(
			lang => (<string>lang.controls.language.value).toLowerCase() === language.toLowerCase()
		);
		if (languageDescription) {
			(<FormArray>languageDescription.controls.image_list).push(
				this.fb.group({
					name: this.fb.control(''),
					alt: this.fb.control('', [Validators.required]),
					data: this.fb.control(null, [Validators.required]),
					is_main_of_list: this.fb.control(false)
				})
			);
		}
	}

	addImage(image: AttachedFile, language: string, imageIndex: number) {
		const imageToUpdateControl = <FormGroup>(
			this.returnFormFieldByName(
				`language_list.${this.returnFormFieldByName('language_list').value.findIndex(
					lang => lang.language === language
				)}.image_list`
			).controls[imageIndex]
		);
		imageToUpdateControl.controls.data.setValue(image[0].data);
		imageToUpdateControl.controls.name.setValue(image[0].name);
	}

	checkUniqueImageMainOfList(language: string, updatedImageIsMainOfList: boolean, selectedImageIndex: number) {
		if (!updatedImageIsMainOfList) {
			const imageList = (<FormArray>(
				(<FormGroup>(
					this.returnFormFieldByName('language_list').controls.find(lang => lang.value.language === language)
				)).controls.image_list
			)).controls;
			for (let [imageIndex, image] of imageList.entries()) {
				if (selectedImageIndex === imageIndex) {
					continue;
				}
				(<FormGroup>image).controls.is_main_of_list.setValue(false);
			}
		}
	}

	onFormSubmit() {
		let article = _.cloneDeep(this.form.value);
		article.code_item = this.article.code_item;

		article.language_list.forEach((lang, i_lang) =>
			lang.image_list.map((image, i_image) => {
				if (image.id) {
					delete image.data;
					image.bucket_link = this.article.articleDescription.language_list[i_lang].image_list[
						i_image
					].bucket_link;
				}
			})
		);
		if (!this.isStockHandled) {
			delete article.stock;
		}
		this.store.dispatch(ArticleDescriptionStateAction.save({ data: article }));
		this.router.navigate([`${PATH_URL.PRIVATE}`, `${ROUTE_URL.catalog}`, `${ROUTE_URL.articles}`]);
	}

	/**
	 *  Utils
	 */

	cancelUpdate() {
		this.router.navigate([`${PATH_URL.PRIVATE}`, `${ROUTE_URL.catalog}`, `${ROUTE_URL.articles}`]);
	}

	selectedTabChange() {
		this.refreshArticleImage = !this.refreshArticleImage;
	}

}
