import { ITdDataTableColumnCustom } from '@saep-ict/angular-core';

export namespace ConfigurationCustomerAttachedFileContextApplicationColumnMap {
	export const base: ITdDataTableColumnCustom[] = [
		{
			name: 'name',
			labelPath: 'general.name',
			label: null
		},
		{
			name: 'alt',
			labelPath: 'general.image.alt',
			label: null
		},
		{
			name: 'data',
			labelPath: 'general.image.name',
			label: null
		},
		{
			name: 'delete',
			labelPath: 'general.image.remove',
			label: null,
			width: 100
		}
	];
}
