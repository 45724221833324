<page-with-sidebar [sideBarPosition]="sideBarPositionValues.NONE">
	<ng-container pageMain>
		<mat-card class="angelo-theme autoheight">
			<top-bar
				[goBackButton]="true"
				[goBackPath]="
					ROUTE_URL.private +
					'/' +
					ROUTE_URL.news
				"
				class="mb-20"
			>
				<ng-container left-side-content><span class="title">{{ 'news.body' | translate | sentencecase }}</span></ng-container>
			</top-bar>
			<form [formGroup]="form" class="angelo-theme">
				<mat-toolbar>
					<mat-toolbar-row>
						<span class="published">
							<mat-checkbox formControlName="published">
								{{ 'news.published' | translate | sentencecase }}
							</mat-checkbox>
						</span>
						<!-- <p class="title">{{ 'news.body' | translate | sentencecase }}</p> -->
						<span class="flex-span"></span>
						<button mat-icon-button (click)="closablePanelPublishWarn.isVisible = true">
							<mat-icon> help_outline </mat-icon>
						</button>
					</mat-toolbar-row>
				</mat-toolbar>
				<closable-panel #closablePanelPublishWarn>
					<ng-container content>
						<span [innerHTML]="'news.publish_warn' | translate"></span>
					</ng-container>
				</closable-panel>
				<!-- <div class="row-wrapper">
					<div class="row-item">
						<mat-checkbox formControlName="published">
							{{
								(
									returnFormFieldValueByName('published') ?
									'news.published' :
									'news.not_published'
								)
								| translate
								| sentencecase
							}}
						</mat-checkbox>
					</div>
				</div> -->
				<div class="row-wrapper">
					<div class="row-item">
						<mat-form-field>
							<input
								matInput
								formControlName="subject"
								[placeholder]="'news.field.title' | translate | sentencecase"
							/>
						</mat-form-field>
					</div>
					<div class="row-item">
						<mat-form-field>
							<input
								matInput
								formControlName="date_publication"
								[placeholder]="'news.field.publish_on' | translate | sentencecase"
								[matDatepicker]="picker"
								(click)="picker.open()"
								stopPropagation
							/>
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-datepicker #picker></mat-datepicker>
						</mat-form-field>
					</div>
				</div>
				<div class="row-wrapper">
					<div class="row-item full-width">
						<mat-form-field>
							<textarea formControlName="message" matInput rows="5"></textarea>
						</mat-form-field>
					</div>
				</div>
				<mat-divider class="both-spacing"></mat-divider>
				<mat-toolbar>
					<p class="title">{{ 'news.upload_file' | translate | sentencecase }}</p>
				</mat-toolbar>
			</form>
			<attachment-list-manager
				[configuration]="attachmentListManagerConfiguration"
				(attachmentListchange)="attachmentListManagerChange($event)"
			>
			</attachment-list-manager>
			<!-- <ng-container
				*ngIf="news && news.body && news.body.attachment_list && news.body.attachment_list.length > 0"
			>
				<mat-toolbar>
					<p class="title">{{ 'attachment.name_plural' | translate | sentencecase }}</p>
				</mat-toolbar>
				<div class="attachments-wrapper">
					<div *ngFor="let attach of news.body.attachment_list" class="attachment-wrapper">
						<div class="attachment-name">
							<mat-icon [svgIcon]="'custom-attach'">attach_file</mat-icon>
							<div>{{ attach.name }}</div>
						</div>
						<div class="attachment-actions">
							<button mat-icon-button (click)="$event.stopPropagation()">
								<mat-icon class="envelop" [svgIcon]="'custom-download'">download</mat-icon>
							</button>

							<button mat-icon-button (click)="$event.stopPropagation()">
								<mat-icon class="trash" [svgIcon]="'custom-trash'">delete</mat-icon>
							</button>
						</div>
					</div>
				</div>

				<mat-divider class="both-spacing"></mat-divider>
			</ng-container> -->
			<mat-divider class="both-spacing"></mat-divider>
			<form-button-bar
				class="actions-buttons"
				[align]="'space-between'"
				[primaryButtonLabel]="'news.action.save' | translate | sentencecase"
				(primaryButtonClickEmitter)="onFormSubmit()"
				[primaryButtonIsDisabled]="!form.valid"
				[secondaryButtonLabel]="'news.action.delete' | translate | sentencecase"
				(secondaryButtonClickEmitter)="exitEditForm()"
			></form-button-bar>
		</mat-card>
	</ng-container>
</page-with-sidebar>
