import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewsModel } from '../../../../model/news.model';
import { SideBarPositionValues } from '../../../../enum/sidebar-position-values.enum';
import { filter, map, Observable } from 'rxjs';
import { AttachmentListManager, BaseState, BaseStateModel, GuidFormatterPipe, SentencecasePipe, SubscribeManagerService } from '@saep-ict/angular-core';
import { AngularSpin8CoreUtilTranslateService, PATH_URL, ROUTE_URL, UserDetailModel } from '@saep-ict/angular-spin8-core';
import { LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';
import { StateFeature } from '../../../../state';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { AttachedFile } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import { NewsDetailActionEnum, NewsDetailStateAction } from '../../../../state/news-detail/news-detail.actions';
import moment from 'moment';import { UtilBreadcrumbService } from '../../../../service/util/util-breadcrumb.service';
import { StoreUtilService } from '../../../../service/util/store-util.service';
import { SubscribeManagerItem } from '../../../../model/subscribe-manager.model';
import { ConfigurationSubscribeManager } from '../../../../constants/subscribe-manager.constant';
import { attachmentListManagerColumnListBase } from '@saep-ict/angular-core';
import { ConfigurationCustomer } from '../../../../constants/configuration-customer';
import { NewsStatusEnum } from '../../../../enum/news.enum';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'news-detail',
	templateUrl: './news-detail.component.html',
	styleUrls: ['./news-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class NewsDetailComponent implements OnInit, OnDestroy {
	news$: Observable<BaseStateModel<NewsModel>> = this.store.select(StateFeature.getNewsDetailState);
	news: NewsModel;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	subscribeList: SubscribeManagerItem[] = [
		{ key: 'news-detail', observable: this.subscribeNewsDetail() }
	];

	form: FormGroup;
	sideBarPositionValues = SideBarPositionValues;

	attachmentListManagerConfiguration: AttachmentListManager.Configuration<AttachedFile>;

	idNews: string = this.route.snapshot.paramMap.get('idNews');

	ROUTE_URL = ROUTE_URL;

	constructor(
		private fb: FormBuilder,
		private store: Store,
		private router: Router,
		private route: ActivatedRoute,
		private subscribeManagerService: SubscribeManagerService,
		private utilBreadcrumbService: UtilBreadcrumbService,
		private guidFormatterPipe: GuidFormatterPipe,
		private utilStoreService: StoreUtilService,
		private snackBar: MatSnackBar,
		private utilTranslateService: AngularSpin8CoreUtilTranslateService,
		private sentencecasePipe: SentencecasePipe
	) {
		this.loadStaticData();
		if (this.idNews !== 'create') {
			this.store.dispatch(NewsDetailStateAction.load({ data: { _id: this.idNews } }));
		} else {
			const news: NewsModel = {
				date_update: new Date().valueOf(),
				date_creation: new Date().valueOf(),
				type: 'news',
				header: {
					status: NewsStatusEnum.NEW,
					user: {
						id: this.user.id,
						username: this.user.username,
						first_name: this.user.first_name,
						last_name: this.user.last_name
					},
					published: false,
					date_publication: null
				},
				body: {
					subject: 'New Draft',
					message: null,
					attachment_list: []
				}
			}
			this.store.dispatch(NewsDetailStateAction.save(new BaseState(news)));
		};
		this.createForm();

		ConfigurationSubscribeManager.init(this.subscribeList, this.subscribeManagerService);
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.store.dispatch(NewsDetailStateAction.reset());
		this.subscribeManagerService.destroy();
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	loadStaticData() {
		this.utilStoreService.retrieveSyncState<UserDetailModel>(this.user$).subscribe(res => {
			this.user = res.data;
		});
	}

	subscribeNewsDetail() {
		return this.news$.pipe(
			filter((state: BaseStateModel<NewsModel>) => !!(state && state.type && state.data)),
			map((state: BaseStateModel<NewsModel>) => {
				switch (state.type) {
					case NewsDetailActionEnum.UPDATE:
						this.setPageData(state.data);
						break;
					case NewsDetailActionEnum.SAVE_COMPLETE:
						this.setPageData(state.data);
						const message =
							this.sentencecasePipe.transform(
								this.utilTranslateService.translate.instant('news.action.saved')
							);
						this.snackBar.open(message, 'OK', { duration: 5000 });
						break;
					case NewsDetailActionEnum.SAVE_ATTACHMENT_COMPLETE:
						this.news._rev = state.data._rev;
						break;
				}
			})
		);
	}

	setPageData(news: NewsModel) {
		this.news = news;
		this.attachmentListManagerConfiguration = {
			localListHandler: <LocalListHandlerBaseModel<AttachedFile>>{
				pagination: {
					pageSize: 10
				},
				filters: {
					localSearchText: {
						value: null,
						key_list: ['name']
					}
				},
				sort: {
					name: 'name',
					order: 'ASC'
				},
				data: news.body.attachment_list
			},
			page: {
				title: 'saep_ict_angular_core.attachment_list_manager.name_plural',
				columnList: attachmentListManagerColumnListBase,
				display: {
					paginator: false,
					sidebarFilter: true,
					textFilter: false
				}
			},
			upload: {
				multiple: true,
				fileNameRegex: /^[a-z0-9_. -]+$/i,
				fileNameCharactersAccepted: "a-z 0-9 . _ -"
			},
			privilege: {
				add: ConfigurationCustomer.Order.Attachment.canCreate[this.user.current_permission.context_application],
				delete:
					ConfigurationCustomer.Order.Attachment.canEdit[this.user.current_permission.context_application],
				download:
					ConfigurationCustomer.Order.Attachment.canDownload[this.user.current_permission.context_application]
			},
			pathUrl: 'news',
			pathQueryParam: news.code_item,
			customHeaders: {
				'context-code': this.user.current_permission.context_code.code,
				'context-application': this.user.current_permission.context_application
			}
		};
		this.attachmentListManagerConfiguration = _.cloneDeep(this.attachmentListManagerConfiguration);
		this.setFormFromRemoteData();
		if (news.code_item) {
			this.setRouteMetaInformation();
			if (this.idNews === 'create') {
				this.router.navigate([PATH_URL.PRIVATE, ROUTE_URL.news, news.code_item]);
			}
		}
	}

	createForm() {
		this.form = this.fb.group({
			published: [false],
			subject: ['', Validators.required],
			date_publication: [''],
			message: ['']
		});
	}

	setFormFromRemoteData() {
		this.form.patchValue(
			{
				published: this.news.header.published,
				date_publication:
					this.news.header.date_publication ? moment(this.news.header.date_publication) : null,
				subject: this.news.body.subject,
				message: this.news.body.message
			}
		);
	}

	onFormSubmit() {
		if (this.form.valid) {
			const news = _.cloneDeep(this.news);
			news.date_update = new Date().valueOf();
			news.date_creation = this.news.date_creation;
			news.header.published = this.form.value.published;
			news.header.date_publication = this.form.value.date_publication ? this.form.value.date_publication.valueOf() : null;
			news.body.subject = this.form.value.subject;
			news.body.message = this.form.value.message;
			news.body.attachment_list = this.news.body.attachment_list;
			this.store.dispatch(NewsDetailStateAction.save(new BaseState(news)));
		}
	}

	returnFormFieldValueByName(formFieldName: string) {
		return this.form.get(formFieldName).value;
	}

	exitEditForm() {
		this.router.navigate([PATH_URL.PRIVATE, ROUTE_URL.news]);
	}

	setRouteMetaInformation() {
		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('news');
		this.utilBreadcrumbService.subtitle.value = this.guidFormatterPipe.transform(this.news.code_item);
		this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['news']);
	}

	attachmentListManagerChange(e: AttachedFile[]) {
		this.news.body.attachment_list = e;
		this.attachmentListManagerConfiguration.localListHandler.data = e;
		this.attachmentListManagerConfiguration = _.cloneDeep(this.attachmentListManagerConfiguration);
		this.news.body.attachment_list = e;
		this.store.dispatch(NewsDetailStateAction.saveAttachment(new BaseState(this.news)));
	}
}
