import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseStateModel, SubscribeManagerService, BaseState, DataSetting } from '@saep-ict/angular-core';
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../../state';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { OpportunityStateAction } from '../../../../state/opportunity/opportunity.actions';
import { OrderStatusEnum, ArticlePouchModel, OrganizationTypeEnum } from '@saep-ict/pouch_agent_models';
import { OrderStateAction } from '../../../../state/order/order.actions';
import { OrganizationStateAction } from '../../../../state/organization/organization.actions';
import { OrderActionEnum } from '../../../../state/order/order.actions';
import { ArticleRecap } from '../../../../model/state/article-list-state.model';
import { ArticleStateAction, ArticleActionEnum } from '../../../../state/article/article.actions';
import { Location } from '@angular/common';
import { OrderStateModel } from '@saep-ict/angular-spin8-core';
import {
	ROUTE_URL,
	OrganizationStateModel,
	AngularSpin8CoreUtilOfferService,
	OpportunityPouchModel,
	OfferStateModel
} from '@saep-ict/angular-spin8-core';


import { UtilBreadcrumbService } from '../../../../service/util/util-breadcrumb.service';
import { TopBarComponent } from '../../../../widget/top-bar/top-bar.component';
import { ConfigurationCustomer } from '../../../../constants/configuration-customer';

@Component({
	selector: 'offer-detail',
	templateUrl: './offer-detail.component.html',
	styleUrls: ['./offer-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class OfferDetailComponent implements OnInit {
	public tabsToExclude: string[] = [ROUTE_URL.attachment];
	public ROUTE_URL = ROUTE_URL;
	public offerStatusEnum = OrderStatusEnum;
	private idOffer = this.activatedRoute.snapshot.paramMap.get('idOffer');
	private idOpportunity = this.activatedRoute.snapshot.paramMap.get('idOpportunity');

	// state
	offer: OfferStateModel;
	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	opportunity$: Observable<BaseStateModel<OpportunityPouchModel[]>> = this.store.select(
		StateFeature.getOpportunityState
	);
	opportunity: OpportunityPouchModel;

	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);
	backPath: string;

	constructor(
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		public activatedRoute: ActivatedRoute,
		private utilOfferService: AngularSpin8CoreUtilOfferService,
		private location: Location,
        private utilBreadcrumbService: UtilBreadcrumbService,
		private router: Router,
	) {
        this.setRouteMetaInformation();
		this.backPath =  this.router.createUrlTree(['../'], { relativeTo: this.activatedRoute }).toString();
    }

	ngOnInit() {
		this.subscribeManagerService.populate(this.subscribeMandatoryData().subscribe(), 'mandatory-data');

		// offerta nuova -> carico un default nello store
		if (this.idOffer === ROUTE_URL.newOffer) {
			const defaultOffer = new BaseState(<OrderStateModel>this.utilOfferService.defaultOffer);
			this.store.dispatch(OrderStateAction.update(defaultOffer));
		}

		// offerta esistente -> carico l'offerta del db
		if (this.idOffer !== ROUTE_URL.newOffer) {
			this.store.dispatch(OrderStateAction.load(new BaseState(this.idOffer)));
		}

		// Opportunity
		this.store.dispatch(
			OpportunityStateAction.load({
				data: { _id: this.idOpportunity }
			})
		);

		// Organization
		const organizationToLoad = new BaseState({
			_id: this.activatedRoute.snapshot.paramMap.get('idOrganization')
		});
		this.store.dispatch(OrganizationStateAction.load(organizationToLoad));
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(OrderStateAction.reset());
		this.store.dispatch(OpportunityStateAction.reset());
		this.store.dispatch(OrganizationStateAction.reset());
        this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	/**
	 * Subscription
	 */

	subscribeMandatoryData() {
		return this.organization$.pipe(
			filter(state => !!(state && state.data)),
			mergeMap(state => {
				// Organization
				this.organization = state.data;
				// TODO: sostituire con nuovo meccanismo di dispatch
				// const articleRecap = <ArticleRecap>{
				// 	_id: `article_recap${ConfigurationCustomer.AppStructure.noSqlDocSeparator}${this.activatedRoute.snapshot.paramMap
				// 		.get('idOrganization')
				// 		.replace('organization' + ConfigurationCustomer.AppStructure.noSqlDocSeparator, '')}`
				// };
				// const dataSetting: DataSetting<OrganizationTypeEnum> = {
				// 	appliedFilter: this.organization.organization_type
				// };
				// this.store.dispatch(ArticleStateAction.loadFromRecap(new BaseState(articleRecap, dataSetting)));
				return this.articleList$;
			}),
			filter(state => !!(state && state.data)),
			mergeMap((articleList: BaseStateModel<ArticlePouchModel[]>) => {
				// articleList
				if (articleList.type === ArticleActionEnum.ERROR) {
					throw new Error(ArticleActionEnum.ERROR);
				}
				return this.order$;
			}),
			filter(state => !!(state && state.data)),
			mergeMap(state => {
				// Offer
				if (state.type === OrderActionEnum.UPDATE) {
					this.offer = <OfferStateModel>state.data;
                    this.setRouteMetaInformation();
					if (this.offer._id) {
						this.tabsToExclude = [];
					}

					// populate code item
					if (!this.offer.code_item && this.offer._id) {
						this.offer.code_item = this.offer._id.replace('order' + ConfigurationCustomer.AppStructure.noSqlDocSeparator, '');
					}
				}
				return this.opportunity$;
			}),
			filter(state => !!(state && state.data)),
			map(state => {
				// Opportunity
				this.opportunity = state.data[0];
				return true;
			})
		);
	}

	goBack() {
		this.location.back();
	}

    setRouteMetaInformation() {
        this.utilBreadcrumbService.title.value =
			this.offer && this.offer.header && this.offer.header.custom_field
			&& this.offer.header.custom_field.title ?
			this.offer.header.custom_field.title
			: 'offer.new';
    }
}
