import { MatDatepickerFilterCustom } from "@saep-ict/angular-core";

// TODO: sostituire il namespace con un alogica che possa ereditare le informazioni dell'omonimo
// frontend/src/app/constants/order/order.constants.ts ed eventualmente sovrascriverle
// il seguente è un fix temporaneo per estrapolare le due variabili di cui sotto dal file index
// frontend/src/app/constants/configuration-customer/index.ts
export namespace ConfigurationOrderExtend {
	export namespace UtilDate {
		export const firstEvasionDateMinDate: number = 14;
		export const firstEvasionDateMaxDate: number = 90;
		export const customerOrderRefDateMaxDate: number = 30;
		export const matDatepickerFilter = {
			customer_date: MatDatepickerFilterCustom.excludeWeekend,
			due_date: MatDatepickerFilterCustom.excludeWeekend
		};
	}
}
