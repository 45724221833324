<page-with-sidebar [isRoot]="true" [sideBarPosition]="sideBarPositionValues.BOTTOM">
	<ng-container pageMain>
		<mat-card *ngIf="permissionUtilService.userHasPermission(user, permissionKeyEnum.order)" class="angelo-theme autoheight">
			<mat-toolbar>
				<mat-toolbar-row>
					{{ 'order.summary.label' | translate | sentencecase }}
					<span class="flex-span"></span>
					<mat-select
						[compareWith]="dashboardConfiguration.compareValueOrderTrendTimeRange"
						[value]="configurationCustomer.Statistic.timeRangeSelector[0]"
						(selectionChange)="updateBoxTrendInfoOrderContent($event)"
					>
						<mat-option
							*ngFor="let item of configurationCustomer.Statistic.timeRangeSelector"
							[value]="item"
						>
							{{ item.label | translate | sentencecase }}
						</mat-option>
					</mat-select>

					<button mat-icon-button (click)="orderSummaryClosablePanel.isVisible = true">
						<mat-icon> help_outline </mat-icon>
					</button>
				</mat-toolbar-row>
				<mat-toolbar-row>
					<mat-card-subtitle>
						{{ 'general.updated.at' | translate | sentencecase }}
						{{ updatedAtGeneral }}
					</mat-card-subtitle>
				</mat-toolbar-row>
			</mat-toolbar>

			<closable-panel #orderSummaryClosablePanel>
				<ng-container content>
					{{ 'order.summary.info_panel' | translate | sentencecase }}
				</ng-container>
			</closable-panel>

			<box-trend-info [boxTrendInfoComponentConfig]="boxTrendInfoOrder"></box-trend-info>

			<mat-divider class="both-spacing"></mat-divider>

			<order-list-wrapper
				[title]="'chart.recent_orders.title'"
				[localListHandlerWidgetWrapperData]="listPageBaseLastOrders"
				[localListHandlerWidgetWrapperColumnList]="listPageBaseLastOrders.columnList"
				[localListHandlerPaginatorShow]="true"
				(onItemSelect)="goToOrderDetail($event)"
				[localListHandlerWidgetWrapperViewAllLink]="true"
				(selectCompany)="utilCompanyService.navigateToCompanyDetail($event.level, $event.code)"
			>
			</order-list-wrapper>
		</mat-card>

		<!-- <mat-card *ngIf="userHasPermission(permissionKeyEnum)" class="angelo-theme autoheight mt-20 mt-30 mb-20 mb-30">
			<box-trend-info [boxTrendInfoComponentConfig]="trendBoxContent"></box-trend-info>
		</mat-card> -->
	</ng-container>
	<ng-container *ngIf="permissionUtilService.userHasPermission(user, permissionKeyEnum.news)" pageSide>
		<sidebar-content-wrapper [title]="'news.label' | translate | sentencecase">
			<ng-container main-content>
				<sidebar-content-standard
					[sidebarContentStandardComponentConfig]="sidebarContentNews"
				></sidebar-content-standard>
				<button
					mat-stroked-button
					type="button"
					class="full-width"
					[routerLink]="[PATH_URL.PRIVATE, ROUTE_URL.news, ROUTE_URL.allNews]"
				>
					{{ 'general.view_all' | translate | sentencecase }}
				</button>
			</ng-container>
		</sidebar-content-wrapper>
	</ng-container>
</page-with-sidebar>
