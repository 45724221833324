import { DatePipe, TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';

// model
import {
	BodyTablePouchModel,
	CausalHeaderSoPouchModel,
	DestinationPouchModel,
	OrderPouchModel,
	PaymentPouchModel,
	OrderStatusEnum,
	DivisionPouchModel,
	OrganizationPouchModel,
	ArticleDescriptionItem,
	ArticlePouchModel,
	AddressPouchModel,
	OrganizationTypeEnum,
	DiscountTypeEnum,
	CommercialAreaPouchModel,
	TableListEnum
} from '@saep-ict/pouch_agent_models';
import {
	BaseStateModel,
	BaseState,
	GuidFormatterPipe,
	SentencecasePipe,
	DataSetting,
	DialogConfirmComponent,
	FormControlMultipurposeModel,
	FormControlMultipurposeEnum
} from '@saep-ict/angular-core';
import {
	ArticleChangeContextModel,
	ArticleChangeContextResponseModel,
	OrderHeaderObjectBodyTablePouchModel,
	UpdateOrder$
} from '../../model/order-util.model';
import { ArticleRecap, DiscountStateModel } from '../../model/state/article-list-state.model';
import { TableOrderModel } from '../../model/table/table-order.model';
import { ConfigurationViewModel } from '../../model/configuration.model';

// constant
import { ConfigurationCustomer } from '../../constants/configuration-customer';

// store
import { Store } from '@ngrx/store';
import { StateFeature } from '../../state';
import { OrderStateAction } from '../../state/order/order.actions';

// misc
import _ from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppUtilService } from './app-util.service';
import { UtilPriceService } from './util-price.service';
import { MeasureFormatter } from '../../shared/misc/measure-formatter';
import { DialogDestinationDetailComponent } from '../../widget/dialog/dialog-destination-detail/dialog-destination-detail.component';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { LocalStorageService } from 'ngx-webstorage';
import { UtilAddressService } from './util-address.service';
import {
	CausalHeaderOrderEnum,
	ContextApplicationItemCodeEnum,
	DiscrepancyOrderEnum,
	UserDetailModel,
	FormControlMap,
	AuxiliaryTableStateModel,
	OrderStateModel,
	ExtraFieldOrderHeaderPouchModel,
	OrderRowModel,
	AngularSpin8CoreUtilTranslateService,
	PATH_URL,
	ROUTE_URL
} from '@saep-ict/angular-spin8-core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderCloneTypeEnum } from '../../enum/order.enum';
import { StoreUtilService } from './store-util.service';
import { StateRelatedLink } from '../../constants/configuration-customer/order/status-aggregation-map.constant';
import { TranslateService } from '@ngx-translate/core';
import { DialogActionsComponent } from '../../widget/dialog/dialog-actions/dialog-actions.component';
import { MatSnackBarWrapperComponent } from '../../widget/mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import { CalcSheetFile, CalcSheetItem } from '../../model/calc-sheet.model';
import * as calcSheet from '../../constants/calc-sheet.constants';
import * as util from '../../constants/util.constants';
import { ExtendedArticleDescription, CurrencyPouchModel } from '@saep-ict/pouch_agent_models';

@Injectable({
	providedIn: 'root'
})
export class UtilOrderService {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);
	auxiliaryTable: AuxiliaryTableStateModel;

	configurationTable$: Observable<BaseStateModel<ConfigurationViewModel>> = this.store.select(
		StateFeature.getConfigurationState
	);
	configurationTable: ConfigurationViewModel;

	private _agreedPaymentDefault: DivisionPouchModel[] = [
		// {
		// 	payment_condition: 'E0220'
		// },
		// {
		// 	payment_condition: 'E0233'
		// },
		// {
		// 	payment_condition: 'E0235'
		// }
	];

	formHeaderValiditySource = new BehaviorSubject<boolean>(false);
	currentFormHeaderValidity = this.formHeaderValiditySource.asObservable();

	propagationSubmitForm$ = new BehaviorSubject<boolean>(false);
	propagationSubmitForm = this.propagationSubmitForm$.asObservable();

	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);
	articleDescription$: Observable<BaseStateModel<ArticleRecap>> = this.store.select(
		StateFeature.getArticleDescription
	);
	// TODO: riallineare i modelli in modo sia presente in article code_item
	articleDescription: any;

	orderThresholdSatisfied = {
		status: false,
		conditionList: []
	};

	orderChangeTemp: OrderStateModel;
	updateOrderSource = new Subject<UpdateOrder$>();
	updateOrder$: Observable<UpdateOrder$> = this.updateOrderSource.asObservable();

	orderHeaderObjectBodyTablePouchModelList: OrderHeaderObjectBodyTablePouchModel[];

	/**
	 * La variabile evita il passaggio presso i metodi del map di `this.categoryList$` (order-detail-catalog,
	 * order-detail-checkout), i quali determinano il totale refresh dell'alberatura di categorie e
	 * dei prodotti in essa contenuti. L'evento genera a sua volta un reset dello scroll, facendo
	 * perdere il punto di lavoro.
	 *
	 * Solo l'eliminazione di un articolo inverte la variabile, consentendo alla schermata di essere ristabilità a partire dall'ordine
	 * aggiornato, in arrivo dalla sua store UPDATE. Si veda onArticleChange().
	 *
	 * @memberof OrderDetailCheckoutComponent
	 */
	updateArticle;

	constructor(
		private utilService: AppUtilService,
		private titlecasePipe: TitleCasePipe,
		private guidFormatterPipe: GuidFormatterPipe,
		private store: Store<any>,
		private utilPriceService: UtilPriceService,
		private dialog: MatDialog,
		private localStorageService: LocalStorageService,
		private utilAddressService: UtilAddressService,
		private utilTranslateService: AngularSpin8CoreUtilTranslateService,
		private sentencecasePipe: SentencecasePipe,
		private snackBar: MatSnackBar,
		private utilStoreService: StoreUtilService,
		private sentenceCasePipe: SentencecasePipe,
		private translate: TranslateService,
		private datePipe: DatePipe
	) {
		this.loadStaticData();
		this.subscribeData();
	}

	loadStaticData() {
		this.utilStoreService.retrieveSyncState<ConfigurationViewModel>(this.configurationTable$).subscribe(res => {
			this.configurationTable = res.data;
		});
	}

	subscribeData() {
		this.user$.pipe().subscribe(res => {
			this.user = res ? res.data : null;
		});
		this.auxiliaryTable$.pipe().subscribe(res => {
			if (res && res.data) {
				this.auxiliaryTable = res.data;
				this.orderHeaderObjectBodyTablePouchModelList = [
					{
						keyCode: 'order_causal',
						keyObject: 'order_causal_object',
						auxiliaryTableList: this.auxiliaryTable.causalHeaderSoList
					},
					{
						keyCode: 'payment_code',
						keyObject: 'payment_object',
						auxiliaryTableList: this.auxiliaryTable.paymentList
					},
					{
						keyCode: 'shipping_method_code',
						keyObject: 'shipping_method_object',
						auxiliaryTableList: this.auxiliaryTable.methodShippingList
					}
				];
			}
		});
		this.articleDescription$.pipe().subscribe(articleDescription => {
			this.articleDescription = articleDescription ? articleDescription.data : null;
		});
		this.updateOrder$.pipe(debounceTime(2000)).subscribe((e: UpdateOrder$) => {
			if (this.user) {
				// qualsiasi modifica ordine da parte di utente noto
				if (e.statusChange) {
					// modifica al solo order.header.status (invio, autorizzazione backoffice)
					if (
						ConfigurationCustomer.Order.canUpdateToStatus[this.user.current_permission.context_application]
						.includes(e.order.header.status)
					) {
						this.orderUpdateHandler(e);
					}
				} else {
					// modifica ordine di altro genere 
					if (
						ConfigurationCustomer.Order.canEditByStatus[this.user.current_permission.context_application]
						.includes(e.order.header.status)
					) {
						this.orderUpdateHandler(e);
					}
				}
			} else {
				// qualsiasi modifica ordine da parte di utente anonimo B2C: nessun controllo privilegi
				this.orderUpdateHandler(e);
			}
		});
	}

	orderUpdateHandler(e: UpdateOrder$) {
		this.orderChangeTemp = null;
		const dataSetting: DataSetting = {};
		dataSetting.useLoader = e.useLoader;
		this.store.dispatch(OrderStateAction.save(new BaseState(_.cloneDeep(e.order), dataSetting)));
	}

	addOrderId(order: OrderStateModel): OrderStateModel {
		if (order.csuite) {
			if (order.csuite.order_number && order.csuite.order_year) {
				order.header.order_id = `${order.csuite.order_year}_${order.csuite.order_number}`;
			}
		}
		return order;
	}

	setNonFreeRowCausal(idCausal: string, causalList: CausalHeaderSoPouchModel[]) {
		const filtered = causalList.filter(i => i.code_item === idCausal)[0];
		return filtered ? filtered.code_item : '';
	}

	formatOdvCode(order: OrderStateModel): string {
		if (order?.csuite?.order_so_number && order?.csuite?.order_so_year && order?.csuite?.order_so_series) {
			return `${order.csuite.order_so_number}_${order.csuite.order_so_year}_${order.csuite.order_so_series}`;
		}
		if (order?.csuite?.order_number && order?.csuite?.order_year) {
			return `${order.csuite.order_number}_${order.csuite.order_year}`;
		}
		return '-';
	}

	filterPaymentDivisionList(
		customerDivision: DivisionPouchModel[],
		paymentList: PaymentPouchModel[]
	): PaymentPouchModel[] {
		const paymentDivision: string[] = customerDivision.map((div: DivisionPouchModel) => div.payment_condition);
		return paymentList.filter(payment => {
			return paymentDivision.includes(payment.code_item);
		});
	}

	addArticleToOrder(article: ArticlePouchModel, order: OrderStateModel): OrderStateModel {
		if (article.input_quantity) {
			const productIndex = this.utilService.getElementIndex(order.product_list, 'code_item', article.code_item);
			const articleReturn: ArticlePouchModel = this.returnArticleForOrderSave(article);
			if (productIndex || productIndex === 0) {
				order.product_list[productIndex] = articleReturn;
			} else {
				order.product_list.push(articleReturn);
			}
		}
		return order;
	}

	returnArticleForOrderSave(article: ArticlePouchModel): ArticlePouchModel {
		const articleReturn: ArticlePouchModel = {
			code_erp: article.code_erp,
			code_item: article.code_item,
			description: article.description,
			discount_agent: article.discount_agent,
			discount: article.articlePrice.discount,
			note_order: article.note_order,
			ordered_quantity: article.input_quantity,
			price: article.articlePrice.price,
			qty_free: article.qty_free > article.input_quantity ? article.input_quantity : article.qty_free,
			vat: article.articlePrice.vat,
			// TODO: ripristinare la selezione di queste voci in maniera dinamica, deprecata tempo addietro qui sotto..
			articleDescription: {
				weight: article.articleDescription.weight ? article.articleDescription.weight : 0,
				category_list: null,
				is_highlighted: null,
				language_list: null,
				related_article_list: null,
				related_kit_list: null
			}
		};
		articleReturn.articleDescription = this.utilService.deleteEmptyProperties(articleReturn.articleDescription);
		// vengono aggiunte all'articolo salvato le proprietà utili al funzionamento dell'order detail in modalità
		// 'screenshot'
		// for (let i = 0; i < ConfigurationArticle.RecapDescription.propertyToBypass.length; i++) {
		// 	articleReturn.articleDescription[ConfigurationArticle.RecapDescription.propertyToBypass[i]] =
		// 		article.articleDescription[ConfigurationArticle.RecapDescription.propertyToBypass[i]];
		// }
		return this.utilService.deleteEmptyProperties(articleReturn);
	}

	removeProductFromOrder(code: string, order: OrderStateModel): OrderStateModel {
		const productIndex = this.utilService.getElementIndex(order.product_list, 'code_item', code);
		if (productIndex || productIndex === 0) {
			order.product_list.splice(productIndex, 1);
		}
		return order;
	}

	get agreedPaymentDefault() {
		return this._agreedPaymentDefault;
	}

	/**
	 * Il metodo restituisce una lista articoli aggiornata con le informazioni recuperate dagli eventuali articoli presenti nell'ordine
	 *
	 * @param {ArticlePouchModel[]} articleList
	 * @param {OrderPouchModel<ExtraFieldOrderHeaderPouchModel>} order
	 * @returns {ArticlePouchModel[]}
	 * @memberof UtilOrderService
	 */
	mergeProductDetailInArticle(
		articleList: ArticlePouchModel[],
		order: OrderPouchModel<ExtraFieldOrderHeaderPouchModel>
	): ArticlePouchModel[] {
		articleList = _.cloneDeep(articleList);
		if (order.product_list && order.product_list.length) {
			for (const article of order.product_list) {
				const articleIndex: number = this.utilService.getElementIndex(
					articleList,
					'code_item',
					article.code_item
				);
				if (articleIndex || articleIndex === 0) {
					articleList[articleIndex].ordered_quantity = article.ordered_quantity;
					articleList[articleIndex].discount_agent = article.discount_agent;
					articleList[articleIndex].qty_free = article.qty_free;
					articleList[articleIndex].note_order = article.note_order;
					articleList[articleIndex].input_quantity = article.ordered_quantity;
					articleList[articleIndex].calculate_price = this.utilPriceService.retrieveTotalArticlePrice(
						articleList[articleIndex]
					);
				} else {
					const articleRelatedTester: ArticlePouchModel = articleList.find(
						i =>
							i.articleDescription &&
							i.articleDescription.relatedArticleTester &&
							i.articleDescription.relatedArticleTester.code_item &&
							i.articleDescription.relatedArticleTester.code_item === article.code_item
					);
					if (articleRelatedTester) {
						articleRelatedTester.articleDescription.relatedArticleTester.ordered_quantity =
							article.ordered_quantity;
						articleRelatedTester.articleDescription.relatedArticleTester.input_quantity =
							article.ordered_quantity;
					}
				}
			}
		}
		return articleList;
	}

	/**
	 * Metodo master per gestire le modifiche dei campi dell'articolo con i quali l'utente può interagire.
	 * Determina la modifica del corrispettivo elemento presente nell'ordine, senza effetti diretti sulle eventuali
	 * liste di articoli esterne coinvolte (order-detail-catalog)
	 *
	 * @param {OrderRowModel} data
	 * @param {OrderStateModel} order
	 * @param {AuxiliaryTableStateModel} auxiliaryTable
	 * @param {OrganizationPouchModel} organization
	 * @memberof UtilOrderService
	 */
	async changeOrderArticle(
		data: OrderRowModel,
		order: OrderStateModel,
		organization: OrganizationPouchModel,
		useLoader: boolean = true
	) {
		this.orderChangeTemp = this.orderChangeTemp ? this.orderChangeTemp : order;
		let inputValue: number;
		if (data.key !== 'note_order') {
			inputValue =
				data.event.target.value === '' || data.key === 'delete'
					? 0
					: parseFloat(data.event.target.value.replace(',', '.'));
			inputValue = this.returnInputMultipleOf(inputValue, data.row, organization);
		}
		const request: ArticleChangeContextModel = {
			value: inputValue,
			article: data.row,
			order: this.orderChangeTemp
		};
		const changeMethodList = {
			discount_agent: { method: () => this.changeDiscountAgent(request) },
			input_quantity: { method: () => this.changeQuantity(request) },
			last_price: { method: () => this.changeLastPrice(request) },
			delete: { method: () => this.changeQuantity(request) },
			note_order: { method: () => this.changeNote(request) },
			qty_free: { method: () => this.changeQuantityFree(request) },
			input_quantity_related_tester: { method: () => this.changeInputQuantityRelatedTester(request) }
		};
		const contextResponse: ArticleChangeContextResponseModel = changeMethodList[data.key].method();
		if (contextResponse) {
			this.updateOrderProductArticleDescription(contextResponse);
			this.updateOrderWeight(contextResponse.order);
			contextResponse.order = await this.utilPriceService.updatePriceOrder(contextResponse.order, organization);
			this.updateOrderSource.next({
				order: contextResponse.order,
				useLoader: useLoader
			});
		}
	}

	updateOrderProductArticleDescription(data: ArticleChangeContextResponseModel) {
		data.order.product_list.forEach(product => {
			if (!product.articleDescription) {
				product.articleDescription = <ArticleDescriptionItem>{};
			}
			// Conversion
			if (!product.articleDescription.conversion) {
				// TODO: senza questa condizione falliva sistematicamente l'eliminazione dell'articolo
				// da verificare il metodo in toto
				if (data.currentArticle) {
					const mainDivision = this.utilService.returnIsMainOfList<DivisionPouchModel>(
						data.currentArticle.division_list
					);
					if (mainDivision && mainDivision.hasOwnProperty('conversion')) {
						product.articleDescription.conversion = mainDivision.conversion;
					}
				}
			}
			// Weight
			if (!product.articleDescription.weight) {
				// TODO: vedi su
				if (data.currentArticle) {
					product.articleDescription.weight = data.currentArticle.articleDescription.weight || 0;
				}
			}
		});
	}

	// util legati a changeOrderArticle
	changeQuantity(acc: ArticleChangeContextModel): ArticleChangeContextResponseModel | boolean {
		let response: ArticleChangeContextResponseModel = {};
		if (acc.value === 0) {
			response.order = <OrderStateModel>this.removeProductFromOrder(acc.article.code_item, acc.order);
			return response;
		} else {
			const currentArticle: ArticlePouchModel = _.cloneDeep(acc.article);
			currentArticle.input_quantity = acc.value;
			currentArticle.price = currentArticle.articlePrice.price;
			response = {
				currentArticle: currentArticle,
				order: <OrderStateModel>this.addArticleToOrder(currentArticle, acc.order)
			};
			return response;
		}
	}

	returnArticleQuantityCanBeOrdered(
		inputQuantity: number,
		article: ArticlePouchModel,
		organization: OrganizationPouchModel
	): number {
		let quantityCanBeOrdered: number = inputQuantity;
		const availableQuantity: number = this.returnAvailableArticleQuantity(article, organization);
		if (availableQuantity !== null) {
			const quantityAlreadyOrdered: number = article.ordered_quantity || 0;
			quantityCanBeOrdered = availableQuantity - quantityAlreadyOrdered;
		}
		return quantityCanBeOrdered;
	}

	returnAvailableArticleQuantity(article: ArticlePouchModel, organization: OrganizationPouchModel): number {
		if (
			article.articleDescription.stock?.hasOwnProperty('is_available') &&
			!article.articleDescription.stock.is_available
		) {
			return 0;
		}
		if (article.articleDescription.stock?.hasOwnProperty('qty_available')) {
			let availableQuantity: number = article.articleDescription.stock.qty_available;
			// Check multiple quantity
			const multipleOfTotalQuantity: number = this.returnInputMultipleOf(
				availableQuantity,
				article,
				organization
			);
			// Multiple quantity greater than available
			if (multipleOfTotalQuantity > availableQuantity && article.articleDescription.qty_box) {
				availableQuantity = multipleOfTotalQuantity - article.articleDescription.qty_box;
			}
			return availableQuantity;
		}
	}

	articleQuantityIsAvailable(article: ArticlePouchModel, organization: OrganizationPouchModel): boolean {
		let isAvailable: boolean = true;
		if (article.articleDescription.stock) {
			const availableQuantity = this.returnAvailableArticleQuantity(article, organization);
			if (availableQuantity !== null) {
				isAvailable = availableQuantity ? true : false;
			}
			if (isAvailable) {
				// Check property: qty_available
				if (article.articleDescription.stock.qty_available && article.ordered_quantity) {
					const quantityCanBeOrdered = this.returnArticleQuantityCanBeOrdered(
						article.ordered_quantity,
						article,
						organization
					);
					isAvailable = quantityCanBeOrdered ? true : false;
				}
				// Check property: is_available (bool)
				if (article.articleDescription.stock.hasOwnProperty('is_available')) {
					isAvailable = isAvailable && (article.articleDescription.stock.is_available || false);
				}
			}
		}
		return isAvailable;
	}

	changeLastPrice(acc: ArticleChangeContextModel): ArticleChangeContextResponseModel | boolean {
		const currentArticle: ArticlePouchModel = _.cloneDeep(acc.article);
		currentArticle.price = acc.value;
		if (!currentArticle.input_quantity) {
			return false;
		}
		const response: ArticleChangeContextResponseModel = {
			currentArticle: currentArticle,
			order: <OrderStateModel>this.addArticleToOrder(currentArticle, acc.order)
		};
		return response;
	}

	changeQuantityFree(acc: ArticleChangeContextModel): ArticleChangeContextResponseModel | boolean {
		const currentArticle: ArticlePouchModel = _.cloneDeep(acc.article);
		currentArticle.qty_free = acc.value;
		const response: ArticleChangeContextResponseModel = {
			currentArticle: currentArticle,
			order: <OrderStateModel>this.addArticleToOrder(currentArticle, acc.order)
		};
		return response;
	}

	changeInputQuantityRelatedTester(acc: ArticleChangeContextModel): ArticleChangeContextResponseModel | boolean {
		let response: ArticleChangeContextResponseModel = {};
		if (acc.value === 0) {
			response.order = <OrderStateModel>(
				this.removeProductFromOrder(acc.article.articleDescription.relatedArticleTester.code_item, acc.order)
			);
			return response;
		} else {
			const currentArticle: ArticlePouchModel = _.cloneDeep(acc.article.articleDescription.relatedArticleTester);
			currentArticle.input_quantity = acc.value;
			currentArticle.price = currentArticle.articlePrice.price;
			response = {
				currentArticle: currentArticle,
				order: <OrderStateModel>this.addArticleToOrder(currentArticle, acc.order)
			};
			return response;
		}
	}

	changeDiscountAgent(acc: ArticleChangeContextModel): ArticleChangeContextResponseModel | boolean {
		const currentArticle: ArticlePouchModel = _.cloneDeep(acc.article);
		currentArticle.discount_agent = { value: acc.value, type: DiscountTypeEnum.percentage };
		const response: ArticleChangeContextResponseModel = {
			currentArticle: currentArticle,
			order: <OrderStateModel>this.addArticleToOrder(currentArticle, acc.order)
		};
		return response;
	}

	changeNote(acc: ArticleChangeContextModel): ArticleChangeContextResponseModel | boolean {
		const currentArticle: ArticlePouchModel = _.cloneDeep(acc.article);
		if (!currentArticle.input_quantity) {
			return false;
		}
		const response: ArticleChangeContextResponseModel = {
			currentArticle: currentArticle,
			order: <OrderStateModel>this.addArticleToOrder(currentArticle, acc.order)
		};
		return response;
	}
	// END - util legati a changeOrderArticle

	/**
	 *
	 * @param order This is the order
	 * @param key This is the property of the order to check in order.header.order_progress_detail
	 */
	getOrderVariationValue(order: OrderStateModel, key: string) {
		if (key && order && order.header && order.header.order_progress_detail) {
			if (order.header.order_progress_detail[key]) {
				return order.header.order_progress_detail[key];
			} else {
				return null;
			}
		}
	}

	getFormattedAddress(destination: AddressPouchModel) {
		const address = destination;
		let formattedAddress = '';
		if (address) {
			formattedAddress =
				(address.locality ? address.locality + ', ' : '-') +
				(address.zip_code ? address.zip_code + ' - ' : '') +
				(address.address ? this.titlecasePipe.transform(address.address) : '-');
		}
		return formattedAddress;
	}

	openDialogNewDestination(order: OrderStateModel, form: FormGroup): Observable<DestinationPouchModel> {
		const dialogRef = this.dialog.open(DialogDestinationDetailComponent, {
			data: {
				title: 'destination.add_new',
				oldValue: FormControlMap.CreationDefaultValue.destination.base,
				formFiledConfiguration: this.utilAddressService.setAddressProvinceOptionList(
					ConfigurationCustomer.Form.formControlMultipurpose[this.user.current_permission.context_application]
					.DESTINATION_BASE
				),
				canEdit: true
			},
			panelClass: 'dialog-normal'
		});
		return new Observable(subscriber => {
			dialogRef.afterClosed().subscribe((res: DestinationPouchModel) => {
				if (res) {
					const destination = res;
					destination.code_item = this.utilService.guid();
					order.header.goods_destination_code = destination.code_item;
					order.header.goods_destination_object = destination.address;
					form.controls['shipping_address'].disable({ emitEvent: false });
					form.patchValue({
						shipping_address: null
					});
					subscriber.next(destination);
				}
			});
		});
	}

	// TODO: definire se deprecato
	checkDiscrepancies(
		order: OrderStateModel,
		contextResponse?: ArticleChangeContextResponseModel,
		defaultPayment?: PaymentPouchModel
	) {
		if (!order.header.discrepancy_list) {
			order.header.discrepancy_list = [];
		}

		// DiscrepancyOrderEnum.DISCOUNT_TYPE
		if (order.product_list.some(pl => pl.discount_agent && !!pl.discount_agent.value)) {
			this.addDiscrepancyElement(order.header.discrepancy_list, DiscrepancyOrderEnum.DISCOUNT_TYPE);
		} else {
			this.removeDiscrepancyElement(order.header.discrepancy_list, DiscrepancyOrderEnum.DISCOUNT_TYPE);
		}

		// DiscrepancyOrderEnum.NET_PRICE_DISCOUNT
		if (
			contextResponse &&
			order.header.order_causal == CausalHeaderOrderEnum.NR &&
			order.product_list.some(pl => {
				const fifty = (contextResponse.currentArticle.articlePrice.price * 50) / 100;
				return pl.price < contextResponse.currentArticle.articlePrice.price - (fifty + (fifty * 5) / 100);
			})
		) {
			this.addDiscrepancyElement(order.header.discrepancy_list, DiscrepancyOrderEnum.NET_PRICE_DISCOUNT);
		} else {
			this.removeDiscrepancyElement(order.header.discrepancy_list, DiscrepancyOrderEnum.NET_PRICE_DISCOUNT);
		}

		// DiscrepancyOrderEnum.PAYMENT_TYPE
		if (defaultPayment && defaultPayment.code_item != order.header.payment_code) {
			this.addDiscrepancyElement(order.header.discrepancy_list, DiscrepancyOrderEnum.PAYMENT_TYPE);
		} else {
			this.removeDiscrepancyElement(order.header.discrepancy_list, DiscrepancyOrderEnum.PAYMENT_TYPE);
		}
	}

	addDiscrepancyElement(discrepancy_list: DiscrepancyOrderEnum[], discrepancyType: DiscrepancyOrderEnum) {
		if (discrepancy_list.indexOf(discrepancyType) == -1) {
			discrepancy_list.push(discrepancyType);
		}
	}

	removeDiscrepancyElement(discrepancy_list: DiscrepancyOrderEnum[], discrepancyType: DiscrepancyOrderEnum) {
		const indexToRemove = discrepancy_list.indexOf(discrepancyType);
		if (indexToRemove !== -1) {
			discrepancy_list.splice(indexToRemove, 1);
		}
	}

	/**
	 * This function returns the order list with more properties to easily show in the table
	 * @param orderList
	 */
	getTableOrderList(orderList: OrderStateModel[]): TableOrderModel[] {
		let tableOrderList: TableOrderModel[] = [];

		tableOrderList = orderList
			.filter((order: OrderStateModel) => order.header)
			.map((order: OrderStateModel) => {
				const temp: TableOrderModel = order;
				temp.id_first_row = this.getOrderFirstRow(order, order.header.status);
				temp.id_second_row = this.getOrderSecondRow(order, order.header.status);
				return temp;
			});
		return tableOrderList;
	}

	/**
	 * Utility function to isolate the logic behind id_first_row
	 */
	getOrderFirstRow(order: OrderStateModel, status: OrderStatusEnum): string {
		let firstRow: string;
		switch (status) {
			case OrderStatusEnum.CONSOLIDATED:
			case OrderStatusEnum.FULFILLED:
			case OrderStatusEnum.PARTIALLY_FULFILLED:
			case OrderStatusEnum.PROCESSING:
			case OrderStatusEnum.SENDING:
				if (order.csuite?.order_number && order.csuite?.order_year) {
					firstRow = `${order.csuite.order_number}_${order.csuite.order_year}`;
				}
				break;
		}
		return firstRow || this.guidFormatterPipe.transform(order._id);
	}

	/**
	 * Utility function to isolate the logic behind id_second_row
	 */
	getOrderSecondRow(order: OrderStateModel, status: OrderStatusEnum): string {
		let secondRow: string;
		switch (status) {
			case OrderStatusEnum.SENDING:
				secondRow = order.csuite?.order_ext_progress;
				break;
			case OrderStatusEnum.CONSOLIDATED:
			case OrderStatusEnum.FULFILLED:
			case OrderStatusEnum.PARTIALLY_FULFILLED:
				if (order.csuite?.order_so_number && order.csuite?.order_so_year) {
					secondRow = `${order.csuite.order_so_number}_${order.csuite.order_so_year}_${order.csuite.order_so_series}`;
				}
				break;
		}
		return secondRow;
	}

	setOrderNewDraft<T extends ConfigurationViewModel>(
		order: OrderStateModel,
		organization: OrganizationPouchModel,
		configuration?: T
	) {
		if (organization) {
			order.header.organization = <OrganizationPouchModel>{
				business_name: organization.business_name,
				code_erp: organization.code_erp,
				code_item: organization.code_item
			};
			const currency = this.utilService.returnIsMainOfList<CurrencyPouchModel>(organization.currency);
			order.header.currency = {
				code_item: currency.code_item,
				description_short: currency.description_short
			};
			this.setOrderHeaderDiscount(order, organization['unconditional_discount']);
			this.setOrderHeaderDestinationDefault(order, organization.destination_list);

			// Division
			const mainDivision = this.utilService.returnIsMainOfList<DivisionPouchModel>(organization.division_list);
			if (mainDivision && mainDivision.division) {
				order.header.division = mainDivision.division;
			}

			// Causal
			if (this.user.current_permission.context_application === ContextApplicationItemCodeEnum.B2C) {
				const order_causal =
					organization.organization_type === OrganizationTypeEnum.COMPANY
						? configuration.order_causal.find(i => i.code_item === ContextApplicationItemCodeEnum.B2B)
						: configuration.order_causal.find(i => i.code_item === ContextApplicationItemCodeEnum.B2C);
				order.header.order_causal = order_causal['causals_row'][0];
			} else {
				order.header.order_causal =
					mainDivision?.order_causal ||
					ConfigurationCustomer.Order.causalCode[this.user.current_permission.context_application];
			}
			order.header.payment_code = this.getOrganizationPreference(
				'payment_condition',
				this.auxiliaryTable.paymentList,
				organization
			);

			// method_shipping
			// TODO: deprecare order.header.shipping_mode
			order.header.shipping_method_code = this.getOrganizationPreference(
				'method_shipping',
				this.auxiliaryTable.methodShippingList,
				organization
			);

			this.setOrderHeaderObjectForBodyTablePouchModel(order);
			order.header.first_evasion_date =
				ConfigurationCustomer.Order.Date.minDateSelectable[this.user.current_permission.context_application].valueOf();
		}
		order.source = 'SYSTEM';
	}

	/**
	 * Applica setOrderHeaderObject() per tutti gli elementi di orderHeaderObjectBodyTablePouchModelList
	 * @param order
	 */
	setOrderHeaderObjectForBodyTablePouchModel(order: OrderStateModel) {
		for (const i of this.orderHeaderObjectBodyTablePouchModelList) {
			this.setOrderHeaderObject<CausalHeaderSoPouchModel | PaymentPouchModel>(
				i.keyCode,
				i.keyObject,
				order,
				i.auxiliaryTableList
			);
		}
	}

	setOrderHeaderObject<T extends BodyTablePouchModel>(
		keyCode: string,
		keyObject: string,
		order: OrderStateModel,
		collection: T[]
	) {
		if (order && order.header && order.header[keyCode]) {
			let orderHeaderObject: T = null;
			if (collection) {
				orderHeaderObject = collection.find(x => {
					return x.code_item === order.header[keyCode];
				});
				if (orderHeaderObject) {
					order.header[keyObject] = {
						code_item: orderHeaderObject.code_item,
						description: orderHeaderObject.description,
						description_short: orderHeaderObject.description_short
							? orderHeaderObject.description_short
							: null
					};
				}
			}
		}
	}

	setOrderHeaderDestinationDefault(order: OrderStateModel, destinationList: DestinationPouchModel[]) {
		if (destinationList?.length) {
			let defaultDestination: DestinationPouchModel =
				this.utilService.returnIsMainOfList<DestinationPouchModel>(destinationList) ||
				this.utilService.returnIsRegisteredOffice(destinationList) ||
				destinationList[0];
			order.header.goods_destination_code = defaultDestination.code_item;
			order.header.goods_destination_object = defaultDestination.address;
		}
	}

	setOrderHeaderDestinationObject(order: OrderStateModel, destinationList: DestinationPouchModel[]) {
		if (order && order.header) {
			let orderHeaderObject: DestinationPouchModel;
			if (destinationList) {
				orderHeaderObject = destinationList.find(x => {
					return x.code_item === order.header.goods_destination_code;
				});
				if (orderHeaderObject) {
					order.header.goods_destination_object = orderHeaderObject.address;
				}
			}
		}
	}

	getOrganizationPreference(
		propertyOrganizationDivision: string,
		collection: BodyTablePouchModel[],
		organization: OrganizationPouchModel
	): string {
		let result = null;
		if (organization.division_list) {
			const organizationDivision: DivisionPouchModel = organization.division_list.find(
				division =>
					division.division ===
					this.utilService.returnIsMainOfList<DivisionPouchModel>(organization.division_list).division
			);
			if (organizationDivision && organizationDivision[propertyOrganizationDivision] && collection) {
				const defaultObj = collection.find(
					item => item.code_item === organizationDivision[propertyOrganizationDivision]
				);

				if (defaultObj && defaultObj.code_item) {
					result = defaultObj.code_item;
				}
			}
		}
		return result;
	}

	setOrderHeaderDiscount(order: OrderStateModel, unconditional_discounts: number[] = []) {
		if (order?.header && unconditional_discounts) {
			unconditional_discounts.forEach(unconditional_discount => {
				order.header.discount.push(
					new DiscountStateModel({
						value: unconditional_discount,
						type: DiscountTypeEnum.percentage
					})
				);
			});
		}
	}

	/**
	 * Restiruisce una lista di ordini derivante da orderList, aventi uno degli stati passati in statusList
	 *
	 * @param {OrderStateModel[]} orderList
	 * @param {OrderStatusEnum[]} statusList
	 * @returns {OrderStateModel[]}
	 * @memberof UtilOrderService
	 */
	filterByOrderStatus(orderList: OrderStateModel[], statusList: OrderStatusEnum[]): OrderStateModel[] {
		orderList = orderList.filter(order => {
			if (order.header.status) {
				return statusList.includes(order.header.status);
			} else {
				return false;
			}
		});

		return orderList;
	}

	/**
	 * Se il prodotto non ha input_quantity valorizzato ma ha ordered_quantity valorizzato
	 * copio il valore per la casistica di articoli aggiunti da AS400
	 */
	fillInputQuantityWithOrderedQuantity(productList: ArticlePouchModel[]): ArticlePouchModel[] {
		productList.map((product: ArticlePouchModel) => {
			if (!product.input_quantity && product.ordered_quantity) {
				product.input_quantity = product.ordered_quantity;
			}
		});
		return productList;
	}

	/**
	 * Effettua il merge delle proprietà di article_recap*description
	 *
	 * @template T
	 * @param {T[]} articleList Eventuale modello di custom field che estende ArticlePouchModel
	 * @param {string[]} [propertyToBypass] Eventuale lista di proprietà da recuperare direttamente dall'article salvato nell'ordine per
	 * non perdere l'informazione nella modalità 'screenshot' (vedere frontend/src/app/constants/article.constant.ts).
	 * In mancanza del parametro il metodo mergia interamente l'elemento proveniente dal documento article_recap*description,
	 * sovrascrivendo qualsiasi riferimento precedentemente salvato nell'ordine.
	 * @returns {T[]}
	 * @memberof UtilOrderService
	 */
	mergeArticleRecapDescription<T extends ArticlePouchModel>(articleList: T[], propertyToBypass?: string[]): T[] {
		for (let i = 0; i < articleList.length; i++) {
			let articleRecapDescriptionItem: ArticleDescriptionItem = this.articleDescription.article_list.find(
				a => a.code_item === articleList[i].code_item
			);
			articleRecapDescriptionItem = articleRecapDescriptionItem
				? articleRecapDescriptionItem
				: <ArticleDescriptionItem>{};
			if (propertyToBypass) {
				for (let p = 0; p < propertyToBypass.length; p++) {
					articleRecapDescriptionItem[propertyToBypass[p]] =
						articleList[i].articleDescription[propertyToBypass[p]];
				}
			}
			articleList[i].articleDescription = articleRecapDescriptionItem;
		}
		return articleList;
	}

	returnOrderedQuantityLabel(article: ArticlePouchModel, inputQuantity?: number): string {
		inputQuantity = inputQuantity ? inputQuantity : article.input_quantity;
		let orderedQuantityLabel = '';
		if (article && article.articleDescription && inputQuantity) {
			const orderedQuantityTotal = Math.round(inputQuantity * 100) / 100;
			orderedQuantityLabel =
				'(' +
				orderedQuantityTotal +
				' ' +
				MeasureFormatter.getMeasure(
					(article.articleDescription as ExtendedArticleDescription).um_selling ||
						article.articleDescription.um_warehouse,
					orderedQuantityTotal
				) +
				')';
		}
		return orderedQuantityLabel;
	}

	/**
	 * Restituisce gli articoli filtrati per i code_item presenti in order.product_list, definendone sconti e prezzi in base
	 * all'organization o alla main division dell'articolo stesso. Da utilizzare per i soli ordini in bozza
	 *
	 * @param {OrderStateModel} order
	 * @param {ArticlePouchModel[]} articleList
	 * @param {OrganizationPouchModel} [organization]
	 * @returns {ArticlePouchModel[]}
	 * @memberof UtilOrderService
	 */
	returnFilteredAndMergedArticleListByOrderDraft(
		order: OrderStateModel,
		articleList: ArticlePouchModel[],
		organization?: OrganizationPouchModel
	): ArticlePouchModel[] {
		let articleListToProcess: ArticlePouchModel[];
		if (order && order.product_list && order.product_list.length) {
			const orderProductListCodeItem: string[] = order.product_list.map(i => i.code_item);
			articleListToProcess = _.cloneDeep(articleList.filter(i => orderProductListCodeItem.includes(i.code_item)));
			const mainDivision =
				organization && organization.division_list && organization.division_list.length > 0
					? this.utilService.returnIsMainOfList<DivisionPouchModel>(organization.division_list) ||
					  organization.division_list[0]
					: {};
			for (const article of articleListToProcess) {
				this.utilPriceService.mapArticlePrice(article, mainDivision.division || null);
			}
			articleListToProcess = this.mergeProductDetailInArticle(articleListToProcess, order);
			// Ordered quantity
			let articlesWarningMessage: string = '';
			articleListToProcess.forEach(article => {
				const availableQuantity = this.returnAvailableArticleQuantity(article, organization);
				if (availableQuantity !== null && article.ordered_quantity > availableQuantity) {
					// Article warning message
					const articleWarningMessage = this.sentencecasePipe.transform(
						this.utilTranslateService.translate.instant('article.quantity.update_from_to', {
							product: article.code_erp,
							from_qty: article.ordered_quantity,
							to_qty: availableQuantity
						})
					);
					articlesWarningMessage += `${articleWarningMessage}\n`;
					// Update quantity
					const orderProduct = order.product_list.find(p => p.code_item === article.code_item);
					orderProduct.ordered_quantity = availableQuantity;
					article.ordered_quantity = availableQuantity;
					article.input_quantity = availableQuantity;
					this.changeOrderArticle(
						{
							event: {
								target: {
									value: availableQuantity.toString()
								}
							},
							row: article,
							key: 'input_quantity'
						},
						order,
						organization
					);
				}
			});
			// Articles warning message
			if (articlesWarningMessage) {
				this.snackBar.open(articlesWarningMessage, 'OK', { duration: 5000 });
			}
		}
		return articleListToProcess;
	}

	/**
	 * Restituisce l'ordine con i soli articoli ancora presenti in article_recap**. Da utilizzare per i soli
	 * ordini in bozza
	 *
	 * @param {OrderStateModel} order
	 * @param {ArticlePouchModel[]} articleList
	 * @returns {OrderStateModel}
	 * @memberof UtilOrderService
	 */
	returnOrderDraftWithoutMissingArticle(order: OrderStateModel, articleList: ArticlePouchModel[]): OrderStateModel {
		const orderReturn: OrderStateModel = _.cloneDeep(order);
		if (order && order.product_list && order.product_list.length) {
			orderReturn.product_list = [];
			for (const orderArticle of order.product_list) {
				const articleListItem: ArticlePouchModel = articleList.find(
					i => i.code_item === orderArticle.code_item
				);
				if (articleListItem) {
					orderReturn.product_list.push(_.cloneDeep(orderArticle));
				}
			}
		}
		this.updateOrderWeight(orderReturn);
		return orderReturn;
	}

	/**
	 * Restituisce una lista articoli generata da quelli presenti in order.product_list.
	 * Da utilizzare per gli ordini in stato diverso da bozza
	 *
	 * @param {OrderStateModel} order
	 * @returns {ArticlePouchModel[]}
	 * @memberof UtilOrderService
	 */
	returnArticleListByOrderSent(order: OrderStateModel): ArticlePouchModel[] {
		let articleList: ArticlePouchModel[] = order.product_list;
		for (const article of articleList) {
			this.utilPriceService.mapOrderArticlePrice(article);
		}
		articleList = this.mergeArticleRecapDescription<ArticlePouchModel>(articleList);
		return articleList;
	}

	setStartingWarehouse(order: OrderStateModel, selectedWarehouse: string = null): OrderStateModel {
		const warehouseCode =
			selectedWarehouse ||
			this.auxiliaryTable.warehouseList.find(warehouse => warehouse.is_main_of_list).code_item;
		order.header.starting_warehouse = warehouseCode;
		return _.cloneDeep(order);
	}

	/**
	 * Il metodo rimodella l'ordine nato da un utente anonimo per adeguarlo al salvataggio presso un db organization
	 *
	 * @param {OrderStateModel} orderFromLocalStorageRef
	 * @param {OrganizationPouchModel} organization
	 * @param {AuxiliaryTableStateModel} auxiliaryTable
	 * @param {ConfigurationViewModel} configuration
	 * @param {ArticlePouchModel[]} articleList
	 * @memberof UtilOrderService
	 */
	async saveOrderNewFromLocalStorageToOrganization(
		orderFromLocalStorageRef: OrderStateModel,
		organization: OrganizationPouchModel,
		configuration: ConfigurationViewModel,
		articleList: ArticlePouchModel[]
	): Promise<void> {
		return new Promise(async resolve => {
			try {
				// rimozione prop. `_rev` che determina conflitto nella crezione di un nuovo documento gemello su db terzo
				delete orderFromLocalStorageRef._rev;
				this.setOrderNewDraft<ConfigurationViewModel>(orderFromLocalStorageRef, organization, configuration);
				// rimozione di eventuali articoli non presenti sul listino dell'organization appena autenticata
				orderFromLocalStorageRef = this.returnOrderDraftWithoutMissingArticle(orderFromLocalStorageRef, articleList);
				// aggiornamento dei dati che potrebbero divergere tra il listino anonimo e quello dell'organization
				for (let i = 0; i < orderFromLocalStorageRef.product_list.length; i++) {
					orderFromLocalStorageRef.product_list[i] = await this.updateOrderArticleByOrganizationArticleList(
						orderFromLocalStorageRef.product_list[i],
						articleList,
						orderFromLocalStorageRef.header.division
					);
				}
				this.updateOrderWeight(orderFromLocalStorageRef);
				// aggiornamento header price
				orderFromLocalStorageRef = await this.utilPriceService.updatePriceOrder(orderFromLocalStorageRef, organization);
				// rimozione della referenza all'ordine conservata nel localstorage
				this.localStorageService.clear('current_order_id');
				// salvataggio del nuovo ordine tramite flusso autenticato, che determina una nuova bozza nel db organization
				this.store.dispatch(OrderStateAction.save(new BaseState(orderFromLocalStorageRef)));
				resolve();
			} catch(err) {
				throw new Error(err);
			}
		});
	}

	/**
	 * Restituisce un articolo formattato per il salvataggio ordine, aggiornato in base alle informazioni contentute
	 * nel listino passato comeparametro `article_list`
	 *
	 * @param {ArticlePouchModel} article
	 * @param {ArticlePouchModel[]} articleList
	 * @param {string} division
	 * @returns
	 * @memberof UtilOrderService
	 */
	updateOrderArticleByOrganizationArticleList(
		article: ArticlePouchModel,
		articleList: ArticlePouchModel[],
		division: string
	): Promise<ArticlePouchModel> {
		return new Promise(resolve => {
			try {
				// recupera l'articolo sul listino tramite `code_item`
				let articleUpdater: ArticlePouchModel;
				for (const articleListItem of articleList) {
					if (articleListItem.code_item === article.code_item) {
						articleUpdater = _.cloneDeep(articleListItem);
						break;
					}
				}
				// avviene il remap della proprietà articlePrice con i nuovi prezzi e sconti
				this.utilPriceService.mapArticlePrice(articleUpdater, division);
				// reimposta `input_quantity` dell'articolo di partenza
				// articleUpdater.input_quantity = article.input_quantity;
				resolve(this.returnArticleForOrderSave(articleUpdater));
			} catch(err) {
				throw new Error(err);
			}
		});
	}

	updateOrderWeight(order) {
		const totalWeight = order.product_list.reduce(
			(cumulative, current) =>
				cumulative + (current.articleDescription.weight || 0) * (current.ordered_quantity || 1),
			0
		);
		order.header.weight = totalWeight;
	}

	// Retrieve causal description from auxiliary table
	getCausalDescription(causal: string) {
		return this.auxiliaryTable?.causalHeaderSoList?.find(table => table.code_item === causal)?.description;
	}

	/**
	 * Restituisce il multiplo di qty_box più vicino (per eccesso) all'input dell'utente.
	 * In base alle configurazioni di progetto il metodo potrebbe bypassare 1 come valore di default. Si veda:
	 * - ConfigurationCustomer.Order.qtyBoxMultipleCheck
	 *
	 * @param {*} value
	 * @param {ArticlePouchModel} article
	 * @param {OrganizationPouchModel} organization
	 * @returns {number}
	 * @memberof UtilOrderService
	 */
	returnInputMultipleOf(value, article: ArticlePouchModel, organization: OrganizationPouchModel): number {
		let coefficient: number;
		if (
			(article.articleDescription.qty_box &&
				organization &&
				ConfigurationCustomer.Order.qtyBoxMultipleCheck[organization.organization_type]) ||
			(!organization && ConfigurationCustomer.Order.qtyBoxMultipleCheck.PUBLIC)
		) {
			coefficient = article.articleDescription.qty_box;
		} else {
			coefficient = 1;
		}
		if (value > 0) {
			value = Math.ceil(value / coefficient) * coefficient;
		} else if (value < 0) {
			value = Math.floor(value / coefficient) * coefficient;
		}
		return value;
	}

	articleInputMultipleValidation(article: ArticlePouchModel, organization: OrganizationPouchModel): string {
		let message = '';
		if (
			article?.articleDescription?.qty_box > 1 &&
			((organization && ConfigurationCustomer.Order.qtyBoxMultipleCheck[organization.organization_type]) ||
				(!organization && ConfigurationCustomer.Order.qtyBoxMultipleCheck.PUBLIC))
		) {
			message = this.sentencecasePipe.transform(
				`${this.utilTranslateService.translate.instant('article.can_be_ordered_in_multiples_of')}
					${article.articleDescription.qty_box}`
			);
		}
		return message;
	}

	// metodi in possibile override su progetto cliente specifico

	// returnOrderThresholdSatisfiedStatus(): boolean {
	// 	return true;
	// }

	/**
	 * RACHELLO extends
	 */

	returnOrderThresholdSatisfiedStatus(order: OrderStateModel, commercialArea: CommercialAreaPouchModel) {
		this.orderThresholdSatisfied.conditionList = [];
		if (commercialArea && commercialArea.threshold && commercialArea.threshold.length) {
			for (const t of commercialArea.threshold) {
				switch (t.type) {
					case TableListEnum.Threshold.Type.K:
						if (t.value <= order.header.weight) {
							this.orderThresholdSatisfied.status = true;
							this.orderThresholdSatisfied.conditionList = [];
							return;
						}
						break;
					case TableListEnum.Threshold.Type.I:
						if (t.value <= order.header.price.article) {
							this.orderThresholdSatisfied.status = true;
							this.orderThresholdSatisfied.conditionList = [];
							return;
						}
						break;
					case TableListEnum.Threshold.Type.C:
						let itemNumber = 0;
						order.product_list.forEach(i => {
							itemNumber = itemNumber + i.ordered_quantity;
						});
						if (t.value <= itemNumber) {
							this.orderThresholdSatisfied.status = true;
							this.orderThresholdSatisfied.conditionList = [];
							return;
						}
						break;
				}
				this.orderThresholdSatisfied.conditionList.push({
					type: t.type,
					description: `checkout.alert.to_proceed_condition_list.${t.type}`,
					value: t.value
				});
			}
		}
		this.orderThresholdSatisfied.status = false;
	}

	mergeArticleTester(article: ArticlePouchModel, articleList: ArticlePouchModel[]) {
		if (article.articleDescription.related_tester) {
			let articleTesterIndex: number;
			const articleTester: ArticlePouchModel = articleList.find((i, index) => {
				if (article.articleDescription.related_tester === i.code_item) {
					articleTesterIndex = index;
					return true;
				}
			});
			if (articleTester) {
				article.articleDescription.relatedArticleTester = articleTester;
				articleList.splice(articleTesterIndex, 1);
			}
		}
	}

	formatOrderCatalogArticleList(
		articleList: ArticlePouchModel[],
		division: string,
		order: OrderStateModel
	): ArticlePouchModel[] {
		for (const article of articleList) {
			this.utilPriceService.mapArticlePrice(article, division);
		}
		const articleListToReturn: ArticlePouchModel[] = _.cloneDeep(articleList);
		for (const article of articleList) {
			const articleToReturnIndex: number = this.utilService.getElementIndex(
				articleListToReturn,
				'code_item',
				article.code_item
			);
			if (articleToReturnIndex) {
				this.mergeArticleTester(articleListToReturn[articleToReturnIndex], articleListToReturn);
			}
		}
		return this.mergeProductDetailInArticle(articleListToReturn, order);
	}

	cloneOrder(order: OrderStateModel, key: OrderCloneTypeEnum = OrderCloneTypeEnum.HEADER_AND_ROWS) {
		order = _.cloneDeep(order);
		delete order._id;
		delete order._rev;
		delete order.header.submission_date;
		delete order.csuite;
		order.header.status = OrderStatusEnum.DRAFT;
		order.header.date = Date.now();
		if (key === OrderCloneTypeEnum.HEADER) {
			order.product_list = [];
			delete order.header.price;
		}
		this.store.dispatch(OrderStateAction.save(new BaseState(order)));
	}

	getGoBackPath(orderStatus: OrderStatusEnum) {
		const statusRelatedMap: StateRelatedLink[] =
			ConfigurationCustomer.Order.statusAggregationMap[
				ContextApplicationItemCodeEnum[this.user.current_permission.context_application]
			];
		const stateRelatedLink = statusRelatedMap.find(i => i.related_list.includes(orderStatus));
		return stateRelatedLink
			? PATH_URL.PRIVATE + '/' + ROUTE_URL.orders + '/' + stateRelatedLink.state.toLowerCase()
			: null;
	}

	// dialog
	dialogConfirmOrderDelete(order: OrderStateModel) {
		const dialog = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: this.sentenceCasePipe.transform(this.translate.instant('order.action.delete')),
				text: this.sentenceCasePipe.transform(this.translate.instant('order.question.delete'))
			}
		});
		dialog.afterClosed().subscribe(res => {
			if (res) {
				if (order._id) {
					this.store.dispatch(OrderStateAction.remove(new BaseState(order)));
				}
			}
		});
	}

	dialogConfirmOrderClone(order: OrderStateModel) {
		const dialogRef = this.dialog.open(DialogActionsComponent, {
			data: {
				title: `${this.sentenceCasePipe.transform(this.translate.instant('general.duplicate'))}
				${this.sentenceCasePipe.transform(this.translate.instant('order.name'))}`,
				text: this.sentenceCasePipe.transform(this.translate.instant('order.dialog.clone.text')),
				actions: ConfigurationCustomer.Order.headerAndRowsOrderClone[this.user.current_permission.context_application] ? [
					{
						key: OrderCloneTypeEnum.HEADER_AND_ROWS,
						text: 'order.dialog.clone.header_and_rows_option'
					}
				]: 
				[
					{
						key: OrderCloneTypeEnum.HEADER,
						text: 'order.dialog.clone.header_option'
					},
					{
						key: OrderCloneTypeEnum.HEADER_AND_ROWS,
						text: 'order.dialog.clone.header_and_rows_option'
					}
				]	
			},
			disableClose: true,
			panelClass: ['dialog-normal','angelo-theme-dialog']
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.cloneOrder(order, res);
			}
		});
	}

	/**
	 * Aggiorna l'ordine con gli articoli forniti da `returnCalcSheetArticleList()`, mediante il metodo
	 * `changeOrderArticle()`
	 * @param file
	 * @param organization
	 * @param order
	 */
	calcSheetArticleListUpdateOrder(file: CalcSheetFile, organization: OrganizationPouchModel, order: OrderStateModel)
	: void {
		const calcSheetArticleList: CalcSheetItem[] = calcSheet.returnCalcSheetArticleList(file);
		let articleList: ArticlePouchModel[];
		this.utilStoreService.retrieveSyncState<ArticlePouchModel[]>(this.articleList$).subscribe(eArticleList => {
			articleList = eArticleList.data;
		});
		const articleAddedList: string[] = [];
		const articleNotAddedList: string[] = [];
		const division: string =
			this.utilService.returnIsMainOfList<DivisionPouchModel>(organization.division_list).division;
		for (const item of calcSheetArticleList) {
			// verifica esistenza articolo
			const article: ArticlePouchModel = articleList.find(i => i.code_erp === item.code);
			if (article && item.quantity) {
				// verifica disponibilità articolo
				const availableQuantity = this.returnAvailableArticleQuantity(article, organization);
				if (availableQuantity !== null && item.quantity > availableQuantity) {
					item.quantity = availableQuantity;
				}
				// la verifica della disponibilità potrebbe restituire 0, rendendo inutile l'aggiunta dell'articlo
				if (item.quantity > 0) {
					this.utilPriceService.mapArticlePrice(article, division);
					this.changeOrderArticle(
						{
							event: {
								target: {
									value: item.quantity.toString()
								}
							},
							key: 'input_quantity',
							row: article
						},
						order,
						organization,
						false
					);
					articleAddedList.push(item.code);
				} else {
					articleNotAddedList.push(item.code);
				}
			} else {
				articleNotAddedList.push(item.code);
			}
		}
		let message: string;
		message = this.sentencecasePipe.transform(`${this.translate.instant('article.added_to_order_or_modified')}:`);
		if (articleAddedList.length > 0) {
			message = util.returnMessageCodeUl(message, articleAddedList);
		} else {
			message = message + ' 0<br><br>'
		}
		// gestione errore per gli articoli non trovati o non disponibili
		if (articleNotAddedList.length > 0) {
			message = message + this.sentencecasePipe.transform(`${this.translate.instant('article.error.not_found_plural')}:`);
			message = util.returnMessageCodeUl(message, articleNotAddedList);
		}
		this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
			duration: null,
			data: {
				message: message,
				action:'OK'
			}
		});
	}

	// TODO: rivedere tutto il flusso dopo una gestione migliore dei fields associati ad oggetti contenenti metadati descrittivi
	returnOrderHeaderFieldListWithValue(creationFieldMap: FormControlMultipurposeModel.Item[], order: OrderStateModel)
		: FormControlMultipurposeModel.Item[] {
		creationFieldMap = _.cloneDeep(creationFieldMap);
		const orderHeaderFieldList: FormControlMultipurposeModel.Item[] = creationFieldMap.map( i => {
			switch(i.type) {
				case FormControlMultipurposeEnum.ItemType.DATE:
					i.value = this.datePipe.transform(order.header[i.name], 'dd/MM/yyyy');
					break;
				default:
				i.value = (order.header[i.name] || order.header[i.name] === false) ? order.header[i.name] : '-';
			}
			// gestione temporanea di campi specifici
			switch(i.name) {
				case 'goods_destination_code':
					i.value =
						order.header.goods_destination_code ?
						this.getFormattedAddress(order.header.goods_destination_object):
						'-';
					break;
				case 'order_causal':
					i.value = 
						order.header.order_causal_object && order.header.order_causal_object.description_short ?
						order.header.order_causal_object.description_short :
						'-';
					break;
				case 'payment_code':
					i.value =
						order.header.payment_object && order.header.payment_object.description_short ?
						order.header.payment_object.description_short :
						'-';
					break;
				case 'shipping_method_code':
					i.value =
						order.header.shipping_method_code && order.header.shipping_method_code ?
						order.header.shipping_method_object.description :
						'-';
					break;
			}
			i.value = (i.value || i.value === false) ? i.value : '-';
			return i;
		});
		return orderHeaderFieldList;
	}

	orderDraftCreate(organization: OrganizationPouchModel) {
		const order = new OrderStateModel();
		this.setOrderNewDraft(order, organization);
		this.store.dispatch(OrderStateAction.update(new BaseState(order)));
	}

}
