<top-bar *ngIf="order" [goBackButton]="true" [goBackPath]="getGoBackPath()" [actionMenu]="true">
	<ng-container left-side-content>
		<status-bar-new [config]="statusBarConfig"></status-bar-new>
	</ng-container>
	<ng-container actions-menu-buttons>
		<button
			mat-menu-item
			(click)="dialogConfirmOrderDelete()"
			*ngIf="orderStatus === orderStatusEnum.DRAFT && order && order._id"
		>
			<mat-icon>delete</mat-icon>
			<span>{{ 'general.delete' | translate | sentencecase }}</span>
		</button>
		<!-- Clone button -->
		<button
			mat-menu-item
			(click)="dialogConfirmOrderClone()"
			*ngIf="
				order &&
				order._id &&
				(user.current_permission.context_application === contextApplicationItemCodeEnum.AGENT ||
					user.current_permission.context_application === contextApplicationItemCodeEnum.B2B ||
					user.current_permission.context_application === contextApplicationItemCodeEnum.CRM)
			"
		>
			<mat-icon>file_copy</mat-icon>
			<span>{{ 'general.duplicate' | translate | sentencecase }}</span>
		</button>
		<!-- Generate PDF button -->
		<button
			mat-menu-item
			(click)="generatePDF()"
			*ngIf="
				order &&
				((order.product_list && order.product_list.length != 0) ||
					(order['free_line_list'] && order['free_line_list'].length != 0))
			"
		>
			<mat-icon>picture_as_pdf</mat-icon>
			<span>{{ 'order.action.get_pdf' | translate | sentencecase }}</span>
		</button>
	</ng-container>
</top-bar>
<!-- BACKOFFICE CONTEXT -->
<mat-toolbar
	*ngIf="
		order?.header.status === orderStatusEnum.TO_AUTHORIZE &&
		user.current_permission.context_application === contextApplicationItemCodeEnum.BACKOFFICE
	"
	class="toolbar order-validation"
>
	<img src="assets/common/img/icon/warning-sign.png" class="warning-sign" alt="Warning Sign" />
	<ng-container *ngIf="!organizationToBeValidated">
		<span class="message">
			{{ 'order.validation.message' | translate | sentencecase }}
		</span>
		<button mat-flat-button color="primary" (click)="checkOrganizationForOrderValidation()">
			{{ 'order.validation.valid' | translate | sentencecase }}
		</button>
	</ng-container>
	<ng-container *ngIf="organizationToBeValidated">
		<span class="message">
			{{ 'order.validation.valid_organization' | translate | sentencecase
			}}<a
				href="javascript:void(0)"
				(click)="
					utilCompanyService.navigateToCompanyDetail(
						organization.level,
						organization.code_item,
						user?.current_permission.context_application
					)
				"
				>{{ 'saep_ict_angular_spin8_core.page.organization.name' | translate }}</a
			>
		</span>
	</ng-container>
</mat-toolbar>
<!-- END BACKOFFICE CONTEXT -->

<div class="order-detail-content-wrapper">
	<tab-wrapper *ngIf="order?.header?.status" [toExclude]="tabsToExclude">
		<ng-container main>
			<form class="angelo-theme" [formGroup]="form">
				<div class="row mt-3">
					<!-- Modalità di consegna -->
					<!-- <div class="col-md-6">
                            <mat-form-field>
                                <mat-select placeholder="Modalità di consegna" formControlName="delivery_methods">
                                    <mat-option
                                        *ngFor="let methodDelivery of methodDeliveryList"
                                        [value]="methodDelivery.code_item"
                                    >
                                        {{ methodDelivery.description }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> -->

					<!-- Causale -->
					<div class="col-md-3 note">
						<form-field-select
							[form]="form"
							formControlName="order_causal"
							[optionList]="auxiliaryTable.causalHeaderSoList"
							[optionItemStructure]="{ value: 'code_item', label_list: ['code_item', 'description'] }"
							label="theme.order.field.causal"
							searchLabel="causal.filter"
							[explicitValue]="order.header.order_causal_object?.description"
							[propertyListNameSettings]="['description']"
							[canFilter]="true"
						>
							<span matPrefix *ngIf="utilOrderService.getOrderVariationValue(order, 'order_causal')">
								<mat-icon
									class="mat-icon variation-icon"
									matTooltipClass="allow-break-line"
									[matTooltip]="
										('order.variation.value_updated_from' | translate | sentencecase) +
										getOrderCausal(utilOrderService.getOrderVariationValue(order, 'order_causal'))
											?.description
									"
								>
									error
								</mat-icon>
							</span>
						</form-field-select>
						<!-- <mat-select formControlName="order_causal" (openedChange)="inputSearchCausal.value = ''">
                                    <mat-form-field class="filter-input">
                                        <mat-label>{{ 'causal.filter' | translate | sentencecase }}</mat-label>
                                        <input matInput type="text" #inputSearchCausal autocomplete="off" />
                                        <button
                                            mat-button
                                            *ngIf="inputSearchCausal.value"
                                            matSuffix
                                            mat-icon-button
                                            aria-label="Clear"
                                            (click)="inputSearchCausal.value = ''"
                                        >
                                            <mat-icon>close</mat-icon>
                                        </button>
                                        <mat-icon matSuffix>search</mat-icon>
                                    </mat-form-field>
                                    <mat-option
                                        *ngFor="
                                            let causal of auxiliaryTable.causalHeaderSoList
                                                | filterByString
                                                    : {
                                                            propertyListName: ['description_short'],
                                                            value: inputSearchCausal.value
                                                    }
                                        "
                                        [value]="causal.code_item"
                                    >
                                        {{ causal.description_short }}
                                    </mat-option>
                                </mat-select> -->
					</div>

					<!-- Data di consegna -->
					<div class="col-md-3">
 							<mat-form-field> 
							<mat-label>{{ 'theme.order.field.delivery_date' | translate | sentencecase }}</mat-label>
							<input
								matInput
								formControlName="first_evasion_date"
								[matDatepicker]="picker"
								[min]="configurationOrder.UtilDate.minDate"
								[max]="
									configurationOrder.UtilDate.addDays(
										configurationOrderExtend.UtilDate.firstEvasionDateMaxDate
									)
								"
								[matDatepickerFilter]="configurationOrderExtend.UtilDate.matDatepickerFilter.due_date"
								stopPropagation
								readonly
								(click)="picker.open()"
							/>
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-datepicker #picker></mat-datepicker>
							<span
								matPrefix
								*ngIf="utilOrderService.getOrderVariationValue(order, 'first_evasion_date')"
							>
								<mat-icon
									class="mat-icon variation-icon"
									matTooltipClass="allow-break-line"
									[matTooltip]="
										('order.variation.value_updated_from' | translate | sentencecase) +
										convertMilllisStringToStringDate(
											utilOrderService.getOrderVariationValue(order, 'first_evasion_date')
										)
									"
								>
									error
								</mat-icon>
							</span>
						</mat-form-field> 					
						
					</div>

					<!-- Codice cliente -->
					<div class="col-md-3">
					<mat-form-field>
						<mat-label>
							{{ 'order.field.customer_reference' | translate | sentencecase }}
						</mat-label>
						<textarea
							matInput
							formControlName="customer_order_ref_code"
							[value]="order.header.customer_order_ref_code?order.header.customer_order_ref_code:'nessun codice'"
							type="text"
						></textarea>
					</mat-form-field>
					</div>

					<!-- Data cliente -->
					<div class="col-md-3">
						<mat-form-field>
							<input
								matInput
								placeholder="{{ 'theme.order.field.customer_date' | translate | sentencecase }}"
								formControlName="customer_order_ref_date"
								[matDatepicker]="customerDatePicker"
								[max]="
									configurationOrder.UtilDate.addDays(
										configurationOrderExtend.UtilDate.customerOrderRefDateMaxDate
									)
								"
								[matDatepickerFilter]="
									configurationOrderExtend.UtilDate.matDatepickerFilter.customer_date
								"
								stopPropagation
							/>
							<mat-datepicker-toggle matSuffix [for]="customerDatePicker"></mat-datepicker-toggle>
							<mat-datepicker #customerDatePicker></mat-datepicker>
						</mat-form-field>
					</div>

					<!-- Metodi di pagamento -->
					<div class="col-md-6">
						<form-field-select
							[form]="form"
							formControlName="payment_code"
							[optionList]="auxiliaryTable.paymentList"
							[optionItemStructure]="{ value: 'code_item', label_list: ['description'] }"
							label="order.field.payment_condition"
							searchLabel="payment_method.filter"
							[explicitValue]="order.header.payment_object?.description"
							[propertyListNameSettings]="['description']"
							[canFilter]="false"
						></form-field-select>
					</div>

					<!-- Indirizzo di destinazione -->
					<div class="col-md-6">
						<ng-container>
							<form-field-select
								[form]="form"
								formControlName="goods_destination_code"
								[optionList]="destinationList"
								[optionItemStructure]="{ value: 'code_item', label_list: ['complete'] }"
								label="theme.order.field.shipping_address"
								searchLabel="destination.filter"
								[explicitValue]="
									utilOrderService.getFormattedAddress(order.header.goods_destination_object)
								"
								[propertyListNameSettings]="['zip_code', 'address', 'locality', 'country', 'province']"
								[canFilter]="true"
							>
								<span
									matPrefix
									*ngIf="utilOrderService.getOrderVariationValue(order, 'goods_destination')"
								>
									<mat-icon
										class="mat-icon variation-icon"
										matTooltipClass="allow-break-line"
										[matTooltip]="
											('order.variation.value_updated_from' | translate | sentencecase) +
											utilOrderService.getFormattedAddress(
												utilOrderService.getOrderVariationValue(order, 'goods_destination')
											)
										"
									>
										error
									</mat-icon>
								</span>
							</form-field-select>
						</ng-container>
					</div>

					<!-- Note -->
					<div class="col-md-6 note">
						<mat-form-field>
							<mat-label>{{ 'theme.order.field.note' | translate | sentencecase }}</mat-label>
							<textarea rows="1" matInput [value]="order.header.note_order" formControlName="note_order"></textarea>
						</mat-form-field>
					</div>

					<!-- Alerts -->
					<ng-container *ngIf="orderProductListNotFound">
						<div class="col-md-12" *ngFor="let product of orderProductListNotFound">
							<alert [type]="alertTypeEnum.warning">
								<p>
									<strong>Attenzione!</strong> Il prodotto con codice {{ product.code_item }} del tuo
									ordine non è stato trovato.
								</p>
							</alert>
						</div>
					</ng-container>
					<div class="col-md-12" *ngIf="setTodayDate">
						<alert [type]="alertTypeEnum.warning">
							<p>
								<strong>Attenzione!</strong> La consegna in data odierna sarà confermata al ricevimento
								dell'ordine.
							</p>
						</alert>
					</div>
					<div class="col-md-12" *ngIf="setProductPartiallyAvailable && setTodayMinusFiveDaysDate">
						<alert [type]="alertTypeEnum.warning">
							<p>
								<strong>Attenzione!</strong> Sono stati selezionati alcuni prodotti disponibili in 72
								ore - 3 giorni, la data di consegna sarà confermata al ricevimento dell'ordine.
							</p>
						</alert>
					</div>
					<div class="col-md-12" *ngIf="setProductNotAvailable">
						<alert [type]="alertTypeEnum.warning">
							<p>
								<strong>Attenzione!</strong> Sono stati selezionati alcuni prodotti disponibili su
								ordinazione, la data di consegna sarà confermata al ricevimento dell'ordine.
							</p>
						</alert>
					</div>
				</div>
			</form>
		</ng-container>
	</tab-wrapper>
</div>
