import { ListAttribute } from './../../../model/misc.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
	SubscribeManagerService, BaseStateModel,
	SentencecasePipe, DialogTextEditComponent, ITdDataTableColumnCustom
} from '@saep-ict/angular-core';
import {
	ArticleDescriptionItem,
	ArticlePouchModel,
	ArticleTableMetaInformation,
	OrderStatusEnum,
	LocalListHandlerBaseModel,
	OrganizationPouchModel
} from '@saep-ict/pouch_agent_models';
import { UtilArticleKitService } from '../../../service/util/util-article-kit.service';
import { UtilPriceService } from '../../../service/util/util-price.service';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';
import _ from 'lodash';
import {
	ArticleEnum,
	OrderRowModel,
	AngularSpin8CoreUtilTranslateService,
	UserDetailModel,
	ContextApplicationItemCodeEnum
} from '@saep-ict/angular-spin8-core';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { LangChangeEvent } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../state';
import { MatDialog } from '@angular/material/dialog';
import { DialogImageGalleryComponent } from '../../dialog/dialog-image-gallery/dialog-image-gallery.component';
import { ArticleTableConfigurationAction } from '../../../model/article.model';
import { OrderStateModel } from '@saep-ict/angular-spin8-core';
import { TicketCenterMainService } from '@saep-ict/ticket-center';
import { TicketCenterBodyCustomField } from '../../../constants/configuration-customer/ticket-center/body-custom-field.constant';
import * as ForecastModel from '../../../model/forecast.model';
import * as UtilPrice from '../../../constants/util-price.constants';
import { AppUtilService } from '../../../service/util/app-util.service';
import { CurrencyPouchModel } from '@saep-ict/pouch_agent_models';
import { json } from 'body-parser';

@Component({
	selector: 'new-article-table-wrapper',
	templateUrl: './new-article-table-wrapper.component.html',
	styleUrls: ['./new-article-table-wrapper.component.scss'],
	providers: [SubscribeManagerService]
})
export class NewArticleTableWrapperComponent implements OnInit {
	@Input('localListHandlerData') set localListHandlerData(e: LocalListHandlerBaseModel<ArticlePouchModel>) {
		if (e) {
			this._localListHandlerData = e;
			const columnWidthConfigurationClassItem: ITdDataTableColumnCustom =
				this._localListHandlerData.columnList.find(i => i.name === "column-width-configuration-class");
			if (columnWidthConfigurationClassItem) {
				this.columnWidthConfigurationClass = columnWidthConfigurationClassItem.label;
			}
			if (
				this._localListHandlerData.filters &&
				this._localListHandlerData.filters.customFilters &&
				this._localListHandlerData.filters.customFilters.forecastYear
			) {
				this.forecastConfigurationInputList =
					ConfigurationCustomer.Forecast.returnInputList(this._localListHandlerData.filters.customFilters.forecastYear);
			}
			for (const article of this._localListHandlerData.dataSubset) {
				this.articleDiscountHandler(article);
				// in questo momento il componente potrebbe ricevere anche un array di soli articleDescription
				// dunque privi a loro volta di un sotto livello articleDescription al quale fanno riferimento
				// i metodi seguenti
				if (article.articleDescription) {
					this.articleDescriptionHandler(article);
					this.inputQuantityDisableHandler(article);
					this.imageGalleryShowandler(article);
					article.calculate_price = article.calculate_price ? article.calculate_price : 0;
				}
			}
		}
	};
	_localListHandlerData: LocalListHandlerBaseModel<ArticlePouchModel, ForecastModel.LocalListHandlerFilter>;
	@Input() sortable;
	@Input() organization: OrganizationPouchModel;
	@Input() onItemSelectShow: boolean;
	@Input() localListHandlerDataGlobalSubset: number = 0;
	@Input('configurationAction') configurationAction: ArticleTableConfigurationAction;
	@Input() order: OrderStateModel;

	@Output() onItemSelect = new EventEmitter();
	@Output() sortChange = new EventEmitter();
	@Output() onArticleChange = new EventEmitter<OrderRowModel>();
	@Output() onDeleteArticle: EventEmitter<ArticlePouchModel | ArticleDescriptionItem> = new EventEmitter();
	@Output() onForecastChange: EventEmitter<ForecastModel.CodeItemUpdate> = new EventEmitter();

	orderStatusEnum = OrderStatusEnum;
	configurationCustomer = ConfigurationCustomer;
	articleEnum = ArticleEnum;
	ticketCenterBodyCustomField = TicketCenterBodyCustomField;
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	isTablet: boolean;
	columnWidthConfigurationClass: string;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	forecastConfigurationInputList: ForecastModel.ConfigurationInput[];

	public sidebarClosed = false;

	constructor(
		private subscribeManagerService: SubscribeManagerService,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		private utilPriceService: UtilPriceService,
		public utilArticleKitService: UtilArticleKitService,
		public utilService: AppUtilService,
		public utilOrderService: UtilOrderService,
		private store: Store,
		private dialog: MatDialog,
		private sentenceCasePipe: SentencecasePipe,
		public ticketCenterMainService: TicketCenterMainService,
	) {
		this.loadStaicData();
		this.subscribeManagerInit();
	}

	ngOnInit(): void {
		const pwsElm = document.getElementsByTagName('page-with-sidebar').item(0).firstChild;

		const observer = new MutationObserver(mutationList => {
			this.checkSidebarclosed(mutationList, this)
		});


		observer.observe(pwsElm, {
			attributes: true,
			attributeFilter: ['class'],
			childList: false,
			characterData: false
		})
	}

	checkSidebarclosed(event, instance) {
		const target = event[0].target as HTMLTextAreaElement;
		instance.setSidebradStatus(target.classList.contains('sidebarclosed'));
	}

	setSidebradStatus(status) {
		this.sidebarClosed = status;
	}


	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	loadStaicData() {
		this.user$.pipe(take(1)).subscribe((store: BaseStateModel<UserDetailModel>) => {
			this.user = store.data;
		});
	}

	subscribeManagerInit() {
		this.subscribeManagerService.populate(
			this.utilTranslateService.translate.onLangChange.subscribe((e: LangChangeEvent) => {
				for (const article of this._localListHandlerData.dataSubset) {
					this.articleDescriptionHandler(article);
				}
			}),
			'utilTranslateService.translate.onLangChange'
		);
	}

	returnArticlePriceWithDiscountDescription(article: ArticlePouchModel): string {
		const priceWithDiscount = this.utilPriceService.retrievePriceWithDiscount(
			article.articlePrice.price,
			article.articlePrice.discount,
			article.discount_agent
		);
		const value = UtilPrice.returnItemValueFormatted(priceWithDiscount);
		const currency =
			this.utilService.returnIsMainOfList<CurrencyPouchModel>(this.organization.currency).description_short;
		return `${value} ${currency}`;
	}

	articleDescriptionHandler(article: ArticlePouchModel) {
		article.description = this.utilTranslateService.getTranslationFromLanguage(
			article.articleDescription?.language_list
		).description;
	}

	articleDiscountHandler(article: ArticlePouchModel) {
		let deiscountDescription: string = '';
		if (article.articlePrice && article.articlePrice.discount && article.articlePrice.discount.length) {
			for (let i = 0; i < article.articlePrice.discount.length; i++) {
				deiscountDescription = deiscountDescription + article.articlePrice.discount[i].value + '%';
				if (i !== article.articlePrice.discount.length - 1) {
					deiscountDescription = deiscountDescription + ' + ';
				}
			}
		}
		if (!article.tableMetaInformation) {article.tableMetaInformation = <ArticleTableMetaInformation>{};}
		if (deiscountDescription !== '') {
			article.tableMetaInformation.discountDescription = deiscountDescription;
			article.tableMetaInformation.priceWithDiscountDescription =
				this.returnArticlePriceWithDiscountDescription(article);
		} else {
			article.tableMetaInformation.discountDescription = '0%';
			if (article.discount_agent) {
				article.tableMetaInformation.priceWithDiscountDescription =
					this.returnArticlePriceWithDiscountDescription(article);
			}
		}
	}

	// TODO: decidere circa la modellazione delle prop. di utility che servono solo di appoggio per la table
	inputQuantityDisableHandler(article: ArticlePouchModel) {
		article.tableMetaInformation.inputQuantityDisable = this.canInputArticleQuantity(article);
	}

	imageGalleryShowandler(article: ArticlePouchModel) {
		article.tableMetaInformation.hasGalleryImage =
			this.utilTranslateService.getImageWithLanguage(article) ? true : false;
	}

	canInputArticleQuantity(article: ArticlePouchModel): boolean {
		let isAvailable: boolean = this.utilOrderService.articleQuantityIsAvailable(article, this.organization);
		if (
			(
				!(article.articlePrice && article.articlePrice.price) &&
				article.articleDescription.free_sample !== 'S'
			) ||
			!(this.order && this.order.header.status ===  OrderStatusEnum.DRAFT)
		) {
			isAvailable = false;
		}
		return isAvailable;
	}

	dialogGallery(article: ArticlePouchModel) {
		this.dialog.open(DialogImageGalleryComponent, {
			data: {
				language_list: article.articleDescription.language_list
			},
			disableClose: true,
			panelClass: ['angelo-theme-dialog', 'image-gallery']
		});
	}

	articleNoteChange(e: any, row: ArticlePouchModel) {
		const dialog = this.dialog.open(DialogTextEditComponent, {
			data: {
				title: `
					${
						this.sentenceCasePipe.transform(
							this.utilTranslateService.translate.instant('comment.name') +
							' ' +
							this.utilTranslateService.translate.instant('product.name')
						)
					}
					${ConfigurationCustomer.AppStructure.Erp.has_erp ? row.code_erp : row.code_item}`,
				text: row.note_order,
				disabled: !(this.order.header.status === OrderStatusEnum.DRAFT),
				optional: true
			},
			panelClass: ['angelo-theme-dialog', 'note-change'],
		});
		dialog.afterClosed().subscribe(res => {
			if (typeof res === 'string') {
				if (res === '' || !res.replace(/\s/g, '').length) {
					row.note_order = null;
				} else {
					row.note_order = res;
				}
				this.onArticleChange.emit(
					{
						event: e, row: row, key: 'note_order'
					}
				);
			}
		});
	}
}
