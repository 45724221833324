import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SubscribeManagerService, BaseStateModel, FormControlMultipurposeModel } from '@saep-ict/angular-core';
import {
	UserDetailModel, OrderStateModel, ArticleListFilterModel,
	OrganizationStateModel, OrderPriceMap, AngularSpin8CoreUtilTranslateService,
} from '@saep-ict/angular-spin8-core';
import {  CategoryMap, Category, ArticlePouchModel, DivisionPouchModel, OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ConfigurationCategory } from '../../../../../../constants/category.constant';
import { ConfigurationCustomer } from '../../../../../../constants/configuration-customer';
import { StoreUtilService } from '../../../../../../service/util/store-util.service';
import { UtilOrderService } from '../../../../../../service/util/util-order.service';
import { UtilPriceService } from '../../../../../../service/util/util-price.service';
import { StateFeature } from '../../../../../../state';
import { ArticleActionEnum } from '../../../../../../state/article/article.actions';
import { CategoryListActionEnum } from '../../../../../../state/category-list/category-list.actions';
import { OrderActionEnum } from '../../../../../../state/order/order.actions';
import { OrganizationActionEnum } from '../../../../../../state/organization/organization.actions';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { ROUTE_URL } from '@saep-ict/angular-spin8-core';
import { AppUtilService } from '../../../../../../service/util/app-util.service';
import { ConfigurationOrder } from '../../../../../../constants/order/order.constants';
import moment from 'moment';
import { ConnectionModel } from '../../../../../../model/connection.model';
import { CustomerAppConfig } from '../../../../../../customer-app.config';
import { ConnectionActionEnum } from '../../../../../../state/connection/connection.actions';
import { SubscribeManagerItem } from '../../../../../../model/subscribe-manager.model';
import _ from 'lodash';
import { ConfigurationSubscribeManager } from '../../../../../../constants/subscribe-manager.constant';
import { CategoryModel } from '../../../../../../model/category-list.model';
import { UtilArticleService } from '../../../../../../service/util/util-article.service';

@Component({
	selector: 'order-detail-aside-summary',
	templateUrl: './order-detail-aside-summary.component.html',
	styleUrls: ['./order-detail-aside-summary.component.scss'],
	providers: [SubscribeManagerService]
})
export class OrderDetailAsideSummaryComponent implements OnInit {

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(StateFeature.getOrganizationState);
	organization: OrganizationStateModel;

	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel;
	orderPriceMap: OrderPriceMap = new OrderPriceMap();

	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);
	articleListAll: ArticlePouchModel[];
	articleList: ArticlePouchModel[];

	categoryList$: Observable<BaseStateModel<CategoryMap, ArticleListFilterModel>> = this.store.select(
		StateFeature.getCategoryListState
	);
	categorySectionList: CategoryModel.SectionItem[] = [];

	connection$: Observable<BaseStateModel<ConnectionModel>> = this.store.select(StateFeature.getConnectionState);
	offline: boolean = false;

	subscribeList: SubscribeManagerItem[] = [
		{ key: 'data', observable: this.subscribeData() },
		{ key: 'router', observable: this.subscribeRouterData() },
		{ key: 'connection', observable: this.subscribeConnectionData() }
	];

	ROUTE_URL = ROUTE_URL;

	routedMainContentKey: string = ConfigurationOrder.returnLastUrlSegment(this.router.url);

	orderHeaderFieldList: FormControlMultipurposeModel.Item[] = [];

	orderStatusEnum = OrderStatusEnum;

	constructor(
		private store: Store,
		private utilStoreService: StoreUtilService,
		private subscribeManagerService: SubscribeManagerService,
		public utilOrderService: UtilOrderService,
		private utilPriceService: UtilPriceService,
		private utilTranslateService: AngularSpin8CoreUtilTranslateService,
		public router: Router,
		public route: ActivatedRoute,
		private utilService: AppUtilService,
		private appConfig: CustomerAppConfig,
		private utilArticleService: UtilArticleService
	) {
		this.loadStaticData();
		ConfigurationSubscribeManager.init(this.subscribeList, this.subscribeManagerService);
	}

	ngOnInit(): void {
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	loadStaticData() {
		this.utilStoreService.retrieveSyncState<UserDetailModel>(this.user$).subscribe(res => {this.user = res.data;});
	}

	subscribeRouterData(): Observable<void> {
		return this.router.events.pipe(
			filter((e) => e instanceof NavigationEnd),
			map((e: NavigationEnd) => {
				this.routedMainContentKey = ConfigurationOrder.returnLastUrlSegment(e.url);
			})
		)
	}

	subscribeConnectionData(): Observable<void> {
		return this.connection$.pipe(
			filter((e: BaseStateModel<ConnectionModel>) => !!(e && e.data && this.appConfig.envConfig.enableOffline)),
			map((e: BaseStateModel<ConnectionModel>) => {
				switch (e.type) {
					case ConnectionActionEnum.ERROR:
						throw new Error(ConnectionActionEnum.ERROR);
					case ConnectionActionEnum.UPDATE:
						this.offline = e.data.offline;
						break;
				}
			})
		);
	}

	subscribeData(): Observable<Promise<void>> {
		return this.organization$.pipe(
			filter((e: BaseStateModel<OrganizationStateModel>) => e && e.data && e.type !== OrganizationActionEnum.LOAD),
			mergeMap((e: BaseStateModel<OrganizationStateModel>) => {
				if (e.type === OrganizationActionEnum.ERROR) { throw new Error(OrganizationActionEnum.ERROR); }
				this.organization = e.data;
				return this.order$;
			}),
			filter((e: BaseStateModel<OrderStateModel>) => !!(e && e.data)),
			mergeMap((e: BaseStateModel<OrderStateModel>) => {
				switch(e.type) {
					case OrderActionEnum.UPDATE:
					case OrderActionEnum.COMPLETED:
						this.order = e.data;
						break;
					case OrderActionEnum.ERROR:
						throw new Error(OrderActionEnum.ERROR);
				}
				return this.articleList$;
			}),
			filter((e: BaseStateModel<ArticlePouchModel[]>) => e && e.type !== ArticleActionEnum.LOAD_FROM_RECAP),
			mergeMap((e: BaseStateModel<ArticlePouchModel[]>) => {
				if (e.type === ArticleActionEnum.ERROR) { throw new Error(ArticleActionEnum.ERROR); }
				this.articleListAll = e.data;
				this.articleList = _.cloneDeep(this.articleListAll);
				return this.categoryList$;
			}),
			filter((e: BaseStateModel<CategoryMap, ArticleListFilterModel>) => e && e.type !== CategoryListActionEnum.LOAD_ALL),
			map(async (store: BaseStateModel<CategoryMap, ArticleListFilterModel>) => {
				if (this.order.product_list.length) {
					if (this.order.header.status !== OrderStatusEnum.DRAFT) {
						this.articleList = this.utilOrderService.returnArticleListByOrderSent(this.order);
					} else {
						this.articleList = this.utilOrderService.returnFilteredAndMergedArticleListByOrderDraft(
							this.order,
							this.articleList,
							this.organization
						);
						this.order = await this.utilArticleService.updateOrderOnArticleRecapChange(
							this.order,
							this.articleList,
							this.organization
						);
					}
				} else {
					this.articleList = [];
				}
				this.orderPriceMap = this.utilPriceService.updateOrderPriceMap(this.order);
				this.orderHeaderFieldList =
					await this.utilOrderService.returnOrderHeaderFieldListWithValue(
						ConfigurationCustomer.Order.headerAsideSummaryFieldList[
							this.user.current_permission.context_application
						],
						this.order
					);
				switch (store.type) {
					case CategoryListActionEnum.UPDATE:
						// TODO: modificare il parse delle categorie in base alla loro organizzazione definitiva sul progetto
						try {
							const categoryTree: Category[] =
								await ConfigurationCustomer.Order.returnNestedCategoryParseAsideSummary(
									this.user,
									store.data.tree
								);
							const sectionList: CategoryModel.SectionItem[] =
								ConfigurationCustomer.Order.categorySectionList &&
								ConfigurationCustomer.Order.categorySectionList.length > 0 ?
								ConfigurationCustomer.Order.categorySectionList :
								[
									{
										title: 'theme.article.field.products',
										articleListFilter: (articleList: ArticlePouchModel[]): ArticlePouchModel[] =>
											articleList.filter(i => i)
									}
								];
							this.categorySectionList =
								await this.returnCategorySectionList(sectionList, categoryTree,	this.articleList);
						} catch (err) {
							throw new Error(err);
						}
						break;
					case CategoryListActionEnum.ERROR:
						throw new Error(OrganizationActionEnum.ERROR);
				}
			})
		);
	}

	handlePrimaryButton() {
		if (this.routedMainContentKey === ROUTE_URL.checkout) {
			// order send
			this.utilOrderService.orderChangeTemp =
				this.utilOrderService.orderChangeTemp ? this.utilOrderService.orderChangeTemp : this.order;
			// if (
			// 	this.utilOrderService.orderChangeTemp.header.discrepancy_list &&
			// 	this.utilOrderService.orderChangeTemp.header.discrepancy_list.length
			// ) {
			// 	this.utilOrderService.orderChangeTemp.header.status = OrderStatusEnum.TO_AUTHORIZE;
			// } else {
				this.utilOrderService.orderChangeTemp.header.status = ConfigurationCustomer.Order.orderToSendStatus;
			// }
			this.utilOrderService.orderChangeTemp.header.submission_date = new Date().getTime();
			if (this.utilService.returnIsMainOfList<DivisionPouchModel>(this.organization.division_list)) {
				this.utilOrderService.orderChangeTemp.header.bank_object = {
					description:
						this.utilService.returnIsMainOfList<DivisionPouchModel>(this.organization.division_list)
							.banks_descriptions &&
						this.utilService.returnIsMainOfList<DivisionPouchModel>(this.organization.division_list)
							.banks_descriptions[0]
							? this.utilService.returnIsMainOfList<DivisionPouchModel>(this.organization.division_list)
									.banks_descriptions[0]
							: '',
					iban: this.utilService.returnIsMainOfList<DivisionPouchModel>(this.organization.division_list).iban
				};
			}
			// TODO: consolidare controllo su data antecedente rispetto alla regola
			const isBeforeToday: boolean =
				moment(this.utilOrderService.orderChangeTemp.header.first_evasion_date)
				.diff(
					ConfigurationCustomer.Order.Date.minDateSelectable[this.user.current_permission.context_application],
					'd',
					false
				) < 0;
			if (isBeforeToday) {
				this.utilOrderService.orderChangeTemp.header.first_evasion_date =
					moment(ConfigurationCustomer.Order.Date.minDateSelectable[this.user.current_permission.context_application])
					.valueOf();
			}
			this.utilService.deleteEmptyProperties(this.utilOrderService.orderChangeTemp.header.bank_object);
			this.utilOrderService.updateArticle = true;
			this.utilOrderService.updateOrderSource.next({
				order: this.utilOrderService.orderChangeTemp,
				useLoader: true,
				statusChange: true
			});
		} else {
			// navigate to checkout
			this.router.navigate([ROUTE_URL.checkout], { relativeTo: this.route });
		}
	}

	returnCategorySectionList(
		categorySectionList: CategoryModel.SectionItem[],
		categoryTree: Category[],
		articleList: ArticlePouchModel[]
	): Promise<CategoryModel.SectionItem[]> {
		return new Promise(async resolve => {
			try {
				categorySectionList = _.cloneDeep(categorySectionList);
				for (const item of categorySectionList) {
					item.articleCategoryTree =
						await ConfigurationCategory.returnArticleCheckoutTree(
							item.articleListFilter(articleList),
							categoryTree,
							this.utilTranslateService.languages
						);
				}
				resolve(categorySectionList);
			} catch (err) {
				throw new Error(err);
			}
		})
	}
}