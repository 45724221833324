<form class="angelo-theme" *ngIf="form" [formGroup]="form">
	<div class="container-fluid">
		<mat-card class="angelo-theme mb-30">
			<top-bar
				[goBackButton]="true"
				[goBackPath]="backPath"
				class="mb-20"
			>
				<ng-container left-side-content><h2>{{ 'general.information.general' | translate | sentencecase }}</h2></ng-container>
			</top-bar>
			<!-- <mat-toolbar>
				<mat-toolbar-row>
					<h1>
						{{ 'general.information.general' | translate | sentencecase }}
					</h1>
				</mat-toolbar-row>
			</mat-toolbar> -->
			<mat-card-content>
				<div class="top-form">
					<div class="">
						<mat-form-field>
							<input
								matInput
								formControlName="size"
								[placeholder]="'article.field.size' | translate | sentencecase"
								maxlength="15"
							/>
						</mat-form-field>
					</div>
					<div class="">
						<div class="flagToggle">
							<mat-slide-toggle formControlName="is_highlighted"
								>{{ 'article.name' | translate | sentencecase }}
								{{ 'article.field.is_highlighted' | translate }}</mat-slide-toggle
							>
						</div>
					</div>
					<div class="">
						<div class="flagToggle" formGroupName="stock">
							<mat-slide-toggle formControlName="is_available">{{
								'article.field.is_available' | translate | sentencecase
							}}</mat-slide-toggle>
						</div>
					</div>
					<div class="">
						<div class="flagToggle">
							<mat-slide-toggle formControlName="visible"
								>{{ 'article.name' | translate | sentencecase }}
								{{ 'article.field.visible' | translate }}</mat-slide-toggle
							>
						</div>
					</div>
				</div>
				<mat-divider></mat-divider>
				<div class="row">
					<div class="col-12">
						<h3>
							{{ 'article.related.list' | translate | sentencecase }}
						</h3>
						<article-list-structure-wrapper-plain-list
							[localListHandlerWidgetWrapperData]="relatedArticleListData"
							[localListHandlerWidgetWrapperColumnList]="relatedArticleListData.columnList"
							[localListHandlerTextFilterShow]="true"
							[localListHandlerPaginatorShow]="false"
							[configurationAction]="{ delete: true }"
							(onDeleteArticle)="dialogRelatedArticleDelete($any($event))"
						></article-list-structure-wrapper-plain-list>
						<button
							mat-fab
							(click)="dialogRelatedArticleAdd()"
							class="mat-fab-button-section-bottom-right"
							[matTooltip]="'article.add_related' | translate | sentencecase"
							matTooltipPosition="left"
						>
							<mat-icon>add</mat-icon>
							<span class="label">{{ 'article.add_related' | translate | sentencecase }}</span>
						</button>
					</div>
				</div>
			</mat-card-content>
		</mat-card>

		<mat-card class="angelo-theme">
			<mat-toolbar>
				<mat-toolbar-row>
					<h1>
						{{ 'language.name_plural' | translate | sentencecase }}
					</h1>
				</mat-toolbar-row>
			</mat-toolbar>
			<mat-card-content>
				<mat-tab-group
					[selectedIndex]="
						utilTranslateService.languages.indexOf(language.DEFAULT ? language.DEFAULT : language.FALLBACK)
					"
					(selectedTabChange)="selectedTabChange()"
				>
					<ng-container formArrayName="language_list">
						<ng-container
							*ngFor="
								let language of returnFormFieldByName('language_list').controls;
								let language_index = index
							"
							[formGroupName]="language_index"
						>
							<!-- <div [formGroupName]="language_index"> -->
							<mat-tab dynamicHeight="true">
								<ng-template mat-tab-label>
									<div *ngIf="!language.valid">
										<mat-icon>error_outline</mat-icon>
									</div>
									&nbsp;
									<span>{{ language.value.language | uppercase }}</span>
								</ng-template>
								<!-- Base Information -->
								<h2>{{ 'general.information.base' | translate | sentencecase }}</h2>
								<mat-form-field>
									<input
										matInput
										formControlName="description"
										[placeholder]="'article.field.name' | translate | sentencecase"
									/>
								</mat-form-field>
								<mat-form-field>
									<textarea
										matInput
										formControlName="description_extended"
										[placeholder]="'article.field.description' | translate | sentencecase"
									></textarea>
								</mat-form-field>
								<!-- Additional Information -->
								<h2>{{ 'general.information.additional' | translate | sentencecase }}</h2>
								<mat-form-field>
									<textarea
										matInput
										formControlName="description_add"
										[placeholder]="'article.field.detail' | translate | sentencecase"
									></textarea>
								</mat-form-field>
								<mat-form-field>
									<textarea
										matInput
										formControlName="technical_info"
										[placeholder]="'general.information.technical' | translate | sentencecase"
									></textarea>
								</mat-form-field>
								<!-- Image List -->
								<h2>{{ 'general.image.name_plural' | translate | sentencecase }}</h2>
								<ng-container formArrayName="image_list">
									<ng-container
										*ngFor="
											let image of returnFormFieldLanguageImageListByIndex(language_index)
												.controls;
											let image_index = index
										"
										[formGroupName]="image_index"
									>
										<div class="row">
											<div class="col-md-6">
												<mat-form-field>
													<input
														matInput
														formControlName="name"
														[placeholder]="'general.image.name' | translate | sentencecase"
													/>
												</mat-form-field>
											</div>
											<div class="col-md-6">
												<mat-form-field>
													<input
														matInput
														formControlName="alt"
														[placeholder]="'general.image.alt' | translate | sentencecase"
													/>
												</mat-form-field>
											</div>
										</div>
										<div class="row">
											<div class="col-md-6">
												<div *ngIf="image.value.bucket_link; else imageLoader">
													<article-image
														[image]="image.value.bucket_link"
														[refreshArticleImage]="refreshArticleImage"
														(click)="downloadImage(image.value)"
													>
													</article-image>
												</div>
												<ng-template #imageLoader>
													<attachment-resolver
														[multiple]="false"
														(fileEmitter)="
															addImage($event, language.value.language, image_index)
														"
													>
													</attachment-resolver>
												</ng-template>
												{{
													'general.image.dimensions'
														| translate
															: { imageWidth: configurationCustomer.Article.imageWidth }
														| sentencecase
												}}
											</div>
											<div class="col-md-3 isHiglightedToggle">
												<mat-slide-toggle
													formControlName="is_main_of_list"
													(click)="
														checkUniqueImageMainOfList(
															language.value.language,
															image.value.is_main_of_list,
															image_index
														)
													"
												>
													{{ 'general.image.main_of_list' | translate | sentencecase }}
												</mat-slide-toggle>
											</div>
											<div class="col-md-3">
												<button
													mat-raised-button
													color="accent"
													(click)="deleteImage(language.value.language, image_index)"
												>
													{{ 'general.delete' | translate | sentencecase }}
												</button>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<button
									mat-fab
									(click)="addImageRow(language.value.language)"
									class="mat-fab-button-section-bottom-right"
									[matTooltip]="'general.image.add' | translate | sentencecase"
									matTooltipPosition="left"
								>
									<mat-icon>add</mat-icon>
									<span class="label">{{ 'general.image.add' | translate | sentencecase }}</span>
								</button>
							</mat-tab>
						</ng-container>
					</ng-container>
				</mat-tab-group>
			</mat-card-content>
		</mat-card>
		<form-button-bar
			[align]="'right'"
			[primaryButtonLabel]="'general.save' | translate | sentencecase"
			(primaryButtonClickEmitter)="onFormSubmit()"
			[primaryButtonIsDisabled]="!form.valid"
			[secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
			(secondaryButtonClickEmitter)="cancelUpdate()"
		></form-button-bar>
	</div>
</form>
