<page-with-sidebar [isRoot]="true" [sideBarPosition]="sideBarPositionValues.TOP">
	<ng-container pageMain>
		<mat-card class="angelo-theme">
			<organization-list-wrapper
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="columns"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				(onItemSelect)="goToDetail($event.level, $event.code)"
				(openOrderNew)="orderDraftCreate($event)"
			>
			</organization-list-wrapper>
		</mat-card>
	</ng-container>
	<ng-container pageSide *ngIf="filterVisible.includes(true)">
		<sidebar-content-wrapper>
			<ng-container main-content>
				<form class="angelo-theme" [formGroup]="formFilters">
					<mat-form-field
						*ngIf="
							user.current_permission.context_application != contextApplicationItemCodeEnum.CRM &&
							filterVisible[1]
						"
					>
						<mat-label>{{ 'organization.field.status' | translate | sentencecase }}</mat-label>
						<mat-select formControlName="company_status">
							<mat-option *ngFor="let item of companyStates" [value]="item">
								<div class="chips-mini">
									<span [ngClass]="item.toUpperCase()"></span>
								</div>
								{{ 'organization.status.' + item | translate | sentencecase }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<!-- filtro tipologia organizzazione -->
					<mat-form-field *ngIf="filterVisible[0]">
						<mat-label>{{ 'organization.typology' | translate | sentencecase }}</mat-label>
						<mat-select formControlName="organization_type">
							<mat-option *ngFor="let item of organizationTypeList" [value]="item">
								{{
									'saep_ict_angular_spin8_core.page.organization.organization_type_label.' + item
										| translate
										| sentencecase
								}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<!-- filtro organization class -->
					<mat-form-field *ngIf="filterVisible[2]">
						<mat-label>{{ 'theme.organization.field.classification' | translate | sentencecase }}</mat-label>
						<mat-select formControlName="organization_class">
							<mat-option *ngFor="let item of configurationCustomer.Organization.request" [value]="item">
								{{item}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<!-- <mat-toolbar>
				<button class="mr-8" mat-raised-button color="primary" type="submit">
					{{ 'general.apply_filters' | translate | sentencecase }}
				</button>
				<button mat-button color="default" type="button" (click)="resetFilters()">
					{{ 'general.reset' | translate | sentencecase }}
				</button>
			</mat-toolbar> -->
					<form-button-bar
						[align]="'right'"
						[primaryButtonLabel]="'general.apply_filters' | translate | sentencecase"
						(primaryButtonClickEmitter)="onFormFiltersSubmit()"
						[secondaryButtonLabel]="'general.reset' | translate | sentencecase"
						(secondaryButtonClickEmitter)="resetFilters()"
					></form-button-bar>
				</form>
			</ng-container>
		</sidebar-content-wrapper>
	</ng-container>
	<ng-container pageSide>
		<button class="export-button" (click)="exportExcel()">Export Excel</button>
	</ng-container>
</page-with-sidebar>

<button
	mat-fab
	*ngIf="
		configurationCustomer.Organization.canCreate.detail[
			contextApplicationItemCodeEnum[user.current_permission.context_application]
		]
	"
	(click)="createNew()"
	[matTooltip]="'organization.add_new' | translate | sentencecase"
	matTooltipPosition="left"
	class="mat-fab-button"
>
	<mat-icon>add</mat-icon>
	<span class="label">{{ 'organization.add_new' | translate | sentencecase }}</span>
</button>
