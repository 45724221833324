import { Component, Input, OnInit } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { AngularSpin8CoreUtilTranslateService } from '@saep-ict/angular-spin8-core';
import { StatisticModel } from '../../model/statistics.model';
import _ from 'lodash';
import { StatisticEnum } from '../../enum/statistic.enum';
import { ConfigurationCustomer } from '../../constants/configuration-customer';
import { CouchDocumentEnum } from '../../enum/couch-document.enum';

@Component({
	selector: 'statistic-nested-category',
	templateUrl: './statistic-nested-category.component.html',
	styleUrls: ['./statistic-nested-category.component.scss']
})

export class StatisticNestedCategoryComponent implements OnInit {

	@Input() set configuration(e: StatisticModel.Structure.NestedCategoryConfiguration) {
		if (e && e.dataParse && e.dataParse.categoryTree) {
			this._configuration = e;
			this.dataSource.data = this._configuration.dataParse.categoryTree;
		}
	}
	_configuration: StatisticModel.Structure.NestedCategoryConfiguration;
	statisticEnum = StatisticEnum;

	treeControl = new NestedTreeControl<StatisticModel.Structure.Tree>(node => node.category_list);
	dataSource = new MatTreeNestedDataSource<StatisticModel.Structure.Tree>();

	articleCodeTypeToShow: string =
		ConfigurationCustomer.AppStructure.Erp.has_erp ?
		CouchDocumentEnum.CodeType.CODE_ERP :
		CouchDocumentEnum.CodeType.CODE_ITEM;

	constructor(
		public utilTranslateService: AngularSpin8CoreUtilTranslateService
	) { }

	ngOnInit(): void {}

}
