<mat-dialog-wrapper [title]="data.title" [showCloseIcon]="false">
  <ng-container content>
    <div class="container-fluid">
      <div class="row" [formGroup]="form">
        <div class="col-12">
          <mat-form-field>
            <mat-label>Data pagamento effettivo</mat-label>
            <input matInput formControlName="actual_payment_date" [matDatepicker]="picker" readonly
              (click)="picker.open()" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>

          </mat-form-field>

        </div>
      </div>
    </div>
  </ng-container>
  <ng-container actions>
    <form-button-bar [align]="'right'" [primaryButtonLabel]="'general.save' | translate | sentencecase"
      (primaryButtonClickEmitter)="onFormSubmit()" [secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
      (secondaryButtonClickEmitter)="dialogRef.close()">

    </form-button-bar>
  </ng-container>
</mat-dialog-wrapper>