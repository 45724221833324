import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// store
import { Store } from '@ngrx/store';
import { LocalListHandlerBase, AngularCoreUtilService } from '@saep-ict/angular-core';
import { ArticlePouchModel, LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';
import { Observable } from 'rxjs';
// widet & utility
import { BaseStateModel } from '@saep-ict/angular-core';
import { StateFeature } from '../../../state';
import { filter, take } from 'rxjs/operators';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'dialog-article-select',
	templateUrl: './dialog-article-select.component.html',
	styleUrls: ['./dialog-article-select.component.scss'],
	providers: []
})
export class DialogArticleSelectComponent extends LocalListHandlerBase implements OnInit, OnDestroy {

	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);
	articleList: ArticlePouchModel[];

	configurationCustomer = ConfigurationCustomer;

	localListHandlerData = <LocalListHandlerBaseModel<ArticlePouchModel>>{
		data: [],
		filters: {
			localSearchText: {
				value: null,
				key_list: [
					'articleDescription.language_list.description',
					ConfigurationCustomer.AppStructure.Erp.has_erp ? 'code_erp' : 'code_item'
				]
			}
		},
		pagination: {
			pageSize: 5
		},
		columnList: ConfigurationCustomer.Article.backofficeArticleDetailColumnList,
		sort: {
			name: ConfigurationCustomer.AppStructure.Erp.has_erp ? 'code_erp' : 'code_item',
			order: 'ASC'
		}
	};

	selectedArticleList: ArticlePouchModel[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { relatedArticleCodeListToFilter: string[] },
		public store: Store,
		public dialogRef: MatDialogRef<DialogArticleSelectComponent>,
		public utilService: AngularCoreUtilService,
		private translateService: TranslateService
	) {
		super(utilService, store);
		this.localListHandlerData.languageKey = this.translateService.currentLang;
		this.articleList$
			.pipe(
				filter(res => !!(res && res.data)),
				take(1)
			)
			.subscribe(state => {
				const articleList = state.data.filter(
					article => !this.data.relatedArticleCodeListToFilter.includes(article.code_item)
				);
				this.localListHandlerData.data.push(...(<ArticlePouchModel[]>articleList));
			});
	}

	ngOnInit() {}
	ngOnDestroy() {}

	// widget & utility
	outputCode() {
		this.dialogRef.close(this.selectedArticleList.map(article => article.code_item));
	}

	selectCode(article: ArticlePouchModel) {
		if (this.selectedArticleList.map(article => article.code_item)?.includes(article.code_item)) {
			const removeIndex = this.selectedArticleList.map(article => article.code_item).indexOf(article.code_item);
			this.selectedArticleList.splice(removeIndex, 1);
		} else {
			this.selectedArticleList.push(article);
		}
	}

	removeCode(code) {
		const removeIndex = this.selectedArticleList.map(article => article.code_item).indexOf(code);
		this.selectedArticleList.splice(removeIndex, 1);
	}
}
