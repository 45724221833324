import { Route } from '@angular/router';
import { FramePageComponent } from '../frame/admin/admin.component';
import { DashboardB2bComponent } from '../page/b2b/dashboard-b2b/dashboard-b2b.component';
import { ContactComponent } from '../page/commons/contact/contact.component';
import { MediaCenterComponent } from '../page/commons/media-center/media-center.component';
import { OrderDetailCatalogComponent } from '../page/commons/order/order-detail/order-detail-catalog/order-detail-catalog.component';
import { OrderDetailCheckoutComponent } from '../page/commons/order/order-detail/order-detail-checkout/order-detail-checkout.component';
import { OrderDetailComponent } from '../page/commons/order/order-detail/order-detail.component';
import { OrderComponent } from '../page/commons/order/order.component';
import { OrganizationDetailCreditComponent } from '../page/commons/organization/organization-detail/organization-detail-credit/organization-detail-credit.component';
import { OrganizationDetailDestinationComponent } from '../page/commons/organization/organization-detail/organization-detail-destination/organization-detail-destination.component';
import { OrganizationDetailOverviewComponent } from '../page/commons/organization/organization-detail/organization-detail-overview/organization-detail-overview.component';
import { OrganizationDetailComponent } from '../page/commons/organization/organization-detail/organization-detail.component';
import { ReceiptComponent } from '../page/commons/receipt/receipt.component';
import { MetaDataResolver } from '../service/resolvers/meta-data.resolver';
import { OrganizationDetailFullDetailComponent } from '../page/commons/organization/organization-detail/organization-detail-full-detail/organization-detail-full-detail.component';
import { TicketCenterRoutes } from '@saep-ict/ticket-center';
import { TicketCenterWrapperComponent } from '../page/commons/ticket-center-wrapper/ticket-center.component';
import { PermissionKeyEnum, ROUTE_URL } from '@saep-ict/angular-spin8-core';
import { NewsComponent } from '../page/commons/news/news.component';
import { NewsListWrapperComponent } from '../widget/news/news-list-wrapper/news-list-wrapper.component';
import { SurveyCenterComponent } from '../page/commons/survey-center/survey-center.component';
import { ForecastComponent } from '../page/commons/forecast/forecast.component';
import { ReportsComponent } from '../page/commons/reports/reports.component';
import { NewOrderDetailComponent } from '../page/commons/order/new-order-detail/new-order-detail.component';
import { OrderDetailMainHeaderEditComponent } from '../page/commons/order/new-order-detail/main/order-detail-main-header-edit/order-detail-main-header-edit.component';
import { AssetRequestCenterComponent } from '../page/commons/asset-request-center/asset-request-center.component';

export namespace B2BRouteList {
	export const routes: Route[] = [
		{
			path: '',
			component: FramePageComponent,
			children: [
				// Dashboard
				{
					path: '',
					component: DashboardB2bComponent,
					data: {
						permissionKey: [PermissionKeyEnum.B2B_DASHBOARD],
						meta: {
							title: 'Dashboard b2b',
							description: 'Home del b2b'
						}
					},
					pathMatch: 'full',
					resolve: {
						meta: MetaDataResolver
					}
				},
				// Organization
				{
					path: `${ROUTE_URL.company}/:idOrganization`, // TODO: :idOrganization should be handled during login for B2B
					component: OrganizationDetailComponent,
					data: {
						permissionKey: [PermissionKeyEnum.B2B_ORGANIZATION_DETAIL],
						meta: {
							title: 'Informazioni azienda',
							description: "Informazioni sull'azienda"
						}
					},
					resolve: {
						meta: MetaDataResolver
					},
					children: [
						{
							path: '',
							pathMatch: 'full',
							redirectTo: ROUTE_URL.overview,
							data: { permissionKey: [PermissionKeyEnum.B2B_ORGANIZATION_DETAIL] }
						},
						{
							path: ROUTE_URL.overview,
							component: OrganizationDetailOverviewComponent,
							data: {
								permissionKey: [PermissionKeyEnum.B2B_ORGANIZATION_DETAIL],
								meta: {
									title: 'Riassunto azienda',
									description: "Informazioni generali sull'azienda"
								}
							},
							resolve: {
								meta: MetaDataResolver
							}
						},
						{
							path: ROUTE_URL.details,
							component: OrganizationDetailFullDetailComponent,
							data: { permissionKey: [PermissionKeyEnum.B2B_ORGANIZATION_FULL_DETAIL] },
							pathMatch: 'full'
						},
						{
							path: ROUTE_URL.contacts,
							component: ContactComponent,
							data: { permissionKey: [PermissionKeyEnum.B2B_ORGANIZATION_CONTACT] }
						},
						{
							path: ROUTE_URL.addresses,
							component: OrganizationDetailDestinationComponent,
							data: { permissionKey: [PermissionKeyEnum.B2B_ORGANIZATION_DESTINATION] }
						},
						{
							path: ROUTE_URL.credits,
							component: OrganizationDetailCreditComponent,
							data: { permissionKey: [PermissionKeyEnum.B2B_ORGANIZATION_CREDIT] }
						}
					]
				},
				// Orders
				{
					path: ROUTE_URL.orders,
					data: { permissionKey: [PermissionKeyEnum.B2B_ORDER_LIST] },
					children: [
						{
							path: ':orderStatus',
							component: OrderComponent,
							pathMatch: 'full',
							data: {
								permissionKey: [PermissionKeyEnum.B2B_ORDER_DETAIL],
								meta: {
									title: 'Riassunto ordine',
									description: "Informazioni generali sull'ordine"
								}
							},
							resolve: {
								meta: MetaDataResolver
							}
						},
						{
							path: ':orderStatus/:organizationId/:orderId',
							// component: OrderDetailComponent,
							component: NewOrderDetailComponent,
							data: { permissionKey: [PermissionKeyEnum.B2B_ORDER_DETAIL] },
							children: [
								{
									path: '',
									// redirectTo: ROUTE_URL.catalog,
									redirectTo: 'header-edit', // TODO: trasferire insieme agli altri segmenti
									data: { permissionKey: [PermissionKeyEnum.B2B_ORDER_DETAIL] },
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.catalog,
									component: OrderDetailCatalogComponent,
									data: { permissionKey: [PermissionKeyEnum.B2B_ORDER_DETAIL] }
								},
								{
									path: ROUTE_URL.checkout,
									component: OrderDetailCheckoutComponent,
									data: {
										permissionKey: [PermissionKeyEnum.B2B_ORDER_DETAIL],
										meta: {
											title: 'Dettagli ordine',
											description: "Informazioni di dettaglio dell'ordine"
										}
									},
									resolve: {
										meta: MetaDataResolver
									}
								}
								,{
									path: 'header-edit', // TODO: trasferire insieme agli altri segmenti
									component: OrderDetailMainHeaderEditComponent,
									data: {	permissionKey: [PermissionKeyEnum.B2B_ORDER_DETAIL] }
								}
							]
						}
					]
				},
				// Receipts
				{
					path: ROUTE_URL.receipts,
					component: ReceiptComponent,
					data: {
						permissionKey: [PermissionKeyEnum.B2B_INVOICE_LIST],
						meta: {
							title: 'Fatture e note di credito',
							description: 'Lista delle fatture e note di credito'
						}
					},
					resolve: {
						meta: MetaDataResolver
					}
				},
				// Mediacenter
				{
					path: `${ROUTE_URL.mediaCenter}/:bucketFolderName`,
					data: {
						permissionKey: [
							PermissionKeyEnum.B2B_MEDIACENTER,
							'b2b-mediacenter-italy'
						]
					},
					component: MediaCenterComponent,
					pathMatch: 'full'
				},
				// ticket-center
				{
					path: `${ROUTE_URL.ticketCenter}`,
					data: { permissionKey: [PermissionKeyEnum.B2B_TICKET_CENTER] },
					children: [
						{
							path: '',
							data: {
								permissionKey: [PermissionKeyEnum.B2B_TICKET_CENTER],
								meta: {
									title: 'Ticket Center',
									description: 'Ticket Center'
								}
							},
							resolve: {
								meta: MetaDataResolver
							},
							component: TicketCenterWrapperComponent,
							children: TicketCenterRoutes
						}
					]
				},
				// News
				{
					path: ROUTE_URL.news,
					component: NewsComponent,
					data: {
						permissionKey: [PermissionKeyEnum.B2B_NEWS],
						meta: {
							title: 'News'
						}
					},
					resolve: {
						meta: MetaDataResolver
					},
					children: [
						{
							path: '',
							pathMatch: 'full',
							redirectTo: ROUTE_URL.new,
							data: { permissionKey: [PermissionKeyEnum.B2B_NEWS] }
						},
						{
							path: ROUTE_URL.new,
							component: NewsListWrapperComponent,
							data: { permissionKey: [PermissionKeyEnum.B2B_NEWS] }
						},
						{
							path: ROUTE_URL.allNews,
							component: NewsListWrapperComponent,
							data: { permissionKey: [PermissionKeyEnum.B2B_NEWS] }
						}
					]
				},
				// Survey Center
				{
					path: ROUTE_URL.surveyCenter,
					data: {
						permissionKey: [PermissionKeyEnum.B2B_SURVEY_CENTER]
					},
					children: [
						{
							path: ROUTE_URL.forecast,
							data: {
								permissionKey: [PermissionKeyEnum.B2B_SURVEY_CENTER_FORECAST]
							},
							component: ForecastComponent
						},
						{
							path: ROUTE_URL.budget,
							data: {
								permissionKey: [PermissionKeyEnum.B2B_SURVEY_CENTER_BUDGET],
								pageKey: ROUTE_URL.budget
							},
							component: SurveyCenterComponent
						},
						{
							path: ROUTE_URL.mediaMarketing,
							data: {
								permissionKey: [PermissionKeyEnum.B2B_SURVEY_CENTER_MEDIA_MARKETING],
								pageKey: ROUTE_URL.mediaMarketing
							},
							component: SurveyCenterComponent
						}
					]
				},
				// Reports
				{
					path: ROUTE_URL.reports,
					data: {
						permissionKey: [PermissionKeyEnum.B2B_REPORTS]
					},
					component: ReportsComponent
				},
								// Asset request center
								{
									path: ROUTE_URL.assetRequestTicketList,
									data: {
										permissionKey: [
											PermissionKeyEnum.B2B_ASSET_REQUEST_CENTER
										]
								},
									children: [
										{
											path: 'asset-request-center',
											data: {
												permissionKey: [PermissionKeyEnum.B2B_ASSET_REQUEST_CENTER],
												assetRequestType: 'image-video',
											},
											component: AssetRequestCenterComponent
										},
									]
									
								}
			]
		}
	];
}
