import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrganizationStateModel } from '@saep-ict/angular-spin8-core';
import moment from 'moment';

@Component({
	selector: 'dialog-partner',
	templateUrl: './dialog-partner.component.html',
	styleUrls: ['./dialog-partner.component.scss']
})
export class DialogPartnerComponent implements OnInit {
	form: FormGroup;
	partner: OrganizationStateModel;
	organizationClasses = ['Medico ortopedico', 'Specializzandi', 'Fisioterapista', 'Fisiatra', 'Infermiere'];

	constructor(
		public dialogRef: MatDialogRef<DialogPartnerComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		private fb: FormBuilder
	) {
		this.partner = this.data['partner'];
		this.formCreate();
		if (this.partner) {
			const organization_class = this.partner.organization_class;
			const contact = this.partner.contact_list && this.partner.contact_list.find(c => c.is_main_of_list);
			const first_name = contact && contact.first_name;
			const last_name = contact && contact.last_name;
			const birth_date = moment(this.partner['date_of_birth']);
			const fiscal_code = this.partner && this.partner.tax_data && this.partner.tax_data.tax_code;

			this.form.patchValue({
				organization_class: organization_class,
				first_name: first_name,
				last_name: last_name,
				birth_date: birth_date,
				fiscal_code: fiscal_code
			});
		}
	}

	formCreate() {
		this.form = this.fb.group({
			organization_class: [],
			first_name: [],
			last_name: [],
			birth_date: [],
			fiscal_code: []
		});
	}

	ngOnInit(): void {}

	onFormSubmit() {
		const organization_class = this.form.get('organization_class').value;
		const first_name = this.form.get('first_name').value;
		const last_name = this.form.get('last_name').value;
		const birth_date = this.form.get('birth_date').value.valueOf();
		const fiscal_code = this.form.get('fiscal_code').value;

		if (organization_class && first_name && last_name && birth_date && fiscal_code) {
			this.partner.organization_class = organization_class;
			this.partner.business_name = `${last_name} ${first_name}`;
			let contact = this.partner.contact_list && this.partner.contact_list.find(c => c.is_main_of_list);
			if (!contact)
				contact = {
					type: 'contact',
					is_main_of_list: true
				};
			contact.first_name = first_name;
			contact.last_name = last_name;
			if (!this.partner.contact_list) this.partner.contact_list = [];
			this.partner.contact_list.push(contact);
			this.partner['date_of_birth'] = birth_date;
			let tax_data = this.partner.tax_data;
			if (!tax_data) tax_data = {};
			tax_data.tax_code = fiscal_code;
			this.partner.tax_data = tax_data;
			this.dialogRef.close(this.partner);
		}
	}
}
