<mat-toolbar *ngIf="localListHandlerTextFilterShow">
	<mat-toolbar-row>
		{{ 'general.items_number' | translate | sentencecase }}
		<span class="badge ml-3" [class.ml-3]="localListHandlerTitle">
			{{ localListHandlerData?.data?.length || 0 }}
		</span>
	</mat-toolbar-row>
	<mat-toolbar-row
		*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
		class="mt-30"
	>
		<span class="flex-span"></span>
		<td-search-box
			*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="true"
			(searchDebounce)="localListHandlerApplyFilter()"
			[placeholder]="'general.search' | translate | sentencecase"
		>
		</td-search-box>
	</mat-toolbar-row>
</mat-toolbar>

<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<td-data-table
		#dataTable
		[data]="localListHandlerData.dataSubset"
		[columns]="_localListHandlerWidgetWrapperColumnList"
		[sortable]="true"
		(sortChange)="localListHandlerApplyFilter({ sort: $event })"
		[sortBy]="localListHandlerData.sort && localListHandlerData.sort.name ? localListHandlerData.sort.name : null"
		[sortOrder]="
			localListHandlerData.sort && localListHandlerData.sort.order ? localListHandlerData.sort.order : null
		"
	>
		<!-- Email confermata -->
		<ng-template *ngIf="companyContact.send_email_confirmation" tdDataTableTemplate="status" let-value="value">
			<div class="chips-mini" [matTooltip]="value | sentencecase">
				<span
					[ngClass]="{
						ACTIVE: value === 'confermato',
						SEND: value === 'validazione in corso',
						BLOCKED: value === 'email non confermata'
					}"
				></span>
			</div>
		</ng-template>

		<!-- full_name -->
		<ng-template tdDataTableTemplate="full_name" let-value="value" let-row="row">
			<div class="full-name">
				<a href="javascript:void(0)" (click)="onItemSelect.emit(row)">
					{{ row.full_name || row.first_name + ' ' + row.last_name | sentencecase }}
				</a>
				<small class="cell-subtitle">{{ row.role ? row.role : '' }}</small>
			</div>
		</ng-template>

		<!-- phone -->
		<ng-template tdDataTableTemplate="phone" let-row="row">
			<p>
				{{ row && row.phone ? row.phone : '-' }}
			</p>
		</ng-template>

		<!-- contact_details -->
		<ng-template tdDataTableTemplate="contact_details" let-row="row">
			<p>
				{{ row && row.email ? row.email : '-' }}
				<!-- <br /> -->
				<!-- <small class="cell-subtitle">
					{{ row && row.phone ? row.phone : '' }}
				</small> -->
			</p>
		</ng-template>

		<!-- area -->
		<ng-template tdDataTableTemplate="area" let-row="row">
			<p>
				{{ row.area || '-' }}
			</p>
		</ng-template>

		<!-- organization -->
		<ng-template tdDataTableTemplate="organization.business_name" let-value="value" let-row="row">
			<p>
				<ng-container *ngIf="row.organization; else nothingToShow">
					<a
						href="javascript:void(0)"
						(click)="
							selectCompany.emit({ level: row.organization.level, code: row.organization.code_item })
						"
					>
						{{ row.organization.business_name | sentencecase }}
					</a>
					<br />
					<small class="cell-subtitle">
						<ng-container
							[ngTemplateOutlet]="
								currentContext === ContextApplicationItemCodeEnum.CRM ? crmLevel : vatNumber
							"
							[ngTemplateOutletContext]="{ value: row.organization }"
						>
						</ng-container>
					</small>
				</ng-container>
			</p>
		</ng-template>

		<ng-template tdDataTableTemplate="date_creation" let-value="value" let-row="row">
			<p *ngIf="value">
				{{ value | date: 'dd/MM/yyyy' }}
				{{ value | date: 'HH:mm' }}
			</p>
			<p *ngIf="!value">
				{{ row.date_created | date: 'dd/MM/yyyy' }}
				{{ row.date_created | date: 'HH:mm' }}
			</p>
		</ng-template>

		<!-- order_confirm_email -->
		<ng-template
			*ngIf="companyContact.can_activate_email_order_receipt"
			tdDataTableTemplate="order_confirm_email"
			let-value="value"
			let-row="row"
		>
			<mat-slide-toggle
				[checked]="value"
				(change)="toggleHasNotification.emit(row)"
				[disabled]="disabledOrderConfirmEmail"
			>
				{{ 'contact.status.' + (value ? 'active' : 'inactive') | translate | sentencecase }}
			</mat-slide-toggle>
		</ng-template>

		<!-- Actions -->
		<ng-template tdDataTableTemplate="actions" let-value="value" let-row="row">
			<button
				mat-icon-button
				aria-label="go to analytics"
				[matTooltip]="'contact.analytics' | translate | sentencecase"
				matTooltipPosition="left"
				(click)="openAnalytics.emit(row)"
				*ngIf="ContextApplicationItemCodeEnum.CRM"
			>
				<mat-icon>analytics</mat-icon>
			</button>
			<button
				mat-icon-button
				aria-label="add action"
				[matTooltip]="'action.show' | translate | sentencecase"
				matTooltipPosition="left"
				(click)="openDialogActionList.emit(row)"
				*ngIf="ContextApplicationItemCodeEnum.CRM"
			>
				<mat-icon>fact_check</mat-icon>
			</button>
			<button
				mat-icon-button
				aria-label="remove contacat"
				[matTooltip]="'contact.remove' | translate | sentencecase"
				matTooltipPosition="left"
				(click)="openDialogConfirmRemoveContact.emit(row)"
				*ngIf="canDelete"
			>
				<mat-icon>clear</mat-icon>
			</button>
		</ng-template>
	</td-data-table>

	<mat-toolbar>
		<mat-toolbar-row>
			<span class="flex-span"></span>
			<mat-paginator
				*ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow"
				#matPaginator
				(page)="localListHandlerApplyFilter({ pagination: $event })"
				[length]="localListHandlerData.pagination.totalFilteredItemCount"
				[pageSize]="localListHandlerData.pagination.pageSize"
				[pageSizeOptions]="[5, 10, 25, 100]"
			>
			</mat-paginator>
		</mat-toolbar-row>
	</mat-toolbar>
</ng-container>
<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
<ng-template #nothingToShow>
	<p>-</p>
</ng-template>
<ng-template #crmLevel let-value="value">
	{{ value.level }}
</ng-template>
<ng-template #vatNumber let-value="value">
	<ng-container *ngIf="value.vat_number">
		{{ 'organization.field.vat_number_short' | translate | uppercase }}
		{{ value.vat_number }}
	</ng-container>
</ng-template>
