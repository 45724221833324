import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { BasePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';
import { OrganizationStateModel } from '@saep-ict/angular-spin8-core';
import { RegistrationTypeEnum } from '../../enum/registration.enum';

export enum OrganizationActionEnum {
	LOAD = '[organization] Load',
	CREATE_ITEM_API = '[organization] Create Item API',
	SAVE = '[organization] Save',
	SAVE_SUCCESS = '[organization] Save success',
	UPDATE = '[organization] Update',
	RESET = '[organization] Reset',
	ERROR = '[organization] Error'
}

export namespace OrganizationStateAction {
	export const load = createAction(OrganizationActionEnum.LOAD, props<BaseStateModel<BasePouchModel>>());
	export const createItemApi = createAction(
		OrganizationActionEnum.CREATE_ITEM_API,
		props<BaseStateModel<{ organization: OrganizationStateModel; registrationType?: RegistrationTypeEnum }>>()
	);
	export const save = createAction(OrganizationActionEnum.SAVE, props<BaseStateModel<OrganizationStateModel>>());
	export const saveSuccess = createAction(
		OrganizationActionEnum.SAVE_SUCCESS,
		props<BaseStateModel<OrganizationStateModel>>()
	);
	export const update = createAction(OrganizationActionEnum.UPDATE, props<BaseStateModel<OrganizationStateModel>>());
	export const reset = createAction(OrganizationActionEnum.RESET);
	export const error = createAction(OrganizationActionEnum.ERROR, props<BaseStateModel<any>>());
}
