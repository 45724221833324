<page-with-sidebar
	[sideBarPosition]="
		currentContext === contextApplicationItemCodeEnum.CRM ? sideBarPositionValues.RIGHT : sideBarPositionValues.NONE
	"
>
	<ng-container pageMain>
		<mat-card class="angelo-theme">
			<contact-list-wrapper
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="columns"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				[disabledOrderConfirmEmail]="currentContext === contextApplicationItemCodeEnum.B2B"
				(toggleHasNotification)="toggleHasNotification($event.row)"
				(onItemSelect)="openDialogContactDetail($event)"
				(openDialogConfirmRemoveContact)="openDialogConfirmRemoveContact($event)"
				(openDialogAddAction)="openAddAction($event)"
				(openDialogActionList)="openDialogActionList($event)"
				(openAnalytics)="openAnalytics($event)"
				(selectCompany)="selectCompany($event.level, $event.code)"
				[currentContext]="currentContext"
				[canDelete]="!!this.organizationId"
			>
			</contact-list-wrapper>
		</mat-card>
	</ng-container>

	<ng-container pageSide>
		<form class="angelo-theme" [formGroup]="formFilters" (ngSubmit)="onFormFiltersSubmit()">
			<div class="mb-3">
				<mat-checkbox formControlName="lead">Solo lead</mat-checkbox>
			</div>

			<form-button-bar
				[align]="'right'"
				[primaryButtonLabel]="'general.apply_filters' | translate | sentencecase"
				(primaryButtonClickEmitter)="onFormFiltersSubmit()"
				[secondaryButtonLabel]="'general.clean' | translate | sentencecase"
				[secondaryButtonIsDisabled]="!hasChange"
				(secondaryButtonClickEmitter)="resetFilters()"
			></form-button-bar>
		</form>
	</ng-container>
</page-with-sidebar>

<button
	mat-fab
	*ngIf="showAddContactFab()"
	class="mat-fab-button"
	(click)="openDialogAddContact()"
	[matTooltip]="'contact.add_new' | translate | sentencecase"
	matTooltipPosition="left"
>
	<mat-icon>add</mat-icon>
	<span class="label">{{ 'contact.add_new' | translate | sentencecase }}</span>
</button>
