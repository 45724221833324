import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import { Store } from '@ngrx/store';
import { BaseStateModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { AuxiliaryTableStateModel, OrganizationStateModel, UserDetailModel } from '@saep-ict/angular-spin8-core';
import { LocalListHandlerBaseModel, Category, CategoryMap, ArticlePouchModel, OrganizationPouchModel, DivisionPouchModel } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import { debounceTime, filter, mergeMap, Observable } from 'rxjs';
import { ConfigurationCategory } from '../../../constants/category.constant';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';
import { ConfigurationSubscribeManager } from '../../../constants/subscribe-manager.constant';
import { CategoryModel } from '../../../model/category-list.model';
import { SubscribeManagerItem } from '../../../model/subscribe-manager.model';
import { StoreUtilService } from '../../../service/util/store-util.service';
import { UtilCategoryListService } from '../../../service/util/util-category-list.service';
import { StateFeature } from '../../../state';
import { ArticleActionEnum } from '../../../state/article/article.actions';
import { CategoryListActionEnum } from '../../../state/category-list/category-list.actions';
import { OrganizationListActionEnum } from '../../../state/common/organization-list/organization-list.actions';
import { BodyTablePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';

@Component({
	selector: 'backoffice-forecast',
	templateUrl: './backoffice-forecast.component.html',
	styleUrls: ['./backoffice-forecast.component.scss'],
	providers: [SubscribeManagerService]
})
export class BackofficeForecastComponent implements OnInit, OnDestroy {

	@ViewChild('inputSearchAreaCommercial') inputSearchAreaCommercial: ElementRef;
	@ViewChild('inputSearchClientCode') inputSearchClientCode: ElementRef;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	organizationList$: Observable<BaseStateModel<OrganizationStateModel[]>> = this.store.select(
		StateFeature.getOrganizationListState
	);
	organizationList: OrganizationStateModel[];
	organizationListFiltered: OrganizationStateModel[];

	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> =
		this.store.select(StateFeature.getAuxiliaryTableState);
	auxiliaryTableAreaCommercial: BodyTablePouchModel[];

	categoryList$: Observable<BaseStateModel<CategoryMap>> = this.store.select(StateFeature.getCategoryListState);
	categoryList: Category[];
	categoryListParseVisualization: Category[];

	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);
	articleList: ArticlePouchModel[];
	listPageBaseData: LocalListHandlerBaseModel<ArticlePouchModel, CategoryModel.List>;

	configurationCustomer = ConfigurationCustomer;

	form: FormGroup;
	formDateSelectorList: number[] = ConfigurationCustomer.Forecast.returnDateSelectorList();

	constructor(
		private store: Store,
		private subscribeManagerService: SubscribeManagerService,
		private utilStoreService: StoreUtilService,
		private utilCategoryListService: UtilCategoryListService,
		private fb: FormBuilder,
		private utilBreadcrumbService: UtilBreadcrumbService
	) {
		this.formCreate();
		this.loadStaticData();
		const subscribeList: SubscribeManagerItem[] = [
			{ key: 'data', observable: this.subscribeData() },
			{ key: 'form-change', observable: this.subscribeFormChange() }
		];
		ConfigurationSubscribeManager.init(subscribeList, this.subscribeManagerService);

		this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['survey_center','survey_center_forecast']);
		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('survey_center');
		this.utilBreadcrumbService.subtitle.value = this.utilBreadcrumbService.getBreadcrumbTitle(
			'survey_center_forecast'
		);
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	loadStaticData() {
		this.utilStoreService.retrieveSyncState<UserDetailModel>(this.user$).subscribe(res => {
			this.user = res.data;
		});
		this.utilStoreService.retrieveSyncState<AuxiliaryTableStateModel>(this.auxiliaryTable$).subscribe(res => {
			this.auxiliaryTableAreaCommercial = res.data.areaCommercial;
		});
	}

	subscribeData(): Observable<void> {
		return this.organizationList$.pipe(
			filter((e: BaseStateModel<OrganizationPouchModel[]>) => !!(e && e.data)),
			mergeMap((e: BaseStateModel<OrganizationPouchModel[]>) => {
				switch (e.type) {
					case OrganizationListActionEnum.UPDATE:
						this.organizationList = e.data;
						this.organizationListFiltered = _.cloneDeep(e.data);
						break;
					case OrganizationListActionEnum.ERROR:
						throw new Error(OrganizationListActionEnum.ERROR);
					default:
						break;
				}
				return this.categoryList$;
			}),
			filter((e: BaseStateModel<CategoryMap>) => e && e.type !== CategoryListActionEnum.LOAD_ALL),
			mergeMap((e: BaseStateModel<CategoryMap>) => {
				switch (e.type) {
					case CategoryListActionEnum.ERROR:
						throw new Error(CategoryListActionEnum.ERROR);
					default:
						this.categoryList = e.data.tree;

				}
				return this.articleList$;
			}),
			filter((e: BaseStateModel<ArticlePouchModel[]>) => e && e.type !== ArticleActionEnum.LOAD_FROM_RECAP),
			mergeMap(async (e: BaseStateModel<ArticlePouchModel[]>) => {
				switch (e.type) {
					case CategoryListActionEnum.ERROR:
						throw new Error(CategoryListActionEnum.ERROR);
					case ArticleActionEnum.UPDATE:
						this.articleList = e.data;
						this.categoryListParseVisualization =
							await ConfigurationCustomer.Forecast.returnNestedCategoryParse(
								this.user, this.categoryList
							);
						const selectLevelBy: CategoryModel.SelectLevelBy =
							_.cloneDeep(
								ConfigurationCustomer.Forecast.categoryTabOnTopConfiguration.initialization.selectLevelBy
							);
						const categorySelectBy: Category =
							await ConfigurationCategory.returnCategorySelectBy(selectLevelBy, this.categoryList);
						this.listPageBaseData = {
							pageName: 'order-detail',
							filters: {
								localSearchText: {
									value: null,
									key_list: ConfigurationCustomer.AppStructure.Erp.has_erp
										? [
												'articleDescription.language_list.description',
												'code_erp',
												'articleDescription.relatedArticleTester.code_erp'
										]
										: [
												'articleDescription.language_list.description',
												'code_item',
												'articleDescription.relatedArticleTester.code_item'
										]
								},
								customFilters: {
									categoryList: [categorySelectBy]
								}
							},
							sort: {
								name: 'articleDescription.sequence',
								order: TdDataTableSortingOrder.Ascending
							},
							columnList:
								ConfigurationCustomer.Forecast.returnArticleColumnList(
									this.user.current_permission.context_application
								),
							data:
								this.utilCategoryListService.returnArticleListFilteredByCategoryList(
									this.articleList,
									categorySelectBy ? [categorySelectBy.code_item] : []
								)
						};
				}
			})
		);
	}

	subscribeFormChange(): Observable<void> {
		return this.form.valueChanges.pipe(
			debounceTime(500),
			mergeMap(async e => {
				this.organizationListFiltered = await this.returnOrganizationListFiltered(e.areaCommercial);
				this.form.get('organizationList').setValue(
					await this.returnFormOrganizationListValue(),
					{ emitEvent: false }
				);
			})
		);
	}

	async categorySelectedChange(e: Category) {
		this.listPageBaseData.data =
			e.code_item ?
			this.utilCategoryListService.returnArticleListFilteredByCategoryList(this.articleList, [e.code_item]) :
			this.articleList;
		const categorySelected: Category =
			await ConfigurationCategory.returnCategorySelectByPropertyValue(this.categoryList, 'code_item', e.code_item);
		this.listPageBaseData.filters.customFilters.categoryList = categorySelected ? [categorySelected] : [];
		this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
	}

	formCreate() {
		this.form = this.fb.group({
			dateSelector: [this.formDateSelectorList[0]],
			areaCommercial: [],
			organizationList: []
		});
	}

	formReset() {
		this.inputSearchAreaCommercial.nativeElement.value = null;
		this.inputSearchClientCode.nativeElement.value = null;
		this.form.patchValue({
			areaCommercial: [],
			organizationList: []
		});
	}

	returnOrganizationListFiltered(areaCommercialCodeItemList: string[]): Promise<OrganizationStateModel[]> {
		return new Promise(resolve => {
			try {
				let organizationListFiltered: OrganizationStateModel[];
				if (areaCommercialCodeItemList && areaCommercialCodeItemList.length > 0) {
					organizationListFiltered = this.organizationList.filter(i => {
						const division: DivisionPouchModel =
							i.division_list.find(d => areaCommercialCodeItemList.includes(d.area_commercial));
						return division ? true : false;
					});
				} else {
					organizationListFiltered = this.organizationList;
				}
				organizationListFiltered = _.cloneDeep(organizationListFiltered);
				resolve(organizationListFiltered);
			} catch(err) {
				throw new Error(err);
			}
		});
	}

	returnFormOrganizationListValue(): Promise<string[]> {
		return new Promise(resolve => {
			try {
				const organizationListValue: string[] = this.form.get('organizationList').value;
				const organizationListValueReturn: string[] = [];
				if (organizationListValue && organizationListValue.length > 0) {
					for (const codeItem of organizationListValue) {
						const organization = this.organizationListFiltered.find(i => i.code_item === codeItem);
						if (organization) {
							organizationListValueReturn.push(codeItem);
						}
					}
				}
				resolve(organizationListValueReturn);
			} catch(err) {
				throw new Error(err);
			}
		});
	}

}
