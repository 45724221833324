import { Injectable } from '@angular/core';

// model
import { ArticleRecapArticleList, ArticleRecap } from '../../model/state/article-list-state.model';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';
import { ArticleListFilterModel } from '../../service/pouch-db/filter/article-list-filter.model';
import { PouchErrorResponse } from '../../service/pouch-db/model/pouch-base-response.model';
import { ArticlePouchModel, DestinationPouchModel, OrganizationTypeEnum } from '@saep-ict/pouch_agent_models';

// store
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ArticleActionEnum, ArticleStateAction } from './article.actions';

// misc
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { UtilArticleKitService } from '../../service/util/util-article-kit.service';
import { CustomerAppConfig } from '../../customer-app.config';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';
import { UtilOrderService } from '../../service/util/util-order.service';
import { StateFeature } from '..';
import { PouchDbModel, AngularSpin8CoreCatalogService } from '@saep-ict/angular-spin8-core';
import { PouchDbCommonsAdapter } from '../../service/pouch-db/spin8/pouchdb-commons.adapter';
import { ConfigurationCustomer } from '../../constants/configuration-customer';
import { ArticleRecapLoadFilter } from '../../model/article.model';
import { AppUtilService } from '../../service/util/app-util.service';
import { PouchDbAgentAdapter } from '../../service/pouch-db/spin8/pouchdb-agent.adapter';
import { PouchDbBackofficeAdapter } from '../../service/pouch-db/spin8/pouchdb-backoffice.adapter';
import { PouchDbCommonsAgentAdapter } from '../../service/pouch-db/spin8/pouchdb-commons-agent.adapter';
import { PouchDbCommonsArticleAdapter } from '../../service/pouch-db/spin8/pouchdb-commons-article.adapter';
import { PouchDbCommonsBackofficeAdapter } from '../../service/pouch-db/spin8/pouchdb-commons-backoffice.adapter';
import { PouchDbCommonsContactAdapter } from '../../service/pouch-db/spin8/pouchdb-commons-contact.adapter';
import { PouchDbCommonsCrmAdapter } from '../../service/pouch-db/spin8/pouchdb-commons-crm.adapter';
import { PouchDbCommonsOrderAdapter } from '../../service/pouch-db/spin8/pouchdb-commons-order.adapter';
import { PouchDbCommonsOrganizationAdapter } from '../../service/pouch-db/spin8/pouchdb-commons-organization.adapter';
import { PouchDbCommonsRequestAdapter } from '../../service/pouch-db/spin8/pouchdb-commons-request';
import { PouchDbCommonsUserAdapter } from '../../service/pouch-db/spin8/pouchdb-commons-user.adapter';
import { PouchDbCrmAdapter } from '../../service/pouch-db/spin8/pouchdb-crm.adapter';
import { PouchDbOrganizationB2BAdapter } from '../../service/pouch-db/spin8/pouchdb-organization-b2b.adapter';
import { PouchDbOrganizationB2CAdapter } from '../../service/pouch-db/spin8/pouchdb-organization-b2c.adapter';
import { PouchDbOrganizationPortalAdapter } from '../../service/pouch-db/spin8/pouchdb-organization-portal.adapter';
import { PouchDbSpin8AgentAdapter } from '../../service/pouch-db/spin8/pouchdb-spin8-agent.adapter';

@Injectable()
export class ArticleEffects {
	updateArticle$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleActionEnum.UPDATE_ARTICLE),
			mergeMap(action =>
				of(action).pipe(
					withLatestFrom(this.store.select(StateFeature.getArticleList)),
					mergeMap(([article, articleList]) => of(this.updateSingleArticle(article, articleList)))
				)
			),
			map((articleList: BaseStateModel<ArticlePouchModel[]>) => ArticleStateAction.update(articleList))
		)
	);

	loadFromRecap$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleActionEnum.LOAD_FROM_RECAP),
			mergeMap((action: BaseStateModel<ArticleRecap>) => from(this.getArticleListFromRecap(action))),
			mergeMap((action: BaseStateModel<ArticlePouchModel[]>) => from(this.mergeArticleRecapDescription(action))),
			map((articleList: BaseStateModel<ArticlePouchModel[]>) => ArticleStateAction.update(articleList)),
			catchError((error, caught) => {
				this.store.dispatch(ArticleStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private utilArticleKitService: UtilArticleKitService,
		private appConfig: CustomerAppConfig,
		private catalogService: AngularSpin8CoreCatalogService,
		private pouchAdapterSelectorService: PouchAdapterSelectorService,
		private utilOrderService: UtilOrderService,
		private pouchDbCommonsAdapter: PouchDbCommonsAdapter,
		private utilService: AppUtilService
	) {}

	async getArticleListFromRecap(
		action: BaseStateModel<ArticleRecap, ArticleRecapLoadFilter>
	): Promise<BaseStateModel<ArticlePouchModel[]>> {
		return new Promise(async (resolve, reject) => {
			try {
				if (this.appConfig.authenticationToken && action && action.dataSetting && action.dataSetting.appliedFilter) {
						resolve(await this.returnArticleRecapFromFallbackSequence(action));
				} else {
					this.catalogService
						.getArticlesDefault({ organization_type: ConfigurationCustomer.Article.articleRecapDefaultPublic })
						.then(res => resolve(res.data.article_list));
				}
			} catch(err) {
				console.log(err);
				throw new Error(err);
			}
		})
			.then((res: ArticleRecapArticleList[]) => {
				return new BaseState(res);
			})
			.catch(err => {
				throw new Error(err);
			});
	}

	async getArticleRecapDefault(organization_type: OrganizationTypeEnum): Promise<BaseStateModel<ArticleRecap>> {
		return this.pouchDbCommonsAdapter.basePouch
			.getDetail('article_recap' + ConfigurationCustomer.AppStructure.noSqlDocSeparator + 'default_' + organization_type)
			.then((doc: ArticleRecap) => {
				return new BaseState(doc);
			})
			.catch(err => {
				throw new Error(err.message);
			});
	}

	async mergeArticleRecapDescription(
		action: BaseStateModel<ArticlePouchModel[], ArticleListFilterModel>
	): Promise<BaseStateModel<ArticlePouchModel[], ArticleListFilterModel>> {
		action.data = this.utilOrderService.mergeArticleRecapDescription<ArticlePouchModel>(action.data);
		return action;
	}

	async mergeArticleKitDetails(
		action: BaseStateModel<ArticlePouchModel[], ArticleListFilterModel>
	): Promise<BaseStateModel<ArticlePouchModel[], ArticleListFilterModel>> {
		// TODO: il seguente non è più allineato col modello univoco dell'article
		// da rivedere in toto una volta ridefinita la gestione

		// ciclo tutti i prodotti
		// for (let i = 0; i < action.data.length; i++) {
		// 	action.data[i].articleKitList = [];

		// 	// // se hanno i kit
		// 	if (action.data[i].art_kit === 'S') {
		// 		// recupero il dettaglio
		// 		await this.utilArticleKitService
		// 			.getArticleKitDetail(action.data[i], action.dataSetting.appliedFilter.organization)
		// 			.then((res: ArticleState[]) => {
		// 				action.data[i].articleKitList = res;
		// 			})
		// 			.catch((err: PouchErrorResponse) => {
		// 				console.log(err, 'article kit not found');
		// 				return null;
		// 			});
		// 	}
		// }

		return action;
	}

	async mergeCategoryInformation(
		action: BaseStateModel<ArticlePouchModel[], ArticleListFilterModel>
	): Promise<BaseStateModel<ArticlePouchModel[], ArticleListFilterModel>> {
		// TODO: il seguente non è più allineato col modello univoco dell'article
		// da rivedere in toto una volta ridefinita la gestione
		// for (let i = 0; i < action.data.length; i++) {
		// 	action.data[i].categoryNodeList = [
		// 		action.dataSetting.appliedFilter.article.categoryList.find(
		// 			f => f.code_item === action.data[i].code_item
		// 		)
		// 	];
		// }
		return action;
	}

	updateSingleArticle(
		article,
		articleList: BaseStateModel<ArticlePouchModel[]>
	): BaseStateModel<ArticlePouchModel<never>[], never> {
		if (article && article.data && articleList && articleList.data) {
			articleList.data.map(art => (art.code_item === article.data.code_item ? article : art));
		}
		return articleList;
	}

	returnArticleRecapFromFallbackSequence(action: BaseStateModel<ArticleRecap, ArticleRecapLoadFilter>):
	Promise<ArticlePouchModel[]> {
		return new Promise(async resolve => {
			const organization = action.dataSetting.appliedFilter.organization;
			const fallbackSequence:
				{
					key: string,
					adapter:
						PouchDbAgentAdapter
						| PouchDbSpin8AgentAdapter
						| PouchDbOrganizationB2CAdapter
						| PouchDbOrganizationB2BAdapter
						| PouchDbOrganizationPortalAdapter
						| PouchDbBackofficeAdapter
						| PouchDbCrmAdapter
						| PouchDbCommonsOrganizationAdapter
						| PouchDbCommonsArticleAdapter
						| PouchDbCommonsOrderAdapter
						| PouchDbCommonsUserAdapter
						| PouchDbCommonsAgentAdapter
						| PouchDbCommonsBackofficeAdapter
						| PouchDbCommonsContactAdapter
						| PouchDbCommonsRequestAdapter
						| PouchDbCommonsCrmAdapter
						| PouchDbCommonsAdapter
				}[] = [];
			const retrieveCurrentAdapterArticle =
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter(PouchDbModel.PouchDbDocumentType.ARTICLE);
			if (action.dataSetting.appliedFilter.destinationCodeItem) {
				fallbackSequence.push({
					key: `${organization.code_item}_${action.dataSetting.appliedFilter.destinationCodeItem}`,
					adapter: retrieveCurrentAdapterArticle
				});
			} else {
				if (organization.destination_list && organization.destination_list.length > 0) {
					const destinationMain =
						this.utilService.returnIsMainOfList<DestinationPouchModel>(organization.destination_list);
					if (destinationMain && destinationMain.code_item) {
						fallbackSequence.push({
							key: `${organization.code_item}_${destinationMain.code_item}`,
							adapter: retrieveCurrentAdapterArticle
						});
					}
				}
			}
			fallbackSequence.push({
				key: organization.code_item,
				adapter: retrieveCurrentAdapterArticle
			});
			if (organization.organization_type) {
				fallbackSequence.push({
					key: `default_${organization.organization_type}`,
					adapter: this.pouchDbCommonsAdapter
				});
			}
			fallbackSequence.push({
				key: ConfigurationCustomer.Article.articleRecapDefaultPublic,
				adapter: this.pouchDbCommonsAdapter
			});
			if (fallbackSequence.length > 0) {
				let resolved = false;
				for (const declination of fallbackSequence) {
					const _id = `article_recap${ConfigurationCustomer.AppStructure.noSqlDocSeparator}${declination.key}`;
					await declination.adapter.basePouch.getDetail<ArticleRecap>(_id)
						.then(result => {
							resolve(result.article_list);
							resolved = true;
						})
						.catch(async err => {
							if (err.status !== 404) {
								console.log(err);
								throw new Error(err.message);
							}
						});
					if (resolved) {
						break;
					}
				}
			}
		});
	}

}
