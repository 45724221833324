import { Component } from '@angular/core';
import { BaseStateModel, LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppUtilService } from '../../../service/util/app-util.service';
import { Observable, take } from 'rxjs';
import { AuxiliaryTableStateModel } from '@saep-ict/angular-spin8-core';
import { StateFeature } from '../../../state';
import { StoreUtilService } from '../../../service/util/store-util.service';
import { BodyTablePouchModel } from '@saep-ict/pouch_agent_models';
@Component({
	selector: 'destination-list-wrapper',
	templateUrl: './destination-list-wrapper.component.html',
	styleUrls: ['./destination-list-wrapper.component.scss']
})
export class DestinationListWrapperComponent extends LocalListHandlerWidgetWrapper {


	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);
	auxiliaryTableAreaCommercial: BodyTablePouchModel[];

	constructor(
		public utilService: AppUtilService,
		private utilStoreService: StoreUtilService,
		public translateService: TranslateService,
		public store: Store
	) {
		super(utilService, translateService, store);

		this.utilStoreService.retrieveSyncState<AuxiliaryTableStateModel>(this.auxiliaryTable$).subscribe(res => {
			this.auxiliaryTableAreaCommercial = res.data.areaCommercial;
		});
	}
}
