// model
import { ContextApplicationItemCodeEnum, OrderStateModel, ROUTE_URL } from '@saep-ict/angular-spin8-core';
import { OrderStatusEnum, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import moment from 'moment';
import { OrderDetailAsideHelpComponent } from '../../page/commons/order/new-order-detail/aside/order-detail-aside-help/order-detail-aside-help.component';
import { OrderDetailAsideSummaryComponent } from '../../page/commons/order/new-order-detail/aside/order-detail-aside-summary/order-detail-aside-summary.component';

// TOFIX: capire come gestire questa costante
export const OrderBackoffice = [
	{
		status: OrderStatusEnum.TO_AUTHORIZE,
		can_edit: true
	}
];

export namespace ConfigurationOrder {
	export const detailAsideComponent =
		new Map<string, any>([
			[ ROUTE_URL.checkout, OrderDetailAsideSummaryComponent ],
			[ ROUTE_URL.catalog, OrderDetailAsideSummaryComponent ],
			// TODO: trasferire insieme agli altri segmenti
			[ "header-edit", OrderDetailAsideHelpComponent ]
		]);

	export const returnLastUrlSegment = (url: string): string => {
		const a: string[] = url.split("/");
		const s: string = a[a.length - 1];
		return s;
	}

	export const listFilter = (
		orderList: OrderStateModel[],
		contextApplication: ContextApplicationItemCodeEnum,
		organization: OrganizationPouchModel
	): OrderStateModel[] => {
		switch (contextApplication) {
			case ContextApplicationItemCodeEnum.BACKOFFICE:
				return organization
					? orderList.filter(
							order =>
								order.header?.organization?.code_item === organization.code_item &&
								//order.header.organization.code_item === organization.code_item &&
								order.header.status !== OrderStatusEnum.DRAFT
					  )
					: orderList.filter(order => order.header.status !== OrderStatusEnum.DRAFT);
			case ContextApplicationItemCodeEnum.B2B:
				return orderList;
			case ContextApplicationItemCodeEnum.B2C:
				return orderList.filter(order => order.header.status !== OrderStatusEnum.DRAFT);
			default:
				return organization
					? orderList.filter(order => order.header?.organization?.code_item === organization.code_item)
					: orderList;
		}
	};

	export const ShippingChargeFilterList: string[] = ['carrier'];

	export namespace UtilDate {
		export const today: Date = new Date()
		export const minDate: Date = new Date(
			new Date().setDate(
				ConfigurationOrder.UtilDate.today.getHours() - 12 > 0 ?
				ConfigurationOrder.UtilDate.today.getDate() + 1 :
				ConfigurationOrder.UtilDate.today.getDate()
			)
		);
		export const addDays = (maxDays: number) =>
			moment(ConfigurationOrder.UtilDate.today).add(maxDays, 'days');
		export const getFirstValidDate = (currentDate: Date, day: number): Date => {
			const currentDay = moment(currentDate).day();
			if ([0, 6].includes(currentDay)) {
				const oneDayAfter = moment(currentDate).add(day, 'day').toDate();
				return ConfigurationOrder.UtilDate.getFirstValidDate(oneDayAfter, day);
			} else {
				return currentDate;
			}
		}
		/**
		 * Restituisce la prima data disponibile in base ai parametri immessi:
		 * @param dayMin : numero minimo di giornate non selezionabili a partire dala data corrente
		 * @param excludeDayList : giorni della settimana da ecludere (0 -> Dom, 1 -> Lun, ecc). Il metodo aggiunge 1 giorno
		 * ogni volta che capita su una data esclusa per via di excludeDayList
		 * @returns
		 */
		export const returnFirstValidDate = (dayMin: number, excludeDayList?: number[]): Date => {
			const dateMin = moment(today).add(dayMin, 'day');
			const dateMinDayNumber = dateMin.day();
			if (excludeDayList && excludeDayList.includes(dateMinDayNumber)) {
				return ConfigurationOrder.UtilDate.returnFirstValidDate(dayMin + 1, excludeDayList);
			}
			return dateMin.toDate();
		}
	}
}
/**
 * Last orders in dashboard
 */
export namespace ConfigurationLastOrders {
	export const listFilter = (
		orderList: OrderStateModel[],
		contextApplication: ContextApplicationItemCodeEnum
	): OrderStateModel[] => {
		switch (contextApplication) {
			default:
				// exclude status: DRAFT, FULFILLED
				return orderList.filter(
					order =>
						!([OrderStatusEnum.DRAFT, OrderStatusEnum.FULFILLED] as OrderStatusEnum[]).includes(
							order.header.status
						)
				);
		}
	};
}
