import { Validators } from "@angular/forms";
import { FormControlMultipurposeEnum, FormControlMultipurposeModel } from "@saep-ict/angular-core";
import { ConfigurationOrderExtend } from "../configuration-customer/order/order.constants";
import { ConfigurationOrder } from "./order.constants";

export const OrderHeaderBase: FormControlMultipurposeModel.Item[] = [
	{
		name: 'order_causal',
		on_form_template: true,
		type: FormControlMultipurposeEnum.ItemType.SELECT,
		label: 'theme.order.field.causal',
		column_width: 3,
		validator_list: [Validators.required],
		selectFilterTextShow: true,
		selectFilterTextPlaceholder: 'causal.filter'
	},
	{
		name: 'first_evasion_date',
		type: FormControlMultipurposeEnum.ItemType.DATE,
		on_form_template: true,
		label: 'theme.order.field.delivery_date',
		column_width: 3,
		validator_list: [Validators.required],
		dateMin: ConfigurationOrder.UtilDate.minDate,
		dateMax:
			ConfigurationOrder.UtilDate.addDays(ConfigurationOrderExtend.UtilDate.firstEvasionDateMaxDate),
		dateFilter: ConfigurationOrderExtend.UtilDate.matDatepickerFilter.due_date
	},
	{
		name: 'payment_code',
		type: FormControlMultipurposeEnum.ItemType.SELECT,
		on_form_template: true,
		label: 'order.field.payment_condition',
		column_width: 6,
		validator_list: [Validators.required],
		selectFilterTextShow: true
	},
	{
		name: 'goods_destination_code',
		type: FormControlMultipurposeEnum.ItemType.SELECT,
		on_form_template: true,
		label: 'theme.order.field.shipping_address',
		column_width: 6,
		validator_list: [Validators.required]
	},
	{
		name: 'note_order',
		type: FormControlMultipurposeEnum.ItemType.STRING ,
		on_form_template: true,
		label: 'theme.order.field.note',
		column_width: 12,
	}
];