import { FormGroup } from '@angular/forms';
import moment from 'moment';

export namespace FormValidatorCustom {
  /**
   * Restituisce errore nel caso in cui la data non sia oltre o uguale a quella minima oppure il giorno della
   * settimana sia marcato come da escludere
   * @param dayMin : data minima
   * @param excludeDayList : giorni della settimana da escludere (0 -> Dom, 1 -> Lun, ecc)
   * @returns 
   */
  export const isDateFarEnough = (dayMin: number, excludeDayList?: number[]) => {
    return (c:FormGroup) => {
      if (c && c.value) {
        const today: Date = new Date();
        const dateMin = moment(today).add(dayMin, 'day');
        const enoughFar: boolean = moment(c.value).diff(dateMin, 'd', false) >= 0;
        if (
          !enoughFar ||
          (excludeDayList && excludeDayList.includes(moment(c.value).day()))
        ) {
          return { valid: false }
        }
      }
      return null;
    }
  }
}