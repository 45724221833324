import { BaseTableListService, ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class OpportunityListColumnService extends BaseTableListService {
	constructor(@Inject(TranslateService) public translate: TranslateService) {
		super();
	}
	public _columns: ITdDataTableColumnCustom[] = [
		{
			name: 'title',
			labelPath: 'saep_ict_angular_spin8_core.general.title',
			label: null
		},
		{
			name: 'contact_name',
			labelPath: 'opportunity.field.referent',
			label: null
		},
		{
			name: 'phone',
			labelPath: 'contact.field.phone',
			label: null
		},
		{
			name: 'organization',
			labelPath: 'saep_ict_angular_spin8_core.page.organization.name',
			label: null
		},
		{
			name: 'date_creation',
			labelPath: 'opportunity.field.date_creation',
			label: null
		},
		{
			name: 'assegnee_full_name',
			labelPath: 'opportunity.field.assegnee',
			label: null
		},
		{
			name: 'opportunity_type',
			labelPath: 'opportunity.field.type',
			label: null,
			width: 120
		},
		// {
		// 	name: 'context_application',
		// 	labelPath: 'opportunity.field.context_application',
		// 	label: null
		// },
		{
			name: 'status_code',
			labelPath: 'opportunity.field.status_code',
			label: null,
			numeric: true
		},
		{
			name: 'actions',
			label: '',
			width: 100,
			numeric: true,
			sortable: false
		}
		// {
		// 	name: 'actual_value',
		// 	labelPath: 'opportunity.field.actual_value',
		// 	label: null,
		// 	numeric: true
		// }
	];
}
