<page-with-sidebar [isRoot]="true" [sideBarPosition]="sideBarPositionValues.NONE">
	<ng-container pageMain>
		<mat-card class="angelo-theme">
			<!-- Tabs | WIP (for associated users)-->
			<!-- <mat-tab-group (selectedTabChange)="filterCodesOnTabClick($event)">
				<mat-tab label="All code"></mat-tab>
				<mat-tab label="Associated code"></mat-tab>
				<mat-tab label="Blank code"></mat-tab>
			</mat-tab-group> -->
			<context-code-management-wrapper
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="permissionContextListColumnService.columns"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				(onItemSelect)="goToDetail($event)"
			>
			</context-code-management-wrapper>
		</mat-card>
	</ng-container>
</page-with-sidebar>

<button
	mat-fab
	color="accent"
	class="mat-fab-button"
	(click)="dialogCreateContextCodeItem(contextTypeAndApplicationLink.context_code_item)"
	[matTooltip]="
		('general.add_new' | translate | sentencecase) +
		' ' +
		(contextTypeAndApplicationLink.context_code_item.description | translate | sentencecase)
	"
	matTooltipPosition="left"
	*ngIf="
		configurationCustomer.UserManagement.canEditContextCode[
			contextApplicationItemCodeEnum[contextTypeAndApplicationLink.context_application_item_code]
		]
	"
>
	<mat-icon>add</mat-icon>
	<span class="label">{{
		('general.add_new' | translate | sentencecase) +
			' ' +
			(contextTypeAndApplicationLink.context_code_item.description | translate | sentencecase)
	}}</span>
</button>
