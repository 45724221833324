import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddressPouchModel } from '@saep-ict/pouch_agent_models';
import { ViewTypeEnum } from '../../../enum/view-type.enum';
import { B2cCheckoutComponent } from '../../../page/b2c/b2c-checkout/b2c-checkout.component';
import { ListMode } from '../../address-list/address-list.component';

@Component({
	selector: 'dialog-pick-address',
	templateUrl: './dialog-pick-address.component.html',
	styleUrls: ['./dialog-pick-address.component.scss']
})
export class DialogPickAddressComponent implements OnInit {
	ViewTypeEnum = ViewTypeEnum;
	ListMode = ListMode;
	title: string = this.data.modalTitle;

	constructor(public dialogRef: MatDialogRef<B2cCheckoutComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

	ngOnInit() {}

	pick(pickedAddress: AddressPouchModel) {
		this.dialogRef.close(pickedAddress);
	}
}
