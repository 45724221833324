import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseStateModel, BaseState } from '@saep-ict/angular-core';
import { OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { StateFeature } from '../../../../state';
import { OrderStateAction } from '../../../../state/order/order.actions';
import { OperationState } from '../../../../widget/operation-state-banner/operation-state-banner.component';
import { ConfigurationCustomer } from '../../../../constants/configuration-customer';
import {
	OrderStateModel,
	ROUTE_URL,
	UserDetailModel,
	OrganizationStateModel,
	AngularSpin8CoreOrderService
} from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'b2c-checkout-transaction',
	templateUrl: './b2c-checkout-transaction.component.html',
	styleUrls: ['./b2c-checkout-transaction.component.scss']
})
export class B2cCheckoutTransactionComponent implements OnInit {
	transactionStatus: string | OperationState;
	transactionResult: OperationState;
	transactionData: any;

	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	constructor(
		private activatedRoute: ActivatedRoute,
		private store: Store<any>,
		private router: Router,
		private orderService: AngularSpin8CoreOrderService
	) {}

	ngOnInit(): void {
		this.order$.pipe(take(1)).subscribe((res: BaseStateModel<OrderStateModel>) => {
			this.order = res?.data || null;
		});
		this.organization$.pipe(take(1)).subscribe((res: BaseStateModel<OrganizationStateModel>) => {
			this.organization = res?.data || null;
		});
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res?.data || null;
		});

		this.transactionStatus = this.activatedRoute.snapshot.paramMap.get('transactionStatus');

		switch (this.transactionStatus) {
			case 'success':
				// Decrypt data
				// var CryptoJS = require('crypto-js');
				// this.activatedRoute.queryParams.subscribe(params => {
				// 	const encryptedTransactionData = params.transactionData;
				// 	const bytes = CryptoJS.AES.decrypt(encryptedTransactionData, encryptSecretKey);
				// 	if (bytes.toString()) {
				// 		this.transactionData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
				// 	}
				// });

				// const authorizedTransaction: boolean = this.transactionData &&
				// this.transactionData.orderId === this.order._id &&
				// this.transactionData.expirationDate >= new Date()
				const authorizedTransaction = true;

				if (authorizedTransaction) {
					this.order.header.status = ConfigurationCustomer.Order.orderToSendStatus;
					this.order.header.submission_date = new Date().getTime();
					this.store.dispatch(OrderStateAction.save(new BaseState(_.cloneDeep(this.order))));
					// New order notification
					this.sendOrderNotification();
					this.transactionResult = OperationState.success;
					break;
				} else {
					this.router.navigate([ROUTE_URL.public, ROUTE_URL.cart, ROUTE_URL.checkout]);
					break;
				}
			case 'error':
				this.store.dispatch(OrderStateAction.error(null));
				this.transactionResult = OperationState.error;
				break;
		}
	}

	// Send email notification of a new order
	sendOrderNotification() {
		this.orderService.sendOrderNotification({ id: this.order._id }).catch(err => {
			throw new Error(err);
		});
	}
}
