<div class="article-table-wrapper-base" class="{{columnWidthConfigurationClass}}" [ngClass]="{'sidebarclosed':sidebarClosed}">
	<div class="head-wrapper">
		<div
			*ngFor="
				let column of _localListHandlerData.columnList
					| filterByAllTheBooleans
						: {
								propertyListName: ['hidden'],
								value: false
						  }
			"
		>
			{{ column.labelPath | translate: column.labelPathParam | sentencecase }}
		</div>
	</div>
	<div class="rows-wrapper">
		<div *ngFor="let row of _localListHandlerData.dataSubset" class="content-wrapper">
			<div
				*ngFor="
					let column of _localListHandlerData.columnList
						| filterByAllTheBooleans
							: {
									propertyListName: ['hidden'],
									value: false
							  }
				"
			>
				<ng-container [ngSwitch]="column.name">
					<!-- Availability -->
					<div *ngSwitchCase="'cf_available'">
						<div class="chips-mini">
							<span
								[ngClass]="articleEnum.Status.Stock[row.articleDescription?.stock?.code_segnalation]"
								[matTooltip]="row.articleDescription?.stock?.description_segnalation"
							></span>
						</div>
					</div>
					<!-- code_item -->
					<div *ngSwitchCase="'code_item'">
						<ng-container
							*ngTemplateOutlet="code_; context: { code: row.code_item | guidFormatter, row: row }"
						></ng-container>
					</div>
					<!-- code_erp -->
					<div *ngSwitchCase="'code_erp'">
						<ng-container
							*ngTemplateOutlet="code_; context: { code: row.code_erp, row: row }"
						></ng-container>
					</div>
					<!-- Description -->
					<div *ngSwitchCase="'description'">
						<ng-template #descriptionValue let-value="value">
							<span [matTooltip]="value">{{ value }}</span>
						</ng-template>
						<ng-container
							*ngTemplateOutlet="
								descriptionValue;
								context: {
									value: utilTranslateService.getTranslationFromLanguage(
										row.articleDescription.language_list
									).description
								}
							"
						>
						</ng-container>
					</div>
					<!-- Actions -->
					<div *ngSwitchCase="'actions'">
						<ng-container *ngIf="configurationAction">
							<div class="actions-wrapper">
								<ng-container *ngIf="order && order.header.status === orderStatusEnum.DRAFT">
									<article-input-multiple-validation
										*ngIf="configurationAction.validationMultiple"
										[article]="row"
										[organization]="organization"
									>
									</article-input-multiple-validation>
								</ng-container>
								<div class="actions-buttons-wrapper">
									<button
										mat-icon-button
										*ngIf="
											configurationAction.note &&
											order &&
											order.header.status === orderStatusEnum.DRAFT && configurationCustomer.Order.noteOrderVisibility[user.current_permission.context_application]
										"
										(click)="articleNoteChange($event, row)"
										[matTooltip]="
											(row.note_order ? 'comment.read' : 'comment.add') | translate | sentencecase
										"
									>
										<!-- TODO:
									- modifica icona
									- opacità dinamica in base a presenza/assenza nota -->
										<mat-icon [ngClass]="{ comment: row.note_order, add_comment: !row.note_order }">
											{{ row.note_order ? 'comment' : 'add_comment' }}
										</mat-icon>
									</button>
									<button
										*ngIf="
											configurationAction.delete &&
											((order && order.header.status === orderStatusEnum.DRAFT) || !order)
										"
										mat-icon-button
										[matTooltip]="'general.delete' | translate | sentencecase"
										(click)="onDeleteArticle.emit(row)"
									>
										<mat-icon class="delete">delete</mat-icon>
									</button>
									<button
										mat-icon-button
										*ngIf="
											configurationAction.ticket &&
											order.header.status !== orderStatusEnum.DRAFT &&
											user.current_permission.context_application !==
												contextApplicationItemCodeEnum.BACKOFFICE
										"
										(click)="
											ticketCenterMainService.dialogCreateNew({
												body: {
													custom_field: {
														request_type:
															ticketCenterBodyCustomField.RequestTypeEnum
																.NOT_CONFORMITY_REPORT,
														organization_code_item: order.header.organization.code_item,
														organization_referent: user.first_name + ' ' + user.last_name,
														organization_referent_contact_address: user.username,
														order_code_item: order._id,
														article_code_item: configurationCustomer.AppStructure.Erp
															.has_erp
															? row.code_erp
															: row.code_item
													},
													reference: null,
													subject: null,
													message: null,
													attachment_list: null
												},
												id: null,
												request: null,
												status: null,
												thread: null
											})
										"
									>
										<mat-icon class="support_agent">support_agent</mat-icon>
									</button>
									<button
										*ngIf="configurationAction.gallery && row.tableMetaInformation.hasGalleryImage"
										mat-icon-button
										(click)="dialogGallery(row)"
									>
										<mat-icon class="image">image</mat-icon>
									</button>

									<mat-icon
										*ngIf="configurationAction.origin && row.articleDescription.origin"
										[matTooltip]="row.articleDescription.origin.description | sentencecase"
										class="public"
										>public</mat-icon
									>
								</div>
							</div>
						</ng-container>
					</div>
					<!-- Price Article -->
					<div *ngSwitchCase="'articlePrice.price'">
						{{ row.articlePrice.price | utilPriceReturnItemValueFormattedPipe }}<br />
						<small
							*ngIf="row.tableMetaInformation?.priceWithDiscountDescription"
							class="cell-subtitle d-block"
						>
							{{ 'general.discounted' | translate }}:
							{{ row.tableMetaInformation.priceWithDiscountDescription }}
						</small>
					</div>
					<!-- Discount Agent -->
					<div *ngSwitchCase="'discount_agent'">
						<article-change-input
							[configuration]="{
								visible: true,
								value: row.discount_agent ? row.discount_agent.value : null,
								disabled:
									row.articleDescription.free_sample === 'S' ||
									!row.input_quantity ||
									!(order && order.header.status === orderStatusEnum.DRAFT),
								maxValue: 100,
								key: 'discount_agent',
								row: row,
								dataSubset: _localListHandlerData.dataSubset,
								organization: organization,
								onArticleChange: onArticleChange,
								event: null,
								pageSize: localListHandlerDataGlobalSubset,
								visibleInputInRow:
									configurationCustomer.Order.commonCatalogVisibleInput[
										user.current_permission.context_application
									],
								keyboardNavigationEnabled:
									configurationCustomer.Article.articleChangeInputKeyboardNavigationEnabled[
										user.current_permission.context_application
									],
								maskEnabled:
									configurationCustomer.Article.articleChangeInputMask[
										user.current_permission.context_application
									]
							}"
						></article-change-input>
					</div>
					<!-- Discount Article Free -->
					<div *ngSwitchCase="'qty_free'">
						<article-change-input
							[configuration]="{
								visible: !(row.articleDescription.free_sample === 'S' || !row.input_quantity),
								value: row.qty_free ? row.qty_free : null,
								disabled: !(order && order.header.status === orderStatusEnum.DRAFT),
								maxValue: row.input_quantity,
								key: 'qty_free',
								row: row,
								dataSubset: _localListHandlerData.dataSubset,
								organization: organization,
								onArticleChange: onArticleChange,
								event: null,
								pageSize: localListHandlerDataGlobalSubset,
								visibleInputInRow:
									configurationCustomer.Order.commonCatalogVisibleInput[
										user.current_permission.context_application
									],
								keyboardNavigationEnabled:
									configurationCustomer.Article.articleChangeInputKeyboardNavigationEnabled[
										user.current_permission.context_application
									],
								maskEnabled:
									configurationCustomer.Article.articleChangeInputMask[
										user.current_permission.context_application
									]
							}"
						></article-change-input>
					</div>
					<!-- Quantity Input -->
					<div *ngSwitchCase="'input_quantity'">
						<article-change-input
							*ngIf="row.tableMetaInformation"
							[configuration]="{
								visible: true,
								value: row.input_quantity ? row.input_quantity : null,
								disabled: !row.tableMetaInformation.inputQuantityDisable,
								key: 'input_quantity',
								row: row,
								dataSubset: _localListHandlerData.dataSubset,
								organization: organization,
								onArticleChange: onArticleChange,
								event: null,
								pageSize: localListHandlerDataGlobalSubset,
								visibleInputInRow:
									configurationCustomer.Order.commonCatalogVisibleInput[
										user.current_permission.context_application
									],
								keyboardNavigationEnabled:
									configurationCustomer.Article.articleChangeInputKeyboardNavigationEnabled[
										user.current_permission.context_application
									],
								maskEnabled:
									configurationCustomer.Article.articleChangeInputMask[
										user.current_permission.context_application
									]
							}"
							[ngClass]="{
								'input-highlight':
									row.articleDescription.free_sample === 'S' && !row.articleDescription.is_tester
							}"
						></article-change-input>
					</div>
					<!-- Related Tester Quantity Input -->
					<div *ngSwitchCase="'articleDescription.relatedArticleTester.input_quantity'">
						<article-change-input
							[configuration]="{
								visible: row.articleDescription.relatedArticleTester ? true : false,
								value:
									row.articleDescription.relatedArticleTester &&
									row.articleDescription.relatedArticleTester.input_quantity
										? row.articleDescription.relatedArticleTester.input_quantity
										: null,
								disabled: false,
								key: 'input_quantity_related_tester',
								row: row,
								dataSubset: _localListHandlerData.dataSubset,
								organization: organization,
								onArticleChange: onArticleChange,
								event: null,
								pageSize: localListHandlerDataGlobalSubset,
								visibleInputInRow:
									configurationCustomer.Order.commonCatalogVisibleInput[
										user.current_permission.context_application
									],
								keyboardNavigationEnabled:
									configurationCustomer.Article.articleChangeInputKeyboardNavigationEnabled[
										user.current_permission.context_application
									],
								maskEnabled:
									configurationCustomer.Article.articleChangeInputMask[
										user.current_permission.context_application
									]
							}"
						></article-change-input>
						<small *ngIf="row.articleDescription.relatedArticleTester" class="cell-subtitle d-block">
							<!-- TODO: eventualmente ripristinare e trovare soluzione per non porre su più righe -->
							<!-- {{ 'article.field.sample_code' | translate | sentencecase }}: -->
							{{
								configurationCustomer.AppStructure.Erp.has_erp
									? row.articleDescription.relatedArticleTester.code_erp
									: row.articleDescription.relatedArticleTester.code_item
							}}
						</small>
					</div>
					<!-- calculate_price -->
					<div *ngSwitchCase="'calculate_price'">
						<!-- TODO: gestire currency dinamica -->
						{{ row.calculate_price | currency: '':'':'':'it' }}
					</div>
					<!-- forecast -->
					<div *ngSwitchCase="'forecast'">
						<forecast-input-list
							[configuration]="
								{
									data: forecastConfigurationInputList,
									article: row
								}
							"
							(onForecastChange)="onForecastChange.emit({ code_item: row.code_item, data: $event })"
						>
						</forecast-input-list>
					</div>
					<!-- default -->
					<div
						*ngSwitchDefault
						articleTableWrapperCellDefaultHandler
						[configuration]="{ row: row, name: column.name }"
					></div>
				</ng-container>
			</div>
		</div>
	</div>
</div>

<!-- TODO: valutare se ridondare la dinamica nelle due diverse celle code_item/erp serva a guadagnare qualcosa nelle
tempistiche di rendering -->
<ng-template #code_ let-code="code" let-row="row">
	<a *ngIf="onItemSelectShow; else codeWithoutLink" (click)="onItemSelect.emit(row)" href="javascript:void(0)">
		<ng-container *ngTemplateOutlet="codeWithoutLink"></ng-container>
	</a>
	<ng-template #codeWithoutLink>{{ code }}</ng-template>
</ng-template>
