import { Injectable } from '@angular/core';
import { ArticleChangeInputConfig } from '../../model/order-util.model';
import { ArticleChangeInputSelector } from '../../model/util.model';
import { ArticleChangeInputComponent } from '../../widget/article/article-change-input/article-change-input.component';

@Injectable({
	providedIn: 'root'
})
export class UtilInputNavigationService {

	inputNavigationMap: ArticleChangeInputSelector = {
		selected: null,
		list: []
	};
	constructor() {}

	returnItem(c: ArticleChangeInputConfig): ArticleChangeInputComponent {
		return this.inputNavigationMap.list.find(i => this.matchItemConfiguration(i, c));
	}

	returnItemIndex(c: ArticleChangeInputConfig): number {
		return this.inputNavigationMap.list.findIndex(i => this.matchItemConfiguration(i, c));
	}

	deleteItem(c: ArticleChangeInputConfig) {
		this.inputNavigationMap.list.splice(this.returnItemIndex(c), 1);
	}

	deleteAll() {
		this.inputNavigationMap = {
			selected: null,
			list: []
		};
	}

	selectItem(c: ArticleChangeInputConfig) {
		this.inputNavigationMap.selected = this.returnItem(c);
	}

	sortByDocumentPosition(c: ArticleChangeInputConfig) {
		if (
			this.inputNavigationMap.list.length ===
			c.pageSize *
			c.visibleInputInRow
		  ) {
			this.inputNavigationMap.list.sort((a, b) => {
				if (!a.inputTag || !b.inputTag) {
					return 0
				} else {
					// https://developer.mozilla.org/en-US/docs/Web/API/Node/compareDocumentPosition#return_value
					const position = a.inputTag.nativeElement.compareDocumentPosition(b.inputTag.nativeElement);
					return position === Node.DOCUMENT_POSITION_PRECEDING ? 1 : -1;
				}
			});
		}
	}

	goToByKeyboard(e: KeyboardEvent, c: ArticleChangeInputConfig) {
		if ('ArrowUp' || 'ArrowRight' || 'ArrowDown' || 'ArrowLeft') {
			const index: number = this.returnItemIndex(c);
			switch (e.key) {
				case 'ArrowUp':
					this.goToPreviousItem(index, this.inputNavigationMap.list[index].configuration.key);
					break;
				case 'ArrowRight':
					this.goToNextItem(index);
					break;
				case 'ArrowDown':
					this.goToNextItem(index, this.inputNavigationMap.list[index].configuration.key);
					break;
				case 'ArrowLeft':
					this.goToPreviousItem(index);
					break;
			}
		}
	}

	goToNextItem(index: number, key?: string) {
		for (let i = index + 1; i < this.inputNavigationMap.list.length; i++) {
			if (this.matchItemAvailability(this.inputNavigationMap.list[i], key)){
				this.inputNavigationMap.list[i].inputTag.nativeElement.focus();
				break;
			}
		}
	}

	goToPreviousItem(index: number, key?: string) {
		for (let i = index - 1; i >= 0; i--) {
			if (this.matchItemAvailability(this.inputNavigationMap.list[i], key)){
				this.inputNavigationMap.list[i].inputTag.nativeElement.focus();
				break;
			}
		}
	}

	// item/config test
	matchItemConfiguration(i: ArticleChangeInputComponent, c: ArticleChangeInputConfig): boolean {
		return i.configuration.row.code_item === c.row.code_item && i.configuration.key === c.key;
	}

	matchItemAvailability(i: ArticleChangeInputComponent, key?: string): boolean {
		return i &&
			i.configuration.visible &&
			!i.configuration.disabled &&
			(key ? key === i.configuration.key : true)
		;
	}

}
