<loading-overlay [loaderServiceInstance]="loaderService"></loading-overlay>
<!-- <div class="align-items-center">
	<div class="wrapper_page">
		<div class="header_cliente">
			<div class="logo-container"></div>
		</div>

		<mat-card>
			<div class="wrapper_header">
				<div class="header_spinotto">
					<img class="logo" src="/assets/common/img/logo/SPIN8/v2/logo-SPIN8-generic.svg" />
				</div>
			</div>
			<mat-card-content>
				<router-outlet></router-outlet>
			</mat-card-content>
		</mat-card>

		<div class="wrapper_footer">
			<div class="wrapper_footer_logo">
				<a class="logo_saep" href="https://www.saep-ict.it/" target="_blank">
					<img src="/assets/common/img/logo/saep-ict/logo-saep-ict-dark.svg" alt="logo SAEP ICT Engineering" />
				</a>
				<span class="version">ver {{ appTag }} - rel {{ appRelease }}</span>
			</div>
		</div>
	</div>
</div> -->

<div class="background">
	<div class="half white"></div>
	<div class="half black"></div>
</div>

<div class="authentication-wrapper">
	<div class="loghi-container side">
		<div class="logo-container"></div>
		<hr />
		<div class="logo-spin8" (click)="dialogShowCompanyInfo()">
			<mat-button>
				<div class="logo" role="button">
					<!-- <div class="svg"></div> -->
				</div>
			</mat-button>
			<!-- <img class="logo" src="/assets/common/img/logo/SPIN8/v2/logo-SPIN8-generic-icon.svg" /> -->
			<span class="version">Spin8 {{ 'login.general.version_abbreviated' | translate }}  {{ appTag }} - {{ 'login.general.release_abbreviated' | translate }} {{ appRelease }}</span>
			<span class="developedby">{{ 'login.general.developed_by' | translate | sentencecase }} {{ company.name }}</span>
		</div>
	</div>
	<div class="form-container">
		<mat-card class="angelo-theme">
			<mat-card-content>
				<router-outlet></router-outlet>
			</mat-card-content>
		</mat-card>
	</div>
	<div class="title-container side">
		<h3>{{ 'login.general.access_private_area' | translate | sentencecase }}</h3>
	</div>
</div>
