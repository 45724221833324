import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseStateModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { ViewTypeEnum } from '../../../../enum/view-type.enum';
import { ConfigurationViewModel } from '../../../../model/configuration.model';
import { StateFeature } from '../../../../state';
import { ItemType, ICardSliderConfiguration } from '../../../../widget/b2c/article/b2c-article-item/card-slider.models';
import { ConfigurationCustomer } from '../../../../constants/configuration-customer';
import { JumbotronConfig } from '../../../../widget/jumbotron/jumbotron.component';
import { LocalListHandlerBaseModel, Category, CategoryMap, ArticlePouchModel, DivisionPouchModel } from '@saep-ict/pouch_agent_models';
import { ArticleActionEnum } from '../../../../state/article/article.actions';
import { ArticleListFilterModel } from '../../../../service/pouch-db/filter/article-list-filter.model';
import { UtilPriceService } from '../../../../service/util/util-price.service';
import { AppUtilService } from '../../../../service/util/app-util.service';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationStateModel, ROUTE_URL } from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	providers: [SubscribeManagerService]
})
export class HomeComponent implements OnInit {
	@ViewChild('mapContainer') gmap: ElementRef;

	public viewTypeEnum = ViewTypeEnum;
	public ItemType = ItemType;

	jumbotronValue: JumbotronConfig = {
		title: `theme.jumbotron.title`,
		subtitle: 'theme.jumbotron.subtitle',
		labelButton: 'theme.jumbotron.labelButton',
		url: `${ROUTE_URL.catalog}/category_ORD_80`, // da RECUPERARE da couch tramite configurazione in evidenza
		backgroundImage: 'theme.jumbotron.backgroundImage', // da configurare manualmente
		hasBackdrop: true
	};

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	configuration$: Observable<BaseStateModel<ConfigurationViewModel>> = this.store.select(
		StateFeature.getConfigurationState
	);
	configuration: BaseStateModel<ConfigurationViewModel>;

	configurationReferenceDev = ConfigurationCustomer.Reference.Development;
	configurationReferenceCustomer = ConfigurationCustomer.Reference.Customer;

	lat = `${this.translateService.instant(this.configurationReferenceCustomer.Position.LAT)}`;
	long = `${this.translateService.instant(this.configurationReferenceCustomer.Position.LONG)}`;

	map: google.maps.Map;

	coordinates = new google.maps.LatLng(+this.lat, +this.long);
	mapOptions: google.maps.MapOptions = {
		center: this.coordinates,
		zoom: 10,
		disableDefaultUI: true,
		// scrollwheel: false,
		// disableDoubleClickZoom: true,
		// draggable: false,
		gestureHandling: 'cooperative'
	};

	marker = new google.maps.Marker({
		position: this.coordinates
	});

	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);

	categoryList$: Observable<BaseStateModel<CategoryMap, ArticleListFilterModel>> = this.store.select(
		StateFeature.getCategoryListState
	);
	categoryHighlightList: Category[] = [];

	listPageBaseData = <LocalListHandlerBaseModel<ArticlePouchModel>>{
		data: []
	};

	config: ICardSliderConfiguration;

	socialMediaList = Object.values(ConfigurationCustomer.Reference.Customer.SocialMedia);

	constructor(
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private utilPriceService: UtilPriceService,
		private utilService: AppUtilService,
		private translateService: TranslateService
	) {
		this.organization$.pipe(take(1)).subscribe(res => {
			this.organization = res && res.data ? res.data : null;
		});
		this.configuration$.pipe(take(1)).subscribe(res => {
			this.configuration = res ? res : null;
		});

		this.subscribeManagerService.populate(
			this.subscribeMainPipeData().subscribe(
				res => {},
				error => {
					console.log('something went wrong ', error);
				}
			),
			'main-pipe-data'
		);
		this.subscribeManagerService.populate(this.subscribeCategoryList().subscribe(), 'category-list-subscription');
	}

	ngOnInit() {}

	ngAfterViewInit() {
		this.mapInitializer();
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	subscribeMainPipeData(): Observable<ArticlePouchModel[]> {
		return this.articleList$.pipe(
			filter((store: BaseStateModel<ArticlePouchModel[]>) => !!(store && store.data)),
			map((store: BaseStateModel<ArticlePouchModel[]>) => {
				switch (store.type) {
					case ArticleActionEnum.ERROR:
						throw new Error(ArticleActionEnum.ERROR);
					case ArticleActionEnum.UPDATE:
						const mainDivision =
							this.organization && this.organization.division_list
								? this.utilService.returnIsMainOfList<DivisionPouchModel>(
										this.organization.division_list
								  )
								: null;
						this.listPageBaseData.data = this.utilPriceService.returnArticleListWithCalculatePriceForSingleItem(
							store.data.filter(i => i.articleDescription.visible && i.articleDescription.is_highlighted),
							mainDivision ? mainDivision.division : null
						);
						break;
				}
				return this.listPageBaseData.data;
			})
		);
	}

	subscribeCategoryList() {
		return this.categoryList$.pipe(
			filter(res => !!(res && res.data)),
			tap(res => {
				this.categoryHighlightList = res && res.data && res.data.is_highlighted ? res.data.is_highlighted : [];
				if (this.categoryHighlightList.length > 0) {
					this.config = {
						data: this.categoryHighlightList,
						medias: {
							sm: 1,
							md: 2,
							lg: 3
						},
						animation: {
							animationDuration: '.8s',
							easing: 'ease-in-out',
							loop: true
						}
					};
				}
			})
		);
	}

	mapInitializer() {
		this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
		this.marker.setMap(this.map);
	}

	getArray(n: number, startFrom: number = 0): number[] {
		const res = [...Array(n).keys()].map(i => i + startFrom);
		return res;
	}

	clickCallToAction() {
		window.location.href = `mailto:${this.translateService.instant(this.configurationReferenceCustomer.EMAIL)}`;
	}
}
