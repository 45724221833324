import { CurrencyPipe } from "@angular/common";
import { Injector } from "@angular/core";

const injector = Injector.create({
	providers: [
		{ provide: CurrencyPipe, deps: [] }
	]
});

const currencyPipe = injector.get(CurrencyPipe);

/**
 * Formatta il valore secondo Angular CurrencyPipe: le migliaia vengono separate da `.` i decimali da `,`
 * https://angular.io/api/common/CurrencyPipe
 *  
 * @param value 
 * @param decimalDigits indica il numero di decimali da mostrare. Default 2. Per nasconderli settare `0`
 * @returns 
 */
export const returnItemValueFormatted = (value: number, decimalDigits?: number): string => {
	let digitsInfo: string = '';
	if (decimalDigits || decimalDigits === 0) {
		digitsInfo = `1.${decimalDigits}-${decimalDigits}`
	}
	return currencyPipe.transform(
		value,
		'EUR',
		'',
		digitsInfo,
		'it'
	)
}