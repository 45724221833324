import { Injectable } from '@angular/core';
import { WorkBook, WorkSheet, utils, writeFile } from 'xlsx-js-style';
import { ConfigurationCustomer } from '../../constants/configuration-customer';
import { ContextApplicationItemCodeEnum, UserDetailModel } from '@saep-ict/angular-spin8-core';

// OrganizationExportExcel
import { OrganizationExportExcelBackoffice } from '../../constants/export-excel/organization/organization-export-excel-backoffice.constants';
import { OrganizationExportExcelAgent } from '../../constants/export-excel/organization/organization-export-excel-agent.constants';
import { OrganizationExportExcelCrm } from '../../constants/export-excel/organization/organization-export-excel-crm.constants';

// OrderExportExcel
import { OrderExportExcelAgent } from '../../constants/export-excel/order/order-export-excel-agent.constants';
import { OrderExportExcelBackoffice } from '../../constants/export-excel/order/order-export-excel-backoffice.constants';
import { OrderExportExcelB2B } from '../../constants/export-excel/order/order-export-excel-b2b.constants';
import { OrderExportExcelB2C } from '../../constants/export-excel/order/order-export-excel-b2c.constants';
import { OrderExportExcelPortal } from '../../constants/export-excel/order/order-export-excel-portal.constants';
import { OrderExportExcelCrm } from '../../constants/export-excel/order/order-export-excel-crm.constants';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
	providedIn: 'root'
})
export class ExportExcelService {
	constructor(private translate: TranslateService) {}

	boldify_header(ws: WorkSheet) {
        //Setta la prima riga (intestazione) in grassetto e in maiuscolo.
        const headerStyle = {
            font: { bold: true } //font: { modificatori  }
        };
        const headerRange = utils.decode_range(ws['!ref']);
        for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
            const cell = utils.encode_cell({ r: headerRange.s.r, c: C });
            ws[cell].v = ('' + ws[cell].v).toUpperCase();
            ws[cell].s = headerStyle;
        }
    }

	createExcelWorkBook(heading: string[][], json: any, excel_file_name: string, worksheet_name: string) {
		let wb: WorkBook = utils.book_new();
		let ws: WorkSheet = utils.json_to_sheet(json);
		utils.sheet_add_aoa(ws, heading);
		this.boldify_header(ws);
		utils.book_append_sheet(wb, ws, worksheet_name);
		writeFile(wb, excel_file_name, { compression: true, bookType: 'xlsx', type: 'binary' });
	}

	// Heading e json data specifici per ogni context -- passo come parametro anche lo user per recuperare il current context
	order_download(order_list, user: UserDetailModel) {
		switch (user.current_permission.context_application) {
			case ContextApplicationItemCodeEnum.BACKOFFICE: 
				// Copio array originale per poter eseguire la traduzione di status.label
				const jsonUpdatedBackoffice = [...OrderExportExcelBackoffice.exportExcel(order_list)]
				jsonUpdatedBackoffice.map(item => item.status = this.translate.instant(item.status).toUpperCase())
				this.createExcelWorkBook(OrderExportExcelBackoffice.heading, jsonUpdatedBackoffice, ConfigurationCustomer.ExportExcel.order_file_name, ConfigurationCustomer.ExportExcel.order_worksheet_name);
				break;
			case ContextApplicationItemCodeEnum.AGENT:
				// Copio array originale per poter eseguire la traduzione di status.label
				const jsonUpdatedAgent = [...OrderExportExcelAgent.exportExcel(order_list)]
				jsonUpdatedAgent.map(item => item.status = this.translate.instant(item.status).toUpperCase())
				this.createExcelWorkBook(OrderExportExcelAgent.heading,jsonUpdatedAgent, ConfigurationCustomer.ExportExcel.order_file_name, ConfigurationCustomer.ExportExcel.order_worksheet_name);
				break;
			case ContextApplicationItemCodeEnum.B2B: 
			// Copio array originale per poter eseguire la traduzione di status.label
			const jsonUpdatedB2B = [...OrderExportExcelB2B.exportExcel(order_list)]
			jsonUpdatedB2B.map(item => item.status = this.translate.instant(item.status).toUpperCase())
				this.createExcelWorkBook(OrderExportExcelB2B.heading, jsonUpdatedB2B, ConfigurationCustomer.ExportExcel.order_file_name, ConfigurationCustomer.ExportExcel.order_worksheet_name);
				break;
			case ContextApplicationItemCodeEnum.B2C:
				// Copio array originale per poter eseguire la traduzione di status.label
				const jsonUpdatedB2C = [...OrderExportExcelB2C.exportExcel(order_list)]
				jsonUpdatedB2C.map(item => item.status = this.translate.instant(item.status).toUpperCase())
				this.createExcelWorkBook(OrderExportExcelB2C.heading, jsonUpdatedB2C, ConfigurationCustomer.ExportExcel.order_file_name, ConfigurationCustomer.ExportExcel.order_worksheet_name);
				break;
			case ContextApplicationItemCodeEnum.PORTAL:
				// Copio array originale per poter eseguire la traduzione di status.label
				const jsonUpdatedPortal = [...OrderExportExcelPortal.exportExcel(order_list)]
				jsonUpdatedPortal.map(item => item.status = this.translate.instant(item.status).toUpperCase())
				this.createExcelWorkBook(OrderExportExcelPortal.heading,jsonUpdatedPortal, ConfigurationCustomer.ExportExcel.order_file_name, ConfigurationCustomer.ExportExcel.order_worksheet_name);
				break;
			case ContextApplicationItemCodeEnum.CRM:
				// Copio array originale per poter eseguire la traduzione di status.label
				const jsonUpdatedCrm = [...OrderExportExcelCrm.exportExcel(order_list)]
				jsonUpdatedCrm.map(item => item.status = this.translate.instant(item.status).toUpperCase())
				this.createExcelWorkBook(OrderExportExcelCrm.heading, jsonUpdatedCrm, ConfigurationCustomer.ExportExcel.order_file_name, ConfigurationCustomer.ExportExcel.order_worksheet_name);				
				break;
		}
	}
		// Heading e json data specifici per ogni context -- passo come parametro anche lo user per recuperare il current context
	organization_download(organization_list, user: UserDetailModel) {
		switch(user.current_permission.context_application){
			case ContextApplicationItemCodeEnum.BACKOFFICE:
				this.createExcelWorkBook(OrganizationExportExcelBackoffice.heading, OrganizationExportExcelBackoffice.exportExcel(organization_list), ConfigurationCustomer.ExportExcel.organization_file_name, ConfigurationCustomer.ExportExcel.organization_worksheet_name);
				break;
			case ContextApplicationItemCodeEnum.AGENT:
				this.createExcelWorkBook(OrganizationExportExcelAgent.heading, OrganizationExportExcelAgent.exportExcel(organization_list), ConfigurationCustomer.ExportExcel.organization_file_name, ConfigurationCustomer.ExportExcel.organization_worksheet_name);
				break;
			case ContextApplicationItemCodeEnum.CRM:
				this.createExcelWorkBook(OrganizationExportExcelCrm.heading, OrganizationExportExcelCrm.exportExcel(organization_list), ConfigurationCustomer.ExportExcel.organization_file_name, ConfigurationCustomer.ExportExcel.organization_worksheet_name);
				break;	
		}	
	}	
}
