<page-with-sidebar
	[isRoot]="
		user.current_permission.context_application === contextApplicationItemCodeEnum.B2B || !organization
			? true
			: false
	"
	[sideBarPosition]="sideBarPositionValues.TOP"
>
	<ng-container pageMain>
		<mat-card class="angelo-theme">
			<order-list-wrapper
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="listPageBaseData.columnList"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				(onItemSelect)="goToOrderDetail($event)"
				(selectCompany)="
					utilCompanyService.navigateToCompanyDetail(
						$event.level,
						$event.code,
						user.current_permission.context_application
					)
				"
			>
			</order-list-wrapper>
		</mat-card>
	</ng-container>
	<ng-container pageSide *ngIf="checkListFilter">
		<sidebar-content-wrapper>
			<ng-container main-content>
				<!-- TODO: riprendere il lavoro configurando le eventuali liste per le SELECT -->
				<!-- <form-dynamic
					[configuration]="formDynamicConfiguration"
					(onFormValueChange)="onFormValueChange($event)"
				></form-dynamic> -->
				<form class="angelo-theme" [formGroup]="orderListFilterForm" (ngSubmit)="submitOrderListFilters()">
					<!-- Filtro data creazione -->
					<ng-container formGroupName="dateCreation">
						<mat-form-field *ngIf="filterOrderVisible.dateCreation">
							<mat-label>{{ 'order.field.creation_date' | translate | sentencecase }}</mat-label>
							<mat-date-range-input [rangePicker]="pickerCreationDate">
								<input
									matStartDate
									formControlName="start"
									placeholder="{{ 'general.time.start_date' | translate | sentencecase }}"
								/>
								<input
									matEndDate
									formControlName="end"
									placeholder="{{ 'general.time.end_date' | translate | sentencecase }}"
								/>
							</mat-date-range-input>
							<mat-datepicker-toggle matSuffix [for]="pickerCreationDate"></mat-datepicker-toggle>
							<mat-date-range-picker #pickerCreationDate></mat-date-range-picker>
						</mat-form-field>
					</ng-container>
					<ng-container formGroupName="dateDelivery">
						<!-- Filtro data consegna -->
						<mat-form-field *ngIf="filterOrderVisible.dateDelivery">
							<mat-label>{{ 'theme.order.field.delivery_date' | translate | sentencecase }}</mat-label>
							<mat-date-range-input [rangePicker]="pickerDeliveryDate">
								<input
									matStartDate
									formControlName="start"
									placeholder="{{ 'general.time.start_date' | translate | sentencecase }}"
								/>
								<input
									matEndDate
									formControlName="end"
									placeholder="{{ 'general.time.end_date' | translate | sentencecase }}"
								/>
							</mat-date-range-input>
							<mat-datepicker-toggle matSuffix [for]="pickerDeliveryDate"></mat-datepicker-toggle>
							<mat-date-range-picker #pickerDeliveryDate></mat-date-range-picker>
						</mat-form-field>
					</ng-container>
					<ng-container formGroupName="dateSubmission">
						<!-- Filtro data trasmissione -->
						<mat-form-field *ngIf="filterOrderVisible.dateSubmission">
							<mat-label>{{ 'theme.order.field.submission_date' | translate | sentencecase }}</mat-label>
							<mat-date-range-input [rangePicker]="pickerSubmissionDate">
								<input
									matStartDate
									formControlName="start"
									placeholder="{{ 'general.time.start_date' | translate | sentencecase }}"
								/>
								<input
									matEndDate
									formControlName="end"
									placeholder="{{ 'general.time.end_date' | translate | sentencecase }}"
								/>
							</mat-date-range-input>
							<mat-datepicker-toggle matSuffix [for]="pickerSubmissionDate"></mat-datepicker-toggle>
							<mat-date-range-picker #pickerSubmissionDate></mat-date-range-picker>
						</mat-form-field>
					</ng-container>
					<!-- Filtro stato -->
					<mat-form-field *ngIf="!orderState && filterOrderVisible.orderStatus">
						<mat-select
							multiple
							formControlName="orderStatus"
							placeholder="{{ 'theme.order.order_status' | translate | sentencecase }}"
						>
							<mat-option
								*ngFor="
									let item of configurationCustomer.Order.statusAggregationMap[
										contextApplicationItemCodeEnum[user.current_permission.context_application]
									]
								"
								[value]="item.related_list"
							>
								<div class="chips-mini">
									<span [ngClass]="item.label.toUpperCase()"></span>
								</div>
								{{
									item.label | translate | sentencecase
								}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<!-- Filtro causale -->
					<mat-form-field  *ngIf="filterOrderVisible.causalList">
						<mat-label>{{'theme.order.field.causal' | translate | sentencecase }}</mat-label>	
						<mat-select 
							formControlName="causalList" 
							placeholder="{{'theme.order.field.causal' | translate | sentencecase }}"
							multiple>
							<mat-option
								*ngFor="let item of auxiliaryTable.causalHeaderSoList"
								[value]="item"
							>
								{{  item.description_short| titlecase }}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<!-- <mat-toolbar>
				<button class="mr-8" mat-raised-button color="primary" type="submit">Filtra</button>
				<button mat-button color="default" type="button" (click)="resetOrderFormFilter()">Annulla</button>
			</mat-toolbar> -->
					<form-button-bar
						[align]="'right'"
						[primaryButtonLabel]="'general.apply_filters' | translate | sentencecase"
						[primaryButtonIsDisabled]="!orderListFilterForm.valid"
						(primaryButtonClickEmitter)="submitOrderListFilters()"
						[secondaryButtonLabel]="'general.reset' | translate | sentencecase"
						(secondaryButtonClickEmitter)="resetOrderFormFilter()"
					></form-button-bar>
				</form>
			</ng-container>
		</sidebar-content-wrapper>
	</ng-container>
	<ng-container pageSide>
		<button class="export-button" (click)="exportExcel()">Export Excel</button>
	</ng-container>
</page-with-sidebar>

<button
	*ngIf="configurationCustomer.Order.canCreate(user.current_permission.context_application, organization)"
	class="mat-fab-button"
	[ngClass]="{
		'mat-fab-button__b2c': user.current_permission.context_application === contextApplicationItemCodeEnum.B2C
	}"
	(click)="orderDraftCreate()"
	mat-fab
	[matTooltip]="'order.add_new' | translate | sentencecase"
	matTooltipPosition="left"
>
	<mat-icon>add</mat-icon>
	<span class="label">{{ 'order.add_new' | translate | sentencecase }}</span>
</button>
