<sidebar-content-wrapper
	*ngIf="order && order._id"
	[title]="'order.order_aside_help.order_utility'"
>
	<ng-container main-content>
		<button
			mat-menu-item
			(click)="utilOrderService.dialogConfirmOrderClone(order)"
			*ngIf="
				order &&
				order._id &&
				user &&
				(user.current_permission.context_application === contextApplicationItemCodeEnum.AGENT ||
					user.current_permission.context_application === contextApplicationItemCodeEnum.B2B ||
					user.current_permission.context_application === contextApplicationItemCodeEnum.CRM)
			"
		>
			<mat-icon>file_copy</mat-icon>
			<span>{{ 'general.duplicate' | translate | sentencecase }}</span>
		</button>
	</ng-container>
</sidebar-content-wrapper>
<sidebar-content-wrapper [title]="'order.order_aside_help.need_help'">
	<ng-container main-content>
		<faq [configuration]="configurationCustomer.Faq.orderDetail[user.current_permission.context_application]"></faq>
	</ng-container>
</sidebar-content-wrapper>