import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { StateFeature } from '../../state';
import { PermissionUtilService } from '../util/permission-util.service';
import { PouchDbAgentAdapter } from './spin8/pouchdb-agent.adapter';
import { PouchDbBackofficeAdapter } from './spin8/pouchdb-backoffice.adapter';
import { PouchDbCommonsAgentAdapter } from './spin8/pouchdb-commons-agent.adapter';
import { PouchDbCommonsArticleAdapter } from './spin8/pouchdb-commons-article.adapter';
import { PouchDbCommonsBackofficeAdapter } from './spin8/pouchdb-commons-backoffice.adapter';
import { PouchDbCommonsOrderAdapter } from './spin8/pouchdb-commons-order.adapter';
import { PouchDbCommonsOrganizationAdapter } from './spin8/pouchdb-commons-organization.adapter';
import { PouchDbCommonsUserAdapter } from './spin8/pouchdb-commons-user.adapter';
import { PouchDbOrganizationB2CAdapter } from './spin8/pouchdb-organization-b2c.adapter';
import { PouchDbSpin8AgentAdapter } from './spin8/pouchdb-spin8-agent.adapter';
import { CustomerAppConfig } from '../../customer-app.config';
import { PouchUtilService } from '@saep-ict/pouch-db';
import { PouchDbCrmAdapter } from './spin8/pouchdb-crm.adapter';
import { PouchDbCommonsContactAdapter } from './spin8/pouchdb-commons-contact.adapter';
import { PouchDbOrganizationB2BAdapter } from './spin8/pouchdb-organization-b2b.adapter';
import { PouchDbOrganizationPortalAdapter } from './spin8/pouchdb-organization-portal.adapter';
import { PouchDbCommonsRequestAdapter } from './spin8/pouchdb-commons-request';
import { PouchDbCommonsCrmAdapter } from './spin8/pouchdb-commons-crm.adapter';
import {
	ContextApplicationItemCodeEnum,
	ContextOtherItemCodeEnum,
	LinkCodeModel,
	PouchDbEnum,
	PouchDbModel,
	UserDetailModel
} from '@saep-ict/angular-spin8-core';

const AdapterSetting = [
	{
		key: ContextApplicationItemCodeEnum.AGENT,
		adapter: PouchDbEnum.CustomAdapter.AGENT
	},
	{
		key: ContextApplicationItemCodeEnum.B2B,
		adapter: PouchDbEnum.CustomAdapter.ORGANIZATION_B2B
	},
	{
		key: ContextApplicationItemCodeEnum.B2C,
		adapter: PouchDbEnum.CustomAdapter.ORGANIZATION_B2C
	},
	{
		key: ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN,
		adapter: PouchDbEnum.CustomAdapter.BACKOFFICE
	},
	{
		key: ContextApplicationItemCodeEnum.BACKOFFICE,
		adapter: PouchDbEnum.CustomAdapter.BACKOFFICE
	},
	{
		key: ContextApplicationItemCodeEnum.PORTAL,
		adapter: PouchDbEnum.CustomAdapter.ORGANIZATION_PORTAL
	},
	{
		key: ContextApplicationItemCodeEnum.CRM,
		adapter: PouchDbEnum.CustomAdapter.CRM
	},
	{
		key: ContextOtherItemCodeEnum.TICKET,
		adapter: PouchDbEnum.CustomAdapter.COMMONS_ASSET_REQUEST
	}
];

export interface AdapterOptions {
	accessCommons?: boolean;
}

@Injectable()
export class PouchAdapterSelectorService {
	@LocalStorage('link_code') link_code: LinkCodeModel;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	constructor(
		private permissionUtilService: PermissionUtilService,
		private store: Store<any>,
		private appConfig: CustomerAppConfig,
		private pouchUtilService: PouchUtilService,
		private pouchDbAgentAdapter: PouchDbAgentAdapter,
		private pouchDbGeneralAdapter: PouchDbSpin8AgentAdapter,
		private pouchDbOrganizationB2CAdapter: PouchDbOrganizationB2CAdapter,
		private pouchDbOrganizationB2BAdapter: PouchDbOrganizationB2BAdapter,
		private PouchDbOrganizationPortalAdapter: PouchDbOrganizationPortalAdapter,
		private pouchDbBackofficeAdapter: PouchDbBackofficeAdapter,
		private pouchDbCrmAdapter: PouchDbCrmAdapter,
		private pouchDbCommonsOrganizationAdapter: PouchDbCommonsOrganizationAdapter,
		private pouchDbCommonsArticleAdapter: PouchDbCommonsArticleAdapter,
		private pouchDbCommonsOrderAdapter: PouchDbCommonsOrderAdapter,
		private pouchDbCommonsUserAdapter: PouchDbCommonsUserAdapter,
		private pouchDbCommonsAgentAdapter: PouchDbCommonsAgentAdapter,
		private pouchDbCommonsBackofficeAdapter: PouchDbCommonsBackofficeAdapter,
		private pouchDbCommonsContactAdapter: PouchDbCommonsContactAdapter,
		private pouchDbCommonsRequest: PouchDbCommonsRequestAdapter,
		private pouchDbCommonsCrmAdapter: PouchDbCommonsCrmAdapter
	) {
		this.user$.pipe().subscribe(user => {
			this.user = user ? user.data : null;
		});
	}

	/**
	 * Restituisce l'adapter corretto in base al contesto in maniera dinamica
	 * @param documentType
	 */
	async retrieveCurrentAdapter(
		// TODO: verificare la casistica in cui non viene passato il parametro
		// su salvataggio organization da parte di backoffice con visibilità su tutte le org.
		// comporta salvataggio su database specifico del codice backoffice
		documentType?: PouchDbModel.PouchDbDocumentType,
		options: AdapterOptions = { accessCommons: false }
	): Promise<
		| PouchDbAgentAdapter
		| PouchDbSpin8AgentAdapter
		| PouchDbOrganizationB2CAdapter
		| PouchDbOrganizationB2BAdapter
		| PouchDbOrganizationPortalAdapter
		| PouchDbBackofficeAdapter
		| PouchDbCrmAdapter
		| PouchDbCommonsOrganizationAdapter
		| PouchDbCommonsArticleAdapter
		| PouchDbCommonsOrderAdapter
		| PouchDbCommonsUserAdapter
		| PouchDbCommonsAgentAdapter
		| PouchDbCommonsBackofficeAdapter
		| PouchDbCommonsContactAdapter
		| PouchDbCommonsRequestAdapter
		| PouchDbCommonsCrmAdapter
	> {
		// verifico se l'utente deve accedere a uno degli adapter per i commons piuttosto che (invece che) per il suo db specifico
		if (
			documentType &&
			this.user &&
			(this.permissionUtilService.checkIfUserCanAccessCommons(
				this.user,
				this.permissionUtilService.getPermissionToCheckForStateType(documentType)
			) ||
				options.accessCommons)
		) {
			const commonsAdapterToUse = this.retrieveCommonsAdapter(documentType);
			const toStartDbList = [
				{
					endpoint: this.appConfig.config.couch[0].endpoint,
					database: commonsAdapterToUse.database,
					baseDatabaseTemplate: commonsAdapterToUse.baseDatabaseTemplate
				}
			];
			try {
				await this.pouchUtilService.explicitInitCouch(toStartDbList);
				return commonsAdapterToUse;
			} catch (err) {
				throw new Error('Cannot start specific database');
			}
		}
		const currentAdapter = AdapterSetting.find(pouchAdapter => pouchAdapter.key == this.link_code.context);
		if (currentAdapter) {
			return this[currentAdapter.adapter];
		}
		return null;
	}

	/**
	 * Restituisce l'adapter commons per il tipo di documento richiesto
	 * @param documentType tipo di documento richiesto
	 */
	retrieveCommonsAdapter(documentType: PouchDbModel.PouchDbDocumentType) {
		switch (documentType) {
			case PouchDbModel.PouchDbDocumentType.AGENT:
				return this.pouchDbCommonsAgentAdapter;
			case PouchDbModel.PouchDbDocumentType.ARTICLE:
				return this.pouchDbCommonsArticleAdapter;
			case PouchDbModel.PouchDbDocumentType.BACKOFFICE:
				return this.pouchDbCommonsBackofficeAdapter;
			case PouchDbModel.PouchDbDocumentType.CRM:
				return this.pouchDbCommonsCrmAdapter;
			case PouchDbModel.PouchDbDocumentType.CONTACT:
				return this.pouchDbCommonsContactAdapter;
			case PouchDbModel.PouchDbDocumentType.ORDER:
				return this.pouchDbCommonsOrderAdapter;
			case PouchDbModel.PouchDbDocumentType.ORGANIZATION:
				return this.pouchDbCommonsOrganizationAdapter;
			case PouchDbModel.PouchDbDocumentType.USER:
				return this.pouchDbCommonsUserAdapter;
			case PouchDbModel.PouchDbDocumentType.REQUEST:
				return this.pouchDbCommonsRequest;
		}
	}
}
