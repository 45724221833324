import { BaseTableListService } from '@saep-ict/angular-core';
import { Injectable } from '@angular/core';

@Injectable()
export class AddressListColumnService extends BaseTableListService {
	constructor() {
		super();
	}

	_columns = [
		{
			name: 'business_name',
			label: 'Nome Destinazione',
			width: { min: 150 }
		},
		{
			name: 'address.address',
			label: 'Indirizzo',
			width: { min: 80 }
		},
		{
			name: 'address.zip_code',
			label: 'CAP',
			width: { min: 80 }
		},
		{
			name: 'address.locality',
			label: 'Città',
			width: { min: 80 }
		},
		{
			name: 'address.province.label',
			label: 'Provincia',
			width: { min: 80 }
		},
		{
			name: 'address.country',
			label: 'Nazione',
			width: { min: 80 }
		},
		{
			name: 'action',
			label: '',
			width: { min: 80 }
		}
	];
}
