import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	BaseState,
	BaseStateModel,
	SubscribeManagerService,
	AngularCoreUtilService
} from '@saep-ict/angular-core';
import { LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';
import { Observable } from 'rxjs';
import { ArticleActionEnum, ArticleStateAction } from '../../../state/article/article.actions';
import { StateFeature } from '../../../state';
import { delay, filter, map, mergeMap, take } from 'rxjs/operators';
import _ from 'lodash';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { Router } from '@angular/router';
import { ArticleRecap } from '../../../model/state/article-list-state.model';
import { TranslateService } from '@ngx-translate/core';
import {
	ArticleEnum,
	ContextApplicationItemCodeEnum,
	PATH_URL,
	ROUTE_URL,
	UserDetailModel
} from '@saep-ict/angular-spin8-core';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { SideBarPositionValues } from '../../../enum/sidebar-position-values.enum';

@Component({
	selector: 'article',
	templateUrl: './article.component.html',
	styleUrls: ['./article.component.scss'],
	providers: [SubscribeManagerService]
})
export class ArticleComponent implements OnInit, OnDestroy {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	articleDescription$ = this.store.select(StateFeature.getArticleDescription);
	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);
	articleList: ArticlePouchModel[];

	articleAvailabilityState = Object.keys(ArticleEnum.Status.Stock).map(key => ({
		value: ArticleEnum.Status.Stock[key],
		key: key
	}));

	formFilters: FormGroup;

	listPageBaseData = <LocalListHandlerBaseModel<any>>{
		pagination: {
			pageSize: 10
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: [
					'articleDescription.language_list.description',
					ConfigurationCustomer.AppStructure.Erp.has_erp ? 'code_erp' : 'code_item'
				]
			}
		},
		columnList: ConfigurationCustomer.Article.backofficeArticleColumnList,
		sort: {
			name: ConfigurationCustomer.AppStructure.Erp.has_erp ? 'code_erp' : 'code_item',
			order: 'ASC'
		},
		sortRemapObject: {
			description: 'articleDescription.language_list.description'
		}
	};

    sideBarPositionValues = SideBarPositionValues;

	constructor(
		private store: Store<any>,
		private fb: FormBuilder,
		private subscribeManagerService: SubscribeManagerService,
		private utilOrderService: UtilOrderService,
		private router: Router,
		private translateService: TranslateService,
		public utilService: AngularCoreUtilService,
		private utilBreadcrumbService: UtilBreadcrumbService
	) {
		this.listPageBaseData.languageKey = this.translateService.currentLang;
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});

		this.createFormFilters();
		this.subscribeManagerService.populate(this.subscribeArticleList().subscribe(), 'subscribeArticleList');
		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('catalogue');
		this.utilBreadcrumbService.subtitle.value = this.utilBreadcrumbService.getBreadcrumbTitle('catalogue_articles');
		this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['catalogue','catalogue_articles']);
	}

	ngOnInit() {}

	goToArticleDetail(selectedArticle: ArticlePouchModel) {
		switch (this.user.current_permission.context_application) {
			case ContextApplicationItemCodeEnum.BACKOFFICE:
				const code = selectedArticle.code_item;
				this.router.navigate([
					`${PATH_URL.PRIVATE}`,
					`${ROUTE_URL.catalog}`,
					`${ROUTE_URL.articles}`,
					`${code}`
				]);
				break;
		}
	}

	ngOnDestroy() {
		// this.store.dispatch(ArticleStateAction.reset());
		this.subscribeManagerService.destroy();
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	/**
	 * Subscription
	 */

	subscribeArticleList() {
		// la pipe comincia con il recupero di articleDescription$ per accertarsi che
		// utilOrderService.mergeArticleRecapDescription scatti quando utilOrderService.articleDescription esista e soprattutto
		// anche dopo questi venga aggiornato da eventuale save in article-detail.component
		return this.articleDescription$.pipe(
			delay(0),
			filter((state: BaseStateModel<ArticleRecap>) => !!(state && state.data)),
			mergeMap(state => this.articleList$),
			filter((state: BaseStateModel<ArticlePouchModel[]>) => !!(state && state.data)),
			map((state: BaseStateModel<ArticlePouchModel[]>) => {
				switch (state.type) {
					case ArticleActionEnum.UPDATE:
						this.articleList = this.utilOrderService.mergeArticleRecapDescription<ArticlePouchModel>(
							state.data
						);
						this.listPageBaseData.data = this.applyDefaultFilter(this.articleList);
						this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
						break;
					case ArticleActionEnum.ERROR:
						throw new Error(ArticleActionEnum.ERROR);
				}

				return state;
			})
		);
	}

	// Form - sidebar

	applyDefaultFilter(articleList: ArticlePouchModel[]): ArticlePouchModel[] {
		return articleList.filter(article => article.valid);
	}

	createFormFilters() {
		this.formFilters = this.fb.group({
			availability_status: ''
		});
	}

	resetFilters() {
		this.formFilters.patchValue({
			availability_status: ''
		});
		this.onFormFiltersSubmit();
	}

	onFormFiltersSubmit() {
		let filteredList = _.cloneDeep(this.articleList);
		if (this.formFilters && this.formFilters.value.availability_status) {
			filteredList = filteredList.filter(item => {
				return item.articleDescription.stock?.code_segnalation === this.formFilters.value.availability_status;
			});
		}
		this.listPageBaseData.data = filteredList;
		this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
	}
}
