import { FormControlMultipurposeEnum, FormControlMultipurposeModel } from "@saep-ict/angular-core";
import _ from "lodash";
import { Validators } from '@angular/forms';
import { ConfigurationOrder } from "../../../order/order.constants";
import { ConfigurationOrderExtend } from "../order.constants";
import { FormValidatorCustom } from "../../../form-validator-custom";

export const configurationCustomerOrderHeaderFieldList: FormControlMultipurposeModel.Item[] = [
	{
		name: 'customer_order_ref_code',
		on_form_template: true,
		label: 'order.field.customer_reference',
		type: FormControlMultipurposeEnum.ItemType.STRING,
		validator_list: [Validators.required],
		column_width: 6
	},
	{
		name: 'first_evasion_date',
		type: FormControlMultipurposeEnum.ItemType.DATE,
		on_form_template: true,
		label: 'theme.order.field.delivery_date',
		column_width: 6,
		validator_list: [
			Validators.required,
			FormValidatorCustom.isDateFarEnough(
				ConfigurationOrderExtend.UtilDate.firstEvasionDateMinDate,
				[0, 6]
			)
		],
		dateMin: ConfigurationOrder.UtilDate.addDays(ConfigurationOrderExtend.UtilDate.firstEvasionDateMinDate),
		dateMax:
			ConfigurationOrder.UtilDate.addDays(ConfigurationOrderExtend.UtilDate.firstEvasionDateMaxDate),
		dateFilter: ConfigurationOrderExtend.UtilDate.matDatepickerFilter.due_date
	},
	{
		name: 'goods_destination_code',
		type: FormControlMultipurposeEnum.ItemType.SELECT,
		on_form_template: true,
		label: 'theme.order.field.shipping_address',
		column_width: 6,
		validator_list: [Validators.required]
	},
	{
		name: 'shipping_method_code',
		on_form_template: true,
		label: 'theme.order.field.shipping_methods',
		type: FormControlMultipurposeEnum.ItemType.SELECT,
		validator_list: [Validators.required],
		column_width: 6
	},
	{
		name: 'note_order',
		type: FormControlMultipurposeEnum.ItemType.STRING ,
		on_form_template: true,
		label: 'theme.order.field.note',
		column_width: 12,
	}
];