import { Route } from '@angular/router';
import { MetaDataResolver } from '../service/resolvers/meta-data.resolver';
import { HomeComponent } from '../page/b2c/storefront/home/home.component';
import { B2cCatalogComponent } from '../page/b2c/b2c-catalog/b2c-catalog.component';
import { ProductDetailComponent } from '../page/b2c/b2c-article-detail/b2c-article-detail.component';
import { B2cCartComponent } from '../page/b2c/b2c-cart/b2c-cart.component';
import { B2cCheckoutComponent } from '../page/b2c/b2c-checkout/b2c-checkout.component';
import { PasswordRecoveryComponent } from '../page/commons/authentication-wrapper/password-recovery/password-recovery.component';
import { VerifyTokenEmailGuard } from '../service/guard/verify-token-email.guard';
import { OrganizationRegistrationComponent } from '../page/commons/authentication-wrapper/organization-registration/organization-registration.component';
import { SearchResultsComponent } from '../page/b2c/storefront/search-results/search-results.component';
import { CheckoutGuard } from '../service/guard/checkout.guard';
import { B2cAuthenticationWrapperComponent } from '../page/b2c/b2c-authentication-wrapper/b2c-authentication-wrapper.component';
import { PublicWrapperComponent } from '../frame/public-wrapper/public-wrapper.component';
import { PasswordCreateComponent } from '../page/commons/authentication-wrapper/password-create/password-create.component';
import { LoginComponent } from '../page/commons/authentication-wrapper/login/login.component';
import { InformativePageComponent } from '../page/commons/informative-page/informative-page.component';
import { B2cCheckoutTransactionComponent } from '../page/b2c/b2c-checkout/b2c-checkout-transaction/b2c-checkout-transaction.component';
import { ROUTE_URL } from '@saep-ict/angular-spin8-core';

export namespace PublicRouteList {
	export const routes: Route[] = [
		{
			path: '',
			component: PublicWrapperComponent,
			children: [
				{
					path: '',
					component: HomeComponent,
					data: {
						meta: {
							title: 'Homepage B2C',
							description: 'Presentazione dello store B2C'
						}
					},
					pathMatch: 'full',
					resolve: {
						meta: MetaDataResolver
					}
				},
				{
					path: `${ROUTE_URL.catalog}`,
					component: B2cCatalogComponent,
					resolve: {
						meta: MetaDataResolver
					},
					data: {
						meta: {
							title: 'Catalogo',
							description: 'Descrizione a caso per testare la parte meta ma nella pagina catalogo'
						}
					}
				},
				{
					path: `${ROUTE_URL.catalog}/:category_id`,
					component: B2cCatalogComponent,
					resolve: {
						meta: MetaDataResolver
					},
					data: {
						meta: {
							title: 'Categoria',
							description: 'Descrizione a caso per testare la parte meta ma nella pagina category'
						}
					}
				},
				{
					path: `${ROUTE_URL.product}/:product_id`,
					component: ProductDetailComponent,
					resolve: {
						meta: MetaDataResolver
					},
					data: {
						meta: {
							title: 'Prodotto',
							description: 'Descrizione a caso per testare la parte meta ma nella pagina prodotto'
						}
					}
				},
				{
					path: `${ROUTE_URL.searchResults}`,
					component: SearchResultsComponent,
					resolve: {
						meta: MetaDataResolver
					},
					data: {
						meta: {
							title: 'Risultati di ricerca',
							description:
								'Descrizione a caso per testare la parte meta ma nella pagina dei risultati di ricerca'
						}
					}
				},
				{
					path: ROUTE_URL.cart,
					component: B2cCartComponent,
					resolve: {
						meta: MetaDataResolver
					},
					data: {
						meta: {
							title: 'Cart',
							description: 'Descrizione a caso per testare la parte meta ma nella pagina carrello'
						}
					}
				},
				{
					path: `${ROUTE_URL.cart}/${ROUTE_URL.checkout}`,
					component: B2cCheckoutComponent,
					canActivate: [CheckoutGuard],
					resolve: {
						meta: MetaDataResolver
					},
					data: {
						meta: {
							title: 'Checkout',
							description: 'Descrizione a caso per testare la parte meta ma nella pagina checkout'
						}
					},
					children: [
						{
							path: 'transaction/:transactionStatus',
							component: B2cCheckoutTransactionComponent
						}
					]
				},
				{
					path: ROUTE_URL.login,
					component: B2cAuthenticationWrapperComponent,
					resolve: {
						meta: MetaDataResolver
					},
					data: {
						meta: {
							title: 'Login',
							description: "Pagina di autenticazione dell'utente"
						},
						context_type: 'login'
					},
					children: [
						{
							path: '',
							component: LoginComponent,
							pathMatch: 'full'
						}
					]
				},
				{
					path: ROUTE_URL.authentication,
					resolve: {
						meta: MetaDataResolver
					},
					data: {
						meta: {
							title: 'Login',
							description: "Pagina di autenticazione dell'utente"
						}
					},
					children: [
						{
							path: '',
							redirectTo: ROUTE_URL.login,
							pathMatch: 'full'
						},
						{
							path: ROUTE_URL.login,
							component: LoginComponent,
							pathMatch: 'full'
						},
						{
							path: ROUTE_URL.passwordRecovery,
							component: PasswordRecoveryComponent,
							pathMatch: 'full',
							data: {
								meta: {
									title: 'Recupero Password',
									description:
										'Descrizione a caso per testare la parte meta ma nella pagina recovery password'
								},
								context_type: 'password',
								redirectLink: ['/', ROUTE_URL.public],
								cancelLink: ['/', ROUTE_URL.public, ROUTE_URL.login]
							}
						},
						{
							path: 'user-activation/:type/:email/:guid',
							component: PasswordCreateComponent,
							pathMatch: 'full',
							canActivate: [VerifyTokenEmailGuard],
							data: {
								context_type: 'password',
								redirectLink: [ROUTE_URL.public, ROUTE_URL.login]
							}
						},
						{
							path: ROUTE_URL.createAccount,
							component: OrganizationRegistrationComponent,
							data: {
								meta: {
									title: 'Crea un nuovo account',
									description:
										'Descrizione a caso per testare la parte meta ma nella pagina Crea un nuovo account'
								}
							}
						}
					]
				},
				// informative pages
				{
					path: ROUTE_URL.termsAndConditions,
					component: InformativePageComponent,
					pathMatch: 'full'
				},
				{
					path: ROUTE_URL.privacyPolicy,
					component: InformativePageComponent,
					pathMatch: 'full'
				},
				{
					path: ROUTE_URL.deliveryPolicy,
					component: InformativePageComponent,
					pathMatch: 'full'
				},
				{
					path: ROUTE_URL.returnPolicy,
					component: InformativePageComponent,
					pathMatch: 'full'
				},
				{
					path: ROUTE_URL.cookiePolicy,
					component: InformativePageComponent,
					pathMatch: 'full'
				},
				{
					path: ROUTE_URL.contact,
					component: InformativePageComponent,
					pathMatch: 'full'
				}
			]
		}
	];
}
