import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AngularSpin8CoreUtilTranslateService } from '@saep-ict/angular-spin8-core';
import { DiscountTypeEnum } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { ArticleChangeInputConfig, ArticleRowSelectionUpdate } from '../../../model/order-util.model';
import { AppUtilService } from '../../../service/util/app-util.service';
import { UtilPriceService } from '../../../service/util/util-price.service';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { SentencecasePipe, SubscribeManagerService } from '@saep-ict/angular-core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UtilInputNavigationService } from '../../../service/util/input-navigation-util.service';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';

@Component({
	selector: 'article-change-input',
	templateUrl: './article-change-input.component.html',
	styleUrls: ['./article-change-input.component.scss'],
	providers: [SubscribeManagerService]
})
export class ArticleChangeInputComponent implements OnInit {

	@ViewChild('inputTag') inputTag: ElementRef;
	@Input() configuration: ArticleChangeInputConfig;

	updateArticleRowSource = new Subject<ArticleRowSelectionUpdate>();
	updateArticleRow$: Observable<ArticleRowSelectionUpdate> = this.updateArticleRowSource.asObservable();

	maskShow = true;
	maskShowFocusOnInput;

	configurationCustomer = ConfigurationCustomer;

	constructor(
		private utilService: AppUtilService,
		public utilInputNavigationService: UtilInputNavigationService,
		private utilOrderService: UtilOrderService,
		private utilPriceService: UtilPriceService,
		private utilTranslateService: AngularSpin8CoreUtilTranslateService,
		private sentencecasePipe: SentencecasePipe,
		private snackBar: MatSnackBar,
		private subscribeManagerService: SubscribeManagerService
	) {
		this.subscribeManagerInit();
	}

	ngOnInit(): void {
		if (this.configuration.keyboardNavigationEnabled) {
			this.utilInputNavigationService.inputNavigationMap.list.push(this);
		}
	}

	ngAfterViewInit(): void {
		if (this.configuration.keyboardNavigationEnabled) {
			this.utilInputNavigationService.sortByDocumentPosition(this.configuration);
			if (
				this.utilInputNavigationService.inputNavigationMap.selected &&
				this.utilInputNavigationService.inputNavigationMap.selected.configuration.row.code_item ===
				this.configuration.row.code_item &&
				this.utilInputNavigationService.inputNavigationMap.selected.configuration.key ===
				this.configuration.key
			) {
				this.utilInputNavigationService.returnItem(this.configuration)
					.inputTag.nativeElement.focus();
			}
		}
	}

	ngOnDestroy(): void {
		if (this.configuration.keyboardNavigationEnabled) {
			this.utilInputNavigationService.deleteItem(this.configuration);
		}
		this.subscribeManagerService.destroy();
	}

	subscribeManagerInit() {
		this.subscribeManagerService.populate(
			this.subscribeArticleRowSelectionUpdate().subscribe(
				res => { },
				error => {
					console.log('something went wrong ', error);
				}
			),
			'order-data'
		);
	}

	updatedArticleRowSelection(e: ArticleRowSelectionUpdate) {
		this.updateArticleRowSource.next(e);
	}

	subscribeArticleRowSelectionUpdate() {
		return this.updateArticleRow$.pipe(
			// debounceTime(1500),
			// TODO: verificare necesità di reintroduzione:
			// senza questo debounce, ogni keydown emette un evento. Tutti gli eventi vengono accodati in util-order.service,
			// solo l'ultimo scatena salvataggio vero e proprio per effetto di un debounce totale e di un oggetto `order`
			// che salva temporaneamente le modifiche accodate.
			// In caso questa meccanica resti invariata, valutare di rimuovere la presente subscribe in favore di un più semplice metodo
			// sincrono.
			map((e: ArticleRowSelectionUpdate) => {
				// update forzato del prezzo di riga in visualizzazione
				const i = this.utilService.getElementIndex(e.dataSubset, 'code_item', e.row.code_item);
				if (i || i === 0) {
					let inputValue = e.event.target.value !== '' ? e.event.target.value : '0';
					inputValue = typeof inputValue === 'string' ? parseFloat(inputValue.replace(',', '.')) : inputValue;
					inputValue = this.utilOrderService.returnInputMultipleOf(inputValue, e.dataSubset[i], e.organization);
					// Check available quantity
					const availableQuantity = this.utilOrderService.returnAvailableArticleQuantity(e.row, e.organization);
					if (availableQuantity !== null && inputValue > availableQuantity) {
						const message = this.sentencecasePipe.transform(
							this.utilTranslateService.translate.instant('article.quantity.not_available')
						);
						this.snackBar.open(message, 'OK', { duration: 5000 });
						// TODO: segnalazione di stefania da verificare -> non aggiorna child component dopo la prima volta
						inputValue = availableQuantity;
					}
					e.dataSubset[i].price = e.dataSubset[i].articlePrice.price;
					e.dataSubset[i].discount = e.dataSubset[i].articlePrice.discount;
					if (inputValue === 0) {
						switch (e.key) {
							case 'input_quantity_related_tester':
								delete e.dataSubset[i].articleDescription.relatedArticleTester.input_quantity;
								break;
							case 'input_quantity':
								delete e.dataSubset[i].qty_free;
								delete e.dataSubset[i].discount_agent;
								e.dataSubset[i].input_quantity = 0;
								break;
							default:
								delete e.dataSubset[i][e.key];
						}
					} else {
						switch (e.key) {
							case 'discount_agent':
								e.dataSubset[i].discount_agent = {
									value: inputValue,
									type: DiscountTypeEnum.percentage
								};
								break;
							case 'input_quantity_related_tester':
								e.dataSubset[i].articleDescription.relatedArticleTester.input_quantity = inputValue;
								break;
							default:
								e.dataSubset[i][e.key] = inputValue;
						}
					}
					if (e.dataSubset[i].input_quantity) {
						if (e.dataSubset[i].qty_free && e.dataSubset[i].qty_free > e.dataSubset[i].input_quantity) {
							e.dataSubset[i].qty_free = e.dataSubset[i].input_quantity;
						}
					} else {
						delete e.dataSubset[i].qty_free;
						delete e.dataSubset[i].discount_agent;
						e.dataSubset[i].input_quantity = 0;
					}
					e.dataSubset[i].calculate_price = this.utilPriceService.retrieveTotalArticlePrice(e.dataSubset[i]);
					if (e.dataTable) {
						e.dataTable.data[i] = _.cloneDeep(e.dataSubset[i]);
						e.dataTable.refresh();
					}
					e.onArticleChange.emit(e);
				}
			})
		);
	}

	onKeydown(e) {
		if (this.configuration.keyboardNavigationEnabled) {
			this.utilInputNavigationService.goToByKeyboard(e, this.configuration)
		}
	}

	maskMouseenterHandler() {
		if (!this.configuration.disabled) {
			this.maskShow = false;
		}
	}

	maskMouseleaveHandler() {
		if (!this.maskShowFocusOnInput) {
			this.maskShow = true;
		}
	}

}
