import {
	Component,
	Input,
	OnDestroy,
	OnInit
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { ArticlePouchModel, OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import {
	SentencecasePipe,
	BaseStateModel,
	SubscribeManagerService,
	MediaReplayService,
	FormControlMultipurposeModel,
	FormControlMultipurposeEnum
} from '@saep-ict/angular-core';
import { ArticleListFilterModel } from '../../../../../service/pouch-db/filter/article-list-filter.model';
import { UtilOrderService } from '../../../../../service/util/util-order.service';
import { StateFeature } from '../../../../../state';
import { OrganizationActionEnum } from '../../../../../state/organization/organization.actions';
import { OrderActionEnum } from '../../../../../state/order/order.actions';
import { CategoryListActionEnum } from '../../../../../state/category-list/category-list.actions';
import { ArticleActionEnum, ArticleStateAction } from '../../../../../state/article/article.actions';
import {
	OrderStateModel,
	UserDetailModel,
	OrganizationStateModel,
	OrderEnum,
	OrderRowModel,
	AngularSpin8CoreUtilTranslateService
} from '@saep-ict/angular-spin8-core';
import {CategoryMap, ArticleCheckoutTree, LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';
import { ConfigurationCustomer } from '../../../../../constants/configuration-customer';
import { UtilInputNavigationService } from '../../../../../service/util/input-navigation-util.service';
import { SubscribeManagerItem } from '../../../../../model/subscribe-manager.model';
import { ConfigurationSubscribeManager } from '../../../../../constants/subscribe-manager.constant';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import { ROUTE_URL } from '@saep-ict/angular-spin8-core';
import { articleConfigurationAction } from '../../../../../constants/configuration-customer/article/article-action-ticket.costant';
import { UtilArticleService } from '../../../../../service/util/util-article.service';

@Component({
	selector: 'app-order-detail-checkout',
	templateUrl: './order-detail-checkout.component.html',
	styleUrls: ['./order-detail-checkout.component.scss'],
	providers: [SubscribeManagerService]
})
export class OrderDetailCheckoutComponent implements OnInit, OnDestroy {

	@Input() public isOffer = false;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel;

	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);
	articleListTotal: ArticlePouchModel[];
	articleList: ArticlePouchModel[];

	categoryList$: Observable<BaseStateModel<CategoryMap, ArticleListFilterModel>> = this.store.select(
		StateFeature.getCategoryListState
	);
	categoryTree: ArticleCheckoutTree[];

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	subscribeList: SubscribeManagerItem[] = [
		{ key: 'data', observable: this.subscribeData() }
	];

	orderHeaderFieldList: FormControlMultipurposeModel.Item[] = [];

	listPageBaseData: LocalListHandlerBaseModel<ArticlePouchModel>;

	articleTableConfigurationAction = articleConfigurationAction.Configuration_B2B;
	configurationCustomer = ConfigurationCustomer;
	orderEnum = OrderEnum;
	formControlMultipurposeEnum = FormControlMultipurposeEnum;

	constructor(
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		public utilOrderService: UtilOrderService,
		private snackBar: MatSnackBar,
		private sentencecasePipe: SentencecasePipe,
		private utilTranslateService: AngularSpin8CoreUtilTranslateService,
		public mediaReplayService: MediaReplayService,
		private utilInputNavigationService: UtilInputNavigationService,
		private utilArticleService: UtilArticleService
	) {
		this.utilOrderService.updateArticle = true;
		this.loadStaticData();
		ConfigurationSubscribeManager.init(this.subscribeList, this.subscribeManagerService);
	}

	ngOnInit() {

	}

	ngOnDestroy() {
		this.utilInputNavigationService.deleteAll();
		this.subscribeManagerService.destroy();
	}

	loadStaticData() {
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});
	}

	// subscribe
	subscribeData() {
		return this.organization$.pipe(
			filter(
				(organization: BaseStateModel<OrganizationStateModel>) =>
					organization && organization.type !== OrganizationActionEnum.LOAD
			),
			mergeMap((organization: BaseStateModel<OrganizationStateModel>) => {
				if (organization.type === OrganizationActionEnum.ERROR) {
					throw new Error(OrganizationActionEnum.ERROR);
				}
				if (organization.data) {
					this.organization = organization.data;
					return this.order$;
				}
			}),
			filter(
				(order: BaseStateModel<OrderStateModel>) =>
					this.organization &&
					order &&
					order.data &&
					order.type !== OrderActionEnum.LOAD &&
					order.type !== OrderActionEnum.SAVE
			),
			mergeMap((order: BaseStateModel<OrderStateModel>) => {
				if (order.type === OrderActionEnum.ERROR) {
					throw new Error(OrderActionEnum.ERROR);
				}
				this.order = order.data;
				if (order.type === OrderActionEnum.COMPLETED) {
					this.snackBar.open(
						this.sentencecasePipe.transform(
							this.utilTranslateService.translate.instant('order.send_success')
						),
						'OK',
						{ duration: 5000 }
					);
				}
				return this.articleList$;
			}),
			// TODO: verificare la possibilità di mettere in cima il recupero dell'ordine e subordinare alla presenza di articoli
			// in product_list gli altri map
			filter(
				(articleList: BaseStateModel<ArticlePouchModel[]>) =>
					articleList && articleList.type !== ArticleActionEnum.LOAD_FROM_RECAP
			),
			mergeMap((articleList: BaseStateModel<ArticlePouchModel[]>) => {
				if (articleList.type === ArticleActionEnum.ERROR) {
					throw new Error(ArticleActionEnum.ERROR);
				}
				this.articleListTotal = articleList.data;
				this.articleList = _.cloneDeep(this.articleListTotal);
				return this.categoryList$;
			}),
			filter(
				(categoryListState: BaseStateModel<CategoryMap, ArticleListFilterModel>) =>
					this.utilOrderService.updateArticle &&
					this.organization && categoryListState && categoryListState.type !== CategoryListActionEnum.LOAD_ALL
			),
			map(async (store: BaseStateModel<CategoryMap, ArticleListFilterModel>) => {
				if (this.order.product_list.length) {
					if (this.order.header.status !== OrderStatusEnum.DRAFT) {
						this.articleList = this.utilOrderService.returnArticleListByOrderSent(this.order);
					} else {
						this.articleList = this.utilOrderService.returnFilteredAndMergedArticleListByOrderDraft(
							this.order,
							this.articleList,
							this.organization
						);
						this.order = await this.utilArticleService.updateOrderOnArticleRecapChange(
							this.order,
							this.articleList,
							this.organization
						);
					}
				} else {
					this.articleList = [];
				}
				this.orderHeaderFieldList =
					this.utilOrderService.returnOrderHeaderFieldListWithValue(
						ConfigurationCustomer.Order.Form.CreationFieldMap.header(this.user),
						this.order
					);
				switch (store.type) {
					case CategoryListActionEnum.UPDATE:
						try {
							this.listPageBaseData = {
								pageName: 'order-detail',
								filters: {
									localSearchText: {
										value: null,
										key_list: ConfigurationCustomer.AppStructure.Erp.has_erp
											? [
													'articleDescription.language_list.description',
													'code_erp',
													'articleDescription.relatedArticleTester.code_erp'
											  ]
											: [
													'articleDescription.language_list.description',
													'code_item',
													'articleDescription.relatedArticleTester.code_item'
											  ]
									},
									customFilters: {
										// risulta dentro Set, alberatura sbarelata
										categoryList: store.data.tree
									}
								},
								sort: {
									name: 'articleDescription.sequence',
									order: TdDataTableSortingOrder.Ascending
								},
								sortRemapObject: {
									discount_agent: 'discount_agent.value'
								},
								columnList:
									ConfigurationCustomer.Article.orderColumnList(
										this.order.header.currency.description_short,
										ROUTE_URL.checkout
									)
									[this.user.current_permission.context_application],
								data: this.articleList
							};
							this.utilOrderService.updateArticle = false;
						} catch (err) {
							throw new Error(err);
						}
						break;
					case CategoryListActionEnum.ERROR:
						throw new Error(OrganizationActionEnum.ERROR);
				}
			})
		);
	}

	// widget & utility
	// article
	onArticleChangeHandler(e: OrderRowModel) {
		if (
			e.key === 'delete' ||
			e.event.target.value === '' ||
			e.event.target.value === '0'
		) {
			this.utilOrderService.updateArticle = true;
		}
		this.utilOrderService.changeOrderArticle(e, this.order, this.organization, false)
	}

}
