import { UtilPriceService } from './../../../../service/util/util-price.service';
import { Component, Input } from '@angular/core';

// model
import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';

// misc
import { UtilOrderService } from '../../../../service/util/util-order.service';
import { AngularSpin8CoreUtilTranslateService, OrderStateModel } from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'b2c-checkout-article-list',
	templateUrl: './b2c-checkout-article-list.component.html',
	styleUrls: ['./b2c-checkout-article-list.component.scss']
})
export class B2cCheckoutArticleListComponent {
	@Input() productList: ArticlePouchModel[] = [];
	@Input() order: OrderStateModel;

	constructor(
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		public utilPriceService: UtilPriceService,
		public utilOrderService: UtilOrderService
	) {}
}
