<page-with-sidebar>
	<ng-container pageMain>
		<mat-card>
			<offer-list-wrapper
				[localListHandlerPaginatorShow]="true"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerWidgetWrapperColumnList]="columns"
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				(onItemSelect)="goToOfferDetail($event)"
				(selectOpportunity)="goToOpportunity($event.code)"
				(selectOrganization)="utilCompanyService.navigateToCompanyDetail($event.level, $event.code)"
			>
			</offer-list-wrapper>
		</mat-card>
	</ng-container>

	<ng-container pageSide>
		<form class="angelo-theme" [formGroup]="filterForm" (ngSubmit)="applyFilterForm()">
			<!-- Creation date -->
			<ng-container formGroupName="dateCreation">
				<mat-form-field>
					<mat-label>{{ 'offer.field.date_creation' | translate | sentencecase }}</mat-label>
					<mat-date-range-input [rangePicker]="pickerCreationDate">
						<input
							matStartDate
							formControlName="start"
							placeholder="{{ 'general.time.start_date' | translate | sentencecase }}"
						/>
						<input
							matEndDate
							formControlName="end"
							placeholder="{{ 'general.time.end_date' | translate | sentencecase }}"
						/>
					</mat-date-range-input>
					<mat-datepicker-toggle matSuffix [for]="pickerCreationDate"></mat-datepicker-toggle>
					<mat-date-range-picker #pickerCreationDate></mat-date-range-picker>
				</mat-form-field>
			</ng-container>
			<ng-container formGroupName="dateExpiration">
				<!-- Expiration date -->
				<mat-form-field>
					<mat-label>{{ 'offer.field.estimated_close_date' | translate | sentencecase }}</mat-label>
					<mat-date-range-input [rangePicker]="pickerExpirationDate">
						<input
							matStartDate
							formControlName="start"
							placeholder="{{ 'general.time.start_date' | translate | sentencecase }}"
						/>
						<input
							matEndDate
							formControlName="end"
							placeholder="{{ 'general.time.end_date' | translate | sentencecase }}"
						/>
					</mat-date-range-input>
					<mat-datepicker-toggle matSuffix [for]="pickerExpirationDate"></mat-datepicker-toggle>
					<mat-date-range-picker #pickerExpirationDate></mat-date-range-picker>
				</mat-form-field>
			</ng-container>
			<form-button-bar
				[align]="'right'"
				[primaryButtonLabel]="'general.apply_filters' | translate | sentencecase"
				[primaryButtonIsDisabled]="!filterForm.valid"
				(primaryButtonClickEmitter)="applyFilterForm()"
				[secondaryButtonLabel]="'general.reset' | translate | sentencecase"
				(secondaryButtonClickEmitter)="resetFilterForm()"
			></form-button-bar>
		</form>
	</ng-container>
</page-with-sidebar>

<button
	class="mat-fab-button"
	(click)="createNew()"
	*ngIf="opportunity && !opportunity.close_date"
	mat-fab
	[matTooltip]="'offer.add_new' | translate | sentencecase"
	matTooltipPosition="left"
>
	<mat-icon>add</mat-icon>
	<span class="label">{{ 'offer.add_new' | translate | sentencecase }}</span>
</button>
