<div class="card-price">
	<div class="container">
		<h2>
			{{ 'order.your_order' | translate | sentencecase }}
			<strong>{{ utilOrderService.getOrderFirstRow(order, order.header.status) }}</strong>
		</h2>
		<!-- Titles -->
		<div class="row card-price__row-title">
			<h3 class="col card-price__row-title-product">
				<strong>{{ 'theme.article.field.products' | translate | sentencecase }}</strong>
			</h3>
			<h3 class="col card-price__row-title-quantity">
				<strong>{{ 'order.field.quantity' | translate | sentencecase }}</strong>
			</h3>
			<h3 class="col card-price__row-title-total">
				<strong>{{ 'order.field.total' | translate | sentencecase }}</strong>
			</h3>
		</div>
		<!-- Product list -->
		<ng-container *ngFor="let product of productList">
			<div class="row card-price__row-data">
				<!-- Description -->
				<div class="col-sm">
					<p class="card-price__row-data-description">
						{{
							utilTranslateService.getTranslationFromLanguage(product.articleDescription.language_list)
								.description | sentencecase
						}}
					</p>
				</div>
				<!-- Quantity -->
				<div class="col-sm">
					<p class="card-price__row-data-quantity">
						{{ product.ordered_quantity }}
						<small class="cell-subtitle">
							<!-- <span>{{
								'article.weight_total'
									| translate
										: { amount: product.ordered_quantity * product.articleDescription.weight }
							}}</span> -->
							<span
								>{{ product.ordered_quantity * product.articleDescription.weight }}
								{{ 'general.unit.weight' | translate }}</span
							>
						</small>
					</p>
				</div>
				<!-- Total -->
				<div class="col-sm">
					<p class="card-price__row-data-total">
						{{
							product.articlePrice
								? (utilPriceService.retrievePriceWithDiscount(
										product.articlePrice.price,
										product.articlePrice.discount
								  ) * product.ordered_quantity | currency: 'EUR':'symbol':'':'it')
								: null
						}}
					</p>
				</div>
			</div>
		</ng-container>
	</div>
</div>
