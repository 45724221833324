import moment from 'moment';
export namespace OrganizationExportExcelBackoffice {
	export const heading = 
				[[
					'Nome e cognome',
					'Data di nascita',
					'Tipologia iscrizione',
					'Codice fiscale',
					'Email',
					'Telefono',
					'Indirizzo di residenza',
					'Indirizzo di lavoro',
					'Anno ultimo pagamento'
				]];
				
	export const exportExcel = (list: any) => {
		let json = [];
		list.forEach(org => {
			let home_address = '',
				business_address = '',
				email = '',
				phone = '';
			org.destination_list.forEach(dest => {
				let a = dest.address;
				if (a) {
					if (dest.business_name == 'Residenza') {
						home_address =
							a.address +
							' - ' +
							a.zip_code +
							' ' +
							a.locality +
							(a.province ? ' (' + a.province.label + ')' : '');
					} else if (dest.business_name == 'Professione') {
						business_address =
							a.address +
							' - ' +
							a.zip_code +
							' ' +
							a.locality +
							(a.province ? ' (' + a.province.label + ')' : '');
					}
				}
			org.contact_list?.forEach( itemContact => {
				email = itemContact.email
				phone = itemContact.phone
			})  
			});
			json.push({
				name: org.business_name,
				birth_date: org.date_of_birth ? moment(org.date_of_birth).toDate() : '',
				class: org.organization_class,
				tax_code: org.tax_data && org.tax_data.tax_code ? org.tax_data.tax_code.toUpperCase() : '',
				email: email,
				phone: phone,
				home_address: home_address,
				business_address: business_address,
				last_payment_year: ''
			});
		});
		return json;
	};	
}

