import { DiscountModel, BasePouchModel, ArticlePouchModel, ArticleDescriptionItem, DiscountTypeEnum } from '@saep-ict/pouch_agent_models';


export class DiscountStateModel implements DiscountModel {
	value: number;
	type: DiscountTypeEnum;

	constructor(data: DiscountModel) {
		Object.keys(data).forEach(key => {
			this[key] = data[key];
		});
	}
}

export interface ArticleKitState extends BasePouchModel {
	date_updated?: string;
	code?: string;
	valid?: boolean;
	components?: ArticleKitComponentsState[];
}

export interface ArticleKitComponentsState {
	code_component?: string;
	cancellation?: string;
	sequence?: string;
	date_begin_validity?: string;
	date_end_validity?: string;
	use_coefficient?: string;
	coeff_discount_goods?: string;
	coefficient_quantity_goods?: number;
	coefficient_discount_goods?: number;
	causal_document_row?: string;
	price_list?: string;
	causal_row_sc_goods?: string;
}

export interface ArticleRecap extends BasePouchModel {
	code_customer: string;
	article_list: any[];
}

export interface ArticleRecapList extends ArticleRecap {
	article_list: ArticleRecapArticleList[];
}

export interface ArticleRecapDescription extends ArticleRecap {
	article_list: ArticleDescriptionItem[];
}

export interface ArticleRecapArticleList extends ArticlePouchModel {
	divisions?: ArticleRecapArticleDivision[];
}

export interface ArticleRecapArticleDivision {
	division: string;
	price: number;
	discounts: number[];
}
