import { DatePipe } from '@angular/common';
import { BaseTableListService, ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import { Inject, Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class CompanyAccountBalanceColumnService extends BaseTableListService {
	constructor(@Inject(DatePipe) private datePipe: DatePipe) {
		super();
	}

	_columns: ITdDataTableColumnCustom[] = [
		{
			name: 'number_document',
			labelPath: 'customer_credit.field.number_document',
			label: null,
			width: 100
		},
		{
			name: 'description_document',
			labelPath: 'customer_credit.field.description_document',
			label: null
		},
		{
			name: 'flag_give_take_dare',
			labelPath: 'customer_credit.field.give',
			label: null,
			numeric: true
		},
		{
			name: 'flag_give_take_avere',
			labelPath: 'customer_credit.field.take',
			label: null,
			numeric: true
		},
		{
			name: 'date_document',
			labelPath: 'customer_credit.field.date_document',
			label: null,
			format: (v: number) => this.datePipe.transform(v, 'dd/MM/yyyy'),
			numeric: true,
			width: 125
		},
		{
			name: 'date_expiration',
			labelPath: 'customer_credit.field.date_expiration',
			label: null,
			format: (v: number) => this.datePipe.transform(v, 'dd/MM/yyyy'),
			numeric: true,
			width: 125
		}
	];
}
