import { CategoryEnum } from "../../../enum/category.enum";
import { CategoryModel } from "../../../model/category-list.model";

export namespace ForecastCategoryConfiguration {
    export const categoryTabOnTopConfiguration: CategoryModel.TabOnTop.Configuration = {
        debounceTime: 700,
        initialization: {
            active: true,
            selectLevelBy: {
                index: [0, 0]
            }
        },
        levelStyleList: [
            {
                styleType: CategoryEnum.TabOnTopStyleType.CHIP,
            }
        ]
    }
}