import { createAction, props } from '@ngrx/store';

import { BaseStateModel } from '@saep-ict/angular-core';
import { StatisticModel } from '../../model/statistics.model';

export enum StatisticActionEnum {
	LOAD = '[statistic] Load',
	UPDATE = '[statistic] Update',
	RESET = '[statistic] Reset',
	ERROR = '[statistic] Error'
}

export namespace StatisticStateAction {
	export const load = createAction(StatisticActionEnum.LOAD, props<{ _id: string }>());
	export const update = createAction(
		StatisticActionEnum.UPDATE,
		props<BaseStateModel<StatisticModel.Document>>()
	);
	export const reset = createAction(StatisticActionEnum.RESET);
	export const error = createAction(StatisticActionEnum.ERROR, props<BaseStateModel<any>>());
}
