import { Injectable } from '@angular/core';

// model

// store
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserManagementStateActionEnum, UserManagementStateAction } from './user-management.actions';

// widget & utility
import { from } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BackofficeUserFilterModel } from '../../../service/pouch-db/filter/backoffice-user-filter.model';
import { TranslateService } from '@ngx-translate/core';
import {
	BaseStateModel,
	SentencecasePipe,
	RestBaseMessageError,
	BaseState,
	RestBaseResponse,
	AngularCoreUtilService
} from '@saep-ict/angular-core';
import {
	RestPostUserListPayload,
	UserDetailModel,
	UserManagementQueryParameter,
	AngularSpin8CoreCompanyService,
	AngularSpin8CoreUserService
} from '@saep-ict/angular-spin8-core';

@Injectable()
export class UserManagementEffects {
	loadList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserManagementStateActionEnum.LOAD_LIST),
			concatMap((action: BaseStateModel<UserDetailModel[], BackofficeUserFilterModel>) =>
				from(this.getUserList(action))
			),
			map((action: any) => UserManagementStateAction.update(action)),
			catchError((error, caught) => {
				UserManagementStateAction.error(null);
				return caught;
			})
		)
	);

	loadListWithAvatar$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserManagementStateActionEnum.LOAD_LIST_WITH_AVATAR),
			concatMap((action: BaseStateModel<UserDetailModel[], BackofficeUserFilterModel>) =>
				from(this.getUserList(action))
			),
			concatMap((action: BaseStateModel<UserDetailModel[]>) => from(this.mergeUserListAvatar(action))),
			map((action: any) => UserManagementStateAction.update(action)),
			catchError((error, caught) => {
				UserManagementStateAction.error(null);
				return caught;
			})
		)
	);

	loadDetail$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserManagementStateActionEnum.LOAD_DETAIL),
			concatMap((action: { id: number }) => from(this.getUserDetail(action))),
			concatMap((action: BaseStateModel<UserDetailModel[]>) => from(this.getContextCodeDescription(action))),
			map((action: BaseStateModel<UserDetailModel[], BackofficeUserFilterModel>) =>
				UserManagementStateAction.update(action)
			),
			catchError((error, caught) => {
				UserManagementStateAction.error(null);
				return caught;
			})
		)
	);

	loadDetailWithAvatar$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserManagementStateActionEnum.LOAD_DETAIL_WITH_AVATAR),
			concatMap((action: { id: number }) => from(this.getUserDetail(action))),
			concatMap((action: BaseStateModel<UserDetailModel[]>) => from(this.getContextCodeDescription(action))),
			concatMap((action: BaseStateModel<UserDetailModel[]>) => from(this.mergeUserListAvatar(action))),
			map((action: BaseStateModel<UserDetailModel[], BackofficeUserFilterModel>) =>
				UserManagementStateAction.update(action)
			),
			catchError((error, caught) => {
				UserManagementStateAction.error(null);
				return caught;
			})
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserManagementStateActionEnum.SAVE),
			concatMap((action: BaseStateModel<UserDetailModel[]>) => from(this.postUserList(action))),
			concatMap((action: BaseStateModel<UserDetailModel[]>) => from(this.mergeUserListAvatar(action))),
			map((action: BaseStateModel<UserDetailModel[]>) => UserManagementStateAction.update(action)),
			catchError((error, caught) => {
				UserManagementStateAction.error(null);
				return caught;
			})
		)
	);

	resendEmailConfirmation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserManagementStateActionEnum.RESEND_EMAIL_CONFIRMATION),
			concatMap((action: BaseStateModel<UserDetailModel>) => from(this.resendEmailConfirmation(action))),
			map((action: BaseStateModel<UserDetailModel[]>) => UserManagementStateAction.update(action)),
			catchError((error, caught) => {
				UserManagementStateAction.error(null);
				return caught;
			})
		)
	);

	deactivateDetail$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserManagementStateActionEnum.DEACTIVATE_DETAIL),
			concatMap((action: BaseStateModel<UserDetailModel>) => from(this.deactivateDetail(action))),
			map((action: BaseStateModel<UserDetailModel[]>) => UserManagementStateAction.update(action)),
			catchError((error, caught) => {
				UserManagementStateAction.error(null);
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private userService: AngularSpin8CoreUserService,
		private utilService: AngularCoreUtilService,
		private snackBar: MatSnackBar,
		private companyService: AngularSpin8CoreCompanyService,
		private translate: TranslateService,
		private sentenceCasePipe: SentencecasePipe
	) {}

	async getUserList(
		action: BaseStateModel<UserDetailModel[], BackofficeUserFilterModel>
	): Promise<BaseStateModel<UserDetailModel[], BackofficeUserFilterModel>> {
		const queryParameter = <UserManagementQueryParameter>this.utilService.deleteEmptyProperties({
			...action.dataSetting.appliedFilter,
			...action.dataSetting.pagination
		});

		let sort = action.dataSetting.sort ? action.dataSetting.sort[0] : null;
		if (sort) {
			queryParameter.sort_type = Object.keys(sort)[0];
			queryParameter.sort_direction = Object.values(sort)[0];
		}

		return this.userService
			.getUserList(queryParameter)
			.then(async res => {
				action.data = res.data;
				action.dataSetting.pagination = res.pagination;
				return action;
			})
			.catch((err: RestBaseMessageError) => {
				console.log(err);
				throw new Error(err.body.detail);
			});
	}

	async getUserDetail(restBasePk: any): Promise<BaseStateModel<UserDetailModel[], BackofficeUserFilterModel>> {
		return this.userService
			.getUserDetail({ id: restBasePk.id })
			.then(async res => {
				return new BaseState([res.data]);
			})
			.catch((err: RestBaseMessageError) => {
				throw new Error(err.body.detail);
			});
	}

	async postUserList(action: BaseStateModel<UserDetailModel[]>): Promise<any> {
		const userList: RestPostUserListPayload = {
			user_list: action.data
		};
		return this.userService
			.postUserList(userList)
			.then(async res => {
				this.showSnackBar('User updated');
				action.data = res.data['user_list'];
				return action;
			})
			.catch((err: RestBaseMessageError) => {
				this.showSnackBar(err.body.message);
				throw new Error(err.body.detail);
			});
	}

	async resendEmailConfirmation(action: BaseStateModel<UserDetailModel>): Promise<any> {
		return this.companyService.resendEmailConfirmation(
			{ id: parseInt(action.data.id, null) },
			res => {
				action.data.is_active = false;
				const msg = this.translate.instant('login.password_recovery.email_confirmation_sent_to_x', {
					user: action.data.username
				});
				this.showSnackBar(msg);
				return new BaseState([action.data]);
			},
			err => {
				const msg = this.translate.instant('login.password_recovery.email_confirmation_not_sent');
				this.showSnackBar(msg);
				throw new Error(err.body.detail);
			}
		);
	}

	async deactivateDetail(action: BaseStateModel<UserDetailModel>): Promise<any> {
		return this.userService.deactivateAccount(
			{ id: action.data.id },
			(res: RestBaseResponse<void>) => {
				this.showSnackBar('User deactivated');
				action.data.is_active = false;
				return new BaseState([action.data]);
			},
			err => {
				this.showSnackBar('There was a problem, the user may not been deactivated', 'Ok');
				throw new Error(err.body.detail);
			}
		);
	}

	async mergeUserListAvatar(userList: BaseStateModel<UserDetailModel[]>): Promise<BaseStateModel<UserDetailModel[]>> {
		const promises = [];
		for (let i = 0; i < userList.data.length; i++) {
			promises.push(
				this.userService
					.getUserAvatar({ id: userList.data[i].id })
					.then(res => {
						userList.data[i].avatar = res.icon ? res.icon : null;
					})
					.catch(err => console.error(err))
			);
		}
		return Promise.all(promises).then(() => {
			return userList;
		});
	}

	async getContextCodeDescription(
		userList: BaseStateModel<UserDetailModel[]>
	): Promise<BaseStateModel<UserDetailModel[]>> {
		return userList;
	}

	showSnackBar(message: string, action = '', duration = 3000) {
		let finalMessage = this.translate.instant(message);
		finalMessage = this.sentenceCasePipe.transform(finalMessage);
		this.snackBar.open(finalMessage, action, { duration: duration });
	}
}
