import { Directive, ElementRef, Input } from '@angular/core';
import { ObjectNestedValueByPathEnum, ObjectNestedValueByPathModel } from '@saep-ict/angular-core';
import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import { AppUtilService } from '../../service/util/app-util.service';

@Directive({
	selector: '[articleTableWrapperCellDefaultHandler]'
})
export class ArticleTableWrapperCellDefaultHandler {
	@Input('configuration') set configuration(e: { row: ArticlePouchModel, name: string }) {
		if (e) {
			const nestedValue: ObjectNestedValueByPathModel = this.utilService.checkObjectNestedValueByPath(e.row, e.name);
			this.elementRef.nativeElement.insertAdjacentHTML(
				'beforeend',
				nestedValue.type === ObjectNestedValueByPathEnum.FOUND ? nestedValue.value : ''
			);
		}
	}
    constructor(
		private elementRef: ElementRef,
		private utilService: AppUtilService
	) { }
}
