import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogProductKitComponent } from '../../widget/dialog/dialog-product-kit/dialog-product-kit.component';
import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';

@Injectable({
	providedIn: 'root'
})
export class UtilArticleKitService {
	constructor(private dialog: MatDialog) {}

	openDialogKit(article: ArticlePouchModel) {
		this.dialog.open(DialogProductKitComponent, {
			data: {
				title: article.description,
				productList: article.articleDescription.related_kit_list
			},
			panelClass: 'dialog-medium'
		});
	}
}
