import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrderStateModel } from '@saep-ict/angular-spin8-core';
import { OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import moment from 'moment';

@Component({
	selector: 'dialog-order-set-paid',
	templateUrl: './dialog-order-set-paid.component.html',
	styleUrls: ['./dialog-order-set-paid.component.scss']
})
export class DialogOrderSetPaidComponent implements OnInit {
	form: FormGroup;
	order: OrderStateModel;

	constructor(
		public dialogRef: MatDialogRef<DialogOrderSetPaidComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		private fb: FormBuilder
	) {
		this.order = this.data['order'];
		this.formCreate();
		if (this.order) {
			const payment_date = this.order.header['actual_payment_date'];
			if (payment_date) {
				this.form.get('actual_payment_date').setValue(moment(payment_date));
			}
		}
	}

	formCreate() {
		this.form = this.fb.group({
			actual_payment_date: []
		});
	}

	onFormSubmit() {
		const apd = this.form.get('actual_payment_date').value;
		if (apd) {
			this.order.header['actual_payment_date'] = apd.valueOf();
			this.order.header.status = OrderStatusEnum.READY_TO_SEND;
			this.dialogRef.close(this.order);
		}
	}
	ngOnInit(): void {}
}
