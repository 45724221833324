<!-- TODO: add code_item inside pathlist of the breadcrumb -->
<form class="angelo-theme" *ngIf="form" [formGroup]="form">
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				<mat-card>
					<mat-toolbar>
						<mat-toolbar-row>
							<h1>
								{{ 'informative_page.edit' | translate | sentencecase }}
							</h1>
						</mat-toolbar-row>
					</mat-toolbar>
					<mat-card-content>
						<mat-form-field>
							<mat-select
								formControlName="document_type"
								[placeholder]="'informative_page.field.document_type' | translate | titlecase"
							>
								<mat-option *ngFor="let pageType of informativePageTypeEnum" [value]="pageType">
									{{ 'informative_page.type.' + pageType | translate | titlecase }}
								</mat-option>
							</mat-select>
						</mat-form-field>

						<mat-tab-group
							[selectedIndex]="
								utilTranslateService.languages.indexOf(
									language.DEFAULT ? language.DEFAULT : language.FALLBACK
								)
							"
						>
							<ng-container formArrayName="language_list">
								<ng-container
									*ngFor="
										let language of returnFormFieldByName('language_list').controls;
										let language_index = index
									"
									[formGroupName]="language_index"
								>
									<mat-tab dynamicHeight="true">
										<ng-template mat-tab-label>
											<div *ngIf="language.touched && !language.valid">
												<mat-icon>error_outline</mat-icon>
											</div>
											&nbsp;
											<span>{{ language.value.language | uppercase }}</span>
										</ng-template>
										<div class="row">
											<div class="col-12">
												<!-- Title -->
												<mat-form-field>
													<input
														matInput
														formControlName="title"
														[placeholder]="
															'saep_ict_angular_spin8_core.general.title'
																| translate
																| sentencecase
														"
													/>
												</mat-form-field>
												<!-- Subtitle -->
												<mat-form-field>
													<input
														matInput
														formControlName="subtitle"
														[placeholder]="
															'saep_ict_angular_spin8_core.general.title'
																| translate
																| sentencecase
														"
													/>
												</mat-form-field>
												<!-- Body -->
												<mat-form-field>
													<textarea
														matInput
														class="textarea"
														formControlName="body"
														[placeholder]="
															'informative_page.field.body' | translate | sentencecase
														"
													></textarea>
												</mat-form-field>
											</div>
										</div>
									</mat-tab>
								</ng-container>
							</ng-container>
						</mat-tab-group>
					</mat-card-content>
				</mat-card>
			</div>
		</div>
		<form-button-bar
			[align]="'right'"
			[primaryButtonLabel]="'general.save' | translate | sentencecase"
			(primaryButtonClickEmitter)="onFormSubmit()"
			[primaryButtonIsDisabled]="!form.valid"
			[secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
			(secondaryButtonClickEmitter)="toInformativePageList()"
		></form-button-bar>
	</div>
</form>
