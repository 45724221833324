<mat-dialog-wrapper [title]="'article.add_related' | translate | sentencecase" [showCloseIcon]="false">
	<ng-container content>
		<mat-form-field appearance="fill">
			<mat-label>{{ 'article.selected_list' | translate | sentencecase }}</mat-label>
			<mat-chip-list>
				<mat-chip
					*ngFor="let selectedArticle of selectedArticleList"
					[selectable]="true"
					[removable]="true"
					(removed)="removeCode(selectedArticle.code_item)"
				>
					{{
						configurationCustomer.AppStructure.Erp.has_erp
							? selectedArticle.code_erp
							: selectedArticle.code_item
					}}
					<mat-icon matChipRemove>cancel</mat-icon>
				</mat-chip>
			</mat-chip-list>
		</mat-form-field>

		<article-list-structure-wrapper-plain-list
			[localListHandlerWidgetWrapperData]="localListHandlerData"
			[localListHandlerWidgetWrapperColumnList]="localListHandlerData.columnList"
			[localListHandlerTextFilterShow]="true"
			[localListHandlerPaginatorShow]="true"
			[onItemSelectShow]="true"
			(onItemSelect)="selectCode($event)"
		>
		</article-list-structure-wrapper-plain-list>
	</ng-container>

	<ng-container actions>
		<form-button-bar
			[align]="'right'"
			[primaryButtonLabel]="'general.save' | translate | sentencecase"
			(primaryButtonClickEmitter)="outputCode()"
			[secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
			(secondaryButtonClickEmitter)="dialogRef.close()"
		></form-button-bar>
	</ng-container>
</mat-dialog-wrapper>
