import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	UrlTree,
	CanLoad,
	Route,
	UrlSegment,
	Router
} from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { UtilGuardService } from './util-guard/util-guard.service';

// SE POSTI SULLA STESSA ROTTA (ES: RESERVED-AREA) PRIMA VIENE ESEGUITO canLoad e poi canActivate
@Injectable({
	providedIn: 'root'
})
export class ReservedAreaGuard implements CanActivate, CanLoad {
	@LocalStorage('authenticationToken')
	authenticationToken: string;

	constructor(private router: Router) {}

	// CONTROLLA SE SI PUÒ CARICARE IL MODULO LAZY
	canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
		// console.log('tutto regolare');
		// console.log({
		//   route,
		//   segments,
		// });

		console.log(route.data.permission);

		// this.router.navigate(['/']);
		return true;
	}
	// CONTROLLA SE SI PUÒ ATTIVARE LA ROTTA
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (!this.authenticationToken) {
			return this.router.navigate(['/']);
		}
		return true;
	}
}
