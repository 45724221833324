import { Component } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import { InformativePageStateAction } from '../../../../state/informative-page/informative-page.actions';
import { AngularSpin8CoreUtilTranslateService } from '@saep-ict/angular-spin8-core';
import { Language } from '../../../../enum/language.enum';
import { BaseStateModel, SubscribeManagerService, AngularCoreUtilService } from '@saep-ict/angular-core';
import { StateFeature } from '../../../../state';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TitleCasePipe } from '@angular/common';
import { InformativePageEnum, PATH_URL, ROUTE_URL, InformativePageModel } from '@saep-ict/angular-spin8-core';
import { UtilBreadcrumbService } from '../../../../service/util/util-breadcrumb.service';

@Component({
	selector: 'informative-page-detail',
	templateUrl: './informative-page-detail.component.html',
	styleUrls: ['./informative-page-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class InformativePageDetailComponent {
	form: FormGroup;
	language = Language;
	breadcrumbPathList: string[];
	informativePageType: string;
	informativePageTypeEnum: InformativePageEnum.Type[] = Object.values(InformativePageEnum.Type);
	informativePage$: Observable<BaseStateModel<InformativePageModel[]>> = this.store.select(
		StateFeature.getInformativePageState
	);
	informativePage: InformativePageModel;

	constructor(
		private store: Store,
		private router: Router,
		private fb: FormBuilder,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		private route: ActivatedRoute,
		private subscribeManagerService: SubscribeManagerService,
		public utilService: AngularCoreUtilService,
		private titleCasePipe: TitleCasePipe,
		private utilBreadcrumbService: UtilBreadcrumbService
	) {
		this.informativePageType = this.route.snapshot.params['informativePageType'];
		this.breadcrumbPathList = [
			'Home',
			'Detail',
			this.titleCasePipe.transform(this.utilService.formatPlaceholder(this.informativePageType))
		];

		this.store.dispatch(InformativePageStateAction.load({ slug: this.informativePageType }));
		this.subscribeManagerService.populate(
			this.subscribeInformativePage().subscribe(),
			'subscribe-informative-page'
		);

		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('informative_pages');
		this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['informative_pages']);
	}

	/**
	 *  Subscriptions
	 */

	subscribeInformativePage() {
		return this.informativePage$.pipe(
			filter(state => !!(state && state.data)),
			map(state => {
				this.informativePage =
					state.data.find(infoPage => infoPage.document_type === this.informativePageType) || null;
				this.createForm();
			})
		);
	}

	/**
	 *  Form
	 */

	createForm() {
		const languageList: Language[] = [...new Set(Object.values(Language))];
		const informativePageInlanguageList: FormGroup[] = [];
		for (let language of languageList) {
			const informativePageInLanguage = this.informativePage
				? this.informativePage.language_list.find(lang => lang.language === language)
				: null;
			informativePageInlanguageList.push(
				this.fb.group({
					language: [informativePageInLanguage ? informativePageInLanguage.language : language],
					title: [informativePageInLanguage ? informativePageInLanguage.title : null, [Validators.required]],
					subtitle: [informativePageInLanguage ? informativePageInLanguage.subtitle : null],
					body: [informativePageInLanguage ? informativePageInLanguage.body : null]
				})
			);
		}
		this.form = this.fb.group({
			document_type: [
				{ value: this.informativePage ? this.informativePage.document_type : null, disabled: true },
				[Validators.required]
			],
			language_list: this.fb.array(informativePageInlanguageList)
		});
	}

	returnFormFieldByName(formFieldName: string) {
		return this.form.get(formFieldName) as FormArray;
	}

	onFormSubmit() {
		let informativePage = _.cloneDeep(this.form.getRawValue());
		this.store.dispatch(InformativePageStateAction.save({ data: informativePage }));
		this.toInformativePageList();
	}

	/**
	 *  Utils
	 */

	toInformativePageList() {
		this.router.navigate([`${PATH_URL.PRIVATE}`, `${ROUTE_URL.informativePage}`]);
	}

	ngOnDestroy() {
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}
}
