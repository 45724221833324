<app-order-detail-checkout [isOffer]="true">
	<!-- Altre Linee -->
	<mat-card *ngIf="offer?.header?.status">
		<offer-free-line-list-wrapper
			[localListHandlerWidgetWrapperData]="listPageBaseData"
			[localListHandlerWidgetWrapperColumnList]="columns"
			[localListHandlerTextFilterShow]="true"
			[localListHandlerPaginatorShow]="true"
			[localListHandlerTitle]="'offer.line.name_plural' | translate | sentencecase"
			(onItemSelect)="openDialogOfferFreeLine($event)"
			(selectDelete)="deleteOfferFreeLine($event)"
			[showActions]="offer?.header?.status === 'DRAFT'"
		>
		</offer-free-line-list-wrapper>
	</mat-card>
</app-order-detail-checkout>

<button
	*ngIf="offer?.header?.status === 'DRAFT'"
	class="mat-fab-button"
	(click)="openDialogOfferFreeLine({})"
	mat-fab
	[matTooltip]="'offer.line.add_new' | translate | sentencecase"
	matTooltipPosition="left"
>
	<mat-icon>add</mat-icon>
	<span class="label">{{ 'offer.line.add_new' | translate | sentencecase }}</span>
</button>
