import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseStateModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { UserDetailModel, ContextApplicationItemCodeEnum } from '@saep-ict/angular-spin8-core';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { StateFeature } from '../../../state';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { MediaCenterFilter } from '@saep-ict/media-center';
import { ConfigurationSubscribeManager } from '../../../constants/subscribe-manager.constant';
import { SubscribeManagerItem } from '../../../model/subscribe-manager.model';

@Component({
	selector: 'media-center',
	templateUrl: './media-center.component.html',
	styleUrls: ['./media-center.component.scss'],
	providers: [SubscribeManagerService]
})
export class MediaCenterComponent {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;
	showWidget = true;
	customHeaders;
	/**
	 * Se valorizzato il query param `pathInternal`, la libreria cercherà di raggiungere il relativo livello annidato
	 * all'interno del bucket
	 *
	 * @type {MediaCenterFilter}
	 * @memberof MediaCenterComponent
	 */
	mediaCenterFilter: MediaCenterFilter;

	subscribeList: SubscribeManagerItem[] = [
		{ key: 'router-data', observable: this.subscribeRouterData() }
	];

	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	constructor(
		private route: ActivatedRoute,
		private store: Store<UserDetailModel>,
		private utilBreadcrumbService: UtilBreadcrumbService,
		public router: Router,
		private subscribeManagerService: SubscribeManagerService,
	) {
		// retrieve user
		this.user$.pipe(take(1)).subscribe((res: BaseStateModel<UserDetailModel>) => {
			if (res && res.data) {
				this.customHeaders = {
					'context-code': res.data.current_permission.context_code.code,
					'context-application': res.data.current_permission.context_application
				};
				this.user = res.data;
				this.mediaCenterFilter = this.setMediaCenterFilter();
			}
		});
		this.setRouteMetaInformation();
		ConfigurationSubscribeManager.init(this.subscribeList, this.subscribeManagerService);
	}

	ngOnDestroy() {
		this.utilBreadcrumbService.unsetRouteMetaInformation();
		this.subscribeManagerService.destroy();
	}

	subscribeRouterData(): Observable<void> {
		return this.router.events.pipe(
			filter((e: RouterEvent) => !!(e && e.url && e instanceof NavigationEnd)),
			map((e: RouterEvent) => {
				this.mediaCenterFilter = this.setMediaCenterFilter();
				this.setRouteMetaInformation();
				this.showWidget = false;
				setTimeout(() => {
					this.showWidget = true;
				}, 0);
			})
		);
	}

	setMediaCenterFilter(): MediaCenterFilter {
		return {
			path: this.route.snapshot.queryParams.pathInternal ? `/${this.route.snapshot.queryParams.pathInternal}/` : '/',
			bucketFolderName: this.route.snapshot.paramMap.get('bucketFolderName'),
			actionDownloadOnly:
				this.user.current_permission.context_application === ContextApplicationItemCodeEnum.BACKOFFICE ||
				this.user.current_permission.context_application === ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN ?
				false :
				true,
			actionDownloadArchive: true,
			loaderExpandBeyondLibrary: true
		};
	}

	setRouteMetaInformation() {
		const bucketFolderName =
			this.mediaCenterFilter.bucketFolderName ?
			this.mediaCenterFilter.bucketFolderName :
			'mediacenter';
		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle(bucketFolderName);
		this.utilBreadcrumbService.updateActiveNavigationItemSource.next([bucketFolderName]);
	}
}
