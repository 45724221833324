import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormValidatorCustom } from '@saep-ict/angular-core';
import { LoginAuthRequestModel, ROUTE_URL } from '@saep-ict/angular-spin8-core';
import { ConfigurationCustomer } from '../../../../constants/configuration-customer';
import { AuthService } from '../../../../service/rest/auth.service';

/**
 * Contesti in cui viene utilizzato:
 * + /login (parte pubblica)
 * + /authentication/login (parte privata)
 */
@Component({
	selector: 'login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	form: FormGroup;
	userLogin: LoginAuthRequestModel;
	hide = true;
	canAccessToRegistration: boolean =
		ConfigurationCustomer.AppStructure.authenticationWrapper.can_access_to_registration;

	ROUTE_URL = ROUTE_URL;

	constructor(private router: Router, private authService: AuthService, private fb: FormBuilder) {
		this.createForm();
	}

	ngOnInit() {}

	createForm() {
		this.form = this.fb.group({
			email: ['', FormValidatorCustom.email],
			password: ['', Validators.required]
		});
	}

	onFormSubmit() {
		if (this.form.valid) {
			this.userLogin = this.form.value;
			this.authService.login(this.userLogin);
		}
	}

	linkAccessToRegistration(): string {
		return ConfigurationCustomer.AppStructure.authenticationWrapper.link_access_to_registration;
	}
}
