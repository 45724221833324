import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseStateModel, SubscribeManagerService } from '@saep-ict/angular-core';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../../state';
import { UserDetailModel } from '@saep-ict/angular-spin8-core';
import { UtilBreadcrumbService } from '../../../../service/util/util-breadcrumb.service';
import { SideBarPositionValues } from '../../../../enum/sidebar-position-values.enum';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard-agent.component.html',
	styleUrls: ['./dashboard-agent.component.scss'],
	providers: [SubscribeManagerService]
})
export class DashboardAgentComponent implements OnDestroy {

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	sideBarPositionValues = SideBarPositionValues;

	constructor(
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private utilBreadcrumbService: UtilBreadcrumbService
	) {
		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('dashboard');
		this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['dashboard']);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}
}
