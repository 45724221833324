import _ from 'lodash';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { ConfigurationContactContextApplicationColumnMap } from '../../../constants/configuration-customer/contact/contact-column-map.constant'
import { Injector } from '@angular/core';
import { AngularCoreUtilService } from '@saep-ict/angular-core';

const injector = Injector.create({
	providers: [{ provide: AngularCoreUtilService, deps: [] }]
});
const utilService = injector.get(AngularCoreUtilService);

export const projectOverride: ITdDataTableColumn[] = 
  _.cloneDeep(ConfigurationContactContextApplicationColumnMap.baseColumns);