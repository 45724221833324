import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GuidFormatterPipe } from '@saep-ict/angular-core';
import { ViewTypeEnum } from '../../../enum/view-type.enum';
import { ListMode } from '../../address-list/address-list.component';

@Component({
	selector: 'dialog-action-list',
	templateUrl: './dialog-action-list.component.html',
	styleUrls: ['./dialog-action-list.component.scss']
})
export class DialogActionListComponent implements OnInit {
	ViewTypeEnum = ViewTypeEnum;
	ListMode = ListMode;
	title: string = this.data.modalTitle;

	constructor(
		public dialogRef: MatDialogRef<DialogActionListComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		private guidFormatterPipe: GuidFormatterPipe
	) {}

	ngOnInit() {
		// console.log('this.data', this.data);
	}

	getTitle(): string {
		if (this.data.opportunity) {
			return 'Richiesta: ' + this.guidFormatterPipe.transform(this.data.opportunity.code_item);
		} else if (this.data.contact) {
			return 'Contatto: ' + this.data.contact.full_name;
		}
	}
}
