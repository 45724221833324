import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
	BodyTablePouchModel,
	DestinationPouchModel,
	OrderStatusEnum,
	OrganizationPouchModel,
	ProgressOrderStatusEnum
} from '@saep-ict/pouch_agent_models';
import { from, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';
import { HeaderStateModelWithDestination } from '../../model/state/order-state.model';
import { OrderListFilterModel } from '../../service/pouch-db/filter/order-filter.model';
import { OrderListActionEnum, OrderListStateAction } from './order-list.actions';
import { OrderEffects } from '../order/order.effects';
import { UtilOrderService } from '../../service/util/util-order.service';
import { UtilPouchService } from '../../service/util/util-pouch.service';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';
import { StateFeature } from '..';
import {
	OrderStateModel,
	PouchDbConstant,
	PouchDbModel,
	ProductVariationStateModel,
	ProductVariationTypeEnum
} from '@saep-ict/angular-spin8-core';
import { ConfigurationCustomer } from '../../constants/configuration-customer';

@Injectable()
export class OrderListEffects {
	loadAll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OrderListActionEnum.LOAD_ALL),
			mergeMap((action: BaseStateModel<OrderStateModel[]>) => from(this.getAllOrders(action))),
			map((orders: BaseStateModel<OrderStateModel[]>) => OrderListStateAction.update(orders)),
			catchError((error, caught) => {
				this.store.dispatch(OrderListStateAction.error(null));
				return caught;
			})
		)
	);

	loadAllAndGetDraft$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OrderListActionEnum.LOAD_ALL_AND_GET_DRAFT),
			mergeMap((action: BaseStateModel<OrderStateModel[]>) => from(this.getAllOrders(action))),
			mergeMap(async (orders: BaseStateModel<OrderStateModel[]>) => {
				const order = orders.data
					.sort((a, b) => b.header.date || 0 - a.header.date || 0)
					.find(ord => ord.header.status == OrderStatusEnum.DRAFT);
				if (order) {
					return OrderListStateAction.update(new BaseState([order]));
				} else {
					return OrderListStateAction.skip();
				}
			}),
			catchError((error, caught) => {
				this.store.dispatch(OrderListStateAction.error(null));
				return caught;
			})
		)
	);

	loginContextCode$: Observable<BaseStateModel<BodyTablePouchModel>> = this.store.select(
		StateFeature.getLoginContextCodeState
	);
	loginContextCode: OrganizationPouchModel;

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private orderEffects: OrderEffects,
		private utilOrderService: UtilOrderService,
		private utilPouchService: UtilPouchService,
		private pouchAdapterSelectorService: PouchAdapterSelectorService
	) {
		this.loginContextCode$.subscribe(res => {
			this.loginContextCode = res ? res.data : null;
		});
	}

	async getAllOrders(action: BaseStateModel<OrderStateModel[]>): Promise<BaseStateModel<OrderStateModel[]>> {
		const documentName = 'order';
		const allDocsParam: any = {
			include_docs: true,
			startkey:
				documentName +
				ConfigurationCustomer.AppStructure.noSqlDocSeparator,
			endkey:
				documentName +
				ConfigurationCustomer.AppStructure.noSqlDocSeparator +
				PouchDbConstant.allDocsLastCharacter
			// endkey: documentName + '_\\' + PouchDbConstant.allDocsLastCharacter     // rimosso perché lascia fuori gli id che iniziano con lettere invece che con numeri
		};

		return this.utilPouchService
			.allDocs<OrderStateModel>(allDocsParam, documentName as PouchDbModel.PouchDbDocumentType)
			.then(async (res: BaseStateModel<OrderStateModel[]>) => {
				const allOrders: OrderStateModel[] = res.data;
				allOrders.forEach(order => (order = this.utilOrderService.addOrderId(order)));

				const allOrderProgress = await (
					await this.pouchAdapterSelectorService.retrieveCurrentAdapter(
						PouchDbModel.PouchDbDocumentType.ORDER
					)
				).orderPouch
					.getOrderProgessList()
					.then(res => {
						return res.rows.map(x => x.doc);
					});

				// map destination
				allOrders.forEach(async order => {
					const headerWithDestination: HeaderStateModelWithDestination = <HeaderStateModelWithDestination>(
						order.header
					);

					// Get order progress
					this.orderEffects.addOrderProgress(order, allOrderProgress);
				});
				return new BaseState(allOrders);
			})
			.catch(err => {
				throw new Error(err.error + err.reason);
			});
	}

	async mergeOrderVariation(
		filter: BaseStateModel<OrderStateModel[], OrderListFilterModel>
	): Promise<BaseStateModel<OrderStateModel[], OrderListFilterModel>> {
		try {
			const orderList = filter;
			for (let order of orderList.data) {
				if (Object.values(ProgressOrderStatusEnum).includes(order.header.status as any)) {
					// order progress
					const orderProgress = order.order_progress;
					if (orderProgress && orderProgress.values) {
						if (!order.order_variation_list) {
							order.order_variation_list = [];
						}
						// Ordina gli oggetti per data DESC e prende il primo
						orderProgress.values.sort((a, b) => (a.date < b.date ? 1 : -1));
						const lastOrderProgress = orderProgress.values[0];

						// values changed
						const valuesChanged = lastOrderProgress.values_changed;
						if (valuesChanged) {
							// Product list della first version di un ordine
							const orderProgressProductList = orderProgress['first_version']['product_list'];
							// Contiene gli indici dei prodotti REPLACED
							const indexListReplacedProducts = [];

							// Product REPLACED / PROPERTY_CHANGED
							for (const valueObj in valuesChanged) {
								// Skip se la proprietà proviene da prototype
								if (!valuesChanged.hasOwnProperty(valueObj)) {
									continue;
								}
								const productListLength = orderProgressProductList.length;

								if (valueObj.startsWith(`root['product_list']`)) {
									for (let i = 0; i < productListLength; i++) {
										if (
											valueObj.startsWith(`root['product_list'][${i}]`) &&
											!indexListReplacedProducts.includes(i)
										) {
											// Estrae il nome della proprietà cambiata
											let propertyChanged = valueObj.replace(`root['product_list'][${i}]`, '');
											if (propertyChanged !== '') {
												propertyChanged = propertyChanged.slice(2, -2);
											}

											// Codice prodotto che ha subito la variazione
											const code_item = orderProgressProductList[i].code_item;

											const obj: Object = valuesChanged[valueObj];
											let productCode: string;
											let productVariationType: any;

											// TODO: verificare il controllo dopo la modifica da code a code_item/code_erp
											if (propertyChanged === 'code_erp') {
												// Product REPLACED
												productCode = obj['old_value'];
												productVariationType = ProductVariationTypeEnum.REPLACED;
												indexListReplacedProducts.push(i);
											} else {
												// Product PROPERTY_CHANGED
												productCode = code_item;
												productVariationType = ProductVariationTypeEnum.PROPERTY_CHANGED;
											}
											const itemChanged: ProductVariationStateModel = {
												productCode: productCode,
												type: productVariationType,
												propertyName: propertyChanged,
												oldValue: obj['old_value'],
												newValue: obj['new_value']
											};
											order.order_variation_list.push(itemChanged);
										}
									}
								}
							}
						}

						// Product ADDED
						const iterableItemAdded = lastOrderProgress.iterable_item_added;
						if (iterableItemAdded) {
							for (const key in iterableItemAdded) {
								// Skip se la proprietà proviene da prototype
								if (!iterableItemAdded.hasOwnProperty(key)) {
									continue;
								}
								const obj: Object = iterableItemAdded[key];
								const itemAdded: ProductVariationStateModel = {
									productCode: obj['code'],
									type: ProductVariationTypeEnum.ADDED
								};
								order.order_variation_list.push(itemAdded);
							}
						}

						// Product REMOVED
						const iterableItemRemoved = lastOrderProgress.iterable_item_removed;
						if (iterableItemRemoved) {
							for (const key in iterableItemRemoved) {
								// Skip se la proprietà proviene da prototype
								if (!iterableItemRemoved.hasOwnProperty(key)) {
									continue;
								}
								const obj: Object = iterableItemRemoved[key];
								const itemRemoved: ProductVariationStateModel = {
									productCode: obj['code'],
									type: ProductVariationTypeEnum.REMOVED
								};
								order.order_variation_list.push(itemRemoved);
							}
						}
					}
				}
			}
			return orderList;
		} catch (err) {
			throw new Error(err.error + err.reason);
		}
	}
}
