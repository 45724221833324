import { Component, OnInit, Inject, ChangeDetectorRef, PLATFORM_ID, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StatusBarOrderB2cService } from '../../../service/status-bar-config/implementation/status-bar-order-b2c.service';
import { BaseStateModel, MediaReplayService } from '@saep-ict/angular-core';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { BreakpointState } from '@angular/cdk/layout';
import { AddressPouchModel, ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { StateFeature } from '../../../state';
import { Store } from '@ngrx/store';
import { UtilPriceService } from '../../../service/util/util-price.service';
import {
	ItemDescriptionModel,
	OrderPriceModel,
	OrderStateModel,
	OrganizationStateModel,
	ShippingMethodModel,
	OrderPriceMap
} from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'app-dialog-order-detail',
	templateUrl: './dialog-order-detail.component.html',
	styleUrls: ['./dialog-order-detail.component.scss'],
	providers: [StatusBarOrderB2cService]
})
export class DialogOrderDetailComponent implements OnInit, OnDestroy {
	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	order: OrderStateModel = this.data.order;
	paymentMethod: ItemDescriptionModel = this.order.header.payment_object;
	price: OrderPriceModel = this.order.header.price;
	shippingAddress: AddressPouchModel = this.order.header.goods_destination_object;
	shippingMethod: ShippingMethodModel = this.order.header.shipping_method_object;

	productList: ArticlePouchModel[] = [];
	orderPriceMap: OrderPriceMap = new OrderPriceMap();

	private _mediaSubscription: Subscription;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		@Inject(PLATFORM_ID) private platform_id: string,
		private cdr: ChangeDetectorRef,
		private store: Store<any>,
		private utilOrderService: UtilOrderService,
		public dialogRef: MatDialogRef<DialogOrderDetailComponent>,
		public mediaReplayService: MediaReplayService,
		public statusBarOrderB2cService: StatusBarOrderB2cService,
		public utilPriceService: UtilPriceService
	) {
		this.organization$.pipe(take(1)).subscribe(res => {
			this.organization = res && res.data ? res.data : null;
		});
	}

	ngOnInit() {
		this.getOrderDetail();
		this.handleStatusBar();
	}

	getOrderDetail() {
		this.productList = this.utilOrderService.returnArticleListByOrderSent(this.order);
		this.orderPriceMap = this.utilPriceService.updateOrderPriceMap(this.order);
	}

	downloadInvoice() {
		console.error('Download invoice not implemented');
	}

	handleStatusBar() {
		this.cdr.detectChanges();
		this._mediaSubscription = this.mediaReplayService.media
			.pipe(debounceTime(100))
			.subscribe((change: BreakpointState) => {
				if (isPlatformBrowser(this.platform_id)) {
					if (window.innerWidth < 1000) {
						this.statusBarOrderB2cService.selectOnly(this.order.header.status);
						this.statusBarOrderB2cService.setCondensed(true);
						// console.log('condensed', this.order.header.status);
						// console.log(this.statusBarOrderB2cService.config.steps);
					} else {
						this.statusBarOrderB2cService.selectUntil(this.order.header.status);
						this.statusBarOrderB2cService.setCondensed(false);
						// console.log('expanded', this.order.header.status);
						// console.log(this.statusBarOrderB2cService.config.steps);
					}
				}
			});
	}

	ngOnDestroy() {
		if (this._mediaSubscription) {
			this._mediaSubscription.unsubscribe();
		}
	}
}
