import { ITdDataTableColumn } from "@covalent/core/data-table";
import _ from "lodash";
import { ConfigurationContactContextApplicationColumnMap } from "../../configuration-customer/contact/contact-column-map.constant";
import { AngularCoreUtilService } from "@saep-ict/angular-core";
import { Injector } from "@angular/core";

const injector = Injector.create({
	providers: [{ provide: AngularCoreUtilService, deps: [] }]
});


// Mostrare: full_name, phone, email,date_creation, contact_detail, area, business_name, actions

const utilService = injector.get(AngularCoreUtilService);
let newColumnList = [...ConfigurationContactContextApplicationColumnMap.baseColumns];
const orderConfirmEmailIndex = utilService.getElementIndex(newColumnList, 'name', 'order_confirm_email');
newColumnList.splice(orderConfirmEmailIndex, 1);

export const projectOverride: ITdDataTableColumn[] = 
_.cloneDeep(newColumnList);