import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseStateModel, BaseState } from '@saep-ict/angular-core';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { StatisticActionEnum, StatisticStateAction } from './statistic.action';
import { StatisticModel } from '../../model/statistics.model';
import { StoreUtilService } from '../../service/util/store-util.service';

@Injectable()
export class StatisticEffects {

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StatisticActionEnum.LOAD),
			mergeMap((action: { _id: string }) => from(
				this.storeUtilService.getCouchDetailAndReturnInDetailState(new BaseState(action))
			)),
			map((action: BaseStateModel<StatisticModel.Document>) => StatisticStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(StatisticStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private storeUtilService: StoreUtilService
	) {}

}
