import { Route } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BasePouchModel, BodyTablePouchModel, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { CustomerAppConfig } from '../../../customer-app.config';
import { BaseState } from '@saep-ict/angular-core';
import { AgentRouteList } from '../../../router/agent-routing';
import { B2BRouteList } from '../../../router/b2b-routing';
import { BackofficeRouteList } from '../../../router/backoffice-routing';
import { B2CRouteList } from '../../../router/b2c-routing';
import { CrmRouteList } from '../../../router/crm-routing';
import { PortalRouteList } from '../../../router/portal-routing';
import { LoginContextCodeStateAction } from '../../../state/common/login/context-code/login-context-code.actions';
import {
	ContextApplicationItemCodeEnum,
	ContextPermission,
	LinkCodeModel,
	PermissionKeyEnum,
	UserDetailModel,
	UserTypeContextModel,
	UserTypePermission,
	BodyTablePouchCustomModel
} from '@saep-ict/angular-spin8-core';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';

export namespace PermissionTypeList {
	export const typeList: UserTypeContextModel[] = [
		{
			permission: PermissionKeyEnum.BACKOFFICE_DASHBOARD,
			route: BackofficeRouteList.routes,
			type: ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN,
			description: 'Backoffice Area (Admin)',
			action_key: 'backoffice_admin'
		},
		{
			permission: PermissionKeyEnum.BACKOFFICE_DASHBOARD,
			route: BackofficeRouteList.routes,
			type: ContextApplicationItemCodeEnum.BACKOFFICE,
			description: 'Backoffice Area',
			action_key: 'backoffice'
		},
		{
			permission: PermissionKeyEnum.AGENT_DASHBOARD,
			route: AgentRouteList.routes,
			type: ContextApplicationItemCodeEnum.AGENT,
			description: 'Agent Area',
			action_key: 'agent'
		},
		{
			permission: PermissionKeyEnum.B2B_DASHBOARD,
			route: B2BRouteList.routes,
			type: ContextApplicationItemCodeEnum.B2B,
			description: 'B2B Area',
			action_key: 'organization'
		},
		{
			permission: PermissionKeyEnum.B2C_DASHBOARD,
			route: B2CRouteList.routes,
			type: ContextApplicationItemCodeEnum.B2C,
			description: 'B2C Area',
			action_key: 'organization'
		},
		{
			permission: PermissionKeyEnum.PORTAL_DASHBOARD,
			route: PortalRouteList.routes,
			type: ContextApplicationItemCodeEnum.PORTAL,
			description: 'Portal Area',
			action_key: 'organization'
		},
		// CRM - TODO
		{
			permission: PermissionKeyEnum.CRM_DASHBOARD,
			route: CrmRouteList.routes,
			type: ContextApplicationItemCodeEnum.CRM,
			description: 'CRM Area',
			action_key: 'crm'
		}
	];
}

export type ContextType = BodyTablePouchModel | OrganizationPouchModel | BodyTablePouchCustomModel;

@Injectable()
export class UtilGuardService {
	constructor(private store: Store<any>, private appConfig: CustomerAppConfig) {}
	checkUserContext(user: UserDetailModel, context: string) {
		let hasContext = false;
		let currentContext: ContextPermission;
		if (context) {
			if (user && user.context_application_list) {
				for (let i = 0; i < user.context_application_list.length; i++) {
					if (user.context_application_list[i].code === context) {
						hasContext = true;
						currentContext = user.context_application_list[i];
						break;
					}
				}
			}
		}
		return { hasContext, currentContext };
	}

	checkUserPermission(user: UserDetailModel, permission: string) {
		let hasPermission = false;
		if (permission) {
			if (user && user.current_permission && user.current_permission.permission) {
				for (let i = 0; i < user.current_permission.permission.length; i++) {
					if (user.current_permission.permission[i] === permission) {
						hasPermission = true;
						break;
					}
				}
			}
		}

		return hasPermission;
	}

	retrievePermissionRoute(
		user: UserDetailModel,
		permissionTypeList?: UserTypeContextModel[]
	): UserTypeContextModel[] {
		if (!permissionTypeList) {
			permissionTypeList = this.retrieveContextPermissionList();
		}
		const authPermissionList: UserTypeContextModel[] = [];
		permissionTypeList.forEach(permissionType => {
			const context = this.checkUserContext(user, permissionType.type);
			// this.checkUserPermission(user, permissionType.permission)
			if (context.hasContext) {
				const permission = new UserTypePermission(permissionType);
				permission.ambiguousRoute =
					context.currentContext.context_code_list && context.currentContext.context_code_list.length > 1
						? true
						: false;
				authPermissionList.push(permission);
			}
		});
		return authPermissionList;
	}

	checkAmbiguousPermission(data: UserTypeContextModel[]) {
		let ambiguousRoute = false;
		if (data.length > 1) {
			ambiguousRoute = true;
		} else {
			ambiguousRoute = data[0].ambiguousRoute;
		}
		return ambiguousRoute;
	}

	dispatchUserContext(permission: UserTypeContextModel, user: UserDetailModel, link?: string | number) {
		if (!link) {
			link = user.context_application_list[0].context_code_list[0].code;
		}
		if (permission) {
			const request: BasePouchModel = {
				_id:
					permission.action_key +
					ConfigurationCustomer.AppStructure.noSqlDocSeparator +
					link.toString()
				};
			this.store.dispatch(LoginContextCodeStateAction.load(new BaseState(request)));
		} else {
			// TODO: error Label
			throw new Error('The permission action does not exist');
		}
	}

	retrieveContextPermissionList(): UserTypeContextModel[] {
		return PermissionTypeList.typeList.filter(permission => {
			return this.appConfig.config.permissionContext.includes(permission.type);
		});
	}

	retrieveContextPermission(key: ContextApplicationItemCodeEnum): UserTypeContextModel {
		return this.retrieveContextPermissionList().find(permission => {
			return permission.type === key;
		});
	}

	retrieveCurrentPermissionFromLinkCode(link_code: LinkCodeModel, user: UserDetailModel) {
		let current_link: ContextPermission;
		let permission: string[] = [];
		const current_context = this.checkUserContext(user, link_code.context);
		if (current_context.hasContext) {
			current_link = current_context.currentContext.context_code_list.find((link: ContextPermission) => {
				return link.code === link_code.code;
			});
		}
		if (current_link) {
			const setPermission = new Set(permission.concat(current_link.permission).concat(user.permission));
			permission = Array.from(setPermission);
		}
		return permission;
	}

	/**
	 * Check user permissions for routing.
	 * If the path element has no permissionKey, the component is shown.
	 */
	filterRouteWithPermission(routeList: Route[], permissionList: string[]) {
		const cloneRouteList: Route[] = [];
		for (let index = 0; index < routeList.length; index++) {
			const route = routeList[index];
			if (
				!route.data ||
				permissionList.find(permission => {
					return (route.data && (route.data['permissionKey'] || [])).includes(permission);
				})
			) {
				cloneRouteList.push(route);
				if (route.children && route.children.length > 0) {
					cloneRouteList[cloneRouteList.length - 1].children = this.filterRouteWithPermission(
						route.children,
						permissionList
					);
				}
			}
		}
		return cloneRouteList;
	}
}
