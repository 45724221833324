import { Component, Input, OnInit } from '@angular/core';
import { FaqConfigurationModel, FaqModel } from '../../model/faq.model';
import { AngularSpin8CoreUtilTranslateService } from '@saep-ict/angular-spin8-core';
import { Language } from '../../enum/language.enum';

@Component({
  selector: 'faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

    @Input('configuration') set configuration(eInput: FaqConfigurationModel) {
        if (eInput) {
            this._configuration = eInput;
            this.utilTranslateService.translate.get('faq').subscribe(eTranslate => {
                const faqCommonList =
                    !eInput.hideCommon ? this.utilTranslateService.translate.store.translations[Language.DEFAULT].faq[eInput.section] : [];
                const faqThemeList =
                    this.utilTranslateService.translate.store.translations[Language.DEFAULT].theme.faq &&
                    this.utilTranslateService.translate.store.translations[Language.DEFAULT].theme.faq[eInput.section] ?
                    this.utilTranslateService.translate.store.translations[Language.DEFAULT].theme.faq[eInput.section] :
                    [];
                for (const faqKey of Object.keys(faqCommonList)) {
                    this.faqCommon.push({ key: faqKey });
                }
                for (const faqKey of Object.keys(faqThemeList)) {
                    this.faqTheme.push({ key: faqKey });
                }
            });
        }
    }
    _configuration: FaqConfigurationModel;

    faqCommon: FaqModel[] = [];
    faqTheme: FaqModel[] = [];

    constructor(
        private utilTranslateService: AngularSpin8CoreUtilTranslateService
    ) { }

    ngOnInit(): void {
    }

}
