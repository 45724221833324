<mat-toolbar *ngIf="title">
	<mat-toolbar-row>
		<h3>{{ title | translate | sentencecase  }}</h3>
	</mat-toolbar-row>
</mat-toolbar>

<mat-toolbar *ngIf="localListHandlerTextFilterShow">
	<mat-toolbar-row>
		{{ 'general.items_number' | translate | sentencecase }}
		<span class="badge ml-3" [class.ml-3]="localListHandlerTitle">
			{{ localListHandlerData?.data?.length || 0 }}
		</span>
	</mat-toolbar-row>
	<mat-toolbar-row
		*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
		class="mt-30"
	>
		<span class="flex-span"></span>
		<td-search-box
			*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[placeholder]="'general.search' | translate | sentencecase"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="true"
			(searchDebounce)="localListHandlerApplyFilter()"
		>
		</td-search-box>
	</mat-toolbar-row>
</mat-toolbar>
<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<td-data-table
		#dataTable
		[data]="localListHandlerData.dataSubset"
		[columns]="localListHandlerData.columnList"
		[sortable]="true"
		(sortChange)="localListHandlerApplyFilter({ sort: $event })"
		[sortBy]="localListHandlerData.sort && localListHandlerData.sort.name ? localListHandlerData.sort.name : null"
		[sortOrder]="
			localListHandlerData.sort && localListHandlerData.sort.order ? localListHandlerData.sort.order : null
		"
		(window:resize)="checkTableColumnVisibility()"
	>
		<!-- Status -->
		<ng-template tdDataTableTemplate="header.status" let-value="value">
			<mat-icon *ngIf="getStatusConfig(value).iconCustom" [svgIcon]="getStatusConfig(value).icon"></mat-icon>
			<mat-icon *ngIf="!getStatusConfig(value).iconCustom">{{ getStatusConfig(value).icon }}</mat-icon>
			{{ getStatusConfig(value).label | translate | sentencecase }}
		</ng-template>
		<!-- Ordine # -->
		<ng-template tdDataTableTemplate="_id" let-value="value" let-row="row">
			<p class="id-column">
				<a href="javascript:void(0)" (click)="onItemSelect.emit(row)">
					{{ row.id_first_row }}
				</a>
				<small class="cell-subtitle" *ngIf="row.id_second_row"> {{ row.id_second_row }} </small>
				<small
					class="badge"
					*ngIf="
						row.csuite &&
						(row.csuite.order_so_type_acquisition === 'A' || row.csuite.order_so_type_acquisition === 'S')
					"
				>
					{{ 'order.erp' | translate | uppercase }}
				</small>
				<small class="cell-subtitle" *ngIf="row.header.customer_order_ref_code">
					{{ row.header.customer_order_ref_code }}
				</small>
			</p>
		</ng-template>
		<!-- <ng-template tdDataTableTemplate="_id" let-value="value" let-row="row">
            <p>
                <a href="javascript:void(0)" (click)="goToOrderDetail(row)">
                    {{ row.csuite && row.csuite.id_prev ? row._id.replace('order_', '') : '-' }}
                </a>
                <br /><small class="cell-subtitle">{{
                    row.csuite && row.csuite.id_prev
                        ? row.csuite.id_prev.replace('order_', '')
                        : (row._id | guidFormatter)
                }}</small>
            </p>
        </ng-template> -->
		<!-- Azienda -->
		<ng-template tdDataTableTemplate="header.organization.business_name" let-value="value" let-row="row">
			<p class="title-card">
				<a
					href="javascript:void(0)"
					(click)="
						selectCompany.emit({ level: row.header?.level, code: row.header?.organization?.code_item })
					"
				>
					<span class="title">{{ value | sentencecase }}</span>
				</a>
				<br />
				<small class="cell-subtitle" *ngIf="row.header.organization?.piva">
					<!-- TODO: controllare che code_erp scenda dal backend -->
					{{
						ConfigurationCustomer.AppStructure.Erp.has_erp && row.header?.organization?.code_erp
							? row.header?.organization?.code_erp + ' | '
							: ''
					}}
					P.IVA {{ row.header?.organization?.tax_data?.vat_number || '-' }}
				</small>
			</p>
		</ng-template>

		<!-- Causale -->
		<ng-template tdDataTableTemplate="header.order_causal_object.description_short" let-value="value">
			{{ value }}
		</ng-template>

		<!-- Data creazione -->
		<ng-template tdDataTableTemplate="header.date" let-value="value" let-row="row">
			<p class="title-card">
				<span [matTooltip]="'Data creazione: ' + (value | date: 'dd/MM/YYYY')">
					{{ value | date: 'dd/MM/YYYY' }}
				</span>
				<br />
				<small class="cell-subtitle" *ngIf="value">{{ value | date: 'HH:mm' }}</small>
			</p>
		</ng-template>

		<!-- Data Trasmissione -->
		<ng-template tdDataTableTemplate="header.submission_date" let-value="value">
			<p class="title-card">
				<span [matTooltip]="'Data creazioneccc: ' + (value | date: 'dd/MM/YYYY')">
					{{ value | date: 'dd/MM/YYYY' }}
				</span>
				<br />
				<small class="cell-subtitle" *ngIf="value">{{ value | date: 'HH:mm' }}</small>
			</p>
		</ng-template>

		<!-- Data consegna -->
		<ng-template tdDataTableTemplate="header.first_evasion_date" let-value="value" let-row="row">
			<p>
				<span
					[ngClass]="{
						'variation-underscore': utilOrderService.getOrderVariationValue(row, 'first_evasion_date')
					}"
					matTooltipClass="allow-break-line"
					[matTooltip]="
						utilOrderService.getOrderVariationValue(row, 'first_evasion_date')
							? ('order.variation.value_updated_from' | translate | sentencecase) +
							  (utilOrderService.getOrderVariationValue(row, 'first_evasion_date') | date: 'dd/MM/YYYY')
							: 'Data consegna: ' + (value | date: 'dd/MM/YYYY')
					"
				>
					{{ value | date: 'dd/MM/YYYY' }}
				</span>
				<!-- <br />
				<small class="cell-subtitle" *ngIf="value">{{ value | date:'HH:mm' }}</small> -->
			</p>
		</ng-template>

		<!-- Destinazione -->
		<ng-template tdDataTableTemplate="header.goods_destination_object" let-value="value" let-row="row">
			<p
				[ngClass]="{
					'variation-underscore': utilOrderService.getOrderVariationValue(row, 'goods_destination')
				}"
				matTooltipClass="allow-break-line"
				[matTooltip]="
					utilOrderService.getOrderVariationValue(row, 'goods_destination')
						? ('order.variation.value_updated_from' | translate | sentencecase) +
						  utilOrderService.getFormattedAddress(row.header.order_progress_detail.goods_destination)
						: ''
				"
			>
				{{ utilOrderService.getFormattedAddress(value) }}
			</p>
		</ng-template>

		<!-- Variation icon -->
		<ng-template tdDataTableTemplate="info" let-value="value" let-row="row">
			<mat-icon
				class="mat-icon variation-icon"
				*ngIf="showGenericVariationIcon(row)"
				[matTooltip]="'order.variation.generic' | translate | sentencecase"
			>
				error
			</mat-icon>
		</ng-template>
		<!-- Total Amount -->
		<ng-template tdDataTableTemplate="header.price.total" let-value="value" let-row="row">
			{{ ( value ? value : 0 ) | utilPriceReturnItemValueFormattedPipe }}
			{{ row.header.currency.description_short }}
		</ng-template>
	</td-data-table>
	<mat-toolbar class="____mt-20">
		<mat-toolbar-row>
			<button
				*ngIf="localListHandlerWidgetWrapperViewAllLink"
				mat-button
				class="mat-stroked-button mt-20"
				[routerLink]="['/' + PATH_URL.PRIVATE + '/' + ROUTE_URL.orders + '/' + orderStatusEnum.PROCESSING]"
			>
				{{ 'general.view_all' | translate | sentencecase }}
			</button>
			<span class="flex-span"></span>
			<mat-paginator
				*ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow"
				#matPaginator
				(page)="localListHandlerApplyFilter({ pagination: $event })"
				[length]="localListHandlerData.pagination.totalFilteredItemCount"
				[pageSize]="localListHandlerData.pagination.pageSize"
				[pageSizeOptions]="[5, 10, 25, 100]"
			>
			</mat-paginator>
		</mat-toolbar-row>
	</mat-toolbar>
</ng-container>
<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
