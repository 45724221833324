import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseStateModel, BaseState } from '@saep-ict/angular-core';
import { from } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ForecastActionEnum, ForecastStateAction } from './forecast.action';
import * as ForecastModel from '../../model/forecast.model';
import { PouchDbOrganizationB2BAdapter } from '../../service/pouch-db/spin8/pouchdb-organization-b2b.adapter';
import { ConfigurationCustomer } from '../../constants/configuration-customer';

@Injectable()
export class ForecastEffects {

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ForecastActionEnum.LOAD),
			mergeMap((action: ForecastModel.LoadQueryParam) => from(this.getForecast(action))),
			map((action: BaseStateModel<ForecastModel.Document>) => ForecastStateAction.update(action))
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ForecastActionEnum.SAVE),
			mergeMap((action: BaseStateModel<ForecastModel.Document>) => from(
				this.pouchDbOrganizationB2BAdapter.basePouch.saveBodyTable<ForecastModel.Document>(action.data))
			),
			map((action: ForecastModel.Document) => ForecastStateAction.saveComplete(new BaseState(action)))
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchDbOrganizationB2BAdapter: PouchDbOrganizationB2BAdapter
	) {}

	getForecast(action: ForecastModel.LoadQueryParam): Promise<BaseStateModel<ForecastModel.Document>> {
		return new Promise(async resolve => {
			try {
				const forecastId = `forecast${ConfigurationCustomer.AppStructure.noSqlDocSeparator}${action.user_code_item}_${action.year}`;
				let forecast: ForecastModel.Document;
				await this.pouchDbOrganizationB2BAdapter.basePouch
				.getDetail<ForecastModel.Document>(forecastId)
				.then(res => { forecast = res; })
				.catch(err => {
					if (err.status === 404) {
						forecast = {
							_id: forecastId,
							valid: true,
							type: 'forecast',
							date_creation: Date.now(),
							year: action.year,
							values: []
						};
					} else {
						this.store.dispatch(ForecastStateAction.error(null));
						throw new Error
					}
				});
				const actionReturn = new BaseState(forecast);
				resolve(actionReturn);
			} catch(err) {
				this.store.dispatch(ForecastStateAction.error(null));
				throw new Error
			}
		});
	}

}
