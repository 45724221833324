<div class="container-fluid">
	<div class="row">
		<div class="col-12">
			<mat-card class="angelo-theme">
				<informative-page-list-wrapper
					[localListHandlerWidgetWrapperData]="listPageBaseData"
					[localListHandlerWidgetWrapperColumnList]="columnList"
					[localListHandlerTextFilterShow]="true"
					[localListHandlerPaginatorShow]="true"
					(onItemSelect)="goToInformativePageDetail($event)"
				>
				</informative-page-list-wrapper>
			</mat-card>
		</div>
	</div>
</div>

<!-- <button
	class="mat-fab-button"
	(click)="addInformativePage()"
	[matTooltip]="'informative_page.add_new' | translate | sentencecase"
	matTooltipPosition="left"
>
	<mat-icon>add</mat-icon>
</button> -->
