import { Injector } from '@angular/core';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { ContactColumnService } from '../../../service/td-data-table/implementation/contact.service';

const injector = Injector.create({
	providers: [{ provide: ContactColumnService, deps: [] }]
});

const contactColumnService = injector.get(ContactColumnService);

export namespace ConfigurationContactContextApplicationColumnMap {
	export const baseColumns: ITdDataTableColumn[] = contactColumnService.columns;

	// export const excludeForB2b: string[] = ['organization.business_name'];
	// export const excludeForCrm: string[] = ['organization.business_name','contact_details', 'order_confirm_email'];
}