import { Directive, ElementRef, Input } from '@angular/core';
import * as UtilPrice from '../../constants/util-price.constants';
import { ArticleListSummaryValueConfiguration } from '../../model/article.model';

@Directive({
	selector: '[articleListSummaryValue]'
})
export class ArticleListSummaryValue {
	@Input('configuration') set configuration(e: ArticleListSummaryValueConfiguration) {
		if (e && e.articleList && e.articleList.length > 0) {
			let articleListValue: number = 0;
			for (const article of e.articleList) {
				articleListValue = articleListValue + article.calculate_price;
			}
			this.elementRef.nativeElement.insertAdjacentHTML(
				'beforeend',
				`${UtilPrice.returnItemValueFormatted(articleListValue, 2)} ${e.currency}`
			);
		}
	}
    constructor(
		private elementRef: ElementRef
	) { }
}
