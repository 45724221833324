import { BaseTableListService, ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ContactColumnService extends BaseTableListService {
	constructor(@Inject(TranslateService) public translate: TranslateService) {
		super();
	}
	_columns: ITdDataTableColumnCustom[] = [
		// TODO: capire come gestire lo status
		// {
		// 	name: 'status',
		// 	label: ''
		// 	width: 50
		// },
		{
			name: 'full_name',
			labelPath: 'contact.field.name',
			label: null
		},
		{
			name: 'phone',
			labelPath: 'contact.field.phone',
			label: null
		},
		{
			name: 'email',
			labelPath: 'contact.field.email',
			label: null
		}
	];
}
