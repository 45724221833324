import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	BaseStateModel,
	ITdDataTableColumnCustom,
	SubscribeManagerService,
	AngularCoreUtilService
} from '@saep-ict/angular-core';
import { LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../../state';
import { delay, filter, map, take } from 'rxjs/operators';
import _ from 'lodash';
import { ConfigurationCustomer } from '../../../../constants/configuration-customer';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Event, Router } from '@angular/router';
import {
	InformativePageActionEnum,
	InformativePageStateAction
} from '../../../../state/informative-page/informative-page.actions';
import { InformativePageListWrapperComponent } from '../../../../widget/informative-page/informative-page-list-wrapper/informative-page-list-wrapper/informative-page-list-wrapper.component';
import {
	ContextApplicationItemCodeEnum,
	PATH_URL,
	ROUTE_URL,
	UserDetailModel,
	InformativePageModel,
	InformativePageLangItemModel
} from '@saep-ict/angular-spin8-core';
import { UtilBreadcrumbService } from '../../../../service/util/util-breadcrumb.service';

@Component({
	selector: 'infromative-page-list',
	templateUrl: './informative-page-list.component.html',
	styleUrls: ['./informative-page-list.component.scss'],
	providers: [SubscribeManagerService]
})
export class InformativePageListComponent implements AfterViewInit, OnDestroy {
	@ViewChild(InformativePageListWrapperComponent)
	informativePageListWrapperComponent: InformativePageListWrapperComponent;
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	informativePageList$: Observable<BaseStateModel<InformativePageModel[]>> = this.store.select(
		StateFeature.getInformativePageState
	);
	informativePageList: InformativePageModel[];

	formFilters: FormGroup;

	listPageBaseData = <LocalListHandlerBaseModel<any>>{
		pagination: {
			pageSize: 10
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: ['language_list.title']
			}
		},
		sort: {
			name: 'title',
			order: 'ASC'
		},
		data: []
	};

	columnList: ITdDataTableColumnCustom[];

	constructor(
		private store: Store<any>,
		private fb: FormBuilder,
		private subscribeManagerService: SubscribeManagerService,
		private router: Router,
		public utilService: AngularCoreUtilService,
		private utilBreadcrumbService: UtilBreadcrumbService
	) {
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});

		this.columnList = ConfigurationCustomer.InformativePage.columnList(
			this.user.current_permission.context_application
		);
		this.store.dispatch(InformativePageStateAction.loadAll());
		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('informative_pages');
		this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['informative_pages']);
	}

	ngAfterViewInit() {
		this.subscribeManagerService.populate(
			this.subscribeInformativePageList().subscribe(),
			'subscribeInformativePageList'
		);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	// addInformativePage() {}

	goToInformativePageDetail(selectedInformativePage: InformativePageLangItemModel) {
		switch (this.user.current_permission.context_application) {
			case ContextApplicationItemCodeEnum.BACKOFFICE:
				const documentType = selectedInformativePage.document_type;
				this.router.navigate([`${PATH_URL.PRIVATE}`, `${ROUTE_URL.informativePage}`, `${documentType}`]);
				break;
		}
	}

	/**
	 * Subscription
	 */

	subscribeInformativePageList() {
		return this.informativePageList$.pipe(
			filter((state: BaseStateModel<InformativePageModel[]>) => !!(state && state.data)),
			map((state: BaseStateModel<InformativePageModel[]>) => {
				switch (state.type) {
					case InformativePageActionEnum.UPDATE:
						this.informativePageList = _.cloneDeep(state.data);
						this.informativePageListWrapperComponent.updateListPageBaseData(this.informativePageList);
						break;
					case InformativePageActionEnum.ERROR:
						throw new Error(InformativePageActionEnum.ERROR);
				}

				return state;
			})
		);
	}
}
