import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
	AngularCoreUtilService, SubscribeManagerService, BaseStateModel,
	SentencecasePipe, DialogTextEditComponent, ITdDataTableColumnCustom
} from '@saep-ict/angular-core';
import {
	ArticleDescriptionItem,
	ArticlePouchModel,
	ArticleTableMetaInformation,
	OrderStatusEnum,
	OrganizationPouchModel,
	LocalListHandlerBaseModel
} from '@saep-ict/pouch_agent_models';
import { UtilArticleKitService } from '../../../service/util/util-article-kit.service';
import { UtilPriceService } from '../../../service/util/util-price.service';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';
import { TdDataTableComponent } from '@covalent/core/data-table';
import _ from 'lodash';
import {
	ArticleEnum,
	OrderRowModel,
	AngularSpin8CoreUtilTranslateService,
	UserDetailModel,
	ContextApplicationItemCodeEnum
} from '@saep-ict/angular-spin8-core';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { LangChangeEvent } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../state';
import { MatDialog } from '@angular/material/dialog';
import { DialogImageGalleryComponent } from '../../dialog/dialog-image-gallery/dialog-image-gallery.component';
import { ArticleTableConfigurationAction } from '../../../model/article.model';
import { OrderStateModel } from '@saep-ict/angular-spin8-core';
import { TicketCenterMainService } from '@saep-ict/ticket-center';
import { TicketCenterBodyCustomField } from '../../../constants/configuration-customer/ticket-center/body-custom-field.constant';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';

@Component({
	selector: 'article-table-wrapper',
	templateUrl: './article-table-wrapper.component.html',
	styleUrls: ['./article-table-wrapper.component.scss'],
	providers: [SubscribeManagerService]
})
export class ArticleTableWrapperComponent implements OnInit {
	@ViewChild('dataTable') set dataTable(t: TdDataTableComponent) {
		if (t && this.updateTable) {
			this.updateTable = false;
			this._dataTable = t;
			// this.checkTableColumnData(window.innerWidth);
			// TODO: verificare in futuro circa l'intenzione di mantenere anche questa gestione deio tester
			// this.articleTesterHandler();
			this._dataTable.refresh();
		}
	}
	_dataTable: TdDataTableComponent;
	updateTable = true;

	@Input('localListHandlerData') set localListHandlerData(e: LocalListHandlerBaseModel<ArticlePouchModel>) {
		if (e) {
			this._localListHandlerData = e;
			const columnWidthConfigurationClassItem: ITdDataTableColumnCustom =
				this._localListHandlerData.columnList.find(i => i.name === "column-width-configuration-class");
			if (columnWidthConfigurationClassItem) {
				this.columnWidthConfigurationClass = columnWidthConfigurationClassItem.label;
			}
			for (const article of this._localListHandlerData.dataSubset) {
				this.articleDiscountHandler(article);
				// in questo momento il componente potrebbe ricevere anche un array di soli articleDescription
				// dunque privi a loro volta di un sotto livello articleDescription al quale fanno riferimento
				// i metodi seguenti
				if (article.articleDescription) {
					this.articleDescriptionHandler(article);
					this.inputQuantityDisableHandler(article);
					this.imageGalleryShowandler(article);
					article.calculate_price = article.calculate_price ? article.calculate_price : 0;
				}
			}
		}
	};
	_localListHandlerData: LocalListHandlerBaseModel<ArticlePouchModel>;
	@Input() sortable;
	@Input() organization: OrganizationPouchModel;
	@Input() onItemSelectShow: boolean;
	@Input() localListHandlerDataGlobalSubset: number = 0;
	@Input('configurationAction') configurationAction: ArticleTableConfigurationAction;
	@Input() order: OrderStateModel;

	@Output() onItemSelect = new EventEmitter();
	@Output() sortChange = new EventEmitter();
	@Output() onArticleChange = new EventEmitter<OrderRowModel>();
	@Output() onDeleteArticle: EventEmitter<ArticlePouchModel | ArticleDescriptionItem> = new EventEmitter();

	orderStatusEnum = OrderStatusEnum;
	configurationCustomer = ConfigurationCustomer;
	articleEnum = ArticleEnum;
	ticketCenterBodyCustomField = TicketCenterBodyCustomField;
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	isTablet: boolean;
	columnWidthConfigurationClass: string;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	constructor(
		private subscribeManagerService: SubscribeManagerService,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		private utilPriceService: UtilPriceService,
		private currencyPipe: CurrencyPipe,
		public utilArticleKitService: UtilArticleKitService,
		public utilService: AngularCoreUtilService,
		public utilOrderService: UtilOrderService,
		private store: Store,
		private dialog: MatDialog,
		private sentenceCasePipe: SentencecasePipe,
		public ticketCenterMainService: TicketCenterMainService,
	) {
		this.loadStaicData();
		this.subscribeManagerInit();
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	loadStaicData() {
		this.user$.pipe(take(1)).subscribe((store: BaseStateModel<UserDetailModel>) => {
			this.user = store.data;
		});
	}

	subscribeManagerInit() {
		this.subscribeManagerService.populate(
			this.utilTranslateService.translate.onLangChange.subscribe((e: LangChangeEvent) => {
				for (const article of this._localListHandlerData.dataSubset) {
					this.articleDescriptionHandler(article);
				}
				this._dataTable.refresh();
			}),
			'utilTranslateService.translate.onLangChange'
		);
	}

	retrieveArticlePrice(article: ArticlePouchModel) {
		return this.currencyPipe.transform(
			this.utilPriceService.retrievePriceWithDiscount(
				article.articlePrice.price,
				article.articlePrice.discount,
				article.discount_agent
			),
			'EUR',
			'symbol',
			'',
			'it'
		);
	}

	/**
	 * Check column visibility based on window width
	 * @param width This is the width of the window (px)
	 */
	// checkTableColumnData(width: number) {
	// 	const _isTablet = this.isTablet;
	// 	this.isTablet = width <= 800;

	// 	if (_isTablet !== this.isTablet) {
	// 		['description'].forEach(columnName => {
	// 			this.changeVisiblityColumn(columnName, this.isTablet);
	// 		});

	// 		// TODO - problem Maximum Excedeed Stack
	// 		// ['code'].forEach(columnName => {
	// 		// 	this.changeWidthColumn(columnName, this.isTablet, 120, 80);
	// 		// });
	// 	}
	// }

	/**
	 * Hide or show a column
	 * @param columnName the name of the column declared in the ITdDataTableColumn
	 * @param hide boolean to hide or not the column
	 */
	// changeVisiblityColumn(columnName: string, hide: boolean) {
	// 	const indexColumn = this.utilService.getElementIndex(
	// 		this._localListHandlerWidgetWrapperColumnList,
	// 		'name',
	// 		columnName
	// 	);
	// 	if (indexColumn !== undefined) {
	// 		this._localListHandlerWidgetWrapperColumnList[indexColumn].hidden = hide;
	// 	}
	// }

	// TODO: verificare in futuro circa l'intenzione di mantenere anche questa gestione deio tester
	// articleTesterHandler() {
	// 	if (this._dataTable._rows.length === this._dataTable.data.length) {
	// 		const testerIndexList: number[] = [];
	// 		const articleTesterReferenceIndexList: number[] = [];
	// 		this._dataTable.data.forEach((row: ArticlePouchModel, i) => {
	// 			const tester: ArticlePouchModel =
	// 				row.articleDescription && row.articleDescription.is_tester ? row : null;
	// 			if (tester) {
	// 				testerIndexList.push(i);
	// 				const articleTesterReference: ArticlePouchModel = this._dataTable.data[i - 1];
	// 				if (
	// 					articleTesterReference &&
	// 					articleTesterReference.articleDescription &&
	// 					articleTesterReference.articleDescription.related_tester &&
	// 					articleTesterReference.articleDescription.related_tester === tester.code_item
	// 				) {
	// 					articleTesterReferenceIndexList.push(i - 1);
	// 				}
	// 			}
	// 		});
	// 		if (testerIndexList.length) {
	// 			this._dataTable._rows.forEach((element, index) => {
	// 				if (testerIndexList.includes(index)) {
	// 					element['_elementRef'].nativeElement.classList.add('product-related__child');
	// 				}
	// 			});
	// 		}
	// 		if (articleTesterReferenceIndexList.length) {
	// 			this._dataTable._rows.forEach((element, index) => {
	// 				if (articleTesterReferenceIndexList.includes(index)) {
	// 					element['_elementRef'].nativeElement.classList.add('product-related__parent');
	// 				}
	// 			});
	// 		}
	// 	}
	// }

	articleDescriptionHandler(article: ArticlePouchModel) {
		article.description = this.utilTranslateService.getTranslationFromLanguage(
			article.articleDescription?.language_list
		).description;
	}

	articleDiscountHandler(article: ArticlePouchModel) {
		let deiscountDescription: string = '';
		if (article.articlePrice && article.articlePrice.discount && article.articlePrice.discount.length) {
			for (let i = 0; i < article.articlePrice.discount.length; i++) {
				deiscountDescription = deiscountDescription + article.articlePrice.discount[i].value + '%';
				if (i !== article.articlePrice.discount.length - 1) {
					deiscountDescription = deiscountDescription + ' + ';
				}
			}
		}
		if (!article.tableMetaInformation) {article.tableMetaInformation = <ArticleTableMetaInformation>{};}
		if (deiscountDescription !== '') {
			article.tableMetaInformation.discountDescription = deiscountDescription;
			article.tableMetaInformation.priceWithDiscountDescription = this.retrieveArticlePrice(article);
		} else {
			article.tableMetaInformation.discountDescription = '0%';
			if (article.discount_agent) {
				article.tableMetaInformation.priceWithDiscountDescription = this.retrieveArticlePrice(article);
			}
		}
	}

	// TODO: decidere circa la modellazione delle prop. di utility che servono solo di appoggio per la table
	inputQuantityDisableHandler(article: ArticlePouchModel) {
		article.tableMetaInformation.inputQuantityDisable = this.canInputArticleQuantity(article);
	}

	imageGalleryShowandler(article: ArticlePouchModel) {
		article.tableMetaInformation.hasGalleryImage =
			this.utilTranslateService.getImageWithLanguage(article) ? true : false;
	}

	canInputArticleQuantity(article: ArticlePouchModel): boolean {
		let isAvailable: boolean = this.utilOrderService.articleQuantityIsAvailable(article, this.organization);
		if (
			(
				!(article.articlePrice && article.articlePrice.price) &&
				article.articleDescription.free_sample !== 'S'
			) ||
			!(this.order && this.order.header.status ===  OrderStatusEnum.DRAFT)
		) {
			isAvailable = false;
		}
		return isAvailable;
	}

	dialogGallery(article: ArticlePouchModel) {
		this.dialog.open(DialogImageGalleryComponent, {
			data: {
				article: article
			},
			disableClose: true,
			panelClass: ['angelo-theme-dialog', 'image-gallery']
		});
	}

	articleNoteChange(e: any, row: ArticlePouchModel) {
		const dialog = this.dialog.open(DialogTextEditComponent, {
			data: {
				title: `
					${
						this.sentenceCasePipe.transform(
							this.utilTranslateService.translate.instant('comment.name') +
							' ' +
							this.utilTranslateService.translate.instant('product.name')
						)
					}
					${ConfigurationCustomer.AppStructure.Erp.has_erp ? row.code_erp : row.code_item}`,
				text: row.note_order,
				disabled: !(this.order.header.status === OrderStatusEnum.DRAFT),
				optional: true
			},
			panelClass: ['angelo-theme-dialog', 'note-change'],
		});
		dialog.afterClosed().subscribe(res => {
			if (typeof res === 'string') {
				if (res === '' || !res.replace(/\s/g, '').length) {
					row.note_order = null;
				} else {
					row.note_order = res;
				}
				this.onArticleChange.emit(
					{
						event: e, row: row, key: 'note_order'
					}
				);
			}
		});
	}
}
