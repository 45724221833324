import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { BaseStateModel } from '@saep-ict/angular-core';
import { StateFeature } from '../../state';
import { AuthService } from '../rest/auth.service';
import { LinkCodeModel, UserDetailModel } from '@saep-ict/angular-spin8-core';

@Injectable()
export class AuthUserStateGuard implements CanActivate {
	@LocalStorage('authenticationToken')
	authenticationToken: string;
	@LocalStorage('link_code') link_code: LinkCodeModel;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	constructor(private router: Router, private authService: AuthService, private store: Store<any>) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		let authState = false;
		if (this.authenticationToken) {
			this.user$.pipe(take(1)).subscribe(res => {
				this.user = res ? res.data : null;
			});
			authState =
				this.user &&
				!(this.link_code || (this.user.current_permission && this.user.current_permission.context_application))
					? true
					: false;

			if (!authState) {
				this.router.navigate(['/']);
			}
			return authState;
		} else {
			this.authService.logout();
			return authState;
		}
	}
}
