<form class="angelo-theme" [formGroup]="form" (ngSubmit)="onFormSubmit()">
	<div class="row">
		<div class="col-md-12">
			<h2 mat-dialog-title>
				{{ 'offer.line.detail' | translate | sentencecase }}
			</h2>
		</div>
	</div>

	<mat-dialog-content class="mat-typography">
		<div class="row">
			<div class="col-md-12">
				<!-- Descrizione -->
				<mat-form-field>
					<mat-label>{{ 'offer.line.field.description' | translate | sentencecase }}</mat-label>
					<input matInput formControlName="description" #description />
					<mat-hint align="end">{{ description.value?.length || 0 }}/{{ descriptionMaxLength }}</mat-hint>
					<mat-error>
						<form-error-message
							ngDefaultControl
							[formControl]="getFormControl('description')"
							[maxLengthValue]="descriptionMaxLength"
						></form-error-message>
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-md-6">
				<!-- Prezzo -->
				<mat-form-field>
					<mat-label>{{ 'offer.line.field.price' | translate | sentencecase }}</mat-label>
					<input matInput appOnlyNumbers type="number" formControlName="price" />
					<mat-error>
						<form-error-message
							ngDefaultControl
							[formControl]="getFormControl('price')"
						></form-error-message>
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-md-6">
				<!-- Sconto -->
				<mat-form-field>
					<mat-label>{{ 'offer.line.field.discount' | translate | sentencecase }}</mat-label>
					<input matInput appOnlyNumbers type="number" formControlName="discount" />
					<mat-error>
						<form-error-message
							ngDefaultControl
							[formControl]="getFormControl('discount')"
						></form-error-message>
					</mat-error>
				</mat-form-field>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button type="button" [mat-dialog-close]="false">
			{{ 'general.cancel' | translate | sentencecase }}
		</button>
		<button mat-button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">
			{{ 'general.confirm' | translate | sentencecase }}
		</button>
	</mat-dialog-actions>
</form>
