import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ListDataPouchModel } from '@saep-ict/pouch_agent_models';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ContactPouchModel } from '@saep-ict/pouch_agent_models';
import { ContactActionEnum, ContactStateAction } from './contact.actions';
import { UtilPouchService } from '../../service/util/util-pouch.service';
import { PouchDbConstant, PouchDbModel } from '@saep-ict/angular-spin8-core';
import { ConfigurationCustomer } from '../../constants/configuration-customer';

@Injectable()
export class ContactEffects {
	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContactActionEnum.SAVE),
			mergeMap((action: BaseStateModel<ContactPouchModel>) => {
				return from(this.createContact(action.data));
			}),
			map((contact: ContactPouchModel) => ContactStateAction.loadAll()),
			catchError((errore, caught) => {
				this.store.dispatch(ContactStateAction.error(null));
				return caught;
			})
		)
	);

	loadAll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContactActionEnum.LOAD_ALL),
			mergeMap((action: BaseStateModel<ListDataPouchModel<ContactPouchModel>>) =>
				// from((await this.pouchAdapterSelectorService.retrieveCurrentAdapter()).contactPouch.getTableContactList())
				from(this.getAllContact(action))
			),
			map((state: BaseStateModel<ListDataPouchModel<ContactPouchModel>>) => ContactStateAction.update(state)),
			catchError((error, caught) => {
				this.store.dispatch(ContactStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(private actions$: Actions, private store: Store<any>, private utilPouchService: UtilPouchService) {}
	async getAllContact(
		action: BaseStateModel<ListDataPouchModel<ContactPouchModel>>
	): Promise<BaseStateModel<ListDataPouchModel<ContactPouchModel>>> {
		const documentName = 'contact';
		const allDocsParam: any = {
			include_docs: true,
			startkey:
				documentName +
				ConfigurationCustomer.AppStructure.noSqlDocSeparator,
			endkey:
				documentName +
				ConfigurationCustomer.AppStructure.noSqlDocSeparator +
				PouchDbConstant.allDocsLastCharacter
		};

		await this.utilPouchService
			.allDocs<ContactPouchModel>(allDocsParam, PouchDbModel.PouchDbDocumentType.CONTACT)
			.then(res => {
				const contact_list = { docs: [] };
				res.data.forEach(doc => {
					if (doc.valid) {
						contact_list.docs.push(doc);
					}
				});
				action.data = contact_list;
			})
			.catch(err => console.log(err));
		return action;
	}

	async createContact(contact: ContactPouchModel): Promise<ContactPouchModel> {
		const res_saved = await this.utilPouchService.saveAnyDocument(contact, PouchDbModel.PouchDbDocumentType.CONTACT);
		return res_saved;
	}
}
