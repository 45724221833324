import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { StatisticsCrmActionEnum, StatisticsCrmStateAction } from './statistics-crm.action';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';
import { StatisticsCrm } from '../../model/statistics-crm.model';
import { ConfigurationCustomer } from '../../constants/configuration-customer';

@Injectable()
export class StatisticsCrmEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StatisticsCrmActionEnum.LOAD),
			mergeMap((action: BaseStateModel<StatisticsCrm>) => from(this.getStatisticsCrm(action))),
			map((action: BaseStateModel<StatisticsCrm>) => StatisticsCrmStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(StatisticsCrmStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchAdapterSelectorService: PouchAdapterSelectorService
	) {}

	async getStatisticsCrm(action: BaseStateModel<any>): Promise<BaseStateModel<StatisticsCrm>> {
		await (await this.pouchAdapterSelectorService.retrieveCurrentAdapter())// .statisticsPouch
		// 	.getStatistics(action.data.code_item)
		.basePouch
			.getDetail('statistic' + ConfigurationCustomer.AppStructure.noSqlDocSeparator + action.data.code_item)
			.then(res => {
				action.data = res;
			})
			.catch(err => {
				console.log(err);
				return action;
			});
		return action;
	}
}
