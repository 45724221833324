import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BaseState, BaseStateModel, RestBaseMessageError, SentencecasePipe } from '@saep-ict/angular-core';
import {
	OrganizationStateModel,
	PouchDbModel,
	AngularSpin8CoreOrganizationService
} from '@saep-ict/angular-spin8-core';
import { BasePouchModel } from '@saep-ict/pouch_agent_models';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ConfigurationCustomer } from '../../constants/configuration-customer';
import { RegistrationTypeEnum } from '../../enum/registration.enum';
import { PouchErrorResponse } from '../../service/pouch-db/model/pouch-base-response.model';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';
import { OrganizationActionEnum, OrganizationStateAction } from './organization.actions';

@Injectable()
export class OrganizationEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OrganizationActionEnum.LOAD),
			mergeMap((action: BaseStateModel<BasePouchModel>) => from(this.getOrganization(action.data))),
			// mergeMap((action: BaseStateModel<BasePouchModel>) =>
			// 	from(this.storeUtilService.getCouchDetailAndReturnInDetailState<BodyTablePouchCustomModel>(action))
			// ),
			map((organization: BaseStateModel<OrganizationStateModel>) => OrganizationStateAction.update(organization)),
			catchError((error, caught) => {
				this.store.dispatch(OrganizationStateAction.error(null));
				return caught;
			})
		)
	);

	createNewItem$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OrganizationActionEnum.CREATE_ITEM_API),
			mergeMap(
				(
					action: BaseStateModel<{
						organization: OrganizationStateModel;
						registrationType?: RegistrationTypeEnum;
					}>
				) => from(this.postOrganization(action))
			),
			map((organization: BaseStateModel<OrganizationStateModel>) =>
				OrganizationStateAction.saveSuccess(organization)
			),
			catchError((error, caught) => {
				this.store.dispatch(OrganizationStateAction.error(null));
				return caught;
			})
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OrganizationActionEnum.SAVE),
			mergeMap((action: BaseStateModel<OrganizationStateModel>) => from(this.putOrganization(action.data))),
			mergeMap((action: BaseStateModel<OrganizationStateModel>) => from(this.addCode(action))),
			map((organization: BaseStateModel<OrganizationStateModel>) =>
				OrganizationStateAction.saveSuccess(organization)
			),
			catchError((error, caught) => {
				this.store.dispatch(OrganizationStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private organizationService: AngularSpin8CoreOrganizationService,
		private snackBar: MatSnackBar,
		private translate: TranslateService,
		private sentenceCasePipe: SentencecasePipe,
		private pouchAdapterSelectorService: PouchAdapterSelectorService // private storeUtilService: StoreUtilService
	) {}

	async getOrganization(data: BasePouchModel): Promise<BaseStateModel<OrganizationStateModel>> {
		try {
			const agent = await (
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter(
					PouchDbModel.PouchDbDocumentType.ORGANIZATION
				)
			).basePouch.getDetail(data._id);
			return new BaseState(agent);
		} catch (err) {
			throw new Error(err);
		}
	}

	// Crea organizzazione tramite API
	async postOrganization(
		action: BaseStateModel<{ organization: OrganizationStateModel; registrationType?: RegistrationTypeEnum }>
	): Promise<BaseStateModel<OrganizationStateModel>> {
		action.data.organization._rev ? delete action.data.organization._rev : null;
		return this.organizationService
			.postNewOrganization({
				organization: action.data.organization,
				registration_type: action.data.registrationType || null
			})
			.then(async res => {
				return new BaseState(res.data.organization);
			})
			.catch((err: RestBaseMessageError) => {
				this.showSnackBar(err.body.message);
				throw new Error(err.body.detail);
			});
	}

	// Creo e aggiorna organizzazione
	async putOrganization(data: OrganizationStateModel): Promise<BaseStateModel<OrganizationStateModel>> {
		return (
			await this.pouchAdapterSelectorService.retrieveCurrentAdapter(PouchDbModel.PouchDbDocumentType.ORGANIZATION)
		).basePouch
			.saveBodyTable(data)
			.then(async organization => {
				return new BaseState(organization);
			})
			.catch((err: PouchErrorResponse) => {
				console.log(err);
				throw { error: err.error, reason: err.reason, status: err.status };
			});
	}

	async addCode(state: BaseStateModel<OrganizationStateModel>): Promise<BaseStateModel<OrganizationStateModel>> {
		if (!state.data.code_item || !state.data.code_item) {
			state.data.code_item = state.data._id.replace('organization' + ConfigurationCustomer.AppStructure.noSqlDocSeparator, '');
			state.data.code_item = state.data._id.replace('organization' + ConfigurationCustomer.AppStructure.noSqlDocSeparator, '');

			return (
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter(
					PouchDbModel.PouchDbDocumentType.ORGANIZATION
				)
			).basePouch
				.saveBodyTable(state.data)
				.then(async organization => {
					return new BaseState(organization);
				})
				.catch((err: PouchErrorResponse) => {
					console.log(err);
					throw { error: err.error, reason: err.reason, status: err.status };
				});
		}
		return state;
	}

	showSnackBar(message: string, action = '', duration = 3000) {
		let finalMessage = this.translate.instant(message);
		finalMessage = this.sentenceCasePipe.transform(finalMessage);
		this.snackBar.open(finalMessage, action, { duration: duration });
	}
}
