import { Injector } from '@angular/core';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import { OrganizationColumnService } from '../../../service/td-data-table/implementation/organization-list.service';

const injector = Injector.create({
	providers: [{ provide: OrganizationColumnService, deps: [] }]
});

const organizationColumnService = injector.get(OrganizationColumnService);

export namespace ConfigurationOrganizationContextApplicationColumnMap {
	export const base: ITdDataTableColumnCustom[] = organizationColumnService.columns;

}
