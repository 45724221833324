import { createReducer, on } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { TypedAction } from '@ngrx/store/src/models';
import { StatisticStateAction, StatisticActionEnum } from './statistic.action';
import { StatisticModel } from '../../model/statistics.model';


export namespace StatisticStateReducer {
	export const initialState: BaseStateModel<StatisticModel.Document> = null;

	const _reducer = createReducer(
		initialState,
		on(StatisticStateAction.load, state => state),
		on(StatisticStateAction.update, (state, param) => param),
		on(StatisticStateAction.reset, () => initialState),
		on(StatisticStateAction.error, (state, param) => ({
			data: state ? state.data : null,
			type: param.type
		}))
	);

	export function reducer(
		state: BaseStateModel<StatisticModel.Document>,
		action: TypedAction<StatisticActionEnum>
	) {
		return _reducer(state, action);
	}
}
