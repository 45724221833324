import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Subscription, Observable } from 'rxjs';
import { CustomerAppConfig } from '../../customer-app.config';
import {
	MediaReplayService,
	NavItem,
	BaseStateModel,
	SubscribeManagerService,
	UtilDownload,
	SentencecasePipe
} from '@saep-ict/angular-core';
import { NavigationService } from '../../service/navigation/navigation.service';
import { StateFeature } from '../../state';
import { Store } from '@ngrx/store';
import { take, filter, map } from 'rxjs/operators';
import {
	ContextApplicationItemCodeEnum,
	PATH_URL,
	UserDetailModel,
	OrganizationStateModel,
	PermissionKeyEnum
} from '@saep-ict/angular-spin8-core';
import { OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import { OrganizationActionEnum } from '../../state/organization/organization.actions';
import { ConfigurationCustomer } from '../../constants/configuration-customer';
import { AppUtilService } from '../../service/util/app-util.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogCompanyInfoComponent } from '../../widget/dialog/dialog-company-info/dialog-company-info.component';
import { UtilOrderService } from '../../service/util/util-order.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PermissionUtilService } from '../../service/util/permission-util.service';

@Component({
	selector: 'saep-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	providers: [SubscribeManagerService]
})
export class SaepSidebarComponent implements OnInit, OnDestroy {
	isOpen: boolean;
	@Output() isOpenEvent = new EventEmitter<boolean>();

	sidebarHeight: number;

	items: NavItem[];
	private _itemsSubscription: Subscription;
	private _routerEventsSubscription: Subscription;
	configSubscription: Subscription;
	appTag: string;
	appRelease: string;
    companyName: string;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;
	logoName: string;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
	configurationCustomer = ConfigurationCustomer;

	orderPermission = PermissionKeyEnum.B2B_ORDER_LIST;


	constructor(
		public navigationService: NavigationService,
		private router: Router,
		protected appConfig: CustomerAppConfig,
		public mediaReplayService: MediaReplayService,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
        public utilService: AppUtilService,
        private dialog: MatDialog,
		private utilOrderService: UtilOrderService,
		private sentencecasePipe: SentencecasePipe,
		private translateService: TranslateService,
		private snackBar: MatSnackBar,
		public permissionUtilService: PermissionUtilService
	) {
		this.configSubscription = this.appConfig.config$.subscribe(config => {
			this.appTag = config.tag;
			this.appRelease = config.release;
            this.companyName = config.company.name;
		});

		this.user$.pipe(take(1)).subscribe(res => {
			if (res.data && res.data.current_permission && res.data.current_permission.context_application) {
				this.user = res.data;
				this.logoName = this.getSpin8LogoName(res.data.current_permission.context_application);
			}
		});

		this.subscribeManagerService.populate(this.subscribeOrganization().subscribe(), 'subscribeOrganization');
	}

	/**
	 * Dato un contesto dell'applicazione viene restituito il nome del logo declinato nella versione corretta
	 * @param applicationContext codice che identifica il contesto dell'applicazione attivo
	 * @returns stringa che identifica il nome del logo spin8 declinato nella versione corretta
	 */
	getSpin8LogoName(applicationContext: ContextApplicationItemCodeEnum): string {
		// Scelgo il logo da mostrare in base al contesto
		switch (applicationContext) {
			case ContextApplicationItemCodeEnum.AGENT:
				return 'logo-SPIN8-esales-agents.svg';
				break;

			case ContextApplicationItemCodeEnum.B2B:
				return 'logo-SPIN8-esales-business.svg';
				break;

			case ContextApplicationItemCodeEnum.B2C:
				return 'logo-SPIN8-esales-consumers.svg';
				break;

			case ContextApplicationItemCodeEnum.BACKOFFICE || ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN:
				return 'logo-SPIN8-controlcenter.svg';
				break;

			case ContextApplicationItemCodeEnum.CRM:
				return 'logo-SPIN8-esales-crm.svg';
				break;

			default:
				return 'logo-SPIN8-generic.svg';
				break;
		}
	}

	ngOnInit() {
		this.setIsOpen(this.utilService.isDesktop() ? true : false);
		this._itemsSubscription = this.navigationService.items$.subscribe((items: NavItem[]) => {
			this.items = this.sortRecursive(items, 'position') as NavItem[];
		});
		this._routerEventsSubscription = this.router.events.subscribe(event => {
			if (this.isOpen && !this.utilService.isDesktop()) {
				this.setIsOpen(false);
				this.navigationService.closeAll();
			}
		});

		this.sidebarHeight = this.getHeight();
	}

	setIsOpen(value: boolean = true) {
		this.isOpen = value;
		this.sendIsOpen();
	}

	getPixelsFromTop(index: number, isOpen: boolean) {
		if (isOpen) {
			return 0;
		}

		// header height + border (1px)
		const headerHeight = 81;
		if (index < 1) {
			return headerHeight;
		} else {
			return headerHeight + index * 56;
		}
	}
	toggleSidebar() {
		this.setIsOpen(!this.isOpen);
		this.navigationService.closeAll();
		this.sidebarHeight = this.getHeight();
	}

	closeSidebar() {
		this.setIsOpen(false);
		this.navigationService.closeAll();
		this.sidebarHeight = this.getHeight();
	}

	sortRecursive(array: NavItem[], propertyName: string) {
		array.forEach(item => {
			const keys = _.keys(item);
			keys.forEach(key => {
				if (_.isArray(item[key])) {
					item[key] = this.sortRecursive(item[key], propertyName);
				}
			});
		});
		return _.sortBy(array, propertyName);
	}

	// Receives the count of Sub Items and multiplies it with 56 (height of one SidenavItem) to set the height for animation.
	getSubItemsHeight(item): string {
		const itemCount = this.getOpenSubItemsCount(item);
		return (itemCount > 0 ? itemCount * 40 + 10 : 0) + 'px';
	}

	// Counts the amount of Sub Items there is and returns the count.
	getOpenSubItemsCount(item: NavItem): number {
		let count = 0;

		if (item.hasSubItems() && this.navigationService.isOpen(item)) {
			count += item.subItems.length;

			item.subItems.forEach(subItem => {
				count += this.getOpenSubItemsCount(subItem);
			});
		}

		return count;
	}

	toggleDropdown(item: NavItem): void {
		if (this.utilService.isMobile()) {
			if (item.hasParent()) {
				this.navigationService.toggleCurrentlyOpen(item.parent);
			} else {
				this.navigationService.toggleCurrentlyOpen(item);
			}
		} else {
			if (item.hasSubItems()) {
				this.navigationService.toggleCurrentlyOpen(item);
				this.setIsOpen(true);
			} else {
				this.navigationService.toggleCurrentlyOpen(item.parent);
			}
		}

		this.sidebarHeight = this.getHeight(item);
	}

	ngOnDestroy() {
		if (this._itemsSubscription) {
			this._itemsSubscription.unsubscribe();
		}
		if (this._routerEventsSubscription) {
			this._routerEventsSubscription.unsubscribe();
		}
		if (this.configSubscription) {
			this.configSubscription.unsubscribe();
		}

		this.subscribeManagerService.destroy();
	}

	orderDraftCreate() {
		this.utilOrderService.orderDraftCreate(this.organization);
		this.router.navigate([
			`${PATH_URL.PRIVATE}/orders/${OrderStatusEnum.DRAFT}/${this.user.current_permission.context_code.code}/new/header-edit`
		]);
	}

	subscribeOrganization() {
		return this.organization$.pipe(
			filter(
				(organizationState: BaseStateModel<OrganizationStateModel>) =>
					!!(organizationState && organizationState.data)
			),
			map((organizationState: BaseStateModel<OrganizationStateModel>) => {
				switch (organizationState.type) {
					case OrganizationActionEnum.UPDATE:
						this.organization = organizationState.data;
						break;

					default:
						break;
				}
			})
		);
	}

	sendIsOpen() {
		this.isOpenEvent.emit(this.isOpen);
	}

	getMaxHeight() {
		let delta: number;
		const itemHeight = 60;

		switch (true) {
			case this.utilService.isTablet():
				delta = 429;
				break;
			case this.utilService.isMobile():
				delta = 399;
				break;
			case this.utilService.isDesktop():
			default:
				//delta = 459;
                delta = 499;
		}

		delta = this.organization ? delta : delta - 138;

		delta =
			this.user.current_permission.context_application === this.contextApplicationItemCodeEnum.B2B
				? delta
				: delta - 60;

		const usefulHeight = this.mediaReplayService.deviceH - delta;
		const maxItemsNumber = Math.floor(usefulHeight / itemHeight);
		return maxItemsNumber * itemHeight;
	}

	getHeight(item: NavItem = null) {
		const height = this.items.length * 60 + 10;

		if (item && this.navigationService.currentlyOpen.length > 0) {
			const subItemsOpenHeight = this.getOpenSubItemsCount(item) * 40 + 10 + height;
			return subItemsOpenHeight <= this.getMaxHeight() ? subItemsOpenHeight : this.getMaxHeight();
		}

		return height;
	}

	// isDesktop() {
	// 	// return this.mediaReplayService.deviceW >= 1200;
	// 	return this.mediaReplayService.deviceW >= 1441;
	// }

	// isTablet() {
	// 	const w = this.mediaReplayService.deviceW;
	// 	//return w < 1200 && w >= 768;
	// 	return w < 1441 && w >= 768;
	// }

	// isMobile() {
	// 	return this.mediaReplayService.deviceW < 768;
	// }

	async downloadUserManual() {
		if (UtilDownload.isPopupBlockerActive()) {
			this.snackBar.open(
				this.sentencecasePipe.transform(this.translateService.instant('saep_ict_angular_core.util.download.popup_blocker_off')),
				this.sentencecasePipe.transform(this.translateService.instant('general.close')),
				{ duration: 3000 }
			);
		} else {
			const fileName = `user_manual_${this.user.current_permission.context_application.toLowerCase()}.pdf`;
			const url =
				`${this.appConfig.config.bucketManager.be_url}/user_manual/download-multipart?path=/${fileName}&token=${this.appConfig.token}`;
			UtilDownload.downloadSimulatingClick(url, fileName);
		}
	}

    dialogShowCompanyInfo() {
		this.dialog.open(DialogCompanyInfoComponent, {
			disableClose: false,
			panelClass: ['company-info', 'angelo-theme-dialog']
		});
	}
}
