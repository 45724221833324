import { OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import { StatusBarConfigNewBis, StatusBarElementNew } from '../../../model/status-bar-new.model';

export namespace StatusBar {
	export const statusList: StatusBarElementNew[] = [
		{
			id: OrderStatusEnum.DRAFT,
			label: 'theme.order.status.draft',
			icon: 'custom-pencil',
			iconCustom: true
		},
		{
			id: OrderStatusEnum.ERROR_PREPARING,
			label: 'theme.order.status.error_preparing',
			icon: 'custom-info',
			iconCustom: true,
			goesInOveride: true
		},
		{
			id: OrderStatusEnum.TO_AUTHORIZE,
			label: 'theme.order.status.to_authorize',
			icon: 'custom-timer',
            iconCustom: true
		},
		{
			id: OrderStatusEnum.NOT_AUTHORIZED,
			label: 'theme.order.status.not_authorized',
			icon: 'not-authorized',
            iconCustom: true
		},
		{
			id: OrderStatusEnum.READY_TO_SEND,
			label: 'theme.order.status.ready_to_send',
			icon: 'custom-check_circle',
            iconCustom: true
		},
		{
			id: OrderStatusEnum.SENDING,
			label: 'theme.order.status.sending',
			icon: 'icon-send',
            iconCustom: true
		},
		{
			id: OrderStatusEnum.PROCESSING,
			label: 'theme.order.status.processing',
			icon: 'custom-gear',
			iconCustom: true
		},
		{
			id: OrderStatusEnum.CONSOLIDATED,
			label: 'theme.order.status.consolidated',
			icon: 'custom-push_pin',
			iconCustom: true
		},
		{
			id: OrderStatusEnum.PARTIALLY_FULFILLED,
			label: 'theme.order.status.partially_fulfilled',
			icon: 'custom-timer',
			iconCustom: true
		},
		{
			id: OrderStatusEnum.ERROR_SENDING,
			label: 'theme.order.status.error_sending',
			icon: 'custom-info',
			iconCustom: true,
			goesInOveride: true
		},
		{
			id: OrderStatusEnum.FULFILLED,
			label: 'theme.order.status.fulfilled',
			icon: 'custom-inbox',
            iconCustom: true
		},
		{
			id: OrderStatusEnum.DELETED,
			label: 'theme.order.status.deleted',
			icon: 'custom-trash',
            iconCustom: true,
			goesInOveride: true
		}
	];

	export const agent: StatusBarConfigNewBis = {
		condensed: false,
		statusMap: [
			{
				state: OrderStatusEnum.DRAFT,
				label: 'theme.order.status.draft',
				related_list: [OrderStatusEnum.DRAFT, OrderStatusEnum.ERROR_PREPARING, OrderStatusEnum.TO_AUTHORIZE, OrderStatusEnum.NOT_AUTHORIZED]
			},
			{
				state: OrderStatusEnum.READY_TO_SEND,
				label: 'theme.order.status.ready_to_send',
				related_list: [OrderStatusEnum.READY_TO_SEND]
			},
			{
				state: OrderStatusEnum.SENDING,
				label: 'theme.order.status.sending',
				related_list: [OrderStatusEnum.SENDING, OrderStatusEnum.ERROR_SENDING, OrderStatusEnum.QUEUE]
			},
			{
				state: OrderStatusEnum.CONSOLIDATED,
				label: 'theme.order.status.consolidated',
				related_list: [OrderStatusEnum.CONSOLIDATED]
			},
			{
				state: OrderStatusEnum.PARTIALLY_FULFILLED,
				label: 'theme.order.status.partially_fulfilled',
				related_list: [OrderStatusEnum.PARTIALLY_FULFILLED]
			},
			{
				state: OrderStatusEnum.FULFILLED,
				label: 'theme.order.status.fulfilled',
				related_list: [OrderStatusEnum.FULFILLED, OrderStatusEnum.DELETED]
			}
		]
	};
	export const b2b: StatusBarConfigNewBis = {
		statusMap: [
			{
				state: OrderStatusEnum.DRAFT,
				label: 'theme.order.status.draft',
				related_list: [OrderStatusEnum.DRAFT, OrderStatusEnum.ERROR_PREPARING, OrderStatusEnum.TO_AUTHORIZE, OrderStatusEnum.NOT_AUTHORIZED]
			},
			{
				state: OrderStatusEnum.PROCESSING,
				label: 'theme.order.status.processing',
				related_list: [
					OrderStatusEnum.READY_TO_SEND,
					OrderStatusEnum.SENDING,					
					OrderStatusEnum.ERROR_SENDING,
					OrderStatusEnum.QUEUE,
					OrderStatusEnum.PROCESSING,
					OrderStatusEnum.CONSOLIDATED
				]
			},
			{
				state: OrderStatusEnum.PARTIALLY_FULFILLED,
				label: 'theme.order.status.partially_fulfilled',
				related_list: [OrderStatusEnum.PARTIALLY_FULFILLED]
			},
			{
				state: OrderStatusEnum.FULFILLED,
				label: 'theme.order.status.order.fulfilled',
				related_list: [OrderStatusEnum.FULFILLED, OrderStatusEnum.DELETED]
			}
		]
	};
	export const backoffice: StatusBarConfigNewBis = {
		statusMap: [
			{
				state: OrderStatusEnum.PROCESSING,
				label: 'theme.order.status.processing',
				related_list: [
					OrderStatusEnum.NOT_AUTHORIZED,
					OrderStatusEnum.SENDING,
					OrderStatusEnum.ERROR_PREPARING,
					OrderStatusEnum.ERROR_SENDING,
					OrderStatusEnum.QUEUE,
					OrderStatusEnum.PROCESSING					
				]
			},
			{
				state: OrderStatusEnum.CONSOLIDATED,
				label: 'theme.order.status.consolidated',
				related_list: [
					OrderStatusEnum.TO_AUTHORIZE, 
					OrderStatusEnum.PARTIALLY_FULFILLED,
					OrderStatusEnum.CONSOLIDATED
				]
			},
			{
				state: OrderStatusEnum.FULFILLED,
				label: 'theme.order.status.fulfilled',
				related_list: [OrderStatusEnum.FULFILLED, OrderStatusEnum.DELETED, OrderStatusEnum.READY_TO_SEND,]
			}
		]
	};
	export const portal: StatusBarConfigNewBis = {
		statusMap: [
			{
				state: OrderStatusEnum.DRAFT,
				label: 'theme.order.status.draft',
				related_list: [OrderStatusEnum.DRAFT, OrderStatusEnum.ERROR_PREPARING, OrderStatusEnum.TO_AUTHORIZE, OrderStatusEnum.NOT_AUTHORIZED]
			},
			{
				state: OrderStatusEnum.PROCESSING,
				label: 'theme.order.status.processing',
				related_list: [					
					OrderStatusEnum.READY_TO_SEND,
					OrderStatusEnum.SENDING,
					OrderStatusEnum.ERROR_SENDING,
					OrderStatusEnum.QUEUE,
					OrderStatusEnum.PROCESSING,
					OrderStatusEnum.CONSOLIDATED,
					OrderStatusEnum.PARTIALLY_FULFILLED,
					OrderStatusEnum.FULFILLED,
					OrderStatusEnum.DELETED
				]
			}
		]
	};
}
