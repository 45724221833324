<ng-container *ngIf="_localListHandlerCategoryTreeConfiguration">
	<ng-container *ngFor="let branch of _localListHandlerCategoryTreeConfiguration.data">
		<div class="mat-h{{_localListHandlerCategoryTreeConfiguration.level}}">
			<span *ngIf="branch.level !== 'section'; else sectionDescription">
				<!-- pipe `translate` necessaria per gestione label multingua nei soli articoli senza categoria -->
				{{
					utilTranslateService.getTranslationFromLanguage(branch.language_list).description |
					translate |
					lowercase |
					sentencecase
				}}
			</span>
			<ng-template #sectionDescription>
				<span *ngIf="branch.article_list && branch.article_list.length > 0">
					{{ branch.description | translate | sentencecase }}
				</span>
			</ng-template>
			<ng-container *ngIf="branch.metaInformation && branch.code_item">
				<span class="flex-span"></span>
				<span>
					{{ 'category.metaInformation.ordered_quantity' | translate }}
					<strong>
						{{ branch['metaInformation'].ordered_quantity | utilPriceReturnItemValueFormattedPipe: 0 }}
					</strong>
				</span>
				<span>
					{{ 'theme.category.metaInformation.qty_free' | translate }}
					<strong>
						{{ branch['metaInformation'].qty_free | utilPriceReturnItemValueFormattedPipe: 0 }}
					</strong>
				</span>
				<span>
					{{ 'category.metaInformation.free_sample' | translate }}
					<strong>
						{{ branch['metaInformation'].free_sample | utilPriceReturnItemValueFormattedPipe: 0 }}
					</strong>
				</span>
				<span>
					{{ 'category.metaInformation.total' | translate }}
					<strong>
						{{ branch['metaInformation'].total | utilPriceReturnItemValueFormattedPipe }}
						{{ order.header.currency.description_short }}
					</strong>
				</span>
			</ng-container>
		</div>
		<new-article-table-wrapper
			*ngIf="branch.article_list && branch.article_list.length > 0 && order"
			[order]="order"
			[localListHandlerData]="{
				data: [],
				dataSubset: branch.article_list,
				filters: _localListHandlerCategoryTreeConfiguration.filters,
				languageKey: _localListHandlerCategoryTreeConfiguration.languageKey,
				pageName: _localListHandlerCategoryTreeConfiguration.pageName,
				pagination: _localListHandlerCategoryTreeConfiguration.pagination,
				sort: _localListHandlerCategoryTreeConfiguration.sort,
				sortRemapObject: _localListHandlerCategoryTreeConfiguration.sortRemapObject,
				columnList: _localListHandlerWidgetWrapperColumnList
			}"
			[organization]="organization"
			[configurationAction]="configurationAction"
			(onArticleChange)="onArticleChange.emit($event)"
			(onDeleteArticle)="onDeleteArticle.emit($event)"
			[localListHandlerDataGlobalSubset]="localListHandlerDataGlobalSubset"
			[sortable]="false"
		>
		</new-article-table-wrapper>
		<article-list-category-tree-recursion-order
			*ngIf="branch.category_list && branch.category_list.length > 0"
			[localListHandlerCategoryTreeConfiguration]="{
				level: _localListHandlerCategoryTreeConfiguration.level + 1,
				data: branch.category_list,
				filters: _localListHandlerCategoryTreeConfiguration.filters,
				languageKey: _localListHandlerCategoryTreeConfiguration.languageKey,
				pageName: _localListHandlerCategoryTreeConfiguration.pageName,
				pagination: _localListHandlerCategoryTreeConfiguration.pagination,
				sort: _localListHandlerCategoryTreeConfiguration.sort,
				sortRemapObject: _localListHandlerCategoryTreeConfiguration.sortRemapObject
			}"
			[_localListHandlerWidgetWrapperColumnList]="_localListHandlerWidgetWrapperColumnList"
			[localListHandlerDataGlobalSubset]="localListHandlerDataGlobalSubset"
			[configurationAction]="configurationAction"
			(onArticleChange)="onArticleChange.emit($event)"
			(onDeleteArticle)="onDeleteArticle.emit($event)"
		>
		</article-list-category-tree-recursion-order>
	</ng-container>	
</ng-container>