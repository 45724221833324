import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// widget & utility
import { BaseStateModel, LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { AngularSpin8CoreUtilCompanyService, UserDetailModel } from '@saep-ict/angular-spin8-core';
import { AppUtilService } from '../../../service/util/app-util.service';
import { Store } from '@ngrx/store';
import { OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { Observable, take } from 'rxjs';
import { StateFeature } from '../../../state';

@Component({
	selector: 'organization-list-wrapper',
	templateUrl: './organization-list-wrapper.component.html',
	styleUrls: ['./organization-list-wrapper.component.scss']
})
export class OrganizationListWrapperComponent extends LocalListHandlerWidgetWrapper {
	@Output() openOrderNew: EventEmitter<OrganizationPouchModel> = new EventEmitter();

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	constructor(
		public utilService: AppUtilService,
		public translateService: TranslateService,
		public store: Store,
		public utilCompanyService: AngularSpin8CoreUtilCompanyService
	) {
		super(utilService, translateService, store);
		this.user$.pipe(take(1)).subscribe(res => {
			if (res) {
				this.user = res.data;
			}
		});
	}
}
