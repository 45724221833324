import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { UtilOrderService } from '../../../../service/util/util-order.service';
import { ArticlePouchModel, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
	selector: 'b2c-quantity-selector',
	templateUrl: './b2c-quantity-selector.component.html',
	styleUrls: ['./b2c-quantity-selector.component.scss'],
    providers: [SubscribeManagerService]
})
export class B2cQuantitySelector implements OnInit {

    @Input() value = 0;
	@Input() disabled = false;
	@Input() showButton = true;
	@Input() article: ArticlePouchModel;
	@Input() organization: OrganizationPouchModel;
	@Output() changeValue: EventEmitter<number> = new EventEmitter();

	onArticleChangeDebounce = new Subject();

	constructor(
        private subscribeManagerService: SubscribeManagerService,
        public utilOrderService: UtilOrderService
    ) {
        this.subscribeManagerService.populate(
			this.onArticleChangeDebounce.pipe(debounceTime(500)).subscribe((action: any) => {
				let newValue = this.value;
				const step: number = this.utilOrderService.returnInputMultipleOf(
					1,
					this.article,
					this.organization
				);
				switch (action) {
					case 'add':
						newValue = newValue + step;
						break;
					case 'sub':
						if (this.value > 0) {
							newValue = newValue - step;
						}
						break;
					default:
						newValue =
							this.utilOrderService.returnInputMultipleOf(
								+action.target.value,
								this.article,
								this.organization
							);
						break;
				}
				this.value = newValue;
                this.changeValue.emit(newValue);
			}),
			'article-change-debounce'
		);
    }

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

}
