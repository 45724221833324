import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, skipWhile, take } from 'rxjs/operators';
import { BaseState, BaseStateModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { StateFeature } from '../../../../../state';
import { OrderListActionEnum, OrderListStateAction } from '../../../../../state/order-list/order-list.actions';
import { MatSelectChange } from '@angular/material/select';
import { AppUtilService } from '../../../../../service/util/app-util.service';
import { OrganizationStateModel, OrderStateModel, ExtraFieldOrderHeaderPouchModel, UserDetailModel } from '@saep-ict/angular-spin8-core';
import { SideBarPositionValues } from '../../../../../enum/sidebar-position-values.enum';
import { ItemBoxTrendInfoComponentConfig } from '../../../../../widget/box-trend-info/box-trend-info.component';
import { OrderPouchModel } from '@saep-ict/pouch_agent_models';
import { ConfigurationCustomer } from '../../../../../constants/configuration-customer';
import * as DashboardConfiguration from '../../../../../constants/dashboard.constants';
import moment from 'moment';

@Component({
	selector: 'organization-detail-overview',
	templateUrl: './organization-detail-overview.component.html',
	styleUrls: ['./organization-detail-overview.component.scss'],
	providers: [
		SubscribeManagerService
	]
})
export class OrganizationDetailOverviewComponent implements OnInit, OnDestroy {

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	sideBarPositionValues = SideBarPositionValues;

	boxTrendInfoOrder: Array<ItemBoxTrendInfoComponentConfig>;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	orderList$: Observable<BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>[]>> = this.store.select(
		StateFeature.getOrderListState
	);
	orderList: OrderStateModel[];

	configurationCustomer = ConfigurationCustomer;
	dashboardConfiguration = DashboardConfiguration;

	updatedAtGeneral = moment().format("DD/MM/YYYY, h:mm");

	constructor(
		private store: Store<any>,
		public utilService: AppUtilService,
		private subscribeManagerService: SubscribeManagerService,
	) {
		this.loadStaticData();
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	loadStaticData() {
		// Recupero le statistiche
		this.organization$.pipe(
			filter(res => !!(res && res.data)),
			take(1)
		)
		.subscribe(state => {
			this.organization = state.data;
		});
		this.user$.pipe(
			skipWhile(
				(res: BaseState<UserDetailModel>) =>
					!(res && res.data && res.data.current_permission && res.data.current_permission.context_code)
			),
			take(1)
		).subscribe((res: BaseState<UserDetailModel>) => {
			this.user = res.data;
			this.store.dispatch(OrderListStateAction.loadAll());
			this.subscribeManagerService.populate(this.retrieveOrderList().subscribe(), 'order-list');
		});
	}

	retrieveOrderList(): Observable<void> {
		return this.orderList$.pipe(
			filter(
				(orderList: BaseStateModel<OrderStateModel[]>) =>
					orderList &&
					orderList.type !== OrderListActionEnum.LOAD_ALL
			),
			map((orderList: BaseStateModel<OrderStateModel[]>) => {
				if (orderList.type === OrderListActionEnum.ERROR) {
					throw new Error(OrderListActionEnum.ERROR);
				}
				this.orderList = orderList.data;
				this.boxTrendInfoOrder =
					DashboardConfiguration.returnBoxTrendInfoOrderContent(
						ConfigurationCustomer.Order.Dashboard.orderTrendBoxStatusList[this.user.current_permission.context_application],
						this.orderList,
						ConfigurationCustomer.Statistic.timeRangeSelector[0]
					);
			})
		);
	}

	updateBoxTrendInfoOrderContent(e: MatSelectChange) {
		this.boxTrendInfoOrder =
			DashboardConfiguration.returnBoxTrendInfoOrderContent(
				ConfigurationCustomer.Order.Dashboard.orderTrendBoxStatusList[this.user.current_permission.context_application],
				this.orderList,
				e.value
			);
	}
}
