import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialComponentsModule } from './modules/material-components.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TranslateModule } from '@ngx-translate/core';
import { CovalentModule } from './modules/covalent.module';
import { ChartsModule } from 'ng2-charts';
import { AttachmentResolverService, SaepICTAngularCoreModule } from '@saep-ict/angular-core';
import { MediaCenterModule } from '@saep-ict/media-center';
import { TicketCenterModule } from '@saep-ict/ticket-center';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SaepICTAngularSpin8CoreModule } from '@saep-ict/angular-spin8-core';
import { DirectiveModule } from './directives/directive.module';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { AppPipeModule } from './pipe/app-pipe.module';
import { NgScrollbarReachedModule } from 'ngx-scrollbar/reached-event';

@NgModule({
	imports: [CommonModule, MaterialComponentsModule],
	exports: [
		CommonModule,
		RouterModule,
		CovalentModule,
		MaterialComponentsModule,
		NgxGalleryModule,
		NgScrollbarModule,
		NgScrollbarReachedModule,
		ChartsModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		SaepICTAngularSpin8CoreModule,
		MediaCenterModule,
		SaepICTAngularCoreModule,
		TicketCenterModule,
		NgxSliderModule,
		AppPipeModule,
		DirectiveModule
	],
	providers: [AttachmentResolverService]
})
export class SharedModule {}
