import { Injector } from '@angular/core';
import { ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import { ReceiptColumnService } from '../../../service/td-data-table/implementation/receipt-list.service';

const injector = Injector.create({
	providers: [{ provide: ReceiptColumnService, deps: [] }]
});

const receiptColumnService = injector.get(ReceiptColumnService);

export namespace ConfigurationReceiptContextApplicationColumnMap {
	export const base: ITdDataTableColumnCustom[] = receiptColumnService._columns;

	export const excludeForAgent: string[] = [''];
	export const excludeForB2b: string[] = ['organization'];
	export const excludeForCrm: string[] = [''];
}
