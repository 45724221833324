<div
	class="homepage__entry-section"
	[style.backgroundImage]="'url(' + ('theme.entry_section_bg.backgroundImageUrl' | translate) + ')'"
	[style.backgroundPosition]="'left bottom'"
>
	<!-- Jumbotron iniziale -->
	<section class="jumbotron-section">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<app-jumbotron [jumbotronConf]="jumbotronValue"> </app-jumbotron>
				</div>
			</div>
		</div>
	</section>

	<!-- Descrizione -->
	<section class="entry-description">
		<div class="container">
			<div class="row">
				<div class="col-md-10 offset-md-1">
					<div class="txt-center">
						<!-- <h2 class="entry-description__title">
							{{ 'theme.home_sections.entry_description.title' | translate | sentencecase }}
						</h2> -->
						<p class="entry-description__paragraph">
							{{ 'theme.home_sections.entry_description.paragraph' | translate | sentencecase }}
						</p>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>

<!-- Categorie -->
<!-- TODO: ripristinare la lista di categorie (di primo livello?) -->
<section *ngIf="config && categoryHighlightList" class="section-slider highlight-categories">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h3 class="section-slider__title">
					{{ 'saep_ict_angular_spin8_core.general.category.name_plural' | translate | sentencecase }}
				</h3>
				<p class="txt-center">
					{{ 'theme.home_sections.category.paragraph' | translate | sentencecase }}
				</p>
				<div class="decoration-baseline"></div>
			</div>
		</div>
	</div>

	<app-card-slider [config]="config">
		<div *sliderItem="let item; let idx = index" class="card-slider-item">
			<b2c-category-item [category]="item"></b2c-category-item>
		</div>
	</app-card-slider>

	<!-- <ng-container *ngIf="categoryHighlightList">
		<p-carousel [value]="categoryHighlightList" [autoplayInterval]="5000">
			<ng-template let-category pTemplate="item" class="category">
				<b2c-category-item [category]="category" class="category__item"></b2c-category-item>
				<h4 class="category__description">
					{{ utilTranslateService.getTranslationFromLanguage(category.language_list).description }}
				</h4>
			</ng-template>
		</p-carousel>
	</ng-container> -->
</section>

<!-- Prodotti in evidenza -->
<section *ngIf="listPageBaseData && listPageBaseData.data && listPageBaseData.data.length" class="highlight-products">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h3 class="highlight-products__title">
					{{ 'home_sections.highlighted_products' | translate | sentencecase }}
				</h3>
				<div class="decoration-baseline"></div>
			</div>
		</div>
		<b2c-article-list-wrapper
			[localListHandlerWidgetWrapperData]="listPageBaseData"
			[localListHandlerPaginatorShow]="false"
			[viewType]="viewTypeEnum.module"
		></b2c-article-list-wrapper>
	</div>
</section>

<!-- Mappa -->
<section class="map">
	<div class="container">
		<div class="row">
			<div class="col-12 p-0">
				<div class="map__content">
					<div class="map__info">
						<h4 class="map__info__name">
							{{ configurationReferenceCustomer.NAME_LONG | translate | sentencecase }}
						</h4>
						<p class="map__info__address">
							{{
								'home_sections.map.address'
									| translate: { adress: configurationReferenceCustomer.ADDRESS | translate }
									| sentencecase
							}}
						</p>

						<p class="map__info__opening">
							<span>{{ 'theme.home_sections.schedule.closing_days' | translate | sentencecase }}</span>
						</p>
						<div class="social-media-list">
							<ng-container *ngFor="let socialMedia of socialMediaList">
								<p *ngIf="!socialMedia.hidden">
									<a [href]="socialMedia.link" target="_blank">
										<img class="icon" [src]="socialMedia.iconPath" [alt]="socialMedia.label" />
										<span>
											{{ socialMedia.label }}
										</span>
									</a>
								</p>
							</ng-container>
						</div>
						<button mat-flat-button color="accent" class="custom-button" (click)="clickCallToAction()">
							{{ 'theme.contact.contact_us' | translate | sentencecase }}
						</button>
					</div>
				</div>
				<div #mapContainer id="map-container"></div>
			</div>
		</div>
	</div>
</section>
