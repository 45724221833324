import { Injectable } from '@angular/core';
import {
	BaseTableListKeyService
} from '@saep-ict/angular-core';

const DECIMAL_FORMAT: (v: any) => any = (v: number) => v.toFixed(2).replace('.', ',');

@Injectable()
export class ProductKitColumnService extends BaseTableListKeyService {
	constructor() {
		super();
	}

	_columns = [
		{
			name: 'code',
			key: 'code',
			label: 'Ref',
			width: 140
		},
		{
			name: 'description',
			key: 'description',
			label: 'Descrizione'
		},
		{
			name: 'articlePrice.price',
			key: 'price',
			label: 'Prezzo',
			width: { min: 80, max: 120 },
			numeric: true,
			format: DECIMAL_FORMAT
		},
		{
			name: 'productDetail.input_quantity',
			key: 'input_quantity',
			label: 'Quantità',
			width: { min: 80, max: 120 },
			numeric: true
		},
		{
			name: 'productDetail.qty_free',
			key: 'qty_free',
			label: 'Articoli gratuiti',
			width: { min: 80, max: 120 },
			numeric: true
		},
		{
			name: 'calculate_price',
			key: 'calculate_price',
			label: 'Totale',
			width: { min: 80, max: 120 },
			numeric: true,
			format: DECIMAL_FORMAT
		}
	];
}
