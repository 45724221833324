<page-with-sidebar [isRoot]="true" [sideBarPosition]="sideBarPositionValues.BOTTOM">
	<ng-container pageMain>
		<mat-card class="angelo-theme autoheight">
			<mat-toolbar>
				<mat-toolbar-row>
					{{ 'analytics.label' | translate | sentencecase }}
					<span class="flex-span"></span>
					<mat-icon [svgIcon]="'custom-help'">help_outline</mat-icon>
				</mat-toolbar-row>
				<mat-toolbar-row>
					<mat-card-subtitle
						>{{ 'analytics.by' | translate | sentencecase }}
						{{ formFilter.get('unit').value }}</mat-card-subtitle
					>
				</mat-toolbar-row>
			</mat-toolbar>
			<box-trend-info [boxTrendInfoComponentConfig]="trendBoxContent"></box-trend-info>
			<mat-divider class="both-spacing"></mat-divider>
			<statistic-nested-category
				[configuration]="statisticNestedCategoryConfiguration"
			></statistic-nested-category>
		</mat-card>
		<mat-card class="angelo-theme autoheight mt-20 mt-30">
			<mat-toolbar>
				<mat-toolbar-row>
					{{ 'product.brand.label_plural' | translate | sentencecase }}
					<span class="flex-span"></span>
					<mat-icon [svgIcon]="'custom-help'">help_outline</mat-icon>
				</mat-toolbar-row>
				<mat-toolbar-row>
					<mat-card-subtitle>{{ 'product.brand.all_brands' | translate | sentencecase }}</mat-card-subtitle>
				</mat-toolbar-row>
			</mat-toolbar>
			<chart-wrapper [config]="chartCategoryRootBar.chart" class="mt-30 mt-20 mb-20 mb-30"></chart-wrapper>
		</mat-card>
		<mat-card class="angelo-theme autoheight mt-20 mt-30">
			<mat-toolbar>
				<mat-toolbar-row>
					{{ 'analytics.progressive' | translate | sentencecase }}
					<span class="flex-span"></span>
					<mat-icon [svgIcon]="'custom-help'">help_outline</mat-icon>
				</mat-toolbar-row>
				<mat-toolbar-row>
					<mat-card-subtitle>{{ 'product.brand.by_brands' | translate | sentencecase }}</mat-card-subtitle>
				</mat-toolbar-row>
			</mat-toolbar>
			<div *ngIf="chartStatistcSummaryListDoughnut" class="charts-group mb-30 mb-20 mt-40 mt-30">
				<chart-wrapper
					*ngFor="let chart of chartStatistcSummaryListDoughnut"
					[config]="chart.chart"
				></chart-wrapper>
			</div>
		</mat-card>
	</ng-container>
	<ng-container pageSide>
		<sidebar-content-wrapper [title]="'general.filters'">
			<ng-container main-content>
				<form class="angelo-theme" *ngIf="formFilter" [formGroup]="formFilter">
					<h3>{{ 'general.unit.label' | translate | sentencecase }}</h3>
					<mat-radio-group formControlName="unit">
						<mat-radio-button
							*ngFor="let item of object.keys(statisticEnum.Unit)"
							[value]="statisticEnum.Unit[item]"
						>
							{{ statisticEnum.Unit[item] | sentencecase }}
						</mat-radio-button>
					</mat-radio-group>
				</form>
			</ng-container>
		</sidebar-content-wrapper>
	</ng-container>
</page-with-sidebar>
