import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SubscribeManagerService, BaseStateModel } from '@saep-ict/angular-core';
import { filter, map, mergeMap } from 'rxjs/operators';
import { OpportunityStateAction, OpportunityActionEnum } from '../../../../state/opportunity/opportunity.actions';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../../state';
import { OrganizationListStateAction } from '../../../../state/common/organization-list/organization-list.actions';
import {
	OpportunityEnum,
	ROUTE_URL,
	OrganizationStateModel,
	OpportunityPouchModel
} from '@saep-ict/angular-spin8-core';

import { UtilBreadcrumbService } from '../../../../service/util/util-breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { TopBarComponent } from '../../../../widget/top-bar/top-bar.component';

@Component({
	selector: 'opportunity-detail',
	templateUrl: './opportunity-detail.component.html',
	styleUrls: ['./opportunity-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class OpportunityDetailComponent implements OnInit, OnDestroy {
	public ROUTE_URL = ROUTE_URL;
	public backButtonRoute;

	organizationList$: Observable<BaseStateModel<OrganizationStateModel[]>> = this.store.select(
		StateFeature.getOrganizationListState
	);
	organizationList: OrganizationStateModel[];

	opportunity: OpportunityPouchModel;
	opportunity$: Observable<BaseStateModel<OpportunityPouchModel[]>> = this.store.select(
		StateFeature.getOpportunityState
	);

	tabsToExclude: string[] = [ROUTE_URL.opportunityLog, ROUTE_URL.opportunityNote, ROUTE_URL.opportunityOffer];
	OpportunityEnum = OpportunityEnum;
	backPath: string;

	constructor(
		private store: Store<any>,
		public route: ActivatedRoute,
		private subscribeManagerService: SubscribeManagerService,
		private translateService: TranslateService,
		private utilBreadcrumbService: UtilBreadcrumbService,
		private router: Router,
	) {
		this.store.dispatch(OrganizationListStateAction.loadAll());
		this.subscribeManagerService.populate(this.subscribeOpportunity().subscribe(), 'subscribeOpportunity');
		this.backPath =  this.router.createUrlTree(['../'], { relativeTo: this.route }).toString();
	}

	ngOnInit() {
		this.getBackButtonRoute();
	}

	getBackButtonRoute() {
		this.backButtonRoute = true;
		// if (this.route.snapshot.paramMap.get('opportunityLevel') === OpportunityEnum.Level.LEAD) {
		// 	this.backButtonRoute =
		// 		ROUTE_URL.private +
		// 		'/' +
		// 		ROUTE_URL.opportunity +
		// 		'/' +
		// 		this.route.snapshot.paramMap.get('opportunityLevel');
		// } else {
		// 	this.backButtonRoute = ROUTE_URL.private + '/' + ROUTE_URL.opportunityKanban;
		// }
	}

	/**
	 * Subscription
	 */

	subscribeOpportunity() {
		return this.organizationList$.pipe(
			filter(res => !!(res && res.data)),
			mergeMap(res => {
				this.organizationList = res.data;
				return this.opportunity$;
			}),
			filter(
				(opportunityState: BaseStateModel<OpportunityPouchModel[]>) =>
					!!(opportunityState && opportunityState.data)
			),
			map(opportunityState => {
				switch (opportunityState.type) {
					case OpportunityActionEnum.UPDATE:
						this.opportunity = opportunityState.data[0];
						this.opportunity = this.getOrganization(this.opportunity);
						this.setRouteMetaInformation();
						// console.log('this.opportunity', this.opportunity);
						const isLead = this.opportunity.level === OpportunityEnum.Level.LEAD;
						if (isLead) {
							this.tabsToExclude = [ROUTE_URL.opportunityOffer];
						} else {
							this.tabsToExclude = [];
						}
						break;

					default:
						break;
				}
			})
		);
	}

	getOrganization(opportunity: OpportunityPouchModel) {
		const founded = this.organizationList.find(org => org.code_item == opportunity.organization.code_item);
		opportunity.organization.code_item = founded?.code_item;
		opportunity.organization.business_name = founded?.business_name;
		return opportunity;
	}

	ngOnDestroy() {
		this.store.dispatch(OpportunityStateAction.reset());
		this.subscribeManagerService.destroy();
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	setRouteMetaInformation() {
		this.utilBreadcrumbService.title.value = this.opportunity
			? this.opportunity.title || OpportunityEnum.Type[this.opportunity.opportunity_type]
			: this.route.parent.snapshot.paramMap.get('opportunityLevel') === OpportunityEnum.Level.LEAD
			? 'request.new'
			: 'opportunity.new';

		this.utilBreadcrumbService.subtitle.value = this.opportunity
			? (this.opportunity.organization?.business_name || '') + ' - ' + (this.opportunity.contact_name || '')
			: '';
	}
}
