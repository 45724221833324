<page-with-sidebar [sideBarPosition]="sideBarPositionValues.NONE">
	<ng-container pageMain>
		<mat-card class="angelo-theme user-detail">
			<top-bar
				[goBackButton]="false"
				class="mb-20"
			>
				<ng-container left-side-content>
					<button
						*ngIf="formEditEnabled"
						mat-icon-button
						(click)="exitEditMode()"
						class="back"
					>
						<mat-icon class="bordered">arrow_back</mat-icon>
					</button>
					<button
						*ngIf="!formEditEnabled"
						mat-icon-button
						[routerLink]="backPath"
						class="back"
					>
						<mat-icon class="bordered">arrow_back</mat-icon>
					</button>
				</ng-container>
			</top-bar>


			<div class="section" *ngIf="!formEditEnabled">
				<!-- User info -->
				<p class="section__title">{{ 'user.profile.information' | translate | sentencecase }}</p>
				<div class="info">
					<div class="info__profile-image">
						<!-- <div class="placeholder-box"></div> -->
						<div class="person-placeholder-box">
							<mat-icon [svgIcon]="'custom-person'" class="person"></mat-icon>
						</div>
					</div>
					<div class="info__content">
						<div class="info__row">
							<p class="info__row__header">{{ 'general.status.name' | translate | sentencecase }}</p>
							<p class="info__row__content">
								{{
									'general.status.' + (userToManage.is_active ? 'active' : 'inactive')
										| translate
										| sentencecase
								}}
							</p>
						</div>
						<div class="info__row">
							<p class="info__row__header">
								{{ 'theme.contact.field.email_label' | translate | sentencecase }}
							</p>
							<p class="info__row__content">{{ userToManage.username }}</p>
						</div>
						<div class="info__row">
							<p class="info__row__header">{{ 'user.field.first_name' | translate | sentencecase }}</p>
							<p class="info__row__content">{{ userToManage.first_name }}</p>
						</div>
						<div class="info__row">
							<p class="info__row__header">{{ 'user.field.last_name' | translate | sentencecase }}</p>
							<p class="info__row__content">{{ userToManage.last_name }}</p>
						</div>
						<div class="info__row">
							<p class="info__row__header">{{ 'language.name' | translate | sentencecase }}</p>
							<p class="info__row__content">
								{{ 'language.' + userToManage.language | translate | sentencecase }}
							</p>
						</div>
					</div>
				</div>

				<!-- Buttons (user info) -->
				<ng-container actions>
					<form-button-bar
						class="stroked-buttons"
						*ngIf="userToManage.id"
						[align]="utilService.isMobile() ? 'vertical' : 'right'"
						[primaryButtonLabel]="'user.management.edit' | translate | sentencecase"
						(primaryButtonClickEmitter)="enableFormEdit()"
						[secondaryButtonLabel]="'user.resend_email_confirmation' | translate | sentencecase"
						(secondaryButtonClickEmitter)="dialogConfirmResendEmailConfirmationMessage()"
					></form-button-bar>
				</ng-container>
			</div>

			<mat-divider *ngIf="!formEditEnabled" class="both-spacing show-on-angelo-theme"></mat-divider>

			<form [formGroup]="form" class="user-detail-context-code angelo-theme">
				<!-- User info -->
				<div *ngIf="formEditEnabled" class="section__user-info">
					<p class="section__title">{{ 'user.profile.information' | translate | sentencecase }}</p>
					<mat-form-field>
						<input
							disabled
							matInput
							[value]="(userToManage.is_active ? 'active' : 'inactive') | translate | sentencecase"
							[placeholder]="'general.status.name' | translate | sentencecase"
						/>
					</mat-form-field>
					<mat-form-field>
						<input
							matInput
							formControlName="username"
							[placeholder]="'theme.contact.field.email_label' | translate | sentencecase"
						/>
					</mat-form-field>
					<mat-form-field>
						<input
							matInput
							formControlName="first_name"
							placeholder="{{ 'user.field.first_name' | translate | sentencecase }}"
						/>
					</mat-form-field>
					<mat-form-field>
						<input
							matInput
							formControlName="last_name"
							placeholder="{{ 'user.field.last_name' | translate | sentencecase }}"
						/>
					</mat-form-field>
					<mat-form-field>
						<mat-select
							formControlName="language"
							placeholder="{{ 'language.name' | translate | sentencecase }}"
						>
							<mat-option *ngFor="let language of languageList" [value]="language.description_short">
								{{ 'language.' + language.description_short | translate | sentencecase }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<mat-divider *ngIf="formEditEnabled" class="both-spacing show-on-angelo-theme"></mat-divider>
				<!-- <p class="section__title">{{ 'context_code.linked' | translate | sentencecase }}</p> -->
				<mat-accordion>
					<!-- Context application -->
					<div *ngFor="let context of contextTypeAndApplicationLink; let last = last">
						<mat-expansion-panel class="user-detail">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<strong>{{
										utilService.formatPlaceholder(context.application_name) | sentencecase
									}}</strong>
									<span class="flex-span"></span>
								</mat-panel-title>
							</mat-expansion-panel-header>

							<!-- Permissions -->
							<p>
								{{
									'context_code.inherited_by'
										| translate
											: {
													context_code_description:
														context.context_code_item.description | translate
											  }
										| sentencecase
								}}
							</p>
							<form-permission-group-select
								[extraFormValue]="getFormFieldValue('permission')"
								[groupList]="
									returnContextApplicationPermissionGroup(
										contextApplicationItemCodeEnum[context.context_application_item_code]
									).context_permission_tree
								"
								[disabled]="!formEditEnabled"
								(extraFormValueEmit)="
									updateFormPermissionFromFormPermissionGroupSelect(
										$event,
										context.context_application_item_code
									)
								"
							>
							</form-permission-group-select>

							<!-- Connected codes -->
							<div class="section__connected-codes">
								<p class="title">
									{{
										'context_code.x_associated'
											| translate
												: { context_code: context.context_code_item.description | translate }
											| sentencecase
									}}
								</p>

								<td-data-table
									#dataTable
									*ngIf="
										permissionUtilService.getContextItem(
											getFormFieldValue('context_application_list'),
											context.context_application_item_code
										) &&
											permissionUtilService.getContextItem(
												getFormFieldValue('context_application_list'),
												context.context_application_item_code
											).context_code_list &&
											permissionUtilService.getContextItem(
												getFormFieldValue('context_application_list'),
												context.context_application_item_code
											).context_code_list.length > 0;
										else noData
									"
									[data]="
										permissionUtilService.getContextItem(
											getFormFieldValue('context_application_list'),
											context.context_application_item_code
										).context_code_list
									"
									[columns]="
										configurationCustomer.ContextCode.columnList(formEditEnabled)[
											context.context_application_item_code
										]
									"
									[clickable]="formEditEnabled"
									(rowClick)="dialogChangePermissionContextCodeItem(context, $event.row)"
								>
									<ng-template tdDataTableTemplate="code" let-value="value" let-row="row">
										<p>
											{{
												row.code_erp ?
												row.code_erp :
												(
													(
														row.code ||
														row.code_item
													)
													| guidFormatter
												)
											}}
											<ng-container *ngIf="row.code_erp">
												<br />
												<small class="cell-subtitle">
													{{ row.code | guidFormatter }}
												</small>
											</ng-container>
										</p>
									</ng-template>
									<ng-template tdDataTableTemplate="permission" let-value="value">
										<ng-container *ngFor="let item of value; let last = last">
											{{
												permissionUtilService.returnPermissionFullObject(
													permissionAuxiliaryTableState.permission_list,
													item
												).key
											}}
											{{ !last ? ', ' : '' }}
										</ng-container>
									</ng-template>
									<ng-template tdDataTableTemplate="action" let-row="row">
										<button
											mat-icon-button
											stopPropagation
											(click)="
												dialogConfirmDeleteContextCodeItem(
													context.context_application_item_code,
													row.code
												)
											"
											type="button"
											color="warn"
										>
											<mat-icon>delete</mat-icon>
										</button>
									</ng-template>
								</td-data-table>
								<ng-template #noData>
									<p class="placeholder">{{ 'context_code.no_items' | translate | sentencecase }}</p>
								</ng-template>

								<!-- Buttons (connected codes) -->
								<ng-container actions *ngIf="formEditEnabled">
									<form-button-bar
										[align]="mediaReplayService.deviceW >= 768 ? 'right' : 'vertical'"
										[primaryButtonLabel]="'context_code.create_x' | translate | sentencecase"
										[primaryButtonIsDisabled]="
											!configurationCustomer.UserManagement.canEditContextCode[
												contextApplicationItemCodeEnum[context.context_application_item_code]
											]
										"
										(primaryButtonClickEmitter)="dialogCreateContextCodeItem(context)"
										[secondaryButtonLabel]="
											'context_code.associate_x_existent' | translate | sentencecase
										"
										(secondaryButtonClickEmitter)="dialogAssociateContextCodeItem(context)"
									></form-button-bar>
								</ng-container>
							</div>
						</mat-expansion-panel>
						<mat-divider *ngIf="!last" class="show-on-angelo-theme"></mat-divider>
					</div>
				</mat-accordion>
			</form>

			<!-- Buttons (save user) -->
			<ng-container actions *ngIf="formEditEnabled">
				<form-button-bar
					class="actions-buttons"
					[align]="'space-between'"
					[primaryButtonLabel]="'user.management.save' | translate | sentencecase"
					[primaryButtonIsDisabled]="!form.valid"
					(primaryButtonClickEmitter)="onFormSubmit()"
					[secondaryButtonLabel]="'general.cancel_changes' | translate | sentencecase"
					(secondaryButtonClickEmitter)="exitEditMode()"
					[otherButtonLabel]="'user.deactivate' | translate | sentencecase"
					(otherButtonClickEmitter)="dialogConfirmDeactivateUser()"
					(otherButtonIsDisabled)="(!userToManage.is_active)"
				></form-button-bar>
			</ng-container>
		</mat-card>
	</ng-container>
</page-with-sidebar>
