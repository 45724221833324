import { ITdDataTableColumn } from "@covalent/core/data-table";
import _ from "lodash";
import { ConfigurationContactContextApplicationColumnMap } from "../../configuration-customer/contact/contact-column-map.constant";
import { Injector } from "@angular/core";
import { AngularCoreUtilService } from "@saep-ict/angular-core";

const injector = Injector.create ({
    providers: [{ provide: AngularCoreUtilService, deps: [] }]
});

// Eliminare: order_confirm_email
const utilService = injector.get(AngularCoreUtilService);
let newColumnList = [...ConfigurationContactContextApplicationColumnMap.baseColumns]
const orderDateCreationIndex = utilService.getElementIndex(newColumnList, 'name', 'date_creation')
newColumnList.splice(orderDateCreationIndex, 6);

export const projectOverride: ITdDataTableColumn[] = 
    _.cloneDeep(newColumnList);
