<td-data-table
	*ngIf="_localListHandlerData"
	#dataTable
	[data]="_localListHandlerData.dataSubset"
	[columns]="_localListHandlerData.columnList"
	[sortable]="sortable"
	(sortChange)="sortChange.emit($event)"
	[sortBy]="_localListHandlerData.sort && _localListHandlerData.sort.name ? _localListHandlerData.sort.name : null"
	[sortOrder]="
		_localListHandlerData.sort && _localListHandlerData.sort.order ? _localListHandlerData.sort.order : null
	"
	[ngClass]="columnWidthConfigurationClass"
>
	<!-- Availability -->
	<ng-template tdDataTableTemplate="cf_available" let-row="row">
		<div class="chips-mini">
			<span
				[ngClass]="articleEnum.Status.Stock[row.stock?.code_segnalation]"
				[matTooltip]="row.stock?.description_segnalation"
			></span>
		</div>
	</ng-template>
	<!-- code_* -->
	<ng-template
		*ngIf="isTablet || onItemSelectShow"
		tdDataTableTemplate="{{ configurationCustomer.AppStructure.Erp.has_erp ? 'code_erp' : 'code_item' }}"
		let-row="row"
		let-value="value"
	>
		<p>
			<a href="javascript:void(0)" (click)="onItemSelect.emit(row)">
				{{ value }}
			</a>
			<ng-container *ngIf="isTablet">
				<br />
				<small class="cell-subtitle d-block" [matTooltip]="row.description">
					{{ row.description }}
				</small>
			</ng-container>
		</p>
	</ng-template>
	<!-- Description -->
	<ng-template tdDataTableTemplate="description" let-row="row">
		<span
			[matTooltip]="
				utilTranslateService.getTranslationFromLanguage(row.articleDescription.language_list).description
			"
		>
			{{ utilTranslateService.getTranslationFromLanguage(row.articleDescription.language_list).description }}
		</span>
		<!-- TODO: ripristinare, calato sui nuovi meccanismi di aggregazione article -->
		<!-- <span class="product-kit" *ngIf="row.art_kit === 'S' && row.articleKitList && row.articleKitList.length">
			<button
				mat-icon-button
				aria-label="Icon to open dialog with kit products"
				(click)="utilArticleKitService.openDialogKit(row)"
				[matTooltip]="'article.kit.show' | translate | sentencecase"
			>
				<mat-icon>shopping_basket</mat-icon>
			</button>
		</span> -->
	</ng-template>
	<!-- Price Article -->
	<ng-template tdDataTableTemplate="articlePrice.price" let-row="row">
		<p>
			{{ row.articlePrice.price }}<br />
			<small *ngIf="row.tableMetaInformation?.priceWithDiscountDescription" class="cell-subtitle d-block">
				{{ 'general.discounted' | translate }}: {{ row.tableMetaInformation.priceWithDiscountDescription }}
			</small>
		</p>
	</ng-template>
	<!-- Discount Agent -->
	<ng-template tdDataTableTemplate="discount_agent" let-row="row">
		<p>
			<article-change-input
				[configuration]="{
					visible: true,
					value: row.discount_agent ? row.discount_agent.value : null,
					disabled:
						row.articleDescription.free_sample === 'S' ||
						!row.input_quantity ||
						!(order && order.header.status === orderStatusEnum.DRAFT),
					maxValue: 100,
					key: 'discount_agent',
					row: row,
					dataSubset: _localListHandlerData.dataSubset,
					dataTable: _dataTable,
					organization: organization,
					onArticleChange: onArticleChange,
					event: null,
					pageSize: localListHandlerDataGlobalSubset,
					visibleInputInRow:
						configurationCustomer.Order.commonCatalogVisibleInput[
							user.current_permission.context_application
						]
				}"
			></article-change-input>
		</p>
	</ng-template>
	<!-- Discount Article Free -->
	<ng-template tdDataTableTemplate="qty_free" let-row="row">
		<p class="input-cell">
			<article-change-input
				[configuration]="{
					visible: !(row.articleDescription.free_sample === 'S' || !row.input_quantity),
					value: row.qty_free ? row.qty_free : null,
					disabled: !(order && order.header.status === orderStatusEnum.DRAFT),
					maxValue: row.input_quantity,
					key: 'qty_free',
					row: row,
					dataSubset: _localListHandlerData.dataSubset,
					dataTable: _dataTable,
					organization: organization,
					onArticleChange: onArticleChange,
					event: null,
					pageSize: localListHandlerDataGlobalSubset,
					visibleInputInRow:
						configurationCustomer.Order.commonCatalogVisibleInput[
							user.current_permission.context_application
						]
				}"
			></article-change-input>
		</p>
	</ng-template>
	<!-- Quantity Input -->
	<ng-template tdDataTableTemplate="input_quantity" let-row="row">
		<p
			class="input-cell"
			[ngClass]="{
				'input-highlight': row.articleDescription.free_sample === 'S' && !row.articleDescription.is_tester
			}"
		>
			<article-change-input
				*ngIf="row.tableMetaInformation"
				[configuration]="{
					visible: true,
					value: row.input_quantity ? row.input_quantity : null,
					disabled: !row.tableMetaInformation.inputQuantityDisable,
					key: 'input_quantity',
					row: row,
					dataSubset: _localListHandlerData.dataSubset,
					dataTable: _dataTable,
					organization: organization,
					onArticleChange: onArticleChange,
					event: null,
					pageSize: localListHandlerDataGlobalSubset,
					visibleInputInRow:
						configurationCustomer.Order.commonCatalogVisibleInput[
							user.current_permission.context_application
						]
				}"
			></article-change-input>
		</p>
	</ng-template>
	<!-- Related Tester Quantity Input -->
	<ng-template tdDataTableTemplate="articleDescription.relatedArticleTester.input_quantity" let-row="row">
		<p class="input-cell">
			<article-change-input
				[configuration]="{
					visible: row.articleDescription.relatedArticleTester,
					value:
						row.articleDescription.relatedArticleTester &&
						row.articleDescription.relatedArticleTester.input_quantity
							? row.articleDescription.relatedArticleTester.input_quantity
							: null,
					disabled: false,
					key: 'input_quantity_related_tester',
					row: row,
					dataSubset: _localListHandlerData.dataSubset,
					dataTable: _dataTable,
					organization: organization,
					onArticleChange: onArticleChange,
					event: null,
					pageSize: localListHandlerDataGlobalSubset,
					visibleInputInRow:
						configurationCustomer.Order.commonCatalogVisibleInput[
							user.current_permission.context_application
						]
				}"
			></article-change-input>
			<small *ngIf="row.articleDescription.relatedArticleTester" class="cell-subtitle d-block">
				{{ 'article.field.sample_code' | translate | sentencecase }}:
				{{
					configurationCustomer.AppStructure.Erp.has_erp
						? row.articleDescription.relatedArticleTester.code_erp
						: row.articleDescription.relatedArticleTester.code_item
				}}
			</small>
		</p>
	</ng-template>
	<!-- Actions -->
	<ng-template tdDataTableTemplate="actions" let-row="row">
		<ng-container *ngIf="configurationAction">
			<div class="actions-wrapper">
				<article-input-multiple-validation
					*ngIf="
						configurationAction.validationMultiple && order && order.header.status === orderStatusEnum.DRAFT
					"
					[article]="row"
					[organization]="organization"
				>
				</article-input-multiple-validation>
				<button
					mat-icon-button
					*ngIf="configurationAction.note && order && order.header.status === orderStatusEnum.DRAFT"
					(click)="articleNoteChange($event, row)"
					[matTooltip]="(row.note_order ? 'comment.read' : 'comment.add') | translate | sentencecase"
				>
					<!-- TODO:
					- modifica icona
					- opacità dinamica in base a presenza/assenza nota -->
					<mat-icon [ngClass]="{ comment: row.note_order, add_comment: !row.note_order }">{{
						row.note_order ? 'comment' : 'add_comment'
					}}</mat-icon>
				</button>
				<button
					*ngIf="
						configurationAction.delete &&
						((order && order.header.status === orderStatusEnum.DRAFT) || !order)
					"
					mat-icon-button
					[matTooltip]="'general.delete' | translate | sentencecase"
					(click)="onDeleteArticle.emit(row)"
				>
					<mat-icon class="delete">delete</mat-icon>
				</button>
				<button
					mat-icon-button
					*ngIf="
						configurationAction.ticket &&
						order.header.status !== orderStatusEnum.DRAFT &&
						user.current_permission.context_application !== contextApplicationItemCodeEnum.BACKOFFICE
					"
					(click)="
						ticketCenterMainService.dialogCreateNew({
							body: {
								custom_field: {
									request_type: ticketCenterBodyCustomField.RequestTypeEnum.NOT_CONFORMITY_REPORT,
									organization_code_item: order.header.organization.code_item,
									organization_referent: user.first_name + ' ' + user.last_name,
									organization_referent_contact_address: user.username,
									order_code_item: order._id,
									article_code_item: configurationCustomer.AppStructure.Erp.has_erp
										? row.code_erp
										: row.code_item
								},
								reference: null,
								subject: null,
								message: null,
								attachment_list: null
							},
							id: null,
							request: null,
							status: null,
							thread: null
						})
					"
				>
					<mat-icon class="support_agent">support_agent</mat-icon>
				</button>
				<button
					*ngIf="row.tableMetaInformation.hasGalleryImage && configurationAction.gallery"
					mat-icon-button
					(click)="dialogGallery(row)"
				>
					<mat-icon class="image">image</mat-icon>
				</button>
				<mat-icon
					class="public"
					*ngIf="configurationAction.origin && row.articleDescription.origin"
					[matTooltip]="utilService.formatPlaceholder(row.articleDescription.origin) | titlecase"
				>
					public
				</mat-icon>
			</div>
		</ng-container>
	</ng-template>
</td-data-table>
