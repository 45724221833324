import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxGalleryAnimation, NgxGalleryImage, NgxGalleryImageSize, NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { AttachedFile, LanguageItem } from '@saep-ict/pouch_agent_models';
import { AngularSpin8CoreUtilTranslateService } from '@saep-ict/angular-spin8-core';
import _ from 'lodash';
import { Language } from '../../../enum/language.enum';

export interface DialogImageGalleryData {
	language_list: LanguageItem[]
}

@Component({
	selector: 'dialog-image-gallery',
	templateUrl: './dialog-image-gallery.component.html',
	styleUrls: ['./dialog-image-gallery.component.scss']
})
export class DialogImageGalleryComponent implements OnInit {
	languageInUse = Language;
	galleryHeight: number = 400;
	galleryOptions: NgxGalleryOptions[] = [
		{
			imageAnimation: NgxGalleryAnimation.Slide,
			preview: false,
			//imagePercent: 100,
			imageSize: NgxGalleryImageSize.Contain,
			thumbnails: false,
			arrowPrevIcon: 'arrowPrevIcon',
			arrowNextIcon: 'arrowNextIcon',
            height: this.galleryHeight + 'px'
		}
	];
	galleryImages: NgxGalleryImage[] = [];
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: DialogImageGalleryData,
		private utilTranslateService: AngularSpin8CoreUtilTranslateService
	) {
		this.galleryInit();
	}
	ngOnInit() {}

	galleryInit() {
		const languageList: string[] = this.returnLanguageList();
		for (const l of languageList) {
			let languageItem: LanguageItem = this.data.language_list.find(i => i.language === l);
			if (!languageItem) {
				languageItem = this.data.language_list[0];
			}
			if (languageItem && languageItem.image_list && languageItem.image_list.length > 0) {
				this.setNgxGalleryImageList(languageItem.image_list);
				break;
			}
		}
	}

	/**
	 * Popola l'oggetto `galleryImages` con `imageList` fornito, mettendo al primo index eventuale immagine
	 * avente `is_main_of_list: true`
	 * @param imageList
	 */
	setNgxGalleryImageList(imageList: AttachedFile[]) {
		imageList = _.cloneDeep(imageList);
		imageList.sort((a, b) => a.is_main_of_list ? -1 : b.is_main_of_list ? 1 : 0);
		for (const image of imageList) {
			this.galleryImages.push({
				medium: image.bucket_link,
				description: image.alt
			});
		}
	}

	/**
	 * Restituisce un array di stringhe rapresentanti tutte le lingue in uso sul progetto ponendo per prime
	 * 1 lingua selezionata, 2 DEFAULT, 3 FALLBACK, ... tutte le altre gestite
	 * @returns
	 */
	returnLanguageList(): string[] {
		const languageListProcess: string[] = [
			this.utilTranslateService.translate.currentLang,
			this.languageInUse.DEFAULT,
			this.languageInUse.FALLBACK,
			...this.utilTranslateService.languages
		];
		const languageListReturn: string[] = [];
		for (const l of languageListProcess) {
			if (!languageListReturn.includes(l)) {
				languageListReturn.push(l);
			}
		}
		return languageListReturn;
	}
}
