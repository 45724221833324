import { ITdDataTableColumn } from "@covalent/core/data-table";
import { DestinationColumnMap } from "@saep-ict/angular-spin8-core";
import _ from "lodash";

export const projectOverride: ITdDataTableColumn[] = 
    _.cloneDeep(DestinationColumnMap.base)
    .concat([
        {
            name: 'address.province.label',
            labelPath: 'theme.destination.province',
            label: null
        },
        {
            name: 'address.country',
            labelPath: 'theme.destination.country',
            label: null
        },
        {
            name: 'division_list.area_commercial',
            labelPath: 'general.area_commercial',
            label: null
        }
    ]);