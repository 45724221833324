<div class="container py-5">
	<ng-container *ngIf="!accountCreated">
		<section class="heading-form">
			<h1>{{ 'general.sign_in' | translate | sentencecase }}</h1>
			<h3>{{ 'general.sign_in_subtitle' | translate | sentencecase }}</h3>
		</section>
		<context-code-edit
			*ngIf="formControlConfigurationEdit"
			[configuration]="formControlConfigurationEdit"
			(onFormValueChange)="contextCodeEditOnChange($event)"
		></context-code-edit>

		<!-- Checkbox list -->
		<div class="checkbox-list" [formGroup]="checkboxForm">
			<mat-checkbox formControlName="privacyPolicy">
				{{ 'login.access_request.privacy_policy.checkbox.start' | translate | sentencecase }}&nbsp;<a
					[routerLink]="['/', ROUTE_URL.privacyPolicy]"
					target="_blank"
					>{{ 'informative_page.type.privacy_policy' | translate }}</a
				>&nbsp;{{ 'login.access_request.privacy_policy.checkbox.end' | translate }}
			</mat-checkbox>
			<mat-checkbox formControlName="termsAndConditions">
				{{ 'login.access_request.terms_and_conditions.checkbox.start' | translate | sentencecase }}&nbsp;<a
					[routerLink]="['/', ROUTE_URL.termsAndConditions]"
					target="_blank"
					>{{ 'informative_page.type.terms_and_conditions' | translate }}</a
				>
			</mat-checkbox>
			<mat-checkbox formControlName="civilCodeArticles">
				{{ 'theme.login.access_request.civil_code_articles.checkbox.text' | translate | sentencecase }}
			</mat-checkbox>
		</div>

		<recaptcha (recaptchaResponseEmitter)="returnRecaptchaResponse($event)"></recaptcha>
		<form-button-bar
			[align]="'left'"
			[primaryButtonLabel]="'general.save' | translate | sentencecase"
			[primaryButtonIsDisabled]="!returnFormIsValid()"
			(primaryButtonClickEmitter)="onFormSubmit()"
			[secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
			(secondaryButtonClickEmitter)="contextCodeEditOnCancel()"
		></form-button-bar>
	</ng-container>
	<!-- Account creato -->
	<ng-container *ngIf="accountCreated">
		<div class="success-message">
			<mat-icon>check_circle</mat-icon>
			<p class="title">
				{{ 'login.general.registration_success' | translate | sentencecase }}
			</p>
			<p class="subtitle">
				{{ 'login.general.registration_check' | translate | sentencecase }}
			</p>
		</div>
	</ng-container>
</div>
