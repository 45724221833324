<page-with-sidebar [isRoot]="true" [sideBarPosition]="sideBarPositionValues.TOP">
	<ng-container pageMain>
		<mat-card class="angelo-theme">
			<article-list-structure-wrapper-plain-list
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="listPageBaseData.columnList"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				[onItemSelectShow]="true"
				(onItemSelect)="goToArticleDetail($event)"
			>
			</article-list-structure-wrapper-plain-list>
		</mat-card>
	</ng-container>
	<ng-container pageSide>
		<sidebar-content-wrapper>
			<ng-container main-content>
				<form class="angelo-theme" [formGroup]="formFilters" (ngSubmit)="onFormFiltersSubmit()">
					<mat-form-field>
						<mat-label>{{ 'article.field.cf_available' | translate | sentencecase }}</mat-label>
						<mat-select formControlName="availability_status">
							<mat-option *ngFor="let item of articleAvailabilityState" [value]="item.key">
								<div class="chips-mini">
									<span [ngClass]="item.value.toUpperCase()"></span>
									<span>{{ this.utilService.formatPlaceholder(item.value.toUpperCase()) }}</span>
								</div>
							</mat-option>
						</mat-select>
					</mat-form-field>

					<form-button-bar
						[align]="'right'"
						[primaryButtonLabel]="'general.apply_filters' | translate | sentencecase"
						(primaryButtonClickEmitter)="onFormFiltersSubmit()"
						[secondaryButtonLabel]="'general.reset' | translate | sentencecase"
						(secondaryButtonClickEmitter)="resetFilters()"
					></form-button-bar>
				</form>
			</ng-container>
		</sidebar-content-wrapper>
	</ng-container>
</page-with-sidebar>
