import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import { AngularSpin8CoreUtilTranslateService } from '@saep-ict/angular-spin8-core';
import _ from 'lodash';
import { debounceTime, Observable, Subject } from 'rxjs';
import { CategoryEnum } from '../../../enum/category.enum';
import { CategoryModel } from '../../../model/category-list.model';
import { combineLatestWith, map, take } from 'rxjs/operators';

@Component({
	selector: 'category-tab-list',
	templateUrl: './category-tab-list.component.html',
	styleUrls: ['./category-tab-list.component.scss'],
	providers: [SubscribeManagerService]
})
export class CategoryTabListComponent implements OnInit {

	@Input('list') set list(e: CategoryModel.Extend.Active[]) {
		if (e) {
			this._list = e;
			this.listChildren = null;
			// notifica del passaggio di dati nell'input
			this.listSource.next(this._list);
		}
	};
	_list: CategoryModel.Extend.Active[];
	listSource = new Subject<CategoryModel.Extend.Active[]>();
	list$: Observable<CategoryModel.Extend.Active[]> = this.listSource.asObservable();

	// TODO: l'oggetto di configurazione nn è obbligatorio ma ha comunque bisogno di un passaggio  che
	// attivi il set. Verificare se possibile rendere l'input opzionale.
	@Input('configuration') set configuration(e: CategoryModel.TabOnTop.Configuration) {
		if (e) {
			this._configuration = _.cloneDeep(e);
			// l'assenza della prop. level identifica l'istanza root del componente, unico a dover trasmettere l'emit
			// attraverso subscribe in debounce
			if (!this._configuration.level) {
				this.subscribeManagerService.populate(
					this.rootEmitHandler.pipe(
						debounceTime(
							this._configuration.debounceTime ? this._configuration.debounceTime : 500
						)
					).subscribe((e: CategoryModel.Extend.Active) =>
						this.categorySelectedChange.emit(e)
					),
					'root-emit'
				);
				this._configuration.level = 1;
			}
			this.styleClassList = this.returnLevelStyleClassList(this._configuration);
			// creazione della configurazione da trasmettere ricorsivamente
			this.listChildrenConfiguration = _.cloneDeep(this._configuration);
			this.listChildrenConfiguration.level += 1;
			// notifica del passaggio di dati nell'input
			this.configurationSource.next(this._configuration);
		}
	};
	_configuration: CategoryModel.TabOnTop.Configuration;
	configurationSource = new Subject<CategoryModel.TabOnTop.Configuration>();
	configuration$: Observable<CategoryModel.TabOnTop.Configuration> = this.configurationSource.asObservable();

	@Output() categorySelectedChange = new EventEmitter<CategoryModel.Extend.Active>();

	rootEmitHandler = new Subject();
	styleClassList: string[];
	listChildren: CategoryModel.Extend.Active[];
	listChildrenConfiguration: CategoryModel.TabOnTop.Configuration;

	constructor(
		private subscribeManagerService: SubscribeManagerService,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService
	) {
		this.subscribeManagerService.populate(
			this.subscribeInput().subscribe({ error: (e) => { console.log('data-input: something went wrong', e); }}),
			'data-input'
		);
	}

	ngOnInit(): void {

	}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
	}

	subscribeInput(): Observable<void> {
		return this.list$.pipe(
			take(1),
			combineLatestWith(this.configuration$),
			map(([list, configuration]) => {
				if (configuration.initialization.active) {
					this._configuration.initialization.active = false;
					this.activeItemHandler();
				}
			})
		);
	}

	/**
	 * Modifica le categorie figlie in visualizzazione in base alla categoria selezionata
	 * @param e
	 */
	clickHandler(e: CategoryModel.Extend.Active) {
		this._list.map(i => {
			i.active = e.code_item === i.code_item ? true : false;
			return i
		});
		this.listChildrenConfiguration.initialization.active = false;
		this.listChildren = _.cloneDeep(e.category_list);
		this.emitHandler(e);
	}

	/**
	 * In base al level effettua l'emit diretto by Output oppre tramite rootEmitHandler.
	 * Questo permette di avere cambiamenti istantanei per la selezione dei livelli annidati ma un debounce
	 * sull'ultimo emit del componente root.
	 * @param e
	 */
	emitHandler(e: CategoryModel.Extend.Active) {
		if (this._configuration.level === 1) {
			this.rootEmitHandler.next(e);
		} else {
			this.categorySelectedChange.emit(e);
		}
	}

	activeItemHandler(){
		const indexLevel: number = this._configuration.level - 1;
		let categoryList: CategoryModel.Extend.Active[];
		if (this._configuration.initialization && this._configuration.initialization.selectLevelBy) {
			if (
				this._configuration.initialization.selectLevelBy.propertyKey &&
				this._configuration.initialization.selectLevelBy.propertyKey.length > 0
			) {
				// TODO: select by code_item
			}
			if (
				this._configuration.initialization.selectLevelBy.index &&
				this._configuration.initialization.selectLevelBy.index.length >0
			) {
				const indexSelectLevelBy = this._configuration.initialization.selectLevelBy.index[indexLevel];
				if (
					(indexSelectLevelBy || indexSelectLevelBy === 0) &&
					this._list[indexSelectLevelBy]
				) {
					this._list[indexSelectLevelBy].active = true;
					if (
						this._list[indexSelectLevelBy].category_list &&
						this._list[indexSelectLevelBy].category_list.length > 0
					) {
						categoryList = this._list[this._configuration.initialization.selectLevelBy.index[indexLevel]].category_list;
					}
				}
			}
		}
		this.listChildren = _.cloneDeep(categoryList);
	}

	/**
	 * Genera la lista di classi utile allo style dell'istanza corrente usando styleType e customClassList trovati in
	 * levelStyleList incrociando level e index dell'array.
	 * Quando il numero di ricorsioni è maggiore del length di levelStyleList, tutti i level scoperti vengono marcati
	 * con lo stile dell'ultimo elemento di levelStyleList.
	 * In assenza di elementi in levelStyleList il nodo verrà gestito come CategoryEnum.TabOnTopStyleType.CHIP
	 *
	 * @param configuration
	 * @returns
	 */
	returnLevelStyleClassList(configuration: CategoryModel.TabOnTop.Configuration): string[] {
		let index: number;
		let styleClassList: string[];
		if (configuration.levelStyleList) {
			if (configuration.levelStyleList[configuration.level - 1]) {
				index = configuration.level - 1;
			} else {
				if (configuration.levelStyleList.length > 0) {
					index = configuration.levelStyleList.length - 1;
				}
			}
		}
		if (index || index === 0) {
			const styleType: CategoryModel.TabOnTop.StyleType = configuration.levelStyleList[index];
			styleClassList = [styleType.styleType];
			if (styleType.customClassList && styleType.customClassList.length > 0) {
				styleClassList =
					styleClassList.concat(
						styleType.customClassList
					);
			}
		} else {
			styleClassList = [CategoryEnum.TabOnTopStyleType.CHIP];
		}
		return styleClassList;
	}

}
