import {
	BaseStateModel,
	BaseTableListService,
	ITdDataTableColumnCustom
} from '@saep-ict/angular-core';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../state';
import { Store } from '@ngrx/store';
import { ColumnListMap } from '../../../constants/column-list-map/column-list-map.constant';
import { take } from 'rxjs/operators';
import { UserDetailModel } from '@saep-ict/angular-spin8-core';

@Injectable()
export class OfferListColumnService extends BaseTableListService {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	constructor(@Inject(TranslateService) public translate: TranslateService, private store: Store) {
		super();
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});
		this.columns = ColumnListMap.ContextApplicationSection[this.user.current_permission.context_application].OFFER;
	}

	_columns: ITdDataTableColumnCustom[];
}

@Injectable()
export class OfferFreelineListColumnService extends BaseTableListService {
	constructor(@Inject(TranslateService) public translate: TranslateService) {
		super();
	}

	_columns: ITdDataTableColumnCustom[] = [
		{
			name: 'description',
			labelPath: 'offer.line.field.description',
			label: null
		},
		{
			name: 'price',
			labelPath: 'offer.line.field.price',
			label: null,
			numeric: true
		},
		{
			name: 'discount_agent.value',
			labelPath: 'offer.line.field.discount',
			label: null,
			numeric: true,
			format: (v: number) => v + '%'
		},
		{
			name: 'price_total',
			labelPath: 'offer.line.field.price_total',
			label: null,
			numeric: true
		},
		{
			name: 'actions',
			labelPath: '',
			label: null,
			numeric: true
		}
	];
}
