import { ArticlePouchModel } from "@saep-ict/pouch_agent_models";
import { CategoryModel } from "../../../model/category-list.model";

export const orderCategorySectionList: CategoryModel.SectionItem[] = [
	{
		title: 'theme.article.field.products',
		articleListFilter: (articleList: ArticlePouchModel[]): ArticlePouchModel[] =>
			articleList.filter(i => i.articleDescription.free_sample !== 'S')
	},
	{
		title: 'theme.order.category_section_list_description.posm',
		articleListFilter: (articleList: ArticlePouchModel[]): ArticlePouchModel[] =>
			articleList.filter(i => i.articleDescription.free_sample === 'S')
	}
];