import { BaseTableListService, ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Il service è condiviso in molteplici contesti spesso coesistenti,
 * è consigliabile dunque instanziarlo univocamente nei provider del componente in cui viene utilizzato
 * per evitare che alcuni elementi ritornino inaspettatamente modificati rispetto al default
 *
 * @export
 * @class PermissionContextListColumnService
 * @extends {BaseTableListService}
 */
@Injectable()
export class PermissionContextListColumnService extends BaseTableListService {
	constructor(@Inject(TranslateService) public translate: TranslateService) {
		super();
	}
	_columns: ITdDataTableColumnCustom[] = [
		{
			name: 'code',
			labelPath: 'context_code.field.code',
			label: 'Code'
		},
		{
			name: 'description',
			labelPath: 'context_code.field.description',
			label: 'Description'
		},
		{
			name: 'typology',
			labelPath: 'context_code.field.typology',
			label: 'Typology'
		},
		{
			name: 'permission',
			labelPath: 'context_code.field.permissions',
			label: 'Permission'
		},
		{
			name: 'action',
			label: null,
			width: 56,
			numeric: true
		}
	];
}
