export namespace ConfigurationOrganizationVisibilityFilter{
	// Context
	export const filterVisibleForAgent = [
		'organization_type',	
		'status',	
		'organization_class'				
	]; 	
	export const filterVisibleForBackoffice = [
		'organization_class'		
	]; 		
	export const filterVisibleForCrm =[
		'organization_type',	
		'status',	
		'organization_class'			
	]; 
}

export namespace ClassRequest {
	export const classificationList = [
		'Medico ortopedico', 
		'Fisioterapista', 
		'Fisiatra', 
		'Specializzandi', 
		'Infermieri'
	];
}


