import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-page-header',
	templateUrl: './page-header.component.html',
	styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
	@Input() title;
	@Input() backgroundImageUrl;
	@Input() hasBackdrop?: boolean = false;

	private _breadcrumbsList: string[];
	@Input()
	set breadcrumbsList(inputBreadcrumbsList: string[]) {
		this._breadcrumbsList = inputBreadcrumbsList.filter(el => el !== '');
	}
	get breadcrumbsList(): string[] {
		return this._breadcrumbsList;
	}

	constructor() {}

	ngOnInit(): void {}
}
