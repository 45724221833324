import { Component, OnInit } from '@angular/core';
import { ItemBoxTrendInfoComponentConfig } from '../../../widget/box-trend-info/box-trend-info.component';
import { Store } from '@ngrx/store';
import {
	ItemSidebarContentStandardComponentConfig,
	IconType
} from '../../../widget/sidebar-content-standard/sidebar-content-standard.component';
import { StatisticActionEnum, StatisticStateAction } from '../../../state/statistic/statistic.action';
import {
	BaseStateModel,
	SubscribeManagerService,
	LoaderService,
	AngularCoreUtilService,
	UtilColorService,
	DateMomentService,
	SentencecasePipe
} from '@saep-ict/angular-core';
import { filter, map, Observable, debounceTime, mergeMap } from 'rxjs';
import { StatisticModel } from '../../../model/statistics.model';
import { StateFeature } from '../../../state';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StatisticEnum } from '../../../enum/statistic.enum';
import {
	AngularSpin8CoreUtilTranslateService,
	OrganizationStateModel,
	UserDetailModel
} from '@saep-ict/angular-spin8-core';
import _ from 'lodash';
import { SideBarPositionValues } from '../../../enum/sidebar-position-values.enum';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { Category, CategoryMap,ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import { UtilStatisticService } from '../../../service/util/util-statistc.service';
import { DatePipe } from '@angular/common';
import { BaseChartClass } from '../../../service/chart-structure/chart-configurations/base-chart.service';
import { ActivatedRoute } from '@angular/router';
import { ChartData, ChartTooltipItem } from 'chart.js';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as UtilPrice from '../../../constants/util-price.constants';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';
import moment from 'moment';
import { StoreUtilService } from '../../../service/util/store-util.service';
import { ArticleStateAction } from '../../../state/article/article.actions';

@Component({
	selector: 'reports',
	templateUrl: './reports.component.html',
	styleUrls: ['./reports.component.scss'],
	providers: [SubscribeManagerService]
})
export class ReportsComponent implements OnInit {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;
	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(StateFeature.getOrganizationState);
	organization: OrganizationStateModel;
	statistic$: Observable<BaseStateModel<StatisticModel.Document>> = this.store.select(StateFeature.getStatistic);
	statisticNestedCategoryConfiguration: StatisticModel.Structure.NestedCategoryConfiguration = {
		unit: StatisticEnum.Unit.VALUE,
		filter: {
			categoryToExlcude: []
		}
	};
	categoryList$: Observable<BaseStateModel<CategoryMap>> = this.store.select(StateFeature.getCategoryListState);
	categoryTree: Category[];
	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);
	articleList: ArticlePouchModel[];
	formFilter: FormGroup;
	statisticEnum = StatisticEnum;
	object = Object;
	sideBarPositionValues = SideBarPositionValues;
	trendBoxContent: Array<ItemBoxTrendInfoComponentConfig> = [];
	chartCategoryRootBar: BaseChartClass = new BaseChartClass(
		this.utilService,
		this.utilColorService,
		this.store,
		this.route,
		this.dateMomentService
	);
	chartStatistcSummaryListDoughnut: BaseChartClass[];
	statisticDataUpdateSnackBarTurnedOff = true;

	constructor(
		private store: Store,
		private subscribeManagerService: SubscribeManagerService,
		private fb: FormBuilder,
		public utilTranslateService: AngularSpin8CoreUtilTranslateService,
		public utilBreadcrumbService: UtilBreadcrumbService,
		private utilStatisticService: UtilStatisticService,
		private datePipe: DatePipe,
		private loaderService: LoaderService,
		public utilService: AngularCoreUtilService,
		public utilColorService: UtilColorService,
		public route: ActivatedRoute,
		public dateMomentService: DateMomentService,
		private matSnackBar: MatSnackBar,
		private sentenceCasePipe: SentencecasePipe,
		private utilStoreService: StoreUtilService,
	) {
		this.init();
	}

	async init() {
		this.loadStaticData();
		this.formFilterCreate();
		this.storeDispatch();
		this.subscribeManagerInit();
		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('reports');
		this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['reports']);
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
		this.store.dispatch(ArticleStateAction.reset());
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	storeDispatch() {
		this.store.dispatch(
			StatisticStateAction.load({
				_id:
					'statistic' +
					ConfigurationCustomer.AppStructure.noSqlDocSeparator +
					StatisticEnum.Type.SALES_REPORTS +
					'_' +
					this.user.current_permission.context_code.code
			})
		);
		this.store.dispatch(
			ArticleStateAction.loadFromRecap(
				{
					dataSetting: {
						appliedFilter: {
							organization: this.organization
						}
					},
					data: null
				}
			)
		);
	}

	// static data
	async loadStaticData() {
		this.utilStoreService.retrieveSyncState<UserDetailModel>(this.user$).subscribe(e => {
			this.user = e.data;
		});
		this.utilStoreService.retrieveSyncState<CategoryMap>(this.categoryList$).subscribe(e => {
			this.categoryTree = e.data.tree;
		});
		this.utilStoreService.retrieveSyncState<OrganizationStateModel>(this.organization$).subscribe(e => {
			this.organization = e.data;
		});
	}

	// subscribe
	subscribeManagerInit() {
		this.subscribeManagerService.populate(
			this.subscribeStatisticData().subscribe(
				res => {},
				error => {
					console.log('subscribeManagerService statistic-data: something went wrong ', error);
				}
			),
			'statistic-data'
		);
		this.subscribeManagerService.populate(
			this.subscribeFormFilterChange().subscribe(
				res => {},
				error => {
					console.log('subscribeManagerService formFilter-data: something went wrong ', error);
				}
			),
			'formFilter-data'
		);
	}

	subscribeStatisticData(): Observable<Promise<void>> {
		return this.articleList$.pipe(
			filter((e: BaseStateModel<ArticlePouchModel[]>) => !!(e && e.data)),
			mergeMap((e: BaseStateModel<ArticlePouchModel[]>) => {
				this.articleList = e.data;
				return this.statistic$;
			}),
			filter((e: BaseStateModel<StatisticModel.Document>) => !!(e && e.data)),
			map(async (e: BaseStateModel<StatisticModel.Document>) => {
				switch (e.type) {
					case StatisticActionEnum.UPDATE:
						const loaderGuid: string = this.loaderService.guid();
						this.loaderService.populateLoader(loaderGuid);
						this.utilStatisticService.categoryTreeWithArticle = await this.utilStatisticService.createDataStructureAndSource(
							this.user,
							this.categoryTree,
							this.articleList,
							loaderGuid
						);
						this.statisticNestedCategoryConfiguration.data = e.data;
						this.utilBreadcrumbService.subtitle = {
							value: 'general.last_update_date',
							param: {
								date: moment(e.data.date_update).format("DD/MM/YYYY")
							}
						};
						this.statisticDataParseUpdate();
						break;
					case StatisticActionEnum.ERROR:
						throw new Error(StatisticActionEnum.ERROR);
				}
			})
		);
	}

	subscribeFormFilterChange(): Observable<Promise<void>> {
		return this.formFilter.valueChanges.pipe(
			debounceTime(1500),
			map(async e => {
				this.statisticNestedCategoryConfiguration.unit = e.unit;
				this.statisticDataParseUpdate();
			})
		);
	}

	// statistic data parse util
	async statisticDataParseUpdate() {
		const loaderGuid: string = this.loaderService.guid();
		this.loaderService.populateLoader(loaderGuid);
		this.statisticNestedCategoryConfiguration = _.cloneDeep(
			await this.utilStatisticService.createDataSource(this.statisticNestedCategoryConfiguration, loaderGuid)
		);
		this.trendBoxContentUpdate();
		this.chartCategoryRootBarUpdate(this.statisticNestedCategoryConfiguration);
		this.chartStatistcSummaryListDoughnutUpdate(this.statisticNestedCategoryConfiguration);
		if (!this.statisticDataUpdateSnackBarTurnedOff) {
			this.matSnackBar.open(
				this.sentenceCasePipe.transform(this.utilTranslateService.translate.instant('general.data_updated')),
				this.utilTranslateService.translate.instant('general.close').toUpperCase(),
				{ duration: 2000 }
			);
		}
		this.statisticDataUpdateSnackBarTurnedOff = false;
	}

	// summary util
	trendBoxContentUpdate() {
		this.trendBoxContent = [];
		this.statisticNestedCategoryConfiguration.dataParse.summary.forEach(i => {
			const summary: ItemBoxTrendInfoComponentConfig = {
				value: UtilPrice.returnItemValueFormatted(
					i.total[this.statisticNestedCategoryConfiguration.unit],
					0
				),
				suffix: this.statisticNestedCategoryConfiguration.data.unit[
					this.statisticNestedCategoryConfiguration.unit
				],
				title: `${i.total.description}`,
				subtitle: `${this.datePipe.transform(i.date.begin, 'dd/MM/yyyy')} -
                  ${this.datePipe.transform(i.date.end, 'dd/MM/yyyy')}`,
				trendDirection: this.utilStatisticService.returnTrandIndicator(
					i.trend[this.statisticNestedCategoryConfiguration.unit]
				)
			};
			this.trendBoxContent.push(summary);
		});
	}

	// chart util
	chartCategoryRootBarUpdate(configuration: StatisticModel.Structure.NestedCategoryConfiguration) {
		this.chartCategoryRootBar.chart.chartType = 'bar';
		this.chartCategoryRootBar.chart.colors = [{ borderColor: this.chartCategoryRootBar.colorWhite }];
		this.chartCategoryRootBar.fullLabels = [];
		this.chartCategoryRootBar.fullDataSets = [];
		for (const category of configuration.dataParse.categoryTree) {
			this.chartCategoryRootBar.fullLabels.push(
				this.utilTranslateService.getTranslationFromLanguage(category.language_list).description
			);
		}
		const backgroundColorGradient: number = 1 / configuration.dataParse.summary.length;
		for (let i = 0; i < configuration.dataParse.summary.length; i++) {
			const valueList: number[] = [];
			for (const category of configuration.dataParse.categoryTree) {
				let statisticItem: StatisticModel.Item.Base;
				if (category.statisticList && category.statisticList.length) {
					statisticItem = category.statisticList.find(s => s.key === configuration.dataParse.summary[i].key);
				}
				valueList.push(statisticItem ? statisticItem.total[configuration.unit] : 0);
			}
			this.chartCategoryRootBar.fullDataSets.push({
				label: configuration.dataParse.summary[i].total.description,
				data: valueList,
				backgroundColor: `rgba(${this.chartCategoryRootBar.colorPrimaryRGB.r},
                  ${this.chartCategoryRootBar.colorPrimaryRGB.g},
                  ${this.chartCategoryRootBar.colorPrimaryRGB.b},
                  ${backgroundColorGradient * (configuration.dataParse.summary.length - i)})`,
				hoverBackgroundColor: `rgba(${this.chartCategoryRootBar.colorAccentRGB.r},
                  ${this.chartCategoryRootBar.colorAccentRGB.g},
                  ${this.chartCategoryRootBar.colorAccentRGB.b},
                  1`
			});
		}
		this.chartCategoryRootBar.removeData(this.chartCategoryRootBar.chart.labels);
		this.chartCategoryRootBar.removeData(this.chartCategoryRootBar.chart.datasets);
		let tempSerie = [];
		this.chartCategoryRootBar.fullDataSets.forEach(item => {
			let serie = _.cloneDeep(item);
			serie.data = item.data;
			tempSerie.push(serie);
		});
		this.chartCategoryRootBar.addData(this.chartCategoryRootBar.chart.datasets, tempSerie);
		this.chartCategoryRootBar.addData(this.chartCategoryRootBar.chart.labels, this.chartCategoryRootBar.fullLabels);
		this.chartCategoryRootBar.chart.options = {
			responsive: true,
			tooltips: {
				intersect: false,
				callbacks: {
					label: (tooltipItem: ChartTooltipItem, data: ChartData) => {
						const label =
							data.datasets[tooltipItem.datasetIndex].label +
							' ' +
							UtilPrice.returnItemValueFormatted(
								data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] as number,
								0
							) +
							configuration.data.unit[configuration.unit];
						return label;
					}
				}
			},
			scales: {
				yAxes: [
					{
						ticks: {
							display: true,
							callback: this.chartCategoryRootBar.ticksCallbackReturnKIfThousand()
						}
					}
				]
			}
		};
		this.chartCategoryRootBar.chart = _.cloneDeep(this.chartCategoryRootBar.chart);
	}

	chartStatistcSummaryListDoughnutUpdate(configuration: StatisticModel.Structure.NestedCategoryConfiguration) {
		this.chartStatistcSummaryListDoughnut = [];
		const summaryTotal: StatisticModel.Item.Base[] = configuration.dataParse.summary.filter(i => i.total);
		for (let i = 0; i < summaryTotal.length; i++) {
			this.chartStatistcSummaryListDoughnut[i] = new BaseChartClass(
				this.utilService,
				this.utilColorService,
				this.store,
				this.route,
				this.dateMomentService
			);
			this.chartStatistcSummaryListDoughnut[i].chart.chartType = 'doughnut';
			this.chartStatistcSummaryListDoughnut[i].chart.colors = [
				{ borderColor: this.chartStatistcSummaryListDoughnut[i].colorWhite }
			];
			this.chartStatistcSummaryListDoughnut[i].fullDataSets = [];
			for (const category of configuration.dataParse.categoryTree) {
				this.chartStatistcSummaryListDoughnut[i].chart.labels;
				this.chartStatistcSummaryListDoughnut[i].fullLabels.push(
					this.utilTranslateService.getTranslationFromLanguage(category.language_list).description
				);
			}
			const backgroundColorGradient: number = 1 / configuration.dataParse.categoryTree.length;
			const valueList: number[] = [];
			const colorList: string[] = [];
			for (let c = 0; c < configuration.dataParse.categoryTree.length; c++) {
				let statisticItem: StatisticModel.Item.Base;
				if (
					configuration.dataParse.categoryTree[c].statisticList &&
					configuration.dataParse.categoryTree[c].statisticList.length
				) {
					statisticItem = configuration.dataParse.categoryTree[c].statisticList.find(
						s => s.key === summaryTotal[i].key
					);
				}
				valueList.push(
					statisticItem && statisticItem.total
						? (statisticItem.total[configuration.unit] * 100) / summaryTotal[i].total[configuration.unit]
						: 0
				);
				colorList.push(
					`rgba(${this.chartStatistcSummaryListDoughnut[i].colorPrimaryRGB.r},
                  ${this.chartStatistcSummaryListDoughnut[i].colorPrimaryRGB.g},
                  ${this.chartStatistcSummaryListDoughnut[i].colorPrimaryRGB.b},
                  ${backgroundColorGradient * (configuration.dataParse.categoryTree.length - c)})`
				);
			}
			this.chartStatistcSummaryListDoughnut[i].fullDataSets.push({
				label: null,
				data: valueList,
				backgroundColor: colorList,
				hoverBackgroundColor: `rgba(${this.chartStatistcSummaryListDoughnut[i].colorAccentRGB.r},
                  ${this.chartStatistcSummaryListDoughnut[i].colorAccentRGB.g},
                  ${this.chartStatistcSummaryListDoughnut[i].colorAccentRGB.b},
                  1`
			});
			this.chartStatistcSummaryListDoughnut[i].removeData(this.chartStatistcSummaryListDoughnut[i].chart.labels);
			this.chartStatistcSummaryListDoughnut[i].removeData(
				this.chartStatistcSummaryListDoughnut[i].chart.datasets
			);
			let tempSerie = [];
			this.chartStatistcSummaryListDoughnut[i].fullDataSets.forEach(item => {
				let serie = _.cloneDeep(item);
				serie.data = item.data;
				tempSerie.push(serie);
			});
			this.chartStatistcSummaryListDoughnut[i].addData(
				this.chartStatistcSummaryListDoughnut[i].chart.datasets,
				tempSerie
			);
			this.chartStatistcSummaryListDoughnut[i].addData(
				this.chartStatistcSummaryListDoughnut[i].chart.labels,
				this.chartStatistcSummaryListDoughnut[i].fullLabels
			);
			this.chartStatistcSummaryListDoughnut[i].chart.options = {
				responsive: true,
				title: {
					display: true,
					text: `${summaryTotal[i].total.description}`,
					position: 'bottom'
				},
				tooltips: {
					intersect: false,
					callbacks: {
						label: (tooltipItem: ChartTooltipItem, data: ChartData) => {
							let label = data.labels[tooltipItem.index];
							let value = UtilPrice.returnItemValueFormatted(
								data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] as number,
								1
							);
							return `${label}: ${value}%`;
						}
					}
				}
			};
			this.chartStatistcSummaryListDoughnut[i].chart = _.cloneDeep(
				this.chartStatistcSummaryListDoughnut[i].chart
			);
		}
	}

	// filter
	formFilterCreate() {
		this.formFilter = this.fb.group({
			unit: [this.statisticNestedCategoryConfiguration.unit]
		});
	}

	/** CONTENUTI FAKE  **/

	sidebarContentDownloadCatalog: Array<ItemSidebarContentStandardComponentConfig> = [
		{
			title: 'Download the updated sales report',
			subtitle: 'Updated: 10 / 11 / 2021',
			//rightContent: '250k',
			icon: { type: IconType.CUSTOM, name: 'custom-download' }
		}
	];
}
