<small
	*ngIf="
		article?.articleDescription?.qty_box > 1 &&
		((organization && configurationCustomer.Order.qtyBoxMultipleCheck[organization.organization_type]) ||
			(!organization && configurationCustomer.Order.qtyBoxMultipleCheck.PUBLIC))
	"
	class="cell-subtitle d-block"
>
	{{ 'article.can_be_ordered_in_multiples_of' | translate | sentencecase }} {{ article.articleDescription.qty_box }}
</small>
