<mat-dialog-wrapper [title]="title" [showCloseIcon]="false">
	<ng-container content>
		<address-list
			[viewType]="ViewTypeEnum.list"
			[mode]="ListMode.pickable"
			(addressPicked)="pick($event)"
		></address-list>
	</ng-container>

	<ng-container actions>
		<form-button-bar
			[align]="'right'"
			[secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
			(secondaryButtonClickEmitter)="dialogRef.close()"
		></form-button-bar>
	</ng-container>
</mat-dialog-wrapper>
