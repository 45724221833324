import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// store
import { Store } from '@ngrx/store';
import {
	BaseStateModel,
	SubscribeManagerService,
	MediaReplayService,
	LocalListHandlerBase
} from '@saep-ict/angular-core';	
import { LocalListHandlerBaseModel } from '@saep-ict/pouch_agent_models';
import { Observable } from 'rxjs';
import { map, catchError, filter } from 'rxjs/operators';
import { OrganizationListStateAction } from '../../../state/common/organization-list/organization-list.actions';

// widet & utility
import { AngularCoreUtilService } from '@saep-ict/angular-core';
import { StateFeature } from '../../../state';
import * as _ from 'lodash';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { PermissionContextListColumnService } from '../../../service/td-data-table/implementation/backoffice/permission-context-list.service';
import { OrganizationStateModel } from '@saep-ict/angular-spin8-core';

@Component({
	selector: 'dialog-select-organization',
	templateUrl: './dialog-select-organization.component.html',
	styleUrls: ['./dialog-select-organization.component.scss'],
	providers: [SubscribeManagerService]
})
export class DialogSelectOrganizationComponent implements OnDestroy {
	listPageBaseData = <LocalListHandlerBaseModel<OrganizationStateModel>>{
		data: [],
		filters: {
			localSearchText: {
				value: null,
				key_list: ['business_name']
			}
		},
		pagination: {
			pageSize: 5
		},
		sort: {
			name: 'code',
			order: 'ASC'
		}
	};

	columns: ITdDataTableColumn[];
	organizationList$: Observable<BaseStateModel<OrganizationStateModel[]>> = this.store.select(
		StateFeature.getOrganizationListState
	);

	constructor(
		// @Inject(MAT_DIALOG_DATA) public data: ContextCodeItemLink,
		private store: Store<any>,
		public mediaReplayService: MediaReplayService,
		public dialogRef: MatDialogRef<DialogSelectOrganizationComponent>,
		public utilService: AngularCoreUtilService,
		private subscribeManagerService: SubscribeManagerService,
		private permissionContextListColumnService: PermissionContextListColumnService
	) {
		this.setVisibleTableColumn();
		this.store.dispatch(OrganizationListStateAction.loadAll());
		this.subscribeManagerService.populate(this.subscribeOrganizationList().subscribe(), 'organization-list');
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(OrganizationListStateAction.reset());
	}

	// subscribe
	subscribeOrganizationList(): Observable<BaseStateModel<OrganizationStateModel[]>> {
		return this.organizationList$.pipe(
			filter(state => !!(state && state.data)),
			map((state: BaseStateModel<OrganizationStateModel[]>) => {
				this.listPageBaseData.data = state.data;
				this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
				return state;
			}),
			catchError((error, caught) => {
				console.log(error);
				this.listPageBaseData.data = [];
				this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
				return caught;
			})
		);
	}

	// widget & utility
	selectOrganization(organization: OrganizationStateModel) {
		this.dialogRef.close(organization);
	}

	setVisibleTableColumn() {
		this.permissionContextListColumnService.columns.map(col => {
			col.hidden = ['permission', 'action', 'typology'].includes(col.name);
		});
		this.columns = _.cloneDeep(this.permissionContextListColumnService.columns);
	}
}
