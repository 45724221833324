import _ from 'lodash';
import { DestinationColumnMap } from '@saep-ict/angular-spin8-core';
import { ITdDataTableColumn } from '@covalent/core/data-table';

export const projectOverride: ITdDataTableColumn[] = 
  _.cloneDeep(DestinationColumnMap.base)
  .concat([
    {
      name: 'address.province.label',
      labelPath: 'theme.destination.province',
      label: null
    },
    {
      name: 'address.country',
      labelPath: 'theme.destination.country',
      label: null
    }
  ]);