<mat-toolbar *ngIf="localListHandlerTextFilterShow">
	<mat-toolbar-row>
		<span class="flex-span"></span>
		<td-search-box
			*ngIf="localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="true"
			(searchDebounce)="localListHandlerApplyFilterCustom()"
			[placeholder]="'general.search' | translate | sentencecase"
		>
		</td-search-box>
	</mat-toolbar-row>
</mat-toolbar>
<div #infiniteScrollContainer>
	<ng-scrollbar
		*ngIf="infiniteScrollContainerHeight"
		#scrollbar
		[autoWidthDisabled]="true"
		[autoHeightDisabled]="false"
		[style.max-height.px]="infiniteScrollContainerHeight"
		(reachedBottom)="infiniteScrollChunkAdd(infiniteScrollChunkSize)"
	>
		<mat-accordion multi>
			<mat-expansion-panel
				*ngFor="let category of categoryTree; let i = index"
				[expanded]="_configuration && _configuration.multipleOpen ? null : matAccordionExpansionPanelActiveIndex === i"
				(opened)="setMatAccordionExpansionPanelActiveIndex(i)"
			>
				<mat-expansion-panel-header>
					<mat-panel-title>
						<span class="mat-panel-open" style="display:none">
							<ng-container
								*ngIf="
									category.language_list &&
									category.language_list[0] &&
									category.language_list[0].image_list &&
									category.language_list[0].image_list[0]; else noImageTemplate
								"
							>
								<img class="logo"
									[attr.src]="
										appConfig.config.bucketManager.be_url +
										'/category/download-multipart?path=/' +
										category.code_item +
										'/' +
										category.language_list[0].image_list[0].name +
										'&token=' +
										appConfig.token
									"
									[attr.title]="category.language_list[0].image_list[0].name"
									[attr.alt]="category.language_list[0].image_list[0].name"
								/>
							</ng-container>
							<ng-template #noImageTemplate>
								{{ utilTranslateService.getTranslationFromLanguage(category.language_list).description | translate | sentencecase }}
							</ng-template>
						</span>
		
						<span class="mat-panel-close">
							{{
								utilTranslateService.getTranslationFromLanguage(category.language_list).description
									| translate
									| sentencecase
							}}
						</span>
						<!-- pipe `translate` necessaria per gestione label multingua nei soli articoli senza categoria -->
						<span class="total">
							{{ category.metaInformation && category.metaInformation.total ? category.metaInformation.total : '' }}
						</span>
					</mat-panel-title>
				</mat-expansion-panel-header>
				<ng-container *ngIf="matAccordionExpansionPanelActiveIndex === i">
					<!-- in `ConfigurationCustomer.Order.categorySectionList` è presente una lista di sezioni nella quale
					suddividere gli articoli del il livello -->
					<ng-container *ngIf="category.category_list && category.category_list.length > 0">
						<ng-container *ngFor="let section of category.category_list">
							<!-- trattandosi `section` di una categoria fittizia, la descrizione multilingua si trova nei bundle FE e non nel
							database: il controller salva il percorso per la traduzione nella prop. `description` lasciando il resto alla pipe
							`translate`, senza avvalersi dei meccanismi legati a `language_list` -->
							<ng-container *ngIf="section.article_list && section.article_list.length > 0">
								<h3>{{ section.description | translate | sentencecase }}</h3>
								<ng-container
									*ngTemplateOutlet="articleTableWrapper; context: { category: section }"
								></ng-container>
							</ng-container>
						</ng-container>
					</ng-container>
					<!-- non c'è un sub annidamento, l'accordion rappresenta solo il livello configurato -->
					<ng-container *ngIf="!(category.category_list && category.category_list.length > 0)">
						<ng-container *ngTemplateOutlet="articleTableWrapper; context: { category: category }"></ng-container>
					</ng-container>
				</ng-container>
			</mat-expansion-panel>
		</mat-accordion>
		<div class="infinite-scroll-show-more-container">
			<button
				*ngIf="
					categoryTree &&
					categoryTreeComplete &&
					categoryTree.length < categoryTreeComplete.length
				"
				mat-raised-button
				color="accent"
				type="button"
				(click)="infiniteScrollChunkAdd(infiniteScrollChunkSize)"
			>
				{{ 'catalogue.search.more' | translate | sentencecase}} {{ 'category.name_plural' | translate }}
			</button>
		</div>
	</ng-scrollbar>
</div>
<ng-template #articleTableWrapper let-category="category">
	<new-article-table-wrapper
		*ngIf="category.article_list && category.article_list.length > 0; else noData"
		[order]="order"
		[localListHandlerData]="{
			data: localListHandlerData.data,
			dataSubset: category.article_list,
			filters: localListHandlerData.filters,
			languageKey: localListHandlerData.languageKey,
			pageName: localListHandlerData.pageName,
			pagination: localListHandlerData.pagination,
			sort: localListHandlerData.sort,
			sortRemapObject: localListHandlerData.sortRemapObject,
			columnList: _localListHandlerWidgetWrapperColumnList
		}"
		[localListHandlerDataGlobalSubset]="localListHandlerData.dataSubset.length"
		[sortable]="true"
		[organization]="organization"
		[configurationAction]="configurationAction"
		(onArticleChange)="onArticleChangeHandler($event)"
		(sortChange)="localListHandlerApplyFilterCustom({ sort: $event })"
		(onItemSelect)="onItemSelect.emit($event)"
		(onDeleteArticle)="onDeleteArticle.emit($event)"
		(onForecastChange)="onForecastChangeHandler($event)"
	>
	</new-article-table-wrapper>
</ng-template>
<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
