import { ConfigurationUtil } from '@saep-ict/angular-spin8-core';
import { TicketStatusContextApplicationMapItem } from '@saep-ict/ticket-center';

export namespace TicketCenterStatus {
	export enum TypeEnum {
		SENT = 'SENT',
		ARCHIVED = 'ARCHIVED'
	}

	export const contextApplicationMap = <
		ConfigurationUtil.Context.ApplicationSelector<TicketStatusContextApplicationMapItem>
	>(<unknown>{
		BACKOFFICE: {
			selection_list: [
				{
					value: TicketCenterStatus.TypeEnum.SENT,
					name: 'New Request',
					color: '#4caf50'
				},
				{ value: TicketCenterStatus.TypeEnum.ARCHIVED }
			],
			filter_list: [
				{
					value: TicketCenterStatus.TypeEnum.SENT,
					name: 'New Request'
				},
				{ value: TicketCenterStatus.TypeEnum.ARCHIVED }
			]
		},
		B2B: {
			filter_list: [{ value: TicketCenterStatus.TypeEnum.SENT }, { value: TicketCenterStatus.TypeEnum.ARCHIVED }]
		},
		PORTAL: {
			filter_list: [{ value: TicketCenterStatus.TypeEnum.SENT }, { value: TicketCenterStatus.TypeEnum.ARCHIVED }]
		}
	});
}
