import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SentencecasePipe, UtilColorService } from '@saep-ict/angular-core';
import * as _ from 'lodash';
import { filter, take } from 'rxjs/operators';
import { StatisticsAgent } from '../../../model/statistics-agent.model';
import { BaseChartClass } from '../chart-configurations/base-chart.service';
import { AppUtilService } from '../../util/app-util.service';
import { MetricsDetail, StatisticsBaseSerieOrder } from '../../../model/statistics.model';
import { ChartEnum } from '@saep-ict/angular-spin8-core';

@Injectable()
export class ClientsActiveChartConfigService extends BaseChartClass {
	metrics: MetricsDetail;

	constructor(
		public utilService: AppUtilService,
		public utilColorService: UtilColorService,
		public store: Store<any>,
		private translateService: TranslateService,
		private sentencecasePipe: SentencecasePipe,
		public route: ActivatedRoute
	) {
		super(utilService, utilColorService, store, route);
		this.chart.chartType = 'line';
		this.chart.options = this.optionsStyle(ChartEnum.Theme.DEFAULT);
		this.chart.colors = [{ borderColor: this.colorPrimary }];
	}

	// Functions

	createDataSerie() {
		if (this.checkStatisticsAgent(this.statistics)) {
			const activeClients = this.utilService.findLeaf(this.statistics, 'clients.active_client');

			const serieTemp: number[] = [];
			activeClients.forEach((element: StatisticsBaseSerieOrder) => {
				serieTemp.push(element.value);
				this.fullLabels.push(element.date);
			});
			this.fullDataSets.push({
				label: 'active',
				data: serieTemp
			});
		}
	}

	populateChart(rangeDays: number = this.cardDashboardConfigClientsActive.rangeTempList.values[0]) {
		this.removeData(this.chart.labels);
		this.removeData(this.chart.datasets);

		let tempSerie = [];

		this.fullDataSets.forEach(item => {
			let serie = _.cloneDeep(item);
			serie.data = item.data.slice(-rangeDays);
			tempSerie.push(serie);
		});

		this.addData(this.chart.datasets, tempSerie);
		this.addData(this.chart.labels, this.fullLabels.slice(-rangeDays));

		this.getMetrics(rangeDays);
		this.chart = _.cloneDeep(this.chart);
	}

	getMetrics(rangeDays: number) {
		this.statistics$
			.pipe(
				filter(state => !!(state && state.data)),
				take(1)
			)
			.subscribe(res => {
				if (this.checkStatisticsAgent(res.data)) {
					this.metrics = this.formatMetrics(this.utilService.findLeaf(res.data, `clients.metrics.active_client.days_${rangeDays}`));
				}
			});
	}

	checkStatisticsAgent(statistics: StatisticsAgent): statistics is StatisticsAgent {
		// if statistics has field 'clients', the type of statistics is 'StatisticsAgent'
		return !!statistics['clients'];
	}

	formatMetrics(data) {
		const metrics = {
			average: data.average < 1 ? +data.average.toFixed(2) : +data.average.toFixed(0),
			trend: data.trend.toFixed(0)
		};
		return metrics;
	}

	// Style

	tooltipsCallbacks(translateService, sentencecasePipe) {
		return {
			label: function (tooltipItem, data) {
				let label =
					translateService.instant('client.status.' + data.datasets[tooltipItem.datasetIndex].label) || '';

				if (label) {
					label = sentencecasePipe.transform(label);
					label += ': ';
				}
				label += tooltipItem.yLabel.toFixed(2);
				return label;
			}
		};
	}

	yAxesStyle(theme: ChartEnum.Theme) {
		return [
			{
				stacked: false,
				gridLines: {
					drawBorder: false,
					display: false,
					borderDash: [4, 4],
					color: theme === ChartEnum.Theme.DEFAULT ? this.colorBlack30 : this.colorWhite50
				},
				ticks: {
					display: false,
					// Valore minimo dell'asse
					min: 0,
					// Scala da usare
					// stepSize: 1000,
					// Sets the offset of the tick labels from the axis
					padding: 8,
					// Font color for tick labels.
					fontColor: theme === ChartEnum.Theme.DEFAULT ? this.colorBlack : this.colorWhite
					// callback: this.ticksCallback()
				}
			}
		];
	}

	xAxesStyle() {
		return [
			{
				stacked: false,
				gridLines: {
					drawBorder: false,
					display: false
				},
				ticks: {
					display: false
				}
			}
		];
	}

	tooltipsStyle() {
		return {
			// se false il tooltip compare se sull'asse x
			intersect: false,
			callbacks: this.tooltipsCallbacks(this.translateService, this.sentencecasePipe)
		};
	}

	optionsStyle(theme: ChartEnum.Theme) {
		return {
			responsive: true,
			layout: this.layoutStyle(),
			elements: this.elementsStyle(),
			tooltips: this.tooltipsStyle(),
			hover: this.hoverStyle(),
			scales: {
				xAxes: this.xAxesStyle(),
				yAxes: this.yAxesStyle(theme)
			},
			aspectRatio: 2
		};
	}
}
