import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SentencecasePipe, UtilColorService, AngularCoreUtilService } from '@saep-ict/angular-core';
import { ChartEnum } from '@saep-ict/angular-spin8-core';
import _ from 'lodash';
import { StatisticsBaseSerieProduct } from '../../../model/statistics.model';
import { AppUtilService } from '../../util/app-util.service';
import { BaseChartClass } from '../chart-configurations/base-chart.service';

@Injectable()
export class OrderBestOrderedChartConfigService extends BaseChartClass {
	constructor(
		public utilService: AppUtilService,
		public utilColorService: UtilColorService,
		public store: Store<any>,
		private translateService: TranslateService,
		private sentencecasePipe: SentencecasePipe,
		public route: ActivatedRoute
	) {
		super(utilService, utilColorService, store, route);
		this.chart.chartType = 'horizontalBar';
		this.chart.options = this.optionsStyle(ChartEnum.Theme.DEFAULT);
		this.chart.colors = [{ borderColor: this.colorPrimary }];
	}

	// Functions

	createDataSerie() {
		// const ordersProduct = this.statistics.orders.products;

		const serieTempDays7: number[] = [];
		const serieTempDays15: number[] = [];
		const serieTempDays30: number[] = [];

		this.utilService
			.findLeaf(this.statistics.data, `orders.products.days_7`)
			.forEach((element: StatisticsBaseSerieProduct) => {
				serieTempDays7.push(element.value);
				this.fullLabelsDays7.push(element.description);
			});

		this.utilService
			.findLeaf(this.statistics.data, `orders.products.days_15`)
			.forEach((element: StatisticsBaseSerieProduct) => {
				serieTempDays15.push(element.value);
				this.fullLabelsDays15.push(element.description);
			});

		this.utilService
			.findLeaf(this.statistics.data, `orders.products.days_30`)
			.forEach((element: StatisticsBaseSerieProduct) => {
				serieTempDays30.push(element.value);
				this.fullLabelsDays30.push(element.description);
			});

		this.fullDataSetDays7.push({
			label: 'order.field.quantity',
			backgroundColor: this.colorPrimary,
			data: serieTempDays7
		});
		this.fullDataSetDays15.push({
			label: 'order.field.quantity',
			backgroundColor: this.colorPrimary,
			data: serieTempDays15
		});
		this.fullDataSetDays30.push({
			label: 'order.field.quantity',
			backgroundColor: this.colorPrimary,
			data: serieTempDays30
		});
	}

	populateChart(rangeDays: number = this.cardDashboardConfigOrdersBestOrdered.rangeTempList.values[0]) {
		this.removeData(this.chart.labels);
		this.removeData(this.chart.datasets);

		switch (rangeDays) {
			case 7:
				this.addData(this.chart.datasets, this.fullDataSetDays7);
				this.addData(this.chart.labels, this.fullLabelsDays7);
				break;
			case 15:
				this.addData(this.chart.datasets, this.fullDataSetDays15);
				this.addData(this.chart.labels, this.fullLabelsDays15);
				break;
			case 30:
				this.addData(this.chart.datasets, this.fullDataSetDays30);
				this.addData(this.chart.labels, this.fullLabelsDays30);
				break;

			default:
				break;
		}
		this.chart = _.cloneDeep(this.chart);
	}

	// Style

	tooltipsCallbacks(translateService, sentencecasePipe) {
		return {
			label: function (tooltipItem, data) {
				let label = translateService.instant(data.datasets[tooltipItem.datasetIndex].label) || '';

				if (label) {
					label = sentencecasePipe.transform(label);
					label += ': ';
				}
				label += tooltipItem.value;
				return label;
			}
		};
	}

	yAxesStyle(theme: ChartEnum.Theme) {
		return [
			{
				stacked: false,
				gridLines: {
					drawBorder: false,
					display: true,
					borderDash: [4, 4],
					color: theme === ChartEnum.Theme.DEFAULT ? this.colorBlack30 : this.colorWhite50
				},
				ticks: {
					display: true,
					// Valore minimo dell'asse
					// min: 0,
					// Scala da usare
					// stepSize: 1000,
					// Sets the offset of the tick labels from the axis
					padding: 8,
					// Font color for tick labels.
					fontColor: theme === ChartEnum.Theme.DEFAULT ? this.colorBlack : this.colorWhite
					// callback: this.ticksCallback()
				}
			}
		];
	}

	xAxesStyle() {
		return [
			{
				stacked: false,
				gridLines: {
					drawBorder: false,
					display: true
				},
				ticks: {
					display: true,
					// Valore minimo dell'asse
					min: 0,
					// Scala da usare
					stepSize: 1
				}
			}
		];
	}

	tooltipsStyle() {
		return {
			// se false il tooltip compare se sull'asse x
			intersect: false,
			callbacks: this.tooltipsCallbacks(this.translateService, this.sentencecasePipe)
		};
	}

	optionsStyle(theme: ChartEnum.Theme) {
		return {
			responsive: true,
			layout: this.layoutStyle(),
			elements: this.elementsStyle(),
			tooltips: this.tooltipsStyle(),
			hover: this.hoverStyle(),
			scales: {
				xAxes: this.xAxesStyle(),
				yAxes: this.yAxesStyle(theme)
			},
			aspectRatio: 2
		};
	}
}
