import { Component, OnDestroy, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// constant
import { ConfigurationCustomer } from '../../../../../constants/configuration-customer';

import { ITdDataTableColumn } from '@covalent/core/data-table';
import { Store } from '@ngrx/store';
import { LocalListHandlerBaseModel, DestinationPouchModel } from '@saep-ict/pouch_agent_models';
import { Observable, of, Subscription } from 'rxjs';
import { filter, take, catchError, switchMap, delay, map } from 'rxjs/operators';

import { BaseState, BaseStateModel } from '@saep-ict/angular-core';
import _ from 'lodash';
import { StateFeature } from '../../../../../state';
import { DestinationListWrapperComponent } from '../../../../../widget/destination/destination-list-wrapper/destination-list-wrapper.component';
import { LoaderService, SubscribeManagerService } from '@saep-ict/angular-core';

import {
	OrganizationStateAction,
	OrganizationActionEnum
} from '../../../../../state/organization/organization.actions';
import { DialogDestinationDetailComponent } from '../../../../../widget/dialog/dialog-destination-detail/dialog-destination-detail.component';
import { UtilAddressService } from '../../../../../service/util/util-address.service';
import {
	ContextApplicationItemCodeEnum,
	UserDetailModel,
	FormControlMap,
	OrganizationStateModel
} from '@saep-ict/angular-spin8-core';

import { TranslateService } from '@ngx-translate/core';
import { ExtendedDestinationPouchModel } from '../../../../../model/destination.model';

@Component({
	selector: 'organization-detail-destination',
	templateUrl: './organization-detail-destination.component.html',
	styleUrls: ['./organization-detail-destination.component.scss'],
	providers: [SubscribeManagerService]
})
export class OrganizationDetailDestinationComponent implements AfterViewInit, OnDestroy, OnInit {
	@ViewChild(DestinationListWrapperComponent) destinationListWrapperComponent: DestinationListWrapperComponent;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organizationSub: Subscription;
	organization: OrganizationStateModel;

	configurationCustomer = ConfigurationCustomer;

	destinationList: DestinationPouchModel[];

	public listPageBaseData = <LocalListHandlerBaseModel<DestinationPouchModel>>{
		pagination: {
			pageSize: 10
		},
		sort: {
			name: 'address.address',
			order: 'ASC'
		},
		data: []
	};

	columns: ITdDataTableColumn[];

	currentContext: ContextApplicationItemCodeEnum;

	// enum
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	constructor(
		private store: Store<any>,
		private dialog: MatDialog,
		private loader: LoaderService,
		private subscribeManagerService: SubscribeManagerService,
		private utilAddressService: UtilAddressService,
		private translateService: TranslateService
	) {
		this.user$
			.pipe(
				filter(res => !!(res && res.data)),
				take(1)
			)
			.subscribe(res => {
				this.user = res ? res.data : null;
				this.currentContext = this.user.current_permission.context_application;
			});

		this.columns = ConfigurationCustomer.Destination.columnList(this.user.current_permission.context_application);
	}

	ngOnInit() {}

	ngAfterViewInit() {
		this.subscribeManagerService.populate(this.subscribeOrganization().subscribe(), 'organization-destination');
	}

	subscribeOrganization(): Observable<BaseStateModel<OrganizationStateModel>> {
		// this function defines a new secondary stream, which has catchError, so that when the subscription is triggered,
		// the error can be handled (if needed) and that secondary stream is completed without affecting the principal one
		return this.organization$.pipe(
			delay(0),
			filter(res => !!(res && res.data)),
			map(organizationState => {
				if (!organizationState) {
					return null;
				}
				switch (organizationState.type) {
					case OrganizationActionEnum.UPDATE:
					case OrganizationActionEnum.SAVE_SUCCESS:
						this.organization = organizationState.data;
						this.destinationList = this.organization.destination_list || [];
						this.destinationList = this.destinationList
							.sort((a, b) => (a.code_item > b.code_item ? 1 : -1))
							.filter(destination => destination.valid);
						const defaultDestination = this.destinationList.find(
							destination => destination.is_registered_office
						);
						if (defaultDestination) {
							defaultDestination.address = this.organization.destination_list?.length
								? this.organization.destination_list.find(
										destination => destination.is_registered_office === true
								  ).address
								: {};
						}
						this.destinationListWrapperComponent.updateListPageBaseData(this.destinationList);
						break;

					default:
						break;
				}
			}),
			catchError(() => of(null))
		);
	}

	addAddress() {
		const dialogRef = this.dialog.open(DialogDestinationDetailComponent, {
			data: {
				title: 'destination.add_new',
				oldValue: FormControlMap.CreationDefaultValue.destination.create,
				formFiledConfiguration: this.utilAddressService.setAddressProvinceOptionList(
					ConfigurationCustomer.Form.formControlMultipurpose[this.user.current_permission.context_application]
					.DESTINATION_LIST_EDIT
				),
				canEdit:
					ConfigurationCustomer.Organization.canCreate.destination[
						this.user.current_permission.context_application
					]
			},
			panelClass: ['dialog-normal', 'angelo-theme-dialog'],
			autoFocus: true
		});

		dialogRef.afterClosed().subscribe(destination => {
			this.saveDestination(destination);
		});
	}

	openDialogDestinationEdit(dataDetail: DestinationPouchModel) {
		const dialogRef = this.dialog.open(DialogDestinationDetailComponent, {
			data: {
				title: 'destination.edit',
				oldValue: _.cloneDeep(dataDetail),
				formFiledConfiguration: this.utilAddressService.setAddressProvinceOptionList(
					ConfigurationCustomer.Form.formControlMultipurpose[this.user.current_permission.context_application]
					.DESTINATION_LIST_EDIT
				),
				canEdit:
					ConfigurationCustomer.Organization.canEdit.destination[
						this.user.current_permission.context_application
					]
			},
			panelClass: ['dialog-normal', 'angelo-theme-dialog'],
			autoFocus: true
		});
		dialogRef.afterClosed().subscribe(destination => {
			this.saveDestination(destination);
		});
	}

	saveDestination(destinationToSave: ExtendedDestinationPouchModel) {
		if (destinationToSave) {
			const updatedOrganization = _.cloneDeep(this.organization);
			destinationToSave = this.formatDestination(destinationToSave);

			if (!updatedOrganization.destination_list) {
				updatedOrganization.destination_list = [];
			}

			const destinationToUpdateIndex = updatedOrganization.destination_list.findIndex(
				dest => dest.code_item === destinationToSave.code_item
			);
			// in caso la nuova/aggiornata destinazione giungesse con is_main_of_list o is_registered_office
			// la prop. viene rimossa da tutte le altre destinazioni presenti, prima dell'aggiornamento
			// il check avviene solo in questo punto, valutare se spostare in futuro presso qualche util
			if (destinationToSave.is_main_of_list) {
				for (let i = 0; i < updatedOrganization.destination_list.length; i++) {
					delete updatedOrganization.destination_list[i].is_main_of_list;
				}
			}
			if (destinationToSave.is_registered_office) {
				for (let i = 0; i < updatedOrganization.destination_list.length; i++) {
					delete updatedOrganization.destination_list[i].is_registered_office;
				}
			}
			if (destinationToUpdateIndex > -1) {
				// Update
				destinationToSave.date_creation =
					updatedOrganization.destination_list[destinationToUpdateIndex].date_creation;
				updatedOrganization.destination_list[destinationToUpdateIndex] = destinationToSave;
			} else {
				// Add
				destinationToSave.date_creation = Date.now();
				updatedOrganization.destination_list.push(destinationToSave);
			}
			destinationToSave.date_update = Date.now();
			this.store.dispatch(OrganizationStateAction.save(new BaseState(updatedOrganization)));
		}
	}

	formatDestination(destinationRow): DestinationPouchModel {
		const destination = destinationRow.address;
		return {
			code_item: destinationRow.code_item ? destinationRow.code_item : this.loader.guid(),
			is_registered_office: destinationRow.is_registered_office,
			is_main_of_list: destinationRow.is_main_of_list,
			business_name: destinationRow.business_name,
			address: {
				address: destination.address ? destination.address : null,
				locality: destination.locality ? destination.locality : null,
				province: destination.province ? destination.province : null,
				zip_code: destination.zip_code ? destination.zip_code : null,
				country: destination.country ? destination.country : null
			},
			source: 'APPLICATION',
			valid: true
		};
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy('organization-destination');
	}
}
