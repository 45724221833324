import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AngularCoreUtilService, BaseStateModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import screenfull from 'screenfull';
import { CustomerAppConfig } from '../../customer-app.config';
import { ToolbarEnum } from '../../enum/frame/toolbar/toolbar.enum';
import { ConnectionModel } from '../../model/connection.model';
import { ToolbarModel } from '../../model/frame/toolbar.model';
import { NavigationService } from '../../service/navigation/navigation.service';
import { UtilBreadcrumbService } from '../../service/util/util-breadcrumb.service';
import { StateFeature } from '../../state';
import { ConnectionActionEnum } from '../../state/connection/connection.actions';

@Component({
	selector: 'ms-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss'],
	providers: [SubscribeManagerService]
})
export class ToolbarComponent implements OnInit {

	connection$: Observable<BaseStateModel<ConnectionModel>> = this.store.select(StateFeature.getConnectionState);

	@Input() sidenav: any;
	isFullscreen: boolean = false;

	iconList: ToolbarModel.Icon[] = [
		{
			key: ToolbarEnum.Icon.Key.CONNECTION_OFFLINE,
			matIconKey: 'signal_wifi_off',
			visible: false,
			link: null,
			description: `frame.toolbar.icon_list.${ToolbarEnum.Icon.Key.CONNECTION_OFFLINE}`
		},
		{
			key: ToolbarEnum.Icon.Key.CONNECTION_ONLINE,
			matIconKey: 'wifi',
			visible: false,
			link: null,
			description: `frame.toolbar.icon_list.${ToolbarEnum.Icon.Key.CONNECTION_ONLINE}`
		}
	];

	constructor(
		public navigationService: NavigationService,
		private store: Store,
		private subscribeManagerService: SubscribeManagerService,
		private utilService: AngularCoreUtilService,
		private appConfig: CustomerAppConfig,
		public utilBreadcrumbService: UtilBreadcrumbService
	) {
		this.subscribeManagerInit();
	}

	ngOnInit() {}

	subscribeManagerInit() {
		this.subscribeManagerService.populate(
			this.subscribeConnectionData().subscribe(
				res => {},
				error => {
					console.log('something went wrong ', error);
				}
			),
			'connection'
		);
	}

	subscribeConnectionData(): Observable<void> {
		return this.connection$.pipe(
			filter((e: BaseStateModel<ConnectionModel>) => !!(e && e.data && this.appConfig.envConfig.enableOffline)),
			map((e: BaseStateModel<ConnectionModel>) => {
				switch (e.type) {
					case ConnectionActionEnum.ERROR:
						throw new Error(ConnectionActionEnum.ERROR);
					case ConnectionActionEnum.UPDATE:
						const connection_online_index =
							this.utilService.getElementIndex(this.iconList, "key", ToolbarEnum.Icon.Key.CONNECTION_ONLINE);
						const connection_offline_index =
							this.utilService.getElementIndex(this.iconList, "key", ToolbarEnum.Icon.Key.CONNECTION_OFFLINE);
						this.iconList[connection_online_index].visible = !e.data.offline;
						this.iconList[connection_offline_index].visible = e.data.offline;
						break;
				}
			})
		);
	}

	toggleFullscreen() {
		if (screenfull.isEnabled) {
			screenfull.toggle();
			this.isFullscreen = !this.isFullscreen;
		}
	}

	toggleIconSidenav() {
		this.navigationService.isIconSidenav = !this.navigationService.isIconSidenav;
	}

	isIconSidenav(): boolean {
		return this.navigationService.isIconSidenav;
	}
}
