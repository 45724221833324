<!-- pre header -->
<div class="pre-header">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="pre-header__content">
					<mat-form-field
						*ngIf="
							utilTranslateService.languages &&
							utilTranslateService.languages.length &&
							utilTranslateService.languages.length > 1
						"
						class="pre-header__lang light"
					>
						<mat-select
							color="primary"
							[disableOptionCentering]="false"
							[value]="utilTranslateService.translate.currentLang"
							(selectionChange)="utilTranslateService.setLanguage($event.value, true)"
							[panelClass]="'lang-select-panel'"
						>
							<mat-option *ngFor="let lang of utilTranslateService.languages" [value]="lang">
								{{ lang }}</mat-option
							>
						</mat-select>
					</mat-form-field>
					<span class="flex-span"></span>
					<a
						*ngIf="!user"
						class="pre-header__my-account"
						[routerLink]="['/', ROUTE_URL.login]"
						routerLinkActive="router-link-active"
					>
						{{ 'general.my_account' | translate | uppercase }}
					</a>
					<ms-toolbar-user-button *ngIf="user" [contextClass]="'b2c'" class="b2c" ></ms-toolbar-user-button>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- main navbar -->
<div class="header">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="header__content">
					<div class="nav-primary">
						<!-- hamburger menu -->
						<app-header-hamburger
							[navbarIsOpen]="navbarIsOpen"
							(toggleNavBar)="navbarIsOpen = !navbarIsOpen"
						></app-header-hamburger>

						<!-- logo -->
						<a
							class="nav-primary__logo"
							[routerLink]="['/' + ROUTE_URL.public]"
							href="javascript:void(0)"
						></a>

						<!-- navigazione -->
						<div class="nav-primary__wrapper" [class.open]="navbarIsOpen">
							<ul class="nav-primary__link">
								<li *ngFor="let link of linkList" class="nav-primary__link__item">
									<a
										href="javascript:void(0)"
										[class.active]="path === link.route"
										[title]="link.altText | translate | sentencecase"
										(click)="goTo(link.route)"
									>
										{{ link.label | translate | sentencecase }}
									</a>
								</li>
							</ul>

							<div class="nav-primary__wrapper__footer">
								<a
									*ngIf="!user"
									class="nav-primary__wrapper__footer__my-account"
									[routerLink]="['/', ROUTE_URL.login]"
									routerLinkActive="router-link-active"
								>
									{{ 'general.my_account' | translate | uppercase }}
								</a>
								<ms-toolbar-user-button *ngIf="user" [openUp]="true" class="b2c" [contextClass]="'b2c'"></ms-toolbar-user-button>
								<ng-container
									*ngIf="
										utilTranslateService.languages &&
										utilTranslateService.languages.length &&
										utilTranslateService.languages.length > 1
									"
								>
									<span class="flex-span"></span>
									<mat-form-field class="pre-header__lang">
										<mat-select
											color="primary"
											[disableOptionCentering]="false"
											[value]="utilTranslateService.translate.currentLang"
											(selectionChange)="utilTranslateService.setLanguage($event.value, true)"
											[panelClass]="'lang-select-panel'"
										>
											<mat-option
												*ngFor="let lang of utilTranslateService.languages"
												[value]="lang"
											>
												{{ lang }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</ng-container>
							</div>
						</div>
						<div class="backdrop" [class.open]="navbarIsOpen"></div>
					</div>

					<span class="flex-span"></span>

					<div class="nav-primary__search-field__wrapper" [class.open]="mobileSearchIsOpen">
						<button
							mat-icon-button
							aria-label="Chiudi ricerca"
							class="nav-primary__search-field__close"
							(click)="mobileSearchIsOpen = false"
						>
							<mat-icon>clear</mat-icon>
						</button>

						<mat-form-field class="nav-primary__search-field" appearance="outline">
							<!-- [matAutocomplete]="auto"
			ngModel
			(ngModelChange)="filter($event)" -->
							<input
								type="text"
								class="nav-primary__search-field__input"
								matInput
								placeholder="{{ 'general.store_search' | translate | sentencecase }}"
								(keyup.enter)="filter($any($event.target).value, true)"
								#search
							/>
							<!-- <mat-autocomplete
				#auto="matAutocomplete"
				(optionSelected)="goToArticle($event.option.value)"
				[displayWith]="getOptionText"
			>
				<mat-option *ngFor="let option of filteredOptions | async" [value]="option">
					{{ option.description }}
				</mat-option>
			</mat-autocomplete> -->

							<button
								mat-icon-button
								color="primary"
								(click)="filter(search.value, true)"
								class="nav-primary__search-field__submit"
								[disabled]="!search.value"
							>
								<mat-icon>search</mat-icon>
							</button>
						</mat-form-field>
					</div>

					<button
						class="nav-primary__search-icon"
						mat-icon-button
						aria-label="Apri ricerca"
						(click)="mobileSearchIsOpen = true"
					>
						<mat-icon>search</mat-icon>
					</button>

					<a
						href="javascript:void(0)"
						mat-icon-button
						aria-label="Apri ricerca"
						[routerLink]="['/' + ROUTE_URL.public + '/' + ROUTE_URL.cart]"
						class="nav-primary__cart"
					>
						<mat-icon [matBadge]="getOrderItemsNumber()" matBadgeColor="accent">shopping_cart</mat-icon>
					</a>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- <div class="header-space"></div> -->
