import { Component, Input } from '@angular/core';
import { AngularCoreUtilService } from '@saep-ict/angular-core';
import { LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

@Component({
	selector: 'context-code-management-wrapper',
	templateUrl: './context-code-management-wrapper.component.html',
	styleUrls: ['./context-code-management-wrapper.component.scss']
})
export class ContextCodeManagementWrapperComponent extends LocalListHandlerWidgetWrapper {
	@Input() showBadgeQty = true;
	@Input() showLinkToDetail: boolean = true;

	constructor(
		public utilService: AngularCoreUtilService,
		public translateService: TranslateService,
		public store: Store
	) {
		super(utilService, translateService, store);
	}
}
