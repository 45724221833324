import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseStateModel, FormControlMultipurposeModel } from '@saep-ict/angular-core';
import { AuxiliaryTabeleGeographicTree, AuxiliaryTableStateModel } from '@saep-ict/angular-spin8-core';
import { AddressPouchModel, CommercialAreaPouchModel, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { StateFeature } from '../../state';
import { AppUtilService } from './app-util.service';

@Injectable({
	providedIn: 'root'
})
export class UtilAddressService {
	auxiliaryTableGeographicTree$: Observable<
		BaseStateModel<AuxiliaryTabeleGeographicTree.StateModel>
	> = this.store.select(StateFeature.getAuxiliaryTableGeographicTreeState);
	auxiliaryTableGeographicTree: AuxiliaryTabeleGeographicTree.StateModel;

	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);
	auxiliaryTable: AuxiliaryTableStateModel;

	constructor(private utilService: AppUtilService, private store: Store<any>) {
		this.auxiliaryTable$.pipe(filter(res => !!(res && res.data))).subscribe(res => {
			this.auxiliaryTable = res ? res.data : null;
		});
		this.auxiliaryTableGeographicTree$.pipe(filter(res => !!(res && res.data))).subscribe(res => {
			this.auxiliaryTableGeographicTree = res ? res.data : null;
		});
	}

	getFormattedAddress(address: AddressPouchModel): string {
		let res: string[] = [];

		if (!(address && address.address)) {
			return '';
		}

		if (address.locality) {
			res.push(address.locality);
		}

		// TODO: TO FIX AFTER LIB UPDATE
		if (address.province) {
			res.push('(' + address.province['label'] + ')');
		}

		if (address.address) {
			res.push(address.address);
		}

		if (address.zip_code) {
			res.push(address.zip_code);
		}

		if (address.country) {
			res.push(', ' + address.country);
		}

		return res.join(' ');
	}

	/**
	 * Restituisce un array di FormControlMultipurposeModel.OptionList cercando ricorsivamente tra gli elementi
	 * di una lista di AuxiliaryTabeleGeographicTree.Item, la dove vi sia match (AuxiliaryTabeleGeographicTree.ChildrenLevelEnum)
	 * sul level indicato
	 *
	 * @param {AuxiliaryTabeleGeographicTree.Item[]} tree
	 * @param {AuxiliaryTabeleGeographicTree.ChildrenLevelEnum} level
	 * @param {FormControlMultipurposeModel.OptionList[]} [provinceList]
	 * @returns {Promise<FormControlMultipurposeModel.OptionList[]>}
	 * @memberof UtilAddressService
	 */
	mapAuxiliaryTableGeographicTreeToOptionList(
		tree: AuxiliaryTabeleGeographicTree.Item[],
		level: AuxiliaryTabeleGeographicTree.ChildrenLevelEnum,
		provinceList?: FormControlMultipurposeModel.OptionList[]
	): Promise<FormControlMultipurposeModel.OptionList[]> {
		return new Promise(resolve => {
			try {
				provinceList = provinceList ? provinceList : [];
				tree.forEach(async i => {
					if (i.level && i.level === level) {
						provinceList.push({
							value: i.code_item,
							label: i.description
						});
					} else {
						if (i.children && i.children.length) {
							resolve(
								await this.mapAuxiliaryTableGeographicTreeToOptionList(i.children, level, provinceList)
							);
						}
					}
				});
				resolve(provinceList);
			} catch (err) {
				throw new Error(err);
			}
		});
	}

	getProvinceList() {
		const provinceList = _.cloneDeep(this.auxiliaryTableGeographicTree.provinceList).sort((a, b) => {
			if (a.label && b.label) {
				return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
			}
			return a ? -1 : b ? 1 : 0;
		});
		return provinceList;
	}

	setAddressProvinceOptionList(
		destinationMap: FormControlMultipurposeModel.Item[]
	): FormControlMultipurposeModel.Item[] {
		const optionList = this.getProvinceList();
		destinationMap[this.utilService.getElementIndex(destinationMap, 'name', 'address')].form_control_list[
			this.utilService.getElementIndex(
				destinationMap[this.utilService.getElementIndex(destinationMap, 'name', 'address')].form_control_list,
				'name',
				'province'
			)
		].option_list = optionList;
		return destinationMap;
	}

	/**
	 * Restituisce una lista di stringhe rappresentante i code_item di tutti gli elementi che percorrono il branch
	 * di `c.tree: AuxiliaryTabeleGeographicTree.StateModel.tree` contenente `c.codeItem: string`. I valori sono ordinati dal
	 * più annidato verso il più esterno
	 *
	 * @param {AuxiliaryTabeleGeographicTree.CodeItemHierarchyConfigParam} c
	 * @returns {Promise<AuxiliaryTabeleGeographicTree.CodeItemHierarchyConfigParam>}
	 * @memberof UtilAddressService
	 */
	returnGeographicTreeCodeItemHierarchy(
		c: AuxiliaryTabeleGeographicTree.CodeItemHierarchyConfigParam
	): Promise<AuxiliaryTabeleGeographicTree.CodeItemHierarchyConfigParam> {
		return new Promise(async resolve => {
			try {
				c.tree = c.tree ? c.tree : this.auxiliaryTableGeographicTree.tree;
				c.codeItemHierarchyList = c.codeItemHierarchyList ? c.codeItemHierarchyList : [];
				for (let i = 0; i < c.tree.length; i++) {
					if (c.codeItemFound) {
						break;
					} else {
						c.codeItemHierarchyList.unshift(c.tree[i].code_item);
						if (c.tree[i].code_item === c.codeItem) {
							c.codeItemFound = true;
							break;
						} else {
							if (c.tree[i].children && c.tree[i].children.length) {
								const cSub: AuxiliaryTabeleGeographicTree.CodeItemHierarchyConfigParam = {
									codeItem: c.codeItem,
									tree: _.cloneDeep(c.tree[i].children),
									codeItemHierarchyList: [],
									codeItemFound: c.codeItemFound
								};
								const cSubResult: AuxiliaryTabeleGeographicTree.CodeItemHierarchyConfigParam = await this.returnGeographicTreeCodeItemHierarchy(
									cSub
								);
								c.codeItem = cSubResult.codeItem;
								c.codeItemFound = cSubResult.codeItemFound;
								c.codeItemHierarchyList = cSubResult.codeItemHierarchyList.concat(
									c.codeItemHierarchyList
								);
							}
							if (!c.codeItemFound) {
								c.codeItemHierarchyList.splice(0, 1);
							}
						}
					}
				}
				resolve(c);
			} catch (err) {
				throw new Error(err);
			}
		});
	}

	/**
	 * Restituisce un elemento di `shippingFeesList` tramite `code_item` match. Secondo la gerarchia rappresentata in
	 * `codeItemHierarchyList` (tipicamente restituito a sua vlta da `returnGeographicTreeCodeItemHierarchy`), rende possibile la
	 * selezione a partire dalla zona geografica più accurata alla meno precisa. Es:
	 * per `['guid-TORINO', 'guid-PIEMONTE', 'guid-ITALIA'] ` proverà a restituire un elemento per Torino, in mancanza per
	 * Piemonte, in mancanza per Italia
	 *
	 * @param {string[]} codeItemHierarchyList
	 * @param {CommercialAreaPouchModel[]} [shippingFeesList]
	 * @returns {CommercialAreaPouchModel}
	 * @memberof UtilAddressService
	 */
	returnCommercialAreaByGeographicTreeCodeItemHierarchy(
		codeItemHierarchyList: string[],
		shippingFeesList?: CommercialAreaPouchModel[],
		organization?: OrganizationPouchModel
	): Promise<CommercialAreaPouchModel> {
		return new Promise(resolve => {
			try {
				// TODO: cambiare i riferimenti a table_area_commercial con table_shipping_fees
				shippingFeesList = shippingFeesList
					? shippingFeesList
					: this.auxiliaryTable[`shippingConditionList_${organization.organization_type.toUpperCase()}`];
				let shippingFees: CommercialAreaPouchModel = null;
				mainLoop: for (const codeItem of codeItemHierarchyList) {
					for (let i = 0; i < shippingFeesList.length; i++) {
						if (codeItem === shippingFeesList[i].code_item) {
							shippingFees = _.cloneDeep(shippingFeesList[i]);
							resolve(shippingFees);
							break mainLoop;
						}
					}
				}
				resolve(shippingFees);
			} catch (err) {
				throw new Error(err);
			}
		});
	}

	async selectCommercialArea(order, organization): Promise<CommercialAreaPouchModel> {
		if (order.header.goods_destination_object) {
			const geographicHierarchy: AuxiliaryTabeleGeographicTree.CodeItemHierarchyConfigParam = await this.returnGeographicTreeCodeItemHierarchy(
				{ codeItem: order.header.goods_destination_object.province.value }
			);
			let commercialArea: CommercialAreaPouchModel = await this.returnCommercialAreaByGeographicTreeCodeItemHierarchy(
				geographicHierarchy.codeItemHierarchyList,
				null,
				organization
			);
			if (commercialArea && commercialArea.children) {
				const childArea = commercialArea.children.find(item =>
					item.cap.includes(order.header.goods_destination_object.zip_code)
				);
				if (childArea) {
					childArea.delivery_days =
						childArea.delivery_days || childArea.delivery_days === 0
							? childArea.delivery_days
							: commercialArea.delivery_days;
					childArea.threshold =
						childArea.threshold && childArea.threshold.length
							? childArea.threshold
							: commercialArea.threshold;
					commercialArea = childArea;
				}
			}
			return commercialArea;
		} else {
			return null;
		}
	}
}
