<mat-toolbar *ngIf="localListHandlerTextFilterShow">
	<mat-toolbar-row>
		<span class="flex-span"></span>
		<td-search-box
			*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[placeholder]="'general.search' | translate | sentencecase"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="true"
			(searchDebounce)="localListHandlerApplyFilter()"
		>
		</td-search-box>
	</mat-toolbar-row>
</mat-toolbar>

<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<td-data-table
		#dataTable
		[data]="localListHandlerData.dataSubset"
		[columns]="_localListHandlerWidgetWrapperColumnList"
		[sortable]="true"
		(sortChange)="localListHandlerApplyFilter({ sort: $event })"
		[sortBy]="localListHandlerData.sort && localListHandlerData.sort.name ? localListHandlerData.sort.name : null"
		[sortOrder]="
			localListHandlerData.sort && localListHandlerData.sort.order ? localListHandlerData.sort.order : null
		"
	>
		<ng-template tdDataTableTemplate="code" let-value="value" let-row="row">
			<p>
				<a
					*ngIf="showLinkToDetail; else noLinkToDetail"
					(click)="onItemSelect.emit(row)"
					href="javascript:void(0)"
				>
					<ng-container *ngTemplateOutlet="noLinkToDetail"></ng-container>
				</a>
			</p>
			<ng-template #noLinkToDetail>
				{{
					row.code_erp ?
					row.code_erp :
					(
						(
							row.code ||
							row.code_item
						)
						| guidFormatter
					)
				}}
				<ng-container *ngIf="row.code_erp">
					<br />
					<small class="cell-subtitle">
						{{ row.code | guidFormatter }}
					</small>
				</ng-container>
			</ng-template>
		</ng-template>
		<ng-template tdDataTableTemplate="description" let-row="row">
			{{ row.description || row.business_name }}
		</ng-template>
	</td-data-table>

	<mat-toolbar-row>
		<span class="flex-span"></span>
		<mat-paginator
			*ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow"
			#matPaginator
			(page)="localListHandlerApplyFilter({ pagination: $event })"
			[length]="localListHandlerData.pagination.totalFilteredItemCount"
			[pageSize]="localListHandlerData.pagination.pageSize"
			[pageSizeOptions]="[5, 10, 25, 100]"
		>
		</mat-paginator>
	</mat-toolbar-row>
</ng-container>
<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
