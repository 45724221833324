<page-with-sidebar>
	<ng-container pageMain>
		<mat-card>
			<opportunity-list-wrapper
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="columns"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				(openDialogAddAction)="openAddAction($event)"
				(openDialogActionList)="openDialogActionList($event)"
				(onItemSelect)="goToOpportunityDetail($event)"
				(selectCompany)="selectCompany($event.level, $event.code)"
				(openDialogContactDetail)="openContactDetail($event)"
			>
			</opportunity-list-wrapper>
		</mat-card>
	</ng-container>

	<ng-container pageSide>
		<form class="angelo-theme" [formGroup]="formFilters" (ngSubmit)="onFormFiltersSubmit()">
			<div class="mb-3">
				<mat-checkbox formControlName="manageable">Solo da gestire</mat-checkbox>
			</div>

			<div class="mb-3">
				<mat-checkbox formControlName="status_open">Solo aperte</mat-checkbox>
			</div>
			<!-- <div>
				<button class="mr-8" mat-raised-button color="primary" type="submit">
					{{ 'general.apply_filters' | translate | sentencecase }}
				</button>

				<button mat-button color="default" type="button" (click)="resetFilters()">
					{{ 'general.reset' | translate | sentencecase }}
				</button>
			</div> -->

			<form-button-bar
				[align]="'right'"
				[primaryButtonLabel]="'general.apply_filters' | translate | sentencecase"
				(primaryButtonClickEmitter)="onFormFiltersSubmit()"
				[secondaryButtonLabel]="'general.clean' | translate | sentencecase"
				[secondaryButtonIsDisabled]="!hasChange"
				(secondaryButtonClickEmitter)="resetFilters()"
			></form-button-bar>
		</form>
	</ng-container>
</page-with-sidebar>

<button
	class="mat-fab-button"
	(click)="new()"
	mat-fab
	[matTooltip]="'request.add_new' | translate | sentencecase"
	matTooltipPosition="left"
>
	<mat-icon>add</mat-icon>
	<span class="label">{{ 'request.add_new' | translate | sentencecase }}</span>
</button>
