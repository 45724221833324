import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// model
import {
	BaseStateModel,
	DateMomentService,
	FormControlMultipurposeEnum,
	FormDynamicConfigurationModel,
	FormValidatorCustom,
	SubscribeManagerService
} from '@saep-ict/angular-core';
import { LocalListHandlerBaseModel, OrderPouchModel, OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import { TableOrderModel } from '../../../model/table/table-order.model';
import { DateFilterModel } from '../../../service/pouch-db/filter/order-filter.model';
import { StateRelatedLink } from '../../../constants/configuration-customer/order/status-aggregation-map.constant';

// constant
import { ConfigurationCustomer } from '../../../constants/configuration-customer';
import { ConfigurationOrder } from '../../../constants/order/order.constants';

// store
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../state';
import { OrderListActionEnum, OrderListStateAction } from '../../../state/order-list/order-list.actions';

// misc
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { delay, filter, map, mergeMap, take } from 'rxjs/operators';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { DialogOrderDetailComponent } from '../../../widget/dialog/dialog-order-detail/dialog-order-detail.component';
import { OrderListWrapperComponent } from '../../../widget/order/order-list-wrapper/order-list-wrapper.component';
import {
	ContextApplicationItemCodeEnum,
	ExtraFieldOrderHeaderPouchModel,
	OrderStateModel,
	PATH_URL,
	UserDetailModel,
	OrganizationStateModel,
	AngularSpin8CoreUtilCompanyService,
	AuxiliaryTableStateModel
} from '@saep-ict/angular-spin8-core';

import { TranslateService } from '@ngx-translate/core';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { FramePageComponent } from '../../../frame/admin/admin.component';
import { SideBarPositionValues } from '../../../enum/sidebar-position-values.enum';
import { ConfigurationVisibleOrderFilter } from '../../../constants/configuration-customer/order/order-filter-form-map.constant';
import { ExportExcelService } from '../../../service/export/export-excel.service';

@Component({
	selector: 'order',
	templateUrl: './order.component.html',
	styleUrls: ['./order.component.scss'],
	providers: [SubscribeManagerService]
})
export class OrderComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild(OrderListWrapperComponent)
	orderListWrapperComponent: OrderListWrapperComponent;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);
	auxiliaryTable: AuxiliaryTableStateModel;

	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	orderList$: Observable<BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>[]>> = this.store.select(
		StateFeature.getOrderListState
	);
	orderList: TableOrderModel[] = [];

	orderListFilterForm: FormGroup;
	filterOrderVisible: ConfigurationVisibleOrderFilter;
	checkListFilter: boolean;

	listPageBaseData = <LocalListHandlerBaseModel<TableOrderModel>>{
		filters: {
			localSearchText: {
				value: null,
				key_list: [
					'_id',
					'header.organization.business_name',
					'header.organization.code_item',
					// TODO: per permettere la ricerca in code_erp, questi va salvato nell'ordine
					ConfigurationCustomer.AppStructure.Erp.has_erp ? 'product_list.code_erp' : 'product_list.code_item'
				]
			}
		},
		pagination: {
			pageSize: 10
		},
		sort: {
			name: 'header.date',
			order: 'DESC'
		},
		data: []
	};

	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
	currentContext: ContextApplicationItemCodeEnum;

	// Contesto di pagina
	// 'orders'
	orderState: string;

	configurationCustomer = ConfigurationCustomer;

	sideBarPositionValues = SideBarPositionValues;

	formDynamicConfiguration: FormDynamicConfigurationModel = {
		creationFieldMap: [],
		emitAlsoNonValidForm: false,
		buttonReset: {
			show: true
		}
	};

	constructor(
		private store: Store<any>,
		private router: Router,
		private route: ActivatedRoute,
		private fb: FormBuilder,
		public dateMomentService: DateMomentService,
		public utilOrderService: UtilOrderService,
		private subscribeManagerService: SubscribeManagerService,
		public utilCompanyService: AngularSpin8CoreUtilCompanyService,
		private dialog: MatDialog,
		private utilBreadcrumbService: UtilBreadcrumbService,
		private exportService: ExportExcelService
	) {
		this.store.dispatch(OrderListStateAction.loadAll());

		this.createFormFilters();

		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
			this.currentContext = res ? res.data.current_permission.context_application : null;
			this.listPageBaseData.columnList = ConfigurationCustomer.Order.columnList(this.currentContext);
			this.formDynamicConfiguration.creationFieldMap =
				ConfigurationCustomer.Order.listFilterCreationFieldMap[
					this.user.current_permission.context_application
				];
		});
		this.auxiliaryTable$.pipe(take(1)).subscribe(res => {
			this.auxiliaryTable = res ? res.data : null;
		});
		this.organization$.pipe(take(1)).subscribe(res => {
			// determina se la lista ordini è contestuale ad un'unica organization
			this.organization = res ? res.data : null;
		});

		this.setRouteMetaInformation();
		this.filterOrderVisible =
			ConfigurationCustomer.Order.filterOrderVisible[ContextApplicationItemCodeEnum[this.currentContext]];
		this.checkListFilter = Object.values(this.filterOrderVisible).includes(true);
	}

	ngOnInit() {}

	ngAfterViewInit() {
		this.subscribeManagerService.populate(this.subscribeOrderList().subscribe(), 'subscribeOrderList');
	}

	ngOnDestroy() {
		this.store.dispatch(OrderListStateAction.reset());
		this.subscribeManagerService.destroy();
		this.unsetRouteMetaInformation();
	}

	subscribeOrderList() {
		return this.route.paramMap.pipe(
			delay(0), // ERROR Error: NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after...
			mergeMap(params => {
				this.orderState = params.get('orderStatus') ? params.get('orderStatus').toUpperCase() : null;
				this.resetOrderFormFilter();
				this.setRouteMetaInformation();
				return this.orderList$;
			}),
			filter(
				(orderList: BaseStateModel<OrderStateModel[]>) =>
					orderList && orderList.type !== OrderListActionEnum.LOAD_ALL
			),
			filter(
				(orderList: BaseStateModel<OrderStateModel[]>) =>
					!!(this.user.current_permission.context_code.code && orderList)
			),
			map((orderList: BaseStateModel<OrderStateModel[]>) => {
				switch (orderList.type) {
					case OrderListActionEnum.UPDATE:
						this.permanentFiltersOrder(orderList.data);
						break;

					case OrderListActionEnum.ERROR:
						this.orderListWrapperComponent.updateListPageBaseData([]);
						throw new Error(OrderListActionEnum.ERROR);
						break;

					default:
						break;
				}
			})
		);
	}

	// form - sidebar

	createFormFilters() {
		this.orderListFilterForm = this.fb.group({
			// stato
			orderStatus: [''],
			// order.header.date
			dateCreation: this.fb.group(
				{
					start: [''],
					end: ['']
				},
				{
					validators: [FormValidatorCustom.allEmptyOrAllFilledByObject]
				}
			),
			// order.header.first_evasion_date
			dateDelivery: this.fb.group(
				{
					start: [''],
					end: ['']
				},
				{
					validators: [FormValidatorCustom.allEmptyOrAllFilledByObject]
				}
			),
			// order.header.submission_date
			dateSubmission: this.fb.group(
				{
					start: [''],
					end: ['']
				},
				{
					validators: [FormValidatorCustom.allEmptyOrAllFilledByObject]
				}
			),
			causalList: ['']
		});
	}

	submitOrderListFilters() {
		let filteredList = _.cloneDeep(this.orderList);
		if (this.orderListFilterForm.valid) {
			filteredList = this.getFilteredList(filteredList);
			// update list
			this.orderListWrapperComponent.updateListPageBaseData(filteredList);
		}
	}

	private getFilteredList(filteredList: TableOrderModel[]) {
		if (filteredList && filteredList.length && this.orderListFilterForm) {
			// order.header.date
			if (this.orderListFilterForm.value.dateCreation) {
				if (this.orderListFilterForm.value.dateCreation.start) {
					const filterDate: DateFilterModel = {
						begin: this.orderListFilterForm.value.dateCreation.start.valueOf()
					};
					filteredList = filteredList.filter(order => order.header.date >= +filterDate.begin);
				}
				if (this.orderListFilterForm.value.dateCreation.end) {
					const filterDate: DateFilterModel = {
						end: this.orderListFilterForm.value.dateCreation.end.add(1, 'days').valueOf()
					};
					filteredList = filteredList.filter(order => order.header.date <= +filterDate.end);
				}
			}
			// order.header.first_evasion_date
			if (this.orderListFilterForm.value.dateDelivery) {
				if (this.orderListFilterForm.value.dateDelivery.start) {
					const filterDate: DateFilterModel = {
						begin: this.orderListFilterForm.value.dateDelivery.start.valueOf()
					};
					filteredList = filteredList.filter(order => order.header.first_evasion_date >= +filterDate.begin);
				}
				if (this.orderListFilterForm.value.dateDelivery.end) {
					const filterDate: DateFilterModel = {
						end: this.orderListFilterForm.value.dateDelivery.end.add(1, 'days').valueOf()
					};
					filteredList = filteredList.filter(order => order.header.first_evasion_date <= +filterDate.end);
				}
			}
			// order.header.submissione_date
			if (this.orderListFilterForm.value.dateSubmission) {
				if (this.orderListFilterForm.value.dateSubmission.start) {
					const filterDate: DateFilterModel = {
						begin: this.orderListFilterForm.value.dateSubmission.start.valueOf()
					};
					filteredList = filteredList.filter(order => order.header.submission_date >= +filterDate.begin);
				}
				if (this.orderListFilterForm.value.dateSubmission.end) {
					const filterDate: DateFilterModel = {
						end: this.orderListFilterForm.value.dateSubmission.end.add(1, 'days').valueOf()
					};
					filteredList = filteredList.filter(order => order.header.submission_date <= +filterDate.end);
				}
			}
			// stato
			if (this.orderListFilterForm.value.orderStatus && this.orderListFilterForm.value.orderStatus.length > 0) {
				const status: OrderStatusEnum[] = [];
				this.orderListFilterForm.value.orderStatus.forEach(statusList => {
					statusList.forEach(s => {
						status.push(s);
					});
				});
				filteredList = this.utilOrderService.filterByOrderStatus(filteredList, status);
			}
			// causal
			if (this.orderListFilterForm.value.causalList && this.orderListFilterForm.value.causalList.length > 0) {
				const filteredCausalList = [];
				for (let order of filteredList) {
					for (let causal of this.orderListFilterForm.value.causalList) {
						order.header.order_causal_object.code_item === causal.code_item
							? filteredCausalList.unshift(order)
							: null;
					}
				}
				filteredList = filteredCausalList;
			}
		}
		return filteredList;
	}

	resetOrderFormFilter() {
		this.orderListFilterForm.patchValue({
			// stato
			orderStatus: '',
			// order.header.date
			dateCreation: {
				start: '',
				end: ''
			},
			// order.header.first_evasion_date
			dateDelivery: {
				start: '',
				end: ''
			},
			// order.header.submission_date
			dateSubmission: {
				start: '',
				end: ''
			},
			causalList: ''
		});
		this.submitOrderListFilters();
	}

	// misc
	// Filtrare in base al contesto
	permanentFiltersOrder(orderList: OrderStateModel[]) {
		// TODO - ci sono degli ordini senza header, come mai?
		orderList = orderList.filter(order => order.header);
		if (this.orderState) {
			const statusRelatedMap: StateRelatedLink[] =
				ConfigurationCustomer.Order.statusAggregationMap[
					ContextApplicationItemCodeEnum[this.user.current_permission.context_application]
				];
			if (statusRelatedMap) {
				const statusRelatedList = statusRelatedMap.find(i => i.state === this.orderState);
				if (statusRelatedList) {
					orderList = this.utilOrderService.filterByOrderStatus(orderList, statusRelatedList.related_list);
				}
			}
		} else {
			orderList = orderList.filter(order => order.header.status !== OrderStatusEnum.DELETED);
		}

		orderList = ConfigurationOrder.listFilter(
			orderList,
			this.user.current_permission.context_application,
			this.organization
		);
		this.orderList = this.utilOrderService.getTableOrderList(_.cloneDeep(orderList));
		this.orderListWrapperComponent.updateListPageBaseData(this.orderList);
	}

	orderDraftCreate() {
		this.utilOrderService.orderDraftCreate(this.organization);
		switch (this.user.current_permission.context_application) {
			case ContextApplicationItemCodeEnum.B2B:
				this.router.navigate([
					`${PATH_URL.PRIVATE}/orders/${OrderStatusEnum.DRAFT}/${this.user.current_permission.context_code.code}/new/header-edit`
				]);
				break;
			default:
				this.router.navigate([
					`${PATH_URL.PRIVATE}/orders/${OrderStatusEnum.DRAFT}/${this.organization.code_item}/new/header-edit`
				]);
				break;
		}
	}

	goToOrderDetail(selectedOrder: OrderPouchModel<ExtraFieldOrderHeaderPouchModel>) {
		switch (this.user.current_permission.context_application) {
			case ContextApplicationItemCodeEnum.B2C:
				this.openDialogOrderDetailB2c(selectedOrder);
				break;
			default:
				this.router.navigate([
					PATH_URL.PRIVATE,
					'orders',
					selectedOrder.header.status,
					selectedOrder.header.organization.code_item,
					selectedOrder._id,
					'checkout'
				]);
				break;
		}
	}

	openDialogOrderDetailB2c(selectedOrder: OrderPouchModel<ExtraFieldOrderHeaderPouchModel>) {
		// ??? product_list manca nell'order passato come parametro
		const order = this.orderList.find(order => {
			return order._id === selectedOrder._id;
		});
		const dialogRef = this.dialog.open(DialogOrderDetailComponent, {
			data: {
				title: 'Dettaglio ordine',
				order: order
			},
			panelClass: 'dialog-medium',
			disableClose: true
		});
	}

	setRouteMetaInformation() {
		if (!this.route.parent.component || this.route.parent.component === FramePageComponent) {
			if (this.orderState) {
				// Componente radice
				this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('orders');
				this.utilBreadcrumbService.subtitle.value = `theme.order.status.${this.orderState.toLocaleLowerCase()}`;
					this.utilBreadcrumbService.updateActiveNavigationItemSource.next([
						'orders',
						`orders_${this.orderState.toLocaleLowerCase()}`
					]);
			}
		}
	}

	unsetRouteMetaInformation() {
		if (!this.route.parent.component || this.route.parent.component === FramePageComponent) {
			// Componente radice
			this.utilBreadcrumbService.unsetRouteMetaInformation();
		}
	}

	onFormValueChange(e) {
		console.log(e);
	}

	exportExcel() {
		let filteredList = _.cloneDeep(this.orderList);
		if (this.orderListFilterForm.valid) {
			filteredList = this.getFilteredList(filteredList);
			this.exportService.order_download(filteredList, this.user);
		}
	}
}
