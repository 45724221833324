<div
    class="container"
    [ngClass]="getClass() + (!isSidebarOpen?' sidebarclosed ':'')"
>
    <div class="main-content">
        <ng-content select="[pageMain]"></ng-content>
    </div>
    <aside *ngIf="sideBarPosition !== sideBarPositionValues.NONE" [ngClass]="{'sticky': isSticky}">
        <mat-icon
            class="filled toggler"
            aria-hidden="false"
            aria-label="toggle sidebar"
            (click)="toggleSidebar()"
        >chevron_right</mat-icon>
        <div class="side-content">
            <ng-content select="[pageSide]"></ng-content>
        </div>
    </aside>
</div>
