import { ITdDataTableColumn } from "@covalent/core/data-table";
import { ITdDataTableColumnCustom } from "@saep-ict/angular-core";
import _ from "lodash";
import { ConfigurationOrganizationContextApplicationColumnMap } from "../../configuration-customer/organization/organization-column-map.constant";

export const valueToExclude = ['add_new_order', 'level', 'status'];

// Prevedere colonna custom per Sicseg con codice fiscale
let newColumnList = [...ConfigurationOrganizationContextApplicationColumnMap.base];
newColumnList.splice(5, 1, 
    {
        name: 'tax_data.tax_code',
        labelPath: 'organization.field.tax_code',
        label: ''
    }
    );
export const projectOverride: ITdDataTableColumnCustom[] = 
_.cloneDeep(newColumnList)
.filter(col => !valueToExclude.includes(col.name))
