import { MatSnackBar } from "@angular/material/snack-bar";
import { FileTypeEnum } from "@saep-ict/angular-core";
import { PermissionKeyEnum } from "@saep-ict/angular-spin8-core";
import { DataConfigurationItemModel } from "../../../model/data-configuration.model";
import { ConfigurationService } from '../../../service/rest/backoffice/configuration.service';
import { ConfigurationCustomer } from "../index";
import {Configuration } from '../../../enum/configuration/type-upload-file'

export const genericSuccessMessage = (snackBar: MatSnackBar) => {
    snackBar.open('Operation successfull', 'OK', {
        duration: 3000
    });
}

export const genericErrorMessage = (snackBar: MatSnackBar) => {
    snackBar.open('An error has occurred, try again later', 'OK', {
        duration: 10000
    });
}
export const uploadCategoryTree = (index: number, data: any, configurationService: ConfigurationService, snackBar: MatSnackBar) => {
    configurationService.uploadCategoryTree(
        data,
        { type: dataConfigurationList()[index].type },
        res => genericSuccessMessage(snackBar),
        err => genericErrorMessage(snackBar)
    );
}

export const dataConfigurationList = (
    configurationService?: ConfigurationService,
    snackBar?: MatSnackBar
): DataConfigurationItemModel[] => {
    return [
        {
            title: 'configuration.statistic_sales_reports',
            type: Configuration.type.SALES_REPORTS,
            upload: {
                multiple: false,
                acceptedType: FileTypeEnum.EXCEL,
                data: []
            },
            button: {
                display: false,
                label: 'configuration.action.save_file',
                action: (index: number, data: any) => uploadCategoryTree(index, data, configurationService, snackBar)
            },
            permission: [PermissionKeyEnum.BACKOFFICE_REPORTS],
            display:
                ConfigurationCustomer.AppStructure.Erp.backoffice_configuration
                    .has_shipping_charges_company_organization
        },
        {
            title: 'configuration.survey_center_budget',
            type: Configuration.type.SURVEY_CENTER,
            upload: {
                multiple: false,
                acceptedType: FileTypeEnum.EXCEL,
                data: []
            },
            button: {
                display: false,
                label: 'configuration.action.save_file',
                action: (index: number, data: any) => uploadCategoryTree(index, data, configurationService, snackBar)
            },
            permission: [PermissionKeyEnum.BACKOFFICE_SURVEY_CENTER_BUDGET],
            display:
                ConfigurationCustomer.AppStructure.Erp.backoffice_configuration
                    .has_shipping_charges_private_organization
        },
        {
            title: 'configuration.asset_request_center_print_production_flow',
            type: Configuration.type.PRINT_PRODUCTION_FLOW,
            upload: {
                multiple: false,
                acceptedType: FileTypeEnum.EXCEL,
                data: []
            },
            button: {
                display: false,
                label: 'configuration.action.save_file',
                action: (index: number, data: any) => uploadCategoryTree(index, data, configurationService, snackBar)
            },
            permission: [PermissionKeyEnum.BACKOFFICE_PRINT_PRODUCTION_FLOW_CENTER],
            display: true
        },
        {
            title: 'configuration.order_form_category',
            type: 'category',
            upload: {
                multiple: false,
                acceptedType: FileTypeEnum.EXCEL,
                data: []
            },
            button: {
                display: false,
                label: 'configuration.action.save_file',
                action: (index: number, data: any) => uploadCategoryTree(index, data, configurationService, snackBar)
            },
            permission: [PermissionKeyEnum.BACKOFFICE_ORDER_LIST],
            display: true
        },
        {
            title: 'configuration.advertsing_material',
            type: Configuration.type.ADVERTISING_MATERIAL,
            upload: {
                multiple: false,
                acceptedType: FileTypeEnum.EXCEL,
                data: []
            },
            button: {
                display: false,
                label: 'configuration.action.save_file',
                action: (index: number, data: any) => uploadCategoryTree(index, data, configurationService, snackBar)
            },
            permission: [PermissionKeyEnum.BACKOFFICE_ASSET_REQUEST_CENTER],
            display: true
        },
    ]
} 
