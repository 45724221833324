<form class="angelo-theme" [formGroup]="form">
	<!-- email -->
	<mat-form-field>
		<mat-label>Email</mat-label>
		<input matInput formControlName="email" />
		<mat-error *ngIf="form.get('email').hasError('required')"> Campo obbligatorio </mat-error>
	</mat-form-field>

	<!-- password -->
	<mat-form-field>
		<mat-label>Password</mat-label>
		<input matInput formControlName="password" [type]="hide ? 'password' : 'text'" />
		<mat-icon class="mat-icon-hide highlight" matSuffix (click)="hide = !hide">
			{{ hide ? 'visibility_off' : 'visibility' }}
		</mat-icon>
		<mat-error *ngIf="form.get('password').hasError('required')"> Campo obbligatorio </mat-error>
	</mat-form-field>

	<!-- submit -->
	<!-- Da controllare -->
	<mat-toolbar>
		<button mat-raised-button color="primary" class="mt-3" (click)="onFormSubmit()">Login</button>
	</mat-toolbar>
</form>
