import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ITdDataTableColumn, TdDataTableComponent } from '@covalent/core/data-table';
import { ViewTypeEnum } from '../../enum/view-type.enum';
import { AddressListColumnService } from '../../service/td-data-table/implementation/reserved-area/address-list.service';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { BaseStateModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { AddressPouchModel, BodyTablePouchModel, DestinationPouchModel } from '@saep-ict/pouch_agent_models';
import { StateFeature } from '../../state';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap } from 'rxjs/operators';
import { OrganizationActionEnum } from '../../state/organization/organization.actions';
import { DialogDestinationDetailComponent } from '../dialog/dialog-destination-detail/dialog-destination-detail.component';
import { UtilAddressService } from '../../service/util/util-address.service';
import { OrganizationStateModel, ContextApplicationItemCodeEnum } from '@saep-ict/angular-spin8-core';
import { ConfigurationCustomer } from '../../constants/configuration-customer';

export enum ListMode {
	show,
	pickable
}

@Component({
	selector: 'address-list',
	templateUrl: './address-list.component.html',
	styleUrls: ['./address-list.component.scss'],
	providers: [SubscribeManagerService]
})
export class AddressListComponent implements OnInit {
	@Input() viewType = ViewTypeEnum.module;
	@Input() mode = ListMode.show;
	@Output() addressPicked = new EventEmitter<AddressPouchModel>();
	@ViewChild('dataTable', { static: false }) dataTable: TdDataTableComponent;

	viewTypeEnum = ViewTypeEnum;
	ListMode = ListMode;
	columnsToShow: ITdDataTableColumn[];

	loginContextCode$: Observable<BaseStateModel<BodyTablePouchModel>> = this.store.select(
		StateFeature.getLoginContextCodeState
	);
	destinationList: DestinationPouchModel[];

	constructor(
		private dialog: MatDialog,
		public addressListColumnService: AddressListColumnService,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private utilAddressService: UtilAddressService
	) {
		this.subscribeManagerService.populate(
			this.initMandatoryData().subscribe(
				res => {},
				error => {
					console.log('something went wrong ', error);
				}
			),
			'order-data'
		);
	}

	ngOnInit() {
		this.columnsToShow = this.addressListColumnService.columns;
		if (this.mode == ListMode.pickable) {
			this.columnsToShow = this.addressListColumnService.columns.filter(c => c.name != 'action');
		}
	}

	initMandatoryData() {
		return this.loginContextCode$.pipe(
			filter(
				(loginContextCode: BaseStateModel<BodyTablePouchModel>) =>
					loginContextCode && loginContextCode.type !== OrganizationActionEnum.LOAD
			),
			map(loginContextCode => {
				if (loginContextCode.type === OrganizationActionEnum.ERROR) {
					throw new Error(OrganizationActionEnum.ERROR);
				}
				// TODO: rivedere quando modello sarà aggiornato
				this.destinationList = (loginContextCode.data as OrganizationStateModel).destination_list;
			})
		);
	}

	editAddress(addressData: DestinationPouchModel) {
		const dialogRef = this.dialog.open(DialogDestinationDetailComponent, {
			data: {
				title: 'destination.edit',
				oldValue: _.cloneDeep(addressData),
				formFiledConfiguration: this.utilAddressService.setAddressProvinceOptionList(
					// TODO: dinamizzazione del context in sopstituzione di `ContextApplicationItemCodeEnum.BACKOFFICE`
					ConfigurationCustomer.Form.formControlMultipurpose[ContextApplicationItemCodeEnum.BACKOFFICE]
					.DESTINATION_BASE
				),
				canEdit: true
			},
			panelClass: ['dialog-normal', 'angelo-theme-dialog'],
			autoFocus: true
		});

		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				console.log(res);
				if (this.dataTable) {
					this.dataTable.refresh();
				}
			}
		});
	}

	rowClick(event: any) {
		this.addressPicked.emit(event.row);
	}
}
