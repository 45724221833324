import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseState, BaseStateModel, RestBasePk, SentencecasePipe } from '@saep-ict/angular-core';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { PouchDeleteResponse, PouchErrorResponse } from '../../service/pouch-db/model/pouch-base-response.model';
import { PouchDbSpin8AgentAdapter } from '../../service/pouch-db/spin8/pouchdb-spin8-agent.adapter';
import { KanbanActionEnum, KanbanStateAction } from './kanban.actions';
import { UtilPouchService } from '../../service/util/util-pouch.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { KanbanModel, PouchDbConstant } from '@saep-ict/angular-spin8-core';
import { ConfigurationCustomer } from '../../constants/configuration-customer';

@Injectable()
export class KanbanEffects {
	// load$ = createEffect(() =>
	// 	this.actions$.pipe(
	// 		ofType(KanbanActionEnum.LOAD),
	// 		mergeMap((action: BaseStateModel<RestBasePk>) => from(this.getKanbanDetail(action.data))),
	// 		map((kanban: BaseStateModel<KanbanModel.Base[]>) => KanbanStateAction.update(kanban)),
	// 		catchError((error, caught) => {
	// 			this.store.dispatch(KanbanStateAction.error(null));
	// 			return caught;
	// 		})
	// 	)
	// );

	loadAll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(KanbanActionEnum.LOAD_ALL),
			mergeMap((action: BaseStateModel<KanbanModel.Base[]>) => from(this.getAllKanban(action))),
			map((kanban: BaseStateModel<KanbanModel.Base[]>) => KanbanStateAction.update(kanban)),
			catchError((error, caught) => {
				this.store.dispatch(KanbanStateAction.error(null));
				return caught;
			})
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(KanbanActionEnum.SAVE),
			mergeMap((action: BaseStateModel<KanbanModel.Base[]>) => from(this.putKanban(action.data))),
			map((opportunities: BaseStateModel<KanbanModel.Base[]>) => KanbanStateAction.update(opportunities)),
			catchError((error, caught) => {
				this.store.dispatch(KanbanStateAction.error(error));
				return caught;
			})
		)
	);

	remove$ = createEffect(() =>
		this.actions$.pipe(
			ofType(KanbanActionEnum.REMOVE),
			mergeMap((action: BaseStateModel<KanbanModel.Base>) => from(this.deleteKanban(action.data))),
			map((user: BaseStateModel<PouchDeleteResponse>) => KanbanStateAction.removed()),
			catchError((error, caught) => {
				this.store.dispatch(KanbanStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchDbSpin8AgentAdapter: PouchDbSpin8AgentAdapter,
		private utilPouchService: UtilPouchService,
		private sentencecasePipe: SentencecasePipe,
		private snackBar: MatSnackBar,
		private translate: TranslateService
	) {}

	async putKanban(data: KanbanModel.Base[]): Promise<BaseStateModel<KanbanModel.Base[]>> {
		return this.pouchDbSpin8AgentAdapter.kanbanPouch
			.putKanban(data[0], data[0]._id ? false : true)
			.then(async (kanban: KanbanModel.Base) => {
				this.showDialog('kanban.save');
				return new BaseState([kanban]);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}

	// async getKanbanDetail(data: RestBasePk): Promise<BaseStateModel<KanbanModel.Base[]>> {
	// 	return this.pouchDbSpin8AgentAdapter.kanbanPouch
	// 		.getKanbanDetail(data)
	// 		.then(async (kanban: KanbanModel.Base) => {
	// 			return new BaseState([kanban]);
	// 		})
	// 		.catch((err: PouchErrorResponse) => {
	// 			throw new Error(err.error + err.reason);
	// 		});
	// }

	async deleteKanban(data: KanbanModel.Base): Promise<BaseStateModel<PouchDeleteResponse>> {
		return this.pouchDbSpin8AgentAdapter.kanbanPouch
			.deleteKanban(data)
			.then(async kanban => {
				return new BaseState(kanban);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}

	async getAllKanban(action: BaseStateModel<KanbanModel.Base[]>): Promise<BaseStateModel<KanbanModel.Base[]>> {
		const documentName = 'kanban';
		const allDocsParam: any = {
			include_docs: true,
			startkey:
				documentName +
				ConfigurationCustomer.AppStructure.noSqlDocSeparator,
			endkey:
				documentName +
				ConfigurationCustomer.AppStructure.noSqlDocSeparator +
				PouchDbConstant.allDocsLastCharacter
		};

		await this.utilPouchService
			.allDocs<KanbanModel.Base>(allDocsParam)
			.then(res => {
				action.data = res.data;
			})
			.catch(err => console.log(err));

		return action;
	}

	showDialog(msg: string, action: string = null, duration: number = 3000) {
		msg = this.sentencecasePipe.transform(this.translate.instant(msg));
		this.snackBar.open(msg, action, { duration: duration });
	}
}
