import { Route } from '@angular/router';
import { FramePageComponent } from '../frame/admin/admin.component';
import { MediaCenterComponent } from '../page/commons/media-center/media-center.component';
import { OrganizationDetailComponent } from '../page/commons/organization/organization-detail/organization-detail.component';
import { MetaDataResolver } from '../service/resolvers/meta-data.resolver';
import { OrganizationDetailFullDetailComponent } from '../page/commons/organization/organization-detail/organization-detail-full-detail/organization-detail-full-detail.component';
import { TicketCenterRoutes } from '@saep-ict/ticket-center';
import { TicketCenterWrapperComponent } from '../page/commons/ticket-center-wrapper/ticket-center.component';
import { ContactComponent } from '../page/commons/contact/contact.component';
import { PermissionKeyEnum, ROUTE_URL } from '@saep-ict/angular-spin8-core';

export namespace PortalRouteList {
	export const routes: Route[] = [
		{
			path: '',
			component: FramePageComponent,
			children: [
				// Organization
				{
					path: `${ROUTE_URL.company}/:idOrganization`,
					component: OrganizationDetailComponent,
					data: {
						permissionKey: [PermissionKeyEnum.PORTAL_DASHBOARD],
						meta: {
							title: 'Informazioni azienda',
							description: "Informazioni sull'azienda"
						}
					},
					resolve: {
						meta: MetaDataResolver
					},
					children: [
						{
							path: '',
							pathMatch: 'full',
							redirectTo: ROUTE_URL.details,
							data: { permissionKey: [PermissionKeyEnum.PORTAL_DASHBOARD] }
						},
						{
							path: ROUTE_URL.details,
							component: OrganizationDetailFullDetailComponent,
							data: { permissionKey: [PermissionKeyEnum.PORTAL_DASHBOARD] },
							pathMatch: 'full'
						},
						{
							path: ROUTE_URL.contacts,
							component: ContactComponent,
							data: { permissionKey: [PermissionKeyEnum.PORTAL_DASHBOARD] }
						}
					]
				},
				// Media center
				{
					path: `${ROUTE_URL.mediaCenter}`,
					data: { permissionKey: [PermissionKeyEnum.PORTAL_MEDIACENTER] },
					component: MediaCenterComponent,
					pathMatch: 'full'
				},
				// Ticket center
				{
					path: `${ROUTE_URL.ticketCenter}`,
					data: { permissionKey: [PermissionKeyEnum.PORTAL_TICKET_CENTER] },
					children: [
						{
							path: '',
							data: {
								permissionKey: [PermissionKeyEnum.PORTAL_DASHBOARD],
								meta: {
									title: 'Ticket Center',
									description: 'Ticket Center'
								}
							},
							resolve: {
								meta: MetaDataResolver
							},
							component: TicketCenterWrapperComponent,
							children: TicketCenterRoutes
						}
					]
				}
			]
		}
	];
}
