<page-with-sidebar [isSticky]="true"
	[sideBarPosition]="order ? sideBarPositionValues.BOTTOM : sideBarPositionValues.NONE">
	<ng-container pageMain>
		<!-- <mat-card  class="angelo-theme autoheight mb-20" *ngIf="this.order?.header?.status === this.orderToAuthorize">
			<order-detail-main-header-edit [configuration]="this.configuration"></order-detail-main-header-edit>
			Nuovo componente di conferma testata ordine
			<order-detail-confirm *ngIf="this.user.current_permission.context_application === this.contextApplicationItemCodeEnum.BACKOFFICE"></order-detail-confirm>
		</mat-card> -->
		<mat-card class="angelo-theme autoheight">
			<ng-container *ngIf="order; else newOrder">
				<top-bar *ngIf="order" [goBackButton]="true" [goBackPath]="goBackPath"
					[actionMenu]="order && order._id ? true : false">
					<ng-container left-side-content>
						<status-bar-new [config]="statusBarConfig"></status-bar-new>
					</ng-container>
					<ng-container additional-right-content
						*ngIf="order.header.status === orderStatusEnum.DRAFT && routedMainContentKey === ROUTE_URL.checkout">
						<button mat-raised-button color="primary"
							(click)="router.navigate([ROUTE_URL.catalog], { relativeTo: route })">
							<span>{{ 'order.edit' | translate | sentencecase }}</span>
						</button>
					</ng-container>
					<ng-container actions-menu-buttons>
						<div class="order-detail-actions-menu-buttons">

							<ng-container *ngIf="order && order._id">
								<!-- Delete button -->
								<button *ngIf="orderStatus === orderStatusEnum.DRAFT" mat-menu-item
									(click)="utilOrderService.dialogConfirmOrderDelete(order)">
									<mat-icon class="delete">delete</mat-icon>
									<span>{{ 'general.delete' | translate | sentencecase }}</span>
								</button>
								<!-- Confirm order button -->
								<button *ngIf="order && order._id && orderStatus === orderStatusEnum.TO_AUTHORIZE" mat-menu-item
									(click)="confirmOrder()">
									<mat-icon class="add_task">add_task</mat-icon>
									<span>{{ 'general.approves' | translate | sentencecase }}</span>
								</button>
								<!-- TODO: trasferire insieme agli altri segmenti -->
								<!-- Go to header-edit button -->
								<button *ngIf="orderStatus === orderStatusEnum.DRAFT" mat-menu-item
									(click)="router.navigate(['header-edit'], { relativeTo: route })">
									<mat-icon class="edit">edit</mat-icon>
									<span>{{ 'order.header_edit' | translate | sentencecase }}</span>
								</button>

								<!-- Clone button -->
								<button mat-menu-item (click)="utilOrderService.dialogConfirmOrderClone(order)" *ngIf="
										user.current_permission.context_application ===
											contextApplicationItemCodeEnum.AGENT ||
										user.current_permission.context_application ===
											contextApplicationItemCodeEnum.B2B ||
										user.current_permission.context_application ===
											contextApplicationItemCodeEnum.CRM
									">
									<mat-icon class="copy">file_copy</mat-icon>
									<span>{{ 'general.duplicate' | translate | sentencecase }}</span>
								</button>
								<!-- Generate PDF button -->
								<button mat-menu-item (click)="generatePDF()">
									<mat-icon class="pdf">picture_as_pdf</mat-icon>
									<span>{{ 'order.action.get_pdf' | translate | sentencecase }}</span>
								</button>
								<!-- Download excel template button-->
								<button *ngIf="orderStatus === orderStatusEnum.DRAFT" mat-menu-item
									(click)="bucketManager.bucketManagerDownloadTemplate('article-list-import-template.xlsx')">
									<mat-icon class="download">file_download</mat-icon>
									<span>{{ 'general.download_xls_template' | translate | sentencecase }}</span>
								</button>
								<!-- Uplaod excel button-->
								<button *ngIf="orderStatus === orderStatusEnum.DRAFT" mat-menu-item
									(click)="openDialogCalcSheetArticleListUpdateOrder()">
									<mat-icon class="upload">file_upload</mat-icon>
									<span>{{ 'order.action.upload_xls' | translate | sentencecase }}</span>
								</button>
								<!-- SICSEG METTI IN PAGATO -->
								<button
									*ngIf="user.current_permission.context_application === contextApplicationItemCodeEnum.BACKOFFICE"
									mat-menu-item (click)="setOrderPaid()">
									<mat-icon class="pdf">payment</mat-icon>
									<span>Pagamento</span>
								</button>
							</ng-container>
						</div>
					</ng-container>
				</top-bar>
				<div class="order-detail-content-wrapper">
					<router-outlet></router-outlet>
				</div>
			</ng-container>
		</mat-card>
	</ng-container>
	<ng-container pageSide>
		<ng-container *ngComponentOutlet="routedSidebarContent"></ng-container>
	</ng-container>
</page-with-sidebar>
<!-- ng-template -->
<ng-template #newOrder>
	<button *ngIf="
			(
				user.current_permission.context_application === contextApplicationItemCodeEnum.B2B ||
				user.current_permission.context_application === contextApplicationItemCodeEnum.AGENT
			); else loadingOrder
		" class="add-order" mat-raised-button (click)="utilOrderService.orderDraftCreate(organization)" color="accent">
		<mat-icon>add</mat-icon>
		<span>{{ 'order.add_new' | translate | sentencecase }}</span>
	</button>
</ng-template>
<ng-template #loadingOrder>
	{{ 'general.loading' | translate | sentencecase }}
</ng-template>