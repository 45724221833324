<mat-dialog-wrapper [title]="data.title | sentencecase" [showCloseIcon]="false">
	<ng-container content>
		<div class="container-fluid">
			<div class="row">
				<div class="col-12 dialog-order-detail__status-bar mb-3">
					<status-bar id="theme-white" [config]="statusBarOrderB2cService.config"></status-bar>
				</div>

				<div class="col-sm-12 col-md-12 col-lg-8 dialog-order-detail__essay">
					<b2c-checkout-article-list [productList]="productList" [order]="order"></b2c-checkout-article-list>
					<order-detail-price [orderPriceMap]="orderPriceMap"></order-detail-price>
				</div>
				<div class="col-sm-12 col-md-12 col-lg-4 dialog-order-detail__main-data">
					<div class="row">
						<div class="col-md-6 col-lg-12">
							<h3>Indirizzo di spedizione</h3>
							<p *ngIf="shippingAddress">
								{{ shippingAddress.address }}<br />
								{{ shippingAddress.zip_code }} - {{ shippingAddress.locality }} ({{
									shippingAddress.province['label']
								}})
								<br />
								{{ shippingAddress.country }}
							</p>
						</div>
						<div class="col-md-6 col-lg-12">
							<!-- <h3>Metodo di spedizione</h3>
							<p *ngIf="shippingMethod">
								{{ shippingMethod.description }}
							</p> -->
							<h3>Metodo di pagamento</h3>
							<p *ngIf="paymentMethod">
								{{
									paymentMethod.description
										? paymentMethod.description
										: paymentMethod.description_short
								}}
							</p>
						</div>
					</div>

					<h3>Note aggiuntive</h3>
					<p>
						{{ order.header?.note_order }}
					</p>
				</div>
			</div>
		</div>
	</ng-container>

	<ng-container actions>
		<form-button-bar
			[align]="'right'"
			[primaryButtonIconLabel]="'receipt'"
			(primaryButtonClickEmitter)="downloadInvoice()"
			[secondaryButtonLabel]="'general.back_to_list' | translate | sentencecase"
			(secondaryButtonClickEmitter)="dialogRef.close()"
		></form-button-bar>

		<!-- 
			!!! Per ripristinare il pulsante "Scarica Fattura" scomentare scommentare form-button-bar sotto ed eliminare quello sopra !!!
			<form-button-bar
			[align]="'right'"
			[primaryButtonLabel]="'receipt.download' | translate | sentencecase"
			[primaryButtonIconLabel]="'receipt'"
			(primaryButtonClickEmitter)="downloadInvoice()"
			[secondaryButtonLabel]="'general.back_to_list' | translate | sentencecase"
			(secondaryButtonClickEmitter)="dialogRef.close()"
		></form-button-bar> -->
	</ng-container>
</mat-dialog-wrapper>
