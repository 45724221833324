<form [formGroup]="form" (ngSubmit)="onFormSubmit()" class="angelo-theme">
	<mat-dialog-wrapper [title]="data.title | translate | sentencecase" [showCloseIcon]="false">
		<ng-container content>
			<form class="angelo-theme" [formGroup]="form">
				<div class="row">
					<form-control-multipurpose
						*ngFor="
							let field of formFieldList
								| filterByAllTheBooleans
									: {
											propertyListName: ['on_form_template'],
											value: true
									  }
						"
						[formControlName]="field.name"
						[field]="field"
						class="col-md-{{ field.column_width ? +field.column_width : 12 }}"
					></form-control-multipurpose>
				</div>
			</form>
		</ng-container>
		<ng-container actions>
			<!-- gestire disable -->
			<form-button-bar
				*ngIf="data.canEdit"
				[align]="'right'"
				[primaryButtonLabel]="'general.confirm' | translate | sentencecase"
				[primaryButtonIsDisabled]="!form.valid"
				(primaryButtonClickEmitter)="onFormSubmit()"
				[secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
				(secondaryButtonClickEmitter)="dialogRef.close()"
			></form-button-bar>
		</ng-container>
	</mat-dialog-wrapper>
</form>
