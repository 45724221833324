import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseStateModel, LocalListHandlerWidgetWrapper, DialogConfirmComponent, SentencecasePipe, UtilDownload } from '@saep-ict/angular-core';
import { NewsModel } from '../../../model/news.model';
import { TranslateService } from '@ngx-translate/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AppUtilService } from '../../../service/util/app-util.service';
import { Router } from '@angular/router';
import { ContextApplicationItemCodeEnum, PATH_URL, ROUTE_URL, UserDetailModel } from '@saep-ict/angular-spin8-core';
import { StateFeature } from '../../../state';
import { Observable, take } from 'rxjs';
import { NewsActionEnum, NewsStatusEnum } from '../../../enum/news.enum';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomerAppConfig } from '../../../customer-app.config';
import moment from 'moment';

@Component({
	selector: 'news-list-wrapper',
	templateUrl: './news-list-wrapper.component.html',
	styleUrls: ['./news-list-wrapper.component.scss']
})
export class NewsListWrapperComponent extends LocalListHandlerWidgetWrapper {
	@Output() selectedNewsList: EventEmitter<{ codeItemList: string[]; action: NewsActionEnum }> = new EventEmitter();
	_selectedNewsList: string[] = [];

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	isBackoffice: boolean;
	statusFilter: string;
	timestampNow = moment().valueOf();
	
	ROUTE_URL = ROUTE_URL;
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

    newsStatusEnum = NewsStatusEnum;
    newsActionEnum = NewsActionEnum;

	utilDownload = UtilDownload;

	constructor(
		public utilService: AppUtilService,
		public translateService: TranslateService,
		public store: Store,
		public router: Router,
        private dialog: MatDialog,
        private sentenceCasePipe: SentencecasePipe,
		public appConfig: CustomerAppConfig
	) {
		super(utilService, translateService, store);
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
			this.isBackoffice = [
				ContextApplicationItemCodeEnum.BACKOFFICE,
				ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN
			].includes(this.user.current_permission.context_application);
		});
	}

	selectedData(e: MatCheckboxChange, data: NewsModel) {
		if (e.checked) {
			this._selectedNewsList.push(data.code_item);
		} else {
			const index = this._selectedNewsList.indexOf(data.code_item);
			if (index > -1) {
				this._selectedNewsList.splice(index, 1);
			}
		}
	}

	editNewsDetail(news: NewsModel) {
		this.router.navigate([PATH_URL.PRIVATE, ROUTE_URL.news, news._id]);
	}

	newsActionHandler(e: MouseEvent, actionType: NewsActionEnum, news?: NewsModel) {
        e.stopPropagation();
		const title = this.sentenceCasePipe.transform(this.translate.instant('general.confirm'));
		let text: string;
		switch (actionType) {
			case NewsActionEnum.ARCHIVE:
				text = this.sentenceCasePipe.transform(this.translate.instant('news.action.confirm_mark_as_archived_selected'));
				break;
			case NewsActionEnum.DELETE:
				text = this.sentenceCasePipe.transform(this.translate.instant('news.action.confirm_delete_selected'));
				break;
			case NewsActionEnum.TOGGLE_STATUS:
				// TODO: verificare se resteranno sufficienti due status ed una dinamica di toggle
				text =
					this.sentenceCasePipe.transform(
						this.translate.instant(
							news.header.status === NewsStatusEnum.NEW ?
							'news.action.confirm_mark_as_archived':
							'news.action.confirm_mark_as_new'
						)
					);
				break;
			case NewsActionEnum.DELETE:
				text = this.sentenceCasePipe.transform(this.translate.instant('news.action.confirm_delete'));
				break;
		}
		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: title,
				text: text,
				disableClose: true,
				panelClass: 'dialog-medium'
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
                this.selectedNewsList.emit(
					{
						codeItemList: news ? [news.code_item] : this._selectedNewsList,
						action: actionType
					}
				);
			}
		});
	}

}
