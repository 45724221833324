<mat-dialog-wrapper [title]="data.title" [showCloseIcon]="false">
  <ng-container content>
    <div class="container-fluid" [formGroup]="form">
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-select formControlName="organization_class" placeholder="Stato">
              <mat-option *ngFor="let class of organizationClasses" [value]="class">
                {{ class }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <input matInput formControlName="first_name" placeholder="Nome" />
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <input matInput formControlName="last_name" placeholder="Cognome" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label>Data di nascita</mat-label>
            <input matInput formControlName="birth_date" [matDatepicker]="picker" readonly (click)="picker.open()" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <input matInput formControlName="fiscal_code" placeholder="Codice Fiscale" />
          </mat-form-field>
        </div>
      </div>


    </div>
  </ng-container>
  <ng-container actions>
    <form-button-bar *ngIf="data.save" [align]="'right'"
      [primaryButtonLabel]="'general.save' | translate | sentencecase" (primaryButtonClickEmitter)="onFormSubmit()"
      [secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
      (secondaryButtonClickEmitter)="dialogRef.close()">

    </form-button-bar>
    <form-button-bar *ngIf="!data.save" [align]="'right'"
      [secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
      (secondaryButtonClickEmitter)="dialogRef.close()">

    </form-button-bar>
  </ng-container>
</mat-dialog-wrapper>