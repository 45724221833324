import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { PouchDeleteResponse } from '../../service/pouch-db/model/pouch-base-response.model';
import { OrderStateModel } from '@saep-ict/angular-spin8-core';

export enum OrderActionEnum {
	UPDATE = '[order] Update',
	LOAD = '[order] Load',
	LOAD_FROM_LOCALSTORAGE_BY_REST = '[order] Load from localstorage by rest',
	SAVE = '[order] Save',
	REMOVE = '[order] Remove',
	REMOVED = '[order] Removed',
	COMPLETED = '[order] Completed',
	SKIP = '[order] Skip',
	RESET = '[order] Reset',
	ERROR = '[order] Error'
}

export namespace OrderStateAction {
	export const load = createAction(OrderActionEnum.LOAD, props<BaseStateModel<string>>());
	export const loadFromLocalStorageByRest = createAction(
		OrderActionEnum.LOAD_FROM_LOCALSTORAGE_BY_REST,
		props<BaseStateModel<string>>()
	);
	export const update = createAction(
		OrderActionEnum.UPDATE,
		props<BaseStateModel<OrderStateModel>>()
	);
	export const save = createAction(OrderActionEnum.SAVE, props<BaseStateModel<OrderStateModel>>());
	export const remove = createAction(OrderActionEnum.REMOVE, props<BaseStateModel<OrderStateModel>>());
	export const removed = createAction(OrderActionEnum.REMOVED, props<BaseStateModel<PouchDeleteResponse>>());
	export const skip = createAction(OrderActionEnum.SKIP);
	export const completed = createAction(
		OrderActionEnum.COMPLETED,
		props<BaseStateModel<OrderStateModel>>()
	);
	export const reset = createAction(OrderActionEnum.RESET);
	export const error = createAction(OrderActionEnum.ERROR, props<BaseStateModel<any>>());
}
