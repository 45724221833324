import { ITdDataTableColumn } from "@covalent/core/data-table";
import _ from "lodash";
import { ConfigurationContactContextApplicationColumnMap } from "../../configuration-customer/contact/contact-column-map.constant";
import { Injector } from "@angular/core";
import { AngularCoreUtilService } from "@saep-ict/angular-core";

const injector = Injector.create({
	providers: [{ provide: AngularCoreUtilService, deps: [] }]
});

// Mostrare: full_name, phone, email, date_creation, contact_details, order_confirm_email,details,area, business_name, order_confirm_email
const utilService = injector.get(AngularCoreUtilService);
let newColumnList = [...ConfigurationContactContextApplicationColumnMap.baseColumns];
const actionIndex = utilService.getElementIndex(newColumnList, 'name', 'actions');
newColumnList.splice(actionIndex, 5);

export const projectOverride: ITdDataTableColumn[] = 
_.cloneDeep(newColumnList);