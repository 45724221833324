// model
import { TicketCenterConfigurationModel } from '@saep-ict/ticket-center';
import {
	ConfigurationCustomerVat,
	ContextPrivilegeMap,
	ConfigurationCustomerOrganizationTypeSelector
} from '../../model/structure/configuration-customer.model';
import {
	OrderStatusEnum,
	OrganizationPouchModel,
	OrganizationTypeEnum,
	ArticlePouchModel
} from '@saep-ict/pouch_agent_models';

// constant
import {
	ConfigurationStatusAggregationMap,
	StateRelatedLinkBackoffice,
	StateRelatedLinkAgent,
	StateRelatedLinkB2b,
	StateRelatedLinkB2c
} from './order/status-aggregation-map.constant';
import {
	configurationCustomerOrderHeaderFieldListBackOffice,
	configurationCustomerOrderRevisionHeaderFieldListBackOffice
} from './order/order-header-creation-field-map/order-header-creation-field-map-backoffice.const ';
import { TicketCenterColumn } from './ticket-center/column-map.constant';
import { ConfigurationOrganizationContextApplicationColumnMap } from './organization/organization-column-map.constant';
import { TicketCenterBodyCustomField } from './ticket-center/body-custom-field.constant';
import { TicketCenterStatus } from './ticket-center/status-configuration.constant';
import { TicketCenterLocalSearchTextKeyList } from './ticket-center/local-search-text-key-list.constant';

// store
import { UserManagementStateAction } from '../../state/backoffice/user-management/user-management.actions';

// misc
import { ITdDataTableColumn } from '@covalent/core/data-table';
import {
	AngularCoreUtilService,
	ITdDataTableColumnCustom,
	FormControlMultipurposeModel,
	FileTypeEnum
} from '@saep-ict/angular-core';
import { Injector } from '@angular/core';
import * as _ from 'lodash';
import { ConfigurationCustomerAttachedFileContextApplicationColumnMap } from './attached-file/attached-file.constant';
import { SortSelectorConfigurationItem } from '../../widget/sort-selector/sort-selector.component';
import { ConfigurationCustomerArticleContextApplicationCatalogSortList } from './article/article-catalog-sort-list.constant';
import {
	ContextApplicationItemCodeEnum,
	ContextTypeAndApplicationLink,
	UserDetailModel,
	contextCodeItemBackoffice,
	contextCodeItemCompany,
	contextCodeItemAgent,
	contextCodeItemB2C,
	contextCodeItemCRM,
	contextCodeItemPortal,
	DestinationColumnMap,
	InformativePageColumnMap,
	ConfigurationProjectModel,
	ConfigurationUtil,
	OrderEnum,
	AngularSpin8CoreUtilTranslateService,
	ROUTE_URL,
	OpportunityEnum
} from '@saep-ict/angular-spin8-core';
import { ConfigurationContextCodeColumnMap } from './context-code/context-code-column-map.constant';
import { StatusBarConfigNewBis } from '../../model/status-bar-new.model';
import { StatusBar } from './order/status-aggregation-map-status-bar.constant';
import { StatisticModel } from '../../model/statistics.model';
import { StatisticEnum } from '../../enum/statistic.enum';
import { ConfigurationCategory } from '../category.constant';
import { ConfigurationReceiptContextApplicationColumnMap } from './receipt/receipt-column-map.constant';
import { CategoryEnum } from '../../enum/category.enum';
import { OrderHeaderCreationFieldMapAgent } from './order/order-header-creation-field-map/order-header-creation-field-map-agent.const';
import { ConfigurationCustomerArticleCategoryColumnMap } from './article/article-category-column-map.constant';
import { orderCategorySectionList } from './order/order-category-section-list.constants';
import { OrderCommonCatalogEnum } from '../../enum/order-common-catalog.enum';
import { OrderCommonCatalog } from './order/order-common-catalog.const';
import { CategoryModel } from '../../model/category-list.model';
import { NewsModel } from '../../model/news.model';
import { NewsStatusEnum } from '../../enum/news.enum';
import { articleColumnMapB2bCatalog } from './article/article-column-map-b2b-catalog.constant';
import { configurationCustomerOrderHeaderFieldList } from './order/order-header-creation-field-map/order-header-creation-field-map-b2b.const ';
import { articleColumnMapB2bCheckout } from './article/article-column-map-b2b-checkout.constant';
import { articleColumnMapAgentCatalog } from './article/article-column-map-agent-catalog.constant';
import { articleColumnMapAgentCheckout } from './article/article-column-map-agent-checkout.constant';
import { articleColumnMapCrmCatalog } from './article/article-column-map-crm-catalog.constant';
import { BackofficeOutcome } from '../../enum/request.enum';
import { ConfigurationOrganizationVisibilityFilter } from './organization/organization-filter-form-map.constant';
import {
	ConfigurationOrderVisibilityFilter,
	ConfigurationVisibleOrderFilter
} from './order/order-filter-form-map.constant';
import { CustomerConfigurationOrderHeaderAsideSummaryB2bFieldList } from './order/order-header-creation-field-map/order-header-creation-field-map-aside-summary/order-header-creation-field-map-aside-summary-b2b.const ';
import { CustomerConfigurationOrderHeaderAsideSummaryAgentFieldList } from './order/order-header-creation-field-map/order-header-creation-field-map-aside-summary/order-header-creation-field-map-aside-summary-agent.const ';
import { ConfigurationOrder } from '../order/order.constants';
import { ConfigurationOrderExtend } from './order/order.constants';
import { articleColumnMapBackofficeArticle } from './article/article-column-map-backoffice-article.constant';
import { articleColumnMapBackofficeArticleDetail } from './article/article-column-map-backoffice-article-detail.constant';
import { articleColumnMapBackofficeProductStock } from './article/article-column-map-backoffice-product-stock.constant';
import { FaqConfigurationModel } from '../../model/faq.model';
import { ClassRequest } from './organization/organization-filter-form-map.constant';
import { articleColumnMapBackOfficeCatalog } from './article/article-column-map-backoffice-catalog.constant';
import { articleColumnMapBackOfficeCheckout } from './article/article-column-map-backoffice-checkout.constant';
import { ForecastCategoryConfiguration } from './forecast/forecast-category.const';
import { articleColumnMapB2bForecast } from './article/article-column-map-b2b-forecast.constant';
import * as ForecastModel from '../../model/forecast.model';
import { ArticleCheckoutTree, Category } from '@saep-ict/pouch_agent_models';
import { ConfigurationPage } from '../../../app/model/page.model';
import * as CreationFieldMapContextCodeOrganizationBackoffice from './form-control-multipurpose/creation-field-map/context-code/context-code-organization/creation-field-map-context-code-organization-backoffice.const';
import * as CreationFieldMapContextCodeOrganizationAgent from './form-control-multipurpose/creation-field-map/context-code/context-code-organization/creation-field-map-context-code-organization-agent.const';
import * as CreationFieldMapContextCodeOrganizationB2b from './form-control-multipurpose/creation-field-map/context-code/context-code-organization/creation-field-map-context-code-organization-b2b.const';
import * as CreationFieldMapContextCodeOrganizationB2c from './form-control-multipurpose/creation-field-map/context-code/context-code-organization/creation-field-map-context-code-organization-b2c.const';
import * as CreationFieldMapContextCodeOrganizationPortal from './form-control-multipurpose/creation-field-map/context-code/context-code-organization/creation-field-map-context-code-organization-portal.const';
import * as CreationFieldMapContextCodeOrganizationCrm from './form-control-multipurpose/creation-field-map/context-code/context-code-organization/creation-field-map-context-code-organization-crm.const';
import * as CreationFieldMapContextCodeOrganizationPublic from './form-control-multipurpose/creation-field-map/context-code/context-code-organization/creation-field-map-context-code-organization-public.const';
import * as CreationFieldMapContextCodeBase from './form-control-multipurpose/creation-field-map/context-code/creation-field-map-context-code-base.const';
import * as CreationFieldMapContactDetailDialog from './form-control-multipurpose/creation-field-map/contact/creation-field-map-contact-detail-dialog.const';
import * as creationFieldMapDocumentBase from './form-control-multipurpose/creation-field-map/creation-field-map-base.const';
import * as creationFieldMapDestinationBase from './form-control-multipurpose/creation-field-map/destination/creation-field-map-destination-base.const';
import * as creationFieldMapDestinationListEdit from './form-control-multipurpose/creation-field-map/destination/creation-field-map-destination-listEdit.const';
import * as creationFieldMapContextCodeBase from './form-control-multipurpose/creation-field-map/context-code/creation-field-map-context-code-base.const';
import * as creationFieldMapDivision from './form-control-multipurpose/creation-field-map/creation-field-map-division.const';
import { ConfigurationNews } from '../news.constants';
import * as columnListDestinationOrganizationBackoffice from '../column-list-map/column-list-section-map/column-list-map-destination-backoffice.const';
import * as columnListDestinationOrganizationB2B from '../column-list-map/column-list-section-map/column-list-map-destination-b2b.const';
import * as columnListDestinationOrganizationB2C from '../column-list-map/column-list-section-map/column-list-map-destination-b2c.const';
import * as columnListDestinationOrganizationPortal from '../column-list-map/column-list-section-map/column-list-map-destination-portal.const';
import * as columnListDestinationOrganizationAgent from '../column-list-map/column-list-section-map/column-list-map-destination-agent.const';
import * as columnListDestinationOrganizationCRM from '../column-list-map/column-list-section-map/column-list-map-destination-crm.const';
import * as columnListOrderListBackoffice from '../column-list-map/column-list-section-map/column-list-map-order-backoffice.constant';
import * as columnListOrderListAgent from '../column-list-map/column-list-section-map/column-list-map-order-agent.constant';
import * as columnListOrderListB2B from '../column-list-map/column-list-section-map/column-list-map-order-b2b-constant';
import * as columnListOrderListB2C from '../column-list-map/column-list-section-map/column-list-map-order-b2c.constant';
import * as columnListOrderListPortal from '../column-list-map/column-list-section-map/column-list-map-order-portal.constant';
import * as columnListOrderListCrm from '../column-list-map/column-list-section-map/column-list-map-organization-crm-constant';
import * as columnListOrganizationBackoffice from '../column-list-map/column-list-section-map/column-list-map-organization-backoffice.constant';
import * as columnListOrganizationAgent from '../column-list-map/column-list-section-map/column-list-map-organization-agent.constant';
import * as columnListOrganizationCRM from '../column-list-map/column-list-section-map/column-list-map-organization-crm-constant';
import * as NavigationMainSidebarBackoffice from './navigation-main-sidebar/navigation-main-sidebar-backoffice.constant';
import * as NavigationMainSidebarB2b from './navigation-main-sidebar/navigation-main-sidebar-b2b.constant';
import * as NavigationMainSidebarAgent from './navigation-main-sidebar/navigation-main-sidebar-agent.constant';
import * as NavigationMainSidebarCrm from './navigation-main-sidebar/navigation-main-sidebar-crm.constant';
import * as NavigationMainSidebarPortal from './navigation-main-sidebar/navigation-main-sidebar-portal.constant';
import * as columnListContactOrganizationBackoffice from '../../constants/column-list-map/column-list-section-map/column-list-map-contact-backoffice.const';
import * as columnListContactOrganizationB2B from '../../constants/column-list-map/column-list-section-map/column-list-map-contact-b2b.const';
import * as columnListContactOrganizationAgent from '../column-list-map/column-list-section-map/column-list-map-contact-agent.const';
import * as columnListContactOrganizationB2C from '../../constants/column-list-map/column-list-section-map/column-list-map-contact-b2c.const';
import * as columnListContactOrganizationCRM from '../column-list-map/column-list-section-map/column-list-map-contact-crm.const';
import * as columnListContactOrganizationPortal from '../../constants/column-list-map/column-list-section-map/column-list-map-contact-portal.const';
import { configurationCustomerOrderListFilterCreationFielMapAgent } from './order/order-list-filter-creation-field-map/order-list-filter-creation-field-map-agent';

const injector = Injector.create({
	providers: [{ provide: AngularCoreUtilService, deps: [] }]
});

const utilService = injector.get(AngularCoreUtilService);

const dayCurrent: Date = new Date();
const yearCurrent: number = dayCurrent.getFullYear();
// per maggiore chiarezza si considera come chiave il dizionario dei mesi dell'applicazione, dove `jan: 1`
// (in getMonth() è `jan: 0`)
const monthCurrent: number = dayCurrent.getMonth() + 1;

export namespace ConfigurationCustomer {
	export namespace AppStructure {
		export namespace Erp {
			export const has_erp = true;
			export const backoffice_configuration = {
				has_shipping_charges_company_organization: true,
				has_shipping_charges_private_organization: true,
				has_stock_synchronization: true
			};
		}
		export const authenticationWrapper: ConfigurationProjectModel.AuthenticationWrapper = {
			can_access_to_registration: true,
			link_access_to_registration: `${ROUTE_URL.public}/${ROUTE_URL.authentication}/${ROUTE_URL.createAccount}`
		};
		// Manages the display of the following features in the CONTACTS section of the company
		export const contact: ConfigurationProjectModel.Contact = {
			send_email_confirmation: true,
			can_activate_email_order_receipt: true
		};
		export const has_manual: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
			AGENT: true,
			B2B: true,
			B2C: true,
			BACKOFFICE_ADMIN: true,
			BACKOFFICE: true,
			PORTAL: true,
			CRM: true,
			PUBLIC: false
		};
		export const noSqlDocSeparator = '_';
	}
	export namespace ContextApplication {
		export const map: ContextTypeAndApplicationLink[] = [
			{
				context_application_item_code: ContextApplicationItemCodeEnum.BACKOFFICE,
				context_code_item: contextCodeItemBackoffice,
				application_name: 'Backoffice',
				icon: 'admin_panel_settings'
			},
			{
				context_application_item_code: ContextApplicationItemCodeEnum.B2B,
				context_code_item: contextCodeItemCompany,
				application_name: 'B2B',
				icon: 'shopping_bag'
			}
		];
	}

	export namespace Navigation {
		export const mainSidebarContextApplicationMap = (
			user: UserDetailModel
		): ConfigurationProjectModel.Navigation.MainSidebarItemModel[] => {
			let navigationList: ConfigurationProjectModel.Navigation.MainSidebarItemModel[];
			switch (user.current_permission.context_application) {
				case ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN:
					navigationList = NavigationMainSidebarBackoffice.projectOverride();
					break;
				case ContextApplicationItemCodeEnum.BACKOFFICE:
					navigationList = NavigationMainSidebarBackoffice.projectOverride();
					break;
				case ContextApplicationItemCodeEnum.AGENT:
					navigationList = NavigationMainSidebarAgent.projectOverride();
					break;
				case ContextApplicationItemCodeEnum.B2B:
					navigationList = NavigationMainSidebarB2b.projectOverride(user);
					break;
				case ContextApplicationItemCodeEnum.PORTAL:
					navigationList = NavigationMainSidebarPortal.projectOverride(user);
					break;
				case ContextApplicationItemCodeEnum.CRM:
					navigationList = NavigationMainSidebarCrm.projectOverride();
					break;
				default:
			}
			// TODO: rimuovere una volta rinormalizzati i path del media-center anche su angular-spin8-core
			if (navigationList) {
				for (const item of navigationList) {
					if (item.key === 'media_center') {
						item.route = 'media-center';
					}
				}
			}
			let navigationList2;
			navigationList2 = [...navigationList];
			navigationList.forEach((item, i) => {
				if (Object.keys(overrideMainSidebarLabelMap).includes(item.key)) {
					item.name = overrideMainSidebarLabelMap[item.key];
				}
				// if (item.parentKey && Object.keys(excludeSidebarSubsections).includes(item.parentKey) &&
				// 	excludeSidebarSubsections[item.parentKey].includes(item.key)
				// ) {
				// 	if(Object.keys(excludeSidebarSubsections).length > 1){
				// 		if(excludeSidebarSubsections.insert_parent_key.includes(item.key))
				// 		{
				// 			navigationList.splice(i,1)
				// 		}
				// 		if(excludeSidebarSubsections.insert_parent_key1.includes(item.key))
				// 		{
				// 			excludeSidebarSubsections.insert_parent_key1.map((item1,index) =>{
				// 				if(item1 == item.key)
				// 				{
				// 					// indice riferito a navigationList.forEach
				// 					navigationList.splice(i,1)
				// 				}
				// 			})
				// 		}
				// 	}
				if (excludeSidebarSections.includes(item.key)) {
					excludeSidebarSections.map((item1, index) => {
						if (item1 === item.key) {
							navigationList2.map((it, index) => {
								if (it.key == item1) {
									navigationList2.splice(index, 1);
								}
							});
						}
					});
					//let new_navigationList = navigationList.slice(i);
					navigationList.forEach((item, i) => {
						if (
							item.parentKey &&
							Object.keys(overrideMainSidebarLabelMapChild).includes(item.parentKey) &&
							Object.keys(overrideMainSidebarLabelMapChild[item.parentKey]).includes(item.key)
						) {
							item.name = String(Object.values(overrideMainSidebarLabelMapChild[item.parentKey]));
						}
					});
				}
				if (
					item.parentKey &&
					Object.keys(overrideMainSidebarLabelMapChild).includes(item.parentKey) &&
					Object.keys(overrideMainSidebarLabelMapChild[item.parentKey]).includes(item.key)
				) {
					for (let value in Object.values(overrideMainSidebarLabelMapChild[item.parentKey])) {
						if (Object.keys(overrideMainSidebarLabelMapChild[item.parentKey])[value].includes(item.key)) {
							item.name = String(Object.values(overrideMainSidebarLabelMapChild[item.parentKey])[value]);
						}
					}
				}
			});
			navigationList = [...navigationList2];
			return navigationList;
		};
		export const overrideMainSidebarLabelMap = {
			orders: 'pagamenti'
		};

		export const excludeSidebarSections = ['insert_key'];

		export const overrideMainSidebarLabelMapChild = {
			orders: {
				orders_received: 'da pagare',
				orders_consolidated: 'da approvare',
				orders_fulfilled: 'pagati'
			}
		};

		//Esempio
		// export const overrideMainSidebarLabelMapChild = {
		// 	"orders": {
		// 		'orders_received': "inviati",
		// 		'orders_consolidated': "consolidati",
		// 		'orders_fulfilled': "completati"
		// 	 },
		// 	"organizations": {
		// 		"organizations_prospect": 'prospetti'
		// 	}

		// };
		// export const excludeSidebarSections = ['organizations_customer', 'organizations_lead']
		// export const overrideMainSidebarLabelMap = {
		// 	organizations: 'membri',
		// 	orders: 'pagamenti'
		// };
	}

	export namespace UserManagement {
		export const canEditContextCode: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
			AGENT: true,
			B2B: true,
			B2C: true,
			BACKOFFICE_ADMIN: true,
			BACKOFFICE: true,
			PORTAL: true,
			CRM: true,
			PUBLIC: false
		};
		export const storeAction: ConfigurationProjectModel.StoreAction = {
			load_list: UserManagementStateAction.loadListWithAvatar,
			load_detail: UserManagementStateAction.loadDetailWithAvatar,
			reset: UserManagementStateAction.reset
		};
	}

	export namespace Organization {
		export const canCreate: ConfigurationProjectModel.Organization = {
			detail: {
				AGENT: true,
				B2B: false,
				B2C: false,
				BACKOFFICE_ADMIN: true,
				BACKOFFICE: false,
				PORTAL: true,
				CRM: true,
				PUBLIC: false
			},
			destination: {
				AGENT: true,
				B2B: true,
				B2C: true,
				BACKOFFICE_ADMIN: true,
				BACKOFFICE: true,
				PORTAL: true,
				CRM: true,
				PUBLIC: false
			},
			contact: {
				AGENT: true,
				B2B: true,
				B2C: true,
				BACKOFFICE_ADMIN: true,
				BACKOFFICE: true,
				PORTAL: true,
				CRM: true,
				PUBLIC: false
			}
		};
		export const canEdit: ConfigurationProjectModel.Organization = {
			detail: {
				AGENT: true,
				B2B: true,
				B2C: true,
				BACKOFFICE_ADMIN: true,
				BACKOFFICE: true,
				PORTAL: true,
				CRM: true,
				PUBLIC: false
			},
			destination: {
				AGENT: true,
				B2B: false,
				B2C: true,
				BACKOFFICE_ADMIN: true,
				BACKOFFICE: false,
				PORTAL: true,
				CRM: true,
				PUBLIC: false
			},
			contact: {
				AGENT: true,
				B2B: false,
				B2C: true,
				BACKOFFICE_ADMIN: false,
				BACKOFFICE: false,
				PORTAL: true,
				CRM: true,
				PUBLIC: false
			}
		};
		export const displayPaymentMethod: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
			AGENT: false,
			B2B: true,
			B2C: false,
			BACKOFFICE_ADMIN: false,
			BACKOFFICE: false,
			PORTAL: false,
			CRM: true,
			PUBLIC: false
		};
		export const displayUtilLinks: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
			AGENT: false,
			B2B: true,
			B2C: false,
			BACKOFFICE_ADMIN: false,
			BACKOFFICE: false,
			PORTAL: false,
			CRM: true,
			PUBLIC: false
		};
		export const displayPersonalData: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
			AGENT: false,
			B2B: true,
			B2C: false,
			BACKOFFICE_ADMIN: false,
			BACKOFFICE: true,
			PORTAL: false,
			CRM: true,
			PUBLIC: false
		};
		export const displayLegalInformation: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
			AGENT: false,
			B2B: true,
			B2C: false,
			BACKOFFICE_ADMIN: false,
			BACKOFFICE: true,
			PORTAL: false,
			CRM: true,
			PUBLIC: false
		};
		// export const columnList = <ConfigurationUtil.Context.ApplicationSelector<ITdDataTableColumn[]>>{
		// 	AGENT: ConfigurationOrganizationContextApplicationColumnMap.base.filter(
		// 		i => !ConfigurationOrganizationContextApplicationColumnMap.excludeForAgent.includes(i.name)
		// 	),
		// 	BACKOFFICE: ConfigurationOrganizationContextApplicationColumnMap.base.filter(
		// 		i => !ConfigurationOrganizationContextApplicationColumnMap.excludeForBackoffice.includes(i.name)
		// 	),
		// 	CRM: ConfigurationOrganizationContextApplicationColumnMap.base.filter(
		// 		i => !ConfigurationOrganizationContextApplicationColumnMap.excludeForCrm.includes(i.name)
		// 	)
		// };

		export const columnList = (contextApplication: ContextApplicationItemCodeEnum): ITdDataTableColumn[] => {
			switch (contextApplication) {
				case ContextApplicationItemCodeEnum.BACKOFFICE:
					return columnListOrganizationBackoffice.projectOverride;
				case ContextApplicationItemCodeEnum.AGENT:
					return columnListOrganizationAgent.projectOverride;
				case ContextApplicationItemCodeEnum.CRM:
					return columnListOrganizationCRM.projectOverride;
			}
		};

		export const displayCreditDebitInfoBox: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
			AGENT: true,
			B2B: false,
			B2C: true,
			BACKOFFICE_ADMIN: true,
			BACKOFFICE: false,
			PORTAL: true,
			CRM: true
		};

		export const filterVisible: ConfigurationUtil.Context.ApplicationSelector<boolean[]> = {
			AGENT: [
				ConfigurationOrganizationVisibilityFilter.filterVisibleForAgent.includes('organization_type'),
				ConfigurationOrganizationVisibilityFilter.filterVisibleForAgent.includes('status'),
				ConfigurationOrganizationVisibilityFilter.filterVisibleForAgent.includes('organization_class')
			],
			BACKOFFICE: [
				ConfigurationOrganizationVisibilityFilter.filterVisibleForBackoffice.includes('organization_type'),
				ConfigurationOrganizationVisibilityFilter.filterVisibleForBackoffice.includes('status'),
				ConfigurationOrganizationVisibilityFilter.filterVisibleForAgent.includes('organization_class')
			],
			CRM: [
				ConfigurationOrganizationVisibilityFilter.filterVisibleForCrm.includes('organization_type'),
				ConfigurationOrganizationVisibilityFilter.filterVisibleForCrm.includes('status'),
				ConfigurationOrganizationVisibilityFilter.filterVisibleForAgent.includes('organization_class')
			]
		};

		export const request = ClassRequest.classificationList;
	}

	export namespace Destination {
		export const columnList = (contextApplication: ContextApplicationItemCodeEnum): ITdDataTableColumn[] => {
			switch (contextApplication) {
				case ContextApplicationItemCodeEnum.BACKOFFICE:
					return columnListDestinationOrganizationBackoffice.projectOverride;
				case ContextApplicationItemCodeEnum.AGENT:
					return columnListDestinationOrganizationAgent.projectOverride;
				case ContextApplicationItemCodeEnum.B2B:
					return columnListDestinationOrganizationB2B.projectOverride;
				case ContextApplicationItemCodeEnum.CRM:
					return columnListDestinationOrganizationCRM.projectOverride;
				case ContextApplicationItemCodeEnum.PORTAL:
					return columnListDestinationOrganizationPortal.projectOverride;
				case ContextApplicationItemCodeEnum.B2C:
					return columnListDestinationOrganizationB2C.projectOverride;
			}
		};
	}

	export namespace Contact {
		// export const columnList = <ConfigurationUtil.Context.ApplicationSelector<ITdDataTableColumn[]>>{
		// 	B2B: ConfigurationContactContextApplicationColumnMap.baseColumns.filter(
		// 		i => !ConfigurationContactContextApplicationColumnMap.excludeForB2b.includes(i.name)
		// 	),
		// 	CRM: ConfigurationContactContextApplicationColumnMap.baseColumns.filter(
		// 		i => !ConfigurationContactContextApplicationColumnMap.excludeForCrm.includes(i.name)
		// 	),
		// 	BACKOFFICE: ConfigurationContactContextApplicationColumnMap.baseColumns,

		// 	AGENT:  ConfigurationContactContextApplicationColumnMap.baseColumns,
		// };

		export const columnList = (ContextApplication: ContextApplicationItemCodeEnum): ITdDataTableColumn[] => {
			switch (ContextApplication) {
				case ContextApplicationItemCodeEnum.BACKOFFICE:
					return columnListContactOrganizationBackoffice.projectOverride;
				case ContextApplicationItemCodeEnum.AGENT:
					return columnListContactOrganizationAgent.projectOverride;
				case ContextApplicationItemCodeEnum.B2B:
					return columnListContactOrganizationB2B.projectOverride;
				case ContextApplicationItemCodeEnum.B2C:
					return columnListContactOrganizationB2C.projectOverride;
				case ContextApplicationItemCodeEnum.CRM:
					return columnListContactOrganizationCRM.projectOverride;
				case ContextApplicationItemCodeEnum.PORTAL:
					return columnListContactOrganizationPortal.projectOverride;
			}
		};
	}

	export namespace Opportunity {
		export const OpportunityEnumStatusOutcome: ConfigurationUtil.Context.ApplicationSelector<any> = {
			CRM: OpportunityEnum.Status.Outcome,
			BACKOFFICE: BackofficeOutcome,
			BACKOFFICE_ADMIN: BackofficeOutcome
		};
	}

	export namespace Order {
		export const orderToSendStatus: OrderStatusEnum = OrderStatusEnum.READY_TO_SEND;
		export const canEditByStatus: ConfigurationUtil.Context.ApplicationSelector<OrderStatusEnum[]> = {
			B2B: [OrderStatusEnum.DRAFT],
			AGENT: [OrderStatusEnum.DRAFT],
			B2C: [OrderStatusEnum.DRAFT],
			BACKOFFICE: [OrderStatusEnum.TO_AUTHORIZE],
			BACKOFFICE_ADMIN: [OrderStatusEnum.TO_AUTHORIZE],
			CRM: [OrderStatusEnum.DRAFT],
			PORTAL: [OrderStatusEnum.DRAFT],
			PUBLIC: [OrderStatusEnum.DRAFT]
		};
		export const canUpdateToStatus: ConfigurationUtil.Context.ApplicationSelector<OrderStatusEnum[]> = {
			B2B: [ConfigurationCustomer.Order.orderToSendStatus],
			AGENT: [ConfigurationCustomer.Order.orderToSendStatus],
			B2C: [ConfigurationCustomer.Order.orderToSendStatus],
			BACKOFFICE: [OrderStatusEnum.READY_TO_SEND],
			BACKOFFICE_ADMIN: [OrderStatusEnum.READY_TO_SEND],
			CRM: [ConfigurationCustomer.Order.orderToSendStatus],
			PORTAL: [ConfigurationCustomer.Order.orderToSendStatus],
			PUBLIC: [ConfigurationCustomer.Order.orderToSendStatus]
		};
		export const Attachment: ContextPrivilegeMap = {
			canEdit: {
				AGENT: true,
				B2B: true,
				B2C: true,
				BACKOFFICE_ADMIN: true,
				BACKOFFICE: true,
				PORTAL: true,
				CRM: true,
				PUBLIC: true
			},
			canCreate: {
				AGENT: true,
				B2B: true,
				B2C: true,
				BACKOFFICE_ADMIN: true,
				BACKOFFICE: true,
				PORTAL: true,
				CRM: true,
				PUBLIC: true
			},
			canDownload: {
				AGENT: true,
				B2B: true,
				B2C: true,
				BACKOFFICE_ADMIN: true,
				BACKOFFICE: true,
				PORTAL: true,
				CRM: true,
				PUBLIC: true
			}
		};
		export const canCreate = (
			contextApplication: ContextApplicationItemCodeEnum,
			organization: OrganizationPouchModel
		): boolean => {
			switch (contextApplication) {
				case ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN:
					return organization ? true : false;
				case ContextApplicationItemCodeEnum.BACKOFFICE:
					return false;
				case ContextApplicationItemCodeEnum.B2B:
					return true;
				case ContextApplicationItemCodeEnum.AGENT:
					return organization ? true : false;
				case ContextApplicationItemCodeEnum.B2C:
					return false;
				case ContextApplicationItemCodeEnum.CRM:
					return false;
				default:
					return false;
			}
		};
		export const statusAggregationMap = <ConfigurationStatusAggregationMap>{
			BACKOFFICE: StateRelatedLinkBackoffice,
			AGENT: StateRelatedLinkAgent,
			B2B: StateRelatedLinkB2b,
			B2C: StateRelatedLinkB2c
		};
		export const statusBarMap: ConfigurationUtil.Context.ApplicationSelector<StatusBarConfigNewBis> = {
			AGENT: StatusBar.agent,
			B2B: StatusBar.b2b,
			BACKOFFICE: StatusBar.backoffice
		};

		// export const columnList = (
		// 	contextApplication: ContextApplicationItemCodeEnum
		// ): ITdDataTableColumn[] => {
		// 	const configurationOrderListColumnMap =
		// 		_.cloneDeep(returnConfigurationOrderListColumnMap());
		// 	switch (contextApplication) {
		// 		case ContextApplicationItemCodeEnum.AGENT:
		// 			return configurationOrderListColumnMap.filter(
		// 				i => !ConfigurationOrderContextApplicationColumnMap.excludeForAgent.includes(i.name)
		// 			);
		// 		case ContextApplicationItemCodeEnum.B2B:
		// 			return configurationOrderListColumnMap.filter(
		// 				i => !ConfigurationOrderContextApplicationColumnMap.excludeForB2b.includes(i.name)
		// 			);
		// 		case ContextApplicationItemCodeEnum.B2C:
		// 			return configurationOrderListColumnMap.filter(
		// 				i => !ConfigurationOrderContextApplicationColumnMap.excludeForB2c.includes(i.name)
		// 			);
		// 		case ContextApplicationItemCodeEnum.BACKOFFICE:
		// 			return configurationOrderListColumnMap.filter(
		// 				i => !ConfigurationOrderContextApplicationColumnMap.excludeForBackoffice.includes(i.name)
		// 			);
		// 		case ContextApplicationItemCodeEnum.PORTAL:
		// 			return configurationOrderListColumnMap.filter(
		// 				i => !ConfigurationOrderContextApplicationColumnMap.excludeForPortal.includes(i.name)
		// 			);
		// 		case ContextApplicationItemCodeEnum.CRM:
		// 			return configurationOrderListColumnMap.filter(
		// 				i => !ConfigurationOrderContextApplicationColumnMap.excludeForCrm.includes(i.name)
		// 			);
		// 	}
		// };

		export const columnList = (contextApplication: ContextApplicationItemCodeEnum): ITdDataTableColumn[] => {
			switch (contextApplication) {
				case ContextApplicationItemCodeEnum.BACKOFFICE:
					return columnListOrderListBackoffice.projectOverride;
				case ContextApplicationItemCodeEnum.AGENT:
					return columnListOrderListAgent.projectOverride;
				case ContextApplicationItemCodeEnum.B2B:
					return columnListOrderListB2B.projectOverride;
				case ContextApplicationItemCodeEnum.PORTAL:
					return columnListOrderListPortal.projectOverride;
				case ContextApplicationItemCodeEnum.B2C:
					return columnListOrderListB2C.projectOverride;
				case ContextApplicationItemCodeEnum.CRM:
					return columnListOrderListCrm.projectOverride;
			}
		};

		export const filterOrderVisible: ConfigurationUtil.Context.ApplicationSelector<ConfigurationVisibleOrderFilter> = {
			AGENT: {
				orderStatus: Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForAgent).includes(
					'orderStatus'
				),
				dateCreation: Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForAgent).includes(
					'dateCreation'
				),
				dateDelivery: Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForAgent).includes(
					'dateDelivery'
				),
				dateSubmission: Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForAgent).includes(
					'dateSubmission'
				),
				causalList: Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForAgent).includes(
					'causalList'
				)
			},
			BACKOFFICE: {
				orderStatus: Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForBackoffice).includes(
					'orderStatus'
				),
				dateCreation: Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForBackoffice).includes(
					'dateCreation'
				),
				dateDelivery: Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForBackoffice).includes(
					'dateDelivery'
				),
				dateSubmission: Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForBackoffice).includes(
					'dateSubmission'
				),
				causalList: Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForBackoffice).includes(
					'causalList'
				)
			},
			CRM: {
				orderStatus: Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForCrm).includes(
					'orderStatus'
				),
				dateCreation: Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForCrm).includes(
					'dateCreation'
				),
				dateDelivery: Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForCrm).includes(
					'dateDelivery'
				),
				dateSubmission: Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForCrm).includes(
					'dateSubmission'
				),
				causalList: Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForCrm).includes('causalList')
			},
			B2B: {
				orderStatus: Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForB2b).includes(
					'orderStatus'
				),
				dateCreation: Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForB2b).includes(
					'dateCreation'
				),
				dateDelivery: Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForB2b).includes(
					'dateDelivery'
				),
				dateSubmission: !Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForB2b).includes(
					'dateSubmission'
				),
				causalList: !Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForB2b).includes(
					'causalList'
				)
			},
			B2C: {
				orderStatus: Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForB2c).includes(
					'orderStatus'
				),
				dateCreation: Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForB2c).includes(
					'dateCreation'
				),
				dateDelivery: Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForB2c).includes(
					'dateDelivery'
				),
				dateSubmission: Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForB2c).includes(
					'dateSubmission'
				),
				causalList: Object.values(ConfigurationOrderVisibilityFilter.filterVisibleForB2c).includes('causalList')
			}
		};

		export const listFilterCreationFieldMap: ConfigurationUtil.Context.ApplicationSelector<
			FormControlMultipurposeModel.Item[]
		> = {
			BACKOFFICE: configurationCustomerOrderListFilterCreationFielMapAgent,
			BACKOFFICE_ADMIN: configurationCustomerOrderListFilterCreationFielMapAgent,
			B2B: configurationCustomerOrderListFilterCreationFielMapAgent,
			AGENT: configurationCustomerOrderListFilterCreationFielMapAgent
		};
		export namespace Dashboard {
			// export const columnList = (
			// 	contextApplication: ContextApplicationItemCodeEnum
			// ): ITdDataTableColumn[] => {
			// 	const configurationOrderListColumnMap =
			// 		_.cloneDeep(returnConfigurationOrderListColumnMap());
			// 	switch (contextApplication) {
			// 		case ContextApplicationItemCodeEnum.AGENT:
			// 			return configurationOrderListColumnMap.filter(
			// 				i =>
			// 					!ConfigurationDashboaradOrderContextApplicationColumnMap.excludeForAgent.includes(
			// 						i.name
			// 					)
			// 			);
			// 		case ContextApplicationItemCodeEnum.B2B:
			// 			return configurationOrderListColumnMap.filter(
			// 				i => !ConfigurationDashboaradOrderContextApplicationColumnMap.excludeForB2b.includes(i.name)
			// 			);
			// 		case ContextApplicationItemCodeEnum.B2C:
			// 			return configurationOrderListColumnMap.filter(
			// 				i => !ConfigurationDashboaradOrderContextApplicationColumnMap.excludeForB2c.includes(i.name)
			// 			);
			// 		case ContextApplicationItemCodeEnum.BACKOFFICE:
			// 			return configurationOrderListColumnMap.filter(
			// 				i =>
			// 					!ConfigurationDashboaradOrderContextApplicationColumnMap.excludeForBackoffice.includes(
			// 						i.name
			// 					)
			// 			);
			// 		case ContextApplicationItemCodeEnum.PORTAL:
			// 			return configurationOrderListColumnMap.filter(
			// 				i =>
			// 					!ConfigurationDashboaradOrderContextApplicationColumnMap.excludeForPortal.includes(
			// 						i.name
			// 					)
			// 			);
			// 		case ContextApplicationItemCodeEnum.CRM:
			// 			return configurationOrderListColumnMap.filter(
			// 				i => !ConfigurationDashboaradOrderContextApplicationColumnMap.excludeForCrm.includes(i.name)
			// 			);
			// 	}
			// };
			export const orderTrendBoxStatusList: ConfigurationUtil.Context.ApplicationSelector<OrderStatusEnum[]> = {
				B2B: [
					OrderStatusEnum.DRAFT,
					OrderStatusEnum.PROCESSING,
					OrderStatusEnum.FULFILLED
					// 'DELETED' // non gestibile
				],
				BACKOFFICE: [
					OrderStatusEnum.PROCESSING,
					OrderStatusEnum.FULFILLED
					// 'DELETED' // non gestibile
				],
				BACKOFFICE_ADMIN: [
					OrderStatusEnum.PROCESSING,
					OrderStatusEnum.FULFILLED
					// 'DELETED' // non gestibile
				]
			};
		}
		export const vat: ConfigurationCustomerVat<number> = {
			ARTICLE: {
				AGENT: 22,
				B2B: 22,
				B2C: 22,
				BACKOFFICE_ADMIN: 22,
				BACKOFFICE: 22,
				PORTAL: 22,
				CRM: 22,
				PUBLIC: 22
			},
			SHIPPING: {
				AGENT: 22,
				B2B: 22,
				B2C: 22,
				BACKOFFICE_ADMIN: 22,
				BACKOFFICE: 22,
				PORTAL: 22,
				CRM: 22,
				PUBLIC: 22
			}
		};
		export const paymentMethodIcon = {
			paymentMethodIcon_501: '../../../assets/common/img/metodi-pagamento/cc-mastercard.svg',
			paymentMethodIcon_500: '../../../assets/common/img/metodi-pagamento/cc-paypal.svg'
		};
		export const commonCatalogVisibleInput: ConfigurationUtil.Context.ApplicationSelector<number> = {
			AGENT: 3,
			B2B: 2
		};
		export const commonCheckoutVisibleInput: ConfigurationUtil.Context.ApplicationSelector<number> = {
			AGENT: 2,
			B2B: 1
		};
		export namespace CommonCatalog {
			export const articleStructure: OrderEnum.CommonArticleStructure =
				OrderEnum.CommonArticleStructure.PLAIN_LIST;
			// OrderEnum.CommonArticleStructure.PLAIN_LIST;
			// OrderEnum.CommonArticleStructure.CATEGORY_TREE
			export const articleStructureAccordionLevel: CategoryEnum.Level = CategoryEnum.Level.BRAND;
			export const articleStructureAccordionMultipleOpen: boolean = false;
			export const categoryStructure: OrderCommonCatalogEnum.CategoryStructure =
				OrderCommonCatalogEnum.CategoryStructure.TAB_ON_TOP;
			export const categoryTabOnTopConfiguration: CategoryModel.TabOnTop.Configuration =
				OrderCommonCatalog.categoryTabOnTopConfiguration;
			export const returnNestedCategoryParse = (user: UserDetailModel, e: Category[]): Promise<Category[]> => {
				return new Promise(async resolve => {
					try {
						let categoryReturn = _.cloneDeep(e);
						switch (user.current_permission.context_application) {
							default:
								for (const category of categoryReturn) {
									if (category.category_list && category.category_list.length > 0) {
										for (const categoryChild of category.category_list) {
											delete categoryChild.category_list;
										}
									}
								}
								break;
						}
						resolve(categoryReturn);
					} catch (err) {
						throw new Error(err);
					}
				});
			};
		}
		export const common_checkout_article_structure: OrderEnum.CommonArticleStructure =
			OrderEnum.CommonArticleStructure.PLAIN_LIST;
		export const qtyBoxMultipleCheck: ConfigurationCustomerOrganizationTypeSelector<boolean> = {
			PRIVATE: true,
			COMPANY: false,
			PUBLIC: true
		};
		export namespace AdditionalService {
			export namespace COMPANY {
				export const stockType: boolean = false;
				export const deliveryDate: boolean = true;
			}
			export namespace PRIVATE {
				export const stockType: boolean = false;
				export const deliveryDate: boolean = false;
			}
		}
		export const causalCode: ConfigurationUtil.Context.ApplicationSelector<string> = {
			AGENT: 'NI',
			B2B: 'NI',
			BACKOFFICE_ADMIN: 'NI',
			BACKOFFICE: 'NI',
			PORTAL: 'NI',
			CRM: 'NI'
		};
		export namespace Form {
			export namespace CreationFieldMap {
				export const header = (
					user: UserDetailModel,
					configuration: ConfigurationPage['pageContext'] = ''
				): FormControlMultipurposeModel.Item[] => {
					switch (user.current_permission.context_application) {
						case ContextApplicationItemCodeEnum.B2B:
							return configurationCustomerOrderHeaderFieldList;
						case ContextApplicationItemCodeEnum.BACKOFFICE:
							return configurationCustomerOrderHeaderFieldListBackOffice;
						case ContextApplicationItemCodeEnum.AGENT:
							//return configurationCustomerOrderHeaderFieldListAgent;
							return OrderHeaderCreationFieldMapAgent.OrderHeader();
						default:
							break;
					}
				};
			}
		}

		export const headerAsideSummaryFieldList: ConfigurationUtil.Context.ApplicationSelector<
			FormControlMultipurposeModel.Item[]
		> = {
			B2B: CustomerConfigurationOrderHeaderAsideSummaryB2bFieldList,
			AGENT: CustomerConfigurationOrderHeaderAsideSummaryAgentFieldList,
			BACKOFFICE: CustomerConfigurationOrderHeaderAsideSummaryB2bFieldList
		};

		export const returnNestedCategoryParseAsideSummary = (
			user: UserDetailModel,
			e: Category[]
		): Promise<Category[]> => {
			return new Promise(async resolve => {
				try {
					let categoryReturn = _.cloneDeep(e);
					switch (user.current_permission.context_application) {
						default:
							// assume che le categorie siano strutturate come segue, componendo una lista di categorie di
							// secondo livello
							// order-form
							//   brand 1
							//   brand 2
							// set
							//   set 1
							//   set 2
							const loop = 2;
							let categoryReturnParse: Category[] = [];
							for (let i = 0; i < loop; i++) {
								if (categoryReturn[i]) {
									for (let n = 0; n < categoryReturn[i].category_list.length; n++) {
										delete categoryReturn[i].category_list[n].category_list;
									}
									categoryReturnParse = categoryReturnParse.concat(categoryReturn[i].category_list);
								}
							}
							categoryReturn = categoryReturnParse;
							break;
					}
					resolve(categoryReturn);
				} catch (err) {
					throw new Error(err);
				}
			});
		};
		export const categorySectionList: CategoryModel.SectionItem[] = orderCategorySectionList;

		export namespace Date {
			export const minDateSelectable: ConfigurationUtil.Context.ApplicationSelector<Date> = {
				B2B: ConfigurationOrder.UtilDate.returnFirstValidDate(
					ConfigurationOrderExtend.UtilDate.firstEvasionDateMinDate,
					[6, 0]
				),
				AGENT:
					// TODO: bonifica di ConfigurationOrderExtend ed aggiunta degli altri context da fare dopo
					// la sostituzione di order-detai.component
					ConfigurationOrder.UtilDate.returnFirstValidDate(1, [6, 0]),
				B2C: ConfigurationOrder.UtilDate.returnFirstValidDate(1, [6, 0])
			};
		}

		export const noteOrderVisibility: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
			AGENT: false,
			B2B: false,
			B2C: false,
			PORTAL: false
		};

		export const headerAndRowsOrderClone: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
			AGENT: true,
			B2B: false,
			B2C: false,
			PORTAL: false
		};
	}

	export namespace Article {
		export const articleRecapDefaultPublic = OrganizationTypeEnum.COMPANY;
		export const previewLength = 380;
		export const has_category: boolean = true;
		export const currencyDefault: string = 'EUR';
		export const backofficeArticleColumnList: ITdDataTableColumnCustom[] = articleColumnMapBackofficeArticle;
		export const backofficeArticleDetailColumnList: ITdDataTableColumnCustom[] = articleColumnMapBackofficeArticleDetail;
		export const articleColumnMapBackofficeProductStockColumnList: ITdDataTableColumnCustom[] = articleColumnMapBackofficeProductStock;
		export const orderColumnList = (
			currency?: string,
			page?: string
		): ConfigurationUtil.Context.ApplicationSelector<ITdDataTableColumnCustom[]> => {
			if (!currency) {
				currency = ConfigurationCustomer.Article.currencyDefault;
			}
			return <ConfigurationUtil.Context.ApplicationSelector<ITdDataTableColumn[]>>{
				AGENT:
					page && page === ROUTE_URL.catalog
						? articleColumnMapAgentCatalog(currency)
						: articleColumnMapAgentCheckout(currency),
				CRM:
					page && page === ROUTE_URL.catalog
						? articleColumnMapCrmCatalog(currency)
						: articleColumnMapB2bCheckout(currency),
				BACKOFFICE:
					page && page === ROUTE_URL.catalog
						? articleColumnMapBackOfficeCatalog(currency)
						: articleColumnMapBackOfficeCheckout(currency),
				B2B:
					page && page === ROUTE_URL.catalog
						? articleColumnMapB2bCatalog(currency)
						: articleColumnMapB2bCheckout(currency)
			};
		};
		export const catalogSortList: ConfigurationUtil.Context.ApplicationSelector<SortSelectorConfigurationItem[]> = {
			BACKOFFICE_ADMIN: ConfigurationCustomerArticleContextApplicationCatalogSortList.base,
			BACKOFFICE: ConfigurationCustomerArticleContextApplicationCatalogSortList.base,
			AGENT: ConfigurationCustomerArticleContextApplicationCatalogSortList.base,
			B2B: ConfigurationCustomerArticleContextApplicationCatalogSortList.base,
			B2C: ConfigurationCustomerArticleContextApplicationCatalogSortList.base,
			PORTAL: ConfigurationCustomerArticleContextApplicationCatalogSortList.base,
			CRM: ConfigurationCustomerArticleContextApplicationCatalogSortList.base,
			PUBLIC: ConfigurationCustomerArticleContextApplicationCatalogSortList.base
		};
		export const articleChangeInputKeyboardNavigationEnabled: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
			AGENT: true
		};
		export const articleChangeInputMask: ConfigurationUtil.Context.ApplicationSelector<boolean> = {
			B2B: true
		};

		export const imageWidth = 350;
		export namespace Category {
			export const columnList = (level: CategoryEnum.Level): ITdDataTableColumnCustom[] => {
				switch (level) {
					case CategoryEnum.Level.BRAND:
						return ConfigurationCustomerArticleCategoryColumnMap.base.filter(i =>
							ConfigurationCustomerArticleCategoryColumnMap.forBrand.includes(i.name)
						);
					case CategoryEnum.Level.LINE:
						return ConfigurationCustomerArticleCategoryColumnMap.base.filter(i =>
							ConfigurationCustomerArticleCategoryColumnMap.forLine.includes(i.name)
						);
					case CategoryEnum.Level.LEAF:
						return ConfigurationCustomerArticleCategoryColumnMap.base.filter(i =>
							ConfigurationCustomerArticleCategoryColumnMap.forLeaf.includes(i.name)
						);
				}
			};
		}
	}

	export namespace Statistic {
		export const returnNestedCategoryParse = (user: UserDetailModel, e: Category[]): Category[] => {
			const categoryReturn: Category[] = _.cloneDeep(e[0].category_list);
			switch (user.current_permission.context_application) {
				case ContextApplicationItemCodeEnum.B2B:
					for (let i = 0; i < categoryReturn.length; i++) {
						delete categoryReturn[i].category_list;
					}
					break;
				default:
					break;
			}
			return categoryReturn;
		};
		export const categoryTreeWithArticleParse = (
			user: UserDetailModel,
			tree: Category[],
			articleList: ArticlePouchModel[],
			utilTranslateService: AngularSpin8CoreUtilTranslateService
		): Promise<ArticleCheckoutTree[]> => {
			return new Promise(async resolve => {
				try {
					// TODO: sistemare questa tipizzazione durante il riordino massivo di tutte le dinamiche relative
					// ad ArticleCheckoutTree
					let categoryReturn = tree as ArticleCheckoutTree[];
					switch (user.current_permission.context_application) {
						case ContextApplicationItemCodeEnum.B2B:
							break;
						default:
							categoryReturn = await ConfigurationCategory.returnArticleCheckoutTree(
								articleList,
								categoryReturn,
								utilTranslateService.languages
							);
							const articleListWithoutCategory: number = utilService.getElementIndex(
								categoryReturn,
								CategoryEnum.CodeItem.ARTICLE_LIST_WITHOUT_CATEGORY,
								'code_item'
							);
							if (articleListWithoutCategory || articleListWithoutCategory === 0) {
								categoryReturn.splice(articleListWithoutCategory, 1);
							}
							break;
					}
					resolve(categoryReturn);
				} catch (err) {
					throw new Error(err);
				}
			});
		};
		export const returnCategoryListFilter = (categoryList: Category[]): Category[] => {
			categoryList = _.cloneDeep(categoryList);
			const categoryListReturn: Category[] = categoryList[0].category_list;
			categoryListReturn.forEach(i => {
				delete i.category_list;
			});
			return categoryListReturn;
		};
		export const calculateSummaryTrend = (summary: StatisticModel.Item.Base[]): StatisticModel.Item.Base[] => {
			const summaryPresent: StatisticModel.Item.Base = summary.find(
				i => i.key === StatisticEnum.Item.Key.PRESENT
			);
			const summaryPast: StatisticModel.Item.Base = summary.find(i => i.key === StatisticEnum.Item.Key.PAST);
			if (summaryPresent && summaryPast && summaryPresent.total && summaryPast.total) {
				for (const itemUnit in StatisticEnum.Unit) {
					const itemUnitName: string = StatisticEnum.Unit[itemUnit];
					if (!summaryPresent.trend) {
						summaryPresent.trend = <StatisticModel.Item.ValueType>{};
					}
					summaryPresent.trend[itemUnitName] =
						(summaryPresent.total[itemUnitName] / summaryPast.total[itemUnitName]) * 100 - 100;
				}
			}
			return summary;
		};
		export const comparisonYearList = (): number[] => {
			const goBackTill: number = 2;
			const yearCurrent: number = new Date().getFullYear();
			const comparisonYearList: number[] = [];
			for (let i = 1; i <= goBackTill; i++) {
				comparisonYearList.push(yearCurrent - i);
			}
			return comparisonYearList;
		};
		export const timeRangeSelector: StatisticModel.TimeRangeSelector[] = [
			{
				key: 'days',
				value: 7,
				label: 'statistic.time_range.last_7_days'
			},
			{
				key: 'months',
				value: 1,
				label: 'statistic.time_range.last_month'
			},
			{
				key: 'months',
				value: 6,
				label: 'statistic.time_range.last_6_months'
			}
		];
	}

	// lib
	export namespace TicketCenter {
		export const configuration = <TicketCenterConfigurationModel>{
			page: {
				list: {
					title: {
						AGENT: 'lib.ticket_center.page.list.title',
						B2B: 'lib.ticket_center.page.list.title',
						B2C: 'lib.ticket_center.page.list.title',
						BACKOFFICE_ADMIN: 'lib.ticket_center.page.list.title',
						BACKOFFICE: 'lib.ticket_center.page.list.title',
						PORTAL: 'lib.ticket_center.page.list.title',
						CRM: 'lib.ticket_center.page.list.title'
					},
					tableColumn: TicketCenterColumn.contextApplicationMap,
					localSearchTextKeyList: TicketCenterLocalSearchTextKeyList.contextApplicationMap
				}
			},
			ticket: {
				bodyCustomField: TicketCenterBodyCustomField.creationFieldMap,
				statusContextApplicationStatusMap: TicketCenterStatus.contextApplicationMap
			}
		};
	}

	export namespace AttachedFile {
		export const columnList = (contextApplication: ContextApplicationItemCodeEnum) => {
			switch (contextApplication) {
				default:
					return ConfigurationCustomerAttachedFileContextApplicationColumnMap.base;
			}
		};
	}

	export namespace Reference {
		export namespace Development {
			export const ADDRESS: string = 'A. Sforza, 87 20141 Milano (MI)';
			export const ADDRESS_SHORT: string = 'A. Sforza, 87';
			export const POSTAL_CODE: string = '20141';
			export const CITY: string = 'Milano';
			export const PROVINCE: string = 'MI';
			export const COUNTRY: string = 'Italy';
			export const WEBSITE: string = 'www.saep.it';
			export const VAT: string = 'IT 03228490136';
			export const EMAIL: string = 'info@saep.it';
			export const NAME_LONG: string = 'SAEP ICT Engineering s.r.l.';
			export const NAME: string = 'SAEP ICT';
			export const PHONE: string = '02 36684945';
			export const SOFTWARE: string = 'SPIN-8';
			export const LOGO: string = '/assets/common/img/logo/saep-ict/logo-saep-ict-color.svg';
		}
		export namespace Customer {
			export const ADDRESS: string = 'theme.contact.field.organization_address';
			export const EMAIL: string = 'theme.contact.field.email';
			export const NAME_LONG: string = 'theme.contact.field.organization_name';
			export const NAME: string = 'theme.contact.field.organization_name';
			export const PHONE: string = 'theme.contact.field.phone';
			export const SOFTWARE: string = 'SPIN-8';
			export namespace Position {
				export const LAT: string = 'theme.contact.organization_position.lat';
				export const LONG: string = 'theme.contact.organization_position.long';
			}
			export namespace SocialMedia {
				export const facebook = {
					hidden: false,
					iconPath: '/assets/common/img/social-media/facebook.svg',
					label: 'Facebook',
					link: 'https://www.facebook.com'
				};
				export const instagram = {
					hidden: false,
					iconPath: '/assets/common/img/social-media/instagram.svg',
					label: 'Instagram',
					link: 'https://www.instagram.com'
				};
				export const linkedin = {
					hidden: false,
					iconPath: '/assets/common/img/social-media/linkedin.svg',
					label: 'LinkedIn',
					link: 'https://www.linkedin.com'
				};
				export const youtube = {
					hidden: false,
					iconPath: '/assets/common/img/social-media/youtube.svg',
					label: 'YouTube',
					link: 'https://www.youtube.com'
				};
			}
		}
	}

	export namespace InformativePage {
		export const columnList = (contextApplication: ContextApplicationItemCodeEnum) => {
			switch (contextApplication) {
				default:
					return InformativePageColumnMap.base;
			}
		};
	}

	export namespace Form {
		export const recaptchaSiteKey: string = '6Leg14kaAAAAAMJDBlrXElHXb5uDs__GQpqaQCBH';
		export const formControlMultipurpose: ConfigurationUtil.Context.ApplicationSelector<
			ConfigurationUtil.DocumentSelector<FormControlMultipurposeModel.Item[]>
		> = {
			BACKOFFICE: {
				ORGANIZATION: CreationFieldMapContextCodeOrganizationBackoffice.projectOverride,
				BACKOFFICE: CreationFieldMapContextCodeBase.projectOverride,
				AGENT: CreationFieldMapContextCodeBase.projectOverride,
				CRM: CreationFieldMapContextCodeBase.projectOverride,
				CONTACT: CreationFieldMapContactDetailDialog.projectOverride,
				CONTEXT_CODE_BASE: creationFieldMapContextCodeBase.projectOverride,
				DOCUMENT_BASE: creationFieldMapDocumentBase.projectOverride,
				DESTINATION_BASE: creationFieldMapDestinationBase.projectOverride,
				DESTINATION_LIST_EDIT: creationFieldMapDestinationListEdit.projectOverride,
				DIVISION: creationFieldMapDivision.projectOverride
			},
			AGENT: {
				ORGANIZATION: CreationFieldMapContextCodeOrganizationAgent.projectOverride,
				CONTACT: CreationFieldMapContactDetailDialog.projectOverride,
				DESTINATION_BASE: creationFieldMapDestinationBase.projectOverride,
				DESTINATION_LIST_EDIT: creationFieldMapDestinationListEdit.projectOverride
			},
			B2B: {
				ORGANIZATION: CreationFieldMapContextCodeOrganizationB2b.projectOverride,
				CONTACT: CreationFieldMapContactDetailDialog.projectOverride,
				DESTINATION_BASE: creationFieldMapDestinationBase.projectOverride,
				DESTINATION_LIST_EDIT: creationFieldMapDestinationListEdit.projectOverride
			},
			B2C: {
				ORGANIZATION: CreationFieldMapContextCodeOrganizationB2c.projectOverride,
				CONTACT: CreationFieldMapContactDetailDialog.projectOverride,
				DESTINATION_BASE: creationFieldMapDestinationBase.projectOverride,
				DESTINATION_LIST_EDIT: creationFieldMapDestinationListEdit.projectOverride
			},
			PORTAL: {
				ORGANIZATION: CreationFieldMapContextCodeOrganizationPortal.projectOverride,
				CONTACT: CreationFieldMapContactDetailDialog.projectOverride,
				DESTINATION_BASE: creationFieldMapDestinationBase.projectOverride,
				DESTINATION_LIST_EDIT: creationFieldMapDestinationListEdit.projectOverride
			},
			CRM: {
				ORGANIZATION: CreationFieldMapContextCodeOrganizationCrm.projectOverride,
				CONTACT: CreationFieldMapContactDetailDialog.projectOverride,
				DESTINATION_BASE: creationFieldMapDestinationBase.projectOverride,
				DESTINATION_LIST_EDIT: creationFieldMapDestinationListEdit.projectOverride
			},
			PUBLIC: {
				ORGANIZATION: CreationFieldMapContextCodeOrganizationPublic.projectOverride,
				CONTACT: CreationFieldMapContactDetailDialog.projectOverride,
				DESTINATION_BASE: creationFieldMapDestinationBase.projectOverride,
				DESTINATION_LIST_EDIT: creationFieldMapDestinationListEdit.projectOverride
			}
		};
	}

	export namespace ContextCode {
		export const columnList = (canEdit: boolean) =>
			<ConfigurationUtil.Context.ApplicationSelector<ITdDataTableColumn[]>>{
				AGENT: ConfigurationContextCodeColumnMap.base(canEdit).filter(
					i => !ConfigurationContextCodeColumnMap.excludeForAgent.includes(i.name)
				),
				B2B: ConfigurationContextCodeColumnMap.base(canEdit).filter(
					i => !ConfigurationContextCodeColumnMap.excludeForB2b.includes(i.name)
				),
				B2C: ConfigurationContextCodeColumnMap.base(canEdit).filter(
					i => !ConfigurationContextCodeColumnMap.excludeForB2c.includes(i.name)
				),
				BACKOFFICE: ConfigurationContextCodeColumnMap.base(canEdit).filter(
					i => !ConfigurationContextCodeColumnMap.excludeForBackoffice.includes(i.name)
				),
				PORTAL: ConfigurationContextCodeColumnMap.base(canEdit).filter(
					i => !ConfigurationContextCodeColumnMap.excludeForPortal.includes(i.name)
				),
				CRM: ConfigurationContextCodeColumnMap.base(canEdit).filter(
					i => !ConfigurationContextCodeColumnMap.excludeForCrm.includes(i.name)
				)
			};
	}

	export namespace Receipt {
		export const columnList = (contextApplication: ContextApplicationItemCodeEnum): ITdDataTableColumnCustom[] => {
			switch (contextApplication) {
				case ContextApplicationItemCodeEnum.AGENT:
					return ConfigurationReceiptContextApplicationColumnMap.base.filter(
						i => !ConfigurationReceiptContextApplicationColumnMap.excludeForAgent.includes(i.name)
					);
				case ContextApplicationItemCodeEnum.B2B:
					return ConfigurationReceiptContextApplicationColumnMap.base.filter(
						i => !ConfigurationReceiptContextApplicationColumnMap.excludeForB2b.includes(i.name)
					);
				case ContextApplicationItemCodeEnum.CRM:
					return ConfigurationReceiptContextApplicationColumnMap.base.filter(
						i => !ConfigurationReceiptContextApplicationColumnMap.excludeForCrm.includes(i.name)
					);
				default:
					return ConfigurationReceiptContextApplicationColumnMap.base;
			}
		};
	}

	export namespace News {
		export const canCreate = {
			detail: {
				AGENT: false,
				B2B: false,
				B2C: false,
				BACKOFFICE_ADMIN: true,
				BACKOFFICE: true,
				CRM: false,
				PORTAL: false,
				PUBLIC: false
			}
		};
		export const showLastN = 3;
		export const returnListFilteredByTab = (
			contextApplication: ContextApplicationItemCodeEnum,
			tab: string,
			list: NewsModel[]
		): NewsModel[] => {
			list = ConfigurationNews.listFilter(list, contextApplication);
			switch (contextApplication) {
				case ContextApplicationItemCodeEnum.BACKOFFICE:
				case ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN:
					switch (tab) {
						case ROUTE_URL.allNews:
							return list;
						case ROUTE_URL.new:
							return list.filter(item => item.header.status === NewsStatusEnum.NEW);
						case ROUTE_URL.archive:
							return list.filter(item => item.header.status === NewsStatusEnum.ARCHIVE);
					}
				default:
					switch (tab) {
						case ROUTE_URL.allNews:
							return list.filter(item => item.header.status === NewsStatusEnum.NEW);
						case ROUTE_URL.new:
							return list
								.filter(item => item.header.status === NewsStatusEnum.NEW)
								.sort((a, b) => b.date_update - a.date_update)
								.slice(0, showLastN);
					}
			}
		};
	}

	export namespace Faq {
		export const orderDetail: ConfigurationUtil.Context.ApplicationSelector<FaqConfigurationModel> = {
			B2B: { section: 'order_detail' },
			AGENT: { section: 'order_detail' }
		};
	}

	export namespace Forecast {
		export const articleStructureAccordionMultipleOpen: boolean = false;
		export const categoryTabOnTopConfiguration: CategoryModel.TabOnTop.Configuration =
			ForecastCategoryConfiguration.categoryTabOnTopConfiguration;
		export const returnNestedCategoryParse = (user: UserDetailModel, e: Category[]): Promise<Category[]> => {
			return new Promise(async resolve => {
				try {
					let categoryReturn = _.cloneDeep(e[0].category_list);
					switch (user.current_permission.context_application) {
						default:
							for (const category of categoryReturn) {
								delete category.category_list;
							}
					}
					resolve(categoryReturn);
				} catch (err) {
					throw new Error(err);
				}
			});
		};
		export const returnArticleColumnList = (
			contextApplication: ContextApplicationItemCodeEnum
		): ITdDataTableColumnCustom[] => {
			let articleColumnList: ITdDataTableColumn[];
			switch (contextApplication) {
				default:
					articleColumnList = articleColumnMapB2bForecast();
			}
			return articleColumnList;
		};
		export const returnInputDisable = (year: number, month: number): boolean => {
			if (year > yearCurrent) {
				return false;
			}
			return month <= monthCurrent;
		};

		/**
		 * Nel corrente progetto il forecast è compilato su base mensile, `inputList` è dunque generato da 12 iterazioni
		 * e la label fa riferimento al nome contratto del mese nei bundle multilingua
		 * @returns
		 */
		export const returnInputList = (year: number): ForecastModel.ConfigurationInput[] => {
			const inputList: ForecastModel.ConfigurationInput[] = [];
			for (let i = 1; i <= 12; i++) {
				inputList.push({
					key: i,
					label: `general.time.month_short.${i}`,
					value: null,
					disabled: ConfigurationCustomer.Forecast.returnInputDisable(year, i)
				});
			}
			return inputList;
		};
		export const returnDateSelectorList = (): number[] => {
			const yearList: number[] = [];
			yearList.push(yearCurrent);
			// dal 1 Ago (mese 8) è disponibile la compilazione del forecast per l'anno successivo
			const monthStart = 8;
			if (monthCurrent >= monthStart) {
				yearList.push(yearCurrent + 1);
			}
			return yearList;
		};
	}

	export namespace ExportExcel {
		export const order_file_name = 'pagamenti.xlsx';
		export const order_worksheet_name = 'orders';

		export const organization_file_name = 'soci.xlsx';
		export const organization_worksheet_name = 'customers';
	}
}
