import { Injectable } from '@angular/core';
import {
	ResourceAction,
	ResourceRequestMethod,
	IResourceMethod,
	IResourceMethodPromiseStrict
} from '@ngx-resource/core';
import { AngularSpin8CoreBaseApiService, returnApiUrlSegment } from '@saep-ict/angular-spin8-core';

@Injectable()
export class ConfigurationService extends AngularSpin8CoreBaseApiService {
	@ResourceAction({
		path:  `/${returnApiUrlSegment('privateApi')}/sync/`,
		method: ResourceRequestMethod.Post
	})
	synchAs400: IResourceMethod<void, void>;

	@ResourceAction({
		path:  `/${returnApiUrlSegment('privateApi')}/download-product-stock/`,
		method: ResourceRequestMethod.Post
	})
	syncProductStock: IResourceMethod<void, void>;

	@ResourceAction({
		path: `/${returnApiUrlSegment('privateApi')}/file/upload/`,
		method: ResourceRequestMethod.Post
	})
	uploadCategoryTree: IResourceMethodPromiseStrict<string, { type: string }, void, void>;
}
