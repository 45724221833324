<page-with-sidebar [sideBarPosition]="sideBarPositionValues.NONE">
	<ng-container pageMain>
		<mat-card class="angelo-theme">
			<top-bar
				[goBackButton]="true"
				[goBackPath]="backPath"
				class="mb-20"
			>
			</top-bar>
			<mat-accordion>
				<!-- Context application -->
				<mat-expansion-panel
					*ngFor="let context of contextTypeAndApplicationLink.context_code_item.association_type"
				>
					<mat-expansion-panel-header>
						<mat-panel-title>
							<strong>
								{{
									'context_code.x_associated'
										| translate
											: {
													context_code: utilService.formatPlaceholder(
														context.description | translate
													)
											  }
										| sentencecase
								}}
							</strong>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<!-- Buttons -->
					<div class="btn" *ngIf="!appUtilService.isMobile()">
						<button mat-button class="btn__add" (click)="dialogAssociateAllContextCodeItem(context)">
							<mat-icon>add</mat-icon>
							<span>
								{{
									(appUtilService.isTablet()
										? 'context_code.associate_all_x_short'
										: 'context_code.associate_all_x'
									)
										| translate: { context_code: context.description | translate }
										| sentencecase
								}}
							</span>
						</button>
						<button
							mat-button
							class="btn__remove"
							(click)="dialogConfirmDeleteAssociatedContextCodeList(context)"
						>
							<mat-icon>remove</mat-icon>
							<span>
								{{
									(appUtilService.isTablet()
										? 'context_code.disassociate_all_x_short'
										: 'context_code.disassociate_all_x'
									)
										| translate: { context_code: context.description | translate }
										| sentencecase
								}}
							</span>
						</button>
						<span class="flex-span"></span>
						<form-button-bar
							*ngIf="
								configurationCustomer.UserManagement.canEditContextCode[
									contextApplicationItemCodeEnum[
										contextTypeAndApplicationLink.context_application_item_code
									]
								]
							"
							[align]="'right'"
							[primaryButtonLabel]="'context_code.create_x' | translate | sentencecase"
							(primaryButtonClickEmitter)="dialogCreateContextCodeItem(context)"
							[secondaryButtonLabel]="'context_code.associate_x_existent' | translate | sentencecase"
							(secondaryButtonClickEmitter)="dialogAssociateContextCodeItem(context)"
						></form-button-bar>
					</div>
					<div *ngIf="appUtilService.isMobile()">
						<mat-form-field>
							<mat-label>Select action</mat-label>
							<mat-select>
								<mat-option value="option1">
									{{
										'context_code.associate_all_x'
											| translate: { context_code: context.description | translate }
											| sentencecase
									}}
								</mat-option>
								<mat-option value="option2">
									{{
										'context_code.disassociate_all_x'
											| translate: { context_code: context.description | translate }
											| sentencecase
									}}
								</mat-option>
								<mat-option value="option3">
									{{ 'context_code.create_x' | translate | sentencecase }}
								</mat-option>
								<mat-option value="option3">
									{{ 'context_code.associate_x_existent' | translate | sentencecase }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<!-- Table -->
					<td-data-table
						#dataTable
						*ngIf="
							permissionUtilService.getContextItem(
								getFormFieldValue('context_code_association_list'),
								context.code
							) &&
							permissionUtilService.getContextItem(
								getFormFieldValue('context_code_association_list'),
								context.code
							).context_code_list &&
							permissionUtilService.getContextItem(
								getFormFieldValue('context_code_association_list'),
								context.code
							).context_code_list.length > 0
						"
						[data]="
							permissionUtilService.getContextItem(
								getFormFieldValue('context_code_association_list'),
								context.code
							).context_code_list
						"
						[columns]="permissionContextListColumnService.columns"
					>
						<ng-template tdDataTableTemplate="code" let-value="value" let-row="row">
							<p>
								{{
									row.code_erp ?
									row.code_erp :
									(
										(
											row.code ||
											row.code_item
										)
										| guidFormatter
									)
								}}
								<ng-container *ngIf="row.code_erp">
									<br />
									<small class="cell-subtitle">
										{{ row.code | guidFormatter }}
									</small>
								</ng-container>
							</p>
						</ng-template>
						<ng-template tdDataTableTemplate="description" let-row="row">
							{{ row.description || row.business_name }}
						</ng-template>
						<ng-template tdDataTableTemplate="action" let-row="row">
							<button
								mat-icon-button
								stopPropagation
								type="button"
								(click)="dialogConfirmDeleteAssociatedContextCodeItem(context, row.code)"
							>
								<mat-icon>delete</mat-icon>
							</button>
						</ng-template>
					</td-data-table>
				</mat-expansion-panel>
			</mat-accordion>
			<!-- Buttons (save changes) -->
			<ng-container actions>
				<form-button-bar
					class="actions-buttons"
					[align]="'space-between'"
					[primaryButtonLabel]="'context_code.save_codes' | translate | sentencecase"
					(primaryButtonClickEmitter)="onFormSubmit()"
					[secondaryButtonLabel]="'general.cancel_changes' | translate | sentencecase"
					(secondaryButtonClickEmitter)="exitEditMode()"
				></form-button-bar>
			</ng-container>
		</mat-card>
	</ng-container>
</page-with-sidebar>
