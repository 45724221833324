import { Injectable } from '@angular/core';
import { IResourceMethodPromise, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';
import { AngularSpin8CoreBaseApiService } from '@saep-ict/angular-spin8-core';

@Injectable()
export class AggregatorService extends AngularSpin8CoreBaseApiService {
	@ResourceAction({
		path: `/../aggregator/command`,
		method: ResourceRequestMethod.Post
	})
	startAggregatorCommand: IResourceMethodPromise<{ command: string; optional_args: { code_item: string[] } }, string>;
}
