<page-with-sidebar [sideBarPosition]="sideBarPositionValues.NONE">
	<ng-container pageMain>
		<mat-card class="angelo-theme autoheight">
			<tab-wrapper></tab-wrapper>
			<news-list-wrapper
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				(selectedNewsList)="updateSelectedNewsList($event.codeItemList, $event.action)"
			></news-list-wrapper>
		</mat-card>
	</ng-container>
	<ng-container pageSide>
	</ng-container>
</page-with-sidebar>

<button
	mat-fab
	*ngIf="
		configurationCustomer.News.canCreate.detail[
			contextApplicationItemCodeEnum[user.current_permission.context_application]
		]
	"
	(click)="createNew()"
	[matTooltip]="'news.action.create' | translate | sentencecase"
	matTooltipPosition="left"
	class="mat-fab-button"
>
	<mat-icon>add</mat-icon>
	<span class="label">{{ 'news.action.create' | translate | sentencecase }}</span>
</button>
