import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// store
import { StateFeature } from '../../../state';
import { Store } from '@ngrx/store';
import {
	BaseStateModel,
	SubscribeManagerService,
	AngularCoreUtilService,
	SentencecasePipe
} from '@saep-ict/angular-core';
// widget & utility
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
// model
import { PermissionUtilService } from '../../../service/util/permission-util.service';
import { ContextCodeAssociationStateAction } from '../../../state/backoffice/context-code/context-code-association/context-code-association.actions';
import { DialogAddContextCodeComponent } from '../dialog-add-context-code/dialog-add-context-code.component';
import { OrganizationPouchModel, OrganizationTypeEnum } from '@saep-ict/pouch_agent_models';
import { TranslateService } from '@ngx-translate/core';
import { FormControlMultipurposeModel } from '@saep-ict/angular-core';
import _ from 'lodash';
import { ContextCodeEditOnChange } from '../../context-code/context-code-edit/context-code-edit.component';
import {
	ContextCodeManagementActionEnum,
	ContextCodeManagementStateAction
} from '../../../state/backoffice/context-code/context-code-management/context-code-management.actions';
import { OrganizationActionEnum, OrganizationStateAction } from '../../../state/organization/organization.actions';
import { UtilAddressService } from '../../../service/util/util-address.service';
import { ConfigurationViewModel } from '../../../model/configuration.model';
import {
	ContextApplicationItemCodeEnum,
	ContextCodeItem,
	ContextCodeItemLink,
	UserDetailModel,
	FormControlMap,
	AngularSpin8CoreUtilTranslateService,
	OrganizationStateModel,
	BodyTablePouchCustomModel
} from '@saep-ict/angular-spin8-core';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';

// TODO: spostare in libreria
export interface ContextCodeItemLinkCustom extends ContextCodeItemLink {
	value?: BodyTablePouchCustomModel | OrganizationPouchModel;
}

@Component({
	selector: 'dialog-context-code-edit',
	templateUrl: './dialog-context-code-edit.component.html',
	styleUrls: ['./dialog-context-code-edit.component.scss'],
	providers: [SubscribeManagerService]
})
export class DialogContextCodeEditComponent implements OnInit, OnDestroy {
	contextCodeCreated: ContextCodeItem;
	formIsValid = false;
	formValue: any;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	configuration$: Observable<BaseStateModel<ConfigurationViewModel>> = this.store.select(
		StateFeature.getConfigurationState
	);
	configuration: ConfigurationViewModel;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);

	formControlConfigurationEdit: FormControlMultipurposeModel.ConfigurationEdit;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: ContextCodeItemLinkCustom,
		private store: Store<any>,
		public dialogRef: MatDialogRef<DialogAddContextCodeComponent>,
		private snackBar: MatSnackBar,
		private subscribeManagerService: SubscribeManagerService,
		private permissionUtilService: PermissionUtilService,
		public utilService: AngularCoreUtilService,
		private translate: TranslateService,
		private utilAddressService: UtilAddressService,
		private utilTranslateService: AngularSpin8CoreUtilTranslateService,
		private sentencecasePipe: SentencecasePipe
	) {
		this.subscribeManagerService.populate(this.subscribeContextCodeList().subscribe(), 'context-code-list');
		this.subscribeManagerService.populate(this.subscribeOrganization().subscribe(), 'organization');
		this.configuration$.pipe(take(1)).subscribe(res => {
			this.configuration = res.data;
		});
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
			const contextApplication = this.user.current_permission.context_application;
			const contextCodeKey = this.data.couch_document_key.toUpperCase();
			const controlMapDefaultValue = _.cloneDeep(
				FormControlMap.CreationDefaultValue.ContextApplication[
					ContextApplicationItemCodeEnum[contextApplication]
				][contextCodeKey]
			);
			const formControlConfigurationEdit = {
				formControlList:
					ConfigurationCustomer.Form.formControlMultipurpose[
						ContextApplicationItemCodeEnum[contextApplication]
					][contextCodeKey],
				value: data.value ? data.value : controlMapDefaultValue
			};
			const division_listIndex = this.utilService.getElementIndex(
				formControlConfigurationEdit.formControlList,
				'name',
				'division_list'
			);
			if (division_listIndex || division_listIndex === 0) {
				controlMapDefaultValue.division_list[0].division = this.configuration.division_list.find(
					div => div.is_main_of_list
				).division;
			}

			const destination_listIndex = this.utilService.getElementIndex(
				formControlConfigurationEdit.formControlList,
				'name',
				'destination_list'
			);
			if (destination_listIndex || destination_listIndex === 0) {
				formControlConfigurationEdit.formControlList[
					destination_listIndex
				].form_control_list = this.utilAddressService.setAddressProvinceOptionList(
					// TODO: dinamizzazione del context in sopstituzione di `ContextApplicationItemCodeEnum.BACKOFFICE`
					ConfigurationCustomer.Form.formControlMultipurpose[ContextApplicationItemCodeEnum.BACKOFFICE]
					.DESTINATION_BASE
				);
			}
			if (!data.value && destination_listIndex) {
				formControlConfigurationEdit.value.destination_list = [
					FormControlMap.CreationDefaultValue.destination.create
				];
			}
			if (formControlConfigurationEdit.value.level) {
				formControlConfigurationEdit.value.level = data['level'] || formControlConfigurationEdit.value.level;
			}

			const contact_listIndex = this.utilService.getElementIndex(
				formControlConfigurationEdit.formControlList,
				'name',
				'contact_list'
			);
			if (contact_listIndex || contact_listIndex === 0) {
				formControlConfigurationEdit.formControlList[
					contact_listIndex
				].form_control_list = this.utilTranslateService.returnCreationMapWithProjectLanguageListOptionList(
					formControlConfigurationEdit.formControlList[contact_listIndex].form_control_list
				);
			}

			this.formControlConfigurationEdit = formControlConfigurationEdit;
		});
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.permissionUtilService.resetContextCodeListAssociatedState();
		this.store.dispatch(ContextCodeAssociationStateAction.reset());
	}

	// subscribe
	subscribeContextCodeList(): Observable<BaseStateModel<ContextCodeItem[]>> {
		return this.permissionUtilService.subscribeContextCodeList().pipe(
			filter((state: BaseStateModel<ContextCodeItem[]>) => {
				return state.type === ContextCodeManagementActionEnum.SAVE_ITEM_SUCCESS;
			}),
			map((state: BaseStateModel<ContextCodeItem[]>) => {
				this.contextCodeCreated = <ContextCodeItem>state.data[0];
				const message = this.translate.instant('context_code.x_created', {
					context_code: this.contextCodeCreated.description
				});
				this.snackBar.open(message, null, { duration: 3000 });
				this.dialogRef.close(this.contextCodeCreated);
				return state;
			})
		);
	}

	subscribeOrganization(): Observable<BaseStateModel<OrganizationStateModel>> {
		return this.organization$.pipe(
			filter((state: BaseStateModel<OrganizationStateModel>) => {
				return state && state.type === OrganizationActionEnum.SAVE_SUCCESS;
			}),
			map((state: BaseStateModel<OrganizationStateModel>) => {
				const message = this.sentencecasePipe.transform(
					this.data['type'] == 'edit'
						? this.translate.instant('organization.edited')
						: this.translate.instant('organization.added')
				);
				this.snackBar.open(message, null, { duration: 3000 });
				this.dialogRef.close(state.data);
				if (!this.permissionUtilService.isOrganizationCodeItem(this.user)) {
					this.store.dispatch(OrganizationStateAction.reset());
				}
				return state;
			})
		);
	}

	contextCodeEditOnChange(e: ContextCodeEditOnChange) {
		this.formValue = e.value;
		this.formIsValid = e.isValid;
	}

	onFormSubmit() {
		const bodyTablePouch = <BaseStateModel<BodyTablePouchCustomModel | OrganizationPouchModel>>{
			data: this.formValue
		};
		if (this.data['contestoDialog'] == 'organization') {
			// Forzo il type nell'attesa di separare le logiche del form di inserimento organization dal form di associazione nuovo context code
			bodyTablePouch.data.type = 'organization';
			if (bodyTablePouch.data['organization_type'] == OrganizationTypeEnum.PRIVATE) {
				bodyTablePouch.data['business_name'] =
					bodyTablePouch.data['contact_list'][0].first_name +
					'|' +
					bodyTablePouch.data['contact_list'][0].last_name;
			}
			this.store.dispatch(OrganizationStateAction.save(bodyTablePouch));
		} else {
			this.store.dispatch(ContextCodeManagementStateAction.saveItem(bodyTablePouch));
		}
	}

	contextCodeEditOnCancel() {
		this.dialogRef.close();
	}
}
