import { BaseTableListService, DateMomentService, ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class RequestListColumnService extends BaseTableListService {
	constructor(
		@Inject(TranslateService) public translate: TranslateService,
		private dateMomentService: DateMomentService
	) {
		super();
	}
	public _columns: ITdDataTableColumnCustom[] = [
		{
			name: '_id',
			labelPath: 'opportunity.field.document_number',
			label: null,
			width: 120
		},
		{
			name: 'date_creation',
			labelPath: 'opportunity.field.date_creation',
			label: null,
			format: (v: string) => this.dateMomentService.convertStringDateFromFormatToFormat(v, 'x', 'DD/MM/YYYY')
		},
		{
			name: 'organization.business_name',
			labelPath: 'theme.opportunity.field.business_name',
			label: null
		},
		{
			name: 'organization.organization_class',
			labelPath: 'theme.organization.field.class',
			label: null
		},
		{
			name: 'organization.contact_list',
			labelPath: 'opportunity.field.email',
			label: null
		}
	];
}
