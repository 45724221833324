<h3>{{'theme.order.information' | translate | sentencecase}}</h3>
<div class="wrapper">
	<div class="row-wrapper order-information">
		<div *ngFor="let field of orderHeaderFieldList" class="row-item">
			<div class="header">{{ field.label | translate | sentencecase }}</div>
			<div class="content">
				<ng-container
					*ngIf="
						field.type !== formControlMultipurposeEnum.ItemType.BOOLEAN;
						else formControlMultipurposeTypeBoolean
					"
				>
					{{ field.value }}
				</ng-container>
				<ng-template #formControlMultipurposeTypeBoolean>
					<!-- TODO: sostituire le icone? -->
					<mat-icon *ngIf="field.value">done</mat-icon>
					<mat-icon *ngIf="!field.value">close</mat-icon>
				</ng-template>
			</div>
		</div>
	</div>
</div>
<mat-divider class="angelo-theme both-spacing"></mat-divider>
<h3>{{'theme.order.field.purchased_product' | translate | sentencecase}}</h3>
<!--
	TODO: verificare comportamento CRM, l'`ng-content` qui di seguito dovrebe contenere
	le righe libere
-->
<ng-content></ng-content>
<ng-container *ngTemplateOutlet="articleStructure"></ng-container>
<!-- ng template partial -->
<ng-template #articleStructure>
	<ng-container
		*ngIf="
			listPageBaseData &&
			order &&
			order.product_list &&
			order.product_list.length > 0;
			else noData
		"
		[ngSwitch]="configurationCustomer.Order.common_checkout_article_structure"
	>
		<article-list-structure-wrapper-category-tree
			*ngSwitchCase="orderEnum.CommonArticleStructure.CATEGORY_TREE"
			[localListHandlerWidgetWrapperData]="listPageBaseData"
			[localListHandlerWidgetWrapperColumnList]="listPageBaseData.columnList"
			[localListHandlerTextFilterShow]="false"
			[localListHandlerPaginatorShow]="false"
			[organization]="organization"
			[configurationAction]="articleTableConfigurationAction"
			(onArticleChange)="onArticleChangeHandler($event)"
			(onDeleteArticle)="
				onArticleChangeHandler({
					event: { target: { value: null } },
					key: 'delete',
					row: $event
				})
			"
			class="checkout-article-table-wrapper"
		>
		</article-list-structure-wrapper-category-tree>
		<article-list-structure-wrapper-plain-list
			*ngSwitchCase="orderEnum.CommonArticleStructure.PLAIN_LIST"
			[localListHandlerWidgetWrapperData]="listPageBaseData"
			[localListHandlerWidgetWrapperColumnList]="listPageBaseData.columnList"
			[localListHandlerTextFilterShow]="true"
			[localListHandlerPaginatorShow]="false"
			[organization]="organization"
			[order]="order"
			[paginatorOnTopShow]="true"
			[configurationAction]="articleTableConfigurationAction"
			(onArticleChange)="onArticleChangeHandler($event)"
			(onDeleteArticle)="
				onArticleChangeHandler({
					event: { target: { value: null } },
					key: 'delete',
					row: $event
				})
			"
			class="checkout-article-table-wrapper"
		>
		</article-list-structure-wrapper-plain-list>
	</ng-container>
</ng-template>
<ng-template #noData>
	<p class="placeholder">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
